@if ((positive20 && positive20.length )|| (negative20 && negative20.length)) {
<div class="container-fluid p-3 head-bg">
  <div id="news" class="text-center fda-text">
    <h1 class="main-heading text-white m-0 me-3">
      Top Positive / Negative News
    </h1>
  </div>
</div>
}
<div class="wrapper justify-content-evenly">
  @if( positive20 && positive20.length){
  <div class="col-md-12 col-lg-5">
    <div class="title-shape most-Unusual-table p-2">
      <h2 #ShortInterest class="main-blue-heading Option-Stock-table pt-3">
        Positive
      </h2>
    </div>
    <div
      id="dataContainer"
      style="max-height: 500px !important"
      #dataContainer
      class="Short-table table-responsive all-table-scroll"
    >
      <table class="table table-fixed table-lock-height mb-0">
        <thead class="sticky-header head-z-index">
          <tr class="text-uppercase table-header">
            <th class="px-3 text-center py-3 sticky-ticker head-z-index">
              Ticker
            </th>
            <th
              class="px-4 py-3 text-nowrap text-center"
              placement="bottom"
              container="body"
              ngbTooltip="Price and change"
            >
              Price
            </th>
            <th
              class="px-2 py-3 text-nowrap text-center"
              placement="bottom"
              container="body"
              ngbTooltip="published date"
            >
              published
            </th>
            <th
              class="px-3 py-3 text-nowrap text-center"
              placement="bottom"
              container="body"
            >
              title
            </th>
          </tr>
        </thead>
        <tbody class="table-font-size">
          @for (data of positive20; track $index) {
          <tr>
            <td
              style="cursor: pointer; vertical-align: middle"
              class="sticky-ticker"
            >
              <div
                class="text-center company-hover"
                (click)="heighrstDetails(data.ticker)"
              >
                <p class="company-img-space mb-0 text-center">
                  {{ data.ticker }}
                </p>
              </div>
            </td>
            <td class="" style="vertical-align: middle">
              <p class="m-0 text-nowrap text-center">
                {{ data.close }}
              </p>
              <p
                class="text-center table-font-size m-0"
                [style.color]="data.change.toString() | riseAndFallColor"
              >
                {{ data.change | number : "1.0-2" | qoqChg }}
              </p>
            </td>
            <td class="" style="vertical-align: middle">
              <p class="m-0 text-nowrap text-center">
                {{ data.published | date : "MMM d, yyyy" }}
              </p>
            </td>
            <td class="" style="vertical-align: middle">
              <!-- <p class="m-0 text-nowrap text-center">
              {{ data.title }}
            </p> -->

              <div
                class="px-2"
                style="
                  align-items: center;
                  display: flex;
                  justify-content: center;
                "
              >
                <div class="table-description-text text-center">
                  @if (data.title) {
                  <a class="text-dark" style="text-decoration: none">
                    {{ data.title.substring(0, 25) }}...
                  </a>
                  } @else {
                  <span>-</span>
                  } @if (data.title && data.title.length > 25) {
                  <p
                    style="cursor: pointer"
                    class="text-danger text-center m-0"
                    placement="left"
                    container="body"
                    ngbTooltip="{{ data.title }}"
                    triggers="click:blur"
                  >
                    Read More+
                  </p>
                  }
                </div>
              </div>
            </td>
          </tr>
          }@empty {
          <tr style="box-shadow: none">
            <td colspan="17">
              <ngx-skeleton-loader
                [theme]="{ height: '50px',  'border-radius': '4px',  }"
                count="9"
              >
              </ngx-skeleton-loader>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
  } @if(negative20 && negative20.length){
  <div class="col-md-12 col-lg-5">
    <div class="title-shape most-Unusual-table p-2">
      <h2 #ShortInterest class="main-blue-heading Option-Stock-table pt-3">
        Negative
      </h2>
    </div>
    <div
      class="Short-table all-table-scroll table-responsive"
      id="dataContainer"
      style="max-height: 500px !important"
      #dataContainer
    >
      <table class="table table-fixed table-lock-height mb-0">
        <thead class="sticky-header head-z-index">
          <tr class="text-uppercase table-header">
            <th class="px-3 text-center py-3 sticky-ticker">Ticker</th>
            <th
              class="px-4 py-3 text-nowrap text-center"
              placement="bottom"
              container="body"
              ngbTooltip="Price and change"
            >
              Price
            </th>
            <th
              class="px-2 py-3 text-nowrap text-center"
              placement="bottom"
              container="body"
              ngbTooltip="published"
            >
              published
            </th>
            <th
              class="px-5 py-3 text-nowrap text-center"
              placement="bottom"
              container="body"
            >
              title
            </th>
          </tr>
        </thead>
        <tbody class="table-font-size">
          @for (data of negative20; track $index) {
          <tr>
            <td
              style="cursor: pointer; vertical-align: middle"
              class="sticky-ticker"
            >
              <div
                class="text-center company-hover"
                (click)="heighrstDetails(data.ticker)"
              >
                <p class="company-img-space mb-0 text-center">
                  {{ data.ticker }}
                </p>
              </div>
            </td>
            <td class="" style="vertical-align: middle">
              <p class="m-0 text-nowrap text-center">
                {{ data.close }}
              </p>
              <p
                class="text-center table-font-size m-0"
                [style.color]="data.change.toString() | riseAndFallColor"
              >
                {{ data.change | number : "1.0-2" | qoqChg }}
              </p>
            </td>
            <td class="" style="vertical-align: middle">
              <p class="m-0 text-nowrap text-center">
                {{ data.published | date : "MMM d, yyyy" }}
              </p>
            </td>
            <td class="" style="vertical-align: middle">
              <!-- <p class="m-0 text-nowrap text-center">
              {{ data.title }}
            </p> -->

              <div
                class="px-3"
                style="
                  align-items: center;
                  display: flex;
                  justify-content: center;
                "
              >
                <div class="table-description-text text-center">
                  @if (data.title) {
                  <a class="text-dark" style="text-decoration: none">
                    {{ data.title.substring(0, 25) }}...
                  </a>
                  } @else {
                  <span>-</span>
                  } @if (data.title && data.title.length > 25) {
                  <p
                    style="cursor: pointer"
                    class="text-danger text-center m-0"
                    placement="left"
                    container="body"
                    ngbTooltip="{{ data.title }}"
                    triggers="click:blur"
                  >
                    Read More+
                  </p>
                  }
                </div>
              </div>
            </td>
          </tr>
          }@empty {
          <tr style="box-shadow: none">
            <td colspan="17">
              <ngx-skeleton-loader
                [theme]="{ height: '50px',  'border-radius': '4px',  }"
                count="9"
              >
              </ngx-skeleton-loader>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
  }
</div>

<div class="container-fluid p-3 mt-5 head-bg">
  <div class="text-center fda-text">
    <h1 class="main-heading text-white m-0 me-3">Latest Insiders</h1>
  </div>
</div>

<div class="wrapper justify-content-evenly mt-3">
  <div id="topBuyers" class="col-md-12 col-lg-5">
    <div class="title-shape most-Unusual-table p-2">
      <div class="d-flex justify-content-center align-items-center">
        <h2 class="main-blue-heading top-buys-head Option-Stock-table">
          Top buys
        </h2>
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            #priceinput
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            {{ RangeDate }}
            <i class="fa fa-caret-down ms-4"></i>
          </button>
          <ul class="dropdown-menu games-menu">
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectDate('month')"
              >
                Month
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectDate('week')"
              >
                Week
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center m-0"
                (click)="selectDate('day')"
              >
                Day
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      id="dataContainer"
      style="max-height: 500px !important"
      #dataContainer
      class="Short-table table-responsive all-table-scroll"
    >
      <table class="table table-fixed table-lock-height mb-0">
        <thead class="sticky-header head-z-index">
          <tr class="text-uppercase table-header">
            <th class="px-3 text-center py-3 sticky-ticker">Ticker</th>
            <th
              class="px-4 py-3 text-nowrap text-center"
              placement="bottom"
              container="body"
              ngbTooltip="Price and change"
            >
              Avg Price
            </th>
            <th
              class="px-2 py-3 text-nowrap text-center"
              placement="bottom"
              container="body"
              ngbTooltip="published date"
            >
              buyers
            </th>
            <th
              class="px-3 py-3 text-nowrap text-center"
              placement="bottom"
              container="body"
            >
              Traded Value
            </th>
          </tr>
        </thead>
        <tbody>
          @for (data of buyers; track $index) {
          <tr>
            <td
              style="cursor: pointer; vertical-align: middle"
              class="sticky-ticker"
            >
              <div
                class="text-center company-hover"
                (click)="heighrstDetails(data.symbol)"
              >
                <p class="company-img-space table-font-size mb-0 text-center">
                  {{ data.symbol }}
                </p>
              </div>
            </td>
            <td class="" style="vertical-align: middle">
              <p class="m-0 text-nowrap table-font-size text-center">
                {{ data.avg_price | number : "1.0-2" }}
              </p>
            </td>
            <td>
              <div
                class="px-2"
                style="
                  align-items: center;
                  display: flex;
                  justify-content: center;
                "
              >
                <div class="table-description-text text-center table-font-size">
                  @if (data.buyers) { @if (data.buyers.length > 1) {
                  <a class="text-dark" style="text-decoration: none">
                    <p class="m-0 table-font-size">{{ data.buyers[0].name }}</p>
                    <i class="table-font-italic">{{
                      data.buyers[0].relationship
                    }}</i>
                  </a>
                  } @else {
                  <a class="text-dark" style="text-decoration: none">
                    @for (item of data.buyers; track $index) {
                    <p class="m-0 table-font-size">{{ item.name }}</p>
                    <i class="table-font-italic">{{ item.relationship }}</i>
                    }
                  </a>

                  } @if (data.buyers.length > 1) {
                  <ng-template #tooltipTemplateBuyer>
                    @for (item of data.buyers; track $index) {
                    <div class="m-1">
                      <p class="m-0 table-font-size">{{ item.name }}</p>
                      <i class="table-font-italic">{{ item.relationship }}</i>
                    </div>
                    }
                  </ng-template>
                  <p
                    style="cursor: pointer"
                    class="text-danger text-center m-0"
                    placement="left"
                    container="body"
                    [ngbTooltip]="tooltipTemplateBuyer"
                    triggers="click:blur"
                  >
                    Read More+
                  </p>
                  } }@else {
                  <span>-</span>
                  }
                </div>
              </div>
            </td>
            <td class="" style="vertical-align: middle">
              <p class="m-0 text-nowrap table-font-size text-center">
                {{ data.totalTradedValue | dollarsCoversion }}
              </p>
            </td>
          </tr>
          }@empty {
          <tr style="box-shadow: none">
            <td colspan="17">
              No data available.
              <!-- <ngx-skeleton-loader
                [theme]="{ height: '50px',  'border-radius': '4px',  }"
                count="9"
              >
              </ngx-skeleton-loader> -->
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  </div>

  <div id="topSellers" class="col-md-12 col-lg-5">
    <div class="title-shape most-Unusual-table p-2">
      <div class="d-flex justify-content-center align-items-center">
        <h2 class="main-blue-heading top-buys-head Option-Stock-table">
          Top Sells
        </h2>
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            #priceinput2
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            {{ RangeDateSell }}
            <i class="fa fa-caret-down ms-4"></i>
          </button>
          <ul class="dropdown-menu games-menu">
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectDateSell('month')"
              >
                Month
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectDateSell('week')"
              >
                Week
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center m-0"
                (click)="selectDateSell('day')"
              >
                Day
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      id="dataContainer"
      style="max-height: 500px !important"
      #dataContainer
      class="Short-table table-responsive all-table-scroll"
    >
      <table class="table table-fixed table-lock-height mb-0">
        <thead class="sticky-header head-z-index">
          <tr class="text-uppercase table-header">
            <th class="px-3 text-center py-3 sticky-ticker">Ticker</th>
            <th
              class="px-4 py-3 text-nowrap text-center"
              placement="bottom"
              container="body"
              ngbTooltip="Price and change"
            >
              Avg Price
            </th>
            <th
              class="px-2 py-3 text-nowrap text-center"
              placement="bottom"
              container="body"
              ngbTooltip="published date"
            >
              Sellers
            </th>
            <th
              class="px-3 py-3 text-nowrap text-center"
              placement="bottom"
              container="body"
            >
              Traded Value
            </th>
          </tr>
        </thead>
        <tbody>
          @for (data of sellers; track $index) {
          <tr>
            <td
              style="cursor: pointer; vertical-align: middle"
              class="sticky-ticker"
            >
              <div
                class="text-center company-hover"
                (click)="heighrstDetails(data.symbol)"
              >
                <p class="company-img-space table-font-size mb-0 text-center">
                  {{ data.symbol }}
                </p>
              </div>
            </td>
            <td class="" style="vertical-align: middle">
              <p class="m-0 text-nowrap table-font-size text-center">
                {{ data.avg_price | number : "1.0-2" }}
              </p>
            </td>
            <td>
              <div
                class="px-2"
                style="
                  align-items: center;
                  display: flex;
                  justify-content: center;
                "
              >
                <div class="table-description-text text-center table-font-size">
                  @if (data.sellers) { @if (data.sellers.length > 1) {
                  <a class="text-dark" style="text-decoration: none">
                    <p class="m-0 table-font-size">
                      {{ data.sellers[0].name }}
                    </p>
                    <i class="table-font-italic">{{
                      data.sellers[0].relationship
                    }}</i>
                  </a>
                  } @else {
                  <a class="text-dark" style="text-decoration: none">
                    @for (item of data.sellers; track $index) {
                    <p class="m-0 table-font-size">{{ item.name }}</p>
                    <i class="table-font-italic">{{ item.relationship }}</i
                    >}
                  </a>

                  } @if (data.sellers.length > 1 ) {
                  <ng-template #tooltipTemplateSeller>
                    @for (item of data.sellers; track $index) {
                    <div class="m-1">
                      <p class="m-0 table-font-size">{{ item.name }}</p>
                      <i class="table-font-italic">{{ item.relationship }}</i>
                    </div>
                    }
                  </ng-template>

                  <p
                    style="cursor: pointer"
                    class="text-danger text-center m-0"
                    placement="left"
                    container="body"
                    [ngbTooltip]="tooltipTemplateSeller"
                    triggers="click:blur"
                  >
                    Read More+
                  </p>
                  } }@else {
                  <span>-</span>
                  }
                </div>
              </div>
            </td>
            <td class="" style="vertical-align: middle">
              <p class="m-0 text-nowrap table-font-size text-center">
                {{ data.totalTradedValue | dollarsCoversion }}
              </p>
            </td>
          </tr>
          }@empty {
          <tr style="box-shadow: none">
            <td colspan="17">No data available.</td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="container-fluid p-3 mt-5 head-bg">
  <div class="text-center fda-text">
    <h1 class="main-heading text-white m-0 me-3">Latest Unusual Volume</h1>
  </div>
</div>

<div id="LatestUnusualVolume" class="wrapper justify-content-evenly my-3">
  <div class="col-md-10">
    <!-- <div class="title-shape most-Unusual-table p-2">
      <h2 #ShortInterest class="main-blue-heading Option-Stock-table pt-3">
        Unusual Volume
      </h2>
    </div> -->
    <div
      id="dataContainer"
      style="max-height: 500px !important"
      #dataContainer
      class="Short-table table-responsive all-table-scroll"
    >
      <table class="table table-fixed table-lock-height mb-0">
        <thead class="sticky-header head-z-index">
          <tr class="text-uppercase table-header">
            <th class="px-3 text-center py-3 sticky-ticker">Ticker</th>
            <th
              class="px-4 py-3 text-nowrap text-center"
              placement="bottom"
              container="body"
              ngbTooltip="Price and change"
            >
              Avg Volume
            </th>
            <th
              class="px-2 py-3 text-nowrap text-center"
              placement="bottom"
              container="body"
              ngbTooltip="published date"
            >
              current
            </th>
            <th
              class="px-3 py-3 text-nowrap text-center"
              placement="bottom"
              container="body"
            >
              Unusualness Ratio
            </th>
          </tr>
        </thead>
        <tbody>
          @for (data of optionUnusual; track $index) {
          <tr>
            <td
              style="cursor: pointer; vertical-align: middle"
              class="sticky-ticker"
            >
              <div
                class="text-center company-hover"
                (click)="heighrstDetails(data.Ticker)"
              >
                <p class="company-img-space table-font-size mb-0 text-center">
                  {{ data.Ticker }}
                </p>
              </div>
            </td>
            <td class="" style="vertical-align: middle">
              <p class="m-0 text-nowrap table-font-size text-center">
                {{ data["Average Volume"] | dollarsCoversion }}
              </p>
            </td>
            <td>
              <p class="m-0 table-font-size text-center">
                {{ data["Current Volume"] | dollarsCoversion }}
              </p>
            </td>
            <td class="" style="vertical-align: middle">
              <p class="m-0 text-nowrap table-font-size text-center">
                {{ data["Unusualness Ratio"] | number : "1.0-2" }}
              </p>
            </td>
          </tr>
          }@empty {
          <tr style="box-shadow: none">
            <td colspan="17">
              <ngx-skeleton-loader
                [theme]="{ height: '50px',  'border-radius': '4px',  }"
                count="9"
              >
              </ngx-skeleton-loader>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</div>
