import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from '../services/login.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  isTokenExpiredError = false;

  constructor(
    private loginService: LoginService,
    private toaster: ToastrService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const accessToken = localStorage.getItem('loggedInUser');
    const userTOKEN = JSON.parse(accessToken!);

    if (userTOKEN != null) {
      request = request.clone({
        setHeaders: {
          'x-access-token': userTOKEN.accessToken,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.isTokenExpiredError = false;
          // this.toaster.error('Token Expired', 'Please Login Again');
          if (localStorage.getItem('accessToken')) {
            this.loginService.logout();
            this.toaster.error('Token Expired', 'Please login Again!!');
          }
        }
        return throwError(error);
      })
    );
  }
}
