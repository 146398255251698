import { Component } from '@angular/core';

@Component({
  selector: 'app-locksec',
  templateUrl: './locksec.component.html',
  styleUrl: './locksec.component.scss',
})
export class LocksecComponent {
  url = '';
  constructor() {}
  ngOnInit() {
    this.url = window.location.href;
    if (this.url.includes('individual')) {
    } else {
    }
  }
}
