<div class="container login-page mt-4 mb-4">
  <div class="row shadow responsive-display">
    <div class="col-lg-6 col-md-5 d-flex justify-content-center responsive-image p-0">
      <div class="p-5">
        <div class="text-center">
          <img class="img-fluid" src="assets/images/login-pg-logo.svg" alt="" />
        </div>
        <div class="text-center mt-3">
          <h2 class="text-white m-0 mb-3">
            Start making profits in the Biotech and Pharma market today!</h2>
            <div class="mb-2">
              <div class="d-flex mb-2 justify-content-start align-items-center">
                <i class="fa fa-check-circle text-success me-2"></i>
                <p class="m-0 text-start text-light">
                  Searchable FDA calendar with data readouts and PDUFA catalysts.
                </p>
              </div>
              <div class="d-flex mb-2 justify-content-start align-items-center">
                <i class="fa fa-check-circle text-success me-2"></i>
                <p class="m-0 text-start text-light">
                  Full access to drug pipelines, company health, and clinical
                  trial research on over 1,100 companies.
                </p>
              </div>
              <div class="d-flex mb-2 justify-content-start align-items-center">
                <i class="fa fa-check-circle text-success me-2"></i>
                <p class="m-0 text-start text-light">
                  Track biotech earnings, catalyst events, insider trading, and
                  hedge fund holdings.
                </p>
              </div>
              <div class="d-flex mb-2 justify-content-start align-items-center">
                <i class="fa fa-check-circle text-success me-2"></i>
                <p class="m-0 text-start text-light">
                  Advanced portfolio management tools with real-time market
                  updates, trends, and analysis.
                </p>
              </div>
            </div>
        </div>

        <div class="testimonial-card" [@slideInOut]="currentState">
          <div class="stars text-center ms-2 mb-2">
            &#9733;&#9733;&#9733;&#9733;&#9733;
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <!-- <div>
                <img
                  class="user-info-img"
                  [src]="displayedTestimonial.image"
                  alt="User Photo"
                />
              </div> -->
              <div>
                <h4 class="m-0 text-center">{{ displayedTestimonial.name }}</h4>
                <div class="d-flex justify-content-center align-items-center">
                  <span>{{ displayedTestimonial.position }}</span>
                  <span
                    ><img
                      class="img-fluid mx-2"
                      width="30px"
                      src="{{ displayedTestimonial.country }}"
                      alt=""
                  /></span>
                </div>
                <div class="d-flex justify-content-center mt-1">
                  <span style="font-size: 13px">{{
                    displayedTestimonial.address
                  }}</span>
                </div>
              </div>
          </div>
          <p class="my-2 text-center">{{ displayedTestimonial.content }}</p>
        </div>
        
      
      </div>
    </div>

    <div class="col-md-7 main-div col-lg-6 col-12 responsive-form">
      <div class="">
        <div class="border-0 rounded-3 my-5 login-space">
          <div class="p-4 p-sm-5 login">
            <h4 class="login-head text-nowrap fw-bold text-center">Log In into account.</h4>
            <p class="login-txt text-center">
              New here?
              <a routerLink="/signup" class="text-decoration-none linkcolor Sign-Up">Sign Up</a>
              and join us!
            </p>

            <div class="d-flex justify-content-center">
              <div class="w-75 login-form-box">
                <form [formGroup]="loginform" (ngSubmit)="login(loginform.value)">
                  <div class="form-floating mb-3">
                    <i class="fa-regular fa-user float-r" style="color: #000000;"></i>
                    <input type="email" class="form-control" #floatingInput placeholder="name@example.com"
                      [formControl]="emailControl" name="email" />

                    @if (loginform.controls['email'].invalid &&
                    (loginform.controls['email'].dirty ||
                    loginform.controls['email'].touched ||
                    submitted)) {
                    <div class="mb-1">

                      @if (loginform.controls['email'].errors?.['required']) {
                      <span class="form-error mb-1">Email is
                        required.</span>
                      }@else {
                      <span class="form-error">Enter correct email
                        address.</span>
                      }

                    </div>
                    }

                    <label class="label login-page-text" for="floatingInput">Email </label>
                  </div>

                  <div class="form-floating mb-3">
                    <i class="fa-solid fa-eye float-r" style="color: #000000;" (click)="showPassword = !showPassword"
                      [ngClass]="{
                        'fa-eye': !showPassword,
                        'fa-eye-slash': showPassword
                      }"></i>
                    <input [type]="showPassword ? 'text' : 'password'" class="form-control" id="floatingPassword"
                      placeholder="Password" formControlName="password" name="password" />

                    @if (loginform.controls['password'].invalid &&
                    (loginform.controls['password'].dirty ||
                    loginform.controls['password'].touched ||
                    submitted)) {
                    @if (loginform.controls['password'].errors?.['required']) {
                    <span class="form-error">Password is
                      required.</span>
                    }@else {
                    <div class="mb-1">

                      @if (loginform.controls['password'].errors?.['pattern']) {
                      <span class="form-error">Enter correct
                        Password.</span>
                      }

                    </div>
                    }

                    }

                    <label class="label login-page-text" for="floatingPassword">Password </label>
                  </div>
                  <!-- <div class="form-check mb-3 d-flex align-items-center">
                    <input class="form-check-input mt-0" type="checkbox" value="" id="rememberPasswordCheck"
                      formControlName="checkbox" />
                    <label class="form-check-label d-flex mx-2 login-page-text" for="rememberPasswordCheck">
                      I agree to <a routerLink="/Terms-service" class="text-reset ms-1">Terms & Condition</a>
                    </label>
                  </div>

                  @if ( loginform.controls['checkbox'].invalid &&
                  (loginform.controls['checkbox'].dirty ||
                  loginform.controls['checkbox'].touched||submitted)) {
                  <div class="">
                    @if (loginform.controls['checkbox'].errors?.['required']) {
                    <span class="form-error">Terms &
                      Condition is required.</span>
                    }

                  </div>
                  } -->

                  <!-- <div class="mb-3">
                    <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha">
                    </ngx-recaptcha2>
                    @if ( loginform.controls['recaptcha'].invalid &&
                    (loginform.controls['recaptcha'].dirty ||
                    loginform.controls['recaptcha'].touched ||
                    submitted)) {
                    <div class="mb-1">
                      @if (loginform.controls['recaptcha'].errors?.['required']) {
                      <span class="form-error mb-1">recaptcha
                        is required.</span>
                      }
                    </div>
                    }

                  </div> -->

                  <div class="d-flex justify-content-start align-items-center Forgot-pass-size mb-3 mt-3">
                    <a (click)="forgetpass()"
                      class="text-decoration-none text-nowrap forget-password login-page-text">Forgot
                      password?</a>
                  </div>

                  <div class="d-grid justify-content-center mt-4">
                    <button class="btn fw-bold shadow login-btn " type="submit">

                      @if (isLoading) {
                      <i class="fa fa-spin fa-spinner"></i>
                      }@else {
                      <span class="">Login Now</span>
                      }

                    </button>
                  </div>

                  <hr class="hr-text m-0 mt-3 mb-3" data-content="OR" />
                </form>
                <div class="d-grid">
                  <button class="btn btn-google " (click)="signInWithGoogle()" type="button">

                    <div class="google-text-size">
                      <img class="me-3" src="assets/images/google-icon.svg" alt="" />
                      Login with <span class="google-text fw-bold">google</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>


            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{ display: displayStyle }">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="modal-title text-center">Password Reset Mail</h4>
                  </div>
                  <div class="modal-body">
                    <form [formGroup]="resetEmail" (ngSubmit)="savePopup()" action="">
                      <div>
                        <div class="form-floating password-input mb-4">
                          <i class="fa-solid fa-envelope float-r"></i>
                          <input type="text" class="form-control" id="resetemail" formControlName="forgetemail"
                            placeholder="Email" />
                          @if ( resetEmail.controls['forgetemail'].invalid &&
                          (resetEmail.controls['forgetemail'].dirty ||
                          resetEmail.controls['forgetemail'].touched ||
                          submitted)) {
                          <div class="mb-1">

                            @if (resetEmail.controls['forgetemail'].errors?.['required']) {
                            <span class="form-error mb-1">Email is required.</span>
                            }@else {
                            <span class="form-error">Enter
                              correct email address.</span>
                            }

                          </div>
                          }

                          <label for="resetEmail"> Email </label>
                        </div>
                      </div>
                    </form>
                    <div class="modal-footer d-flex justify-content-between">
                      <button type="button" (click)="closePopup()"
                        class="btn profile-edit-button fw-bold text-uppercase btn-light shadow rounded bg-white password-input-reset">
                        Cancel
                      </button>
                      <button type="button" (click)="savePopup()"
                        class="btn profile-edit-button fw-bold text-uppercase btn-light shadow rounded bg-white password-input-save">
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>