import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catalystSync } from '../../shared/models/CatalystSync';
import {
  catalystStages,
  catalystSyncdata,
  catalystSyncdataTen,
} from '../const';

@Injectable({
  providedIn: 'root',
})
export class CatalystSyncService {
  constructor(private http: HttpClient) {}

  getCatalystSync(page: number) {
    return this.http.post<catalystSync[]>(`${catalystSyncdata}${page}`, '');
  }

  getStages() {
    return this.http.get<any[]>(`${catalystStages}`);
  }
  // getCatalystTenSync() {
  //   return this.http.get<catalystSync[]>(`${catalystSyncdataTen}`);
  // }
}
