<div class="container signup-page mt-4 mb-4">
  <div class="row shadow responsive-display">
    <div class="col-lg-6 col-md-7 responsive-form">
      <div class="">
        <div class="border-0 rounded-3 my-5">
          <div class="p-4 signup">
            <p class="mb-0 m-0 text-center">START FOR FREE</p>

            <p class="text text-center m-0">Create new account.</p>
            <p class="text-center">
              Already A Member?
              <a routerLink="/login" class="text-decoration-none linkcolor"
                >Log In</a
              >
            </p>

            <div class="d-flex justify-content-center">
              <div class="form-size w-75">
                <form
                  [formGroup]="signupform"
                  (ngSubmit)="signup(signupform.value)"
                >
                  <div class="">
                    <div class="form-floating mb-3">
                      <i class="fa-solid fa-address-card float-r"></i>
                      <input
                        type="text"
                        class="form-control"
                        id="fname"
                        placeholder="name@example.com"
                        formControlName="fname"
                      />

                      @if ( signupform.controls['fname'].invalid &&
                      (signupform.controls['fname'].dirty ||
                      signupform.controls['fname'].touched||submitted)) {
                      <div class="form-error">
                        @if (signupform.controls['fname'].errors?.['required'])
                        {
                        <span class="">First Name is required.</span>
                        }
                      </div>
                      }

                      <label for="fname">First name </label>
                    </div>

                    <!-- <div class="form-floating input-width mb-3">
                      <input type="text" class="form-control" id="lname" placeholder="name@example.com"
                        formControlName="lname" />
                      @if ( signupform.controls['lname'].invalid &&
                      (signupform.controls['lname'].dirty ||
                      signupform.controls['lname'].touched||submitted)) {
                      <div class="form-error">

                        @if (signupform.controls['lname'].errors?.['required']) {
                        <span class="">Last Name is
                          required.</span>
                        }

                      </div>
                      }

                      <label for="lname">Last name</label>
                      <i class="fa-solid fa-address-card float-r"></i>
                    </div> -->
                  </div>

                  <!-- <div class="form-floating mb-3">
                    <i class="fa-solid fa-phone float-r"></i>
                    <input type="tel" class="form-control" id="mobile" placeholder="name@example.com"
                      formControlName="mobile" />

                    @if ( signupform.controls['mobile'].invalid &&
                    (signupform.controls['mobile'].dirty ||
                    signupform.controls['mobile'].touched||submitted)) {
                    <div class="">
                      @if (signupform.controls['mobile'].errors?.['required']) {
                      <span class="form-error">PhoneNumber is
                        required.</span>
                      }@else{
                      <span class="form-error">Enter correct
                        PhoneNumber.</span>
                      }

                    </div>
                    }

                    <label for="mobile">Phone Number </label>
                  </div> -->

                  <div class="form-floating mb-3">
                    <i class="fa-solid fa-envelope float-r"></i>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      placeholder="name@example.com"
                      formControlName="email"
                    />
                    @if ( signupform.controls['email'].invalid &&
                    (signupform.controls['email'].dirty ||
                    signupform.controls['email'].touched||submitted)) {
                    <div class="">
                      @if (signupform.controls['email'].errors?.['required']) {
                      <span class="form-error">Email is required.</span>
                      }@else {
                      <span class="form-error"
                        >Enter correct email address.</span
                      >
                      }
                    </div>
                    }

                    <label for="email">Email </label>
                  </div>

                  <div class="form-floating mb-3">
                    <i
                      class="fa-solid fa-eye float-r"
                      (click)="showPassword = !showPassword"
                      [ngClass]="{
                        'fa-eye': !showPassword,
                        'fa-eye-slash': showPassword
                      }"
                    ></i>
                    <input
                      [type]="showPassword ? 'text' : 'password'"
                      class="form-control"
                      id="password"
                      placeholder="Password"
                      formControlName="password"
                    />

                    @if ( signupform.controls['password'].invalid &&
                    (signupform.controls['password'].dirty ||
                    signupform.controls['password'].touched||submitted)) {

                    <div class="">
                      @if (signupform.controls['password'].errors?.['required'])
                      {
                      <span class="form-error">Password is required.</span>
                      }@else {
                      <span class="form-error"
                        >Password must be 8 characters long.</span
                      >
                      }
                    </div>
                    }

                    <label for="password">Password </label>
                  </div>

                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input mt-0"
                      type="checkbox"
                      value=""
                      id="rememberPasswordCheck"
                      formControlName="checkbox"
                    />
                    <label
                      class="form-check-label d-flex mx-2"
                      for="rememberPasswordCheck"
                    >
                      I agree to
                      <a routerLink="/Terms-service" class="text-reset ms-1"
                        >Terms & Condition</a
                      >
                    </label>
                  </div>

                  @if ( signupform.controls['checkbox'].invalid &&
                  (signupform.controls['checkbox'].dirty ||
                  signupform.controls['checkbox'].touched||submitted)) {
                  <div class="">
                    @if (signupform.controls['checkbox'].errors?.['required']) {
                    <span class="form-error"
                      >Terms & Condition is required.</span
                    >
                    }
                  </div>
                  }

                  <!-- <div class="mb-3">

                    <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha">
                    </ngx-recaptcha2>

                    @if (signupform.controls['recaptcha'].invalid &&
                    (signupform.controls['recaptcha'].dirty ||
                    signupform.controls['recaptcha'].touched||submitted)) {
                    <div class="">

                      @if (signupform.controls['recaptcha'].errors?.['required']) {
                      <span class="form-error">recaptcha
                        is
                        required</span>
                      }

                    </div>
                    }

                    <div>

                    </div>
                  </div> -->
                  <div class="d-grid justify-content-center mt-4">
                    <button class="btn fw-bold shadow login-btn" type="submit">
                      @if (isLoading) {
                      <i class="fa fa-spin fa-spinner"></i>
                      }@else {
                      <span>Create Account</span>
                      }
                    </button>
                  </div>

                  <hr class="hr-text my-4" data-content="OR" />

                  <div class="d-grid">
                    <button
                      class="btn btn-google fw-bold"
                      (click)="signInWithGoogle()"
                      type="button"
                    >
                      <div class="google-text-size">
                        <img
                          class="me-3"
                          src="assets/images/google-icon.svg"
                          alt=""
                        />
                        Signup with
                        <span class="google-text fw-bold">Google</span>
                      </div>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-lg-6 col-md-5 col-12 d-flex justify-content-center responsive-image"
    >
      <div class="p-5">
        <div class="text-center">
          <img class="img-fluid" src="assets/images/login-pg-logo.svg" alt="" />
        </div>
        <!-- <div class="text-center mt-4">
          <img class="img-fluid" src="assets/images/user-image.svg" alt="" />
        </div> -->
        <div class="text-center mt-4">
          <h2 class="text-white m-0 mb-3">
            Start making profits in the Biotech and Pharma market today!
          </h2>
          <div class="mb-4">
            <div class="d-flex mb-2 justify-content-start align-items-center">
              <i class="fa fa-check-circle text-success me-2"></i>
              <p class="m-0 text-start text-light">
                Searchable FDA calendar with data readouts and PDUFA catalysts.
              </p>
            </div>
            <div class="d-flex mb-2 justify-content-center align-items-center">
              <i class="fa fa-check-circle text-success me-2"></i>
              <p class="m-0 text-start text-light">
                Full access to drug pipelines, company health, and clinical
                trial research on over 1,100 companies.
              </p>
            </div>
            <div class="d-flex mb-2 justify-content-center align-items-center">
              <i class="fa fa-check-circle text-success me-2"></i>
              <p class="m-0 text-start text-light">
                Track biotech earnings, catalyst events, insider trading, and
                hedge fund holdings.
              </p>
            </div>
            <div class="d-flex mb-2 justify-content-center align-items-center">
              <i class="fa fa-check-circle text-success me-2"></i>
              <p class="m-0 text-start text-light">
                Advanced portfolio management tools with real-time market
                updates, trends, and analysis.
              </p>
            </div>
          </div>
          <div class="testimonial-card" [@slideInOut]="currentState">
            <div class="stars text-center ms-2 mb-2">
              &#9733;&#9733;&#9733;&#9733;&#9733;
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <!-- <div>
                  <img
                    class="user-info-img"
                    [src]="displayedTestimonial.image"
                    alt="User Photo"
                  />
                </div> -->
              <div>
                <h4 class="m-0 text-center">{{ displayedTestimonial.name }}</h4>
                <div class="d-flex justify-content-center align-items-center">
                  <span>{{ displayedTestimonial.position }}</span>
                  <span
                    ><img
                      class="img-fluid mx-2"
                      width="30px"
                      src="{{ displayedTestimonial.country }}"
                      alt=""
                  /></span>
                </div>
                <div class="d-flex justify-content-center mt-1">
                  <span style="font-size: 13px">{{
                    displayedTestimonial.address
                  }}</span>
                </div>
              </div>
            </div>
            <p class="my-2 text-center">{{ displayedTestimonial.content }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Verify Email_Popup -->

<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngStyle]="{ display: displayStyle }"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Verify Email</h4>
      </div>
      <div class="modal-body">
        <form [formGroup]="varifyEmail" (ngSubmit)="savePopup()" action="">
          <div class="p-3">
            <label for=""
              >Enter OTP or click on the verification link sent to your email.
            </label>
            <input
              type="text"
              class="form-control"
              formControlName="sinupToken"
              name="otpCode"
              id="otpCode"
            />
          </div>
        </form>
        <div class="modal-footer d-flex justify-content-between">
          <button
            type="button"
            (click)="closePopup()"
            class="btn profile-edit-button fw-bold text-uppercase btn-light shadow rounded bg-white password-input-reset"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="savePopup()"
            class="btn profile-edit-button fw-bold text-uppercase btn-light shadow rounded bg-white password-input-save"
          >
            @if (isLoading) {
            <i class="fa fa-spin fa-spinner"></i>
            }@else {
            <span class="linkcolor">Verify</span>
            }
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
