import { Component } from '@angular/core';
import { IpoService } from 'src/app/modules/shared/service/ipo.service';

@Component({
  selector: 'app-upcomingipo',
  templateUrl: './upcomingipo.component.html',
  styleUrl: './upcomingipo.component.scss',
})
export class UpcomingipoComponent {
  upcomingIPOdata: any[] = [];

  constructor(private ipoService: IpoService) {}

  ngOnInit() {
    this.ipoService.getUpcomingIpoTable(1).subscribe((data) => {
      this.upcomingIPOdata = data;
    });
  }
}
