<section class="BiotechEarnings text-center">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="mt-3 mb-3">
          <h1 class="main-heading text-white m-0 me-3">PDUFA Calendar</h1>
          <p class="sub-heading text-center text-white m-0">
            Keep up-to-date with upcoming drug approval dates and key regulatory
            events using our PDUFA Calendar to strategize your investments.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

@if (!isLoading) {
<div class="container p-1 mb-3">
  <div class="row">
    <div class="col-lg-7">
      <div class="calendar-container">
        <div class="header">
          <div class="d-flex align-items-center">
            <span class="yearfont">{{ currentMonth | date : " yyyy" }}</span>
            <span class="monthfont">{{ currentMonth | date : "MMMM" }}</span>
            <!-- <input *ngIf="!modalDisplayStyle" type="month" class="border-0 p-0 month-picker rang-font"
                (click)="selectdateforcalendar()" (change)="onMonthYearChange($event)"
                [value]="currentMonth | date : 'yyyy-MM'" id="startMonth" /> -->
            <img
              (click)="toggle()"
              width="30px"
              class="cursorpointer"
              src="/assets/images/calender-img.webp"
              alt=""
            />

            @if(!modalDisplayStyle){
            <input
              class="hide_date_input"
              (click)="selectdateforcalendar()"
              [(ngModel)]="dateModel"
              type="text"
              bsDatepicker
              [bsConfig]="bsConfig"
              (ngModelChange)="onMonthYearChange($event)"
              id="startMonth"
            />
            }
          </div>
          <div>
            <button class="prev-btn ms-2 me-3" (click)="prevMonth()">
              <img
                width="20px"
                src="/assets/images/left-arrow-icon.png"
                alt=""
              />
            </button>
            <button class="next-btn me-3" (click)="nextMonth()">
              <img
                width="20px"
                src="/assets/images/right-arrow-icon.png"
                alt=""
              />
            </button>
          </div>
        </div>

        <div class="weekdays">
          @for (day of weekdays; track $index) {
          <div>{{ day }}</div>
          }
        </div>

        <div
          class="days cursorpointer"
          (click)="selectedDate ? getSelectedDayData(selectedDate) : null"
        >
          @for (blank of getEmptyDaysBeforeFirstDay(); track $index) {
          <div></div>
          } @for (day of calendarDays; track $index) {

          <div class="calendar-box" (click)="selectDate(day);scrollToSection(datagetscroll)">
            <p
              [ngClass]="{ today: isToday(day), d: isSelected(day) }"
              class="d-flex getDate px-2 m-1"
              style="margin-bottom: 0"
            >
              {{ day.getDate() }}
            </p>
            <span class="text-center">
              @for (stock of getMatchingStocks(day) | slice:0:1; track $index) {
              <p class="forlep">{{ stock.ticker }}</p>
              } @if (getMatchingStocks(day).length > 1) {
              <p
                class="formobile mx-auto px-1 m-0 mt-1"
                (click)="openpopupmo(day)"
              >
                <strong>+{{ getMatchingStocks(day).length - 1 }} More</strong>
              </p>
              } @if (getMatchingStocks(day).length >= 1) {
              <p
                class="formobile-number d-none mx-auto px-1 m-0 mt-1"
                (click)="openpopupmo(day);"
              >
                <strong
                  >{{ getMatchingStocks(day).length }}<sup>+</sup>
                </strong>
              </p>
              }
            </span>
          </div>
          }
        </div>
      </div>
    </div>
    <div class="col-lg-5">
      <div>
        <div #datagetscroll class="">
          <div class="justify-content-start my-3">
            <form style="height: 30px" class="d-flex example add_m_infilter">
              <input
                [(ngModel)]="searchValuequery"
                autocomplete="off"
                (keyup)="searchFilters($event)"
                (input)="onSearchInput($event)"
                #searchVal
                type="search"
                class="input-box"
                placeholder="Search by Comapny name or Ticker"
                name="search2"
              />
              <button (click)="showDetailData()" class="btn btn-sm p-0 mx-3">
                <i
                  style="opacity: unset; font-size: 20px"
                  class="fa-solid fa-rotate-right"
                ></i>
              </button>
            </form>
            @if (suggection?.length > 0) {
            <ul class="list-unstyled m-0">
              @for (suggestion of suggection; track $index) {
              <li class="suggestion-item p-0 cursorpointer">
                <div
                  (click)="getQueryData(suggestion.symbol)"
                  class="d-flex filter-bottom justify-content-between px-2"
                >
                  <span>
                    @if (suggestion.logoid !== '') {
                    <img
                      width="25px"
                      [src]="basePath + suggestion.logoid + '.svg'"
                    />
                    }@else {
                    <span
                      style="
                        width: 25px !important;
                        height: 25px !important;
                        margin: 0 !important;
                      "
                      class="avatarcss"
                      >{{ suggestion.name | initialLetter }}</span
                    >
                    }
                  </span>
                  <p
                    class="text-start text-dark filter-font-text mb-1 border-1 me-4"
                  >
                    {{ suggestion.name }}
                  </p>
                  <p class="text-end text-dark filter-font-text mb-1">
                    {{ suggestion.symbol }}
                  </p>
                </div>
              </li>
              }
            </ul>
            }
          </div>
          <div [ngClass]="pdufascrollData">
            <div>
              @for (calenderData of detailData; track $index) {
              <div class="box-border mb-2">
                <div class="d-grid calendar-month-data">
                  <div class="left-border">
                    <div class="d-flex align-items-center">
                      @if (calenderData.logoid !== '') {
                      <img
                        width="40px"
                        [src]="basePath + calenderData.logoid + '.svg'"
                        class="company-hover"
                      />
                      }@else {
                      <span class="avatarcss mb-0">{{
                        calenderData.name | initialLetter
                      }}</span>
                      }

                      <div class="ms-2">
                        <h5 style="font-size: 18px" class="m-0">
                          {{ calenderData.name }}
                        </h5>
                        <p class="pdufa-symbol m-0">
                          <a
                            href="{{ clientUrl }}/company/{{
                              calenderData.ticker | uppercase
                            }}"
                            class="link-ticker"
                          >
                            {{ calenderData.ticker }}</a
                          >
                        </p>
                      </div>
                    </div>
                    <div class="mt-2 d-flex align-items-center">
                      <i class="fas fa-calendar-alt"></i>
                      <p class="m-0 ms-2 catdate-text">
                        {{ calenderData.catdate }}
                      </p>
                    </div>
                    <div class="mt-2">
                      <p class="m-0 calnder-text">
                        <span
                          class=""
                          style="color: var(--clr-primary); font-weight: 600"
                          >Drug Name : </span
                        >{{ calenderData.drugname }}
                      </p>
                    </div>
                    <div class="mt-2">
                      <p class="m-0 calnder-text">
                        <span
                          class=""
                          style="color: var(--clr-primary); font-weight: 600"
                          >Stage : </span
                        >{{ calenderData.stage }}
                      </p>
                    </div>
                    <div class="mt-2">
                      <p class="m-0 calnder-text">
                        <span
                          class=""
                          style="color: var(--clr-primary); font-weight: 600"
                          >Catalyst : </span
                        >{{ calenderData.catalyst }}
                      </p>
                    </div>
                    <div class="mt-2">
                      <p class="m-0 calnder-text">
                        <span
                          class=""
                          style="color: var(--clr-primary); font-weight: 600"
                          >Treatment : </span
                        >{{ calenderData.treatment }}
                      </p>
                    </div>
                    <div class="mt-2">
                      @if (calenderData.description.length > 0) {
                      <p class="m-0 calnder-text">
                        <span
                          style="color: var(--clr-primary); font-weight: 600"
                          >Description : </span
                        >{{ calenderData.description }}
                      </p>
                      }@else {
                      <p class="m-0 calnder-text">
                        <span
                          style="color: var(--clr-primary); font-weight: 600"
                          >Description : </span
                        >-
                      </p>
                      }
                    </div>
                  </div>
                </div>
              </div>
              }@empty {
              <h5
                _ngcontent-ng-c489926447=""
                class="no_record text-center"
                style="color: var(--clr-red); margin-top: 250px"
              >
              No Record Found on {{selectedDate | dateformate}} !
              </h5>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
}@else {
  <div class="container">

    <ngx-skeleton-loader
      [theme]="{ height: '500px', 'border-radius': '4px', 'margin-top': '20px' }"
      count="1"
    >
    </ngx-skeleton-loader>
  </div>
}

<app-loginpop-up
  [displayStyle]="modalDisplayStyle"
  (myout)="GetChildData($event)"
></app-loginpop-up>
