<div class="container">
  <div class="mt-3 mb-3">
    <h1 class="main-heading text-center head-text">Terms of Service</h1>
  </div>
  <p class="sub-heading text-center">
    Welcome to BiopharmaWatch. These Terms of Service ("Terms") govern your
    access to and use of our services, including our website, email newsletters,
    software applications, social media channels, and other products or services
    (collectively, "Services"). By accessing or using our Services, you agree to
    be bound by these Terms.
  </p>
</div>
<div class="container">
  <ul class="p-0">
    <li>
      <p>
        <strong class="main-heading our-text">1.Acceptance of Terms :</strong>
        By using BiopharmaWatch's Services, you confirm that you have read,
        understood, and agree to these Terms. If you do not agree with any of
        these Terms, you should not use our Services.
      </p>
    </li>
    <li>
      <p>
        <strong class="main-heading our-text">2.Service Description :</strong>
        BiopharmaWatch provides a platform for investment information and
        analysis in the biotech and pharmaceutical sectors. Our Services are
        intended to provide general information and should not be considered as
        financial advice or recommendations.
      </p>
    </li>
    <li>
      <p>
        <strong class="main-heading our-text">3.User Obligations :</strong>
        You agree to use our Services only for lawful purposes and in a manner
        that does not infringe the rights of or restrict or inhibit the use and
        enjoyment of this Service by any third party.
      </p>
    </li>
    <li>
      <p>
        <strong class="main-heading our-text">4.Intellectual Property :</strong>
        All content on BiopharmaWatch, including text, graphics, logos, and
        software, is the property of BiopharmaWatch or its licensors and is
        protected by international copyright and intellectual property laws.
      </p>
    </li>
    <li>
      <p>
        <strong class="main-heading our-text">5.User Accounts :</strong>
        When you create an account with us, you must provide accurate and
        complete information. You are responsible for maintaining the
        confidentiality of your account and password.
      </p>
    </li>
    <li>
      <p>
        <strong class="main-heading our-text">6.Disclaimers :</strong>
        BiopharmaWatch provides its Services on an "as is" and "as available"
        basis. We do not guarantee the accuracy, completeness, or timeliness of
        information provided and are not responsible for any errors or
        omissions.
      </p>
    </li>
    <li>
      <p>
        <strong class="main-heading our-text"
          >7.Limitation of Liability :</strong
        >
        To the fullest extent permitted by applicable law, BiopharmaWatch shall
        not be liable for any indirect, incidental, special, consequential, or
        punitive damages, or any loss of profits or revenues, whether incurred
        directly or indirectly.
      </p>
    </li>
    <li>
      <p>
        <strong class="main-heading our-text"
          >8.Modifications to the Terms :</strong
        >
        BiopharmaWatch reserves the right to modify these Terms at any time.
        Your continued use of the Services after any such modification
        constitutes your acceptance of the new Terms.
      </p>
    </li>
    <li>
      <p>
        <strong class="main-heading our-text">9.Termination :</strong>
        We may terminate or suspend access to our Services immediately, without
        prior notice or liability, for any reason whatsoever, including, without
        limitation, if you breach the Terms.
      </p>
    </li>
    <li>
      <p>
        <strong class="main-heading our-text">10.Governing Law :</strong>
        These Terms shall be governed by and construed in accordance with the
        laws of the jurisdiction in which BiopharmaWatch is based, without
        regard to its conflict of law provisions.
      </p>
    </li>
    <li>
      <p>
        <strong class="main-heading our-text"
          >11.Feedback and Contact Information :</strong
        >
        Any feedback you provide on our Services is non-confidential and shall
        become the sole property of BiopharmaWatch. For any questions about
        these Terms, please contact our support team.
      </p>
    </li>
  </ul>
</div>
