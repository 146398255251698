<div class="d-flex justify-content-between m-3">
    <h3>Fda calender Admin</h3>
    <div class="d-flex">
        <form style="height: 30px;" class="example d-flex add_m_infilter">
            <input style="width: 300px;" [(ngModel)]="searchValuequery" autocomplete="off"
                (keyup)="searchFilters($event)" (input)="onSearchInput($event)" #searchVal type="search"
                class="input-box form-control" placeholder="Search by Comapny name or Ticker" name="search2">
            <button (click)="searchFiltersonclick(searchValuequery)" class="searchquerybtn" type="button">
                @if (isFilterBtnSearch) {
                <i style="color: white;" class="fa fa-spin fa-spinner"></i>

                }@else {
                <i class="fa-solid fa-magnifying-glass"></i>
                }
            </button>
            <button (click)="fdaData()" class="btn btn-warning mx-2 py-0">Reset</button>
        </form>
        @if (suggection?.length > 0) {
        <ul style="height: 300px; width: 300px; overflow: auto;" class="list-unstyled m-0">
            @for (suggestion of suggection; track $index) {
            <li class="suggestion-item  p-0 cursorpointer">
                <div (click)="searchFiltersonclick(suggestion.ticker)"
                    class="d-flex filter-bottom justify-content-between px-2">
                    <p class="text-start text-dark filter-font-text mb-1 border-1 me-4">{{ suggestion.name }}</p>
                    <p class="text-end text-dark filter-font-text mb-1">{{ suggestion.ticker }}</p>
                </div>
            </li>
            }
        </ul>
        }
    </div>
    <button (click)="showtablerow()" class="btn btn-success">
        @if (showtable) {
        <p class="m-0">Show Table</p>

        }@else {

        <p class="m-0">Insert Records</p>
        }
    </button>
</div>


@if (showtable) {
<div class="container mt-4">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <table class="table">
            <thead>
                <tr>
                    <th>Ticker</th>
                    <th>Name</th>
                    <th>Catalyst</th>
                    <th>Catalyst Date</th>
                    <th>Drug Name</th>
                    <th>Stage</th>
                    <th>Treatment</th>
                    <th>Description</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody formArrayName="items">
                @for (item of items.controls; track $index) {
                <tr [formGroupName]="$index">
                    <td><input type="text" formControlName="ticker" class="form-control"></td>
                    <td><input type="text" formControlName="name" class="form-control"></td>
                    <td><input type="text" formControlName="catalyst" class="form-control"></td>
                    <td><input type="text" formControlName="catdate" class="form-control"></td>
                    <td><input type="text" formControlName="drugname" class="form-control"></td>
                    <td><input type="text" formControlName="stage" class="form-control"></td>
                    <td><input type="text" formControlName="treatment" class="form-control"></td>
                    <td><textarea formControlName="description" class="form-control"></textarea></td>
                    <td><button type="button" (click)="removeItem($index)" class="btn btn-danger">Remove</button></td>
                </tr>
                }
            </tbody>
        </table>
        <button type="button" (click)="addItem()" class="btn btn-primary me-3">Add Item</button>
        <button type="submit" class="btn btn-success">Submit</button>
    </form>
</div>

}@else {
<table class="table">
    <thead class="text-uppercase">
        <tr>
            <th scope="col">#</th>
            <th scope="col">ticker</th>
            <th scope="col">name</th>
            <th scope="col">catdate</th>
            <th scope="col">drugname</th>
            <th scope="col">stage</th>
            <th scope="col">catalyst</th>
            <th scope="col">treatment</th>
            <th scope="col">description</th>
            <th scope="col">edit</th>
            <th scope="col">delete</th>
        </tr>
    </thead>
    <tbody>
        @for (item of admin; track $index) {
        <tr>
            <th scope="row">{{$index + 1}}</th>
            <td>{{item.ticker}}</td>
            <td>{{item.name}}</td>
            <td>{{item.catdate}}</td>
            <td>{{item.drugname}}</td>
            <td>{{item.stage}}</td>
            <td>{{item.catalyst}}</td>
            <td>{{item.treatment}}</td>
            <td>{{item.description}}</td>
            <td>
                <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal"
                    (click)="editData(item)">Edit</button>
            </td>
            <td>
                <button class="btn btn-danger" (click)="deleteRecord(item._id)">Delete</button>
            </td>
        </tr>
        }
    </tbody>
</table>
}






<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">FDA-ADMIN</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form>

                    <div class="mb-3">

                        <label for="ticker" class="form-label">Id</label>
                        <input type="text" class="form-control" disabled id="catId" [(ngModel)]="catalystId"
                            name="catId">
                    </div>

                    <div class="mb-3">
                        <label for="ticker" class="form-label">Ticker</label>
                        <input type="text" class="form-control" disabled id="ticker" [(ngModel)]="ticker" name="ticker">
                    </div>

                    <div class="mb-3">
                        <label for="name" class="form-label">Name</label>
                        <input type="text" class="form-control" disabled id="name" [(ngModel)]="name" name="name">
                    </div>
                    <div class="mb-3">
                        <label for="catdate" class="form-label">Catdate</label>
                        <input type="text" class="form-control" id="catdate" [(ngModel)]="catdate" name="catdate">
                    </div>
                    <div class="mb-3">
                        <label for="drugname" class="form-label">Drugname</label>
                        <input type="text" class="form-control" id="drugname" [(ngModel)]="drugname" name="drugname">
                    </div>
                    <div class="mb-3">
                        <label for="stage" class="form-label">Stage</label>
                        <input type="text" class="form-control" id="stage" [(ngModel)]="stage" name="stage">
                    </div>
                    <div class="mb-3">
                        <label for="catalyst" class="form-label">Catalyst</label>
                        <input type="text" class="form-control" id="catalyst" [(ngModel)]="catalyst" name="catalyst">
                    </div>
                    <div class="mb-3">
                        <label for="treatment" class="form-label">Treatment</label>
                        <input type="text" class="form-control" id="treatment" [(ngModel)]="treatment" name="treatment">
                    </div>
                    <div class="mb-3">
                        <label for="description" class="form-label">Description</label>
                        <!-- <input type="text" class="form-control" id="description" name="description"> -->
                        <textarea class="form-control" id="description" name="description" [(ngModel)]="description"
                            cols="30" rows="10"></textarea>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="updateChanges()">Save
                    changes</button>
            </div>
        </div>
    </div>
</div>