<table class="table table-fixed align-middle mb-0 bg-white p-0">
  <thead>
    <tr class="text-uppercase table-header">
      <th *ngFor="let column of columns">{{ column.name }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of data">
      <td *ngFor="let column of columns">
        <ng-container *ngIf="column.isMultiValue; else singleValue">
          <div *ngFor="let value of row[column.valuename]">{{ value }}</div>
        </ng-container>
        <ng-template #singleValue>
          @if (column.isFunction) {
          <div
            class="cursorpointer"
            (click)="indiDetails(row[column.valuename])"
          >
            <a
              class="text-dark"
              href="{{ clientUrl }}/individualfund/{{ row[column.valuename] }}"
              >{{ row[column.valuename] }}</a
            >
          </div>
          }@else if (column.isFunctionCompany) {
          <div
            class="cursorpointer"
            (click)="companyDetails(row[column.valuename])"
          >
            <a
              class="text-dark"
              href="{{ clientUrl }}/company/{{ row[column.valuename] }}"
              >{{ row[column.valuename] }}</a
            >
          </div>
          } @else{
          {{ row[column.valuename] }}
          }
        </ng-template>
      </td>
    </tr>
  </tbody>
</table>

<!--  -->

<!-- // comman table code -->

<!-- 
   -->
