import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap, throwError } from 'rxjs';
import { forgotPassword } from '../model/forgotpassword';
import { environment } from 'src/environments/environment';
import { newForgetpassword } from '../../shared/const';
@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  constructor(private http: HttpClient) { }
  Forgetpassword(token: any, data: any) {
    return this.http.post<forgotPassword[]>(`${newForgetpassword}${token}`, data).pipe(
      tap(res => {
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error.error.message);
      })
    )
  }
}
