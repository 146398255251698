<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div style="background-color: #3b2cc6 !important;" class="modal-header">
                <h1 class="modal-title text-light fs-5" id="exampleModalLabel">Add to Portfolio</h1>
                <!-- <button type="button" class="btn-close " data-bs-dismiss="modal" aria-label="Close"></button> -->
                <i (click)="resetPortfolioInputs()" class="fa-solid fa-xmark cursorpointer" data-bs-dismiss="modal"
                    style="opacity: inherit; color: #FFF7D7;"></i>
            </div>
            <div class="modal-body">
                <div class="form-group d-flex align-items-center mb-2">
                    <label for="symbol"><b>Symbol &nbsp;</b></label>
                    <p id="symbol" class="table-text-blue m-0"> {{ portfolioTicker }}&nbsp;&nbsp;</p>
                    <label for="symbol"><b>Price &nbsp;</b></label>
                    <p id="symbol" class="table-text-blue m-0"> {{ portfolioPrice }}</p>
                </div>
                <div class="form-group mb-2">
                    <label for="cost ">Cost</label>
                    <input [(ngModel)]="portfoliocost" type="number" class="form-control" id="cost"
                        placeholder="Enter quantity (optional)">
                </div>
                <div class="form-group mb-2">
                    <label for="quantity ">Quantity</label>
                    <input [(ngModel)]="portfolioquntity" type="number" class="form-control" id="quantity"
                        placeholder="Enter quantity (optional)">
                </div>
                <div class="form-group ">
                    <label for="total">Total amount</label>
                    @if (portfoliocost && portfolioquntity) {
                    <p class="m-0" id="total"><strong>{{ portfoliocost * portfolioquntity }} $</strong></p>
                    }
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal"
                    (click)="addSymboltoPotfoliyo()" class="addportfoiliobtn">ADD</button>
            </div>
        </div>
    </div>
</div>

<app-loginpop-up [displayStyle]="modalDisplayStyle" (myout)="GetChildData($event)"></app-loginpop-up>