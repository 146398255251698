import { Component, ElementRef, Type, ViewChild } from '@angular/core';
import { FdaCalenarService } from '../../../shared/service/fda-calendar.service';
import { FDATable } from '../../../shared/models/fda-calendar';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { CompanyDetailsService } from '../../../shared/service/company-details.service';
import { VottingPriceService } from '../../../shared/service/votting-price.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FilterdataService } from '../../../shared/service/filterdata.service';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/modules/core/services/login.service';
import { ProfileService } from 'src/app/modules/core/services/profile.service';
import { DecryptionService } from 'src/app/modules/shared/service/decryption.service';
import { event } from 'jquery';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-fda-calendar',
  templateUrl: './fda-calendar.component.html',
  styleUrls: ['./fda-calendar.component.scss'],
})
export class FdaCalendarComponent {
  inputControl!: string;
  filterArray: any[] = [];

  @ViewChild('dataContainer') dataContainer!: ElementRef;

  isCollapsed: boolean = true;
  private currentPage = 1;
  isLoading = false;
  loadData!: boolean;
  isCompanyDetailsTriggered: boolean = false;
  searchingOrFiltering: boolean = true;
  Filtering: boolean = true;
  modalDisplayStyle!: boolean;
  displayStyle = 'none';
  availableColumns = [
    'TICKER',
    'PRICE',
    '30 DAYS PRICE CHANGE',
    'DRUG',
    'CATALYST DATE',
    'STAGE',
    'TREATMENT',
    'Burn Rate',
    'UPDOWN',
    'PRICE TARGET',
    'DESCRIPTION',
  ];
  selectedColumns: string[] = [];
  suggestions: string[] = [];
  list!: any[];
  selectlist: any[] = [];
  diseasesList!: any[];
  therapeuticList!: any[];
  fdaTable: FDATable[] = [];
  displayStyle2 = 'none';
  fdaForm!: FormGroup;
  plusminuspricetarget!: FormGroup;
  priceTarget!: FormGroup;
  basePath: string = 'https://s3-symbol-logo.tradingview.com/';
  socket = io(environment.socketUrl);
  index: number = 0;
  selectedColumn: string = '';
  pricingup_Down: any;
  selectedCompany: any;
  socketIO: any;
  ticker = true;
  price = true;
  thirtydaypricechange = true;
  drug = true;
  showButtons = true;
  catalystdate = true;
  stage = true;
  treatment = true;
  burn_rate = true;
  price_target = true;
  description = true;
  scrollend = true;
  catalyst = true;
  industry = true;
  hedgeFunds = true;
  predicted_iv = true;
  market_cap_basic = true;
  volume = true;
  Short_percent_of_Float = true;
  Cash_and_Cash_Equivalents = true;
  trend_voting = true;
  insiders = true;
  options = true;
  select_all = true;
  apidata: any;
  resetbtn: boolean = false;
  controls!: string;
  PriceInput: boolean = false;
  DateInput: boolean = false;
  isIndustrydownOpen!: boolean;
  selectedIndustryRange: string | null = null;
  disableDeselect: boolean = false;
  disableTheraputics: boolean = false;
  disablediseasesList: boolean = false;
  filterOpened = false;
  placeholder: string = 'Enter Value';
  inputControlName: string = 'inputControl';
  inputType: string = 'text';
  inputLabel: string = 'text';
  inputValues: any[] = [];
  stageArr: any[] = [];
  isFilterBtn = false;
  isFilterBtnResert = false;
  isFilterBtnSearch = false;
  forResetfilters = false;
  maxPrice!: number;
  minPrice!: number;
  dateEnd!: any;
  dateStart!: any;
  filterActive = false;
  showPrice = false;
  showDate = false;
  showStage = false;
  ChangeinPrice = false;
  showloginWarning = false;
  toggle: boolean = true;
  // isRotated = false;
  isDropdownOpen: { [key: string]: boolean } = {};
  selectedPriceRange: string | null = null;
  selectedChangePriceRange: string | null = null;
  selectedDateRange: any = null;
  minCustomValue: number | undefined;
  searchValuequery: string = '';
  maxCustomValue: number | undefined;

  isCustomChangePriceRangeSelected: boolean = false;
  isCustomRangeSelected: boolean = false;
  isCustomDateRangeSelected: boolean = false;
  showpriceDropDown!: boolean;
  showchangepriceDropDown!: boolean;
  isdateDropdownOpen!: boolean;
  isstageDropdownOpen!: boolean;
  filterpage = 1;
  filterResData: any;
  apitype = 'defualt';
  FilterObj: any;
  sortKeyVal = '';
  sortNumber = 1;
  arrowclass = 'fa-arrow-up';
  isLoadTableDataLoader!: boolean;
  BiotechnologyData!: any[];
  MedicalDeviceData!: any[];
  DiagnosticsData!: any[];
  DrugData!: any[];
  totalDatalength = 0;
  selectedDataColor!: string;
  selectedchangeColor!: string;
  selectedDateColor!: string;
  selectedDiseasesColor!: string;
  selectedTherapeuticColor!: string;
  selectedStageColor!: string;
  selectedindustryColor!: string;
  selectedEventColor!: string;
  selectedValue: string | null = null;
  selectedStages: any | null = null;
  selectedDiseases: any | null = null;
  selectedTherapeutic: any | null = null;
  selectedValueinchange: string | null = null;
  selectedValueinDate: string | null = null;
  selectedValueinindustry: string | null = null;
  selectedValueinEvent: string | null = null;
  minCustomchange!: number | null;
  maxCustomchange!: number | null;
  minCustomDate!: number;
  maxCustomDate!: number;
  isFilter = false;
  isSort = false;
  arrowname: string = 'fa-arrow-up';
  arrowticker: string = 'fa-arrow-up';
  arrowprice: string = 'fa-arrow-up';
  arrowdrug: string = 'fa-arrow-up';
  arrowcatadate: string = 'fa-arrow-up';
  arrowstage: string = 'fa-arrow-up';
  // arrowtreatment: string = "fa-arrow-up"
  suggection: any;
  profileId: any;
  id: any;
  portfoliomatchTicker: any[] = [];
  highlightarrow: any;
  portfoliocost: number | undefined;
  portfolioTicker: any;
  portfolioPrice: any;
  portfolioquntity: number | undefined;
  portfoliototal: any;
  lockedArray: any[] = [];
  productId: number | undefined;
  btnLoading: any;
  // portfolioholdings: any;
  // Price_Target_Input!: number
  nm: any = {
    Ticker: 'ticker',
    Price: 'close',
    'Market Capital': 'market_cap_basic',
    Volume: 'volume',
    'Short %': 'Short_percent_of_Float',
    'Live-Cash': 'Cash_and_Cash_Equivalents',
    'Hedge Funds': 'hedgeFunds',
    'Event Type': 'catalyst',
    Drug: 'drugname',
    'Catalyst Date': 'catdate',
    Stage: 'stage',
    Treatment: 'treatment',
    'Burn Rate': 'Monthly_Burn_Rate',
    Description: 'description',
    Industry: 'Industry',
    Insiders: 'avgInsiderTransactions',
    Options: 'option',
    'Predicted iv': 'predicted_iv',
  };

  clientUrl = environment.clientUrl;

  constructor(
    private toaster: ToastrService,
    private fdacalendarservice: FdaCalenarService,
    private formBuilder: FormBuilder,
    private vottingprice: VottingPriceService,
    private compamyDetails: CompanyDetailsService,
    private router: Router,
    private filter: FilterdataService,
    private decryptionService: DecryptionService
  ) {
    this.fdaForm = this.formBuilder.group({
      addform: this.formBuilder.array([]),
    });

    this.list = [
      { name: 'Ticker', checked: true },
      { name: 'Price', checked: true },
      { name: '30 Days Price Change', checked: true },
      { name: 'Market Capital', checked: true },
      { name: 'Volume', checked: true },
      { name: 'Short %', checked: true },
      { name: 'Live-Cash', checked: true },
      { name: 'Hedge Funds', checked: true },
      { name: 'Event Type', checked: true },
      { name: 'Drug', checked: true },
      { name: 'Catalyst Date', checked: true },
      { name: 'Trend Voting', checked: true },
      { name: 'Stage', checked: true },
      { name: 'Treatment', checked: true },
      { name: 'Burn Rate', checked: true },
      { name: 'Description', checked: true },
      { name: 'Industry', checked: true },
      { name: 'Insiders', checked: true },
      { name: 'Options', checked: true },
      { name: 'Predicted iv', checked: true },
    ];

    // this.selectlist = [
    //   { name: 'Phase 1', checked: false },
    //   { name: 'Phase 1/a', checked: false },
    //   { name: 'Phase 1/b', checked: false },
    //   { name: 'Phase 1/2', checked: false },
    //   { name: 'Phase 2a', checked: false },
    //   { name: 'Phase 2', checked: false },
    //   { name: 'Phase 2b', checked: false },
    //   { name: 'Phase 2/3', checked: false },
    //   { name: 'Phase 3', checked: false },
    //   { name: 'Preclinical', checked: false },
    //   { name: 'NDA Filing', checked: false },
    //   { name: 'sNDA Filing', checked: false },
    //   { name: 'BLA Filing', checked: false },
    //   { name: 'PDUFA', checked: false },
    //   { name: 'Approved', checked: false },
    //   { name: 'CRL', checked: false },
    //   { name: 'Phase 1a/1b', checked: false },
    //   { name: 'Orphan Drug Designation', checked: false },
    //   { name: 'Investigational New Drug(IND)', checked: false },
    //   { name: 'NDA Submission', checked: false },
    //   { name: 'PDUFA Adcomm', checked: false },
    // ];

    this.diseasesList = [
      { name: 'Multiple Sclerosis', checked: false },
      { name: 'Autoimmune Diseases', checked: false },
      { name: 'Genetic Disorders', checked: false },
      { name: 'Lymphoma', checked: false },
      { name: "Parkinson's Disease", checked: false },
      { name: 'Hepatitis', checked: false },
      { name: 'Heart Disease', checked: false },
      { name: "Alzheimer's Disease", checked: false },
      { name: 'Diabetes', checked: false },
      { name: 'HIV/AIDS', checked: false },
      { name: 'Breast Cancer', checked: false },
      { name: 'Lung Cancer', checked: false },
      { name: 'Leukemia', checked: false },
    ];

    this.therapeuticList = [
      { name: 'Cardiovascular Disease', checked: false },
      { name: 'Dermatology', checked: false },
      { name: 'Endocrinology/Metabolism/Bone', checked: false },
      { name: 'Gastroenterology', checked: false },
      { name: 'Infectious Disease (viral)', checked: false },
      { name: 'Neurology', checked: false },
      { name: 'Oncology', checked: false },
      { name: 'Ophthalmology', checked: false },
      { name: 'Renal Disease', checked: false },
      { name: 'Analgesia/Anesthesiology/Anti-inflammatory', checked: false },
      { name: 'Psychiatry', checked: false },
      { name: 'Addiction', checked: false },
      { name: 'Infectious Disease (non viral)', checked: false },
      { name: 'Hematology/Coagulation', checked: false },
      { name: 'Pulmonary', checked: false },
      { name: 'Medical Imaging', checked: false },
      { name: 'Rheumatology', checked: false },
      { name: 'Allergy', checked: false },
      { name: 'Immunomodulators', checked: false },
    ];

    this.BiotechnologyData = [
      'Biotechnology',
      'Tobacco',
      'Real Estate Services',
      'Chemicals',
      ' N/A',
      'Farm Products',
      'Food Distribution',
      'Packaged Foods',
    ];

    this.MedicalDeviceData = [
      'Medical Devices',
      'Medical Instruments & Supplies',
      'Electronic Components',
      'Scientific & Technical Instruments',
      'Health Information Services',
      'Medical Care Facilities',
      'Furnishings',
      'Fixtures & Appliances',
      'Medical Distribution',
    ];

    this.DiagnosticsData = ['Diagnostics & Research'];

    this.DrugData = [
      'Drug Manufacturers—General',
      'Drug Manufacturers—Specialty & Generic',
      'Household & Personal Products,Pharmaceutical Retailers',
    ];
  }

  ngOnInit(): void {
    this.plusminuspricetarget = this.formBuilder.group({
      up_button: ['Up', Validators.required],
      down_button: ['Down', Validators.required],
    });

    this.priceTarget = this.formBuilder.group({
      price_up_button: [
        '',
        [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)],
      ],
    });

    this.stagelist();
    this.shareIndividualCheckedList(this.suggestions);
    this.returnLogin();
    this.fdaTableData();
    this.tblcompaniesScoket();
    this.updownSocketData();
    // this.getPortfolioTickers();
  }

  stagelist() {
    this.fdacalendarservice.getStageValues().subscribe({
      next: (res: any) => {
        let list = JSON.parse(this.decryptionService.decrypt(res.result));

        list.forEach((item: any) => {
          let items = {
            name: item,
            cheked: false,
          };
          if(items.name !== 'Preclinical') {
            this.selectlist.push(items);
          }
        });
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  sde() {
    let sl = this.list
      .filter((item) => item.checked)
      .map((item) => this.nm[item.name] || item.name.toLowerCase());
    sl.unshift('name');
    return () => {
      return this.fdaTable.map((item: any) =>
        sl.reduce((acc: any, key: any) => {
          if (key in item) acc[key] = item[key];
          return acc;
        }, {})
      );
    };
  }

  exportToExcel() {
    this.btnLoading = true;
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.sde()());
    const workbook: XLSX.WorkBook = {
      Sheets: { Data: worksheet },
      SheetNames: ['Data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, 'FdaCalendarData');
    setTimeout(() => {
      this.btnLoading = false;
    }, 700);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(data, `${fileName}${new Date()}.xlsx`);
  }

  validateInput(event: any) {
    const input = event.target.value;
    const numericInput = input.replace(/[^0-9.]/g, '');
    this.priceTarget.patchValue({ price_up_button: numericInput });
  }

  shareCheckedList(item: any[]) {}

  selectAll() {
    if (!this.disableDeselect) {
      this.selectlist.forEach((item) => (item.checked = true));
    }
  }

  selectTheraputics() {
    if (!this.disableTheraputics) {
      this.therapeuticList.forEach((item) => (item.checked = true));
    }
  }

  selectDiseases() {
    if (!this.disableDeselect) {
      this.diseasesList.forEach((item) => (item.checked = true));
    }
  }

  deselectAll() {
    if (!this.disablediseasesList) {
      this.selectlist.forEach((item) => (item.checked = false));
    }
  }

  deselectTheraputics() {
    if (!this.disableTheraputics) {
      this.therapeuticList.forEach((item) => (item.checked = false));
    }
  }

  deselectDiseases() {
    if (!this.disablediseasesList) {
      this.diseasesList.forEach((item) => (item.checked = false));
    }
  }

  toggleSelectAll(event: any) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.selectAll();
    } else {
      this.deselectAll();
    }
    this.toggleDropdown('Stage');
    this.pushFilterstage();
  }

  toggleTheraputics(event: any) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.selectTheraputics();
    } else {
      this.deselectTheraputics();
    }
    this.toggleDropdown('Therapeutic_area');
    this.pushFilterTherapeutic();
  }

  toggleDiseasesSelect(event: any) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.selectDiseases();
    } else {
      this.deselectDiseases();
    }
    this.toggleDropdown('Specific_Diseases');
    this.pushFilterDiseases();
  }

  pushFilterstage() {
    this.selectedStages = this.selectlist
      .filter((item) => item.checked)
      .map((item) => item.name);
    if (this.selectedStages.length > 0) {
      this.selectedStageColor = 'selected-button';
    } else {
      this.selectedStageColor = '';
    }
    if(this.selectedStages.includes('Pre-clinical')) {
      this.filterArray.push({ stage: [...this.selectedStages, 'Preclinical'] });
    } else {
      this.filterArray.push({ stage: this.selectedStages });
    }
    this.saveFilters();
  }

  pushFilterTherapeutic() {
    this.selectedTherapeutic = this.therapeuticList
      .filter((item) => item.checked)
      .map((item) => item.name);
    if (this.selectedTherapeutic.length > 0) {
      this.selectedTherapeuticColor = 'selected-button';
    } else {
      this.selectedTherapeuticColor = '';
    }
    this.filterArray.push({ Therapeutic_area: this.selectedTherapeutic });
    this.saveFilters();
  }

  pushFilterDiseases() {
    this.selectedDiseases = this.diseasesList
      .filter((item) => item.checked)
      .map((item) => item.name);
    if (this.selectedDiseases.length > 0) {
      this.selectedDiseasesColor = 'selected-button';
    } else {
      this.selectedDiseasesColor = '';
    }
    this.filterArray.push({ Specific_Diseases: this.selectedDiseases });
    this.saveFilters();
  }
  //   pushFilterstage(data: any) {
  //   this.filterArray.push({ stage: data.target.value })
  // }

  isSelectedAnyCheckbox(): boolean {
    return this.selectlist.some((item) => item.checked);
  }

  toggleDropdown(key: string): void {
    this.isDropdownOpen[key] = !this.isDropdownOpen[key];
  }

  scrollLeft() {
    this.dataContainer.nativeElement.scrollLeft -= 300;
  }

  scrollRight() {
    this.dataContainer.nativeElement.scrollLeft += 300;
  }

  checkScrollable() {
    const container = this.dataContainer.nativeElement;
    if (container.scrollWidth > container.clientWidth) {
      this.showButtons = true;
    } else {
      this.showButtons = false;
    }
  }

  shareIndividualChecked(item: {}) {
    let value = Object.values(item)[1];
    this.stageArr.push(value);
    const stage = this.stageArr;
    const obj = {
      stage,
    };
    this.filterArray.push(obj);
  }

  shareIndividualCheckedList(item: {}) {
    let value = Object.values(item)[1];

    switch (value) {
      case 'Ticker':
        this.ticker = !this.ticker;
        break;
      case 'Price':
        this.price = !this.price;
        break;
      case '30 Days Price Change':
        this.thirtydaypricechange = !this.thirtydaypricechange;
        break;
      case 'Drug':
        this.drug = !this.drug;
        break;
      case 'Catalyst Date':
        this.catalystdate = !this.catalystdate;
        break;
      case 'Stage':
        this.stage = !this.stage;
        break;
      case 'Treatment':
        this.treatment = !this.treatment;
        break;
      case 'Burn Rate':
        this.burn_rate = !this.burn_rate;
        break;
      case 'PRICE TARGET':
        this.price_target = !this.price_target;
        break;
      case 'Description':
        this.description = !this.description;
        break;
      case 'Industry':
        this.industry = !this.industry;
        break;
      case 'Market Capital':
        this.market_cap_basic = !this.market_cap_basic;
        break;
      case 'Volume':
        this.volume = !this.volume;
        break;
      case 'Short %':
        this.Short_percent_of_Float = !this.Short_percent_of_Float;
        break;
      case 'Live-Cash':
        this.Cash_and_Cash_Equivalents = !this.Cash_and_Cash_Equivalents;
        break;
      case 'Hedge Funds':
        this.hedgeFunds = !this.hedgeFunds;
        break;
      case 'Predicted iv':
        this.predicted_iv = !this.predicted_iv;
        break;
      case 'Event Type':
        this.catalyst = !this.catalyst;
        break;
      case 'Trend Voting':
        this.trend_voting = !this.trend_voting;
        break;
      case 'Insiders':
        this.insiders = !this.insiders;
        break;
      case 'Options':
        this.options = !this.options;
        break;
      default:
        break;
    }
  }

  tblcompaniesScoket() {
    this.socket.on('tblcompaniesScoket', (res: any) => {
      if (res.operationType == 'update') {
        var Data = {
          ...res.documentKey,
          ...res.updateDescription.updatedFields,
        };
        this.fdaTable.filter((FDAtableObj: any) => {
          if (FDAtableObj._id === Data._id) {
            FDAtableObj.close = Data.close ? Data.close : FDAtableObj.close;
          }
          if (FDAtableObj._id === Data._id) {
            FDAtableObj.change = Data.change ? Data.change : FDAtableObj.change;
            this.animateField(FDAtableObj, 'change');
          }
        });
      } else {
      }
    });
    this.socket.connect();
  }

  animateField(FDAtableObj: any, field: string) {
    // Add the 'highlight' class
    FDAtableObj[field + 'Class'] = 'highlight';

    // Remove the class after the animation duration (e.g., 1 second)
    setTimeout(() => {
      FDAtableObj[field + 'Class'] = '';
    }, 1000); // Adjust this time based on the animation duration
  }

  updownSocketData() {
    this.socket.on('fdacalendarScoket', (res: any) => {
      this.fdaTable = this.fdaTable.map((FDAtableObj: any) => {
        if (FDAtableObj.ticker === res.ticker) {
          FDAtableObj.upVotesLength =
            res.upVotesLength !== undefined
              ? res.upVotesLength
              : FDAtableObj.upVotesLength;
          FDAtableObj.downVotesLength =
            res.downVotesLength !== undefined
              ? res.downVotesLength
              : FDAtableObj.downVotesLength;
        }
        return FDAtableObj;
      });
    });
    this.socket.connect();
  }

  openpopup(i: number) {
    this.displayStyle2 = 'none';
    const UserId = localStorage.getItem('UserId');
    const profiledataId = JSON.parse(UserId!);

    if (profiledataId) {
      this.displayStyle = 'block';
    } else {
      this.modalDisplayStyle = true;
    }

    this.selectedCompany = this.fdaTable[i];
  }

  targetOpenPopup(i: number) {
    this.priceTarget.controls['price_up_button'].setValue(null);
    this.displayStyle = 'none';
    const UserId = localStorage.getItem('UserId');
    const profiledataId = JSON.parse(UserId!);

    if (profiledataId) {
      this.displayStyle2 = 'block';
    } else {
      this.modalDisplayStyle = true;
    }

    this.selectedCompany = this.fdaTable[i];
  }

  closePopup() {
    this.displayStyle = 'none';
    this.displayStyle2 = 'none';
  }

  GetChildData(data: any) {
    this.modalDisplayStyle = data;
  }

  fdaTableData() {
    // if (login) {
    this.fdacalendarservice.getFdaData(this.currentPage).subscribe({
      next: (res: any) => {
        // this.isLoadTableDataLoader = false;
        this.isFilterBtnResert = false;
        this.isFilterBtn = false;
        this.currentPage = 2;

        setTimeout(() => {
          this.checkScrollable();
        });

        const decryptData = this.decryptionService.decrypt(res.result);

        const ParseDecryptedData = JSON.parse(decryptData);

        ParseDecryptedData.find((x: any) => x.portfolio)?.portfolio.forEach(
          (element: any) => {
            this.portfoliomatchTicker.push(element.ticker);
          }
        );

        this.productId = ParseDecryptedData.find(
          (x: any) => x.productId
        )?.productId;

        this.totalDatalength = ParseDecryptedData[0].total[0].count;

        this.fdaTable = ParseDecryptedData[0].data;

        // for (let i = 0; i < 2; i++) {
        //   this.lockedArray.push(this.fdaTable);
        //   this.lockedArray = this.lockedArray.flat();
        // }

        // if (!this.productId) {
        //   this.fdaTable = this.lockedArray;
        //   this.fdaTable.slice(0, 18);
        // }
      },
      error: (error) => {
        console.log('error :', error);
      },
    });
    // }
  }

  loginpage() {
    this.router.navigate(['login']);
  }

  refresh() {
    this.fdaTable = [];
    this.lockedArray = [];
    this.isLoading = false;
    this.loadData = false;

    this.currentPage = 1;
    this.arrowname = 'fa-arrow-up';
    this.arrowticker = 'fa-arrow-up';
    this.arrowprice = 'fa-arrow-up';
    this.arrowdrug = 'fa-arrow-up';
    this.arrowcatadate = 'fa-arrow-up';
    this.arrowstage = 'fa-arrow-up';
  }

  savePopup() {
    const UserId = localStorage.getItem('UserId');
    const profiledataId = JSON.parse(UserId!);

    this.pricingup_Down = this.plusminuspricetarget.value;
    const vote = this.pricingup_Down.up_button ? 1 : 0;

    this.vottingprice
      .votting_price(profiledataId, this.selectedCompany.ticker, vote)
      .subscribe({
        next: (res: any) => {
          this.toaster.success(res.message);
          this.closePopup();
        },
        error: (error) => {
          this.toaster.error(error.error.message);
          this.closePopup();
        },
      });
  }

  priceSavePopup() {
    const UserId = localStorage.getItem('UserId');
    const profiledataId = JSON.parse(UserId!);

    this.pricingup_Down = this.priceTarget.value;
    const vote = this.pricingup_Down.price_up_button ? 1 : 0;
    const selectPrice = this.priceTarget.controls['price_up_button'].value;

    this.vottingprice
      .votting_priceTarget(
        profiledataId,
        this.selectedCompany.ticker,
        selectPrice,
        vote
      )
      .subscribe({
        next: (res: any) => {
          this.toaster.success(res.message);
          this.closePopup();
        },
        error: (error) => {
          this.toaster.error(error.error.message);
          this.closePopup();
        },
      });
  }

  clearSearch() {
    this.fdaForm.reset();
    this.fdaTableData();
  }

  Select_All_checkbox() {
    this.toggle = !this.toggle;
    this.list.forEach((item) => {
      if (this.toggle) {
        this.ticker = true;
        this.price = true;
        this.thirtydaypricechange = true;
        this.drug = true;
        this.catalyst = true;
        this.catalystdate = true;
        this.stage = true;
        this.treatment = true;
        this.burn_rate = true;
        this.price_target = true;
        this.description = true;
        this.industry = true;
        this.hedgeFunds = true;
        this.predicted_iv = true;
        this.market_cap_basic = true;
        this.volume = true;
        this.Short_percent_of_Float = true;
        this.Cash_and_Cash_Equivalents = true;
        this.trend_voting = true;
        this.insiders = true;
        this.options = true;
        this.list.forEach((item) => (item.checked = true));
      } else {
        this.ticker = false;
        this.price = false;
        this.thirtydaypricechange = false;
        this.drug = false;
        this.catalyst = false;
        this.catalystdate = false;
        this.stage = false;
        this.treatment = false;
        this.burn_rate = false;
        this.price_target = false;
        this.description = false;
        this.industry = false;
        this.hedgeFunds = false;
        this.predicted_iv = false;
        this.market_cap_basic = false;
        this.volume = false;
        this.Short_percent_of_Float = false;
        this.Cash_and_Cash_Equivalents = false;
        this.trend_voting = false;
        this.insiders = false;
        this.options = false;

        this.list.forEach((item) => (item.checked = false));
      }
    });
  }

  // searchFilters(query: any) {
  //   if (query == '') {
  //     this.toaster.warning('Enter Valid Inputs.');
  //     return;
  //   }

  //   let search =
  //   {
  //     query,
  //   }

  //   // this.isLoading = true;
  //   this.isFilterBtnSearch = true;
  //   this.forresetFilter = true;
  //   this.fdaTable = [];

  //   this.filter.fdafilterData(search).subscribe({
  //     next: (res: any) => {
  //       this.isFilterBtnSearch = false;
  //       this.forresetFilter = false;

  //       this.totalDatalength = res.length
  //       this.fdaTable = res as FDATable[];
  //       this.isLoading = true
  //     },
  //     error: (err) => {
  //       this.isFilterBtnSearch = false;
  //       this.forresetFilter = false;

  //       this.searchValuequery = ""
  //       this.fdaTable = [];
  //       this.fdaTableData();
  //       this.toaster.error(err.error.message);
  //     },
  //   });
  // }

  saveFilters() {
    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }

    const filtered = this.filterArray.filter(
      (el) => Object.keys(el).length > 0
    );
    if (filtered.length === 0) {
      this.toaster.warning('Enter Valid Inputs.');
      return;
    }
    this.refresh();
    this.isFilter = true;
    this.isFilterBtn = true;
    // this.isLoadTableDataLoader = true
    this.forResetfilters = true;
    this.apitype = 'filter';
    this.FilterObj = filtered.reduce((result, currentObject) => {
      for (var key in currentObject) {
        if (currentObject.hasOwnProperty(key)) {
          result[key] = currentObject[key];
        }
      }
      return result;
    }, {});

    this.filter.fdafilterColumn(this.currentPage, this.FilterObj).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);

        const ParseDecryptedData = JSON.parse(decryptData);
        this.fdaTable = ParseDecryptedData[0].data;

        this.totalDatalength = ParseDecryptedData[0].total[0].count;

        res.portfolioSymbols &&
          res.portfolioSymbols.forEach((element: any) => {
            this.portfoliomatchTicker.push(element.ticker);
          });

        this.isFilterBtn = false;
        // this.isLoadTableDataLoader = false

        this.currentPage = 2;
      },
      error: (err) => {
        this.isLoading = false;
        // this.isLoadTableDataLoader = false
        this.isFilterBtn = false;
        this.resetFilters();
        this.toaster.error(err.error.message);
      },
    });
  }

  onChange(value: any) {
    const selectedValue = value.target.value;
    if (selectedValue.length) {
      this.resetbtn = true;
    } else {
      this.resetbtn = false;
    }
  }

  // onScroll(event: Event) {

  //   const element = event.target as HTMLElement;
  //   const scrollPosition = element.scrollTop + element.clientHeight;
  //   const totalHeight = element.scrollHeight;

  //   if (scrollPosition >= totalHeight - 1) {

  //     if (localStorage.getItem('loggedInUser')) {
  //       this.loadData = true;
  //       if (this.apidata.length == 0) {
  //         this.loadData = false;
  //       }
  //       this.currentPage++;
  //       if (this.apiMethod == "filter") {
  //         this.scrollFilter();
  //       } else {
  //         if(!this.searchingOrFiltering){
  //           this.fdaTableData();
  //         }
  //       }

  //       //  else if (this.apiMethod == "filter") {
  //       //   this.sortFunction();
  //       // }

  //     }
  //     else {
  //       if (!this.scrollend) {
  //         this.loadData = false;
  //         let href = this.router.url;
  //         localStorage.setItem("route", href)
  //         this.modalDisplayStyle = true;
  //       }
  //     }
  //   }
  // }

  companyDetails(ticker: any, isoption: boolean) {
    this.compamyDetails.getCompanyTable(ticker).subscribe({
      next: (res: any) => {
        if (isoption) {
          this.router.navigate(['company', ticker], {
            queryParams: { section: 'optiondataSection' },
          });
        } else {
          this.router.navigate(['company', ticker]);
        }
      },
    });
  }

  loadMoreData() {
    this.isLoading = true;
    this.loadData = true;

    if (this.apitype == 'filter') {
      this.filter.fdafilterColumn(this.currentPage, this.FilterObj).subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res.result);

          const ParseDecryptedData = JSON.parse(decryptData);

          ParseDecryptedData[0].data.forEach((element: any) => {
            this.fdaTable.push(element);
          });
          this.isLoading = false;
          this.loadData = false;

          this.currentPage++;
        },
        error: (err) => {
          this.isLoading = true;
          this.loadData = false;

          this.toaster.warning('All Record displayed');
          this.apitype = 'defualt';
        },
      });
    }

    if (this.apitype == 'defualt') {
      this.fdacalendarservice.getFdaData(this.currentPage).subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res.result);

          const ParseDecryptedData = JSON.parse(decryptData);
          ParseDecryptedData[0].data.forEach((element: any) => {
            this.fdaTable.push(element);
          });
          this.isLoading = false;
          this.loadData = false;
          this.currentPage++;
        },
        error: (err) => {
          this.isLoading = true;
          this.loadData = false;
          this.toaster.warning('All Record displayed');
          this.apitype = 'defualt';
        },
      });
    }

    if (this.apitype == 'sort') {
      this.filter
        .fdaSort(this.sortKeyVal, this.sortNumber, this.currentPage)
        .subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res.result);

            const ParseDecryptedData = JSON.parse(decryptData);
            ParseDecryptedData[0].data.forEach((element: any) => {
              this.fdaTable.push(element);
            });
            this.isLoading = false;
            this.loadData = false;

            this.currentPage++;
          },
          error: (err) => {
            this.isLoading = true;
            this.loadData = false;

            this.toaster.warning('All Record displayed');
            this.apitype = 'defualt';
          },
        });
    }

    if (this.isSort) {
      this.filter
        .fdasortwithFilter(
          this.sortKeyVal,
          this.sortNumber,
          this.currentPage,
          this.FilterObj
        )
        .subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res.result);

            const ParseDecryptedData = JSON.parse(decryptData);
            this.totalDatalength = ParseDecryptedData[0].total[0].count;
            this.isLoading = false;
            this.loadData = false;

            this.currentPage++;
            ParseDecryptedData[0].data.forEach((element: any) => {
              this.fdaTable.push(element);
            });
          },
          error: (err) => {
            this.isLoading = true;
            this.loadData = false;

            this.toaster.warning('All Record displayed');
            this.apitype = 'defualt';
          },
        });
    }
  }

  assending(sortKey: string) {
    if (!this.productId) {
      this.modalDisplayStyle = true;
    } else {
      this.apitype = 'sort';

      this.sortNumber = this.sortKeyVal === sortKey ? this.sortNumber * -1 : 1;
      this.sortKeyVal = sortKey;

      this.refresh();

      switch (sortKey) {
        case 'name':
          this.arrowname =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'ticker':
          this.arrowticker =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'close':
          this.arrowprice =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'market_cap_basic':
          this.arrowdrug =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'catdate':
          this.arrowcatadate =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'stage':
          this.arrowstage =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        default:
          break;
      }

      if (this.isFilter === true) {
        // this.isLoadTableDataLoader = true;
        this.apitype = 'filtersort';
        this.isSort = true;
        this.isLoading = true;

        this.filter
          .fdasortwithFilter(
            this.sortKeyVal,
            this.sortNumber,
            this.currentPage,
            this.FilterObj
          )
          .subscribe({
            next: (res: any) => {
              const decryptData = this.decryptionService.decrypt(res.result);

              const ParseDecryptedData = JSON.parse(decryptData);

              this.totalDatalength = ParseDecryptedData[0].total[0].count;

              this.isLoading = false;

              ParseDecryptedData[0].data.forEach((element: any) => {
                this.fdaTable.push(element);
                this.currentPage = 2;
              });
              for (let i = 0; i < 2; i++) {
                this.lockedArray.push(this.fdaTable);
                this.lockedArray = this.lockedArray.flat();
              }

              if (!this.productId) {
                this.fdaTable = this.lockedArray;
              }
            },
            error: (err) => {
              this.isLoading = false;
              this.isFilterBtn = false;
              // this.isLoadTableDataLoader = false

              this.refresh();
              this.fdaTableData();
              this.toaster.error(err.error.message);
            },
          });
      } else {
        // this.isLoadTableDataLoader = true;
        this.filter
          .fdaSort(this.sortKeyVal, this.sortNumber, this.currentPage)
          .subscribe({
            next: (res: any) => {
              // this.isLoadTableDataLoader = false
              const decryptData = this.decryptionService.decrypt(res.result);

              const ParseDecryptedData = JSON.parse(decryptData);

              this.totalDatalength = ParseDecryptedData[0].total[0].count;

              ParseDecryptedData[0].data.forEach((element: any) => {
                this.fdaTable.push(element);
                this.currentPage = 2;
              });
              for (let i = 0; i < 2; i++) {
                this.lockedArray.push(this.fdaTable);
                this.lockedArray = this.lockedArray.flat();
              }

              if (!this.productId) {
                this.fdaTable = this.lockedArray;
              }
            },
            error: (err) => {
              // this.isLoadTableDataLoader = false
              this.isLoading = false;
              this.isFilterBtn = false;
              this.refresh();
              this.fdaTableData();
              this.toaster.error(err.error.message);
            },
          });
      }
    }
  }

  onScroll(event: any) {
    const element = event.target as HTMLElement;
    const scrollPosition = element.scrollTop + element.clientHeight;
    const totalHeight = element.scrollHeight;
    if (this.productId) {
      if (scrollPosition >= totalHeight - 1 && !this.isLoading) {
        if (this.currentPage != 1) {
          this.loadMoreData();
        }
      }
    } else {
      let href = this.router.url;
      localStorage.setItem('route', href);
      // this.modalDisplayStyle = true;
      if (!localStorage.getItem('accessToken')) {
        // this.modalDisplayStyle = true;
      }
    }
  }

  // popFilterPrice() {
  //   for (let key in this.filterArray) {
  //     delete this.filterArray[key].startPrice;
  //     delete this.filterArray[key].endPrice;
  //   }
  //   this.showPrice = false
  // }

  popFilterStage() {
    this.showStage = false;
  }

  popFilterChange() {
    this.ChangeinPrice = false;
  }

  selectedFilter(val: any) {
    if (val.target.value == 'Date') {
      this.showDate = true;
    }
    if (val.target.value == 'Price') {
      this.showPrice = true;
    }
    if (val.target.value == 'Stage') {
      this.showStage = true;
    }
    if (val.target.value == 'ChangeinPrice') {
      this.ChangeinPrice = true;
    }
    this.filterOpened = false;
  }

  openFilter() {
    this.filterOpened = true;
  }

  iconchange(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  returnLogin() {
    let loign = localStorage.getItem('loggedInUser');
    if (!loign) {
      this.showloginWarning = true;
    }
  }

  //pricefilter method

  selectPriceRange(selectedRange: any) {
    if (selectedRange === 'customRange') {
      this.selectedPriceRange = selectedRange;
      this.isCustomRangeSelected = true;
      this.showpriceDropDown = false;
      this.isDropdownOpen['customRange'] = true;
    } else {
      if (this.selectedPriceRange === selectedRange) {
        this.selectedPriceRange = null;
      } else {
        this.selectedPriceRange = selectedRange;
      }
      this.isCustomRangeSelected = false;
    }
  }

  pricefilterclick() {
    if (this.isDropdownOpen['customRange'] === true) {
      this.showpriceDropDown = true;
      this.isCustomRangeSelected = false;
    } else {
      this.showpriceDropDown = true;
    }
    this.isCustomDateRangeSelected = false;
    this.isCustomChangePriceRangeSelected = false;
    this.isstageDropdownOpen = false;
  }

  changefilterclick() {
    if (this.isDropdownOpen['customRangechange'] === true) {
      this.showchangepriceDropDown = true;
      this.isCustomChangePriceRangeSelected = false;
    } else {
      this.showchangepriceDropDown = true;
    }
    this.isCustomRangeSelected = false;
    this.isCustomDateRangeSelected = false;
    this.isstageDropdownOpen = false;
  }

  datefilterclick() {
    if (this.isDropdownOpen['customRangeDate'] === true) {
      this.isdateDropdownOpen = true;
      this.isCustomDateRangeSelected = false;
    } else {
      this.isdateDropdownOpen = true;
    }
    this.isCustomRangeSelected = false;
    this.isCustomChangePriceRangeSelected = false;
    this.isstageDropdownOpen = false;
  }

  pushFilterminPriceless(data: any) {
    this.filterArray.push({ close: 'less_than_1' });
    this.selectPriceRange(data);
  }

  pushFilterminPrice1t02(data: any) {
    this.filterArray.push({ startPrice: 1 }, { endPrice: 2 });

    this.selectPriceRange(data);
  }

  pushFilterminPrice2t05(data: any) {
    this.filterArray.push({ startPrice: 2 }, { endPrice: 5 });
    this.selectPriceRange(data);
  }
  pushFilterminPrice5t50(data: any) {
    this.filterArray.push({ startPrice: 5 }, { endPrice: 50 });
    this.selectPriceRange(data);
  }
  pushFilterminPrice50t100(data: any) {
    this.filterArray.push({ startPrice: 50 }, { endPrice: 100 });
    this.selectPriceRange(data);
  }

  isSelected(selectedRange: any) {
    return this.selectedPriceRange === selectedRange;
  }

  // $
  updateCustomRange() {
    if (this.minCustomValue && this.maxCustomValue) {
      this.selectedPriceRange = `${this.minCustomValue}$ to ${
        this.maxCustomValue
      }$`;
      this.selectedDataColor = 'selected-button';
    } else {
      this.selectedPriceRange = null;
      this.selectedDataColor = '';
    }
  }

  //pricechnagefilter method

  pushFilterminChangePriceless(data: any) {
    this.filterArray.push({ change: 'less_than_1' });
    this.selectChangePriceRange(data);
  }

  pushFilterminChangePrice1t02(data: any) {
    this.filterArray.push({ startChange: 1 }, { endChange: 2 });
    this.selectChangePriceRange(data);
  }

  pushFilterminChangePrice2t05(data: any) {
    this.filterArray.push({ startChange: 2 }, { endChange: 5 });
    this.selectChangePriceRange(data);
  }
  pushFilterminChangePrice5t50(data: any) {
    this.filterArray.push({ startChange: 5 }, { endChange: 50 });
    this.selectChangePriceRange(data);
  }
  pushFilterminChangePrice50t100(data: any) {
    this.filterArray.push({ startChange: 50 }, { endChange: 100 });
    this.selectChangePriceRange(data);
  }

  pushFilterChange(data: any) {
    // this.selectedchangeColor = 'selected-button';
    this.filterArray.push({ startChange: Number(data.target.value) });
    if (!this.maxCustomchange) this.filterArray.push({ endChange: 100 });
  }

  EndpushFilterChange(data: any) {
    // this.selectedchangeColor = 'selected-button';
    this.filterArray.push({ endChange: Number(data.target.value) });
    if (!this.minCustomchange) this.filterArray.push({ startChange: 0 });
  }
  // %
  updateChangePriceCustomRange() {
    if (
      this.minCustomchange && 
      this.maxCustomchange
    ) {
      this.selectedChangePriceRange = `${this.minCustomchange }% to ${
        this.maxCustomchange
      }%`;
      this.selectedchangeColor = 'selected-button';
    } else {
      this.selectedChangePriceRange = null;
      this.selectedchangeColor = '';
    }
  }

  ChangeSelected(selectedRange: any) {
    return this.selectedChangePriceRange === selectedRange;
  }

  selectChangePriceRange(selectedRange: string) {
    if (selectedRange === 'customRangechange') {
      this.selectedChangePriceRange = selectedRange;
      this.isCustomChangePriceRangeSelected = true;
      this.showchangepriceDropDown = false;
      this.isDropdownOpen['customRangechange'] = true;
    } else {
      if (this.selectedChangePriceRange === selectedRange) {
        this.selectedChangePriceRange = null;
      } else {
        this.selectedChangePriceRange = selectedRange;
      }
    }
  }

  //date filter

  Datefilterthisweek(data: any) {
    this.filterArray.push({ week: 'thisWeek' });
    this.selectDateRange(data);
  }

  Datefilterthismonth(data: any) {
    this.filterArray.push({ month: 'thisMonth' });
    this.selectDateRange(data);
  }

  Datefilternextweek(data: any) {
    this.filterArray.push({ week: 'nextWeek' });
    this.selectDateRange(data);
  }
  Datefilternextmonth(data: any) {
    this.filterArray.push({ month: 'nextMonth' });
    this.selectDateRange(data);
  }

  selectDateRange(selectedRange: string) {
    if (selectedRange === 'customRangeDate') {
      this.selectedDateRange = selectedRange;
      this.isCustomDateRangeSelected = true;
      this.isdateDropdownOpen = false;
      this.isDropdownOpen['customRangeDate'] = true;
    } else {
      if (this.selectedDateRange === selectedRange) {
        this.selectedDateRange = null;
      } else {
        this.selectedDateRange = selectedRange;
      }
    }
  }
  updateDateCustomRange() {
    if (
      this.minCustomDate !== undefined &&
      this.maxCustomDate !== undefined
    ) {
      this.selectedDateRange = `${this.minCustomDate} to ${this.maxCustomDate}`;
    }
  }

  DateSelected(selectedRange: any) {
    return this.selectedDateRange === selectedRange;
  }

  popFilterDate() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].startDate;
      delete this.filterArray[key].endDate;
    }
    this.showDate = false;
  }

  clickstagefilter() {
    this.isstageDropdownOpen = true;
    this.isCustomRangeSelected = false;
    this.isCustomChangePriceRangeSelected = false;
    this.isCustomDateRangeSelected = false;
  }
  Industryfilterclick() {
    this.isIndustrydownOpen = true;
    // this.isMarketcapDropdownOpen = false;
    this.isCustomDateRangeSelected = false;
    this.isCustomRangeSelected = false;
    this.isCustomChangePriceRangeSelected = false;
    this.isstageDropdownOpen = false;
  }

  IndustrySelected(selectedRange: any) {
    return this.selectedIndustryRange === selectedRange;
  }

  selectIndustryRange(selectedRange: string) {
    if (this.selectedIndustryRange === selectedRange) {
      this.selectedIndustryRange = null;
    } else {
      this.selectedIndustryRange = selectedRange;
    }
  }

  Biotechnology(data: any) {
    this.filterArray.push({ Industry: this.BiotechnologyData });
    this.selectIndustryRange(data);
  }

  MedicalDevice(data: any) {
    this.filterArray.push({ Industry: this.MedicalDeviceData });
    this.selectIndustryRange(data);
  }

  Diagnostics(data: any) {
    this.filterArray.push({ Industry: this.DiagnosticsData });
    this.selectIndustryRange(data);
  }

  DrugManufacturers(data: any) {
    this.filterArray.push({ Industry: this.DrugData });
    this.selectIndustryRange(data);
  }

  selectedData(data: string) {
    this.selectedPriceRange = null;
    this.minCustomValue = undefined;
    this.maxCustomValue = undefined;
    this.maxCustomchange = null;
    this.minCustomchange = null;
    this.selectedChangePriceRange = null;

    switch (data) {
      case 'less_than_1 $': {
        this.selectedValue = 'less_than_1 $';
        this.selectedDataColor = 'selected-button';
        this.filterArray.push({ close: 'less_than_1' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].startPrice;
          delete this.filterArray[key].endPrice;
        }
        this.saveFilters();
        break;
      }
      case '1$ to 2$': {
        this.selectedValue = '1$ to 2$';
        this.selectedDataColor = 'selected-button';
        this.filterArray.push({ startPrice: 1 }, { endPrice: 2 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].close;
        }
        this.saveFilters();
        break;
      }
      case '2$ to 5$': {
        this.selectedValue = '2$ to 5$';
        this.selectedDataColor = 'selected-button';
        this.filterArray.push({ startPrice: 2 }, { endPrice: 5 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].close;
        }
        this.saveFilters();
        break;
      }
      case '5$ to 50$': {
        this.selectedValue = '5$ to 50$';
        this.selectedDataColor = 'selected-button';
        this.filterArray.push({ startPrice: 5 }, { endPrice: 50 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].close;
        }
        this.saveFilters();
        break;
      }
      case '50$ to 100$': {
        this.selectedValue = '50$ to 100$';
        this.selectedDataColor = 'selected-button';
        this.filterArray.push({ startPrice: 50 }, { endPrice: 100 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].close;
        }
        this.saveFilters();
        break;
      }
      case 'less_than_1 %': {
        this.selectedValueinchange = 'less_than_1 %';
        this.selectedchangeColor = 'selected-button';
        this.filterArray.push({ change: 'less_than_1' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].startChange;
          delete this.filterArray[key].endChange;
        }
        this.saveFilters();
        break;
      }
      case '1% to 2%': {
        this.selectedValueinchange = '1% to 2%';
        this.selectedchangeColor = 'selected-button';
        this.filterArray.push({ startChange: 1 }, { endChange: 2 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].change;
        }
        this.saveFilters();
        break;
      }
      case '2% to 5%': {
        this.selectedValueinchange = '2% to 5%';
        this.selectedchangeColor = 'selected-button';
        this.filterArray.push({ startChange: 2 }, { endChange: 5 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].change;
        }
        this.saveFilters();
        break;
      }
      case '5% to 10%': {
        this.selectedValueinchange = '5% to 10%';
        this.selectedchangeColor = 'selected-button';
        this.filterArray.push({ startChange: 5 }, { endChange: 10 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].change;
        }
        this.saveFilters();
        break;
      }
      case '10% to 50%': {
        this.selectedValueinchange = '10% to 50%';
        this.selectedchangeColor = 'selected-button';
        this.filterArray.push({ startChange: 10 }, { endChange: 50 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].change;
        }
        this.saveFilters();
        break;
      }
      case 'This Week': {
        this.selectedDateRange = null;
        this.selectedValueinDate = 'This Week';
        this.selectedDateColor = 'selected-button';
        this.filterArray.push({ week: 'thisWeek' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].month;
          delete this.filterArray[key].startDate;
          delete this.filterArray[key].endDate;
        }
        this.saveFilters();
        break;
      }
      case 'This Month': {
        this.selectedDateRange = null;
        this.selectedValueinDate = 'This Month';
        this.selectedDateColor = 'selected-button';
        this.filterArray.push({ month: 'thisMonth' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].week;
          delete this.filterArray[key].startDate;
          delete this.filterArray[key].endDate;
        }
        this.saveFilters();
        break;
      }
      case 'Next Month': {
        this.selectedDateRange = null;
        this.selectedValueinDate = 'Next Month';
        this.selectedDateColor = 'selected-button';
        this.filterArray.push({ month: 'nextMonth' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].week;
          delete this.filterArray[key].startDate;
          delete this.filterArray[key].endDate;
        }
        this.saveFilters();
        break;
      }
      case 'Next Week': {
        this.selectedDateRange = null;
        this.selectedValueinDate = 'Next Week';
        this.selectedDateColor = 'selected-button';
        this.filterArray.push({ week: 'nextWeek' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].month;
          delete this.filterArray[key].startDate;
          delete this.filterArray[key].endDate;
        }
        this.saveFilters();
        break;
      }
      case 'Biotechnology': {
        this.selectedindustryColor = 'selected-button';
        this.selectedValueinindustry = 'Biotechnology';
        const Biotechnology = [
          'Biotechnology',
          'Tobacco',
          'Real Estate Services',
          'Chemicals',
          ' N/A',
          'Farm Products',
          'Food Distribution',
          'Packaged Foods',
        ];
        this.filterArray.push({ Industry: Biotechnology });
        this.saveFilters();
        break;
      }
      case 'Medical Device': {
        this.selectedindustryColor = 'selected-button';
        this.selectedValueinindustry = 'Medical Device';
        const Medical_Device = [
          'Medical Devices',
          'Medical Instruments & Supplies',
          'Electronic Components',
          'Scientific & Technical Instruments',
          'Health Information Services',
          'Medical Care Facilities',
          'Furnishings',
          'Fixtures & Appliances',
          'Medical Distribution',
        ];
        this.filterArray.push({ Industry: Medical_Device });
        this.saveFilters();
        break;
      }
      case 'Diagnostics & Research': {
        this.selectedindustryColor = 'selected-button';
        this.selectedValueinindustry = 'Diagnostics & Research';
        const Diagnostics = ['Diagnostics & Research'];
        this.filterArray.push({ Industry: Diagnostics });
        this.saveFilters();
        break;
      }
      case 'Drug Manufacturers': {
        this.selectedindustryColor = 'selected-button';
        this.selectedValueinindustry = 'Drug Manufacturers';

        const Drug_Manufacturers = [
          'Drug Manufacturers—General',
          'Drug Manufacturers—Specialty & Generic',
          'Household & Personal Products,Pharmaceutical Retailers',
        ];
        this.filterArray.push({ Industry: Drug_Manufacturers });
        this.saveFilters();
        break;
      }
      case 'BLA Filing': {
        this.selectedEventColor = 'selected-button';
        this.selectedValueinEvent = 'BLA Filing';

        const Event_type = ['BLA Filing'];
        this.filterArray.push({ eventType: Event_type });
        this.saveFilters();
        break;
      }
      case 'Clinical Update': {
        this.selectedEventColor = 'selected-button';
        this.selectedValueinEvent = 'Clinical Update';

        const Event_type = [
          'Clinical Study',
          'Clinical Trial',
          'Clinical Update',
          'Data',
          'Efficacy and Safety Data',
          'Enrollment Update',
          'Provided Update',
          'Regulatory Update',
        ];
        this.filterArray.push({ eventType: Event_type });
        this.saveFilters();
        break;
      }
      case 'IND Filing': {
        this.selectedEventColor = 'selected-button';
        this.selectedValueinEvent = 'IND Filing';

        const Event_type = ['IND Filing'];
        this.filterArray.push({ eventType: Event_type });
        this.saveFilters();
        break;
      }
      case 'NDA Filing': {
        this.selectedEventColor = 'selected-button';
        this.selectedValueinEvent = 'NDA Filing';

        const Event_type = ['NDA Filing', 'Submission'];
        this.filterArray.push({ eventType: Event_type });
        this.saveFilters();
        break;
      }
      case 'PDUFA Date': {
        this.selectedEventColor = 'selected-button';
        this.selectedValueinEvent = 'PDUFA Date';

        const Event_type = ['PDUFA Date', 'Priority PDUFA Date'];
        this.filterArray.push({ eventType: Event_type });
        this.saveFilters();
        break;
      }
      case 'Preclinical data readout': {
        this.selectedEventColor = 'selected-button';
        this.selectedValueinEvent = 'Preclinical data readout';

        const Event_type = ['Preclinical data readout'];
        this.filterArray.push({ eventType: Event_type });
        this.saveFilters();
        break;
      }
      case 'Phase 1 Readout': {
        this.selectedEventColor = 'selected-button';
        this.selectedValueinEvent = 'Phase 1 Readout';

        const Event_type = [
          'Phase 1 data readout',
          'Phase 1/2 data readout',
          'Phase 1a data readout',
          'Phase 1b data readout',
        ];
        this.filterArray.push({ eventType: Event_type });
        this.saveFilters();
        break;
      }
      case 'Phase 2 Readout': {
        this.selectedEventColor = 'selected-button';
        this.selectedValueinEvent = 'Phase 2 Readout';

        const Event_type = [
          'Phase 2 Immunogenicity update',
          'Phase 2 data readout',
          'Phase 2/3 data readout',
          'Phase 2a data readout',
          'Phase 2b data readout',
        ];
        this.filterArray.push({ eventType: Event_type });
        this.saveFilters();
        break;
      }
      case 'Phase 3 Readout': {
        this.selectedEventColor = 'selected-button';
        this.selectedValueinEvent = 'Phase 3 Readout';

        const Event_type = ['Phase 3 data readout'];
        this.filterArray.push({ eventType: Event_type });
        this.saveFilters();
        break;
      }
      case 'Top-line Data': {
        this.selectedEventColor = 'selected-button';
        this.selectedValueinEvent = 'Top-line Data';

        const Event_type = [
          'Top-line Data Due',
          'Top-line data',
          'Top-line results',
          'Results',
          'Study Results',
        ];
        this.filterArray.push({ eventType: Event_type });
        this.saveFilters();
        break;
      }
    }
  }

  clearSelectedPrice() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].close;
      delete this.filterArray[key].startPrice;
      delete this.filterArray[key].endPrice;
    }

    this.selectedPriceRange = null;
    this.maxCustomValue = 0;
    this.minCustomValue = 0;
    this.selectedDataColor = '';
    this.selectedValue = '';

    if(this.isFilter){
      if(this.filterArray.every((item) => Object.keys(item).length === 0)){
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedChangePrice() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].change;
      delete this.filterArray[key].startChange;
      delete this.filterArray[key].endChange;
    }

    this.selectedChangePriceRange = null;
    this.minCustomchange = 0;
    this.maxCustomchange = 0;
    this.selectedchangeColor = '';
    this.selectedValueinchange = '';

    if(this.isFilter){
      if(this.filterArray.every((item) => Object.keys(item).length === 0)){
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedDate() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].startDate;
      delete this.filterArray[key].endDate;
      delete this.filterArray[key].month;
      delete this.filterArray[key].week;
    }

    this.selectedDateRange = null;
    this.minCustomDate = 0;
    this.maxCustomDate = 0;
    this.selectedDateColor = '';
    this.selectedValueinDate = '';

    if(this.isFilter){
      if(this.filterArray.every((item) => Object.keys(item).length === 0)){
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedCheckbox() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].stage;
    }

    this.selectedStages = null;
    this.selectedStageColor = '';
    this.selectlist.forEach((item) => (item.checked = false));
    this.isDropdownOpen['Stage'] = false;

    if(this.isFilter){
      if(this.filterArray.every((item) => Object.keys(item).length === 0)){
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearDiseasesCheckbox() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].Specific_Diseases;
    }

    this.selectedDiseases = null;
    this.selectedDiseasesColor = '';
    this.diseasesList.forEach((item) => (item.checked = false));
    this.isDropdownOpen['Specific_Diseases'] = false;

    if(this.isFilter){
      if(this.filterArray.every((item) => Object.keys(item).length === 0)){
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearTherapeuticCheckbox() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].Therapeutic_area;
    }

    this.selectedTherapeutic = null;
    this.selectedTherapeuticColor = '';
    this.therapeuticList.forEach((item) => (item.checked = false));
    this.isDropdownOpen['Therapeutic_area'] = false;

    if(this.isFilter){
      if(this.filterArray.every((item) => Object.keys(item).length === 0)){
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedIndustry() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].Industry;
    }
    this.selectedindustryColor = '';
    this.selectedValueinindustry = '';

    if(this.isFilter){
      if(this.filterArray.every((item) => Object.keys(item).length === 0)){
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedEvent() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].eventType;
    }
    this.selectedEventColor = '';
    this.selectedValueinEvent = '';

    if(this.isFilter){
      if(this.filterArray.every((item) => Object.keys(item).length === 0)){
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }
  pushFilterminPrice(data: any) {
    // this.selectedDataColor = 'selected-button';
    this.filterArray.push({ startPrice: Number(data.target.value) });
    if (!this.maxCustomValue) this.filterArray.push({ endPrice: 1000 });
    // this.saveFilters();
  }

  pushFiltermaxPrice(data: any) {
    // this.selectedDataColor = 'selected-button';
    this.filterArray.push({ endPrice: Number(data.target.value) });
    if (!this.minCustomValue) this.filterArray.push({ startPrice: 0 });
    // this.saveFilters();
  }

  pushFilterDateStart(data: any) {
    for (let key in this.filterArray) {
      delete this.filterArray[key].week;
      delete this.filterArray[key].month;
    }
    this.selectedDateColor = 'selected-button';
    this.filterArray.push({ startDate: data.target.value });
  }

  pushFilterDateEnd(data: any) {
    for (let key in this.filterArray) {
      delete this.filterArray[key].week;
      delete this.filterArray[key].month;
    }
    this.selectedDateColor = 'selected-button';
    this.filterArray.push({ endDate: data.target.value });
  }

  resetFilters() {
    this.selectedDateRange = null;
    this.minCustomDate = 0;
    this.maxCustomDate = 0;
    this.searchingOrFiltering = true;
    this.isFilterBtnResert = true;
    this.isFilter = false;
    this.isSort = false;
    this.refresh();
    this.totalDatalength = 0;
    this.apitype = 'defualt';
    this.fdaTableData();
    this.selectedPriceRange = null;
    this.selectedChangePriceRange = null;

    this.clearSelectedPrice();
    this.clearSelectedChangePrice();
    this.clearSelectedDate();
    this.clearSelectedCheckbox();
    this.clearDiseasesCheckbox();
    this.clearTherapeuticCheckbox();
    this.clearSelectedIndustry();
    this.clearSelectedEvent();
    // if (this.forResetfilters) {
    //   this.clearSelectedPrice();
    //   this.clearSelectedChangePrice();
    //   this.clearSelectedDate();
    //   this.clearSelectedCheckbox();
    //   this.clearDiseasesCheckbox();
    //   this.clearTherapeuticCheckbox();
    //   this.clearSelectedIndustry();
    //   this.clearSelectedEvent();
    // }
  }

  searchFilters(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;

    if (event.key === 'Escape' && !inputElement.value) {
      this.suggection = [];
      return;
    }

    if (!inputElement.value || !this.searchValuequery) {
      this.suggection = [];
      return;
    }

    this.compamyDetails.searchsuggection(inputElement.value).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res);

        const ParseDecryptedData = JSON.parse(decryptData);

        this.suggection = ParseDecryptedData;

        // this.symbol = res[0].symbol
      },
    });
  }

  onSearchInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;

    if (inputValue === '') {
      this.suggection = [];
    }
  }

  searchFiltersonclick(query: any) {
    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }

    if (query == '') {
      this.toaster.warning('Enter Valid Inputs.');
      return;
    }

    let search = {
      query,
    };
    this.suggection = [];
    this.searchValuequery = '';

    this.isLoading = true;
    this.isFilterBtnSearch = true;
    this.forResetfilters = true;
    this.isFilter = true;

    this.fdaTable = [];

    this.filter.fdafilterData(search).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);

        const ParseDecryptedData = JSON.parse(decryptData);

        this.isFilterBtnSearch = false;
        this.forResetfilters = false;
        this.loadData = false;
        this.totalDatalength = ParseDecryptedData[0].data.length;
        this.fdaTable = ParseDecryptedData[0].data as FDATable[];
        this.suggection = [];
        this.isLoading = true;
      },
      error: (err) => {
        this.isFilterBtnSearch = false;
        this.forResetfilters = false;
        this.searchValuequery = '';
        this.fdaTable = [];
        this.fdaTableData();
        this.toaster.error(err.error.message);
      },
    });
  }

  onAddSymbol(data: any) {
    this.portfoliomatchTicker.push(data.ticker);
    return;
  }

  removeSymboltoPotfoliyo(symbol: any) {
    let loginuser = JSON.parse(localStorage.getItem('loggedInUser') || '{}');

    let obj = {
      email: loginuser.email,
      ticker: symbol,
    };

    if (obj) {
      this.fdacalendarservice.removeSymboltoPotfoliyo(obj).subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res);
          this.portfoliomatchTicker = [];
          const ParseDecryptedData = JSON.parse(decryptData);
          ParseDecryptedData.portfolio.forEach((element: any) => {
            this.portfoliomatchTicker.push(element.ticker);
          });

          this.toaster.success('Symbol removed from your portfolio');
        },
      });
    }
  }

  up_down_vote(
    symbol: any,
    vote: string,
    isupVoted: boolean,
    isdownVoted: boolean
  ) {
    let UserId = JSON.parse(localStorage.getItem('UserId') || '{}');
    let loginuser = JSON.parse(localStorage.getItem('loggedInUser') || '{}');
    let obj = {
      userid: UserId,
      ticker: symbol,
      vote: vote,
    };

    if (loginuser.email) {
      if (UserId) {
        if (vote === 'down' && isdownVoted) {
          this.removeVote(symbol);
          return;
        } else if (vote === 'up' && isupVoted) {
          this.removeVote(symbol);
          return;
        } else if (vote === 'up' && isdownVoted) {
          this.removeVote(symbol);
          setTimeout(() => {
            this.fdacalendarservice.upvote(obj).subscribe({
              next: (res: any) => {
                this.fdaTable.filter((FDAtableObj: any) => {
                  if (symbol === FDAtableObj.ticker) {
                    FDAtableObj.isIdIncludedInUp = res.isIdIncludedInUp;
                    FDAtableObj.isIdIncludedInDown = res.isIdIncludedInDown;
                  }
                });
              },
            });
          }, 700);
          return;
        } else if (vote === 'down' && isupVoted) {
          this.removeVote(symbol);
          setTimeout(() => {
            this.fdacalendarservice.upvote(obj).subscribe({
              next: (res: any) => {
                this.fdaTable.filter((FDAtableObj: any) => {
                  if (symbol === FDAtableObj.ticker) {
                    FDAtableObj.isIdIncludedInUp = res.isIdIncludedInUp;
                    FDAtableObj.isIdIncludedInDown = res.isIdIncludedInDown;
                  }
                });
              },
            });
          }, 700);
          return;
        }

        // If not upvoted or downvoted yet, proceed with voting
        this.fdacalendarservice.upvote(obj).subscribe({
          next: (res: any) => {
            this.fdaTable.filter((FDAtableObj: any) => {
              if (symbol === FDAtableObj.ticker) {
                FDAtableObj.isIdIncludedInUp = res.isIdIncludedInUp;
                FDAtableObj.isIdIncludedInDown = res.isIdIncludedInDown;
              }
            });
          },
        });
      }
    } else {
      this.modalDisplayStyle = true;
    }
  }

  removeVote(symbol: any) {
    let UserId = JSON.parse(localStorage.getItem('UserId') || '{}');
    let loginuser = JSON.parse(localStorage.getItem('loggedInUser') || '{}');
    let obj = {
      ticker: symbol,
      userid: UserId,
    };

    if (loginuser.email) {
      if (UserId) {
        this.fdacalendarservice.removeVote(obj).subscribe({
          next: (res: any) => {
            this.fdaTable.filter((FDAtableObj: any) => {
              if (symbol === FDAtableObj.ticker) {
                FDAtableObj.isIdIncludedInUp = res.isIdIncludedInUp;
              }
              if (symbol === FDAtableObj.ticker) {
                FDAtableObj.isIdIncludedInDown = res.isIdIncludedInDown;
              }
            });
          },
        });
      }
    } else {
      this.modalDisplayStyle = true;
    }
  }

  votingPrice(ticker: string, id: any) {
    let UserId = JSON.parse(localStorage.getItem('UserId') || '{}');
    let loginuser = JSON.parse(localStorage.getItem('loggedInUser') || '{}');
    let Price = (document.getElementById(id) as HTMLInputElement).value;
    if (Price === '' || Price === null || Price === undefined) {
      return;
    }
    let obj = {
      userid: UserId,
      price: Number(Price),
      ticker: ticker,
    };

    if (loginuser.email) {
      this.fdacalendarservice.vottingprice(obj).subscribe({
        next: (res: any) => {
          this.toaster.success(res.message);
          (document.getElementById(id) as HTMLInputElement).value = '';
        },
      });
    } else {
      this.modalDisplayStyle = true;
      (document.getElementById(id) as HTMLInputElement).value = '';
    }
  }
}
