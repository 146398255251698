<div class="container">
    <h2 class="title main-heading m-1">Write Blog here..</h2>
    <div class="content">

        <form [formGroup]="form">
            <div class="editor editor-toolbar">
                <ngx-editor-menu [editor]="editor" [toolbar]="toolbar" class="toolbar-container">
                </ngx-editor-menu>
                @if (isLoading) {
                <button class="load-button">Saving...</button>
                }
                @else {
                <button class="save-button" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Save
                </button>
                }
            </div>
            <ngx-editor [editor]="editor" formControlName="editorContent">
            </ngx-editor>
        </form>

    </div>
</div>

<!-- <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Heyy</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              
            </div>
            <div class="modal-footer">
                <button (click)="getEditorContent()" type="button" class="btn btn-secondary"
                    data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</div> -->

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div style="background-color: #3b2cc6 !important;" class="modal-header">
                <h1 class="modal-title text-light fs-5" id="exampleModalLabel">Blog Details</h1>
                <!-- <button type="button" class="btn-close " data-bs-dismiss="modal" aria-label="Close"></button> -->
                <i class="fa-solid fa-xmark cursorpointer" data-bs-dismiss="modal"
                    style="opacity: inherit; color: #FFF7D7;"></i>
            </div>
            <div class="modal-body">
                <h6 class="my-2">Title</h6>
                <input class="form-control" required [(ngModel)]="blogtitle" type="text">
                <h6 class="my-2">Introduction</h6>
                <textarea required [(ngModel)]="blogintro" name="" id="" class="form-control"></textarea>
                <h6 class="my-2">Thumbnail</h6>
                <!-- <input (change)="onFileChange($event)" class="form-control" type="file"> -->
                <input class="form-control" required [(ngModel)]="blogthumb" type="text">

            </div>
            <div class="modal-footer">
                <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal"
                    [disabled]=" !blogintro || !blogtitle" (click)="getEditorContent()"
                    class="btn addportfoiliobtn">ADD</button>
            </div>
        </div>
    </div>
</div>

<!-- put this in the blog page  -->
<!-- <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(htmlContent)"></div> -->