import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  getDrugwishlist,
  getOptionData,
  getPortfolio,
  getWishlistbiotech,
  getWishlistcatalyst,
  getWishlistinside,
  gethedgefundwish,
} from '../const';

@Injectable({
  providedIn: 'root',
})
export class WishlistServiesService {
  constructor(private http: HttpClient) {}

  getCatalystwishlist(page: number) {
    return this.http.post(`${getWishlistcatalyst}${page}`, {});
  }
  getinsidewishlist(page: number) {
    return this.http.post(`${getWishlistinside}${page}`, {});
  }
  getBiotechwishlist(page: number) {
    return this.http.post(`${getWishlistbiotech}${page}`, {});
  }
  getDrugwishlist(page: number) {
    return this.http.post(`${getDrugwishlist}${page}`, {});
  }
  getHedgeFundwishlist(page: number) {
    return this.http.post(`${gethedgefundwish}${page}`, {});
  }
  getOptionsummary() {
    return this.http.get(`${getOptionData}`);
  }
  getPortfolioOverview() {
    return this.http.get(`${getPortfolio}`);
  }
}
