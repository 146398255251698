import { Component } from '@angular/core';
import { HedgefundsService } from '../../../shared/service/hedgefunds.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FilterdataService } from '../../../shared/service/filterdata.service';
import { DecryptionService } from 'src/app/modules/shared/service/decryption.service';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-hedgefunds',
  templateUrl: './hedgefunds.component.html',
  styleUrls: ['./hedgefunds.component.scss'],
})
export class HedgefundsComponent {
  hedgeTbl: any[] = [];
  modalDisplayStyle!: boolean;
  isFilterBtnSearch = false;
  isCustommarketcapRangeSelected: boolean = false;
  isDropdownOpen: { [key: string]: boolean } = {};
  selectedMarketCapRange: string | null = null;
  selectedFundSizeRange: string | null = null;
  selectedShareHeldRange: string | null = null;
  isMarketcapDropdownOpen!: boolean;
  minCustomValue: number | undefined;
  maxCustomValue: number | undefined;
  filterArray: any[] = [];
  isFilterBtn = false;
  isFilterBtnResert = false;
  currentPage = 1;
  apidata: any;
  scrollend = true;
  searchingOrFiltering: boolean = true;
  stockheld: boolean = false;
  stockheldDrop: boolean = false;
  isOuterDropdownOpen: boolean = false;
  isInnerDropdownOpen: boolean = false;
  showloginWarning = false;
  arrowclass = 'fa-arrow-up';
  FilterObj: any;
  sortNumber = 1;
  sortKeyVal = '';
  isSorton = false;
  apitype = 'defualt';
  private isLoading = false;
  isLoadTableDataLoader!: boolean;
  totalDatalength = 0;
  selectedDataColor!: string;
  selectedValue: string | null = null;
  selectedshareColor!: string;
  selectedValueinshare: string | null = null;
  maxCustomFund: any;
  minCustomFund: any;
  maxCustomHold: any;
  minCustomhold: any;
  isFilter = false;
  isSort = false;
  searchValuequery: any;
  arrowfundname = 'fa-arrow-up';
  arrowfundsize = 'fa-arrow-up';
  arrowstockheld = 'fa-arrow-up';
  arrowQoq = 'fa-arrow-up';
  arrowlargestHeld = 'fa-arrow-up';
  loadData!: boolean;
  basePath: string = 'https://s3-symbol-logo.tradingview.com/';
  lockedArray: any[] = [];
  productId: number | undefined;
  btnLoading: any;

  constructor(
    private hedgeService: HedgefundsService,
    private router: Router,
    private toaster: ToastrService,
    private filter: FilterdataService,
    private decryptionService: DecryptionService
  ) {}

  ngOnInit(): void {
    this.hedgefundTable();
    this.returnLogin();
  }

  exportToExcel() {
    this.btnLoading = true;
    const trar: any = this.hedgeTbl.map(({ _id, combinedData, ...rest }) => ({
      ...rest,
    }));
    trar.unshift({ site: 'https://biopharmawatch.com/hedgefunds' });
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(trar);
    const workbook: XLSX.WorkBook = {
      Sheets: { Data: worksheet },
      SheetNames: ['Data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, 'HFS');
    setTimeout(() => {
      this.btnLoading = false;
    }, 500);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(data, `${fileName}${new Date()}.xlsx`);
  }

  returnLogin() {
    let loign = localStorage.getItem('loggedInUser');
    if (!loign) {
      this.showloginWarning = true;
    }
  }
  toggleOuterDropdown(event: Event) {
    this.isOuterDropdownOpen = !this.isOuterDropdownOpen;
    this.isInnerDropdownOpen = false; // Close inner dropdown on outer dropdown click
    event.stopPropagation(); // Prevent event bubbling to document click
  }

  toggleInnerDropdown(event: Event) {
    this.isInnerDropdownOpen = !this.isInnerDropdownOpen;
    event.stopPropagation(); // Prevent event bubbling to outer dropdown click
  }
  toggleDropdown() {
    this.stockheld = !this.stockheld; // Toggle dropdown visibility
  }

  hedgefundTable() {
    this.hedgeService.Hedgedata(this.currentPage).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);
        const ParseDecryptedData = JSON.parse(decryptData);

        this.productId = ParseDecryptedData.find(
          (x: any) => x.productId
        )?.productId;

        this.totalDatalength = ParseDecryptedData[0].total[0].count;
        this.hedgeTbl = ParseDecryptedData[0].data;
        this.isFilterBtnResert = false;
        this.currentPage = 2;
        for (let i = 0; i < 2; i++) {
          this.lockedArray.push(this.hedgeTbl);
          this.lockedArray = this.lockedArray.flat();
        }

        if (!this.productId) {
          this.hedgeTbl = this.lockedArray;
        }
      },
      error: (err) => {
        this.toaster.error('No data to Show');
        this.isFilterBtnResert = false;
      },
    });
  }

  searchFilters(query: any) {
    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }
    if (query == '') {
      this.toaster.warning('Enter Valid Inputs.');
      return;
    }
    let search = {
      query,
    };

    this.hedgeTbl = [];
    this.isFilterBtnSearch = true;
    this.filter.hedgefundsearch(search).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);
        const ParseDecryptedData = JSON.parse(decryptData);
        this.totalDatalength = ParseDecryptedData[0].data.length;
        this.hedgeTbl = ParseDecryptedData[0].data;
        this.isLoading = true;
        this.isFilterBtnSearch = false;
      },
      error: (err: any) => {
        console.log('err :', err);
        this.hedgeTbl = [];
        this.hedgefundTable();
        this.searchValuequery = '';
        this.isFilterBtnSearch = false;
        this.toaster.error(err.error.message);
      },
    });
  }

  indiDetails(fundname: any) {
    let name = fundname.replace(/ /g, '_');
    this.router.navigate(['individualfund', name]);
  }

  GetChildData(data: any) {
    this.modalDisplayStyle = data;
  }

  marcketcap200more(data: any) {
    this.filterArray.push({ current_mv: '200b_or_more' });
    this.selectMarketCapRange(data);
  }

  marcketcap10t0200(data: any) {
    this.filterArray.push({ current_mv: '10b_to_200b' });
    this.selectMarketCapRange(data);
  }

  marcketcap2t010(data: any) {
    this.filterArray.push({ current_mv: '2b_to_10b' });
    this.selectMarketCapRange(data);
  }

  marcketcap250to2(data: any) {
    this.filterArray.push({ current_mv: '250m_to_20b' });
    this.selectMarketCapRange(data);
  }
  marcketcap250less(data: any) {
    this.filterArray.push({ current_mv: '250m_or_less' });
    this.selectMarketCapRange(data);
  }

  selectMarketCapRange(selectedRange: string) {
    if (selectedRange === 'customRangemarketcap') {
      this.selectedMarketCapRange = selectedRange;
      this.isCustommarketcapRangeSelected = true;
      this.isMarketcapDropdownOpen = false;
      this.isDropdownOpen['customRangemarketcap'] = true;
    } else {
      if (this.selectedMarketCapRange === selectedRange) {
        this.selectedMarketCapRange = null;
      } else {
        this.selectedMarketCapRange = selectedRange;
      }
    }
  }

  updateMarketCapCustomRange() {
    if (
      this.minCustomValue !== undefined &&
      this.maxCustomValue !== undefined
    ) {
      this.selectedMarketCapRange = `${this.minCustomValue}$ to ${this.maxCustomValue}$`;
    }
  }

  updateFundSizeCustomRange() {
    if (
      this.minCustomFund &&
      this.maxCustomFund
    ) {
      this.selectedFundSizeRange = `$${this.minCustomFund}m to $${this.maxCustomFund}m`;
      this.selectedDataColor = 'selected-button';
    } else {
      this.selectedFundSizeRange = null;
      this.selectedDataColor = '';
    }
  }

  updateShareHeldCustomRange() {
    if (
      this.minCustomhold &&
      this.maxCustomHold
    ) {
      this.selectedShareHeldRange = `${this.minCustomhold} to ${this.maxCustomHold}`;
      this.selectedshareColor = 'selected-button';
    } else {
      this.selectedShareHeldRange = null;
      this.selectedshareColor = '';
    }
  }


  MarketCapSelected(selectedRange: any) {
    return this.selectedMarketCapRange === selectedRange;
  }

  marketcapfilterclick() {
    if (this.isDropdownOpen['customRangemarketcap'] === true) {
      this.isMarketcapDropdownOpen = true;
      this.isCustommarketcapRangeSelected = false;
    } else {
      this.isMarketcapDropdownOpen = true;
    }
  }
  saveFilters() {
    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }
    let filtered = this.filterArray.filter((el) => Object.keys(el).length);
    if (filtered.length <= 0) {
      this.toaster.warning('Enter Valid Inputs.');
      return;
    }
    this.isFilterBtn = true;
    this.isFilter = true;

    this.apitype = 'filter';
    var newObject = filtered.reduce((result, currentObject) => {
      for (var key in currentObject) {
        if (currentObject.hasOwnProperty(key)) {
          result[key] = currentObject[key];
        }
      }
      return result;
    }, {});

    this.refresh();
    this.FilterObj = newObject;

    this.filter
      .hedgefundscreenerColum(this.FilterObj, this.currentPage)
      .subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res.result);
          const ParseDecryptedData = JSON.parse(decryptData);
          this.totalDatalength = ParseDecryptedData[0].total[0].count;
          this.isFilterBtn = false;

          this.hedgeTbl = ParseDecryptedData[0].data;
          this.searchingOrFiltering = false;
          this.currentPage = 2;
        },
        error: (err) => {
          this.isLoading = false;
          this.isFilterBtn = false;
          this.resetFilters();
          this.toaster.error(err.error.message);
        },
      });
  }

  resetFilters() {
    this.searchingOrFiltering = true;
    this.isSort = false;
    this.isFilter = false;
    this.isFilterBtnResert = true;
    this.refresh();
    this.apitype = 'defualt';
    this.hedgeTbl = [];
    this.hedgefundTable();
    this.clearSelectedshareHeld();
    this.clearFundSize();
  }

  loadMoreData() {
    this.isLoading = true;
    this.loadData = true;

    if (this.apitype == 'filter') {
      this.filter
        .hedgefundscreenerColum(this.FilterObj, this.currentPage)
        .subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res.result);
            const ParseDecryptedData = JSON.parse(decryptData);
            ParseDecryptedData[0].data.forEach((element: any) => {
              this.hedgeTbl.push(element);
            });
            this.isLoading = false;
            this.loadData = false;
            this.currentPage++;
          },
          error: (err) => {
            this.loadData = false;
            this.isLoading = true;
            this.toaster.warning('All Record displayed');
            this.apitype = 'defualt';
          },
        });
    }

    if (this.apitype == 'defualt') {
      this.hedgeService.Hedgedata(this.currentPage).subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res.result);
          const ParseDecryptedData = JSON.parse(decryptData);
          ParseDecryptedData[0].data.forEach((element: any) => {
            this.hedgeTbl.push(element);
          });
          this.loadData = false;
          this.isLoading = false;
          this.currentPage++;
        },
        error: (err) => {
          this.loadData = false;
          this.isLoading = true;
          this.toaster.warning('All Record displayed');
          this.apitype = 'defualt';
        },
      });
    }

    if (this.apitype == 'sort') {
      this.filter
        .hedgeStocksSort(this.sortKeyVal, this.sortNumber, this.currentPage)
        .subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res.result);
            const ParseDecryptedData = JSON.parse(decryptData);
            ParseDecryptedData[0].data.forEach((element: any) => {
              this.hedgeTbl.push(element);
            });
            this.loadData = false;
            this.isLoading = false;
            this.currentPage++;
          },
          error: (err) => {
            this.loadData = false;
            this.isLoading = true;
            this.toaster.warning('All Record displayed');
            this.apitype = 'defualt';
          },
        });
    }
    if (this.isSort) {
      this.filter
        .hedgesortwithFilter(
          this.sortKeyVal,
          this.sortNumber,
          this.currentPage,
          this.FilterObj
        )
        .subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res.result);
            const ParseDecryptedData = JSON.parse(decryptData);
            this.totalDatalength = ParseDecryptedData[0].total[0].count;
            this.loadData = false;
            this.isLoading = false;
            this.currentPage++;
            ParseDecryptedData[0].data.forEach((element: any) => {
              this.hedgeTbl.push(element);
            });
          },
          error: (err) => {
            this.loadData = false;
            this.isLoading = true;
            this.toaster.warning('All Record displayed');
            this.apitype = 'defualt';
          },
        });
    }
  }

  assending(sortKey: string) {
    if (!this.productId) {
      this.modalDisplayStyle = true;
    } else {
      this.apitype = 'sort';

      this.sortNumber = this.sortKeyVal === sortKey ? this.sortNumber * -1 : 1;
      this.sortKeyVal = sortKey;

      this.refresh();

      switch (sortKey) {
        case 'hedgeFundName':
          this.arrowfundname =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'current_mv':
          this.arrowfundsize =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'symbolLength':
          this.arrowstockheld =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'QoQ_change':
          this.arrowQoq =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        case 'symbolWithMaxOwnership':
          this.arrowlargestHeld =
            this.sortNumber == 1
              ? 'fa-arrow-up-wide-short'
              : 'fa-arrow-down-wide-short';
          break;
        default:
          break;
      }

      if (this.isFilter === true) {
        this.apitype = 'filtersort';
        this.isSort = true;
        this.filter
          .hedgesortwithFilter(
            this.sortKeyVal,
            this.sortNumber,
            this.currentPage,
            this.FilterObj
          )
          .subscribe({
            next: (res: any) => {
              const decryptData = this.decryptionService.decrypt(res.result);
              const ParseDecryptedData = JSON.parse(decryptData);
              this.totalDatalength = ParseDecryptedData[0].total[0].count;

              ParseDecryptedData[0].data.forEach((element: any) => {
                this.hedgeTbl.push(element);
                this.currentPage = 2;
              });
            },
            error: (err) => {
              this.isLoading = false;
              this.isFilterBtn = false;
              this.refresh();
              this.hedgefundTable();
              this.toaster.error(err.error.message);
            },
          });
      } else {
        this.filter
          .hedgeStocksSort(this.sortKeyVal, this.sortNumber, this.currentPage)
          .subscribe({
            next: (res: any) => {
              const decryptData = this.decryptionService.decrypt(res.result);
              const ParseDecryptedData = JSON.parse(decryptData);
              this.totalDatalength = ParseDecryptedData[0].total[0].count;

              ParseDecryptedData[0].data.forEach((element: any) => {
                this.hedgeTbl.push(element);
                this.currentPage = 2;
              });
            },
            error: (err) => {
              this.isLoading = false;
              this.isFilterBtn = false;
              this.refresh();
              this.hedgefundTable();
              this.toaster.error(err.error.message);
            },
          });
      }
    }
  }

  refresh() {
    this.hedgeTbl = [];
    this.lockedArray = [];
    this.isLoading = false;
    this.currentPage = 1;
    this.loadData = false;
    this.arrowfundname = 'fa-arrow-up';
    this.arrowfundsize = 'fa-arrow-up';
    this.arrowstockheld = 'fa-arrow-up';
    this.arrowQoq = 'fa-arrow-up';
    this.arrowlargestHeld = 'fa-arrow-up';
  }

  onScroll(event: any) {
    const element = event.target as HTMLElement;
    const scrollPosition = element.scrollTop + element.clientHeight;
    const totalHeight = element.scrollHeight;

    if (this.productId) {
      if (scrollPosition >= totalHeight - 1 && !this.isLoading) {
        if (this.currentPage != 1) {
          this.loadMoreData();
        }
      }
    } else {
      let href = this.router.url;
      localStorage.setItem('route', href);
      this.modalDisplayStyle = true;
    }
  }

  selectedData(data: any) {
    switch (data) {
      case '1_to_5': {
        this.selectedShareHeldRange = null;
        this.selectedValueinshare = '1_to_5';
        this.selectedshareColor = 'selected-button';
        for (let key in this.filterArray) {
          delete this.filterArray[key].max_symbolLength;
          delete this.filterArray[key].min_symbolLength;
        }
        this.filterArray.push({ symbolLength: '1_to_5' });
        this.saveFilters();
        break;
      }
      case '5_to_10': {
        this.selectedShareHeldRange = null;
        this.selectedValueinshare = '5_to_10';
        for (let key in this.filterArray) {
          delete this.filterArray[key].max_symbolLength;
          delete this.filterArray[key].min_symbolLength;
        }
        this.selectedshareColor = 'selected-button';
        this.filterArray.push({ symbolLength: '5_to_10' });
        this.saveFilters();
        break;
      }
      case '10_or_more': {
        this.selectedShareHeldRange = null;
        this.selectedValueinshare = '10_or_more';
        this.selectedshareColor = 'selected-button';
        for (let key in this.filterArray) {
          delete this.filterArray[key].max_symbolLength;
          delete this.filterArray[key].min_symbolLength;
        }
        this.filterArray.push({ symbolLength: '10_or_more' });
        this.saveFilters();
        break;
      }
      case '10b_or_more': {
        this.selectedFundSizeRange = null;
        this.selectedValue = '10b_or_more';
        this.selectedDataColor = 'selected-button';
        for (let key in this.filterArray) {
          delete this.filterArray[key].max_current_mv;
          delete this.filterArray[key].min_current_mv;
        }
        this.filterArray.push({ current_mv: '10b_or_more' });
        this.saveFilters();
        break;
      }
      case '2b_to_10b': {
        this.selectedFundSizeRange = null;
        this.selectedValue = '2b_to_10b';
        this.selectedDataColor = 'selected-button';
        for (let key in this.filterArray) {
          delete this.filterArray[key].max_current_mv;
          delete this.filterArray[key].min_current_mv;
        }
        this.filterArray.push({ current_mv: '2b_to_10b' });
        this.saveFilters();
        break;
      }
      case '250m_to_2b': {
        this.selectedFundSizeRange = null;
        this.selectedValue = '250m_to_2b';
        this.selectedDataColor = 'selected-button';
        for (let key in this.filterArray) {
          delete this.filterArray[key].max_current_mv;
          delete this.filterArray[key].min_current_mv;
        }
        this.filterArray.push({ current_mv: '250m_to_2b' });
        this.saveFilters();
        break;
      }
      case '250m_or_less': {
        this.selectedFundSizeRange = null;
        this.selectedValue = '250m_or_less';
        this.selectedDataColor = 'selected-button';
        for (let key in this.filterArray) {
          delete this.filterArray[key].max_current_mv;
          delete this.filterArray[key].min_current_mv;
        }
        this.filterArray.push({ current_mv: '250m_or_less' });
        this.saveFilters();
        break;
      }
    }
  }

  clearFundSize() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].current_mv;
      delete this.filterArray[key].min_current_mv;
      delete this.filterArray[key].max_current_mv;
    }

    this.selectedFundSizeRange = null;
    this.maxCustomFund = 0;
    this.minCustomFund = 0;
    this.selectedDataColor = '';
    this.selectedValue = '';

    if(this.isFilter){
      if(this.filterArray.every((item) => Object.keys(item).length === 0)){
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedshareHeld() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].symbolLength;
      delete this.filterArray[key].min_symbolLength;
      delete this.filterArray[key].max_symbolLength;
    }

    this.selectedShareHeldRange = null;
    this.minCustomhold = 0;
    this.maxCustomHold = 0;
    this.selectedshareColor = '';
    this.selectedValueinshare = '';

    if(this.isFilter){
      if(this.filterArray.every((item) => Object.keys(item).length === 0)){
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  pushFilterShareheld(data: any) {
    for (let key in this.filterArray) {
      delete this.filterArray[key].symbolLength;
    }
    // this.selectedshareColor = 'selected-button';
    this.filterArray.push({ min_symbolLength: Number(data.target.value) });
  }

  EndpushFilterShareheld(data: any) {
    // this.selectedshareColor = 'selected-button';
    this.filterArray.push({ max_symbolLength: Number(data.target.value) });
  }

  pushFilterMarket_cap(data: any) {
    for (let key in this.filterArray) {
      delete this.filterArray[key].current_mv;
    }
    // this.selectedDataColor = 'selected-button';
    this.filterArray.push({
      min_current_mv: Number(data.target.value) * 1000000,
    });
  }

  EndpushFilterMarket_cap(data: any) {
    for (let key in this.filterArray) {
      delete this.filterArray[key].current_mv;
    }
    // this.selectedDataColor = 'selected-button';
    this.filterArray.push({
      max_current_mv: Number(data.target.value) * 1000000,
    });
  }
}
