import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BlogUserService } from '../../../shared/service/blog-user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-view-more',
  templateUrl: './blog-view-more.component.html',
  styleUrls: ['./blog-view-more.component.scss'],
})
export class BlogViewMoreComponent implements OnInit {
  blogpost: any[] = [];
  blogMore: any[] = [];
  subhead: any;
  subText: any;
  Keywords: string = '';
  newArray: { [key: string]: string }[] = [];
  blogId!: string | null;
  title: any;
  description: any;
  image: any;
  displayStyle = 'none';
  htmlContent: any = '';

  constructor(
    private blogmoreDetail: BlogUserService,
    private router: Router,
    private toaster: ToastrService,
    private activatedRoute: ActivatedRoute,
    private meta: Meta,
    private titleService: Title,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.blogId = this.activatedRoute.snapshot.paramMap.get('id');
    this.blogview(this.blogId);
  }

  blogview(blogId: string | null) {
    this.blogmoreDetail.getblogTable(blogId).subscribe({
      next: (res: any) => {
        this.htmlContent = res[0].htmlContent;
      },
      error: () => {
        this.toaster.warning('Login to see more details !');
        this.router.navigate(['login']);
      },
    });
  }

  copyText(val: any) {
    const id = val;
    const link = `https://biopharmawatch.com/blog/${id}`;
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = link;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      this.toaster.info('Copied  a link');
      if (successful) {
        return true;
      }
    } catch (err) {}
    return false;
  }

  closePopup() {
    this.displayStyle = 'none';
  }

  shareBlog() {
    this.displayStyle = 'block';
  }
}
