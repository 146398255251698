import { Component, ViewChild } from '@angular/core';
import { MinusData, PlusData } from '../../../shared/models/lossing-gainers';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { LoosingGainerService } from '../../../shared/service/loosing-gainer.service';
import { CompanyDetailsService } from '../../../shared/service/company-details.service';
import { ToastrService } from 'ngx-toastr';
import { io } from "socket.io-client";
import { environment } from 'src/environments/environment';
import { NgbCarousel, NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
import { DecryptionService } from 'src/app/modules/shared/service/decryption.service';

@Component({
  selector: 'app-volatile-data',
  templateUrl: './volatile-data.component.html',
  styleUrls: ['./volatile-data.component.scss']
})
export class VolatileDataComponent {

  @ViewChild('gainerTab') gainerTab: any;
  @ViewChild('loserTab') loserTab: any;
  @ViewChild(NgbCarousel) carousel!: NgbCarousel;

  plusData: PlusData[] = [];
  minusData: MinusData[] = [];
  currentPage = 1;
  itemsPerPage = 10;
  isLoading = false;
  basePath: string = 'https://s3-symbol-logo.tradingview.com/';
  pageSize: number = 10
  lastClickTime = 0;
  isCompanyDetailsTriggered: boolean = false;
  selectedCompany: any;
  socket = io(environment.socketUrl);
  idlogoNull = false;
  gainers = true;
  loosings = false;

  activeSlide: any = 0;
  activeTab: number = 0;

  activebtntab = '';
  activeGainerTab = 'active-tab';
  constructor(private router: Router, private formBuilder: FormBuilder, private LoosingGainerService: LoosingGainerService, private compamyDetails: CompanyDetailsService, private toaster: ToastrService, private decryptionService: DecryptionService) { }

  ngOnInit(): void {
    this.gainerData();
    this.lossingData();
  }



  gainerData() {
    this.isLoading = true;
    this.LoosingGainerService.getPlusData(20).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.encrypted);

        const ParseDecryptedData = JSON.parse(decryptData);
        this.isLoading = false;
        this.plusData = ParseDecryptedData;
        this.updateDataCssClass(this.plusData);

      }
    });
  }

  lossingData() {
    this.isLoading = true;
    this.LoosingGainerService.getminusData(20).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.encrypted);

        const ParseDecryptedData = JSON.parse(decryptData);
        this.isLoading = false;
        this.minusData = ParseDecryptedData;
        this.updateDataCssClass(this.minusData);
      }
    });
  }

  updateDataCssClass(data: any[]) {
    // data.forEach((item: any) => {
    //   const varprice = item.change
    //   item.change = varprice.toFixed(2)
    //   const Sign = Math.sign(varprice);
    //   item.cssClass = Sign <= -1 ? 'red-color' : 'green-color';
    // });
  }



  plusDetails(ticker: any) {
    this.compamyDetails.getCompanyTable(ticker).subscribe({
      next: (res: any) => {
        this.router.navigate(['company', ticker]);
        this.compamyDetails.getCompanyTicker(ticker)
      },
      error: (error) => {
        this.toaster.warning('login and show more details!');
        this.router.navigate(['login'])
      }
    })
  }



  minusDetails(ticker: any) {
    this.compamyDetails.getCompanyTable(ticker).subscribe({
      next: (res: any) => {
        this.router.navigate(['company', ticker]);
        this.compamyDetails.getCompanyTicker(ticker)
      },
      error: (error) => {
        this.toaster.warning('login and show more details!');
        this.router.navigate(['login'])
      }
    })
  }

  ScoketAllData() {
    this.socket.on('tblcompaniesScoket', (res: any) => {
      if (res.operationType == "update") {
        var Data = {
          ...res.documentKey,
          ...res.updateDescription.updatedFields,
        }
        this.plusData.filter((PlusObj: any) => {
          if (PlusObj._id === Data._id) {
            PlusObj.close = Data.close ? Data.close : PlusObj.close;
          }
          if (PlusObj._id === Data._id) {
            PlusObj.change_abs = Data.change_abs ? Data.change_abs : PlusObj.change_abs;
          }
        });

        this.minusData.filter((MinusObj: any) => {
          if (MinusObj._id === Data._id) {
            MinusObj.close = Data.close ? Data.close : MinusObj.close;
          }
          if (MinusObj._id === Data._id) {
            MinusObj.change_abs = Data.change_abs ? Data.change_abs : MinusObj.change_abs;
          }
        });
      } else { }
    });
    this.socket.connect();
  }
  gainersdata() {
    this.gainers = true;
    this.loosings = false;
  }
  lossingdata() {
    this.loosings = true;
    this.gainers = false;
  }

  onSlideChange(slideEvent: NgbSlideEvent) {
    this.activeSlide = slideEvent.current;
    if (this.activeSlide == 0) {
      this.activebtntab = ""
      this.activeGainerTab = 'active-tab';
    } else if (this.activeSlide == 1) {
      this.activebtntab = "active-tab"
      this.activeGainerTab = '';
    }
  }

  selectTab(tabId: number) {
    if (tabId === 0) {
      this.gainerTab.nativeElement.click();
      this.carousel.select('0');
    } else if (tabId === 1) {
      this.loserTab.nativeElement.click();
      this.carousel.select('1');
    }
  }


}