import { Injectable } from '@angular/core';
import { signup } from '../../core/model/signup';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { VerifyEmail, VerifyOtp } from '../../shared/const';
import { postSignup } from '../../shared/const';

@Injectable({
  providedIn: 'root'
})
export class SignupService {

  constructor(private http: HttpClient) { }

  // getSignup() {
  //   return this.http.get<signup[]>('http://localhost:3000/signup');
  // }

  postSignup(data: any): Observable<any> {
    return this.http.post<signup[]>(`${postSignup}`, data).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error.error.message);
      })
    );
  }

  VerifyEmail(data: any) {

    // let newdata = JSON.stringify(data)
    return this.http.post(`${VerifyEmail}`, data)
  }

  VerifyOtp(data: string) {
    // let newdata = JSON.stringify(data)
    let obj = { otp: data };
    return this.http.post(`${VerifyOtp}`, obj);
  }
}
