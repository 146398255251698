<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-lg-12 formobileL">
      <div class="row justify-content-center Market-Size_div mt-3">
        <div class="col-12 col-lg-auto col-md-auto col-sm-auto col-12">
          <h3 class="text-center text-nowrap m-1">Market Size</h3>
        </div>
        <div class="col-12 col-lg-auto col-md-auto col-sm-auto col-12">
          <button
            [ngClass]="{ selectedbg: marketCapVal === 'micro-cap' }"
            class="mx-2 px-3 button-set2-banner m-1"
            (click)="selectedCap('micro-cap')"
          >
            Micro Caps
          </button>
        </div>
        <div class="col-12 col-lg-auto col-md-auto col-sm-auto col-12">
          <button
            class="mx-2 px-3 button-set2-banner m-1"
            [ngClass]="{ selectedbg: marketCapVal === 'small-cap' }"
            (click)="selectedCap('small-cap')"
          >
            Small-Caps
          </button>
        </div>
        <div class="col-12 col-lg-auto col-md-auto col-sm-auto col-12">
          <button
            [ngClass]="{ selectedbg: marketCapVal === 'mid-cap' }"
            class="mx-2 px-3 button-set2-banner m-1"
            (click)="selectedCap('mid-cap')"
          >
            Mid Caps
          </button>
        </div>
        <div class="col-12 col-lg-auto col-md-auto col-sm-auto col-12">
          <button
            [ngClass]="{ selectedbg: marketCapVal === 'large-cap' }"
            class="mx-2 px-3 button-set2-banner m-1"
            (click)="selectedCap('large-cap')"
          >
            Large Caps
          </button>
        </div>
        <div class="col-12 col-lg-auto col-md-auto col-sm-auto col-12">
          <button
            [ngClass]="{ selectedbg: marketCapVal === 'mega-cap' }"
            class="mx-2 px-3 button-set2-banner m-1"
            (click)="selectedCap('mega-cap')"
          >
            Mega Caps
          </button>
        </div>
      </div>
      <!-- <h3 class="text-center text-nowrap mt-3">{{today | dateformate}}</h3> -->
    </div>
    <div class="d-none formobile">
      <div class="d-flex justify-content-center">
        <div class="dropdown m-3 d-flex justify-content-center">
          <button
            [ngClass]="{ selectedbgforsec: marketCapVal !== '' }"
            class="px-2 d-flex align-items-center justify-content-between accordion-down"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            @if (marketCapVal) {
            <span>
              @if(marketCapVal == 'small-cap'){ Small Caps } @else if(marketCapVal == 'mid-cap'){ Mid Caps } @else if(marketCapVal ==
              'large-cap'){ Large Caps } @else if(marketCapVal == 'mega-cap'){
              Mega Caps } @else if(marketCapVal == 'micro-cap'){ Micro Caps }
            </span>
            }@else {
            <span>Market Size</span>
            }

            <i class="fa fa-caret-down ms-4"></i>
          </button>
          <ul class="dropdown-menu games-menu">
            <li>
              <a
                class="dropdown-item filter-bottom filter-font-text"
                [ngClass]="{ selecteddropdwon: marketCapVal === 'micro-cap' }"
                (click)="selectedCap('micro-cap')"
                >Micro Caps</a
              >
            </li>
            <li>
              <a
                class="dropdown-item filter-bottom filter-font-text"
                [ngClass]="{ selecteddropdwon: marketCapVal === 'small-cap' }"
                (click)="selectedCap('small-cap')"
                >Small Caps</a
              >
            </li>
            <li>
              <a
                class="dropdown-item filter-bottom filter-font-text"
                [ngClass]="{ selecteddropdwon: marketCapVal === 'mid-cap' }"
                (click)="selectedCap('mid-cap')"
                >Mid Caps</a
              >
            </li>

            <li>
              <a
                class="dropdown-item filter-bottom filter-font-text"
                [ngClass]="{ selecteddropdwon: marketCapVal === 'large-cap' }"
                (click)="selectedCap('large-cap')"
                >Large Caps</a
              >
            </li>
            <li>
              <a
                class="dropdown-item text-center filter-font-text"
                [ngClass]="{ selecteddropdwon: marketCapVal === 'mega-cap' }"
                (click)="selectedCap('mega-cap')"
                >Mega Caps</a
              >
            </li>
          </ul>
        </div>
        <div class="dropdown m-3 d-flex justify-content-center">
          <button
            [ngClass]="{ selectedbgforsec: bySecVal !== '' }"
            class="px-2 d-flex btn align-items-center justify-content-between accordion-down"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            @if (bySecVal) {
            <span>{{ bySecVal }}</span>
            }@else {
            <span>By Sector</span>
            }

            <i class="fa fa-caret-down ms-4"></i>
          </button>
          <ul class="dropdown-menu games-menu">
            <li>
              <a
                class="dropdown-item filter-bottom filter-font-text"
                [ngClass]="{ selecteddropdwon: bySecVal === 'Biotechnology' }"
                (click)="selectedCap('Biotechnology')"
                >Biotechnology</a
              >
            </li>
            <li>
              <a
                class="dropdown-item filter-bottom filter-font-text"
                [ngClass]="{ selecteddropdwon: bySecVal === 'Medical Device' }"
                (click)="selectedCap('Medical Device')"
                >Medical Device</a
              >
            </li>
            <li>
              <a
                class="dropdown-item filter-bottom filter-font-text"
                [ngClass]="{
                  selecteddropdwon: bySecVal === 'Diagnostics & Research'
                }"
                (click)="selectedCap('Diagnostics & Research')"
                >Diagnostics & Research</a
              >
            </li>

            <li>
              <a
                class="dropdown-item filter-font-text"
                [ngClass]="{
                  selecteddropdwon: bySecVal === 'Drug Manufacturers'
                }"
                (click)="selectedCap('Drug Manufacturers')"
                >Drug Manufacturers</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-md-auto formobileL col-lg-auto col-sm-auto p-0">
          <div class="d-grid by_Sector_div p-0">
            <div
              class="col-12 my-3 by_sector col-lg-auto col-md-auto col-sm-auto col-12"
            >
              <h3 class="sector_text">By Sector</h3>
            </div>
            <div
              class="col-12 my-3 by_sector col-lg-auto col-md-auto col-sm-auto col-12"
            >
              <button
                [ngClass]="{ selectedbg: bySecVal === 'Biotechnology' }"
                class="button-set2-banner"
                (click)="selectedCap('Biotechnology')"
              >
                Biotechnology
              </button>
            </div>
            <div
              class="col-12 my-3 by_sector col-lg-auto col-md-auto col-sm-auto col-12"
            >
              <button
                [ngClass]="{ selectedbg: bySecVal === 'Medical Device' }"
                class="button-set2-banner"
                (click)="selectedCap('Medical Device')"
              >
                Medical Device
              </button>
            </div>
            <div
              class="col-12 my-3 by_sector col-lg-auto col-md-auto col-sm-auto col-12"
            >
              <button
                [ngClass]="{
                  selectedbg: bySecVal === 'Diagnostics & Research'
                }"
                class="button-set2-banner"
                (click)="selectedCap('Diagnostics & Research')"
              >
                Diagnostics & Research
              </button>
            </div>
            <div
              class="col-12 my-3 by_sector col-lg-auto col-md-auto col-sm-auto col-12"
            >
              <button
                [ngClass]="{ selectedbg: bySecVal === 'Drug Manufacturers' }"
                class="button-set2-banner"
                (click)="selectedCap('Drug Manufacturers')"
              >
                Drug Manufacturers
              </button>
            </div>
          </div>
        </div>
        <div
          style="overflow: auto"
          class="col-md-12 d-flex heatmap-container col-lg-10 col-sm-12 p-0"
        >
          <div id="container"></div>
          <div id="containerN"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loginpop-up
  [displayStyle]="modalDisplayStyle"
  (myout)="GetChildData($event)"
></app-loginpop-up>
