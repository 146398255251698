@if (htmlContent) {

<div class="container blog-content">
  <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(htmlContent)"></div>
  <div>
    <hr class="m-0">
    <button class="box-share">
      <div class="social-share" (click)="shareBlog()">
        <i class="fa-solid fa-share-nodes" style="color: #0561ff; opacity: inherit; font-size: x-large;"></i>
      </div>
    </button>
  </div>
  <div class="text-center my-3">
    <button class="arrow-button" routerLink="/blog">
      <img src="/assets/images/blog-left.svg" alt="Previous" class="arrow-size" />
    </button>
  </div>

</div>



}@else {
  <ngx-skeleton-loader
  [theme]="{
    height: '600px',
    'border-radius': '4px',
    'margin-top': '20px'
  }"
  count="1"
>
</ngx-skeleton-loader>
}
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{ display: displayStyle }">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-center share-title">Share on social media</h4>
        <div class="d-flex align-items-center">
          <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closePopup()"
            aria-label="Close"></button>
        </div>
      </div>
      <div class="modal-body d-flex justify-content-center">
        <a class="ms-3" href="https://api.whatsapp.com/send?text=https://biopharmawatch.com/blog/{{blogId}}"
          target="_blank">
          <i class="fa-brands fa-whatsapp" style="color: #269009; opacity: inherit; font-size: x-large;"></i>
        </a>
        <a class="ms-3" href="https://www.linkedin.com/cws/share?url=https://biopharmawatch.com/blog/{{blogId}}"
          target="_blank">
          <i class="fa-brands fa-linkedin" style="color: #3673dd; opacity: inherit; font-size: x-large;"></i>
        </a>
        <a class="ms-3" href="https://www.facebook.com/sharer/sharer.php?u=https://biopharmawatch.com/blog/{{blogId}}"
          target="_blank">
          <i class="fa-brands fa-facebook" style="color: #045bf1; opacity: inherit; font-size: x-large;"></i>
        </a>
        <a class="ms-3" target="_blank">
          <i (click)="copyText(blogId)" class="fa-solid fa-copy"
            style="color: #0561ff; opacity: inherit; font-size: x-large; cursor: pointer;"></i>
        </a>

      </div>
    </div>
  </div>
</div>