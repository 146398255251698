import { Injectable } from '@angular/core';
import { FDATable } from '../models/fda-calendar';
import { HttpClient } from '@angular/common/http';

import {
  addSymbol,
  getFdaData,
  getFdaUpdates,
  getFdaUpdateslen,
  getFdaUpdateslenupdate,
  getFdafields,
  removeSymbol,
  remove_price,
  votting_price,
  votting_priceTarget,
} from '../const';

@Injectable({
  providedIn: 'root',
})
export class FdaCalenarService {
  constructor(private http: HttpClient) {}

  getFdaData(page: number) {
    return this.http.post<FDATable[]>(`${getFdaData}${page}`, '');
  }

  getStageValues() {
    return this.http.get<any[]>(`${getFdafields}`);
  }

  getFdaUpdate() {
    return this.http.get<any[]>(`${getFdaUpdates}`);
  }

  getNotificationlen() {
    return this.http.get<any[]>(`${getFdaUpdateslen}`);
  }

  getNotificationlenUpdate(data: object) {
    return this.http.post<any[]>(`${getFdaUpdateslenupdate}`, data);
  }

  addSymboltoPotfoliyo(data: any) {
    return this.http.post(`${addSymbol}`, data);
  }
  removeSymboltoPotfoliyo(data: any) {
    return this.http.post(`${removeSymbol}`, data);
  }
  upvote(data: object) {
    return this.http.post(`${votting_price}`, data);
  }
  removeVote(data: object) {
    return this.http.post(`${remove_price}`, data);
  }
  vottingprice(data: object) {
    return this.http.post(`${votting_priceTarget}`, data);
  }
}
