import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ForgotPasswordService } from '../../services/forgot-password.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  showPassword: boolean = false;
  shownewpassword: boolean = false;
  forgotpassword!: FormGroup;
  token!: string
  passwordsMatching = false;
  isConfirmPasswordDirty = false;
  confirmPasswordClass = 'form-control';

  constructor(private fb: FormBuilder, private forgotPassword: ForgotPasswordService, private route: ActivatedRoute, private router: Router, private toaster: ToastrService) {
  }

  ngOnInit() {
    this.forgotpassword = this.fb.group(
      {
        newPassword: new FormControl(null, [
          Validators.required
        ]),
        confirmPassword: new FormControl(null, [
          Validators.required
        ])
      },
      {
        validator: this.ConfirmedValidator('newPassword', 'confirmPassword')
      }
    );
    const token = this.route.snapshot.params['token'];

    if (!token) {
      this.router.navigate(['home']);
    }
  }


  onSubmit(data: any): void {
    const token = this.route.snapshot.params['token'];
    const confirmPasswordData = {
      password: data.confirmPassword
    };

    this.forgotPassword.Forgetpassword(token, confirmPasswordData).subscribe({
      next: (response: any) => {

        this.toaster.success(response.message);
        this.router.navigate(['login']);
      },
      error: (error) => {
        this.router.navigate(['home']);
      }
    })
  }

  resetPasswordVisibility() {
    this.showPassword = false;
    this.shownewpassword = false;
  }

  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors['confirmedValidator']
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
