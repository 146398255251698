<!--BioPharmaWatch Website User Guide-->

<section class="website">
  <div class="container p-3">
    <div class="text-center">
      <div class="accordion accordion-flush" id="accordionFlushExample">
        <h1 class="header-text text-white m-0 me-3">
          BioPharmaWatch Website User Guide
        </h1>
      </div>
    </div>
  </div>
</section>

<!--Introduction-->

<section class="Introduction mt-3 mb-3">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="text-center">
          <h2 class="main-blue-heading">Introduction</h2>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="container-fluid about-BiopharmaWatch p-5 pt-3 pb-3">
  <h4 class="header-text text-center m-0">About BiopharmaWatch</h4>
  <p class="mt-4 sub-blue-heading text-center">
    BiopharmaWatch is your trusted partner in navigating the complex and dynamic
    landscape of biotech and pharma investing. We combine the expertise of
    scientists, investment experts, and AI technology to provide you with
    real-time updates and analysis on more than 1,000 companies and their 85+
    key metrics. Our goal is to empower you with the information you need to
    make informed investment decisions and maximize your returns.
  </p>
</div>

<div class="mt-3">
  <h4 class="mt-3 header-text text-center m-0">How We Give You an Edge</h4>
  <p class="sub-blue-heading mt-4 text-center">
    Our rigorous research process ensures that you stay ahead of the curve when
    it comes to the latest developments in the biotech and pharma industries. By
    tracking crucial metrics such as clinical trial progress, FDA approvals, and
    financial performance, we provide you with personalized advice and alerts
    tailored to your investment goals and risk tolerance. With BiopharmaWatch,
    you'll have the tools and insights you need to excel in the competitive
    world of biotech investing.
  </p>
</div>
<!--Account Information & Setup-->

<!-- <section class="Information  mt-3 mb-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="text-center">
                    <h3 class="main-blue-heading">Account Information & Setup</h3>
                    <img src="assets/images/underline.svg" alt="" class="mb-3" />
                </div>
                <div class="website-heading">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12 d-flex">
                                <p>Subscriptions</p>
                                <div class="link-line"></div>
                                <div>
                                    <img src="assets/images/arrow1.svg" alt="" class="arrow" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container-fluid mt-3">
                        <div class="row">
                            <div class="col-12 d-flex">
                                <p class="text-nowrap">Account Details</p>
                                <div class="link-line"></div>
                                <div>
                                    <img src="assets/images/arrow1.svg" alt="" class="arrow" />
                                </div>
                            </div>
                        </div>
                    </div>




                    <div class="container-fluid mt-3">
                        <div class="row">
                            <div class="col-12 d-flex">
                                <p class="text-nowrap">Desktop Email Notification</p>
                                <div class="link-line"></div>
                                <div>
                                    <img src="assets/images/arrow1.svg" alt="" class="arrow" />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="container-fluid mt-3">
                        <div class="row">
                            <div class="col-12 d-flex">
                                <p class="text-nowrap">Personalized Portfolio</p>
                                <div class="link-line"></div>
                                <div>
                                    <img src="assets/images/arrow1.svg" alt="" class="arrow" />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="container-fluid mt-3">
                        <div class="row">
                            <div class="col-12 d-flex">
                                <p class="text-nowrap">Cancel Subscription</p>
                                <div class="link-line"></div>
                                <div>
                                    <img src="assets/images/arrow1.svg" alt="" class="arrow" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</section> -->

<!--catalyst Calendar & Tools-->

<section class="Tools mt-3">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="text-center">
          <h3 class="main-blue-heading">Key Features</h3>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="container-fluid Information p-0 mt-3 mb-3">
  <div class="accordion-key">
    <div>
      <div class="accordion" id="accordionExample">
        <!-- Accordion Item 1 -->
        <div class="accordion-item key-tab">
          <div class="container">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button collapsed header-text"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <span class="number-key">01</span> FDA Calendar
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Our FDA Calendar is your go-to resource for tracking upcoming
                PDUFA drug approval dates and FDA advisory committee meetings.
                With daily updates, you'll always have access to the most
                current information about future catalysts that can impact your
                investments.
              </div>
            </div>
          </div>
        </div>
        <!-- Accordion Item 2 -->
        <div class="accordion-item key-tab">
          <div class="container">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed header-text"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <span class="number-key">02</span> PDUFA Calendar
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Stay informed about upcoming drug approval dates and FDA
                advisory committee meetings through our PDUFA Calendar. This
                feature helps you plan your investment strategies by staying
                ahead of key regulatory events.
              </div>
            </div>
          </div>
        </div>

        <!-- Accordion Item 3 -->
        <div class="accordion-item key-tab">
          <div class="container">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed header-text"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <span class="number-key">03</span> CatalystSync™
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                CatalystSync™ is a comprehensive platform that offers a complete
                overview of clinical trials, drug pipelines, and more. Explore
                critical details such as phases, completion dates, working
                capital, runway, market cap, and targeted diseases. Uncover new
                investment opportunities and gain insights into the dynamic
                biotech industry using our powerful Filters function.
              </div>
            </div>
          </div>
        </div>
        <!-- Accordion Item 4 -->
        <div class="accordion-item key-tab">
          <div class="container">
            <h2 class="accordion-header" id="headingFour">
              <button
                class="accordion-button collapsed header-text"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                <span class="number-key">04</span> Company Screener
              </button>
            </h2>
            <div
              id="collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Our Company Screener provides a snapshot of a company's
                financial health and drug pipeline. Key financial metrics,
                including EPS, P/E Ratio, working capital, and debt-to-equity
                ratio, are presented alongside insights into financial
                sustainability through burn rate and runway analysis. The
                development timeline offers a clear picture of a company's
                future prospects.
              </div>
            </div>
          </div>
        </div>
        <!-- Accordion Item 5 -->
        <div class="accordion-item key-tab">
          <div class="container">
            <h2 class="accordion-header" id="headingFive">
              <button
                class="accordion-button collapsed header-text"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                <span class="number-key">05</span> Inside Trades
              </button>
            </h2>
            <div
              id="collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Access our Insider Trades tool to stay informed about the latest
                trades made by top executives and insiders. This feature
                provides valuable insights into the actions of key players
                within the companies you're interested in.
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item key-tab">
          <div class="container">
            <h2 class="accordion-header" id="headingSix">
              <button
                class="accordion-button collapsed header-text"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                <span class="number-key">06</span> Biotech Earnings Calendar
              </button>
            </h2>
            <div
              id="collapseSix"
              class="accordion-collapse collapse"
              aria-labelledby="headingSix"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Stay ahead of the earnings game with our Biotech Earnings
                Calendar. This calendar helps you keep track of important
                earnings announcements, enabling you to align your investment
                strategies accordingly.
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item key-tab">
          <div class="container">
            <h2 class="accordion-header" id="headingSeven">
              <button
                class="accordion-button collapsed header-text"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeven"
                aria-expanded="false"
                aria-controls="collapseSeven"
              >
                <span class="number-key">07</span> Hedge Funds
              </button>
            </h2>
            <div
              id="collapseSeven"
              class="accordion-collapse collapse"
              aria-labelledby="headingSeven"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Explore the Biotech and Pharma Hedge Fund List and Holding
                Screener – Dive into quarterly updated 13-F filings of top
                biotech hedge funds, revealing holdings, performance, and
                strategies. Utilize filters like Ticker, Fund Name, Fund Size,
                and Quarterly Performance to refine your search and uncover
                market trends.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="d-flex justify-content-center text-center mt-3 container">
  <p class="sub-heading">
    Thank you for choosing BiopharmaWatch as your partner in biotech and pharma
    investing. With our powerful tools and insights, you're equipped to navigate
    the market with confidence and make well-informed investment decisions. If
    you have any questions or need assistance, feel free to reach out to our
    support team. Happy investing!
  </p>
</div>
