import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getUsers } from '../const';
@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private http: HttpClient) {}

  getUsers(search?: string, page?: any, productPlan?: string, sort?: string) {
    return this.http.get<any[]>(
      `${getUsers}?page=${page}&search=${search}&productPlan=${productPlan}&sort=${sort}`
    );
  }
}
