<div class="container">
  <div class="row mb-2">
    <div class="d-flex justify-content-evenly mt-3 mb-1">
      <div class="w-25">
        <button
          [ngClass]="{ selectedbg: newsType === 'event' }"
          class="mx-1 px-2 button-set2-banner m-1"
          (click)="selectType('event')"
        >
          Events
        </button>
      </div>
      <div class="w-25">
        <button
          class="mx-1 px-2 button-set2-banner m-1"
          [ngClass]="{ selectedbg: newsType === 'Phase 1' }"
          (click)="selectType('Phase 1')"
        >
          Phase 1
        </button>
      </div>
      <div class="w-25">
        <button
          [ngClass]="{ selectedbg: newsType === 'Phase 2' }"
          class="mx-1 px-2 button-set2-banner m-1"
          (click)="selectType('Phase 2')"
        >
          Phase 2
        </button>
      </div>
      <div class="w-25">
        <button
          [ngClass]="{ selectedbg: newsType === 'Phase 3' }"
          class="mx-1 px-2 button-set2-banner m-1"
          (click)="selectType('Phase 3')"
        >
          Phase 3
        </button>
      </div>
      <!-- <div class="w-25">
     <input type="text" [(ngModel)]="searchText" class="form-control" placeholder="Search" />
      </div> -->
    </div>
    <p
      class="text-start tblcompanyLength my-1 text-nowrap d-flex align-items-center"
    >
      <i class="fa-solid fa-circle-info mx-2"></i> Showing
      {{ newsItems.length }} out of {{ newsCount }} News
    </p>
  </div>
  <div
    class="row scrollable-table"
    id="dataContainer"
    #dataContainer
    (scroll)="onScroll($event)"
  >
    @if (newsItems.length ==0) {
      <div class="container">
        <ngx-skeleton-loader
          [theme]="{
            height: '150px',
            'border-radius': '4px',
            'margin-top': '20px'
          }"
          count="5"
        >
        </ngx-skeleton-loader>
      </div>
    } @else { @for (item of newsItems; track $index) {

    <div class="col-md-12">
      <div class="row mb-4">
        <div class="col-md-12">
          <div class="outer-main small-single-sec layout-right">
            <h4 class="head-text">{{ item.title }}</h4>
            <div class="d-flex align-items-center stock-details">
              <div class="dateText mb-0 me-2">
                <span class="news-date">
                  <b> {{ item.published }}</b></span
                >
              </div>
              <div class="tag-btn mrgn-lft-12 me-2">
                @if (item.ticker) {
                <a
                  class="link-ticker most-value-btn"
                  routerLink="/company/{{ item.ticker }}"
                >
                  {{ item.ticker }} </a
                >}
              </div>
              <div class="d-flex">
                <p
                  class="company-plus-minus me-2 mb-0 d-flex align-items-center"
                  [style.color]="item.close | riseAndFallColor"
                >
                  {{ item.close | number : "1.0-2" }}
                </p>
                <p
                  class="company-plus-minus mb-0 d-flex align-items-center"
                  [style.color]="item.change | riseAndFallColor"
                >
                  ({{ item.change | number : "1.0-2" }}%)
                </p>
              </div>
            </div>
            @if (item.content) {
            <p class="news-content">
              {{ item.content[1] | slice : 0 : 500 }}...
              <span
                style="font-size: 12px"
                (click)="openpopup(item)"
                class="text-danger cursorpointer"
              >
                <b>Read more+</b>
              </span>
            </p>

            }@else {
            <p>-</p>
            }
          </div>
        </div>
        <hr class="uk-hr" />
      </div>
    </div>
    } @empty { No Record Found }} @if (loadData) {
    <tr class="loading-border">
      <td colspan="12">
        <div class="data-loading-container d-flex">
          <p class="Fetching me-3 mb-0 mt-0">Fetching more data for you</p>
          <span class="loader mb-2" style="font-size: 3px"> </span>
        </div>
      </td>
    </tr>
    }
  </div>

  <!-- <div class="d-flex justify-content-evenly mt-3 mb-3">
        <div class="w-25">
            <button [ngClass]="{'selectedbg':newsType === 'event'}" class="mx-1 px-2 button-set2-banner m-1"
                (click)="selectType('event')">Events</button>
        </div>
        <div class="w-25">
            <button class="mx-1 px-2 button-set2-banner m-1" [ngClass]="{'selectedbg':newsType === 'Phase 1'}"
                (click)="selectType('Phase 1')">Phase 1</button>
        </div>
        <div class="w-25">
            <button [ngClass]="{'selectedbg':newsType === 'Phase 2'}" class="mx-1 px-2 button-set2-banner m-1"
                (click)="selectType('Phase 2')">Phase 2</button>
        </div>
        <div class="w-25">
            <button [ngClass]="{'selectedbg':newsType === 'Phase 3'}" class="mx-1 px-2 button-set2-banner m-1"
                (click)="selectType('Phase 3')">Phase 3</button>
        </div>
    </div>

    <div class="table-responsive scrollable-table" id="dataContainer" #dataContainer (scroll)="onScroll($event)">

        <table class=" table align-middle mb-0 bg-white">
            <thead class="text-center sticky">
                <tr class="text-uppercase table-header">
                    <th class="table-name-size">
                        <div class="d-flex justify-content-center cursor-pointer">published
                        </div>
                    </th>
                    <th class="table-name-size">
                        <div class="d-flex justify-content-center cursor-pointer">ticker
                        </div>
                    </th>
                    <th class="table-name-size">
                        <div class="d-flex justify-content-center cursor-pointer">title
                        </div>
                    </th>
                    <th class="table-name-size">
                        <div class="d-flex justify-content-center cursor-pointer">content
                        </div>
                    </th>
                </tr>
            </thead>

            <tbody id="tbody" class="text-center">
                @if (newsItems.length ==0) {}
                @else {
                @for (news of newsItems; track $index;) {
                <tr class="table-tr-font">
                    <td>
                        <p>{{news.published}}</p>
                    </td>
                    <td scope="row">
                        <p class="cursorpointer" (click)="companyDetails(news.ticker)">{{news.ticker}}</p>
                    </td>
                    <td>
                        <p>{{news.title}}</p>
                    </td>

                    <td>

                        @if(!showFullContent[ $index]){
                        <p>{{news.content | slice:0:100}}...</p>
                        <button class="btn btn-link p-0" (click)="toggleContent($index)">Read more</button>
                        }

                        @if(showFullContent[ $index]){
                        <p>{{news.content}}</p>
                        <button class="btn btn-link p-0" (click)="toggleContent($index)">Read less</button>
                        }

                    </td>

                </tr>
                }
                @empty {
                No Record Found
                }
                }
            </tbody>

        </table>

    </div> -->
</div>

<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngStyle]="{ display: displayStyle }"
>
  <div
    class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title d-flex align-items-center text-light">
          Description
        </h4>
        <div class="d-flex align-items-center">
          <button type="button" class="btn" (click)="closePopup()">
            <i class="fa-solid fa-xmark text-white" style="opacity: unset"></i>
          </button>
        </div>
      </div>
      <div class="modal-body">
        @if (newsItem!=null) {
        <h4 class="head-text">{{ newsItem.title }}</h4>
        <div class="dateText mb-1">
          <div class="d-flex align-items-center">
            <span class="me-1 news-date"
              ><b>{{ newsItem.published }}</b></span
            >
            @if (newsItem.ticker) {
            <a
              class="link-ticker most-value-btn me-1"
              style="font-size: 13px"
              routerLink="/company/{{ newsItem.ticker }}"
            >
              {{ newsItem.ticker }} </a
            >}

            <p
              class="company-plus-minus me-2 mb-0 d-flex align-items-center me-1"
              [style.color]="newsItem.close | riseAndFallColor"
              style="font-size: 13px"
            >
              {{ newsItem.close | number : "1.0-2" }}
            </p>
            <p
              class="company-plus-minus mb-0 d-flex align-items-center"
              [style.color]="newsItem.change | riseAndFallColor"
              style="font-size: 13px"
            >
              ({{ newsItem.change | number : "1.0-2" }}%)
            </p>
          </div>
        </div>
        @for (item of newsItem.content; track $index) {
        <p class="news-content">{{ item }}</p>
        }}
      </div>
    </div>
  </div>
</div>
