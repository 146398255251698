import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Validators, Editor, Toolbar, NgxEditorModule } from 'ngx-editor';
import { ToastrService } from 'ngx-toastr';
import { BlogService } from 'src/app/modules/shared/service/blog.service';
@Component({
  selector: 'app-blogadmin',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule, NgxEditorModule],
  templateUrl: './blogadmin.component.html',
  styleUrl: './blogadmin.component.scss',
})
export class BlogadminComponent {
  editordoc = '';
  htmlContent!: any;
  blogtitle: any;
  blogintro: any;
  blogthumb = '/assets/images/example.png';
  editor!: Editor;
  toolbar: Toolbar = [
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['bold', 'italic', 'underline'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
    ['indent', 'outdent'],
    ['ordered_list', 'bullet_list'],
    ['link', 'image'],
    ['superscript', 'subscript'],
    ['undo', 'redo'],
  ];
  isLoading = false;

  form = new FormGroup({
    editorContent: new FormControl(
      { value: this.editordoc, disabled: false },
      Validators.required()
    ),
  });

  constructor(
    public sanitizer: DomSanitizer,
    private blogService: BlogService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.editor = new Editor();
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  getEditorContent() {
    this.htmlContent = this.form.get('editorContent')?.value;
    let jsoncontetnt = JSON.stringify(this.htmlContent);

    let content = {
      title: this.blogtitle,
      blogurl: this.blogtitle.split(' ').join('_'),
      intro: this.blogintro,
      thumb: this.blogthumb,
      htmlContent: jsoncontetnt,
    };
    this.isLoading = true;

    //Save the content to the database
    this.blogService.saveblogdata(content).subscribe((res: any) => {
      this.isLoading = false;

      this.toaster.success(res);
      this.editor.setContent('');
    });
  }

  onFileChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsDataURL(file);
    }
  }

  handleReaderLoaded(event: any): void {
    this.blogthumb = event.target.result;
  }
}
