import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FdaCalenarService } from '../../service/fda-calendar.service';
import { DecryptionService } from '../../service/decryption.service';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-portfoliomodal',
  standalone: true,
  templateUrl: './portfoliomodal.component.html',
  styleUrl: './portfoliomodal.component.scss',
  imports: [FormsModule, ReactiveFormsModule, SharedModule],
})
export class PortfoliomodalComponent {
  @Input() portfolioTicker: string = '';
  @Input() portfolioPrice: string = '';
  @Input() portfoliocost: number | undefined;
  @Input() portfolioquntity: number | undefined;
  @Output() addSymbol = new EventEmitter<any>();

  modalDisplayStyle!: boolean;

  constructor(
    private toaster: ToastrService,
    private fdacalendarservice: FdaCalenarService,
    private decryptionService: DecryptionService
  ) {}
  ngOnInit() {
    // Reset input fields when modal is initialized
    this.portfoliocost = undefined;
    this.portfolioquntity = undefined;
  }

  addSymboltoPotfoliyo() {
    let loginuser = JSON.parse(localStorage.getItem('loggedInUser') || '{}');

    // Emit event with data
    this.addSymbol.emit({
      email: loginuser.email,
      ticker: this.portfolioTicker,
      cost: this.portfoliocost,
      holdings: this.portfolioquntity,
    });

    let obj = {
      email: loginuser.email,
      ticker: this.portfolioTicker,
      cost: this.portfoliocost,
      holdings: this.portfolioquntity,
    };

    if (loginuser.email) {
      this.fdacalendarservice.addSymboltoPotfoliyo(obj).subscribe({
        next: (res: any) => {
          // const decryptData = this.decryptionService.decrypt(res);

          // const ParseDecryptedData = JSON.parse(decryptData);

          this.portfoliocost = undefined;
          this.portfolioquntity = undefined;
          this.toaster.success('Symbol added to your portfolio');
        },
      });
    } else {
      this.modalDisplayStyle = true;
    }
  }

  GetChildData(data: any) {
    this.modalDisplayStyle = data;
  }

  resetPortfolioInputs() {
    this.portfoliocost = undefined;
    this.portfolioquntity = undefined;
  }
}
