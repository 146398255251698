<div class="col volatile-content">
  @for (company of companyDetails; track $index) { @if (company) {

  <div class="container-fluid p-3 company-head-box">
    <div class="big-screen-title">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-6">
          <div class="row">
            <div class="col-md-12">
              <div class="company-img d-flex align-items-center">
                @if (company.logoid !== '') {
                <img
                  width="25px"
                  [src]="basePath + company.logoid + '.svg'"
                  class="mb-2"
                />
                }@else {
                <span class="avatarcssForTable">{{
                  company.name | initialLetter
                }}</span>
                }
                <h2 class="text-start mb-0 ms-2 company-name-text">
                  {{ company.name }}
                </h2>
                @if(portfoliomatchTicker.includes(company.symbol)){
                <i
                  placement="bottom"
                  container="body"
                  ngbTooltip="Remove from Portfolio"
                  (click)="removeSymboltoPotfoliyo(company.symbol)"
                  style="
                    opacity: unset;
                    font-size: 20px;
                    color: var(--clr-primary);
                  "
                  class="fa-solid fa-circle-minus mx-2 cursorpointer"
                ></i>
                }@else {
                <i
                  placement="bottom"
                  container="body"
                  ngbTooltip="Add to Portfolio"
                  (click)="
                    this.portfolioTicker = company.symbol;
                    this.portfolioPrice = company.close;
                    portfoliocost = undefined;
                    portfolioquntity = undefined
                  "
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style="
                    opacity: unset;
                    font-size: 20px;
                    color: var(--clr-primary);
                  "
                  class="fa-solid fa-circle-plus mx-3 cursorpointer"
                ></i>
                }
              </div>
            </div>
            <div class="col-md-12">
              <li
                class="d-flex mainsecdivforeps1 justify-content-start align-items-center"
              >
                <div class="d-grid company-header-name">
                  <div class="d-flex align-items-center justify-content-evenly">
                    <p class="most-value-btn me-2 mb-0">{{ company.symbol }}</p>
                    <p
                      class="close_price_width-lep text-center me-2 text-nowrap mb-0"
                      title=""
                    >
                      {{ company.close | number : "1.0-2" }}
                      <span class="currency">{{ company.currency }}</span>
                    </p>
                    <div class="d-flex">
                      <p
                        class="company-plus-minus me-2 mb-0 d-flex align-items-center"
                        [style.color]="company.change_abs | riseAndFallColor"
                      >
                        {{ company.change_abs | number : "1.0-2" }}
                      </p>
                      <p
                        class="company-plus-minus mb-0 d-flex align-items-center"
                        [style.color]="company.change | riseAndFallColor"
                      >
                        ({{ company.change | number : "1.0-2" }}%)
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 center-content d-flex align-items-center">
          <div>
            <div class="d-grid justify-content-center mainsecdivforeps">
              <div class="d-flex align-items-center scroll-head">
                <div class="d-flex justify-content-center secondeps">
                  <div class="me-4 childdivforeps">
                    <p
                      [ngClass]="{ blur: !this.productId }"
                      class="company-plus-minus justify-content-center mb-0 d-flex align-items-center text-nowrap"
                      [style.color]="
                        company.earnings_per_share_basic_ttm | riseAndFallColor
                      "
                    >
                      {{
                        company.earnings_per_share_basic_ttm
                          | number : "1.0-2"
                          | zerotoDash
                      }}
                    </p>
                    <p class="text-center text-nowrap epstext">EPS</p>
                  </div>
                  <div class="me-4 childdivforeps">
                    <p
                      [ngClass]="{ blur: !this.productId }"
                      [style.color]="
                        company.market_cap_basic | riseAndFallColor
                      "
                      class="company-plus-minus justify-content-center mb-0 d-flex align-items-center text-nowrap"
                    >
                      {{ company.market_cap_basic | dollarsCoversion }}
                    </p>
                    <p class="text-center text-nowrap epstext">Market Cap</p>
                  </div>
                  <div class="me-4 childdivforeps">
                    <p
                      [ngClass]="{ blur: !this.productId }"
                      [style.color]="
                        company.price_earnings_ttm | riseAndFallColor
                      "
                      class="company-plus-minus justify-content-center mb-0 d-flex align-items-center"
                    >
                      {{
                        company.price_earnings_ttm
                          | number : "1.0-2"
                          | zerotoDash
                      }}
                    </p>
                    <p class="text-center text-nowrap epstext">P/E Ratio</p>
                  </div>
                  <div class="me-4 childdivforeps">
                    <p
                      [ngClass]="{ blur: !this.productId }"
                      [style.color]="company.value_traded | riseAndFallColor"
                      class="company-plus-minus justify-content-center mb-0 d-flex align-items-center text-nowrap"
                    >
                      {{ company.value_traded | dollarsCoversion }}
                    </p>
                    <p class="text-center text-nowrap epstext">Value Traded</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-11 col-12">
          @for (company of companyDetails; track $index) { @if (company) {
          <div class="d-flex company-bio-box">
            <p class="company-sub-details m-0 me-4 ms-0">
              <span class="company-bio-data">Headquarters :</span>
              <span [ngClass]="{ blur: !this.productId }">
                {{ company.Address }}</span
              >
            </p>
            <p class="company-sub-details m-0 me-4">
              <span class="company-bio-data">Founded :</span>
              <span [ngClass]="{ blur: !this.productId }">
                {{ company.Foundation_Year }}</span
              >
            </p>
            <p class="company-sub-details m-0 me-4">
              <span class="company-bio-data">Employees :</span>
              <span [ngClass]="{ blur: !this.productId }">
                {{ company.Full_Time_Employees }}</span
              >
            </p>
            <p class="m-0 company-sub-details">
              <span class="company-bio-data">Website : </span
              ><a
                [ngClass]="{ blur: !this.productId }"
                style="color: #000"
                href="{{ company.Website }}"
                target="_blank"
                >{{ company.Website }}</a
              >
            </p>
          </div>
          } }@empty { No Record Found }
        </div>
      </div>
    </div>
  </div>
  } }@empty {
  <div class="">
    <ngx-skeleton-loader
      [theme]="{
        height: '100px',
        'border-radius': '4px',
        'margin-top': '20px'
      }"
      count="1"
    >
    </ngx-skeleton-loader>
  </div>
  }
</div>

<div class="container-fluid mt-4 box-scroll company-responsive-pd rounded p-0">
  <div class="d-flex justify-content-center table-button my-3">
    @if(this.catalystresultData.length > 0) {

    <div class="text-center mx-1">
      <p
        (click)="scrollToSection(catalystresultSection)"
        class="table-option mb-1 text-light"
      >
        Clinical Trial Results
      </p>
    </div>
    } @if (istradesShow) {
    <div class="text-center mx-1">
      <p
        (click)="scrollToSection(insideTradesSection)"
        class="table-option mb-1 text-light"
      >
        Inside Trades
      </p>
    </div>
    } @if (upcomingdatashow) {
    <div class="text-center mx-1">
      <p
        class="table-option mb-1 text-light"
        (click)="scrollToSection(upcomingcatalystsection)"
      >
        Upcoming Catalyst
      </p>
    </div>
    } @if (isPipelineDataShow) {
    <div class="text-center mx-1">
      <p
        class="table-option mb-1 text-light"
        (click)="scrollToSection(drugPipelineSection)"
      >
        Drug Pipeline
      </p>
    </div>
    } @if (hedgedataShow) {
    <div class="text-center mx-1">
      <p
        (click)="scrollToSection(HedgeFundsSection)"
        class="table-option mb-1 text-light"
      >
        Hedge Funds
      </p>
    </div>
    } @if (this.optionTwotable.length > 0) {
    <div class="text-center mx-1">
      <p
        (click)="scrollToSection(optiondataSection)"
        class="table-option mb-1 text-light"
      >
        Options Data
      </p>
    </div>
    } @if (showNewdata) {
    <div class="text-center mx-1">
      <p
        (click)="scrollToSection(NewsSectionSection)"
        class="table-option mb-1 text-light"
      >
        News Section
      </p>
    </div>
    }
  </div>

  <div class="container-fluid company-responsive-pd p-0 mt-4">
    <div class="row m-0 p-0 justify-content-evenly">
      <div class="col-lg-7 company-tablet-wrap col-md-6 col-sm-12 p-0">
        <div class="chart">
          <div class="custom-height-column" id="tradingview_68936"></div>
        </div>

        <div class="col-sm-12 company-responsive-pd p-0 my-3">
          @for (company of companyDetails; track $index) { @if (company) {
          <div class="performance">
            <h2 class="seconddiv company-detail-list">Performance</h2>
            <div
              class="d-flex justify-content-between align-items-center performance-wrap"
            >
              <div
                class="performance-box text-center p-1 mt-2"
                [style.background]="company.perf_w | companyPerformance"
              >
                <p class="company-data-details m-0">Week</p>
                <p
                  [ngClass]="{ blur: !this.productId }"
                  [style.color]="company.perf_w | riseAndFallColor"
                  class="company-api-data text-nowrap m-0"
                >
                  {{ company.perf_w | number : "1.0-2" }} %
                </p>
              </div>

              <div
                class="performance-box text-center p-1 mt-2"
                [style.background]="company.perf_1_m | companyPerformance"
              >
                <p class="company-data-details m-0">1 Month</p>
                <p
                  [ngClass]="{ blur: !this.productId }"
                  [style.color]="company.perf_1_m | riseAndFallColor"
                  class="company-api-data text-nowrap m-0"
                >
                  {{ company.perf_1_m | number : "1.0-2" }} %
                </p>
              </div>

              <div
                class="performance-box text-center p-1 mt-2"
                [style.background]="company.perf_3_m | companyPerformance"
              >
                <p class="company-data-details m-0">3 Month</p>
                <p
                  [ngClass]="{ blur: !this.productId }"
                  [style.color]="company.perf_3_m | riseAndFallColor"
                  class="company-api-data text-nowrap m-0"
                >
                  {{ company.perf_3_m | number : "1.0-2" }} %
                </p>
              </div>

              <div
                class="performance-box text-center p-1 mt-2"
                [style.background]="company.perf_6_m | companyPerformance"
              >
                <p class="company-data-details m-0">6 Month</p>
                <p
                  [ngClass]="{ blur: !this.productId }"
                  [style.color]="company.perf_6_m | riseAndFallColor"
                  class="company-api-data text-nowrap m-0"
                >
                  {{ company.perf_6_m | number : "1.0-2" }} %
                </p>
              </div>

              <div
                class="performance-box text-center p-1 mt-2"
                [style.background]="company.perf_5y | companyPerformance"
              >
                <p class="company-data-details m-0">5 Year</p>
                <p
                  [ngClass]="{ blur: !this.productId }"
                  [style.color]="company.perf_5y | riseAndFallColor"
                  class="company-api-data text-nowrap m-0"
                >
                  {{ company.perf_5y | number : "1.0-2" }} %
                </p>
              </div>

              <div
                class="performance-box text-center p-1 mt-2"
                [style.background]="company.perf_all | companyPerformance"
              >
                <p class="company-data-details m-0">All Time</p>
                <p
                  [ngClass]="{ blur: !this.productId }"
                  [style.color]="company.perf_all | riseAndFallColor"
                  class="company-api-data text-nowrap m-0"
                >
                  {{ company.perf_all | number : "1.0-2" }} %
                </p>
              </div>
            </div>
          </div>
          } }@empty {
          <div class="">
            <ngx-skeleton-loader
              [theme]="{
                height: '100px',
                'border-radius': '4px',
                'margin-top': '20px'
              }"
              count="1"
            >
            </ngx-skeleton-loader>
          </div>
          }
        </div>
      </div>

      <div
        class="col-lg-3 company-tablet-wrap col-md-4 col-sm-12 company-responsive-pd p-0"
      >
        <div class="">
          <div class="bordered p-0">
            <div class="cashdata">
              @if (isCashDataShow) {
              <div class="first-list-cashdata">
                @for (companyCash of companyDetails; track $index) { @if
                (companyCash) {
                <div>
                  <h2
                    class="company-detail-list seconddiv Cash-Data-head text-center text-white m-0"
                  >
                    Cash-Data
                  </h2>

                  <div
                    class="d-flex justify-content-between company-border-line cashdata-space"
                  >
                    <p class="company-data-details m-0">Live-Cash</p>
                    <p
                      [ngClass]="{ blur: !this.productId }"
                      [style.color]="
                        companyCash.Cash_and_Cash_Equivalents | riseAndFallColor
                      "
                      class="company-api-data m-0"
                    >
                      {{
                        companyCash.Cash_and_Cash_Equivalents | dollarsCoversion
                      }}
                    </p>
                  </div>

                  <div
                    class="d-flex justify-content-between company-border-line cashdata-space"
                  >
                    <p class="company-data-details m-0">Runway</p>
                    <p
                      class="company-api-data m-0"
                      [ngClass]="{ blur: !this.productId }"
                    >
                      {{
                        companyCash["Runway_(Months)"]
                          | number : "1.0-2"
                          | zerofortable
                      }}
                    </p>
                  </div>

                  <div
                    class="d-flex justify-content-between company-border-line cashdata-space"
                  >
                    <p class="company-data-details m-0">Burn Rate</p>
                    <p
                      [ngClass]="{ blur: !this.productId }"
                      [style.color]="
                        companyCash.Monthly_Burn_Rate | riseAndFallColor
                      "
                      class="company-api-data m-0"
                    >
                      {{ companyCash.Monthly_Burn_Rate | dollarsCoversion }}
                    </p>
                  </div>

                  @for (company of companyDetails; track $index) {
                  <div
                    class="d-flex justify-content-between company-border-line cashdata-space"
                  >
                    <p class="company-data-details m-0">Debt to Equity Ratio</p>
                    <p
                      [ngClass]="{ blur: !this.productId }"
                      [style.color]="
                        company.Debt_to_Equity_Ratio | riseAndFallColor
                      "
                      class="company-api-data m-0"
                    >
                      {{ company.Debt_to_Equity_Ratio | number : "1.0-2" }}
                    </p>
                  </div>
                  }
                </div>
                } }@empty {
                <div class="container">
                  <ngx-skeleton-loader
                    [theme]="{
                      height: '300px',
                      'border-radius': '4px',
                      'margin-top': '20px'
                    }"
                    count="1"
                  >
                  </ngx-skeleton-loader>
                </div>
                }
              </div>
              }
            </div>
          </div>
        </div>
        <div class="mt-4">
          <div class="bordered p-0">
            @for (company of companyDetails; track $index) { @if (company) {
            <div class="oview">
              <div class="first-list-Overview">
                <h2
                  class="company-detail-list Overview-head text-center text-white m-0"
                >
                  Overview
                </h2>
                <div
                  class="d-flex justify-content-between company-border-line overview-space"
                >
                  <p class="company-data-details m-0">Volume</p>
                  <p
                    [ngClass]="{ blur: !this.productId }"
                    [style.color]="company.volume | riseAndFallColor"
                    class="company-api-data m-0"
                  >
                    {{ company.volume | dollarsCoversion }}
                  </p>
                </div>
                <div
                  class="d-flex justify-content-between company-border-line overview-space"
                >
                  @if (company['52_Week_Range'] !== null) {
                  <p class="company-data-details m-0">52 Week Range</p>
                  <p
                    [ngClass]="{ blur: !this.productId }"
                    class="company-api-data m-0"
                  >
                    {{ company["52_Week_Range"] }}
                  </p>
                  }@else {
                  <p class="m-0">-</p>
                  }
                </div>
                <div
                  class="d-flex justify-content-between company-border-line overview-space"
                >
                  <p class="company-data-details m-0">% held by Insiders</p>
                  <p
                    [ngClass]="{ blur: !this.productId }"
                    class="company-api-data m-0"
                  >
                    {{
                      company.percent_Held_by_Insiders * 100
                        | number : "1.0-2"
                        | qoqChg
                    }}
                  </p>
                </div>
                <div
                  class="d-flex justify-content-between company-border-line overview-space"
                >
                  <p class="company-data-details m-0">% held by Institutions</p>
                  <p
                    [ngClass]="{ blur: !this.productId }"
                    class="company-api-data m-0"
                  >
                    {{
                      company.percent_Held_by_Institutions * 100
                        | number : "1.0-2"
                        | qoqChg
                    }}
                  </p>
                </div>
                <div
                  class="d-flex justify-content-between company-border-line overview-space"
                >
                  <p class="company-data-details m-0">Enterprise Value</p>
                  <p
                    [ngClass]="{ blur: !this.productId }"
                    [style.color]="company.Enterprise_Value | riseAndFallColor"
                    class="company-api-data m-0"
                  >
                    {{ company.Enterprise_Value | dollarsCoversion }}
                  </p>
                </div>
                <div
                  class="d-flex justify-content-between company-border-line overview-space"
                >
                  <p class="company-data-details m-0">Total Shares</p>
                  <p
                    [ngClass]="{ blur: !this.productId }"
                    [style.color]="company.Total_Shares | riseAndFallColor"
                    class="company-api-data m-0"
                  >
                    {{ company.Total_Shares | dollarsCoversion }}
                  </p>
                </div>
                <div
                  class="d-flex justify-content-between company-border-line overview-space"
                >
                  <p class="company-data-details m-0">Short %</p>
                  <p
                    [ngClass]="{ blur: !this.productId }"
                    class="company-api-data m-0"
                  >
                    {{
                      company.Short_percent_of_Float * 100
                        | number : "1.0-2"
                        | qoqChg
                    }}
                  </p>
                </div>
                <div
                  class="d-flex justify-content-between company-border-line overview-space"
                >
                  <p class="company-data-details m-0">Float Shares</p>
                  <p
                    [ngClass]="{ blur: !this.productId }"
                    [style.color]="company.Float_Shares | riseAndFallColor"
                    class="company-api-data m-0"
                  >
                    {{ company.Float_Shares | dollarsCoversion }}
                  </p>
                </div>
              </div>
            </div>
            } }@empty {
            <div class="container">
              <ngx-skeleton-loader
                [theme]="{
                  height: '300px',
                  'border-radius': '4px',
                  'margin-top': '20px'
                }"
                count="1"
              >
              </ngx-skeleton-loader>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row justify-content-center">
      @if (companyDescription) {
      <div class="col-md-11 company-responsive-pd mt-3">
        @for (companyCash of companyDetails; track $index) {
        <div>
          <h2 class="company-detail-list">Company Description</h2>
          <hr />
          @if (companyCash.Company_Description == 'locked') {
          <p [ngClass]="{ blur: !this.productId }" class="m-0">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio,
            id reprehenderit corporis perferendis impedit magni eligendi
            repellendus quas nemo similique, eos et deserunt. Officiis deserunt
            quod nulla consequatur libero laborum.
          </p>
          }@else {
          <p class="m-0">
            {{
              showFullDescription
                ? companyCash.Company_Description
                : (companyCash.Company_Description | slice : 0 : 200)
            }}

            @if (companyCash.Company_Description.length > 200) {
            <span>
              <a
                class="cursorpointer"
                style="
                  color: var(--clr-red);
                  font-weight: bolder;
                  font-size: 14px;
                "
                (click)="toggleDescription()"
              >
                {{ showFullDescription ? "Less-" : "Read More+" }}
              </a>
            </span>
            }
          </p>
          }
        </div>
        }@empty {
        <ngx-skeleton-loader
          [theme]="{
            height: '50px',
            'border-radius': '4px',
            'margin-top': '20px'
          }"
          count="1"
        >
        </ngx-skeleton-loader>
        }
      </div>
      }
    </div>
  </div>
</div>

<div class="mb-3">
  <div class="container-fluid">
    <div class="row justify-content-center">
      @if (catalystresult) {
      <div
        [ngClass]="customclassforcatalystresult"
        class="col-md-11 col-sm-12 mt-3 m-0"
      >
        <div class="pipeline" style="width: 100%">
          <div class="table-div p-0">
            <h2 #catalystresultSection class="company-detail-list mb-4">
              Clinical Trial Results
            </h2>
            <div [ngClass]="customclassforcatalystresult" id="dataContainer">
              @if(!this.productId){
              <table class="table table-fixed align-middle mb-0 bg-white p-0">
                <thead class="text-center sticky-header">
                  <tr class="text-uppercase table-header">
                    <th>Ticker</th>
                    <th>phase</th>
                    <th>trial results summary</th>
                    <th>date</th>
                    <th>drug name</th>
                    <th>indications</th>
                    <th>title</th>
                  </tr>
                </thead>
                <tbody class="text-center blurCataresult">
                  @for (upcoming of catalystresultData; track $index) {
                  <tr class="table-tr-font">
                    <td class="table-wide-cell">
                      <div class="table-font-size">{{ upcoming.ticker }}</div>
                    </td>

                    <td class="table-wide-cell">
                      <div class="mx-2">
                        <p class="phase-1">
                          {{ upcoming.clinical_trial_phase }}
                        </p>
                        @if (upcoming.clinical_trial_phase) {
                        <app-stage-progress
                          stage="{{ upcoming.clinical_trial_phase }}"
                        ></app-stage-progress>
                        }@else {
                        <p>-</p>
                        }
                      </div>
                    </td>
                    <td class="table-wide-cell table-text-blue">
                      <div class="table-font-size mx-2">
                        {{
                          upcoming.clinical_trial_results_summary[0].slice(
                            0,
                            60
                          )
                        }}
                        @if (upcoming.clinical_trial_results_summary[0].length >
                        60) {
                        <p
                          triggers="click:blur"
                          style="cursor: pointer"
                          class="text-danger"
                          container="body"
                          ngbTooltip="{{
                            upcoming.clinical_trial_results_summary
                          }}"
                        >
                          <strong>Read More+</strong>
                        </p>
                        }
                      </div>
                    </td>
                    <td class="table-wide-cell table-text-blue">
                      <div class="table-font-size mx-2">
                        {{ upcoming.date }}
                      </div>
                    </td>
                    <td class="table-wide-cell table-text-blue">
                      <div class="table-font-size mx-2">
                        {{ upcoming.drug_name }}
                      </div>
                    </td>
                    <td class="table-wide-cell table-text-blue">
                      <div class="table-font-size mx-2">
                        {{ upcoming.indications }}
                      </div>
                    </td>

                    <td class="table-wide-cell">
                      <div class="table-font-size mx-2">
                        {{ upcoming.title.slice(0, 30) }}
                        @if (upcoming.title .length > 30) {
                        <p
                          triggers="click:blur"
                          style="cursor: pointer"
                          class="text-danger"
                          container="body"
                          ngbTooltip="{{ upcoming.title }}"
                        >
                          <strong>Read More+</strong>
                        </p>
                        }
                      </div>
                    </td>
                  </tr>
                  }@empty { No Record Found }
                </tbody>
              </table>
              }@else {
              <table class="table table-fixed align-middle mb-0 bg-white p-0">
                <thead class="text-center sticky-header">
                  <tr class="text-uppercase table-header">
                    <th>Ticker</th>
                    <th>phase</th>
                    <th>trial results summary</th>
                    <th>date</th>
                    <th>drug name</th>
                    <th>indications</th>
                    <th>title</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  @for (upcoming of catalystresultData; track $index) {
                  <tr class="table-tr-font">
                    <td class="table-wide-cell">
                      <div class="table-font-size">{{ upcoming.ticker }}</div>
                    </td>

                    <td class="table-wide-cell">
                      <div class="mx-2">
                        <p class="phase-1">
                          {{ upcoming.clinical_trial_phase }}
                        </p>
                        @if (upcoming.clinical_trial_phase) {
                        <app-stage-progress
                          stage="{{ upcoming.clinical_trial_phase }}"
                        ></app-stage-progress>
                        }@else {
                        <p>-</p>
                        }
                      </div>
                    </td>
                    <td class="table-wide-cell table-text-blue">
                      <div class="table-font-size mx-2">
                        {{
                          upcoming.clinical_trial_results_summary[0].slice(
                            0,
                            60
                          )
                        }}
                        @if (upcoming.clinical_trial_results_summary[0].length >
                        60) {
                        <p
                          triggers="click:blur"
                          style="cursor: pointer"
                          class="text-danger"
                          container="body"
                          ngbTooltip="{{
                            upcoming.clinical_trial_results_summary
                          }}"
                        >
                          <strong>Read More+</strong>
                        </p>
                        }
                      </div>
                    </td>
                    <td class="table-wide-cell table-text-blue">
                      <div class="table-font-size mx-2">
                        {{ upcoming.date }}
                      </div>
                    </td>
                    <td class="table-wide-cell table-text-blue">
                      <div class="table-font-size mx-2">
                        {{ upcoming.drug_name }}
                      </div>
                    </td>
                    <td class="table-wide-cell table-text-blue">
                      <div class="table-font-size mx-2">
                        {{ upcoming.indications }}
                      </div>
                    </td>

                    <td class="table-wide-cell">
                      <div class="table-font-size mx-2">
                        {{ upcoming.title.slice(0, 30) }}
                        @if (upcoming.title .length > 30) {
                        <p
                          triggers="click:blur"
                          style="cursor: pointer"
                          class="text-danger"
                          container="body"
                          ngbTooltip="{{ upcoming.title }}"
                        >
                          <strong>Read More+</strong>
                        </p>
                        }
                      </div>
                    </td>
                  </tr>
                  }@empty { No Record Found }
                </tbody>
              </table>
              }
            </div>
          </div>
        </div>
      </div>
      }
    </div>
  </div>

  <div class="container-fluid">
    <div class="row justify-content-center">
      @if (upcomingdatashow) {
      <div
        [ngClass]="customclassfordrugsize"
        class="col-md-11 col-sm-12 mt-3 m-0"
      >
        <div class="pipeline" style="width: 100%">
          <div class="table-div p-0">
            <h2 #upcomingcatalystsection class="company-detail-list mb-4">
              Upcoming Catalyst
            </h2>
            <!-- <hr class="mb-1" /> -->
            <div [ngClass]="customclassforupcomingcyt" id="dataContainer">
              @if(!this.productId){
              <app-financial-table
                [columns]="tableCatalyst"
                [data]="upcomingdata"
              >
              </app-financial-table>
              }@else {
              <table class="table table-fixed align-middle mb-0 bg-white p-0">
                <thead class="text-center sticky-header">
                  <tr class="text-uppercase table-header">
                    <th>catalyst date</th>
                    <th>drug name</th>
                    <th>Treatment</th>
                    <th>stage</th>
                    <th>close</th>
                    <th>change</th>
                    <th>change abs</th>
                    <th>description</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  @for (upcoming of upcomingdata; track $index) {
                  <tr class="table-tr-font">
                    <td class="table-wide-cell">
                      <div class="table-font-size">{{ upcoming.catdate }}</div>
                    </td>

                    <td class="table-wide-cell">
                      <div class="table-font-size">{{ upcoming.drugname }}</div>
                    </td>
                    <td class="table-wide-cell table-text-blue">
                      <div class="table-font-size">
                        {{ upcoming.treatment }}
                      </div>
                    </td>
                    <td class="table-wide-cell table-text-blue">
                      <div class="table-font-size text-nowrap">
                        {{ upcoming.stage }}
                      </div>
                    </td>
                    <td class="table-wide-cell table-text-blue">
                      <div class="table-font-size text-nowrap">
                        {{ upcoming.close | number : "1.0-2" | zerofortable }}
                      </div>
                    </td>
                    <td class="table-wide-cell table-text-blue">
                      <div
                        class="table-font-size text-nowrap"
                        [style.color]="upcoming.change | riseAndFallColor"
                      >
                        {{ upcoming.change | number : "1.0-2" | qoqChg }}
                      </div>
                    </td>
                    <td class="table-wide-cell table-text-blue">
                      <div
                        class="table-font-size text-nowrap"
                        [style.color]="upcoming.change_abs | riseAndFallColor"
                      >
                        {{
                          upcoming.change_abs | number : "1.0-2" | zerofortable
                        }}
                      </div>
                    </td>
                    <td class="table-wide-cell table-text-blue">
                      @if (upcoming.description) {
                      <div class="table-font-size">
                        {{ upcoming.description.slice(0, 30) }}
                      </div>
                      @if (upcoming.description.length > 30) {
                      <p
                        triggers="click:blur"
                        style="cursor: pointer"
                        class="text-danger"
                        container="body"
                        ngbTooltip="{{ upcoming.description }}"
                      >
                        <strong>Read More+</strong>
                      </p>
                      } } @else { - }
                    </td>
                  </tr>
                  }@empty { No Record Found }
                </tbody>
              </table>
              }
            </div>
          </div>
        </div>
      </div>
      }
    </div>
  </div>

  <div class="container-fluid">
    <div class="row justify-content-center">
      @if (istradesShow) {
      <div [ngClass]="customclassfordrugsize" class="col-md-11 mt-3 col-sm-12">
        <h2 #insideTradesSection class="company-detail-list m-0 mb-4">
          Inside Trades
        </h2>
        <!-- <hr class="mb-1" /> -->
        <div [ngClass]="customclassfortrades" id="dataContainer" #dataContainer>
          @if (!this.productId) {
          <app-financial-table [columns]="tableInside" [data]="companytrades">
          </app-financial-table>
          }@else {
          <table #table class="table table-fixed align-middle mb-0 bg-white">
            <thead class="text-center sticky-header">
              <tr class="text-uppercase table-header">
                <th>Transactor name</th>
                <th>Traded shares</th>
                <th>trade price</th>
                <th>trade value</th>
                <th>Trade Date</th>
                <th>Current Holdings</th>
              </tr>
            </thead>
            <tbody class="text-center">
              @for (it of companytrades; track $index) {
              <tr class="table-tr-font">
                <td class="text-nowrap">
                  {{ it.name }}
                  <div>
                    <i class="table-font-italic">{{ it.relationship }}</i>
                  </div>
                </td>
                <td>
                  @if (it.buy_sell) {
                  <!-- <td> -->
                  <p class="table-font-size">
                    @if(it.buy_sell=='Buy') {
                    <span style="color: var(--clr-green)">{{
                      it.buy_sell
                    }}</span>
                    }@else if(it.buy_sell=='Sale') {
                    <span style="color: var(--clr-red)">{{ it.buy_sell }}</span>
                    }@else if(it.buy_sell=='Option Exercise'){
                    <span style="color: var(--clr-primary)">Option</span>

                    }
                  </p>
                  <!-- </td> -->
                  } @if(it.buy_sell=='Buy') {
                  <p class="table-font-size" style="color: var(--clr-green)">
                    {{ it.change | dollarsCoversion }}
                  </p>
                  }@else if(it.buy_sell=='Sale') {
                  <p class="table-font-size" style="color: var(--clr-red)">
                    - {{ it.change | dollarsCoversion }}
                  </p>
                  }@else if(it.buy_sell=='Option Exercise'){
                  <p class="table-font-size" style="color: var(--clr-primary)">
                    {{ it.change | dollarsCoversion }}
                  </p>

                  }
                  <!-- <p class="table-font-size" [style.color]="it.change">
                  {{ it.change | dollarsCoversion }}
                </p> -->
                </td>

                <td class="">
                  {{ it.transactionPrice | number : "1.0-2" }}
                </td>

                <td class="">
                  {{ it.traded_value | dollarsCoversion }}
                </td>

                <td class="text-nowrap">
                  {{ it.transactionDate }}
                </td>

                <td class="">
                  {{ it.share | dollarsCoversion }}
                </td>
              </tr>
              }@empty { No Record Found }
            </tbody>
          </table>
          }
        </div>
      </div>
      }
    </div>
  </div>

  <div class="container-fluid">
    <div class="row justify-content-center">
      @if (isPipelineDataShow) {
      <div
        [ngClass]="customclassforoptiondata"
        class="col-md-11 col-sm-12 mt-3 m-0"
      >
        <div class="pipeline" style="width: 100%">
          <div class="table-div p-0">
            <h2 #drugPipelineSection class="company-detail-list mb-4">
              Drug Pipeline
            </h2>
            <!-- <hr class="mb-1" /> -->
            <div
              [ngClass]="customclassfordrug"
              class="table-responsive scrollable-table"
              id="dataContainer"
            >
              @if (!this.productId) {
              <app-financial-table
                [columns]="tablePipeline"
                [data]="companyPipelineData"
              >
              </app-financial-table>
              }@else {
              <table class="table table-fixed align-middle mb-0 bg-white p-0">
                <thead class="text-center sticky-header">
                  <tr class="text-uppercase table-header">
                    <th class="table-name-size">Pipeline</th>
                    <th>Condition</th>
                    <th>Phase</th>
                    <th>Catalyst Date</th>
                    <th>Study Title</th>
                    <th>NCT Id</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  @for (pipeline of companyPipelineData; track $index) {
                  <tr class="table-tr-font">
                    <td class="table-wide-cell">
                      <p class="pipelinefont text-break">
                        @if (pipeline.InterventionName) {
                        {{ pipeline?.InterventionName?.slice(0, 40) }}
                        }
                      </p>

                      @if (pipeline.InterventionName &&
                      pipeline.InterventionName.length > 40) {
                      <p
                        triggers="click:blur"
                        style="cursor: pointer"
                        class="text-danger"
                        container="body"
                        ngbTooltip="{{ pipeline.InterventionName }}"
                      >
                        <strong>Read More+</strong>
                      </p>
                      }
                    </td>

                    <td class="table-wide-cell">
                      <div class="table-font-size">
                        {{ pipeline.Condition.slice(0, 40) }}
                      </div>

                      @if(pipeline.Condition.length > 40){
                      <p
                        triggers="click:blur"
                        style="cursor: pointer"
                        class="text-danger"
                        container="body"
                        ngbTooltip="{{ pipeline.Condition }}"
                      >
                        <strong>Read More+</strong>
                      </p>
                      }
                    </td>

                    <td class="table-wide-cell">
                      <div>
                        <p class="phase-1">{{ pipeline.Phase }}</p>

                        @if (pipeline.Phase) {
                        <app-stage-progress
                          stage="{{ pipeline.Phase }}"
                        ></app-stage-progress>
                        }@else {
                        <p>-</p>
                        }
                      </div>
                    </td>

                    <td class="table-wide-cell table-text-blue">
                      <div class="table-font-size text-nowrap">
                        {{ pipeline.CompletionDate }}
                      </div>
                    </td>

                    <td class="table-wide-cell">
                      <div class="table-font-size">
                        {{ pipeline.BriefTitle.slice(0, 30) }}
                        <!-- <span>NCTId : {{ pipeline.nctId }}</span> -->
                        @if (pipeline.BriefTitle.length > 30) {
                        <p
                          triggers="click:blur"
                          style="cursor: pointer"
                          class="text-danger"
                          container="body"
                          ngbTooltip="{{ pipeline.BriefTitle }}"
                        >
                          <strong>Read More+</strong>
                        </p>
                        }
                      </div>
                    </td>
                    <td class="table-wide-cell">
                      <div class="table-font-size">
                        {{ pipeline.nctId }}
                      </div>
                    </td>
                  </tr>
                  }@empty { No Record Found }
                </tbody>
              </table>
              }
            </div>
          </div>
        </div>
      </div>
      }
    </div>
  </div>

  <div class="container-fluid">
    <div class="row justify-content-center">
      @if (hedgedataShow) {
      <div class="col-md-11 col-sm-12 mt-3 m-0">
        <div class="pipeline" style="width: 100%">
          <div class="table-div p-0">
            <h2 #HedgeFundsSection class="company-detail-list mb-4">
              Hedge Funds
            </h2>
            <!-- <hr class="mb-1" /> -->
            <div [ngClass]="customclassforhedge" id="dataContainer">
              @if (!this.productId) {
              <app-financial-table
                [columns]="tableHedge"
                [data]="hedgedata"
              ></app-financial-table>
              }@else {
              <table class="table table-fixed align-middle mb-0 bg-white p-0">
                <thead class="text-center sticky-header">
                  <tr class="text-uppercase table-header">
                    <th>HedgeFund Name</th>
                    <th>% of portfolio</th>
                    <!-- <th>percent ownership</th> -->
                    <!-- <th>avg price</th> -->
                    <th>current mv</th>
                    <!-- <th>QOQ % CHG</th> -->
                    <!-- <th>change_abs</th> -->
                  </tr>
                </thead>
                <tbody class="text-center">
                  @for (data of hedgedata; track $index) {
                  <tr class="table-tr-font">
                    <td class="table-wide-cell">
                      <div
                        (click)="indiDetails(data.hedgeFundName)"
                        class="table-font-size cursorpointer"
                      >
                        <p class="text-dark">
                          <u>{{ data.hedgeFundName }}</u>
                        </p>
                      </div>
                    </td>

                    <!-- <td class="table-wide-cell">
                      <div class="table-font-size">{{ data.previous_percent_of_portfolio | number:"1.0-2" | qoqChg }}
                      </div>
                    </td> -->
                    <td class="table-wide-cell table-text-blue">
                      <div class="table-font-size text-nowrap">
                        {{ data.current_percent_of_portfolio }}
                      </div>
                    </td>
                    <!-- <td class="table-wide-cell table-text-blue">
                      <div class="table-font-size text-nowrap">
                        {{ data.avg_price | number:"1.0-2" | zerotoDash }}
                      </div>
                    </td> -->
                    <td class="table-wide-cell table-text-blue">
                      <div class="table-font-size text-nowrap">
                        {{ data.current_mv | dollarsCoversion }}
                      </div>
                    </td>
                    <!-- <td class="table-wide-cell table-text-blue">
                          <div 
                            class="table-font-size text-nowrap">
                            {{ data.percent_shares_change | number:"1.0-2" | qoqChg }}
                          </div>
                        </td> -->
                  </tr>
                  }
                </tbody>
              </table>
              }
            </div>
          </div>
        </div>
      </div>
      }
    </div>
  </div>

  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-11">
        <div class="row">
          <p class="m-0" (click)="scrollToOption(myel)" #myel></p>

          @if (this.optionTwotable.length > 0) {
          <div class="col-sm-12 col-md-12 mt-3 m-0">
            <div class="pipeline" style="width: 100%">
              <div class="table-div p-0">
                <h2 #optiondataSection class="company-detail-list mb-4">
                  Options Data
                </h2>
                <!-- <hr class="mb-3" /> -->
                <div [ngClass]="customclassforoption" id="dataContainer">
                  <table class="table table-fixed mb-0">
                    <thead class="sticky-header">
                      <tr class="text-uppercase table-header">
                        <th
                          style="border-right: 1px solid rgb(211, 209, 209)"
                          class="text-center"
                          colspan="1"
                        ></th>
                        <th
                          style="border-right: 1px solid rgb(211, 209, 209)"
                          class="text-center"
                          colspan="3"
                        >
                          Volume
                        </th>
                        <th
                          style="border-right: 1px solid rgb(211, 209, 209)"
                          class="text-center"
                          colspan="3"
                        >
                          Open Interest
                        </th>
                        <th class="text-center" colspan="4">
                          Implied Volatility
                        </th>
                      </tr>
                      <tr class="text-uppercase table-header">
                        <th
                          style="border-right: 1px solid rgb(211, 209, 209)"
                          class="text-center"
                        >
                          Expiration
                        </th>
                        <th class="text-center">Calls</th>
                        <th class="text-center">Puts</th>
                        <th
                          style="border-right: 1px solid rgb(211, 209, 209)"
                          class="text-nowrap text-center"
                        >
                          Put-Call Ratio
                        </th>
                        <th class="text-center">Calls</th>
                        <th class="text-center">Puts</th>
                        <th
                          style="border-right: 1px solid rgb(211, 209, 209)"
                          class="text-nowrap"
                        >
                          Put-Call Ratio
                        </th>
                        <th
                          placement="bottom"
                          container="body"
                          class="text-center"
                          ngbTooltip="Open Interest Weighted Average Implied Volatility.(Calls)"
                        >
                          calls oiwaiv
                        </th>
                        <th
                          placement="bottom"
                          container="body"
                          class="text-center"
                          ngbTooltip="Volume-Weighted Average Implied Volatility.(Calls)"
                        >
                          calls vwaiv
                        </th>
                        <th
                          placement="bottom"
                          container="body"
                          class="text-center"
                          ngbTooltip="Open Interest Weighted Average Implied Volatility.(Puts)"
                        >
                          puts oiwaiv
                        </th>
                        <th
                          placement="bottom"
                          container="body"
                          class="text-center"
                          ngbTooltip="Volume-Weighted Average Implied Volatility.(Puts)"
                        >
                          puts vwaiv
                        </th>
                      </tr>
                    </thead>
                    <tbody [ngClass]="{ blur: !this.productId }">
                      @for (option of optionTwotable; track $index) {

                      <tr class="table-tr-font">
                        <td
                          class="table-wide-cell table-text-blue"
                          style="border-right: 1px solid rgb(211, 209, 209)"
                        >
                          <div class="table-font-size text-center text-nowrap">
                            {{ option.date }}
                          </div>
                        </td>
                        <td class="table-wide-cell">
                          <div class="table-font-size text-center">
                            {{ option.totalVolume | zerofortable }}
                          </div>
                        </td>
                        <td class="table-wide-cell text-center table-text-blue">
                          <div class="table-font-size text-nowrap">
                            {{ option.putsvolume | zerofortable }}
                          </div>
                        </td>
                        <td
                          style="border-right: 1px solid rgb(211, 209, 209)"
                          class="table-wide-cell table-text-blue"
                        >
                          <div class="table-font-size text-center text-nowrap">
                            {{
                              option.volumeratio
                                | number : "1.0-2"
                                | infinityTable
                            }}
                          </div>
                        </td>
                        <td class="table-wide-cell">
                          <div class="table-font-size text-center">
                            {{ option.totalopenInterest | zerofortable }}
                          </div>
                        </td>
                        <td class="table-wide-cell table-text-blue">
                          <div class="table-font-size text-center text-nowrap">
                            {{ option.putsInterest | zerofortable }}
                          </div>
                        </td>
                        <td
                          style="border-right: 1px solid rgb(211, 209, 209)"
                          class="table-wide-cell table-text-blue"
                        >
                          <div class="table-font-size text-center text-nowrap">
                            {{
                              option.openInterestRatio
                                | number : "1.0-2"
                                | infinityTable
                            }}
                          </div>
                        </td>

                        <td class="table-wide-cell">
                          <div class="table-font-size text-center">
                            {{
                              option.calls_oiwaiv
                                | number : "1.0-2"
                                | zerofortable
                            }}
                          </div>
                        </td>
                        <td class="table-wide-cell table-text-blue">
                          <div class="table-font-size text-center text-nowrap">
                            {{
                              option.calls_vwaiv
                                | number : "1.0-2"
                                | zerofortable
                            }}
                          </div>
                        </td>
                        <td class="table-wide-cell table-text-blue">
                          <div class="table-font-size text-center text-nowrap">
                            {{
                              option.puts_oiwaiv
                                | number : "1.0-2"
                                | zerofortable
                            }}
                          </div>
                        </td>
                        <td class="table-wide-cell table-text-blue">
                          <div class="table-font-size text-center text-nowrap">
                            {{
                              option.puts_vwaiv
                                | number : "1.0-2"
                                | zerofortable
                            }}
                          </div>
                        </td>
                      </tr>

                      }@empty { No Record Found }

                      <tr>
                        <td
                          class="text-center"
                          style="border-right: 1px solid rgb(211, 209, 209)"
                          colspan="1"
                        >
                          <P class="m-0 fw-bold text-center">Total</P>
                        </td>
                        <td class="text-center" colspan="1">
                          <b>{{ callTotal }}</b>
                        </td>
                        <td class="text-center" colspan="1">
                          <b>{{ putTotal }}</b>
                        </td>
                        <td
                          class="text-center"
                          style="border-right: 1px solid rgb(211, 209, 209)"
                          colspan="1"
                        >
                          <b>{{ ratio | number : "1.0-2" }}</b>
                        </td>
                        <td class="text-center" colspan="1">
                          <b>{{ opencallTotal }}</b>
                        </td>
                        <td class="text-center" colspan="1">
                          <b>{{ openputTotal }}</b>
                        </td>
                        <td
                          class="text-center"
                          style="border-right: 1px solid rgb(211, 209, 209)"
                          colspan="1"
                        >
                          <b>{{ openratio | number : "1.0-2" }}</b>
                        </td>
                        <td colspan="4"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-11">
        <div class="row">
          @if (this.optionTwotable.length > 0) {
          <div class="col-md-12 mt-3">
            <app-optionchart></app-optionchart>
          </div>
          }
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row justify-content-center">
      @if (showNewdata) {
      <div class="col-md-11 mt-3">
        <h2 class="company-detail-list" #NewsSectionSection>News Section</h2>
        <hr class="mb-1" />

        <div class="row">
          @if (companynews.length ==0) {
          <div class="table-loading-container d-flex">
            <p class="Fetching me-3 mb-0 mt-0">Fetching more data for you</p>
            <span class="loader mt-1" style="font-size: 3px"> </span>
          </div>
          } @else { @for (item of companynews; track $index) {

          <div class="col-md-12">
            <div class="row mb-1">
              <div class="col-md-12">
                <div class="outer-main small-single-sec layout-right">
                  <h6 class="head-text mb-1">{{ item.title }}</h6>
                  <div class="d-flex align-items-center stock-details">
                    <span class="dateText mb-0 me-2">
                      {{ item.published }}</span
                    >
                    <div class="tag-btn mrgn-lft-12 me-2">
                      @if (item.type) {
                      <span class="most-value-btn">{{ item.type }}</span>
                      }
                    </div>
                  </div>
                  @if (item.content) {
                  <p style="font-size: 13px">
                    {{ item.content[1] | slice : 0 : 500 }}...
                    <span
                      (click)="openpopup(item)"
                      class="text-danger cursorpointer"
                    >
                      Read more+
                    </span>
                  </p>

                  }@else {
                  <p>-</p>
                  }
                </div>
              </div>
              <hr class="uk-hr" />
            </div>
          </div>
          } @empty { No Record Found }}
        </div>

        <!-- <div class="table-responsive scrollable-table">
        <table class="table table-fixed">
          <thead class="sticky-header">
            <tr class="text-uppercase table-header">
              <th>Date and Time</th>
              <th>Title</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>

            @for (news of companynews; track $index) {
            <tr class="table-tr-font">
              <td class="">
                <div class="d-flex align-items-center">
                
                  <p>{{news.published | date: 'medium' }}</p>
                </div>
              </td>
              <td>
                <h5 style="font-size: 16px; font-weight: 700;">{{news.title}}</h5>
              </td>
              <td>

                @if (news.content) {
                <p>{{news.content | slice: 0:100}}</p>
                <p (click)="openpopup(news.content)" class="text-danger cursorpointer">
                  <b>Read more+</b>
                </p>
                }@else {
                <p>-</p>
                }

              </td>
            </tr>
            }

          </tbody>
        </table>
      </div> -->
      </div>
      }
    </div>
  </div>
</div>

<!-- news popup  -->

<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngStyle]="{ display: displayStyle }"
>
  <div
    class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title d-flex align-items-center text-light">
          Description
        </h4>
        <div class="d-flex align-items-center">
          <button type="button" class="btn" (click)="closePopup()">
            <i class="fa-solid fa-xmark text-white" style="opacity: unset"></i>
          </button>
        </div>
      </div>
      <div class="modal-body">
        @if (newsItem!=null) {
        <h4 class="head-text">{{ newsItem.title }}</h4>
        <div class="dateText mb-1">
          <div class="d-flex align-items-center">
            <span class="me-1"
              ><b style="font-size: 13px">{{ newsItem.published }}</b></span
            >
            @if (newsItem.ticker) {
            <a
              class="link-ticker most-value-btn me-1"
              style="font-size: 13px"
              routerLink="/company/{{ newsItem.ticker }}"
            >
              {{ newsItem.ticker }} </a
            >}

            <p
              class="company-plus-minus me-2 mb-0 d-flex align-items-center me-1"
              [style.color]="newsItem.close | riseAndFallColor"
              style="font-size: 13px"
            >
              {{ newsItem.close | number : "1.0-2" }}
            </p>
            <p
              class="company-plus-minus mb-0 d-flex align-items-center"
              [style.color]="newsItem.change | riseAndFallColor"
              style="font-size: 13px"
            >
              ({{ newsItem.change | number : "1.0-2" }}%)
            </p>
          </div>
        </div>
        @for (item of newsItem.content; track $index) {
        <p>{{ item }}</p>
        }}
      </div>
    </div>
  </div>
</div>

<app-loginpop-up
  [displayStyle]="modalDisplayStyle"
  (myout)="GetChildData($event)"
></app-loginpop-up>

<app-portfoliomodal
  [portfolioTicker]="portfolioTicker"
  [portfolioPrice]="portfolioPrice"
  [portfoliocost]="portfoliocost"
  [portfolioquntity]="portfolioquntity"
  (addSymbol)="onAddSymbol($event)"
></app-portfoliomodal>
