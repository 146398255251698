import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import {
  MinusData,
  PlusData,
  PreMarketData,
} from '../../shared/models/lossing-gainers';
import { catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import {
  activeOptions,
  getPlusData,
  getminusData,
  heighestvolume,
  upcomingcatalystdata,
  upcomingearnings,
  UnusualOptionActivitys,
  shortInterests,
  getPrePlusData,
  getPreMinusData,
} from '../const';

@Injectable({
  providedIn: 'root',
})
export class LoosingGainerService {
  constructor(private http: HttpClient) {}

  getPlusData(pageSize: number = 7): Observable<any> {
    return this.http.get<PlusData[]>(`${getPlusData}${pageSize}`).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error.error.message);
      })
    );
  }

  getPrePlusData(pageSize: number = 7): Observable<any> {
    return this.http.get<PreMarketData[]>(`${getPrePlusData}${pageSize}`).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error.error.message);
      })
    );
  }

  getPreMinusData(pageSize: number = 7): Observable<any> {
    return this.http.get<PreMarketData[]>(`${getPreMinusData}${pageSize}`).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error.error.message);
      })
    );
  }

  getminusData(pageSize: number = 7): Observable<any> {
    return this.http.get<MinusData[]>(`${getminusData}${pageSize}`).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error.error.message);
      })
    );
  }

  upcomingcatalyst() {
    return this.http.get(`${upcomingcatalystdata}`);
  }
  upcomingEarnigs() {
    return this.http.get(`${upcomingearnings}`);
  }
  heighestVol() {
    return this.http.get(`${heighestvolume}`);
  }
  activeOption() {
    return this.http.get(`${activeOptions}`);
  }
  UnusualOptionActivity() {
    return this.http.get(`${UnusualOptionActivitys}`);
  }
  shortInterest() {
    return this.http.get(`${shortInterests}`);
  }
}
