<section class="BiotechEarnings text-center">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="mt-3 mb-3">
          <h1 class="main-heading text-white m-0 me-3">Conference Calendar</h1>
          <p class="sub-heading text-center text-white m-0">
            The Biomedical Meeting Calendar lists upcoming biotech conferences
            and abstract release dates, crucial for tracking stock movers. It
            includes headline drugs, presenting companies, and links to detailed
            conference information. Conferences often release key data and
            update companies' catalyst schedules. Stay informed and ahead in the
            market with our comprehensive calendar.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

@if (!isLoading) {
<div class="container mb-3 p-1">
  <div class="row">
    <div class="col-lg-7">
      <div class="calendar-container">
        <div class="header">
          <div class="d-flex align-items-center">
            <span class="yearfont">{{ currentMonth | date : " yyyy" }}</span>
            <span class="monthfont">{{ currentMonth | date : "MMMM" }}</span>
            <!-- <input *ngIf="!modalDisplayStyle" type="month" class="border-0 p-0 month-picker rang-font"
                      (click)="selectdateforcalendar()" (change)="onMonthYearChange($event)"
                      [value]="currentMonth | date : 'yyyy-MM'" id="startMonth" /> -->
            <img
              (click)="toggle()"
              width="30px"
              class="cursorpointer"
              src="/assets/images/calender-img.webp"
              alt=""
            />

            @if(!modalDisplayStyle){
            <input
              class="hide_date_input"
              (click)="selectdateforcalendar()"
              [(ngModel)]="dateModel"
              type="text"
              bsDatepicker
              [bsConfig]="bsConfig"
              (ngModelChange)="onMonthYearChange($event)"
              id="startMonth"
            />
            }
          </div>
          <div>
            <button class="prev-btn ms-2 me-3" (click)="prevMonth()">
              <img
                width="20px"
                src="/assets/images/left-arrow-icon.png"
                alt=""
              />
            </button>
            <button class="next-btn me-3" (click)="nextMonth()">
              <img
                width="20px"
                src="/assets/images/right-arrow-icon.png"
                alt=""
              />
            </button>
          </div>
        </div>

        <div class="weekdays">
          @for (day of weekdays; track $index) {
          <div>{{ day }}</div>
          }
        </div>

        <div class="days cursorpointer" (click)="getSelectedDayData()">
          @for (blank of getEmptyDaysBeforeFirstDay(); track $index) {
          <div></div>
          } @for (day of calendarDays; track $index) {

          <div class="calendar-box" (click)="selectDate(day)">
            <p
              [ngClass]="{ today: isToday(day), d: isSelected(day) }"
              class="d-flex getDate px-2 m-1"
              style="margin-bottom: 0"
            >
              {{ day.getDate() }}
            </p>

            <div>
              <span class="text-center">
                @for (conference of getConferencesForDate(day); track $index) {
                <!-- {{ getConferencesForDate(day).length }} -->
                <div container="body" ngbTooltip="{{ conference.name }}">
                  <p
                    [ngStyle]="{
                      'background-color': conference.color,
                      color: conference.textcolor
                    }"
                    class="forlep my-1"
                  >
                    {{ conference.name | slice : 0 : 7 }}...
                  </p>
                </div>
                }
                <!-- @if (getConferencesForDate(day).length >= 1) {
                              <p class="formobile-number d-none mx-auto px-1 m-0 mt-1">{{getConferencesForDate(day).length}}</p>
                              } -->
              </span>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
    <div class="col-lg-5">
      <div>
        <div class="">
          <div class="d-flex justify-content-start align-items-center my-3">
            <h3 class="Pdufa-hending mb-0">Conferences</h3>

            <button (click)="showDetailData()" class="btn btn-sm p-0 mx-3">
              <i
                style="opacity: unset; font-size: 20px"
                class="fa-solid fa-rotate-right"
              ></i>
            </button>
          </div>
          <div [ngClass]="pdufascrollData">
            <div>
              @if(!showDetailDataFlag){
              <div>
                @for (calenderData of this.resobj; track $index) {
                <div class="box-border mb-2">
                  <div class="d-grid calendar-month-data">
                    <div class="left-border">
                      <div class="d-flex align-items-center">
                        <!-- @if (calenderData.logoid !== '') {
                                                  <img width="40px" [src]="basePath + calenderData.logoid + '.svg'"
                                                      class=" company-hover" />
                                                  }@else {
                                                  <span class="avatarcss mb-0">{{
                                                      calenderData.name | initialLetter
                                                      }}</span>
                                                  } -->
                        <div class="">
                          <h5 style="font-size: 17px" class="m-0">
                            {{
                              calenderData[
                                "Conference
                                                          Name"
                              ]
                            }}
                          </h5>
                        </div>
                      </div>
                      <div class="mt-2 d-flex align-items-center">
                        <i class="fas fa-calendar-alt"></i>
                        <p class="m-0 ms-2 catdate-text">
                          {{ calenderData["Start Date"] }}
                        </p>
                        <p class="m-0 ms-2 catdate-text">-</p>
                        <p class="m-0 ms-2 catdate-text">
                          {{ calenderData["End Date"] }}
                        </p>
                      </div>
                      <!-- <div class="mt-2">
                                                  <p class="m-0 calnder-text"><span class=""
                                                          style="color: var(--clr-primary);font-weight: 600;">Drug Name
                                                          : </span>{{calenderData.drugname}}</p>
                                              </div> -->
                    </div>
                  </div>
                </div>
                }@empty { No Record Found }
              </div>
              }@else { @if (selectedDate) { @for (calenderData of
              getConferencesForDate(selectedDate); track $index) {

              <div class="box-border mb-2">
                <div class="d-grid calendar-month-data">
                  <div class="left-border">
                    <div class="d-flex align-items-center">
                      <!-- @if (calenderData.logoid !== '') {
                                                          <img width="40px" [src]="basePath + calenderData.logoid + '.svg'"
                                                              class=" company-hover" />
                                                          }@else {
                                                          <span class="avatarcss mb-0">{{
                                                              calenderData.name | initialLetter
                                                              }}</span>
                                                          } -->
                      <div class="">
                        <h5 style="font-size: 17px" class="m-0">
                          {{ calenderData.name }}
                        </h5>
                      </div>
                    </div>
                    <div class="mt-2 d-flex align-items-center">
                      <i class="fas fa-calendar-alt"></i>
                      <p class="m-0 ms-2 catdate-text">
                        {{ calenderData.startdate }}
                      </p>
                      <p class="m-0 ms-2 catdate-text">-</p>
                      <p class="m-0 ms-2 catdate-text">
                        {{ calenderData.enddate }}
                      </p>
                    </div>
                    <!-- <div class="mt-2">
                                                          <p class="m-0 calnder-text"><span class=""
                                                                  style="color: var(--clr-primary);font-weight: 600;">Drug Name
                                                                  : </span>{{calenderData.drugname}}</p>
                                                      </div> -->
                  </div>
                </div>
              </div>
              }@empty { No Record Found } } }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
}@else {
  <div class="container">

<ngx-skeleton-loader
  [theme]="{ height: '500px', 'border-radius': '4px', 'margin-top': '20px' }"
  count="1"
>
</ngx-skeleton-loader>
</div>
}

<app-loginpop-up
  [displayStyle]="modalDisplayStyle"
  (myout)="GetChildData($event)"
></app-loginpop-up>
