import { Component } from '@angular/core';
import {
  getAuthenticatedUser,
  lemonSqueezySetup,
} from '@lemonsqueezy/lemonsqueezy.js';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionService } from 'src/app/modules/shared/service/subscription.service';
import { environment } from 'src/environments/environment';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translateY(15px)', // At its original position
          opacity: 1,
          filter: 'blur(0px)', // No blur when fully visible
        })
      ),
      state(
        'out',
        style({
          transform: 'translateY(0px)', // 30px lower (off-screen)
          opacity: 0, // Transparent
          filter: 'blur(8px)', // Apply blur when sliding out
        })
      ),
      transition('out => in', [
        animate('1.8s cubic-bezier(0.34, 1.56, 0.64, 1)'),
      ]),
      transition('in => out', [
        animate('1.5s ease-out'), // Slow down ease-out slightly
      ]),
    ]),
  ],
})
export class SubscriptionComponent {
  isloader = '';
  isswitchplan = 'annual';
  modalDisplayStyle!: boolean;
  clientUrl = environment.clientUrl;
  testimonials: {
    content: string;
    name: string;
    position: string;
    image: string;
    country?: string;
    address?: string;
  }[] = [
    {
      content:
        'Thanks to the searchable FDA calendar and PDUFA catalysts, I’ve been able to plan ahead and consistently capitalize on market-moving events. This service has played a crucial role in helping me generate steady profits!',
      name: 'John D',
      position: 'Individual Investor',
      image: '/assets/images/young-bearded-man-with-striped-shirt.jpg',
      country: 'https://png.pngtree.com/png-vector/20230905/ourmid/pngtree-us-flag-sign-png-image_9947494.png',
      address: 'San Francisco, CA, USA',
    },
    {
      content:
        'The portfolio tracking tools have made managing my investments so much smoother. I can track catalysts and drug pipelines, and it’s helped me make better decisions that have really paid off. Definitely a great resource for anyone in biotech.',
      name: 'Samantha R',
      position: 'Portfolio Manager',
      image: '/assets/images/women.jpg',
      country: 'https://upload.wikimedia.org/wikipedia/commons/3/3d/Flag_of_germany_800_480.png',
      address: 'Berlin, Germany',
    },
    {
      content:
        'Tracking biotech earnings and catalyst events is now easier than ever. I can quickly assess how different companies are performing and adjust my portfolio accordingly.',
      name: 'Emily K',
      position: 'Individual Investor',
      image:
        '/assets/images/girl-with-gray-sweater-blue-jeans-is-posing-photo.jpg',
      country: 'https://png.pngtree.com/png-vector/20230905/ourmid/pngtree-us-flag-sign-png-image_9947494.png',
      address: 'Chicago, IL, USA',
    },
    {
      content:
        'As someone new to biotech investing, the tools have been a lifesaver. I’ve been able to make some solid gains just by following the market updates and tracking my portfolio closely. Highly recommend it!',
      name: 'Linda P',
      position: 'New Biotech Investor',
      image:
        'assets/images/portrait-charming-businesswoman-sitting-her-workplace-writing.jpg',
      country: 'https://img.freepik.com/free-vector/illustration-uk-flag_53876-18166.jpg',
      address: 'London, UK',
    },
    {
      content:
        'Being able to monitor insider trading and hedge fund holdings has provided invaluable insights. This feature alone has helped me spot opportunities and avoid potential pitfalls.',
      name: 'Carlos M',
      position: 'API User',
      image: 'assets/images/bohemian-man-with-his-arms-crossed.jpg',
      country: 'https://png.pngtree.com/png-vector/20230905/ourmid/pngtree-us-flag-sign-png-image_9947494.png',
      address: 'Boston, MA, USA',
    },
    {
      content:
        'The extensive research on over 1,100 companies and clinical trials is simply impressive. It’s helped me shape more informed, strategic plans for both investments and R&D purposes.',
      name: 'Michael L',
      position: 'Pharma Researcher',
      image:
        'https://media.istockphoto.com/id/1170953707/photo/smiling-black-man.jpg?s=612x612&w=0&k=20&c=lKqsRoMExYAnVtIw9fadM84rOPBhI_LVLCuRaBvstvo=',
      country: 'https://img.freepik.com/free-vector/illustration-canada-flag_53876-27114.jpg',
      address: 'Toronto, Canada',
    },
  ];
  currentIndex = 0;
  currentState = 'in'; // This will track the current animation state
  displayedTestimonial = this.testimonials[0];

  constructor(
    private checkout: SubscriptionService,
    private toaster: ToastrService,
    private router: Router
  ) {}

  ngOnInit() {
    setInterval(() => {
      this.changeTestimonial();
    }, 8000); // Change every 3 seconds
  }

  generateorderToken() {
    const token = Math.floor(Math.random() * 1000000).toString();
    return token;
  }

  changeTestimonial() {
    this.currentState = 'out';

    setTimeout(() => {
      this.currentIndex = (this.currentIndex + 1) % this.testimonials.length;
      this.displayedTestimonial = this.testimonials[this.currentIndex];
      this.currentState = 'in';
    }, 400); // Match this delay with the 'in => out' transition duration
  }

  makeorder(plan: string, id: string) {
    if (this.isswitchplan === 'annual' && plan === 'Basic') {
      id = '525208';
    }

    if (this.isswitchplan === 'monthly' && plan === 'Basic') {
      id = '525208';
    }

    if (this.isswitchplan === 'annual' && plan === 'Elite plus') {
      id = '525209';
    }

    if (this.isswitchplan === 'monthly' && plan === 'Elite plus') {
      id = '525210';
    }

    let email = localStorage.getItem('loggedInUser');

    if (email) {
      var parsedEmail = JSON.parse(email);
      this.isloader = plan;
    } else {
      this.router.navigate(['/signup']);
      return;
    }

    const data = {
      data: {
        type: 'checkouts',
        attributes: {
          checkout_data: {
            email: parsedEmail.email,
            name: parsedEmail.fname,
            custom: [this.generateorderToken()],
          },
          product_options: {
            redirect_url: `${this.clientUrl}/success`,
          },
        },
        relationships: {
          store: {
            data: {
              type: 'stores',
              id: '107355',
            },
          },
          variant: {
            data: {
              type: 'variants',
              id: `${id}`,
            },
          },
        },
      },
    };

    this.checkout.createcheckoutsession(data).subscribe({
      next: (response: any) => {
        if (response.message) {
          this.toaster.warning(response.message);
          this.isloader = 'false';
          return;
        }
        this.isloader = 'false';
        window.open(response.data.attributes.url, '_self');
      },
      error: (error) => {
        console.error('error', error);
      },
    });
  }

  changePlan(event: string) {
    this.isswitchplan = event;
  }

  GetChildData(data: any) {
    this.modalDisplayStyle = data;
  }
}
