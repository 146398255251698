import { Component, ElementRef } from '@angular/core';
import Chart from 'chart.js/auto';
import { CompanyDetailsService } from '../../service/company-details.service';
import { ActivatedRoute } from '@angular/router';
import { DecryptionService } from '../../service/decryption.service';
@Component({
  selector: 'app-optionchart',
  templateUrl: './optionchart.component.html',
  styleUrls: ['./optionchart.component.scss'],
})
export class OptionchartComponent {
  public chart: any;
  public Interestchart: any;
  chartDate: any[] = [];
  chartOpenDate: any[] = [];
  chartCalls: any[] = [];
  openCall: any[] = [];
  openPut: any[] = [];
  chartputs: any[] = [];
  selectedOptionType: string = 'callput';
  selectedInterestType: string = 'callput';
  optionalldata: any[] = [];
  companySymbol!: string | null;
  fakeData = [
    {
      totalCalls: [
        {
          _id: {
            expiration_date: '2025-12-19',
            contractSymbol: 'fakedata',
          },
          totalVolume: 1000,
          totalopenInterest: 1000,
        },
        {
          _id: {
            expiration_date: '2025-01-17',
            contractSymbol: 'fakedata',
          },
          totalVolume: 1000,
          totalopenInterest: 1000,
        },
        {
          _id: {
            expiration_date: '2024-09-20',
            contractSymbol: 'fakedata',
          },
          totalVolume: 1000,
          totalopenInterest: 1000,
        },
        {
          _id: {
            expiration_date: '2024-12-20',
            contractSymbol: 'fakedata',
          },
          totalVolume: 1000,
          totalopenInterest: 1000,
        },
        {
          _id: {
            expiration_date: '2024-10-18',
            contractSymbol: 'fakedata',
          },
          totalVolume: 1000,
          totalopenInterest: 1000,
        },
      ],
      totalPuts: [
        {
          _id: {
            expiration_date: '2024-09-20',
            contractSymbol: 'fakedata',
          },
          totalVolume: 1000,
          totalopenInterest: 1000,
        },
        {
          _id: {
            expiration_date: '2024-10-18',
            contractSymbol: 'fakedata',
          },
          totalVolume: 1000,
          totalopenInterest: 1000,
        },
        {
          _id: {
            expiration_date: '2024-12-20',
            contractSymbol: 'fakedata',
          },
          totalVolume: 1000,
          totalopenInterest: 1000,
        },
      ],
    },
  ];
  productId: any;
  constructor(
    private optionservice: CompanyDetailsService,
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private decryptionService: DecryptionService
  ) {}

  ngOnInit(): void {
    this.companySymbol = this.route.snapshot.paramMap.get('ticker');
    this.createChart();
    this.InterestChart();
    this.getCallData();
  }

  createChart() {
    this.chart = new Chart(
      this.elementRef.nativeElement.querySelector('#MyChart'),
      {
        type: 'bar',
        data: {
          labels: this.chartDate,
          datasets: [
            {
              label: 'Calls Volume',
              data: this.chartCalls,
              backgroundColor: '#089981',
              barPercentage: 0.3,
            },
            {
              label: 'Puts Volume',
              data: this.chartputs,
              backgroundColor: '#F23645',
              barPercentage: 0.3,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            x: { stacked: true },
            y: { stacked: true },
          },
        },
      }
    );
  }

  InterestChart() {
    this.Interestchart = new Chart(
      this.elementRef.nativeElement.querySelector('#MyChart2'),
      {
        type: 'bar',
        data: {
          labels: this.chartOpenDate,
          datasets: [
            {
              label: 'Interest Calls',
              data: this.openCall,
              backgroundColor: '#089981',
              barPercentage: 0.3,
            },
            {
              label: 'Interest Puts',
              data: this.openPut,
              backgroundColor: '#F23645',
              barPercentage: 0.3,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            x: { stacked: true },
            y: { stacked: true },
          },
        },
      }
    );
  }

  callupdateOption(optionType: string) {
    this.selectedOptionType = optionType;
    this.optionalldata.forEach((option: any) => {
      this.chartCalls.push(option.totalVolume);
    });
    this.chartputs = [];
    this.updateChart();
  }

  putupdateOption(optionType: string) {
    this.selectedOptionType = optionType;
    this.optionalldata.forEach((option: any) => {
      this.chartputs.push(option.putsvolume);
    });
    this.chartCalls = [];
    this.updateChart();
  }

  callputupdateOption(optionType: string) {
    this.selectedOptionType = optionType;
    this.optionalldata.forEach((option: any) => {
      this.chartCalls.push(option.totalVolume);
      this.chartputs.push(option.putsvolume);
    });
    this.updateChart();
  }

  callupdateOpenInterest(optionType: string) {
    this.selectedInterestType = optionType;
    this.optionalldata.forEach((option: any) => {
      this.openCall.push(option.totalopenInterest);
    });
    this.openPut = [];
    this.updateOpenChart();
  }

  putupdateOpenInterest(optionType: string) {
    this.selectedInterestType = optionType;
    this.optionalldata.forEach((option: any) => {
      this.openPut.push(option.putsInterest);
    });
    this.openCall = [];
    this.updateOpenChart();
  }

  callputupdateOpenInterest(optionType: string) {
    this.selectedInterestType = optionType;
    this.optionalldata.forEach((option: any) => {
      this.openCall.push(option.totalopenInterest);
      this.openPut.push(option.putsInterest);
    });
    this.updateOpenChart();
  }

  getCallData() {
    this.optionservice.getoptiondata(this.companySymbol).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);
        const ParseDecryptedData = JSON.parse(decryptData);
        this.productId = ParseDecryptedData?.productId;


        if(this.productId){
          ParseDecryptedData.data.forEach((data: any) => {
            const callsTotal = data.totalCalls;
            const putTotal = data.totalPuts;
            for (const a of callsTotal) {
              const id = a._id.expiration_date;
              const fiter = putTotal.find(
                (x: any) => x._id.expiration_date === id
              );
              this.optionalldata.push({
                ...a,
                putsInterest: fiter?.totalopenInterest,
                putsvolume: fiter?.totalVolume,
                date: a._id.expiration_date,
                volumeratio: fiter?.totalVolume / a.totalVolume,
                openInterestRatio: fiter?.totalopenInterest / a.totalopenInterest,
              });
              this.optionalldata.sort(
                (a: any, b: any) =>
                  new Date(a.date).getTime() - new Date(b.date).getTime()
              );
            }
          });
        }else{
          this.fakeData.forEach((data: any) => {
            const callsTotal = data.totalCalls;
            const putTotal = data.totalPuts;
            for (const a of callsTotal) {
              const id = a._id.expiration_date;
              const fiter = putTotal.find(
                (x: any) => x._id.expiration_date === id
              );
              this.optionalldata.push({
                ...a,
                putsInterest: fiter?.totalopenInterest,
                putsvolume: fiter?.totalVolume,
                date: a._id.expiration_date,
                volumeratio: fiter?.totalVolume / a.totalVolume,
                openInterestRatio: fiter?.totalopenInterest / a.totalopenInterest,
              });
              this.optionalldata.sort(
                (a: any, b: any) =>
                  new Date(a.date).getTime() - new Date(b.date).getTime()
              );
            }
          });
        }


        this.optionalldata.forEach((option: any) => {
          this.chartDate.push(option.date);
          this.chartOpenDate.push(option.date);
          this.chartCalls.push(option.totalVolume);
          this.chartputs.push(option.putsvolume);
          this.openCall.push(option.totalopenInterest);
          this.openPut.push(option.putsInterest);
        });
        this.updateChart();
        this.updateOpenChart();
      },
    });
  }

  updateChart() {
    this.chart.data.labels = this.chartDate;
    this.chart.data.datasets[0].data = this.chartCalls;
    this.chart.data.datasets[1].data = this.chartputs;
    this.chart.update();
  }

  updateOpenChart() {
    this.Interestchart.data.labels = this.chartOpenDate;
    this.Interestchart.data.datasets[0].data = this.openCall;
    this.Interestchart.data.datasets[1].data = this.openPut;
    this.Interestchart.update();
  }
}
