import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ISTable } from '../models/inside-trades';
import { environment } from 'src/environments/environment';
import { LogarithmicScale } from 'chart.js';
import {
  getInsideTabledata,
  getteninsideTabledata,
  tradessort,
} from '../const';

@Injectable({
  providedIn: 'root',
})
export class InsideTradesService {
  constructor(private http: HttpClient) {}

  getInsideTable(page: number) {
    return this.http.post<ISTable[]>(`${getInsideTabledata}${page}`, '');
  }
  // getteninsideTable() {
  //   return this.http.get<ISTable[]>(`${getteninsideTabledata}`);
  // }
  tradessort(sortKey: string, order: number, page: number) {
    return this.http.get<ISTable[]>(
      `${tradessort}?sortKey=${sortKey}&&sortOrder=${order}&&page=${page}`
    );
  }
}
