<section class="Hedgefund">
  <div class="container p-3">
    <div class="text-center fda-text">
      <h1 class="main-heading text-white m-0 me-3">Hedge Fund Screener</h1>
      <p class="sub-heading text-center text-white m-0">
        Our Hedge Funds feature tracks 13-F filings from top biotech hedge
        funds, updated quarterly to reveal the holdings, performance, and
        strategies of major investors, offering valuable insights into market
        trends and investment patterns.
      </p>
    </div>
  </div>
  <div class="container-fluid form-box py-2">
    <div
      class="row align-items-center text-center justify-content-center mt-2 mb-2"
    >
      <div class="col-lg-4 col-md-10 col-12">
        <form style="height: 30px" class="example d-flex add_m_infilter">
          <input
            #searchVal
            [(ngModel)]="searchValuequery"
            type="search"
            class="input-box"
            placeholder="Search by Fund name"
            name="search2"
          />
          <button
            (click)="searchFilters(searchVal.value)"
            class="searchquerybtn"
            type="button"
          >
            @if (isFilterBtnSearch) {
            <i style="color: white" class="fa fa-spin fa-spinner"></i>
            }@else {
            <i class="fa-solid fa-magnifying-glass"></i>
            }
          </button>
        </form>
      </div>
      <div class="col-lg-auto add_m_infilter col-md-3 col-6">
        <div class="dropdown d-flex mx-2">
          <button
            [ngClass]="selectedDataColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            Fund-Size
          </button>

          @if (selectedDataColor) {
          <span class="" (click)="clearFundSize()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }

          <ul class="dropdown-menu">
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'10b_or_more' === selectedValue"
                (click)="selectedData('10b_or_more')"
              >
                $ 10 billion or more
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'2b_to_10b' === selectedValue"
                (click)="selectedData('2b_to_10b')"
              >
                $ 2 billion to $ 10 billion
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'250m_to_2b' === selectedValue"
                (click)="selectedData('250m_to_2b')"
              >
                $ 250 million to $ 2 billion
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'250m_or_less' === selectedValue"
                (click)="selectedData('250m_or_less')"
              >
                Less than $ 250 million
              </p>
            </li>
            <li>
              <button
                class="dropdown-item dropdown-toggle dropdown-item filter-font-text text-center m-0"
                data-bs-toggle="dropdown"
              >
                Customize
              </button>
              <ul class="dropdown-menu">
                <div class="p-1" aria-labelledby="shareHoldDropdown">
                  <input
                    type="number"
                    class="custom-input-set mb-2"
                    placeholder="Min Value (Millions)"
                    [(ngModel)]="minCustomFund"
                    (change)="pushFilterMarket_cap($event)"
                  />
                  <input
                    type="number"
                    class="custom-input-set"
                    placeholder="Max Value (Millions)"
                    [(ngModel)]="maxCustomFund"
                    (change)="EndpushFilterMarket_cap($event)"
                  />
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-auto add_m_infilter col-md-3 col-6">
        <div class="dropdown d-flex mx-2">
          <button
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            [ngClass]="selectedshareColor"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            Share Held
          </button>

          @if (selectedshareColor) {
          <span class="" (click)="clearSelectedshareHeld()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }

          <ul class="dropdown-menu">
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'1_to_5' === selectedValueinshare"
                (click)="selectedData('1_to_5')"
              >
                1 to 5
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'5_to_10' === selectedValueinshare"
                (click)="selectedData('5_to_10')"
              >
                5 to 10
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'10_or_more' === selectedValueinshare"
                (click)="selectedData('10_or_more')"
              >
                10 or more
              </p>
            </li>
            <li>
              <button
                class="dropdown-item dropdown-toggle dropdown-item filter-font-text text-center m-0"
                data-bs-toggle="dropdown"
              >
                Customize
              </button>
              <ul class="dropdown-menu">
                <div class="p-1" aria-labelledby="shareHoldDropdown">
                  <input
                    type="number"
                    class="custom-input-set mb-2"
                    placeholder="Min Value"
                    [(ngModel)]="minCustomhold"
                    (change)="pushFilterShareheld($event)"
                  />
                  <input
                    type="number"
                    class="custom-input-set"
                    placeholder="Max Value"
                    [(ngModel)]="maxCustomHold"
                    (change)="EndpushFilterShareheld($event)"
                  />
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div id="add" class="text-center mt-1 d-flex justify-content-center">
        <button
          type=""
          class="form-save-button d-flex justify-content-center align-items-center text-nowrap me-3 mt-2 mb-1"
          (click)="saveFilters()"
        >
          @if (!isFilterBtn) {
          <p class="mb-0">APPLY FILTERS</p>
          }@else {
          <i class="fa fa-spin fa-spinner"></i>
          }
        </button>
        <button
          type=""
          class="form-reset-button d-flex justify-content-center align-items-center text-nowrap mt-2 mb-1"
          (click)="resetFilters()"
        >
          @if (!isFilterBtnResert) {
          <p class="mb-0">RESET FILTERS</p>
          }@else {
          <i class="fa fa-spin fa-spinner"></i>
          }
        </button>
      </div>
    </div>
  </div>
</section>

<section class="volatile">
  <p
    class="text-start tblcompanyLength my-1 text-nowrap d-flex align-items-center"
  >
    <i class="fa-solid fa-circle-info mx-2"></i>
    Showing {{ hedgeTbl?.length }} Funds Out of {{ totalDatalength }}
  </p>

  <div style="position: relative">
    <div
      class="table-responsive scrollable-table"
      (scroll)="onScroll($event)"
      id="dataContainer"
      [ngClass]="{ tablelocked: !productId }"
      #dataContainer
    >
      <table class="table align-middle mb-0 bg-white">
        <thead class="text-center">
          <tr class="text-uppercase table-header">
            <th class="table-name-size">
              <div class="d-flex justify-content-center cursor-pointer">
                Fund Name<i
                  (click)="assending('hedgeFundName')"
                  [ngClass]="arrowfundname"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                Fund Size<i
                  (click)="assending('current_mv')"
                  [ngClass]="arrowfundsize"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                Stocks Held<i
                  (click)="assending('symbolLength')"
                  [ngClass]="arrowstockheld"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                Largest Holding<i
                  (click)="assending('symbolWithMaxOwnership')"
                  [ngClass]="arrowlargestHeld"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            <!-- <th>
            <div class="d-flex justify-content-center cursor-pointer">Company Name<i (click)="assending('Issuer_Name')"
                class="fa-solid cursorpointer mx-1"></i>
            </div>
          </th> -->
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                QoQ Change<i
                  (click)="assending('QoQ_change')"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
          </tr>
        </thead>
        <tbody id="tbody" class="text-center">
          @if (!hedgeTbl || hedgeTbl?.length == 0) {
            <tr style="box-shadow: none;">
              <td colspan="17">
                <ngx-skeleton-loader
                  [theme]="{ height: '50px',  'border-radius': '4px', 'margin-top': '20px'}"
                  count="11"
                >
                </ngx-skeleton-loader>
              </td>
            </tr>
          }@else { @for (data of hedgeTbl; track $index) {

          <tr class="table-tr-font">
            <td scope="row">
              <p
                class="cursorpointer"
                (click)="indiDetails(data.hedgeFundName)"
              >
                {{ data.hedgeFundName }}
              </p>
            </td>
            <td class="table-wide-cell">
              <p class="table-font-size">
                {{ data.current_mv | dollarsCoversion }}
              </p>
            </td>
            <td>
              <p>{{ data.symbolLength }}</p>
            </td>
            <td>
              <div>
                <div class="d-flex justify-content-center align-items-center">
                  <div style="width: 40px">
                    @if (data.combinedData[0]?.logoid) {
                    <img
                      width="25px"
                      [src]="basePath + data.combinedData[0]?.logoid + '.svg'"
                      class="me-2"
                    />
                    }@else {
                    <span class="avatarcss me-2">{{
                      data.symbolWithMaxOwnership | initialLetter
                    }}</span>
                    }
                  </div>
                  <div style="width: 40px">
                    <span>
                      {{ data.symbolWithMaxOwnership }}
                    </span>
                  </div>
                </div>
                <p style="font-weight: 600" class="m-0">
                  {{ data.Issuer_Name }}
                </p>
              </div>
              <!-- @if (data.combinedData[0]?.logoid) {
            <img width="25px" [src]="basePath + data.combinedData[0]?.logoid + '.svg'" class="mb-2" />
            }@else {
            <span class="avatarcssForTable">{{
              data.symbolWithMaxOwnership | initialLetter
              }}</span>
            }
            <p>{{data.symbolWithMaxOwnership}}</p> -->
            </td>
            <!-- <td>
            <p class="m-0">{{data.Issuer_Name}}</p>
          </td> -->
            <td>
              <div class="d-flex justify-content-center align-items-center">
                <div style="width: 40px">
                  @if (data.QoQ_change !== 0) { @if (data.QoQ_change > 0) {
                  <i
                    style="color: green; font-size: 17px; opacity: unset"
                    class="fas fa-circle-up mx-2"
                  ></i>
                  }@else {
                  <i
                    style="color: red; font-size: 17px; opacity: unset"
                    class="fas fa-circle-down mx-2"
                  ></i>
                  } }
                </div>
                <div style="width: 40px">
                  <p class="m-0">{{ data.QoQ_change | number : "1.0-2" }}</p>
                </div>
              </div>
            </td>
          </tr>

          } @empty { No Record Found } } @if (loadData) {
          <tr class="loading-border">
            <td colspan="12">
              <div class="data-loading-container">
                <p class="Fetching me-3 mb-0 mt-0">
                  Fetching more data for you
                </p>
                <span class="loader mb-2" style="font-size: 3px"> </span>
              </div>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
    <div
      style="
        position: absolute;
        bottom: 0;
        background-color: #f1f7fde6;
        z-index: 1;
      "
    >
      @if (!productId) { @if (hedgeTbl?.length > 0) {
      <app-locksec></app-locksec>
      } }
    </div>
  </div>
</section>
<app-loginpop-up
  [displayStyle]="modalDisplayStyle"
  (myout)="GetChildData($event)"
></app-loginpop-up>

<!-- <tr *ngIf="isLoading" class="loading-border">
          <td class="loading-size" colspan="12">
            <div class="table-loading-container">
              <p class="Fetching me-3 mb-0 mt-0">Fetching more data for you</p>
              <span class="loader mb-2" style="font-size: 3px"> </span>
            </div>
          </td>
        </tr> -->
<!-- //*ngFor="let fda of fdaTable; let i = index" -->

<!-- <tr *ngIf="loadData" class="loading-border">
                    <td colspan="12">
                        <div class="data-loading-container">
                            <p class="Fetching me-3 mb-0 mt-0">Fetching more data for you</p>
                            <span class="loader mb-2" style="font-size: 3px"> </span>
                        </div>
                    </td>
                </tr> -->

<!-- <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{ display: displayStyle }">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title text-center">Price Votting</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closePopup()"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="plusminuspricetarget" (ngSubmit)="savePopup()" action="">
                        <div class="d-flex text-center justify-content-center">
                            <div style="width: 100%">
                                <input formControlName="up_button"
                                    class="votting-price-up btn profile-edit-button button-up fw-bold text-uppercase btn-light shadow rounded password-input-save"
                                    type="submit" value="Up" />
                            </div>
                            <div style="width: 100%">
                                <input formControlName="down_button"
                                    class="votting-price-up button-down btn profile-edit-button fw-bold text-uppercase btn-light shadow rounded password-input-save"
                                    type="submit" value="Down" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{ display: displayStyle2 }">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title text-center">Price Target</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closePopup()"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="priceTarget" (ngSubmit)="priceSavePopup()" action="">
                        <div class="d-flex text-center justify-content-center price_target_input_maindiv">
                            <div class="me-3 popup-input">
                                <input formControlName="price_up_button" inputmode="numeric" class="price_target_input"
                                    type="number" value="price_up_button" (input)="validateInput($event)" />
                            </div>
                            <div class="popup-button">
                                <button
                                    class="votting-price-up btn profile-edit-button button-up fw-bold text-uppercase btn-light shadow rounded password-input-save"
                                    type="submit" [disabled]="!priceTarget.valid">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>-->

<app-loginpop-up
  [displayStyle]="modalDisplayStyle"
  (myout)="GetChildData($event)"
></app-loginpop-up>
