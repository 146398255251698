import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DecryptionService {
  constructor() {}

  decrypt(data: string): any {
    // let secret = environment.secretKey
    // const decrypted = CryptoJS.AES.decrypt(data, secret).toString(CryptoJS.enc.Utf8);
    // return decrypted

    const key = environment.secretKey;

    // Decrypt the text
    const bytes = CryptoJS.AES.decrypt(data, key);
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedText;
  }
}
