import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  getDailyUpdates,
  top_insiders_buyer,
  top_insiders_seller,
  ununusualVolumeinDailyUpdates,
} from '../const';

@Injectable({
  providedIn: 'root',
})
export class DailyUpdatesService {
  constructor(private http: HttpClient) {}

  dailyUpdatesdata() {
    return this.http.get<any[]>(`${getDailyUpdates}`);
  }

  top_insidersdata_seller(date: string) {
    return this.http.get<any[]>(`${top_insiders_seller}${date}`);
  }

  top_insidersdata_buyer(date: string) {
    return this.http.get<any[]>(`${top_insiders_buyer}${date}`);
  }

  unusual_volumesAtDailyUpdates() {
    return this.http.get<any[]>(`${ununusualVolumeinDailyUpdates}`);
  }
}
