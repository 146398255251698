import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Contact_us, advertise_us } from '../const';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  constructor(private http: HttpClient) { }

  contactusData(data:any) {
    return this.http.post<any[]>(`${Contact_us}`,data);
  }
  advertiesData(data:any) {
    return this.http.post<any[]>(`${advertise_us}`,data);
  }
}
