<section class="volatile leptop-section">
  <div class="container-lg">
    <div class="row">
      <div class="col-12">
        <div class="title-shape">
          <h1 class="main-blue-heading">Today’s most Volatile</h1>
        </div>
        <div class="row">
          @if (plusData.length != 0 && minusData.length !=0) {
          <div class="d-responsive" id="dataContainer">
            <div class="col">
              @for (plus of plusData; track $index) {
              <div class="volatile-content">
                <ul>
                  <li class="volatile-data-hover">
                    @if (plus.logoid !== '') {
                    <img
                      (click)="plusDetails(plus.symbol)"
                      width="30px"
                      [src]="basePath + plus.logoid + '.svg'"
                      class="mb-2"
                    />
                    }@else {
                    <span class="avatarcssForTable">{{
                      plus.name | initialLetter
                    }}</span>
                    }
                    <h6 class="mt-not-0 name-text-lep">
                      {{ plus.name }} <br />
                      <span class="most-value-btn">{{ plus.symbol }}</span>
                    </h6>
                    <p class="d-none">{{ $index + 1 }}</p>
                    <a
                      class="btn-green close_price_width-lep text-center text-nowrap"
                      title=""
                      >{{ plus.close.toString() | number : "1.0-2" }} $
                    </a>
                    <P class="change-plus-box"
                      >{{ plus.change | number : "1.0-2" }} %
                    </P>
                  </li>
                </ul>
              </div>
              }@empty { No Record Found }
            </div>
            <div class="col minusdata">
              @for (minus of minusData; track $index) {
              <div class="">
                <ul class="ps-0">
                  <li class="volatile-data-hover">
                    @if (minus.logoid !== '') {
                    <img
                      (click)="minusDetails(minus.symbol)"
                      width="30px"
                      [src]="basePath + minus.logoid + '.svg'"
                      class="mb-2"
                    />
                    }@else {
                    <span class="avatarcssForTable">{{
                      minus.name | initialLetter
                    }}</span>
                    }
                    <h6 class="mt-not-0 name-text-lep">
                      {{ minus.name }} <br />
                      <span class="most-value-btn">{{ minus.symbol }}</span>
                    </h6>
                    <p class="d-none">{{ $index + 1 }}</p>
                    <a
                      title=""
                      class="button-red close_price_width-lep text-center text-nowrap"
                      >{{ minus.close.toString() | number : "1.0-2" }} $
                    </a>
                    <P class="change-minus-box"
                      >{{ minus.change | number : "1.0-2" }}
                      %
                    </P>
                  </li>
                </ul>
              </div>
              }@empty { No Record Found }
            </div>
          </div>
          }@else {
          <div class="loader-container my-5">
            <div class="table-loading-container d-flex align-items-center">
              <p style="font-size: 20px" class="Fetching me-3 mb-0 mt-0">
                Fetching more data for you
              </p>
              <span class="loader mb-2" style="font-size: 3px"> </span>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section class="volatile mobile-section d-none">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="title-shape">
          <h3 class="main-blue-heading">Today’s most Volatile</h3>

        </div>
        <div class="row">
          <div class="d-responsive">
            <div class="d-flex justify-content-evenly">
              <h3 (click)="gainersdata()" class="p-2 text-center gainer-text mb-0 mt-2">Gainer</h3>
              <h3 (click)="lossingdata()" class="p-2 text-center loosing-text mb-0 mt-2">Loosers</h3>
            </div>
            <div *ngIf="gainers" class="col">
              <div class="volatile-content" *ngFor="let plus of plusData; let ip = index">
                <ul>
                  <li class="volatile-data-hover">
                    <div class="d-grid d-grid-mobile">
                      <img width="40px" [src]="basePath + plus.logoid + '.svg'" *ngIf="plus.logoid !== ''; else noImage"
                        class="mb-2 company-hover" (click)="plusDetails(plus.symbol)" />
                      <ng-template #noImage>
                        <span class="avatarcss mb-2">{{
                          plus.name | initialLetter
                          }}</span>
                      </ng-template>
                      <span class="most-value-btn">{{ plus.symbol }}</span>
                    </div>
                    <h6 class="mt-not-0 name-text">{{ plus.name }} <br /></h6>
                    <p class="d-none">{{ ip + 1 }}</p>
                    <div>
                      <div>
                        <a style="width: 80px" class="btn-green btn text-nowrap" title="">{{ plus.close.toString() | number :
                          "1.0-2" }} $
                        </a>
                      </div>
                      <div>
                        <P class="mt-1 change-mobile-plus-box">{{ plus.change | number : "1.0-2" }}%</P>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div *ngIf="loosings" class="col minusdata">
              <div class="">
                <ul class="ps-0" *ngFor="let minus of minusData; let im = index">
                  <li class="volatile-data-hover">
                    <div class="d-grid d-grid-mobile">
                      <img width="40px" *ngIf="minus.logoid !== ''; else noImage"
                        [src]="basePath + minus.logoid + '.svg'" class="mb-2 company-hover"
                        (click)="minusDetails(minus.symbol)" />
                      <ng-template #noImage>
                        <span class="avatarcss mb-2">{{
                          minus.name | initialLetter
                          }}</span>
                      </ng-template>
                      <span class="most-value-btn mt-1">{{
                        minus.symbol
                        }}</span>
                    </div>
                    <h6 class="mt-not-0 name-text">{{ minus.name }} <br /></h6>
                    <p class="d-none">{{ im + 1 }}</p>
                    <div>
                      <div>
                        <a style="width: 80px" class="btn button-red text-nowrap" title="">{{ minus.close.toString() |
                          number : "1.0-2" }} $
                        </a>
                      </div>
                      <div>
                        <P class="change-minus-box">{{ minus.change | number : "1.0-2" }}
                          %
                        </P>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section class="mt-5 mobile-section d-none">
  <div class="d-flex justify-content-evenly mb-4">
    <h3
      #gainerTab
      class="p-2 text-center gainer-loser-btn gainer-text mb-0 mt-2"
      (click)="selectTab(0)"
      [ngClass]="activeGainerTab"
    >
      Gainer
    </h3>

    <h3
      #loserTab
      class="p-2 text-center gainer-loser-btn loosing-text mb-0 mt-2"
      (click)="selectTab(1)"
      [ngClass]="activebtntab"
    >
      Losers
    </h3>
  </div>

  <ngb-carousel [activeId]="activeSlide" (slide)="onSlideChange($event)">
    <ng-template ngbSlide id="0">
      <div class="col">
        <div
          class="volatile-content"
          *ngFor="let plus of plusData; let ip = index"
        >
          <ul>
            <li class="volatile-data-hover d-flex justify-content-evenly">
              <div class="d-grid d-grid-mobile">
                @if (plus.logoid !== '') {
                <img
                  width="40px"
                  [src]="basePath + plus.logoid + '.svg'"
                  class="mb-0 company-hover"
                />
                }@else {
                <span class="avatarcss mb-0">{{
                  plus.name | initialLetter
                }}</span>
                }
                <span class="most-value-btn">{{ plus.symbol }}</span>
              </div>
              <h6 class="mt-not-0 name-text">{{ plus.name }} <br /></h6>
              <p class="d-none">{{ ip + 1 }}</p>
              <div>
                <div>
                  <a
                    style="width: 80px"
                    class="btn-green btn text-nowrap"
                    title=""
                    >{{ plus.close.toString() | number : "1.0-2" }} $
                  </a>
                </div>
                <div>
                  <P class="mt-1 change-mobile-plus-box"
                    >{{ plus.change | number : "1.0-2" }}%</P
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </ng-template>

    <ng-template ngbSlide id="1">
      <div class="col minusdata">
        <div class="">
          <ul class="ps-0" *ngFor="let minus of minusData; let im = index">
            <li class="volatile-data-hover d-flex justify-content-evenly">
              <div class="d-grid d-grid-mobile">
                @if (minus.logoid !== '') {
                <img
                  width="40px"
                  [src]="basePath + minus.logoid + '.svg'"
                  class="mb-0 company-hover"
                />
                }@else {
                <span class="avatarcss mb-0">{{
                  minus.name | initialLetter
                }}</span>
                }
                <span class="most-value-btn mt-1">{{ minus.symbol }}</span>
              </div>
              <h6 class="mt-not-0 name-text">{{ minus.name }} <br /></h6>
              <p class="d-none">{{ im + 1 }}</p>
              <div>
                <div>
                  <a
                    style="width: 80px"
                    class="btn button-red text-nowrap"
                    title=""
                    >{{ minus.close.toString() | number : "1.0-2" }} $
                  </a>
                </div>
                <div>
                  <P class="change-minus-box"
                    >{{ minus.change | number : "1.0-2" }}
                    %
                  </P>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </ng-template>
  </ngb-carousel>
</section>
