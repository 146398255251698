<section class="volatile">
  <div class="container-fluid p-2 fda-header-box">
    <div class="text-center fda-text">
      <h1 class="main-heading text-white m-0 me-3">
        Historical FDA PDUFA Dates
      </h1>

      <p class="sub-heading text-white m-0">
        Historical PDUFA dates, offering a detailed PDUFA timeline and insights
        into FDA approval dates history.
      </p>
    </div>
  </div>

  <div class="container-fluid form-box py-2">
    <div class="row justify-content-center align-items-start mt-1">
      <div class="col-lg-3 col-md-10 col-12">
        <form style="height: 30px" class="example d-flex add_m_infilter">
          <input
            [(ngModel)]="searchValuequery"
            autocomplete="off"
            (keyup)="searchFilters($event)"
            (input)="onSearchInput($event)"
            #searchVal
            type="search"
            class="input-box"
            placeholder="Search by Comapny name or Ticker"
            name="search2"
          />
          <button
            (click)="searchFiltersonclick(searchValuequery)"
            class="searchquerybtn"
            type="button"
          >
            @if (isFilterBtnSearch) {
            <i style="color: white" class="fa fa-spin fa-spinner"></i>

            }@else {
            <i class="fa-solid fa-magnifying-glass"></i>
            }
          </button>
        </form>
        @if (suggection?.length > 0) {
        <ul class="list-unstyled m-0">
          @for (suggestion of suggection; track $index) {
          <li class="suggestion-item p-0 cursorpointer">
            <div
              (click)="searchFiltersonclick(suggestion.symbol)"
              class="d-flex filter-bottom justify-content-between px-2"
            >
              <span>
                @if (suggestion.logoid !== '') {
                <img
                  width="25px"
                  [src]="basePath + suggestion.logoid + '.svg'"
                />
                }@else {
                <span
                  style="
                    width: 25px !important;
                    height: 25px !important;
                    margin: 0 !important;
                  "
                  class="avatarcss"
                  >{{ suggestion.name | initialLetter }}</span
                >
                }
              </span>
              <p
                class="text-start text-dark filter-font-text mb-1 border-1 me-4"
              >
                {{ suggestion.name }}
              </p>
              <p class="text-end text-dark filter-font-text mb-1">
                {{ suggestion.symbol }}
              </p>
            </div>
          </li>
          }
        </ul>
        }
      </div>

      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedDateColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <span>Date</span>
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if (selectedDateColor) {
          <span class="" (click)="clearSelectedDate()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }

          <ul class="dropdown-menu">
            <li>
              <p
                [class.selected]="'Last month' === selectedValueinDate"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('Last month')"
              >
                Last month
              </p>
            </li>
            <li>
              <p
                [class.selected]="'Last quarter' === selectedValueinDate"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('Last quarter')"
              >
                Last quarter
              </p>
            </li>
            <li>
              <p
                [class.selected]="'Last year' === selectedValueinDate"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('Last year')"
              >
                Last year
              </p>
            </li>
            <!-- <li>
                            <p [class.selected]="'Next Month' === selectedValueinDate"
                                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                                (click)="selectedData('Next Month')">
                                Next Month
                            </p>
                        </li> -->
            <li>
              <button
                class="dropdown-item dropdown-toggle dropdown-item filter-font-text text-center m-0"
                data-bs-toggle="dropdown"
              >
                Customize
              </button>
              <ul class="dropdown-menu">
                <div class="p-1" aria-labelledby="shareHoldDropdown">
                  <input
                    type="date"
                    class="custom-input-set mb-2"
                    placeholder="Min Value"
                    [(ngModel)]="minCustomDate"
                    (input)="updateDateCustomRange()"
                    (change)="pushFilterDateStart($event)"
                  />
                  <input
                    type="date"
                    class="custom-input-set"
                    placeholder="Max Value"
                    [(ngModel)]="maxCustomDate"
                    (input)="updateDateCustomRange()"
                    (change)="pushFilterDateEnd($event)"
                  />
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedstatusColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <span>Status</span>
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if (selectedstatusColor) {

          <span class="" (click)="clearSelectedStatus()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>

          }
          <ul class="dropdown-menu">
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'Approval' === selectedValueinStatus"
                (click)="selectedData('Approval')"
              >
                Approval
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'CRL' === selectedValueinStatus"
                (click)="selectedData('CRL')"
              >
                CRL
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div id="add" class="text-center mt-1 d-flex justify-content-center">
      <button
        type=""
        class="form-save-button d-flex justify-content-center align-items-center text-nowrap me-3 mt-2 mb-1"
        (click)="saveFilters()"
      >
        @if (!isFilterBtn) {
        <p class="mb-0">APPLY FILTERS</p>
        }@else {
        <i class="fa fa-spin fa-spinner"></i>
        }
      </button>
      <button
        type=""
        class="form-reset-button d-flex justify-content-center align-items-center text-nowrap me-3 mt-2 mb-1"
        (click)="resetFilters()"
      >
        @if (!isFilterBtnResert) {
        <p class="mb-0">RESET FILTERS</p>
        }@else {
        <i class="fa fa-spin fa-spinner"></i>
        }
      </button>
    </div>
  </div>
</section>

<section class="volatile">
  @if (showButtons) {

  <div class="scrollbtns d-flex justify-content-between">
    <img
      id="left-button"
      (click)="scrollLeft()"
      class="button"
      src="assets/images/left-blue-arrow.svg"
      alt=""
    />
    <img
      id="right-button"
      (click)="scrollRight()"
      class="button"
      src="assets/images/right-blue-arrow.svg"
      alt=""
    />
  </div>

  }

  <div class="d-flex justify-content-between align-items-center mx-1">
    <p
      class="text-start tblcompanyLength my-1 text-nowrap d-flex align-items-center"
    >
      <i class="fa-solid fa-circle-info mx-2"></i>
      Showing {{ pdufaTable.length }} Drugs Out of {{ totalDatalength }}
    </p>
  </div>

  <div style="position: relative">
    <div
      class="table-responsive scrollable-table"
      id="dataContainer"
      #dataContainer
      (scroll)="onScroll($event)"
      [ngClass]="{ tablelocked: !productId }"
    >
      <table class="table align-middle mb-0 bg-white">
        <thead class="text-center">
          <tr class="text-uppercase table-header">
            <th class="table-name-size">Name</th>
            <th style="width: 200px" sty class="table-name-size">Tiker</th>
            <th style="width: 200px" class="table-name-size">PDUFA Date</th>
            <th style="width: 300px" class="table-name-size">Drug/Therapy</th>
            <th style="width: 400px" class="table-name-size">Indication</th>
            <th class="table-name-size">Status</th>
          </tr>
        </thead>
        <tbody id="tbody" class="text-center">
          @if (pdufaTable.length === 0) {
            <tr style="box-shadow: none;">
              <td colspan="17">
                <ngx-skeleton-loader
                  [theme]="{ height: '50px',  'border-radius': '4px', 'margin-top': '20px'}"
                  count="11"
                >
                </ngx-skeleton-loader>
              </td>
            </tr>
          }@else { @for (pdufa of pdufaTable; track $index) {
          <tr class="table-tr-font">
            <td class="" scope="row">
              <div
                (click)="companyDetails(pdufa.Ticker)"
                class="text-center company-hover p-1"
              >
                @if (pdufa.logoid.length !== 0) {
                <img
                  width="25px"
                  [src]="basePath + pdufa.logoid + '.svg'"
                  class="mb-1"
                />
                }@else {
                <span class="avatarcssForTable">{{
                  pdufa.Ticker | initialLetter
                }}</span>
                }

                <p class="text-center text-data table-font-size">
                  <a
                    href="{{ clientUrl }}/company/{{
                      pdufa.Ticker | uppercase
                    }}"
                    class="link-ticker"
                  >
                    {{ pdufa.name }}
                  </a>
                </p>
              </div>
            </td>

            <td class="" style="z-index: 1">
              <div class="d-grid align-items-center justify-content-center">
                <p class="table-astec-font text-center table-font-size m-0">
                  {{ pdufa.Ticker }}
                </p>
                @if (pdufa.Ticker) { @if (portfoliomatchTicker) { @if
                (portfoliomatchTicker.includes(pdufa.Ticker)) {
                <i
                  placement="bottom"
                  container="body"
                  ngbTooltip="Remove from Portfolio"
                  (click)="removeSymboltoPotfoliyo(pdufa.Ticker)"
                  style="
                    opacity: unset;
                    font-size: 20px;
                    color: var(--clr-primary);
                  "
                  class="fa-solid fa-circle-minus mt-2 cursorpointer"
                ></i>
                }@else {
                <i
                  placement="bottom"
                  container="body"
                  ngbTooltip="Add to Portfolio"
                  (click)="
                    this.portfolioTicker = pdufa.Ticker;
                    this.portfolioPrice = pdufa.close;
                    portfoliocost = undefined;
                    portfolioquntity = undefined
                  "
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style="
                    opacity: unset;
                    font-size: 20px;
                    color: var(--clr-primary);
                  "
                  class="fa-solid fa-circle-plus mt-2 cursorpointer"
                ></i>
                } } @if (!portfoliomatchTicker) {
                <i
                  placement="bottom"
                  container="body"
                  ngbTooltip="Add to Portfolio"
                  (click)="
                    portfolioTicker = pdufa.Ticker;
                    this.portfolioPrice = pdufa.close;
                    portfoliocost = undefined;
                    portfolioquntity = undefined
                  "
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style="
                    opacity: unset;
                    font-size: 20px;
                    color: var(--clr-primary);
                  "
                  class="fa-solid fa-circle-plus mt-2 cursorpointer"
                ></i>
                } }
              </div>
            </td>

            <td class="table-font-size" scope="row" style="z-index: 1">
             <a class="table-text-blue" href="{{clientUrl}}/company/{{pdufa.Ticker}}?section=catalyst">{{ pdufa["PDUFA Date"] }}</a> 
            </td>
            <td class="table-font-size" scope="row" style="z-index: 1">
              <a href="{{clientUrl}}/company/{{pdufa.Ticker}}?section=catalyst" class="text-dark">
                {{ pdufa.Drug }}
              </a>
            </td>
            <td class="table-font-size" scope="row" style="z-index: 1">
              <a href="{{clientUrl}}/company/{{pdufa.Ticker}}?section=catalyst" class="text-dark">{{ pdufa.Indication.substring(0, 100) }}</a>
              @if (pdufa.Indication.length > 100) {
              <p
                style="cursor: pointer"
                class="text-danger"
                container="body"
                ngbTooltip="{{ pdufa.Indication }}"
                triggers="click:blur"
              >
                Read More+
              </p>
              }
            </td>
            <td class="table-font-size" scope="row" style="z-index: 1">
              {{ pdufa.Decision }}
            </td>
          </tr>
          }@empty { No data Found } }
        </tbody>
        <tfoot>
          @if (loadData) {
          <tr class="loading-border">
            <td colspan="14">
              <div class="data-loading-container">
                <p class="Fetching me-3 mb-0 mt-0">
                  Fetching more data for you
                </p>
                <span class="loader mb-2" style="font-size: 3px"> </span>
              </div>
            </td>
          </tr>
          }
        </tfoot>
      </table>
    </div>
    <div
      style="
        position: absolute;
        bottom: 0;
        background-color: #f1f7fde6;
        z-index: 1;
      "
    >
      @if (!productId) { @if (pdufaTable.length > 0) {
      <app-locksec></app-locksec>
      } }
    </div>
  </div>

  <app-loginpop-up
    [displayStyle]="modalDisplayStyle"
    (myout)="GetChildData($event)"
  ></app-loginpop-up>
  <app-portfoliomodal
    [portfolioTicker]="portfolioTicker"
    [portfolioPrice]="portfolioPrice"
    [portfoliocost]="portfoliocost"
    [portfolioquntity]="portfolioquntity"
    (addSymbol)="onAddSymbol($event)"
  ></app-portfoliomodal>
</section>
