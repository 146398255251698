import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BioHedgeFundShots, HedgefundFamous, OncologyShots } from '../const';

@Injectable({
  providedIn: 'root',
})
export class BioHedgeFundShotsService {
  constructor(private http: HttpClient) {}

  bioHedgeFundShots() {
    return this.http.get(`${BioHedgeFundShots}`);
  }

  apiOncologyShots() {
    return this.http.get(`${OncologyShots}`);
  }
  apiHedgefundFamous() {
    return this.http.get(`${HedgefundFamous}`);
  }
}
