import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Route, Router } from '@angular/router';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { Subscription } from '../model/subscription';
import { environment } from 'src/environments/environment';
import { footerEmail } from '../../shared/const';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  constructor(private http: HttpClient) { }

  footerEmail(data: any) {
    return this.http.post<any>(`${footerEmail}`, data);
  }
}
