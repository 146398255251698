import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CanonicalService } from './modules/shared/service/canonical.service';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'bio-pharma-watch';

  constructor(
    private route: Router,
    private canonicalService: CanonicalService
  ) {}

  ngOnInit(): void {
    this.PageNavigation();
    this.canonicalService.setCanonicalURL();
  }

  checkRoute() {
    this.route.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.canonicalService.setCanonicalURL();
      });
  }

  PageNavigation() {
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }
}
