import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zerotoDash'
})
export class ZerotoDashPipe implements PipeTransform {

  transform(value: any): any {

    if (!value) {
      return '-';
    }
    if (value === "0") {
      return '-';
    }
    return `${value} $`;
  }

}
