import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  optionIVData,
  companyCashData,
  companyDetail,
  companyPipelineData,
  companyhedgedata,
  companyinsidetrades,
  companynews,
  companyupcoming,
  optionData,
  catalystresult,
} from '../../../shared/models/company-details';
import { CompanyDetailsService } from '../../../shared/service/company-details.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
declare const TradingView: any;
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs';
import { DecryptionService } from 'src/app/modules/shared/service/decryption.service';
import { FdaCalenarService } from 'src/app/modules/shared/service/fda-calendar.service';
import { LoginService } from 'src/app/modules/core/services/login.service';
import { ProfileService } from 'src/app/modules/core/services/profile.service';
import { Title } from '@angular/platform-browser';
import { DollarsCoversionPipe } from 'src/app/modules/shared/pipes/dollars-coversion.pipe';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
  providers: [DollarsCoversionPipe],
})
export class CompanyDetailsComponent implements OnInit {
  @ViewChild('myel') myel!: ElementRef;
  @ViewChild('drugPipelineSection') drugPipelineSection!: ElementRef;
  @ViewChild('insideTradesSection') insideTradesSection!: ElementRef;
  @ViewChild('catalystresultSection') catalystresultSection!: ElementRef;
  @ViewChild('upcomingcatalystsection') upcomingcatalystsection!: ElementRef;
  @ViewChild('optiondataSection') optiondataSection!: ElementRef;
  @ViewChild('HedgeFundsSection') HedgeFundsSection!: ElementRef;
  @ViewChild('NewsSectionSection') NewsSectionSection!: ElementRef;
  companyDetails: companyDetail[] = [];
  companyCashData: companyCashData[] = [];
  companyPipelineData: companyPipelineData[] = [];
  companytrades: companyinsidetrades[] = [];
  catalystresultData: catalystresult[] = [];
  upcomingdata: companyupcoming[] = [];
  hedgedata: companyhedgedata[] = [];
  optiondata: optionData[] = [];
  optionIv: optionIVData[] = [];
  companynews: companynews[] = [];
  optionalldata: any[] = [];
  optionTwotable: any[] = [];
  basePath: string = 'https://s3-symbol-logo.tradingview.com/';
  symbol: any;
  cssClass = '';
  ticker!: string;
  socket = io(environment.socketUrl);
  socketData: any[] = [];
  data: any;
  wCapital!: number;
  Dtoe!: number;
  burnrate!: number;
  runway!: number;
  isCashDataShow: boolean = true;
  isPipelineDataShow: boolean = false;
  istradesShow: boolean = false;
  showNewdata: boolean = false;
  optiondataShow: boolean = false;
  hedgedataShow: boolean = false;
  upcomingdatashow: boolean = false;
  catalystresult: boolean = false;
  customclassfortrades = '';
  customclassforoption = '';
  customclassforhedge = '';
  customclassfordrug = '';
  customclassforupcomingcyt = '';
  customclassfordrugsize = '';
  customclassforoptiondata = '';
  customclassforcatalystresult = '';
  companyDescription: Boolean = true;
  item1: any;
  companydescriptionshow: any;
  companyticker: any;
  showFullDescription = false;
  displayStyle = 'none';
  newsItem: any;
  callTotal = 0;
  putTotal = 0;
  ratio = 0;
  opencallTotal = 0;
  openputTotal = 0;
  openratio = 0;
  value: any[] = [];
  putvalue: any[] = [];
  putcallratio: any[] = [];
  openvalue: any[] = [];
  openputvalue: any[] = [];
  openputcallratio: any[] = [];
  portfoliomatchTicker: any[] = [];
  companySymbol: any;
  isOptionDatascroll: any;
  isInsidersDatascroll: any;
  localsymbol!: any | null;
  modalDisplayStyle!: boolean;
  showloginWarning = false;
  portfoliocost: number | undefined;
  portfolioTicker: any;
  portfolioPrice: any;
  portfolioquntity: number | undefined;
  portfoliototal: any;
  productId: any;
  clientUrl = environment.clientUrl;

  tableCatalyst = [
    { name: 'catalyst date', valuename: 'catdate' },
    { name: 'drug name', valuename: 'drugname' },
    { name: 'Treatment', valuename: 'treatment' },
    { name: 'stage', valuename: 'stage' },
    { name: 'close', valuename: 'close' },
    { name: 'change', valuename: 'change' },
    { name: 'change abs', valuename: 'change_abs' },
    { name: 'description', valuename: 'description' },
  ];
  tableCatalystresult = [
    { name: 'Ticker', valuename: 'ticker' },
    { name: 'clinical trial phase', valuename: 'clinical_trial_phase' },
    {
      name: 'clinical trial results summary',
      valuename: 'clinical_trial_results_summary',
    },
    { name: 'date', valuename: 'date' },
    { name: 'Drug name', valuename: 'drug_name' },
    { name: 'indications', valuename: 'indications' },
    { name: 'title', valuename: 'title' },
  ];

  tableInside = [
    { name: 'Transactor name', valuename: 'name' },
    { name: 'Traded shares', valuename: 'change' },
    { name: 'trade price', valuename: 'transactionPrice' },
    { name: 'trade value', valuename: 'traded_value' },
    { name: 'Trade Date', valuename: 'transactionDate' },
    { name: 'Current Holdings', valuename: 'share' },
  ];

  tablePipeline = [
    { name: 'Pipeline', valuename: 'InterventionName' },
    { name: 'Condition', valuename: 'Condition' },
    { name: 'Phase', valuename: 'Phase' },
    { name: 'Catalyst Date', valuename: 'CompletionDate' },
    { name: 'Study Title', valuename: 'BriefTitle' },
    { name: 'NCT Id', valuename: 'nctId' },
  ];

  tableHedge = [
    { name: 'HedgeFund Name', valuename: 'hedgeFundName', isFunction: true },
    { name: '% of Portfolio', valuename: 'current_percent_of_portfolio' },
    { name: 'current mv', valuename: 'current_mv' },
  ];

  // tableOption = [
  //   { name: 'Expiration', valuename: 'hedgeFundName' },
  //   { name: 'Calls', valuename: 'current_percent_of_portfolio' },
  //   { name: 'Puts', valuename: 'current_mv' },
  //   { name: 'Put-Call Ratio	', valuename: 'current_mv' },
  //   { name: 'Calls', valuename: 'current_percent_of_portfolio' },
  //   { name: 'Puts', valuename: 'current_mv' },
  //   { name: 'Put-Call Ratio	', valuename: 'current_mv' },
  // ];

  @Output() activesearchincompanypage: EventEmitter<string> =
    new EventEmitter();

  //snapshot query section value optionsection

  constructor(
    private compamyDetailsService: CompanyDetailsService,
    private toaster: ToastrService,
    private route: ActivatedRoute,
    private el: ElementRef,
    private router: Router,
    private decryptionService: DecryptionService,
    private fdacalendarservice: FdaCalenarService,
    private titleService: Title,
    private dolloarconv: DollarsCoversionPipe
  ) {}

  ngOnInit() {
    this.companySymbol = this.route.snapshot.paramMap.get('ticker');
    this.isOptionDatascroll = this.route.snapshot.queryParams['section'];

    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event) => {
        window.location.reload();
      });

    this.loadTradingViewWidget();

    if (!localStorage.getItem('loggedInUser')) {
      this.showloginWarning = true;
    }

    this.getPipelineDetails();
    this.getoptionSIVData();
    this.getCompanyDetails();
    this.getInsidetradesDetails();
    this.getoverviewData();
    this.getupcomingcataDetails();
    this.getNewesDetails();
    this.gethedgeFundDetails();
    this.tblcompaniesScoket();
    this.getcatalystresult();

    //pass scroll scrollToSection(section)
  }

  ngAfterViewInit() {
    if (this.isOptionDatascroll === 'option') {
      setTimeout(() => {
        this.scrollToSection(this.myel);
      }, 1500);
    }
    if (this.isOptionDatascroll === 'Insiders') {
      setTimeout(() => {
        this.scrollToSection(this.insideTradesSection);
      }, 1500);
    }
    if (this.isOptionDatascroll === 'catalyst') {
      setTimeout(() => {
        this.scrollToSection(this.upcomingcatalystsection);
      }, 1500);
    }
    if (this.isOptionDatascroll === 'drugpipe') {
      setTimeout(() => {
        this.scrollToSection(this.drugPipelineSection);
      }, 1500);
    }
    // if (this.isOptionDatascroll === 'drugpipe') {
    //   setTimeout(() => {
    //     this.scrollToSection(this.drugPipelineSection);
    //   }, 1500);
    // }
  }

  scrollToSection(section: any): void {
    if (section && section.nativeElement) {
      section.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  scrollToOption(section: any): void {
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }

  tblcompaniesScoket() {
    this.socket.on('tblcompaniesScoket', (res: any) => {
      if (res.operationType == 'update') {
        var Data = {
          ...res.documentKey,
          ...res.updateDescription.updatedFields,
        };

        this.companyDetails.filter((socketObj: any) => {
          if (socketObj._id === Data._id) {
            socketObj.close = Data.close ? Data.close : socketObj.close;
          }
          if (socketObj._id === Data._id) {
            socketObj.change = Data.change
              ? Data.change.toFixed(2)
              : socketObj.change;
          }
          if (socketObj._id === Data._id) {
            socketObj.bb_lower = Data.bb_lower
              ? Data.bb_lower
              : socketObj.bb_lower;
          }
          if (socketObj._id === Data._id) {
            socketObj.bb_upper = Data.bb_upper
              ? Data.bb_upper
              : socketObj.bb_upper;
          }
          if (socketObj._id === Data._id) {
            socketObj.change_abs = Data.change_abs
              ? Data.change_abs.toFixed(4)
              : socketObj.change_abs;
          }
          if (socketObj._id === Data._id) {
            socketObj.earnings_per_share_basic_ttm =
              Data.earnings_per_share_basic_ttm
                ? Data.earnings_per_share_basic_ttm
                : socketObj.earnings_per_share_basic_ttm;
          }
          if (socketObj._id === Data._id) {
            socketObj.market_cap_basic = Data.market_cap_basic
              ? Data.market_cap_basic
              : socketObj.market_cap_basic;
          }
          if (socketObj._id === Data._id) {
            socketObj.minmov = Data.minmov ? Data.minmov : socketObj.minmov;
          }
          if (socketObj._id === Data._id) {
            socketObj.minmove2 = Data.minmove2
              ? Data.minmove2
              : socketObj.minmove2;
          }
          if (socketObj._id === Data._id) {
            socketObj.number_of_employees = Data.number_of_employees
              ? Data.number_of_employees
              : socketObj.number_of_employees;
          }
          if (socketObj._id === Data._id) {
            socketObj.perf_1_m = Data.perf_1_m
              ? Data.perf_1_m
              : socketObj.perf_1_m;
          }
          if (socketObj._id === Data._id) {
            socketObj.perf_3_m = Data.perf_3_m
              ? Data.perf_3_m
              : socketObj.perf_3_m;
          }
          if (socketObj._id === Data._id) {
            socketObj.perf_5y = Data.perf_5y ? Data.perf_5y : socketObj.perf_5y;
          }
          if (socketObj._id === Data._id) {
            socketObj.perf_6_m = Data.perf_6_m
              ? Data.perf_6_m
              : socketObj.perf_6_m;
          }
          if (socketObj._id === Data._id) {
            socketObj.perf_all = Data.perf_all
              ? Data.perf_all
              : socketObj.perf_all;
          }
          if (socketObj._id === Data._id) {
            socketObj.perf_w = Data.perf_w ? Data.perf_w : socketObj.perf_w;
          }
          if (socketObj._id === Data._id) {
            socketObj.perf_y = Data.perf_y ? Data.perf_y : socketObj.perf_y;
          }
          if (socketObj._id === Data._id) {
            socketObj.perf_ytd = Data.perf_ytd
              ? Data.perf_ytd
              : socketObj.perf_ytd;
          }
        });
      } else {
      }
    });
    this.socket.connect();
  }

  getCompanyDetails() {
    this.compamyDetailsService.getCompanyTable(this.companySymbol).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);

        const ParseDecryptedData = JSON.parse(decryptData);
        this.productId = ParseDecryptedData?.productId;

        ParseDecryptedData.data.forEach((data: any) => {
          this.companydescriptionshow = data.Company_Description;
          if (data.change > 0) {
            this.titleService.setTitle(
              `${data.symbol} ${data.close} ↑	 ${data.change.toFixed(2)}%`
            );
          } else {
            this.titleService.setTitle(
              `${data.symbol} ${data.close} ↓ ${data.change.toFixed(2)}%`
            );
          }
        });

        if (this.companydescriptionshow === undefined) {
          this.companyDescription = false;
        }
        const result = ParseDecryptedData.data[0];
        this.companyDetails.push(result);
      },
    });
  }

  getPipelineDetails() {
    this.compamyDetailsService
      .getCompanyPipelineData(this.companySymbol)
      .subscribe({
        next: (res) => {
          const decryptData = this.decryptionService.decrypt(res.result);

          const ParseDecryptedData = JSON.parse(decryptData);
          if (ParseDecryptedData.data.length === 0) {
            this.isPipelineDataShow = false;
          } else {
            this.isPipelineDataShow = true;
            this.companyPipelineData = ParseDecryptedData.data;

            this.productId = ParseDecryptedData?.productId;

            if (!ParseDecryptedData?.productId || this.showloginWarning) {
              let lockedArray: any[] = [];

              for (let i = 0; i < 4; i++) {
                lockedArray.push(this.companyPipelineData);
                lockedArray = lockedArray.flat();
              }
              this.companyPipelineData = lockedArray;
            }
          }

          if (ParseDecryptedData.data.length > 4) {
            return (this.customclassfordrug =
              'table-responsive scrollable-table');
          } else {
            return (this.customclassfordrug =
              'table-responsive fitcontenttable');
          }
        },
      });
  }

  getInsidetradesDetails() {
    this.compamyDetailsService
      .getCompanyInsidetradesData(this.companySymbol)
      .subscribe({
        next: (res) => {
          const decryptData = this.decryptionService.decrypt(res.result);
          const ParseDecryptedData = JSON.parse(decryptData);
          // const decryptData = this.decryptionService.decrypt(res);

          // const ParseDecryptedData = JSON.parse(decryptData);
          if (ParseDecryptedData.data.length === 0) {
            this.istradesShow = false;
            // this.customclassfordrugsize = "col-lg-11";
          } else {
            this.istradesShow = true;
            this.companytrades = ParseDecryptedData.data;
            this.productId = ParseDecryptedData?.productId;

            if (!ParseDecryptedData?.productId || this.showloginWarning) {
              let lockedArray: any[] = [];

              for (let i = 0; i < 4; i++) {
                lockedArray.push(this.companytrades);
                lockedArray = lockedArray.flat();
              }

              this.companytrades = lockedArray;
              this.companytrades.forEach((element) => {
                // element.change = Number(element.change.toFixed(2));
                // element.traded_value = this.dolloarconv.transform(element.traded_value);
                // element.change = Number(this.dolloarconv.transform(element.traded_value));
              });
              this.companytrades[0].change = this.dolloarconv.transform(
                this.companytrades[0].change
              );
              this.companytrades[0].traded_value = this.dolloarconv.transform(
                this.companytrades[0].traded_value
              );
              this.companytrades[0].share = this.dolloarconv.transform(
                this.companytrades[0].share
              );
            }
          }

          if (ParseDecryptedData.data.length > 6) {
            return (this.customclassfortrades =
              'table-responsive scrollable-table');
          } else {
            return (this.customclassfortrades =
              'table-responsive fitcontenttable');
          }
        },
      });
  }

  getcatalystresult() {
    this.compamyDetailsService.getcatalsytresult(this.companySymbol).subscribe({
      next: (res) => {
        const decryptData = this.decryptionService.decrypt(res.result);
        const ParseDecryptedData = JSON.parse(decryptData);

        if (ParseDecryptedData.data.length === 0) {
          this.catalystresult = false;
          // this.customclassfordrugsize = "col-lg-11";
        } else {
          this.catalystresult = true;
          this.catalystresultData = ParseDecryptedData.data;
          this.catalystresultData.forEach((element) => {
            element.clinical_trial_results_summary.length === 0
              ? (this.catalystresult = false)
              : (this.catalystresult = true);
          });

          this.productId = ParseDecryptedData?.productId;

          if (!ParseDecryptedData?.productId || this.showloginWarning) {
            let lockedArray: any[] = [];

            for (let i = 0; i < 4; i++) {
              lockedArray.push(this.catalystresultData);
              lockedArray = lockedArray.flat();
            }

            this.catalystresultData = lockedArray;
          }
        }

        if (ParseDecryptedData.length > 6) {
          return (this.customclassforcatalystresult =
            'table-responsive scrollable-table');
        } else {
          return (this.customclassforcatalystresult =
            'table-responsive fitcontenttable');
        }
      },
    });
  }

  getupcomingcataDetails() {
    this.compamyDetailsService
      .getcompanyupcomingdata(this.companySymbol)
      .subscribe({
        next: (res) => {
          const decryptData = this.decryptionService.decrypt(res.result);

          const ParseDecryptedData = JSON.parse(decryptData);
          if (ParseDecryptedData.data.length === 0) {
            this.upcomingdatashow = false;
            // this.customclassforoptiondata = "col-lg-11";
          } else {
            this.upcomingdatashow = true;
            this.upcomingdata = ParseDecryptedData.data;
            this.productId = ParseDecryptedData?.productId;
            if (!ParseDecryptedData?.productId || this.showloginWarning) {
              let lockedArray: any[] = [];

              for (let i = 0; i < 4; i++) {
                lockedArray.push(this.upcomingdata);
                lockedArray = lockedArray.flat();
              }
              this.upcomingdata = lockedArray;
              this.upcomingdata.forEach((element) => {
                element.change = Number(element.change.toFixed(2));
                element.close = Number(element.close.toFixed(2));
                element.change_abs = Number(element.change_abs.toFixed(2));
              });
            }
          }

          // if (this.istradesShow == false) {
          //   return this.customclassfordrugsize = "col-lg-10";
          // }
          if (ParseDecryptedData.data.length > 6) {
            return (this.customclassforupcomingcyt =
              'table-responsive scrollable-table');
          } else {
            return (this.customclassforupcomingcyt =
              'table-responsive fitcontenttable');
          }
        },
      });
  }
  gethedgeFundDetails() {
    this.compamyDetailsService.gethedgedata(this.companySymbol).subscribe({
      next: (res) => {
        const decryptData = this.decryptionService.decrypt(res.result);

        const ParseDecryptedData = JSON.parse(decryptData);

        if (ParseDecryptedData.data.length === 0) {
          this.hedgedataShow = false;
        } else {
          this.hedgedataShow = true;
          this.hedgedata = ParseDecryptedData.data;
          if (!ParseDecryptedData?.productId || this.showloginWarning) {
            let lockedArray: any[] = [];

            for (let i = 0; i < 4; i++) {
              lockedArray.push(this.hedgedata);
              lockedArray = lockedArray.flat();
            }
            this.hedgedata = lockedArray;

            this.hedgedata[0].current_mv = this.dolloarconv.transform(
              this.hedgedata[0].current_mv
            );
          }
        }

        if (ParseDecryptedData.data.length > 7) {
          return (this.customclassforhedge =
            'table-responsive scrollable-table');
        } else {
          return (this.customclassforhedge =
            'table-responsive fitcontenttable');
        }
      },
    });
  }

  getoverviewData() {
    this.compamyDetailsService.getoptiondata(this.companySymbol).subscribe({
      next: (res) => {
        const decryptData = this.decryptionService.decrypt(res.result);

        const ParseDecryptedData = JSON.parse(decryptData);

        if (ParseDecryptedData.data.length == 0) {
          this.optiondataShow = false;
        } else {
          this.optiondataShow = true;
          ParseDecryptedData.data.forEach((data: any) => {
            const callsTotal = data.totalCalls;
            const putTotal = data.totalPuts;
            for (const a of callsTotal) {
              const id = a._id.expiration_date;
              const fiter = putTotal.find(
                (x: any) => x._id.expiration_date === id
              );
              this.optionalldata.push({
                ...a,
                putsInterest: fiter?.totalopenInterest,
                putsvolume: fiter?.totalVolume,
                date: a._id.expiration_date,
                volumeratio: fiter?.totalVolume / a.totalVolume,
                openInterestRatio:
                  fiter?.totalopenInterest / a.totalopenInterest,
              });
            }
          });

          this.optionSumData(this.optionalldata);
          this.optionsivget(this.optionalldata);
        }

        if (this.optionalldata.length > 6) {
          return (this.customclassforoption =
            'table-responsive scrollable-table');
        } else {
          return (this.customclassforoption =
            'table-responsive fitcontenttable');
        }
      },
    });
  }

  optionSumData(data: any) {
    data.forEach((call: any) => {
      this.callTotal = this.sumofoption(call.totalVolume, this.value);

      this.putTotal = this.sumofoption(call.putsvolume, this.putvalue);

      this.ratio = this.sumofoption(call.volumeratio, this.putcallratio);

      this.opencallTotal = this.sumofoption(
        call.totalopenInterest,
        this.openvalue
      );

      this.openputTotal = this.sumofoption(
        call.putsInterest,
        this.openputvalue
      );

      this.openratio = this.sumofoption(
        call.openInterestRatio,
        this.openputcallratio
      );
    });
  }

  sumofoption(option: any, container: any) {
    if (
      option == null ||
      option == undefined ||
      isNaN(option) ||
      option == '∞' ||
      option == Infinity
    ) {
      container.push(0);
    } else {
      container.push(option);
    }

    return container.reduce((acc: any, cur: any) => acc + cur, 0);
  }

  async getoptionSIVData() {
    try {
      const res = await this.compamyDetailsService
        .getoptionsummaryIvdata(this.companySymbol)
        .toPromise();
      const decryptData = this.decryptionService.decrypt(res.result);

      const ParseDecryptedData = JSON.parse(decryptData);
      if (ParseDecryptedData.data.length === 0) {
        this.optiondataShow = false;
        this.customclassforoptiondata = 'col-lg-11';
      } else {
        ParseDecryptedData.data.forEach((data: any) => {
          let optionsummery = data.results;
          optionsummery.forEach((data: any) => {
            this.optionIv.push(data);
          });
        });
      }
    } catch (error) {
      console.error('Error fetching option SIV data:', error);
    }
  }

  optionsivget(data: any) {
    // let optionIvdata = this.optionIv;
    for (const a of data) {
      const id = a.date;
      const alldata = this.optionIv.find((x: any) => x.expiration_date === id);
      this.optionTwotable.push({
        ...a,
        puts_vwaiv: alldata?.puts_vwaiv,
        calls_oiwaiv: alldata?.calls_oiwaiv,
        calls_vwaiv: alldata?.calls_vwaiv,
        puts_oiwaiv: alldata?.puts_oiwaiv,
      });
      this.optionTwotable.sort(
        (a: any, b: any) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      );
    }
  }

  removeDuplicatesByTitle(arr: any) {
    const uniqueTitles = new Set();
    return arr.filter((obj: any) => {
      const title = obj.title;
      return uniqueTitles.has(title) ? false : uniqueTitles.add(title);
    });
  }

  getNewesDetails() {
    this.compamyDetailsService
      .getCompanyNewsData(this.companySymbol)
      .subscribe({
        next: (res) => {
          const decryptData = this.decryptionService.decrypt(res.result);

          const ParseDecryptedData = JSON.parse(decryptData);
          if (ParseDecryptedData.data.length == 0) {
            this.showNewdata = false;
          } else {
            this.showNewdata = true;

            this.companynews = this.removeDuplicatesByTitle(
              ParseDecryptedData.data
            );
            this.companynews.sort(function (a: any, b: any) {
              return (
                new Date(b.published).getTime() -
                new Date(a.published).getTime()
              );
            });
          }
        },
      });
  }

  toggleDescription() {
    this.showFullDescription = !this.showFullDescription;
  }

  openpopup(data: any) {
    this.newsItem = data;
    this.displayStyle = 'block';
    document.body.style.overflow = 'hidden';
  }

  closePopup() {
    this.displayStyle = 'none';
    document.body.style.overflow = 'auto';
  }

  loadTradingViewWidget() {
    new TradingView.widget({
      autosize: true,
      symbol: ` ${this.companySymbol}`,
      interval: 'D',
      timezone: 'Etc/UTC',
      theme: 'light',
      style: '1',
      locale: 'in',
      toolbar_bg: '#f1f3f6',
      enable_publishing: false,
      allow_symbol_change: true,
      container_id: 'tradingview_68936',
    });
  }
  GetChildData(data: any) {
    this.modalDisplayStyle = data;
  }

  onAddSymbol(data: any) {
    this.portfoliomatchTicker.push(data.ticker);
    return;
  }

  removeSymboltoPotfoliyo(symbol: any) {
    let loginuser = JSON.parse(localStorage.getItem('loggedInUser') || '{}');

    let obj = {
      email: loginuser.email,
      ticker: symbol,
    };

    if (obj) {
      this.fdacalendarservice.removeSymboltoPotfoliyo(obj).subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res);
          this.portfoliomatchTicker = [];
          const ParseDecryptedData = JSON.parse(decryptData);
          ParseDecryptedData.portfolio.forEach((element: any) => {
            this.portfoliomatchTicker.push(element.ticker);
          });

          this.toaster.success('Symbol removed from your portfolio');
        },
      });
    }
  }

  indiDetails(fundname: any) {
    // this.hedgeService.indivisualData(fundname, 1);
    let name = fundname.replace(/ /g, '_');

    this.router.navigate(['individualfund', name]);
  }
}
