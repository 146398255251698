import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpHandler,
} from '@angular/common/http';
import { LoginService } from 'src/app/modules/core/services/login.service';

// local
/////////////////////////////////////////////////////////////////////////////////////////////

// https://api.biopharmawatch.com/api/auth/data/gainers
const baseUrl = 'https://biopharma-node-6o2b.onrender.com';

export const environment = {
  production: false,
  ApiURL: `${baseUrl}/api/auth`,
  adminUrl: `${baseUrl}/api/admin`,
  ApiURL_Without_Auth: `${baseUrl}/api/data`,
  sitekeyurl: '6Ldpk1snAAAAADPBh50HhUXoEoTEAV-92oPPDeuI',
  socketUrl: `${baseUrl}`,
  secretKey: 'biopharmawatch-secret-key',
  clientUrl: 'http://localhost:4200',
};

export const googleConfig = {
  exchangeCodeForIdToken(
    code: string,
    http: HttpClient,
    loginserv: LoginService
  ): void {
    const tokenEndpoint = 'https://oauth2.googleapis.com/token';

    const body = new HttpParams()
      .set('code', code)
      .set(
        'client_id',
        '947292781403-4s03u0m47ctcatkmf7f7jvvps5c5g25j.apps.googleusercontent.com'
      )
      .set('client_secret', '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')
      .set('redirect_uri', 'http://localhost:4200/login')
      .set('grant_type', 'authorization_code');

    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );

    http.post<any>(tokenEndpoint, body.toString(), { headers }).subscribe(
      (response) => {
        const idToken = response.id_token;
        loginserv.googlePostLogin(idToken).subscribe((res) => {});
      },
      (error) => {
        console.error('Token exchange error', error);
      }
    );
  },

  googleClick() {
    const googleOAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';

    const params = new URLSearchParams({
      client_id:
        '947292781403-4s03u0m47ctcatkmf7f7jvvps5c5g25j.apps.googleusercontent.com',
      redirect_uri: 'http://localhost:4200/login',
      response_type: 'code',
      scope: 'openid profile email',
    });

    window.location.href = `${googleOAuthUrl}?${params.toString()}`;
  },
};
