import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IPOtable } from 'src/app/modules/shared/models/iposec';
import { CompanyDetailsService } from 'src/app/modules/shared/service/company-details.service';
import { DecryptionService } from 'src/app/modules/shared/service/decryption.service';
import { IpoServiceService } from 'src/app/modules/shared/service/ipo-service.service';

@Component({
  selector: 'app-ipo-component',
  templateUrl: './ipo-component.component.html',
  styleUrl: './ipo-component.component.scss',
})
export class IpoComponentComponent {
  ipoDataupcoming: IPOtable[] = [];
  ipoDatapast: IPOtable[] = [];

  constructor(
    private ipoService: IpoServiceService,
    private decryptionService: DecryptionService,
    private compamyDetails: CompanyDetailsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.ipoUpcomingData();
    this.ipoPastData();
  }

  ipoUpcomingData() {
    this.ipoService.getIpoData().subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);

        const ParseDecryptedData = JSON.parse(decryptData);
        this.ipoDataupcoming = ParseDecryptedData;
        // this.ipoDataupcoming = data;
      },
      error: (error) => {
        console.log('error :', error);
      },
    });
  }

  ipoPastData() {
    this.ipoService.getPastipoData().subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);

        const ParseDecryptedData = JSON.parse(decryptData);
        this.ipoDatapast = ParseDecryptedData;
        // this.ipoDataupcoming = data;
      },
      error: (error) => {
        console.log('error :', error);
      },
    });
  }

  companyDetails(ticker: any) {
    this.compamyDetails.getCompanyTable(ticker).subscribe({
      next: (res: any) => {
        this.router.navigate(['company', ticker]);
      },
    });
  }
}
