import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'infinityTable'
})
export class InfinityTablePipe implements PipeTransform {

  transform(value: any): any {
    if ( value == "∞" || value == null ) {
      return 0;
    }
    return `${value}`;
  }

}
