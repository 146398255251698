import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BioHedgeFundShotsService } from 'src/app/modules/shared/service/bio-hedge-fund-shots.service';
import { CompanyDetailsService } from 'src/app/modules/shared/service/company-details.service';
import { DecryptionService } from 'src/app/modules/shared/service/decryption.service';

@Component({
  selector: 'app-bio-hedge-fund-shots',
  templateUrl: './bio-hedge-fund-shots.component.html',
  styleUrl: './bio-hedge-fund-shots.component.scss',
})
export class BioHedgeFundShotsComponent {
  constructor(
    private bioHedgeFundShotsService: BioHedgeFundShotsService,
    private decryptionService: DecryptionService,
    private compamyDetails: CompanyDetailsService,
    private router: Router
  ) {}

  bioHedgeFundShots: any;
  oncologyData: any;
  isSubscribed: boolean = true;
  fakeSymbols = [
    'ABCD',
    'XYZW',
    'ABCD',
    'XYZW',
    'ABCD',
    'XYZW',
    'ABCD',
    'XYZW',
    'ABCD',
  ];
  fakeSymbolsOncology = [
    'ABCD',
    'XYZW',
    'ABCD',
    'XYZW',
    'ABCD',
    'XYZW',
    'ABCD',
  ];
  HedgefundFamousData: any;

  ngOnInit() {
    this.GetbioHedgeFundShots();
    this.getOncologyShots();
    this.getHedgefundFamous();
  }

  GetbioHedgeFundShots() {
    this.bioHedgeFundShotsService.bioHedgeFundShots().subscribe((res: any) => {
      const decryptData = this.decryptionService.decrypt(res.result);

      const ParseDecryptedData = JSON.parse(decryptData);
      this.bioHedgeFundShots = ParseDecryptedData;

      if (!this.bioHedgeFundShots[0].isSubscribed) {
        this.isSubscribed = this.bioHedgeFundShots[0]?.isSubscribed;
      }
    });
  }

  getOncologyShots() {
    this.bioHedgeFundShotsService.apiOncologyShots().subscribe((res: any) => {
      const decryptData = this.decryptionService.decrypt(res.result);

      const ParseDecryptedData = JSON.parse(decryptData);
      this.oncologyData = ParseDecryptedData;
    });
  }

  companyDetails(ticker: any) {
    this.compamyDetails.getCompanyTable(ticker).subscribe({
      next: (res: any) => {
        this.router.navigate(['company', ticker]);
      },
    });
    this.compamyDetails.getCompanyTicker(ticker);
  }

  getHedgefundFamous() {
    this.bioHedgeFundShotsService.apiHedgefundFamous().subscribe((res: any) => {
      const decryptData = this.decryptionService.decrypt(res.result);
      const ParseDecryptedData = JSON.parse(decryptData);
      this.HedgefundFamousData = ParseDecryptedData;
    });
  }
}
