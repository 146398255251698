import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HistoricalPdufa, PdufaCalendar, PdufacurrentCalendar } from '../const';
@Injectable({
  providedIn: 'root',
})
export class PdufaCalendarService {
  constructor(private http: HttpClient) {}

  bioclender(requestBody: any) {
    return this.http.post(`${PdufaCalendar}`, requestBody);
  }
  pdufaclender() {
    return this.http.get(`${PdufacurrentCalendar}`);
  }

  historicalPdufa(page: number) {
    return this.http.post<any[]>(`${HistoricalPdufa}${page}`, '');
  }
}
