import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { googleConfig } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translateY(15px)', // At its original position
          opacity: 1,
          filter: 'blur(0px)', // No blur when fully visible
        })
      ),
      state(
        'out',
        style({
          transform: 'translateY(0px)', // 30px lower (off-screen)
          opacity: 0, // Transparent
          filter: 'blur(8px)', // Apply blur when sliding out
        })
      ),
      transition('out => in', [
        animate('1.8s cubic-bezier(0.34, 1.56, 0.64, 1)'),
      ]),
      transition('in => out', [
        animate('1.5s ease-out'), // Slow down ease-out slightly
      ]),
    ]),
  ],
})
export class LoginComponent {
  displayStyle = 'none';
  protected loginform!: FormGroup;
  resetEmail!: FormGroup;
  emailControl = new FormControl('', Validators.required);
  formsuccess!: boolean;
  formfailure!: boolean;
  showPassword: boolean = false;
  successMsg = '';
  errorMsg = '';
  submitted: boolean = false;
  siteKey: string = environment.sitekeyurl;
  isLoading = false;
  testimonials: {
    content: string;
    name: string;
    position: string;
    image: string;
    country?: string;
    address?: string;
  }[] = [
    {
      content:
        'Thanks to the searchable FDA calendar and PDUFA catalysts, I’ve been able to plan ahead and consistently capitalize on market-moving events. This service has played a crucial role in helping me generate steady profits!',
      name: 'John D',
      position: 'Individual Investor',
      image: '/assets/images/young-bearded-man-with-striped-shirt.jpg',
      country: 'https://png.pngtree.com/png-vector/20230905/ourmid/pngtree-us-flag-sign-png-image_9947494.png',
      address: 'San Francisco, CA, USA',
    },
    {
      content:
        'The portfolio tracking tools have made managing my investments so much smoother. I can track catalysts and drug pipelines, and it’s helped me make better decisions that have really paid off. Definitely a great resource for anyone in biotech.',
      name: 'Samantha R',
      position: 'Portfolio Manager',
      image: '/assets/images/women.jpg',
      country: 'https://upload.wikimedia.org/wikipedia/commons/3/3d/Flag_of_germany_800_480.png',
      address: 'Berlin, Germany',
    },
    {
      content:
        'Tracking biotech earnings and catalyst events is now easier than ever. I can quickly assess how different companies are performing and adjust my portfolio accordingly.',
      name: 'Emily K',
      position: 'Individual Investor',
      image:
        '/assets/images/girl-with-gray-sweater-blue-jeans-is-posing-photo.jpg',
      country: 'https://png.pngtree.com/png-vector/20230905/ourmid/pngtree-us-flag-sign-png-image_9947494.png',
      address: 'Chicago, IL, USA',
    },
    {
      content:
        'As someone new to biotech investing, the tools have been a lifesaver. I’ve been able to make some solid gains just by following the market updates and tracking my portfolio closely. Highly recommend it!',
      name: 'Linda P',
      position: 'New Biotech Investor',
      image:
        'assets/images/portrait-charming-businesswoman-sitting-her-workplace-writing.jpg',
      country: 'https://img.freepik.com/free-vector/illustration-uk-flag_53876-18166.jpg',
      address: 'London, UK',
    },
    {
      content:
        'Being able to monitor insider trading and hedge fund holdings has provided invaluable insights. This feature alone has helped me spot opportunities and avoid potential pitfalls.',
      name: 'Carlos M',
      position: 'API User',
      image: 'assets/images/bohemian-man-with-his-arms-crossed.jpg',
      country: 'https://png.pngtree.com/png-vector/20230905/ourmid/pngtree-us-flag-sign-png-image_9947494.png',
      address: 'Boston, MA, USA',
    },
    {
      content:
        'The extensive research on over 1,100 companies and clinical trials is simply impressive. It’s helped me shape more informed, strategic plans for both investments and R&D purposes.',
      name: 'Michael L',
      position: 'Pharma Researcher',
      image:
        'https://media.istockphoto.com/id/1170953707/photo/smiling-black-man.jpg?s=612x612&w=0&k=20&c=lKqsRoMExYAnVtIw9fadM84rOPBhI_LVLCuRaBvstvo=',
      country: 'https://img.freepik.com/free-vector/illustration-canada-flag_53876-27114.jpg',
      address: 'Toronto, Canada',
    },
  ];
  currentIndex = 0;
  currentState = 'in'; // This will track the current animation state
  displayedTestimonial = this.testimonials[0];

  constructor(
    private fb: FormBuilder,
    private loginData: LoginService,
    private router: Router,
    private toaster: ToastrService,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {}

  ngOnInit() {
    setInterval(() => {
      this.changeTestimonial();
    }, 8000); // Change every 3 seconds
    const code = this.route.snapshot.queryParams['code'];
    if (code) {
      // this.exchangeCodeForIdToken(code);
      googleConfig.exchangeCodeForIdToken(code, this.http, this.loginData);
    }

    localStorage.removeItem('loggedInUser');
    localStorage.removeItem('UserId');
    localStorage.removeItem('profileData');
    localStorage.removeItem('profileId');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('lastName');
    localStorage.removeItem('firstName');
    localStorage.removeItem('password');
    localStorage.removeItem('bloguserDetails');
    this.loginformdata();
  }

  signInWithGoogle(): void {
    googleConfig.googleClick();
  }

  changeTestimonial() {
    this.currentState = 'out';

    setTimeout(() => {
      this.currentIndex = (this.currentIndex + 1) % this.testimonials.length;
      this.displayedTestimonial = this.testimonials[this.currentIndex];
      this.currentState = 'in';
    }, 400); // Match this delay with the 'in => out' transition duration
  }

  get f() {
    return this.loginform.controls;
  }

  loginformdata() {
    this.loginform = this.fb.group({
      email: this.emailControl,
      password: ['', Validators.required],
      // checkbox: [false, Validators.requiredTrue],
      // recaptcha: ['', Validators.required]
    });

    this.resetEmail = this.fb.group({
      forgetemail: ['', Validators.required],
    });
  }
  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  login(data: any) {
    this.submitted = true;

    if (this.loginform.valid) {
      this.isLoading = true;
      this.loginData.postlogin(data).subscribe({
        next: (response) => {
          this.isLoading = false;

          this.loginData.login();
          this.loginData.setFormData(response);
          this.formsuccess = true;

          this.loginform.reset();
          let snapsot = localStorage.getItem('route');

          if (snapsot !== '/login') {
            this.router.navigate([snapsot]);
          } else {
            this.router.navigate(['home']);
          }
        },
        error: (error) => {
          this.formfailure = true;
          this.isLoading = false;
          this.errorMsg = error;
          this.toaster.error(error);
        },
      });
    }
  }

  savePopup() {
    let email = this.resetEmail.value;
    email['email'] = email['forgetemail'];
    delete email['forgetemail'];

    if (email.email === null) {
      this.toaster.warning('Email is required !!');
      return;
    }

    this.loginData.forgetpassword(email).subscribe({
      next: (response) => {
        this.toaster.success(response.message);
      },
      error: (error) => {
        this.toaster.error(error);
      },
    });
    this.closePopup();
  }

  forgetpass() {
    this.displayStyle = 'block';
    this.resetEmail.reset();
  }

  closePopup() {
    this.displayStyle = 'none';
    this.resetEmail.reset();
  }
}
