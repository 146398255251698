<!-- <section>
    <div class="mt-3 mb-3">
        <h3 class="main-heading text-center">Data Protection Policy</h3>
    </div>
</section>

<section class="mb-3 section-height">
    <div class="container">
        <p class="sub-heading text-center">
            In the digital era, the confidentiality of personal details and the
            security of
            information are crucial. At BiopharmaWatch, we understand the reliance you place in us by using our
            platform, and we consider the protection of your privacy as one of our highest priorities. Our Data
            Protection Policy demonstrates our commitment to this principle, outlining in intricate detail the
            comprehensive measures we undertake to handle your information responsibly and ethically. Your data, your
            interactions, and your involvement with our platform are protected by a network of protocols and practices
            that prioritize your privacy. Our Data Protection Policy covers various aspects of data gathering,
            utilization, and security, ensuring that you have a clear understanding of how your information is managed.
            We are open about our methods, openly sharing the ways in which we utilize your data to enhance your
            experience on BiopharmaWatch. We are dedicated to equipping you with the knowledge you need to make informed
            choices, and this extends to how your data is managed within our system. By reading and internalizing our
            Data Protection Policy, you gain insight into the intricacies of our data management practices. You will
            learn about the mechanisms we have put in place to safeguard your information and ensure that your journey
            with BiopharmaWatch is not only educational but also secure. At BiopharmaWatch, we are not solely focused on
            providing insights for your investment decisions; we are also committed to earning your trust, protecting
            your privacy, and empowering you. Engage with confidence on our platform, knowing that your information is
            in capable hands.



        </p>
        <p class="sub-heading text-center">
            A Commitment to Excellence At BiopharmaWatch, our path is intertwined with yours. We're not just a platform;
            we're your companion as you navigate the intricate pathways of biotech and pharma investments. Our
            dedication to surpassing expectations encompasses every aspect of your interaction with our platform. From
            personalized assistance that bridges the divide between your inquiries and responses to our thorough Terms
            of Service that establish the groundwork for an enriched user community, to our Privacy Policy that
            safeguards your data as you explore—every element is a testament to our unwavering commitment to your
            experience. Your journey on BiopharmaWatch is a voyage of empowerment, knowledge, and well-informed choices.
            As you delve into the insights, tools, and resources we provide, keep in mind that we're by your side,
            prepared to guide and support you every step of the way. Your triumphs are our triumphs, and the decisions
            you make regarding investments are what propel us forward. So explore, engage, and embrace the opportunities
            that the world of biotech and pharma investments presents. As you do so, take solace in the fact that you're
            not alone on this journey. You're part of a vibrant community that shares your fervor, inquisitiveness, and
            determination. As you make informed investment choices, remember that BiopharmaWatch is more than a
            platform—it's a partner dedicated to your success.
        </p>
    </div>
</section> -->

<section class="mb-3 section-height">
  <div class="container">
    <div class="mt-3 mb-3">
      <h1 class="main-heading text-center">Company Information</h1>
    </div>
    <p class="sub-heading text-center">
      BiopharmaWatch is a specialized service provider focused on offering
      insights and analysis in the biotech and pharma investment sector. We
      combine expertise in scientific research, investment analysis, and
      artificial intelligence to deliver in-depth information on over 1,000
      companies, covering more than 85 key metrics in the industry.
    </p>
  </div>
  <div class="container">
    <div class="mt-3 mb-3">
      <h3 class="main-heading text-center">Purpose of Service</h3>
    </div>
    <p class="sub-heading text-center">
      The information provided by BiopharmaWatch is intended for informational
      and educational purposes only. It is designed to assist investors in
      making more informed decisions in the biotech and pharma markets. Our
      services include, but are not limited to, FDA and PDUFA calendars,
      CatalystSync™ updates, company screening tools, insights into insider
      trades, biotech earnings calendars, and analysis of hedge fund trends.
    </p>
  </div>

  <div class="container">
    <div class="mt-3 mb-3">
      <h3 class="main-heading text-center">No Investment Advice</h3>
    </div>
    <p class="sub-heading text-center">
      BiopharmaWatch does not offer investment advice, endorse or recommend any
      securities or investments. The information provided should not be
      construed as a solicitation or offer to buy or sell any securities or as
      investment advice. The use of any information provided by BiopharmaWatch
      is at the user's own risk.
    </p>
  </div>
  <div class="container">
    <div class="mt-3 mb-3">
      <h3 class="main-heading text-center">Purpose of Service</h3>
    </div>
    <p class="sub-heading text-center">
      The information provided by BiopharmaWatch is intended for informational
      and educational purposes only. It is designed to assist investors in
      making more informed decisions in the biotech and pharma markets. Our
      services include, but are not limited to, FDA and PDUFA calendars,
      CatalystSync™ updates, company screening tools, insights into insider
      trades, biotech earnings calendars, and analysis of hedge fund trends.
    </p>
  </div>
  <div class="container">
    <div class="mt-3 mb-3">
      <h3 class="main-heading text-center">
        Accuracy and Reliability of Information
      </h3>
    </div>
    <p class="sub-heading text-center">
      While we strive to provide accurate and up-to-date information,
      BiopharmaWatch does not guarantee the accuracy, completeness, or
      timeliness of the information provided. The biotech and pharma markets are
      subject to rapid changes, and information may become outdated or
      superseded by new developments.
    </p>
  </div>
  <div class="container">
    <div class="mt-3 mb-3">
      <h3 class="main-heading text-center">User Responsibility</h3>
    </div>
    <p class="sub-heading text-center">
      Users are responsible for conducting their own research and due diligence.
      Investment decisions should be based on an individual's own objectives,
      financial situation, and risk tolerance. BiopharmaWatch is not responsible
      for any loss or damage resulting from reliance on the information or
      services provided.
    </p>
  </div>
  <div class="container">
    <div class="mt-3 mb-3">
      <h3 class="main-heading text-center">Intellectual Property Rights</h3>
    </div>
    <p class="sub-heading text-center">
      All content provided by BiopharmaWatch, including text, graphics, logos,
      and software, is the property of BiopharmaWatch or its content suppliers
      and is protected by international copyright and intellectual property
      laws.
    </p>
  </div>
  <div class="container">
    <div class="mt-3 mb-3">
      <h3 class="main-heading text-center">Contact and Queries</h3>
    </div>
    <p class="sub-heading text-center">
      All content provided by BiopharmaWatch, including text, graphics, logos,
      and software, is the property of BiopharmaWatch or its content suppliers
      and is protected by international copyright and intellectual property
      laws.
    </p>
  </div>
  <div class="container">
    <div class="mt-3 mb-3">
      <h3 class="main-heading text-center">Acknowledgment</h3>
    </div>
    <p class="sub-heading text-center">
      By using the services of BiopharmaWatch, users acknowledge and agree to
      the terms outlined in this legal disclaimer.
    </p>
  </div>
</section>
