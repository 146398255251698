import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-website-user',
  templateUrl: './website-user.component.html',
  styleUrls: ['./website-user.component.scss'],
})
export class WebsiteUserComponent implements OnInit {
  @ViewChild('mainPanel') mainPanel!: ElementRef;


  ngOnInit() {}
  
  scrollToSection(sectionId: string, event: MouseEvent): void {
    event.stopPropagation(); // Prevent event bubbling

    // Get the section element to scroll to
    const section = document.getElementById(sectionId);

    if (section) {
      // Calculate the offset to scroll the main panel
      const mainPanel = this.mainPanel.nativeElement;
      const sectionOffset = section.getBoundingClientRect().top + mainPanel.scrollTop - mainPanel.getBoundingClientRect().top;

      // Smooth scroll to the calculated position
      mainPanel.scrollTo({
        top: sectionOffset,
        behavior: 'smooth'
      });
    } else {
      console.error('Section not found:', sectionId);
    }
  }

}
