import { environment } from 'src/environments/environment';

//Sign-up
export const VerifyEmail = `${environment.ApiURL}/verifyEmail`;
export const VerifyOtp = `${environment.ApiURL}/verifyOtp`;
export const postSignup = `${environment.ApiURL}/signup`;

//Log-in

export const postlogin = `${environment.ApiURL}/signin`;
export const googlePostLogin = `${environment.ApiURL}/google-login`;
export const forgetpassword = `${environment.ApiURL}/forgot-password`;

//footerservie

export const footerEmail = `${environment.ApiURL}/data/subscription`;

//Forgetpasswordto i thinjkto

export const newForgetpassword = `${environment.ApiURL}/new_password/`;

//userProfile

export const getProfile = `${environment.ApiURL}/profile/`;
export const postProfile = `${environment.ApiURL}/profileEdit/`;
export const resetpassword = `${environment.ApiURL}/reset-password/`;
export const cancelplan = `${environment.ApiURL}/cancel-subscription`;
export const cancelplanotp = `${environment.ApiURL}/cancel-subscription-token`;

//bioCelender

export const bioCelender = `${environment.ApiURL_Without_Auth}/earning-calendar`;
export const bioCelenderCurrent = `${environment.ApiURL_Without_Auth}/earning-calendar-current`;
export const bioCelenderSearch = `${environment.ApiURL_Without_Auth}/earning-calendar?search=1`;

//pdufa calendar

export const PdufaCalendar = `${environment.ApiURL_Without_Auth}/pdufa-calendar`;
export const PdufacurrentCalendar = `${environment.ApiURL_Without_Auth}/pdufa-calendar-current`;
export const pudaCalenderSearch = `${environment.ApiURL_Without_Auth}/pdufa-calendar?search=1`;

// conference-calendar

export const conferencecalendar = `${environment.ApiURL_Without_Auth}/conference-calendar`;

//Blog-admin

export const getblogTable = `${environment.ApiURL_Without_Auth}/blogs/`;
export const saveblog = `${environment.adminUrl}/blogs/save`;

//Blog-servies

export const getblogdata = `${environment.ApiURL_Without_Auth}/blogs`;

//catalystsync

export const catalystSyncdata = `${environment.ApiURL_Without_Auth}/catalyst-sync?page=`;
export const catalystSyncdataTen = `${environment.ApiURL_Without_Auth}/catalyst-sync-ten`;

//company-detail

export const getCompanyTabledata = `${environment.ApiURL_Without_Auth}/stock-data/`;
export const getCompanyTablePipelinedata = `${environment.ApiURL_Without_Auth}/stock-data-pipeline/`;
export const getCompanyInsidetradesData = `${environment.ApiURL_Without_Auth}/stock-data-trades/`;
export const getcatalystresult = `${environment.ApiURL_Without_Auth}/catalyst-results/`;
export const getoptiondata = `${environment.ApiURL_Without_Auth}/option-summary/`;
export const getoptionIvdata = `${environment.ApiURL_Without_Auth}/option-summary-iv/`;
export const getcompanyupcomingdata = `${environment.ApiURL_Without_Auth}/stock-data-catalyst/`;
export const gethedgedata = `${environment.ApiURL_Without_Auth}/stock-data-hedgefund/`;
export const getCompanynewsData = `${environment.ApiURL_Without_Auth}/stock-data-news/`;

// comapnyscreener

export const getCSTableData = `${environment.ApiURL_Without_Auth}/company-screener?page=`;
export const getCSTenTableData = `${environment.ApiURL_Without_Auth}/company-screener-ten`;

//fadtabel

export const getFdaData = `${environment.ApiURL_Without_Auth}/fda-calendar?page=`;
export const getTenFdaData = `${environment.ApiURL_Without_Auth}/fda-calendar`;
export const addSymbol = `${environment.ApiURL}/wishlist/add`;
export const removeSymbol = `${environment.ApiURL}/wishlist/remove`;

//ittable

export const getInsideTabledata = `${environment.ApiURL_Without_Auth}/inside-trades?page=`;
export const getteninsideTabledata = `${environment.ApiURL_Without_Auth}/inside-trades-ten`;

//admin-fda
export const getadminfda = `${environment.adminUrl}/fda-calendar/read`;
export const editadminfda = `${environment.adminUrl}/fda-calendar/edit`;
export const removeadminfda = `${environment.adminUrl}/fda-calendar/remove`;
export const addadminfda = `${environment.adminUrl}/fda-calendar/add`;
export const searchsuggectionadmin = `${environment.adminUrl}/fda-calendar/search?suggestion=`;
export const searchadmin = `${environment.adminUrl}/fda-calendar/search`;

//Filters

export const fdafilter = `${environment.ApiURL_Without_Auth}/fda-calendar?search=1`;
export const bioteachfilterdata = `${environment.ApiURL_Without_Auth}/earning-calendar?search=1`;
export const compnayfilter = `${environment.ApiURL_Without_Auth}/company-screener?search=1`;
export const itfilter = `${environment.ApiURL_Without_Auth}/inside-trades?search=1`;
export const fdafilterColoum = `${environment.ApiURL_Without_Auth}/fda-calendar?column=1&page=`;
export const syncfilterColoum = `${environment.ApiURL_Without_Auth}/catalyst-sync?column=1&page=`;
export const allstocksfilterColoum = `${environment.ApiURL_Without_Auth}/hedge-fund-stock?column=1&page=`;
export const allstockssortdata = `${environment.ApiURL_Without_Auth}/hedge-fund-stock?sort=1&`;
export const insidetradesort = `${environment.ApiURL_Without_Auth}/inside-trades?sort=1&`;
export const hedgefundsortdata = `${environment.ApiURL_Without_Auth}/hedge-fund-screener?sort=1&`;
export const syncDatasort = `${environment.ApiURL_Without_Auth}/catalyst-sync?sort=1&`;
export const hedgefundfilterSearch = `${environment.ApiURL_Without_Auth}/hedge-fund-screener?search=1`;
export const hedgefundStockAllfilterColoum = `${environment.ApiURL_Without_Auth}/hedge-fund-screener?column=1&page=`;
export const itfilterColoum = `${environment.ApiURL_Without_Auth}/inside-trades?column=1&page=`;
export const syncfilter = `${environment.ApiURL_Without_Auth}/catalyst-sync?search=1`;
export const itdatasortwithfilter = `${environment.ApiURL_Without_Auth}/inside-trades?column=1&`;
export const syncdatasortwithfilter = `${environment.ApiURL_Without_Auth}/catalyst-sync?column=1&`;
export const screenerdatasortwithfilter = `${environment.ApiURL_Without_Auth}/company-screener?column=1&`;
export const fdadatasortwithfilter = `${environment.ApiURL_Without_Auth}/fda-calendar?column=1&`;
export const hedgedatasortwithfilter = `${environment.ApiURL_Without_Auth}/hedge-fund-screener?column=1&`;
export const compnayfilterColoum = `${environment.ApiURL_Without_Auth}/company-screener?column=1&page=`;
export const fdasortdata = `${environment.ApiURL_Without_Auth}/fda-calendar?sort=1&`;
export const allstocksfilterSearch = `${environment.ApiURL_Without_Auth}/hedge-fund-stock?search=1`;
export const allstocksdatasortwithfilter = `${environment.ApiURL_Without_Auth}/hedge-fund-stock?column=1&`;
export const screenerdatasort = `${environment.ApiURL_Without_Auth}/company-screener?sort=1&`;
export const searchsuggection = `${environment.ApiURL_Without_Auth}/search-query?suggestion=`;
//lossing-geinerData

export const getPlusData = `${environment.ApiURL}/data/gainers?pageSize=`;
export const getPrePlusData = `${environment.ApiURL}/data/pre-gainers?pageSize=`;
export const getPreMinusData = `${environment.ApiURL}/data/pre-losers?pageSize=`;
export const getminusData = `${environment.ApiURL}/data/losers?pageSize=`;
export const upcomingcatalystdata = `${environment.ApiURL_Without_Auth}/upcoming-catalyst`;
export const upcomingearnings = `${environment.ApiURL_Without_Auth}/upcoming-earnings`;

//votting_price

export const votting_price = `${environment.ApiURL_Without_Auth}/fda-calendar/voting/up-down`;
export const remove_price = `${environment.ApiURL_Without_Auth}/fda-calendar/voting/up-down/delete`;
export const votting_priceTarget = `${environment.ApiURL_Without_Auth}/fda-calendar/voting/price`;

// conatact-us

export const Contact_us = `${environment.ApiURL_Without_Auth}/contact-us`;
export const advertise_us = `${environment.ApiURL_Without_Auth}/advertise`;

//myportfolio

export const getChart = `http://localhost:3000/chartdata`;

export const heighestvolume = `${environment.ApiURL_Without_Auth}/highest-volume`;
export const activeOptions = `${environment.ApiURL_Without_Auth}/option-active`;
export const UnusualOptionActivitys = `${environment.ApiURL_Without_Auth}/option-unusual`;
export const shortInterests = `${environment.ApiURL_Without_Auth}/short-interest`;
export const tradessort = `${environment.ApiURL_Without_Auth}/trades-sort`;

//hedge funds
export const indevidual = `${environment.ApiURL_Without_Auth}/hedge-fund-ind?page=`;
export const indevidualoverview = `${environment.ApiURL_Without_Auth}/hedge-fund-ind/overview`;
export const allStocks = `${environment.ApiURL_Without_Auth}/hedge-fund-stock?page=`;
export const allStocks20 = `${environment.ApiURL_Without_Auth}/hedge-fund-stock-twenty`;
export const hedgefund = `${environment.ApiURL_Without_Auth}/hedge-fund-screener?page=`;

//optionchart
export const getoptionData = `${environment.ApiURL_Without_Auth}/option-summary/`;
//Hitmap
export const HitmapData = `${environment.ApiURL_Without_Auth}/categories?industry=1`;
export const HitmapDatawIndustry = `${environment.ApiURL_Without_Auth}/categories`;

//wishlist
export const getWishlistcatalyst = `${environment.ApiURL_Without_Auth}/fda-calendar?wishlist=1&page=`;
export const getWishlistinside = `${environment.ApiURL_Without_Auth}/inside-trades?wishlist=1&page=`;
export const getWishlistbiotech = `${environment.ApiURL_Without_Auth}/earnings-portfolio?wishlist=1&page=`;
export const getDrugwishlist = `${environment.ApiURL_Without_Auth}/catalyst-sync?wishlist=1&page=`;
export const gethedgefundwish = `${environment.ApiURL_Without_Auth}/hedge-fund-stock?wishlist=1&page=`;
export const getOptionData = `${environment.ApiURL_Without_Auth}/option-summary-portfolio`;
export const getPortfolio = `${environment.ApiURL}/wishlist/portfolio`;

// news
export const getNews = `${environment.ApiURL_Without_Auth}/news`;
export const getPortfolioNews = `${environment.ApiURL_Without_Auth}/potfolio-news?page=`;

//historical-pdufa
export const HistoricalPdufa = `${environment.ApiURL_Without_Auth}/pdufa-historical?page=`;
export const pdufafilter = `${environment.ApiURL_Without_Auth}/pdufa-historical/search`;
export const pdufadatawithfilter = `${environment.ApiURL_Without_Auth}/pdufa-historical/column?page=`;

// create-checkout-session

export const createcheckout = `${environment.ApiURL}/create-checkout-session`;  


// IPO
export const IPO = `${environment.ApiURL_Without_Auth}/upcoming-ipo`;  
export const pastIPO = `${environment.ApiURL_Without_Auth}/past-ipo`;  