import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { conferencecalendar } from '../const';

@Injectable({
  providedIn: 'root',
})
export class ConferenceCalendarServiceService {
  constructor(private http: HttpClient) {}

  conferencecalendar(requestBody: any) {
    return this.http.post(`${conferencecalendar}`, requestBody);
  }
}
