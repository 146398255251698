import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linebreaks'
})
export class LinebreaksPipe implements PipeTransform {

  transform(value: any): any {
    return value.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }
}
