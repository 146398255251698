import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  allStocks,
  allStocks20,
  hedgefund,
  indevidual,
  indevidualoverview,
} from '../const';

@Injectable({
  providedIn: 'root',
})
export class HedgefundsService {
  constructor(private http: HttpClient) {}

  indivisualData(data: string, page: number) {
    let obj = {
      fund: data,
    };
    return this.http.post(`${indevidual}${page}`, obj);
  }

  Indevidualoverview(data: string) {
    let obj = {
      fund: data,
    };
    return this.http.post(`${indevidualoverview}`, obj);
  }

  AllstocksData(page: number) {
    return this.http.post(`${allStocks}${page}`, '');
  }

  // AllstocksData20() {
  //   return this.http.get(`${allStocks20}`);
  // }

  Hedgedata(page: number) {
    return this.http.post(`${hedgefund}${page}`, '');
  }

  HedgeAlldata(page: number) {
    return this.http.get(`${hedgefund}${page}`);
  }
}
