import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catalystSync } from '../../shared/models/CatalystSync';
import { catalystSyncdata, catalystSyncdataTen } from '../const';

@Injectable({
  providedIn: 'root',
})
export class CatalystSyncService {
  constructor(private http: HttpClient) {}

  getCatalystSync(page: number) {
    return this.http.post<catalystSync[]>(`${catalystSyncdata}${page}`, '');
  }
  // getCatalystTenSync() {
  //   return this.http.get<catalystSync[]>(`${catalystSyncdataTen}`);
  // }
}
