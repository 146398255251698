import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginpopUpComponent } from './components/loginpop-up/loginpop-up.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgxCaptchaModule } from 'ngx-captcha';
import { DollarsCoversionPipe } from './pipes/dollars-coversion.pipe';
import { RiseAndFallColorPipe } from './pipes/rise-and-fall-color.pipe';
import { InfinityNumberPipe } from './pipes/infinity-number.pipe';
import { InitialLetterPipe } from './pipes/initial-letter.pipe';
import { StageProgressComponent } from './components/stage-progress/stage-progress.component';
import { FirstthirtywordPipe } from './pipes/firstthirtyword.pipe';
import { ConditionlengthPipe } from './pipes/conditionlength.pipe';
import { SpaceaftercomaPipe } from './pipes/spaceaftercoma.pipe';
import { DateformatePipe } from './pipes/dateformate.pipe';
import { QoqChgPipe } from './pipes/qoq-chg.pipe';
import { ZerotoDashPipe } from './pipes/zeroto-dash.pipe';
import { CompanyPerformancePipe } from './pipes/company-performance.pipe';
import { ZerofortablePipe } from './pipes/zerofortable.pipe';
import { OptionchartComponent } from './components/optionchart/optionchart.component';
import { LinebreaksPipe } from './pipes/linebreaks.pipe';
import { InfinityTablePipe } from './pipes/infinity-table.pipe';
import { ScrolltopComponent } from './components/scrolltop/scrolltop.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HedgeFundIndHeatmapComponent } from './components/hedge-fund-ind-heatmap/hedge-fund-ind-heatmap.component';
import { FinancialTableComponent } from './components/financial-table/financial-table.component';
import { LocksecComponent } from './components/locksec/locksec.component';

@NgModule({
  declarations: [
    MultiselectComponent,
    LoginpopUpComponent,
    DollarsCoversionPipe,
    RiseAndFallColorPipe,
    InfinityNumberPipe,
    InitialLetterPipe,
    StageProgressComponent,
    FirstthirtywordPipe,
    ConditionlengthPipe,
    SpaceaftercomaPipe,
    DateformatePipe,
    QoqChgPipe,
    ZerotoDashPipe,
    CompanyPerformancePipe,
    ZerofortablePipe,
    OptionchartComponent,
    LinebreaksPipe,
    InfinityTablePipe,
    ScrolltopComponent,
    HedgeFundIndHeatmapComponent,
    FinancialTableComponent,
    LocksecComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxCaptchaModule,
    NgbModule,
  ],
  exports: [
    InfinityTablePipe,
    HedgeFundIndHeatmapComponent,
    LinebreaksPipe,
    OptionchartComponent,
    ZerofortablePipe,
    MultiselectComponent,
    StageProgressComponent,
    LoginpopUpComponent,
    DollarsCoversionPipe,
    InfinityNumberPipe,
    RiseAndFallColorPipe,
    InitialLetterPipe,
    FirstthirtywordPipe,
    ConditionlengthPipe,
    SpaceaftercomaPipe,
    DateformatePipe,
    QoqChgPipe,
    ZerotoDashPipe,
    CompanyPerformancePipe,
    ScrolltopComponent,
    FinancialTableComponent,
    LocksecComponent,
  ],
})
export class SharedModule {}
