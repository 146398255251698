import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initialLetter'
})
export class InitialLetterPipe implements PipeTransform {

  transform(value: any): String {

    if (!value) {
      return "-"
    }
    return (typeof (value) == 'string' ? value.charAt(0) : value[0].charAt(0));
  }

}
