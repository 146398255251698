import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactUsService } from '../../../shared/service/contact-us.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {
  protected conatact_us!: FormGroup
  submitted: boolean = false;

  constructor(private fb: FormBuilder, private conatctusapi: ContactUsService, private toaster: ToastrService) { }

  ngOnInit() {
    this.conatact_us = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],

      phone: [''],
      subject: [''],
      feedback: [''],
    })
  }
  submit(data: any) {
    this.submitted = true
    if (this.conatact_us.valid) {
      this.conatctusapi.contactusData(data).subscribe({
        next: ((res: any) => {
          this.conatact_us.reset()
          this.submitted = false
          this.toaster.success(res)
        }), error: (error) => {

          this.toaster.error(error)

        }
      })
    }
  }
}
