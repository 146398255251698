import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class CanonicalService {
  constructor() {}

  setCanonicalURL(url?: string) {
    const canonicalURL = url ? url : window.location.href;
    let link: HTMLLinkElement | null = document.querySelector(
      "link[rel='canonical']"
    );

    if (link) {
      // Update the existing canonical link tag
      link.href = canonicalURL;
    } else {
      // Create and add the canonical link tag if it doesn't exist
      link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', canonicalURL);
      document.head.appendChild(link);
    }
  }
}
