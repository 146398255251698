import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DailyUpdatesService } from 'src/app/modules/shared/service/daily-updates.service';
import { environment } from 'src/environments/environment';
import { DecryptionService } from 'src/app/modules/shared/service/decryption.service';
import { LoosingGainerService } from '../../../shared/service/loosing-gainer.service';

@Component({
  selector: 'app-market-movers',
  templateUrl: './market-movers.component.html',
  styleUrl: './market-movers.component.scss',
})
export class MarketMoversComponent implements OnInit {
  positive20: any;
  negative20: any;
  optionUnusual: any;
  heighestVolArry: any[] = [];
  basePath: string = 'https://s3-symbol-logo.tradingview.com/';
  clientUrl = environment.clientUrl;
  shortinterest: any[] = [];
  activeOptionsArry: any[] = [];
  UnusualOptionActivity: any[] = [];
  buyers: any;
  sellers: any;
  RangeDate = 'Month';
  lastDays = 30;
  RangeDateSell = 'Month';
  lastDaysSell = 30;

  constructor(
    private DUpdateService: DailyUpdatesService,
    private decryptionService: DecryptionService,
    private LoosingGainerService: LoosingGainerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.dailyUpdates();
    this.unusual();
    this.heighestVol();
    this.shortInterestData();
    this.activeOptions();
    this.UnusualOptionActivitys();
    this.top_insiders_sell('month');
    this.top_insiders_buy('month');
  }

  removeDuplicatesByTitle(arr: any) {
    const uniqueTitles = new Set();
    return arr.filter((obj: any) => {
      const title = obj.title;
      return uniqueTitles.has(title) ? false : uniqueTitles.add(title);
    });
  }

  top_insiders_sell(date: string) {
    this.DUpdateService.top_insidersdata_seller(date).subscribe({
      next: (res: any) => {
        this.sellers = res.Sellers;
      },
    });
  }

  top_insiders_buy(date: string) {
    this.DUpdateService.top_insidersdata_buyer(date).subscribe({
      next: (res: any) => {
        this.buyers = res.Buyers;
      },
    });
  }

  selectDate(date: string) {
    switch (date) {
      case 'month':
        // this.top_insiders_sell('month');
        this.RangeDate = 'Month';
        this.lastDays = 30;
        this.top_insiders_buy('month');
        break;
      case 'week':
        // this.top_insiders_sell('week');
        this.RangeDate = 'Week';
        this.lastDays = 7;

        this.top_insiders_buy('week');
        break;

      case 'day': {
        this.RangeDate = 'Day';
        this.lastDays = 1;

        this.top_insiders_buy('day');
        break;
      }
    }
  }

  selectDateSell(date: string) {
    switch (date) {
      case 'month':
        this.RangeDateSell = 'Month';
        this.lastDaysSell = 30;
        this.top_insiders_sell('month');
        break;

      case 'week':
        this.RangeDateSell = 'Week';
        this.lastDaysSell = 7;
        this.top_insiders_sell('week');
        break;

      case 'day': {
        this.RangeDateSell = 'Day';
        this.lastDaysSell = 1;
        this.top_insiders_sell('day');
        break;
      }
    }
  }

  dailyUpdates() {
    this.DUpdateService.dailyUpdatesdata().subscribe({
      next: (res: any) => {
        // this.positive20 = this.removeDuplicatesByTitle(res.positive20);
        this.positive20 = this.removeDuplicatesByTitle(res.positive20);

        // this.negative20 = this.removeDuplicatesByTitle(res.negative20);
        this.negative20 = this.removeDuplicatesByTitle(res.negative20);
      },
    });
  }

  unusual() {
    this.DUpdateService.unusual_volumesAtDailyUpdates().subscribe({
      next: (res: any) => {
        this.optionUnusual = res;
      },
    });
  }

  heighrstDetails(ticker: any) {
    this.router.navigate(['company', ticker]);
  }

  activeOptions() {
    this.LoosingGainerService.activeOption().subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res);
        const ParseDecryptedData = JSON.parse(decryptData);
        console.log('ParseDecryptedData :', ParseDecryptedData);
        this.activeOptionsArry = ParseDecryptedData;
      },
    });
  }

  UnusualOptionActivitys() {
    this.LoosingGainerService.UnusualOptionActivity().subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res);
        const ParseDecryptedData = JSON.parse(decryptData);
        this.UnusualOptionActivity = ParseDecryptedData;
      },
    });
  }

  heighestVol() {
    this.LoosingGainerService.heighestVol().subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res);
        const ParseDecryptedData = JSON.parse(decryptData);
        this.heighestVolArry = ParseDecryptedData;
      },
    });
  }

  shortInterestData() {
    this.LoosingGainerService.shortInterest().subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res);
        const ParseDecryptedData = JSON.parse(decryptData);
        this.shortinterest = ParseDecryptedData;
      },
    });
  }
}
