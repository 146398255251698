import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-investing-masterclass',
  templateUrl: './investing-masterclass.component.html',
  styleUrl: './investing-masterclass.component.scss',
})
export class InvestingMasterclassComponent {
  @ViewChild('mainPanel') mainPanel!: ElementRef;

  scrollToSection(sectionId: string, event: MouseEvent): void {
    event.stopPropagation(); // Prevent event bubbling

    // Get the section element to scroll to
    const section = document.getElementById(sectionId);

    if (section) {
      // Calculate the offset to scroll the main panel
      const mainPanel = this.mainPanel.nativeElement;
      const sectionOffset =
        section.getBoundingClientRect().top +
        mainPanel.scrollTop -
        mainPanel.getBoundingClientRect().top;

      // Smooth scroll to the calculated position
      mainPanel.scrollTo({
        top: sectionOffset,
        behavior: 'smooth',
      });
    } else {
      console.error('Section not found:', sectionId);
    }
  }
}
