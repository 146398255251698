import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    var loginuser = localStorage.getItem('loggedInUser');

    if (loginuser == null || loginuser == undefined) {
      this.router.navigate(['home']);
      return false;
    }

    try {
      var jsonloginData = JSON.parse(loginuser);

      if (typeof jsonloginData != 'object') {
        this.router.navigate(['home']);
        return false;
      }
    } catch (error) {
      this.router.navigate(['home']);
      return false;
    }

    return true;
  }
}
