import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'companyPerformance'
})
export class CompanyPerformancePipe implements PipeTransform {

  transform(value: string | number): string | number {

    if (Number(value) > 0) {
      return '#B5E0D9';
    } else if (Number(value) < 0) {
      return '#FBC3C7';
    } else if (Number(value) == 0){
      return '#cdd2d7';
    }
     else {
      return ''; 

    }
  }

}
