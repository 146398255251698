import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'conditionlength',
})
export class ConditionlengthPipe implements PipeTransform {
  transform(value: any): any {
    if (!value) {
      return '-';
    }

    // const newword = value
    const words = value?.substring(0, 80);
    return words;
  }
}
