<section class="volatile">
  <div class="container-fluid p-2 fda-header-box">
    <div class="text-center fda-text">
      <h1 class="main-heading text-white m-0 me-3">IPO</h1>

      <p class="sub-heading text-white m-0">
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rem ipsa
        possimus rerum perferendis amet nobis, facilis vel esse dicta
        necessitatibus cumque sed numquam! Quos, veniam eos. Porro, in vero.
        Quae quibusdam rerum ratione inventore maiores dicta numquam a ea modi.
      </p>
    </div>
  </div>
</section>

<div>
  <h1 class="main-blue-heading my-2">Upcoming IPOs</h1>
</div>

<div id="dataContainer" class="table-responsive">
  <table class="table align-middle mb-0 bg-white">
    <thead class="text-center">
      <tr class="text-uppercase table-header">
        <th>company</th>
        <th>industry</th>
        <th>shares</th>
        <th>price low</th>
        <th>price high</th>
        <th>est_$_vol</th>
        <th>expected to trade</th>
        <th>status</th>
      </tr>
    </thead>
    <tbody class="text-center">
      @for (ipo of ipoDataupcoming; track $index) {
      <tr class="table-tr-font">
        <td scope="row" class="col-stick">
          <div class="text-center company-hover">
            <div>
              <p>{{ ipo.company }}</p>
            </div>
            <div>
              <p class="text-center text-data table-font-size">
                <a class="link-ticker table-astec-font text-white">{{
                  ipo.symbol
                }}</a>
              </p>
            </div>
          </div>
        </td>
        <td>{{ ipo.industry }}</td>
        <td>{{ ipo["shares_(millions)"] }}</td>
        <td>{{ ipo.price_low }}</td>
        <td>{{ ipo.price_high }}</td>
        <td>{{ ipo["est_$_vol_(millions)"] }}</td>
        <td>{{ ipo.expected_to_trade | date }}</td>
        <td>
          @if (ipo.status) {
          {{ ipo.status }}
          }@else { - }
        </td>
      </tr>
      }@empty {
        <tr style="box-shadow: none;">
          <td colspan="17">
            <ngx-skeleton-loader
              [theme]="{ height: '50px',  'border-radius': '4px', 'margin-top': '20px'}"
              count="7"
            >
            </ngx-skeleton-loader>
          </td>
        </tr>
      }
    </tbody>
  </table>
</div>

<div>
  <h1 class="main-blue-heading my-2">Past IPOs</h1>
</div>

<div id="dataContainer" class="table-responsive scrollable-table">
  <table class="table align-middle mb-0 bg-white">
    <thead class="text-center">
      <tr class="text-uppercase table-header">
        <th>company</th>
        <th>Close</th>
        <th>therapeutic focus</th>
        <th>underwriters</th>
        <th>ipo date</th>
        <th>offer price</th>
        <th>raised amount</th>
        <th>original price range</th>
      </tr>
    </thead>
    <tbody class="text-center">
      @for (ipo of ipoDatapast; track $index) {
      <tr class="table-tr-font">
        <td
          scope="row"
          class="col-stick cursorpointer"
          (click)="companyDetails(ipo.ticker)"
        >
          <div class="text-center company-hover">
            <div>
              <p>{{ ipo.company }}</p>
            </div>
            <div>
              <p class="text-center text-data table-font-size">
                <a class="link-ticker table-astec-font text-white">{{
                  ipo.ticker
                }}</a>
              </p>
            </div>
          </div>
        </td>
        <td>
          @if (ipo.close) {
            {{ ipo.close | number : "1.0-2" }}
          }@else {
            -
          }
        </td>
        <td>{{ ipo.therapeutic_focus }}</td>
        <td>{{ ipo.underwriters }}</td>
        <td>{{ ipo.ipo_date }}</td>
        <td>{{ ipo.offer_price }}</td>
        <td>{{ ipo.raised_amount }}</td>
        <td>{{ ipo.original_price_range }}</td>
      </tr>
      }@empty {
        <tr style="box-shadow: none;">
          <td colspan="17">
            <ngx-skeleton-loader
              [theme]="{ height: '50px',  'border-radius': '4px', 'margin-top': '20px'}"
              count="10"
            >
            </ngx-skeleton-loader>
          </td>
        </tr>
      }
    </tbody>
  </table>
</div>
