<section class="company-screener">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="text-center mt-3 mb-3">
          <h1 class="main-heading text-white m-0 me-3">Company Screener</h1>
          <p class="sub-heading text-center text-white m-0">
            Our screener delivers a holistic view of a company's financial
            health, drug pipeline, and options data, incorporating key metrics
            like EPS, P/E Ratio, burn rate, and more, alongside financial
            sustainability analysis and a development timeline for a complete
            forecast of future milestones and approvals.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid form-box py-2">
    <div class="row justify-content-center align-items-center">
      <div class="col-lg-3 col-md-10 col-12">
        <form style="height: 30px" class="example d-flex add_m_infilter">
          <input
            [(ngModel)]="searchValuequery"
            autocomplete="off"
            (keyup)="searchFilters($event)"
            (input)="onSearchInput($event)"
            #searchVal
            type="search"
            class="input-box"
            placeholder="Search by Comapny name or Ticker"
            name="search2"
          />
          <button
            (click)="searchFiltersonclick(searchValuequery)"
            class="searchquerybtn"
            type="button"
          >
            @if (isFilterBtnSearch) {
            <i style="color: white" class="fa fa-spin fa-spinner"></i>

            }@else {
            <i class="fa-solid fa-magnifying-glass"></i>
            }
          </button>
        </form>
        @if (suggection?.length > 0) {
        <ul class="list-unstyled m-0">
          @for (suggestion of suggection; track $index) {
          <li class="suggestion-item p-0 cursorpointer">
            <div
              (click)="searchFiltersonclick(suggestion.symbol)"
              class="d-flex filter-bottom justify-content-between px-2"
            >
              <span>
                @if (suggestion.logoid !== '') {
                <img
                  width="25px"
                  [src]="basePath + suggestion.logoid + '.svg'"
                />
                }@else {
                <span
                  class="avatarcss"
                  style="
                    width: 25px !important;
                    height: 25px !important;
                    margin: 0 !important;
                  "
                  >{{ suggestion.name | initialLetter }}</span
                >
                }
              </span>
              <p
                class="text-start text-dark filter-font-text mb-1 border-1 me-4"
              >
                {{ suggestion.name }}
              </p>
              <p class="text-end text-dark filter-font-text mb-1">
                {{ suggestion.symbol }}
              </p>
            </div>
          </li>
          }
        </ul>
        }
      </div>
      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedDataColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            #priceinput
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            Price
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if (selectedDataColor) {
          <span class="" (click)="clearSelectedPrice()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }

          <ul class="dropdown-menu games-menu">
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('less_than_1 $')"
                [class.selected]="'less_than_1 $' === selectedValue"
              >
                Less than 1 $
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('1$ to 2$')"
                [class.selected]="'1$ to 2$' === selectedValue"
              >
                1 $ to 2 $
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('2$ to 5$')"
                [class.selected]="'2$ to 5$' === selectedValue"
              >
                2 $ to 5 $
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('5$ to 50$')"
                [class.selected]="'5$ to 50$' === selectedValue"
              >
                5 $ to 50 $
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('50$ to 100$')"
                [class.selected]="'50$ to 100$' === selectedValue"
              >
                50 $ to 100 $
              </p>
            </li>
            <li>
              <button
                class="dropdown-item dropdown-toggle sub-menu dropdown-item filter-font-text text-center m-0"
                data-bs-toggle="dropdown"
              >
                Customize
              </button>
              <ul class="dropdown-menu games-menu">
                <div class="p-1" aria-labelledby="shareHoldDropdown">
                  <input
                    type="number"
                    class="custom-input-set mb-2"
                    placeholder="Min Value"
                    [(ngModel)]="minCustomValue"
                    (input)="updateCustomRange()"
                    (change)="pushFilterminPrice($event)"
                  />
                  <input
                    type="number"
                    class="custom-input-set"
                    placeholder="Max Value"
                    [(ngModel)]="maxCustomValue"
                    (input)="updateCustomRange()"
                    (change)="pushFiltermaxPrice($event)"
                  />
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedchangeColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <span>Change in Price</span>
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if (selectedchangeColor) {
          <span class="" (click)="clearSelectedChangePrice()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }

          <ul class="dropdown-menu">
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('less_than_1 %')"
                [class.selected]="'less_than_1 %' === selectedValueinchange"
              >
                Less than 1 %
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('1% to 2%')"
                [class.selected]="'1% to 2%' === selectedValueinchange"
              >
                1 % to 2 %
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('2% to 5%')"
                [class.selected]="'2% to 5%' === selectedValueinchange"
              >
                2 % to 5 %
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('5% to 10%')"
                [class.selected]="'5% to 10%' === selectedValueinchange"
              >
                5 % to 10 %
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('10% to 50%')"
                [class.selected]="'10% to 50%' === selectedValueinchange"
              >
                10 % to 50 %
              </p>
            </li>
            <li>
              <button
                class="dropdown-item dropdown-toggle dropdown-item filter-font-text text-center m-0"
                data-bs-toggle="dropdown"
              >
                Customize
              </button>
              <ul class="dropdown-menu">
                <div class="p-1" aria-labelledby="shareHoldDropdown">
                  <input
                    type="number"
                    class="custom-input-set mb-2"
                    placeholder="Min Value"
                    [(ngModel)]="minCustomchange"
                    (input)="updateChangePriceCustomRange()"
                    (change)="pushFilterChange($event)"
                  />
                  <input
                    type="number"
                    class="custom-input-set"
                    placeholder="Max Value"
                    [(ngModel)]="maxCustomchange"
                    (input)="updateChangePriceCustomRange()"
                    (change)="EndpushFilterChange($event)"
                  />
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedmarketColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <span>Market-Cap</span>
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if (selectedmarketColor) {
          <span class="" (click)="clearSelectedMarketCap()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }

          <ul class="dropdown-menu">
            <li>
              <p
                [class.selected]="'200b_or_more' === selectedValueinmarket_cap"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('200b_or_more')"
              >
                $ 200 billion or more
              </p>
            </li>
            <li>
              <p
                [class.selected]="'10b_to_200b' === selectedValueinmarket_cap"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('10b_to_200b')"
              >
                $ 10 billion to $ 200 billion
              </p>
            </li>
            <li>
              <p
                [class.selected]="'2b_to_10b' === selectedValueinmarket_cap"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('2b_to_10b')"
              >
                $ 2 billion to $ 10 billion
              </p>
            </li>
            <li>
              <p
                [class.selected]="'250m_to_2b' === selectedValueinmarket_cap"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('250m_to_2b')"
              >
                $ 250 million to $ 2 billion
              </p>
            </li>
            <li>
              <p
                [class.selected]="'250m_or_less' === selectedValueinmarket_cap"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('250m_or_less')"
              >
                Less than $ 250 million
              </p>
            </li>
            <li>
              <button
                class="dropdown-item dropdown-toggle dropdown-item filter-font-text text-center m-0"
                data-bs-toggle="dropdown"
              >
                Customize
              </button>
              <ul class="dropdown-menu">
                <div class="p-1" aria-labelledby="shareHoldDropdown">
                  <input
                    type="number"
                    class="custom-input-set mb-2"
                    placeholder="Min Value (Millions)"
                    [(ngModel)]="minCustomMarket"
                    (input)="updateMarketCapCustomRange()"
                    (change)="pushFilterMarket_cap($event)"
                  />
                  <input
                    type="number"
                    class="custom-input-set"
                    placeholder="Max Value (Millions)"
                    [(ngModel)]="maxCustomMarket"
                    (input)="updateMarketCapCustomRange()"
                    (change)="EndpushFilterMarket_cap($event)"
                  />
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedepsColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            #priceinput
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            EPS
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if (selectedepsColor) {
          <span class="" (click)="clearSelectedEPS()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>

          }

          <ul class="dropdown-menu games-menu">
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('less_than_1 $ E')"
                [class.selected]="'less_than_1 $ E' === selectedValueineps"
              >
                Less than 1 $
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('1$ to 2$ E')"
                [class.selected]="'1$ to 2$ E' === selectedValueineps"
              >
                1 $ to 2 $
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('2$ to 5$ E')"
                [class.selected]="'2$ to 5$ E' === selectedValueineps"
              >
                2 $ to 5 $
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('5$ to 50$ E')"
                [class.selected]="'5$ to 50$ E' === selectedValueineps"
              >
                5 $ to 50 $
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('50$ to 100$ E')"
                [class.selected]="'50$ to 100$ E' === selectedValueineps"
              >
                50 $ to 100 $
              </p>
            </li>
            <li>
              <button
                class="dropdown-item dropdown-toggle sub-menu dropdown-item filter-font-text text-center m-0"
                data-bs-toggle="dropdown"
              >
                Customize
              </button>
              <ul class="dropdown-menu games-menu">
                <div class="p-1" aria-labelledby="shareHoldDropdown">
                  <input
                    [(ngModel)]="minCustomEps"
                    type="number"
                    class="custom-input-set mb-2"
                    placeholder="Min Value"
                    (change)="pushFilterstartEPS($event)"
                  />
                  <input
                    [(ngModel)]="maxCustomEps"
                    type="number"
                    class="custom-input-set"
                    placeholder="Max Value"
                    (change)="pushFilterendEPS($event)"
                  />
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedbutn_rateColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <span>Burn-Rate</span>
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if (selectedbutn_rateColor) {
          <span class="" (click)="clearSelectedBurn_rate()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }

          <ul class="dropdown-menu">
            <li>
              <p
                [class.selected]="'-5b_negative' === selectedValueinburn_rate"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('-5b_negative')"
              >
                More than -5 Billion (Negative)
              </p>
            </li>
            <li>
              <p
                [class.selected]="'-500m_-5b' === selectedValueinburn_rate"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('-500m_-5b')"
              >
                -500 Million to -5 Billion
              </p>
            </li>
            <li>
              <p
                [class.selected]="'-10m_-500m' === selectedValueinburn_rate"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('-10m_-500m')"
              >
                -10 Million to -500 Million
              </p>
            </li>
            <li>
              <p
                [class.selected]="'-10m_500m' === selectedValueinburn_rate"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('-10m_500m')"
              >
                -10 Million to 500 Million
              </p>
            </li>
            <li>
              <p
                [class.selected]="'500m_5b' === selectedValueinburn_rate"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('500m_5b')"
              >
                500 Million to 5 Billion
              </p>
            </li>
            <li>
              <p
                [class.selected]="'5b_more' === selectedValueinburn_rate"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('5b_more')"
              >
                More than 5 Billion
              </p>
            </li>
            <li>
              <button
                class="dropdown-item dropdown-toggle dropdown-item filter-font-text text-center m-0"
                data-bs-toggle="dropdown"
              >
                Customize
              </button>
              <ul class="dropdown-menu">
                <div class="p-1" aria-labelledby="shareHoldDropdown">
                  <input
                    type="number"
                    class="custom-input-set mb-2"
                    placeholder="Min Value (Millions)"
                    [(ngModel)]="minCustomburn_rate"
                    (change)="pushFilterBurn_rate($event)"
                  />
                  <input
                    type="number"
                    class="custom-input-set"
                    placeholder="Max Value (Millions)"
                    [(ngModel)]="maxCustoburn_rate"
                    (change)="EndpushFilterBurn_rate($event)"
                  />
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedindustryColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <span>Industry</span>
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if (selectedindustryColor) {
          <span class="" (click)="clearSelectedIndustry()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }

          <ul class="dropdown-menu">
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'Biotechnology' === selectedValueinindustry"
                (click)="selectedData('Biotechnology')"
              >
                Biotechnology
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'Medical Device' === selectedValueinindustry"
                (click)="selectedData('Medical Device')"
              >
                Medical Device
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="
                  'Diagnostics & Research' === selectedValueinindustry
                "
                (click)="selectedData('Diagnostics & Research')"
              >
                Diagnostics & Research
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center m-0"
                [class.selected]="
                  'Drug Manufacturers' === selectedValueinindustry
                "
                (click)="selectedData('Drug Manufacturers')"
              >
                Drug Manufacturers
              </p>
            </li>
          </ul>
        </div>
      </div>

      <!-- SPECIFIC DISEASES filter  -->
      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedDiseasesColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <span>Specific Diseases</span>
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if(selectedDiseasesColor){
          <span class="" (click)="clearDiseasesCheckbox()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }

          <ul class="dropdown-menu">
            <div class="form-check form-switch p-2 m-0">
              <label
                class="form-check-label p-0 m-0"
                for="selectDiseasesCheckbox"
                >Select</label
              >
              <input
                class="form-check-input"
                type="checkbox"
                id="selectDiseasesCheckbox"
                (change)="toggleDiseasesSelect($event)"
              />
            </div>
            <div class="p-3 pt-1">
              @for (item of diseasesList; track $index) {
              <div>
                <label class="filter-font-text">
                  <input
                    type="checkbox"
                    (change)="pushFilterDiseases()"
                    [(ngModel)]="item.checked"
                  />
                  {{ item.name }}
                </label>
              </div>
              }@empty { No Record Found }
            </div>
          </ul>
        </div>
      </div>

      <!-- Therapeutic_area -->
      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedTherapeuticColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <span>Therapeutic Area</span>
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if(selectedTherapeuticColor){
          <span class="" (click)="clearTherapeuticCheckbox()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }

          <ul class="dropdown-menu">
            <div class="form-check form-switch p-2 m-0">
              <label
                class="form-check-label p-0 m-0"
                for="selectTheraputicsCheckbox"
                >Select</label
              >
              <input
                class="form-check-input"
                type="checkbox"
                id="selectTheraputicsCheckbox"
                (change)="toggleTheraputics($event)"
              />
            </div>
            <div class="p-3 pt-1">
              @for (item of therapeuticList; track $index) {
              <div>
                <label class="filter-font-text">
                  <input
                    type="checkbox"
                    (change)="pushFilterTherapeutic()"
                    [(ngModel)]="item.checked"
                  />
                  {{ item.name }}
                </label>
              </div>
              }@empty { No Record Found }
            </div>
          </ul>
        </div>
      </div>

      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedrunwayColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <span>Runway</span>
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if (selectedrunwayColor) {
          <span class="" (click)="clearSelectedrunway()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>

          }

          <ul class="dropdown-menu">
            <li>
              <p
                [class.selected]="'less_than_6m' === selectedValueinrunway"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('less_than_6m')"
              >
                Less than 6 Months
              </p>
            </li>
            <li>
              <p
                [class.selected]="'1y_to_2y' === selectedValueinrunway"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('1y_to_2y')"
              >
                1 Year to 2 Years
              </p>
            </li>
            <li>
              <p
                [class.selected]="'2_to_5y' === selectedValueinrunway"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('2_to_5y')"
              >
                2 to 5 Years
              </p>
            </li>
            <li>
              <p
                [class.selected]="'5_to_10y' === selectedValueinrunway"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('5_to_10y')"
              >
                5 to 10 Years
              </p>
            </li>
            <li>
              <p
                [class.selected]="'morethan_10y' === selectedValueinrunway"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('morethan_10y')"
              >
                More than 10 Years
              </p>
            </li>
            <li>
              <button
                class="dropdown-item dropdown-toggle dropdown-item filter-font-text text-center m-0"
                data-bs-toggle="dropdown"
              >
                Customize
              </button>
              <ul class="dropdown-menu">
                <div class="p-1" aria-labelledby="shareHoldDropdown">
                  <input
                    type="number"
                    class="custom-input-set mb-2"
                    placeholder="Min Value (Months)"
                    [(ngModel)]="minCustomrunway"
                    (change)="pushFilterrunway($event)"
                  />
                  <input
                    type="number"
                    class="custom-input-set"
                    placeholder="Max Value (Months)"
                    [(ngModel)]="maxCustomrunway"
                    (change)="EndpushFilterrunway($event)"
                  />
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedvolumeColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <span>Volume</span>
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if (selectedvolumeColor) {
          <span class="" (click)="clearSelectedvolume()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>

          }

          <ul class="dropdown-menu">
            <li>
              <p
                [class.selected]="'less_than_0.1m' === selectedValueinvolume"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('less_than_0.1m')"
              >
                Less than 0.1 Million
              </p>
            </li>
            <li>
              <p
                [class.selected]="'0.1m_10m' === selectedValueinvolume"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('0.1m_10m')"
              >
                0.1 Million to 10 Million
              </p>
            </li>
            <li>
              <p
                [class.selected]="'10m_100m' === selectedValueinvolume"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('10m_100m')"
              >
                10 Million to 100 Million
              </p>
            </li>
            <li>
              <p
                [class.selected]="'100m_200m' === selectedValueinvolume"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('100m_200m')"
              >
                100 Million to 200 Million
              </p>
            </li>
            <li>
              <p
                [class.selected]="'more_than_200m' === selectedValueinvolume"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('more_than_200m')"
              >
                More than 200 Million
              </p>
            </li>
            <li>
              <button
                class="dropdown-item dropdown-toggle dropdown-item filter-font-text text-center m-0"
                data-bs-toggle="dropdown"
              >
                Customize
              </button>
              <ul class="dropdown-menu">
                <div class="p-1" aria-labelledby="shareHoldDropdown">
                  <input
                    type="number"
                    class="custom-input-set mb-2"
                    placeholder="Min Value (Millions)"
                    [(ngModel)]="minCustomrvolume"
                    (change)="pushFiltervolume($event)"
                  />
                  <input
                    type="number"
                    class="custom-input-set"
                    placeholder="Max Value (Millions)"
                    [(ngModel)]="maxCustomrvolume"
                    (change)="EndpushFiltervolume($event)"
                  />
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedpeColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <span>P/E Ratio</span>
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if (selectedpeColor) {
          <span class="" (click)="clearSelectedpe()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>

          }

          <ul class="dropdown-menu">
            <!-- <li>
              <p [class.selected]="'Negative' === selectedValueinpe"
                class="dropdown-item filter-font-text text-center filter-bottom m-0" (click)="selectedData('Negative')">
                Negative
              </p>
            </li> -->
            <li>
              <p
                [class.selected]="'1_20' === selectedValueinpe"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('1_20')"
              >
                1 to 20
              </p>
            </li>
            <li>
              <p
                [class.selected]="'20_100' === selectedValueinpe"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('20_100')"
              >
                20 to 100
              </p>
            </li>
            <li>
              <p
                [class.selected]="'100_500' === selectedValueinpe"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('100_500')"
              >
                100 to 500
              </p>
            </li>
            <li>
              <button
                class="dropdown-item dropdown-toggle dropdown-item filter-font-text text-center m-0"
                data-bs-toggle="dropdown"
              >
                Customize
              </button>
              <ul class="dropdown-menu">
                <div class="p-1" aria-labelledby="shareHoldDropdown">
                  <input
                    type="number"
                    class="custom-input-set mb-2"
                    placeholder="Min Value"
                    [(ngModel)]="minCustomrpe"
                    (change)="pushFilterpe($event)"
                  />
                  <input
                    type="number"
                    class="custom-input-set"
                    placeholder="Max Value"
                    [(ngModel)]="maxCustomrpe"
                    (change)="EndpushFilterpe($event)"
                  />
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedlive_cashColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <span>Live Cash</span>
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if (selectedlive_cashColor) {
          <span class="" (click)="clearSelectedLive_cash()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }

          <ul class="dropdown-menu">
            <li>
              <p
                [class.selected]="'200b_more L' === selectedValueinlive_cash"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('200b_more L')"
              >
                $200 Billion or More
              </p>
            </li>
            <li>
              <p
                [class.selected]="'10b_200b L' === selectedValueinlive_cash"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('10b_200b L')"
              >
                $10 Billion to $200 Billion
              </p>
            </li>
            <li>
              <p
                [class.selected]="'2b_10b L' === selectedValueinlive_cash"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('2b_10b L')"
              >
                $2 Billion to $10 Billion
              </p>
            </li>
            <li>
              <p
                [class.selected]="'250m_2b L' === selectedValueinlive_cash"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('250m_2b L')"
              >
                $250 Million to $2 Billion
              </p>
            </li>
            <li>
              <p
                [class.selected]="
                  'less_than_250m L' === selectedValueinlive_cash
                "
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('less_than_250m L')"
              >
                Less than $250 Million
              </p>
            </li>
            <li>
              <button
                class="dropdown-item dropdown-toggle dropdown-item filter-font-text text-center m-0"
                data-bs-toggle="dropdown"
              >
                Customize
              </button>
              <ul class="dropdown-menu">
                <div class="p-1" aria-labelledby="shareHoldDropdown">
                  <input
                    type="number"
                    class="custom-input-set mb-2"
                    placeholder="Min Value (Millions)"
                    [(ngModel)]="minCustomlive_cash"
                    (change)="pushFilterlive_cash($event)"
                  />
                  <input
                    type="number"
                    class="custom-input-set"
                    placeholder="Max Value (Millions)"
                    [(ngModel)]="maxCustomlive_cash"
                    (change)="EndpushFilterlive_cash($event)"
                  />
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div id="add" class="text-center mt-1 d-flex justify-content-center">
      <button
        type=""
        class="form-save-button d-flex justify-content-center align-items-center text-nowrap me-3 mt-2 mb-1"
        (click)="saveFilters()"
      >
        @if (!isFilterBtn) {
        <p class="mb-0">APPLY FILTERS</p>
        }@else {
        <i class="fa fa-spin fa-spinner"></i>
        }
      </button>
      <button
        type=""
        class="form-reset-button d-flex justify-content-center align-items-center text-nowrap me-3 mt-2 mb-1"
        (click)="resetFilters()"
      >
        @if (!isFilterBtnResert) {
        <p class="mb-0">RESET FILTERS</p>
        }@else {
        <i class="fa fa-spin fa-spinner"></i>
        }
      </button>
    </div>
  </div>
</section>

<section class="">
  @if (showButtons) {

  <div class="scrollbtns d-flex justify-content-between">
    <img
      id="left-button"
      (click)="scrollLeft()"
      class="button"
      src="assets/images/left-blue-arrow.svg"
      alt=""
    />
    <img
      id="right-button"
      (click)="scrollRight()"
      class="button"
      src="assets/images/right-blue-arrow.svg"
      alt=""
    />
  </div>

  }
  <div class="d-flex justify-content-between align-items-center mx-1">
    <p
      class="text-start tblcompanyLength my-1 text-nowrap d-flex align-items-center"
    >
      <i class="fa-solid fa-circle-info mx-2"></i>
      Showing {{ csTable.length }} Company Out of {{ totalDatalength }}
    </p>
    <app-multiselect
      [list]="list"
      (select_all)="Select_All_checkbox()"
      (shareCheckedList)="shareCheckedList($event)"
      (shareIndividualCheckedList)="shareIndividualCheckedList($event)"
    >
    </app-multiselect>
  </div>
  <div style="position: relative">
    <div
      class="table-responsive scrollable-table"
      id="dataContainer"
      #dataContainer
      (scroll)="onScroll($event)"
      [ngClass]="{ tablelocked: !productId }"
    >
      <table class="table align-middle mb-0 bg-white">
        <thead class="text-center stikyness">
          <tr class="border-none">
            <th colspan="2" class=""></th>
            <th colspan="9" class="growth-color">
              <p class="pt-2 me-2 ms-2">GROWTH</p>
            </th>
            <th colspan="6" class="bar-blue">
              <p class="pt-2 ms-2 me-2">CASH DATA</p>
            </th>
          </tr>

          <tr class="text-uppercase table-header">
            <th class="table-name">
              <div class="d-flex justify-content-center cursor-pointer">
                NAME<i
                  (click)="assending('name')"
                  [ngClass]="arrowname"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>

            @if (ticker) {
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                TICKER<i
                  (click)="assending('symbol')"
                  [ngClass]="arrowticker"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            } @if (price) {
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                Price<i
                  (click)="assending('close')"
                  [ngClass]="arrowprice"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            } @if (thirtydaypricechange) {
            <th
              placement="bottom"
              container="body"
              ngbTooltip="30 Days Of Price Change"
            >
              30 DAYS PRICE CHANGE
            </th>
            } @if (industry) {
            <th>Industry</th>
            } @if (Specific_Diseases) {
            <th>Specific Diseases</th>
            } @if (Therapeutic_area) {
            <th>Therapeutic Area</th>
            } @if (marketcap) {
            <th
              class="text-nowrap"
              placement="bottom"
              container="body"
              ngbTooltip="Market Capital"
            >
              <div class="d-flex justify-content-center cursor-pointer">
                MARKET CAP<i
                  (click)="assending('market_cap_basic')"
                  [ngClass]="arrowmarket_cap"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            } @if (volume) {
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                VOLUME<i
                  (click)="assending('volume')"
                  [ngClass]="arrowvolume"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            } @if (pe_ratio) {
            <th
              class="text-nowrap"
              placement="bottom"
              container="body"
              ngbTooltip="Price To Earnings Ratio"
            >
              <div class="d-flex justify-content-center cursor-pointer">
                P/E RATIO<i
                  (click)="assending('price_earnings_ttm')"
                  [ngClass]="arrowPe_rat"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            } 
            <th>Insiders</th>

            @if (eps) {
            <th
              class="text-nowrap"
              placement="bottom"
              container="body"
              ngbTooltip="EARNINGS PER SHARE"
            >
              <div class="d-flex justify-content-center cursor-pointer">
                EPS<i
                  (click)="assending('earnings_per_share_basic_ttm')"
                  [ngClass]="arrowEps"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            } @if(dter){
            <th
              class="text-nowrap"
              placement="bottom"
              container="body"
              ngbTooltip="Debt To Equity Ratio"
            >
              <div class="d-flex justify-content-center cursor-pointer">
                D/E RATIO<i
                  (click)="assending('dtoe')"
                  [ngClass]="arrowDE_rat"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            } @if (working_cap) {
            <th class="text-nowrap">
              <div class="d-flex justify-content-center cursor-pointer">
                LIVE CASH<i
                  (click)="assending('Cash_and_Cash_Equivalents')"
                  [ngClass]="arrowlive_cash"
                  class="fa-solid cursorpointer mx-2"
                ></i>
              </div>
            </th>
            } @if (burn_rate) {
            <th
              class="text-nowrap"
              placement="bottom"
              container="body"
              ngbTooltip="BURN RATE (MONTHLY)"
            >
              <div class="d-flex justify-content-center cursor-pointer">
                BURN RATE
                <i
                  (click)="assending('Monthly_Burn_Rate')"
                  [ngClass]="arrowburn_rate"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            }

            <th class="text-nowrap">EBITDA</th>

            @if (runway) {
            <th placement="left" container="body" ngbTooltip="Runway (Months)">
              <div class="d-flex justify-content-center cursor-pointer">
                RUNWAY<i
                  (click)="assending('Runway_(Months)')"
                  [ngClass]="arrowrunway"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            }
          </tr>
        </thead>
        <tbody class="text-center">
          @if (csTable.length == 0) {
            <tr style="box-shadow: none;">
              <td colspan="17">
                <ngx-skeleton-loader
                  [theme]="{ height: '50px',  'border-radius': '4px', 'margin-top': '20px'}"
                  count="11"
                >
                </ngx-skeleton-loader>
              </td>
            </tr>
          }@else { @for (cs of csTable; track $index) {
          <tr class="table-tr-font">
            <td scope="row" class="col-stick">
              <div
                class="text-center company-hover"
                (click)="companyDetails(cs.symbol)"
              >
                @if (cs.logoid !== '') {
                <img
                  width="25px"
                  [src]="basePath + cs.logoid + '.svg'"
                  class="mb-1"
                />
                }@else {
                <span class="avatarcssForTable">{{
                  cs.name | initialLetter
                }}</span>
                }

                <p class="text-center text-data table-font-size">
                  <a
                    href="{{ clientUrl }}/company/{{ cs.symbol | uppercase }}"
                    class="link-ticker"
                  >
                    {{ cs.name }}
                  </a>
                </p>
              </div>
            </td>
            <td scope="row" class="d-none">{{ $index + 1 }}</td>

            @if (ticker) {
            <td class="table-wide-cell">
              <div class="d-grid justify-content-center">
                <p class="table-astec-font table-font-size text-center">
                  <a
                    href="{{ clientUrl }}/company/{{ cs.symbol | uppercase }}"
                    class="link-ticker"
                  >
                    {{ cs.symbol }}
                  </a>
                </p>

                @if (cs.symbol) { @if (portfoliomatchTicker) { @if
                (portfoliomatchTicker.includes(cs.symbol)) {
                <i
                  placement="bottom"
                  container="body"
                  ngbTooltip="Remove from Portfolio"
                  (click)="removeSymboltoPotfoliyo(cs.symbol)"
                  style="
                    opacity: unset;
                    font-size: 20px;
                    color: var(--clr-primary);
                  "
                  class="fa-solid fa-circle-minus my-2 cursorpointer"
                ></i>
                }@else {
                <i
                  placement="bottom"
                  container="body"
                  ngbTooltip="Add to Portfolio"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  (click)="
                    this.portfolioTicker = cs.symbol;
                    portfoliocost = undefined;
                    this.portfolioPrice = cs.close;
                    portfolioquntity = undefined
                  "
                  style="
                    opacity: unset;
                    font-size: 20px;
                    color: var(--clr-primary);
                  "
                  class="fa-solid fa-circle-plus my-2 cursorpointer"
                ></i>
                } } @if (!portfoliomatchTicker) {
                <i
                  placement="bottom"
                  container="body"
                  ngbTooltip="Add to Portfolio"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  (click)="
                    this.portfolioTicker = cs.symbol;
                    this.portfolioPrice = cs.close;
                    portfoliocost = undefined;
                    portfolioquntity = undefined
                  "
                  style="
                    opacity: unset;
                    font-size: 20px;
                    color: var(--clr-primary);
                  "
                  class="fa-solid fa-circle-plus my-2 cursorpointer text-center"
                ></i>
                } }
              </div>
            </td>
            } @if (price) {
            <td>
              <p [ngClass]="cs.closeClass" class="table-font-size">{{ cs.close | number : "1.0-2" }}</p>
              <p
                [style.color]="cs.change?.toString() | riseAndFallColor"
                class="table-price table-font-size"
                id="table-price-color"
              >
                {{ cs.change | number : "1.0-2" }}%
              </p>
            </td>
            } @if (thirtydaypricechange) {
            <td>
              @if (cs.thirtyDayChart) {
              <app-thirty-day-trend
                class="d-flex justify-content-center"
                [chartData]="cs.thirtyDayChart"
                [x]="$index"
              ></app-thirty-day-trend>
              }@else { - }
            </td>
            } @if (industry) {
            <td>
              @if (cs.Industry) {
              <p class="table-font-size">{{ cs.Industry }}</p>
              }@else {
              <p>-</p>
              }
            </td>
            } @if (Specific_Diseases) {
            <td>
              <div>
                @if (cs.Specific_Diseases) {
                <p class="table-description-text table-font-size">
                  {{ cs.Specific_Diseases.substring(0, 50) }}
                </p>
                }@else {
                <p>-</p>
                } @if (cs.Specific_Diseases) {
                <div>
                  @if (cs.Specific_Diseases.length > 50) {
                  <p
                    style="cursor: pointer"
                    class="text-danger"
                    placement="left"
                    container="body"
                    container="body"
                    ngbTooltip="{{ cs.Specific_Diseases }}"
                    triggers="click:blur"
                  >
                    Read More+
                  </p>
                  }
                </div>
                }
              </div>
            </td>
            } @if (Therapeutic_area) {
            <td>
              <div>
                @if (cs.Therapeutic_area) {
                <p class="table-description-text table-font-size">
                  {{ cs.Therapeutic_area.substring(0, 50) }}...
                </p>
                }@else {
                <p>-</p>
                } @if (cs.Therapeutic_area) {
                <div>
                  @if (cs.Therapeutic_area.length > 50) {
                  <p
                    style="cursor: pointer"
                    class="text-danger"
                    placement="left"
                    container="body"
                    container="body"
                    ngbTooltip="{{ cs.Therapeutic_area }}"
                    triggers="click:blur"
                  >
                    Read More+
                  </p>
                  }
                </div>
                }
              </div>
            </td>
            } @if (marketcap) {
            <td class="table-wide-cell text-nowrap">
              <p class="table-font-size">
                {{ cs.market_cap_basic | dollarsCoversion }}
              </p>
            </td>
            } @if (volume) {
            <td>
              <p
                [style.color]="cs.volume?.toString() | riseAndFallColor"
                class="table-wide-cell text-nowrap"
                class="table-font-size"
              >
                {{ cs.volume | dollarsCoversion }}
              </p>
            </td>
            } @if (pe_ratio) {
            <td class="table-wide-cell text-nowrap">
              @if (cs.price_earnings_ttm) {
              <p
                [style.color]="
                  cs.price_earnings_ttm?.toString() | riseAndFallColor
                "
                class="table-font-size"
              >
                {{ cs.price_earnings_ttm | infinityNumber }}
              </p>
              }@else {
              <p>-</p>
              }
            </td>
            } 
            
            <td class="table-wide-cell">
              @if (cs.avgInsiderTransactions) {
                <p [style.color]="cs.avgInsiderTransactions.toString() | riseAndFallColor" class="table-font-size">
                  @if(cs.avgInsiderTransactions){
                  {{ cs.avgInsiderTransactions |  dollarsCoversion }}
                  }
                </p>
              }@else{
                -
              }
            </td>
            
            @if (eps) {
            <td class="table-wide-cell text-nowrap">
              <p
                [style.color]="
                  cs.earnings_per_share_basic_ttm?.toString() | riseAndFallColor
                "
                class="table-font-size"
              >
                {{ cs.earnings_per_share_basic_ttm | number : "1.0-2" }}
              </p>
            </td>
            } @if (dter) {
            <td class="table-wide-cell text-nowrap">
              <p class="table-font-size">
                {{ cs.Debt_to_Equity_Ratio | number : "1.0-2" }}
              </p>
            </td>
            } @if (working_cap) {
            <td class="table-wide-cell text-nowrap">
              <p
                [style.color]="
                  cs.Cash_and_Cash_Equivalents?.toString() | riseAndFallColor
                "
                class="table-font-size"
              >
                {{ cs.Cash_and_Cash_Equivalents | dollarsCoversion }}
              </p>
            </td>
            } @if (burn_rate) {
            <td class="table-wide-cell text-nowrap">
              <p
                [style.color]="
                  cs.Monthly_Burn_Rate?.toString() | riseAndFallColor
                "
                class="table-font-size"
              >
                {{ cs.Monthly_Burn_Rate | dollarsCoversion }}
              </p>
            </td>
            }

            <td class="table-wide-cell text-nowrap">
              <p
                [style.color]="cs.ebitda | riseAndFallColor"
                class="table-font-size"
              >
                {{ cs.ebitda | dollarsCoversion }}
              </p>
            </td>

            @if (runway) {
            <td>
              <p
                [style.color]="
                  cs['Runway_(Months)']?.toString() | riseAndFallColor
                "
                class="table-wide-cell text-nowrap"
                class="table-font-size"
              >
                {{ cs["Runway_(Months)"]?.toString() | infinityNumber }}
              </p>
            </td>

            }
          </tr>
          }@empty { No Record Found } }
        </tbody>

        <tfoot>
          @if (loadData) {
          <tr class="loading-border">
            <td colspan="12">
              <div class="data-loading-container">
                <p class="Fetching me-3 mb-0 mt-0">
                  Fetching more data for you
                </p>
                <span class="loader mb-2" style="font-size: 3px"> </span>
              </div>
            </td>
          </tr>
          }
        </tfoot>
      </table>
      <div
        style="
          position: absolute;
          bottom: 0;
          background-color: #f1f7fde6;
          z-index: 1;
        "
      >
        @if (!productId) { @if (csTable.length > 0) {
        <app-locksec></app-locksec>
        } }
      </div>
    </div>
  </div>

  <app-loginpop-up
    [displayStyle]="modalDisplayStyle"
    (myout)="GetChildData($event)"
  ></app-loginpop-up>
  <app-portfoliomodal
    [portfolioTicker]="portfolioTicker"
    [portfolioPrice]="portfolioPrice"
    [portfoliocost]="portfoliocost"
    [portfolioquntity]="portfolioquntity"
    (addSymbol)="onAddSymbol($event)"
  ></app-portfoliomodal>
</section>
