import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'riseAndFallColor'
})
export class RiseAndFallColorPipe implements PipeTransform {

  transform(value: string | number): string | number {

    if (Number(value) > 0) {
      return 'var(--clr-green)';
    } else if (Number(value) < 0) {
      return 'var(--clr-red)';
    } else {
      return '';

    }
  }

}