import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CSTable } from '../models/company-screener';
import { environment } from 'src/environments/environment';
import { catchError, map, throwError } from 'rxjs';
import { getCSTableData, getCSTenTableData } from '../const';

@Injectable({
  providedIn: 'root',
})
export class CompanyScreenerService {
  constructor(private http: HttpClient) {}

  getCSTable(page: number) {
    return this.http.post<CSTable[]>(`${getCSTableData}${page}`, {});
  }

  // getCSTenTable() {
  //   return this.http.get<CSTable[]>(`${getCSTenTableData}`).pipe(
  //     map((data: CSTable[]) => {
  //       return data;
  //     }),
  //     catchError((error: HttpErrorResponse) => {
  //       return throwError(() => error.error.message);
  //     })
  //   );
  // }
}
