<div
  [ngClass]="this.url.includes('individual') ? 'sub-box-individual' : 'sub-box'"
  class="shadow-lg"
>
  <div class="container-fluid py-1">
    <div class="row justify-content-center">
      <div
        [ngClass]="
          this.url.includes('individual')
            ? 'col-lg-11 col-md-10 col-12'
            : 'col-lg-9 col-md-10 col-12'
        "
      >
        <div class="row align-items-center py-2 addlock-style">
          <div class="col-12 col-md-4 show-sub-btn-m d-none">
            <div class="text-center">
              <button routerLink="/subscription" class="form-save-button mb-2">
                Start Free Trial
              </button>
            </div>
            <div
              style="opacity: 0.2"
              class="d-flex justify-content-center lockimgdiv"
            >
              <img width="30%" src="assets/images/lock.svg" alt="" />
            </div>
          </div>
          <div class="col-12 col-md-4 ">
            <div class="row text-start">
              <div class="col-12 mb-2">
                <div
                  [ngClass]="
                    this.url.includes('individual')
                      ? 'fw-bold-individual p-1'
                      : 'p-1 fw-bold'
                  "
                >
                  <i
                    style="color: rgb(7, 175, 7); opacity: unset"
                    class="fa-solid fa-circle-check mx-2 mt-1"
                  ></i>
                  <p class="m-0">Full access to a searchable FDA calendar</p>
                </div>
              </div>
              <div class="col-12 mb-2">
                <div
                  [ngClass]="
                    this.url.includes('individual')
                      ? 'fw-bold-individual p-1'
                      : 'p-1 fw-bold'
                  "
                >
                  <i
                    style="color: rgb(7, 175, 7); opacity: unset"
                    class="fa-solid fa-circle-check mx-2 mt-1"
                  ></i>
                  <p class="m-0">
                    Detailed monitoring of upcoming PDUFA dates and historical
                    data
                  </p>
                </div>
              </div>
              <div class="col-12 mb-2">
                <div
                  [ngClass]="
                    this.url.includes('individual')
                      ? 'fw-bold-individual p-1'
                      : 'p-1 fw-bold'
                  "
                >
                  <i
                    style="color: rgb(7, 175, 7); opacity: unset"
                    class="fa-solid fa-circle-check mx-2 mt-1"
                  ></i>
                  <p class="m-0">
                    Bio hedge fund screener and holdings analysis
                  </p>
                </div>
              </div>
              <div class="col-12 mb-2">
                <div
                  [ngClass]="
                    this.url.includes('individual')
                      ? 'fw-bold-individual p-1'
                      : 'p-1 fw-bold'
                  "
                >
                  <i
                    style="color: rgb(7, 175, 7); opacity: unset"
                    class="fa-solid fa-circle-check mx-2 mt-1"
                  ></i>
                  <p class="m-0">Insider trading monitoring</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 sub-btn-l">
            <div class="text-center">
              <button routerLink="/subscription" class="form-save-button mb-2">
                Start Free Trial
              </button>
            </div>
            <div
              style="opacity: 0.2"
              class="d-flex justify-content-center lockimgdiv"
            >
              <img width="30%" src="assets/images/lock.svg" alt="" />
            </div>
          </div>
          <!-- Column 2 -->
          <div class="col-12 col-md-4">
            <div class="row text-start">
              
              <div class="col-12 mb-2">
                <div
                  [ngClass]="
                    this.url.includes('individual')
                      ? 'fw-bold-individual p-1'
                      : 'p-1 fw-bold'
                  "
                >
                  <i
                    style="color: rgb(7, 175, 7); opacity: unset"
                    class="fa-solid fa-circle-check mx-2 mt-1"
                  ></i>
                  <p class="m-0">
                    Comprehensive research on over 1,100 companies
                  </p>
                </div>
              </div>
              <div class="col-12 mb-2">
                <div
                  [ngClass]="
                    this.url.includes('individual')
                      ? 'fw-bold-individual p-1'
                      : 'p-1 fw-bold'
                  "
                >
                  <i
                    style="color: rgb(7, 175, 7); opacity: unset"
                    class="fa-solid fa-circle-check mx-2 mt-1"
                  ></i>
                  <p class="m-0">
                    Stay updated on all biotech earnings announcements
                  </p>
                </div>
              </div>
              <div class="col-12 mb-2">
                <div
                  [ngClass]="
                    this.url.includes('individual')
                      ? 'fw-bold-individual p-1'
                      : 'p-1 fw-bold'
                  "
                >
                  <i
                    style="color: rgb(7, 175, 7); opacity: unset"
                    class="fa-solid fa-circle-check mx-2 mt-1"
                  ></i>
                  <p class="m-0">
                    Personalized portfolio management with advanced tracking
                  </p>
                </div>
              </div>
              <div class="col-12 mb-2">
                <div
                  [ngClass]="
                    this.url.includes('individual')
                      ? 'fw-bold-individual p-1'
                      : 'p-1 fw-bold'
                  "
                >
                  <i
                    style="color: rgb(7, 175, 7); opacity: unset"
                    class="fa-solid fa-circle-check mx-2 mt-1"
                  ></i>
                  <p class="m-0">Clinical trials and drug pipeline analysis</p>
                </div>
              </div>
             
        
          
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
