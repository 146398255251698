import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { FooterService } from '../../../core/services/footer.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @ViewChild('myForm') myForm!: NgForm;
  successMsg = ""
  emailControl: FormControl;
  hidefooter = true;

  constructor(private footerService: FooterService, private tost: ToastrService) {
    this.emailControl = new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)]);
  }

  ngOnInit() {

  }

  getLinkId(data: string) {

    var objData = {
      email: data
    }
    if (this.emailControl.invalid) {
      this.tost.warning('Please enter valid Email.')
      return
    }
    this.footerService.footerEmail(objData).subscribe({
      next: (res: any) => {

        this.tost.success(res.message)
      },
      error: (err) => {
        this.tost.error(err.error.message)
      }
    });
    this.emailControl.reset('');
  }

  hideshowfooter() {
    this.hidefooter = !this.hidefooter;
  }
}
