import { Component, ElementRef, ViewChild } from '@angular/core';
import { News } from 'src/app/modules/shared/models/news.model';
import { DecryptionService } from 'src/app/modules/shared/service/decryption.service';
import { NewsService } from 'src/app/modules/shared/service/news.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrl: './news.component.scss',
})
export class NewsComponent {
  newsItems: News[] = [];
  newsItem: any;
  currentPage = 1;
  newsType: string = 'event';
  isLoading = false;
  descriptionforpopup = '';
  displayStyle = 'none';
  newsCount: number = 0;
  loadData!: boolean;
  searchText: string = '';

  @ViewChild('dataContainer') dataContainer!: ElementRef;

  constructor(
    private newsService: NewsService,
    private decryptionService: DecryptionService
  ) {}

  ngOnInit() {
    this.newsData()
  }

  newsData(){
    this.isLoading = true;

    this.newsService.getNewsData(this.newsType,this.currentPage).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.encrypted);
        const ParseDecryptedData = JSON.parse(decryptData);
        this.newsItems = this.newsItems.concat(ParseDecryptedData[0].data);
        this.newsCount = ParseDecryptedData[0].total[0].count;
        this.isLoading = false;
        this.loadData = false;
        this.currentPage++;
      },
      error: (err: any) => {
        this.isLoading = true;
      },
    });
  }

  loadMoreData() {
    this.isLoading = true;
    this.loadData = true;

    this.newsService.getNewsData(this.newsType,this.currentPage).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.encrypted);
        const ParseDecryptedData = JSON.parse(decryptData);
        this.newsItems = this.newsItems.concat(ParseDecryptedData[0].data);
        this.newsCount = ParseDecryptedData[0].total[0].count;
        this.isLoading = false;
        this.loadData = false;
        this.currentPage++;
      },
      error: (err: any) => {
        this.isLoading = true;
      },
    });
  }

  // onScroll(e: any) {
    
  //   const tableViewHeight = e.target.offsetHeight;
  //   const tableScrollHeight = e.target.scrollHeight;
  //   const scrollLocation = e.target.scrollTop + 20;

  //   const buffer = 0;
  //   const limit = tableScrollHeight - tableViewHeight - buffer;

  //   if (scrollLocation >= limit && !this.isLoading) {
  //     this.loadMoreData();
  //   }
  // }

  onScroll(event: any) {
    const element = event.target as HTMLElement;
    const scrollPosition = element.scrollTop + element.clientHeight;
    const totalHeight = element.scrollHeight;
    if (localStorage.getItem('loggedInUser')) {
      if (scrollPosition >= totalHeight - 1 && !this.isLoading) {
        if (this.currentPage != 1) {
          this.loadMoreData();
        }
      }
    } 
  }

  selectType(type: string) {
    this.newsItems = [];
    this.newsType = type;
    this.currentPage = 1;

    const dataContainer = document.getElementById('dataContainer');
    if (dataContainer) {
      dataContainer.scrollTop = 0;
    }

    this.newsData();
  }

  // companyDetails(ticker: string) {
  //   // this.router.navigate(['company-details']);
  // }

  openpopup(data: any) {
    this.newsItem = data;
    this.displayStyle = 'block';
    document.body.style.overflow = 'hidden';
  }

  closePopup() {
    this.displayStyle = 'none';
    document.body.style.overflow = 'auto';
  }
}
