<div class="Inside">
  <div class="col-12">
    <div class="text-center mt-3 mb-3">
      <h1 class="main-heading text-white m-0 py-4">My Portfolio</h1>
    </div>
  </div>
</div>
<div class="container-fluid px-5 justify-content-center main-div">
  <div class="">
    <div class="">
      <div class="d-flex justify-content-center align-items-center">
        <h5 class="">Welcome, {{ fullName }}</h5>
        <i
          placement="bottom"
          container="body"
          ngbTooltip="Add to Portfolio"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          style="
            opacity: unset;
            font-size: 20px;
            color: var(--clr-primary);
            margin-bottom: 0.4rem;
          "
          class="fa-solid fa-circle-plus cursorpointer ms-2"
        ></i>
      </div>
    </div>
  </div>

  <div class="overflow">
    <div class="d-flex justify-content-center table-button">
      @if (totalInvestedval > 0) {
      <div class="text-center mx-1">
        <p
          (click)="scrollToSection(overview)"
          class="table-option mb-1 text-light"
        >
          Portfolio Overview
        </p>
      </div>
      } @if (insideData.length > 0) {
      <div class="text-center mx-1">
        <p
          (click)="scrollToSection(insidetrades)"
          class="table-option mb-1 text-light"
        >
          Inside Trades
        </p>
      </div>
      } @if (biotechData.length > 0) {
      <div class="text-center mx-1">
        <p
          class="table-option mb-1 text-light"
          (click)="scrollToSection(bioteach)"
        >
          Biotech Earnings
        </p>
      </div>
      } @if (drugpipeData.length > 0) {
      <div class="text-center mx-1">
        <p
          class="table-option mb-1 text-light"
          (click)="scrollToSection(drugpipe)"
        >
          Drug Pipeline
        </p>
      </div>
      } @if (hedgeFundData.length > 0) {
      <div class="text-center mx-1">
        <p
          (click)="scrollToSection(hedge)"
          class="table-option mb-1 text-light"
        >
          Hedge Funds
        </p>
      </div>
      } @if (this.optionData.length > 0) {
      <div class="text-center mx-1">
        <p
          (click)="scrollToSection(optiondata)"
          class="table-option mb-1 text-light"
        >
          Options Data
        </p>
      </div>
      } @if (catalystData.length > 0) {
      <div class="text-center mx-1">
        <p
          (click)="scrollToSection(catalyst)"
          class="table-option mb-1 text-light"
        >
          Catalysts
        </p>
      </div>
      } @if (newsItems.length > 0) {
      <div class="text-center mx-1">
        <p
          (click)="scrollToSection(newsSection)"
          class="table-option mb-1 text-light"
        >
          News
        </p>
      </div>
      }
    </div>
  </div>

  @if (portfolioData.length > 0 || catalystData.length > 0 || insideData.length
  > 0 || biotechData.length > 0 || drugpipeData.length > 0 ||
  hedgeFundData.length > 0 || optionData.length > 0 || optionDataPuts.length >
  0) {
  <div>
    @if(portfolioData.length > 0 && totalInvestedval > 0){
    <h2 #overview class="my-2">Portfolio Overview</h2>
    <!-- <p class="text-start tblcompanyLength my-1 text-nowrap d-flex align-items-center">
      <i class="fa-solid fa-circle-info mx-2"></i>
      Showing {{ catalystData.length }} catalyst Out of {{totalDatalengthofCatalyst}}
    </p> -->

    <div class="d-flex my-portfolio-view">
      <div class="overviewitem w-100 mb-3 mt-3">
        <div class="d-flex align-items-center justify-content-center">
          <img
            class="icon m-1"
            src="assets/images/portfolio-overview-1.svg"
            alt=""
          />
          <div style="width: 150px" class="p-1 text-nowrap text-center">
            <p class="text-size mb-1">Invested Value</p>
            <h5 class="m-1">$ {{ totalInvestedval | number : "1.0-2" }}</h5>
          </div>
        </div>
      </div>
      <div class="overviewitem w-100 mb-3 mt-3">
        <div class="d-flex align-items-center justify-content-center">
          <img
            class="icon m-1"
            src="assets/images/portfolio-overview-2.svg"
            alt=""
          />
          <div style="width: 150px" class="p-1 text-nowrap text-center">
            <p class="text-size mb-1">Latest Value</p>
            <h5 class="m-1">$ {{ totalcostval | number : "1.0-2" }}</h5>
          </div>
        </div>
      </div>
      <div class="overviewitem w-100 mb-3 mt-3">
        <div class="d-flex align-items-center justify-content-center">
          <img
            class="icon m-1"
            src="assets/images/portfolio-overview-3.svg"
            alt=""
          />
          <div style="width: 150px" class="p-1 text-nowrap text-center">
            <p class="text-size mb-1">Gain/loss</p>
            <h5
              [style.color]="totalprofit.toString() | riseAndFallColor"
              class="m-1"
            >
              $ {{ totalprofit | number : "1.0-2" }}
            </h5>
          </div>
        </div>
      </div>
    </div>

    <div
      [ngClass]="customclassforoverview"
      id="dataContainer"
      class="table-responsive scrollable-table my-3"
    >
      @if(!this.productId){
      <app-financial-table [columns]="tablePortfolio" [data]="portfolioData">
      </app-financial-table>
      }@else {
      <table class="table align-middle mb-0 bg-white">
        <thead>
          <tr class="text-center text-uppercase table-header">
            <th>Ticker</th>
            <th>Purchase Date</th>
            <th>Cost per share</th>
            <th>Price per share</th>
            <th>Earning per share</th>
            <th>Holdings</th>
            <th>Total Cost</th>
            <th>Total Price</th>
            <th>Total Profit</th>
          </tr>
        </thead>
        <tbody>
          @for (item of portfolioData; track $index) { @if (item.cps > 0 ) {

          <tr class="text-center">
            <td>
              <div 
                class="d-flex justify-content-center cursorpointer align-items-center tablefontsize"
              >
                <div (click)="companyDetails(item.ticker)" style="width: 40px">
                  @if (item.logoid !== '') {
                  <img
                    width="20px"
                    [src]="basePath + item.logoid + '.svg'"
                    class="me-2"
                  />
                  }@else {
                  <span class="avatarcssForTable">{{
                    item.name | initialLetter
                  }}</span>
                  }
                </div>
                <div style="width: 40px">
                  {{ item.ticker }}
                </div>
                <div style="width: 40px">
                  <i
                    placement="bottom"
                    container="body"
                    ngbTooltip="Remove from Portfolio"
                    (click)="removeSymboltoPotfoliyo(item.ticker, 'overview')"
                    style="
                      opacity: unset;
                      font-size: 20px;
                      color: var(--clr-primary);
                    "
                    class="fa-solid fa-circle-minus my-2 ms-2 cursorpointer"
                  ></i>
                </div>
              </div>
            </td>
            <td class="tablefontsize">
              @if (item.purchaseDate) {
              <p class="m-0">{{ item.purchaseDate }}</p>
              }@else {
              <p class="m-0">-</p>
              }
            </td>
            <td class="tablefontsize">
              @if (item.cps) {
              <p class="m-0">{{ item.cps | number : "1.0-2" }}</p>
              }@else {
              <p class="m-0">-</p>
              }
            </td>
            <td class="tablefontsize">
              @if (item.pps) {
              <p class="m-0">{{ item.pps }}</p>
              }@else {
              <p class="m-0">-</p>
              }
            </td>
            <td class="tablefontsize">
              @if (item.eps) {
              <p
                class="m-0"
                [style.color]="item.eps.toString() | riseAndFallColor"
              >
                {{ item.eps | number : "1.0-2" }}
              </p>
              }@else {
              <p class="m-0">-</p>
              }
            </td>
            <td class="tablefontsize">
              @if (item.holdings) {
              <p class="m-0">{{ item.holdings }}</p>
              }@else {
              <p class="m-0">-</p>
              }
            </td>
            <td class="tablefontsize">
              @if (item.totalCost) {
              <p class="m-0">{{ item.totalCost | number : "1.0-2" }}</p>
              }@else {
              <p class="m-0">-</p>
              }
            </td>
            <td class="tablefontsize">
              @if (item.totalPrice) {
              <p class="m-0">{{ item.totalPrice | number : "1.0-2" }}</p>
              }@else {
              <p class="m-0">-</p>
              }
            </td>
            <td class="tablefontsize">
              @if (item.totalProfit) {
              <p
                class="m-0"
                [style.color]="item.totalProfit.toString() | riseAndFallColor"
              >
                {{ item.totalProfit | number : "1.0-2" }}
              </p>
              }@else {
              <p class="m-0">-</p>
              }
            </td>
          </tr>
          } }@empty { No Record Found }
        </tbody>
      </table>
      }
    </div>
    } @if(catalystData.length > 0){
    <h2 #catalyst class="my-2">Catalysts</h2>
    <p
      class="text-start tblcompanyLength my-1 text-nowrap d-flex align-items-center"
    >
      <i class="fa-solid fa-circle-info mx-2"></i>
      Showing {{ catalystData.length }} catalyst Out of
      {{ totalDatalengthofCatalyst }}
    </p>
    <div
      [ngClass]="customclassforcatalyst"
      class="table-responsive scrollable-table my-3"
      id="dataContainer"
      (scroll)="onScrollCatalyst($event)"
      #dataContainer
    >
      @if (!this.productId) {
      <app-financial-table  [columns]="tableCatalyst" [data]="catalystData">
      </app-financial-table>
      }@else {
      <table class="table align-middle mb-0 bg-white">
        <thead>
          <tr class="text-center text-uppercase table-header">
            <th>Symbol</th>
            <th>Name</th>
            <th>Close</th>
            <th>Change</th>
            <th>Catalyst Date</th>
            <th>Phase</th>
            <th>Drug Name</th>
            <th>treatment</th>
            <!-- <th>Change Abs</th> -->
          </tr>
        </thead>
        <tbody>
          @for (item of catalystData; track $index) {
          <tr class="text-center">
            <td>
              <div
                class="d-flex cursorpointer justify-content-center align-items-center tablefontsize"
              >
                <div  (click)="companyDetails(item.ticker)" style="width: 40px">
                  @if (item.logoid !== '') {
                  <img
                    width="20px"
                    [src]="basePath + item.logoid + '.svg'"
                    class="me-2"
                  />
                  }@else {
                  <span class="avatarcssForTable">{{
                    item.name | initialLetter
                  }}</span>
                  }
                </div>
                <div style="width: 40px">
                  {{ item.ticker }}
                </div>
                <div style="width: 40px">
                  <i
                    placement="bottom"
                    container="body"
                    ngbTooltip="Remove from Portfolio"
                    (click)="removeSymboltoPotfoliyo(item.ticker, 'catalyst')"
                    style="
                      opacity: unset;
                      font-size: 20px;
                      color: var(--clr-primary);
                    "
                    class="fa-solid fa-circle-minus my-2 ms-2 cursorpointer"
                  ></i>
                </div>
              </div>
            </td>
            <td class="tablefontsize">{{ item.name }}</td>
            <td
              class="tablefontsize"
              [style.color]="item.close.toString() | riseAndFallColor"
            >
              {{ item.close | number : "1.0-2" }}
            </td>
            <td
              class="tablefontsize"
              [style.color]="item.change.toString() | riseAndFallColor"
            >
              {{ item.change | number : "1.0-2" }}
            </td>
            <td class="tablefontsize">{{ item.catdate }}</td>
            <td class="" style="width: 150px">
              {{ item.stage }}
              <app-stage-progress stage="{{ item.stage }}"></app-stage-progress>
            </td>
            <td class="tablefontsize">{{ item.drugname }}</td>
            <td class="">
              <div class="">
                <p class="tablefontsize mb-0">
                  {{ item.treatment.substring(0, 100) }}
                </p>

                @if (item.treatment.length > 100) {
                <div>
                  <p
                    style="cursor: pointer"
                    class="text-danger"
                    container="body"
                    ngbTooltip="{{ item.treatment }}"
                    triggers="click:blur"
                  >
                    Read More+
                  </p>
                </div>
                }
              </div>
            </td>
            <!-- <td>{{item.changeAbs}}</td> -->
          </tr>
          }@empty { No Record Found }
        </tbody>
      </table>
      }
    </div>
    } @if (insideData.length > 0) {
    <h2 #insidetrades class="my-2">Insides Trades</h2>

    <p
      class="text-start tblcompanyLength my-1 text-nowrap d-flex align-items-center"
    >
      <i class="fa-solid fa-circle-info mx-2"></i>
      Showing {{ insideData.length }} Trades Out of
      {{ totalDatalengthofinside }}
    </p>

    <div
      [ngClass]="customclassforinsides"
      class="table-responsive scrollable-table my-3"
      id="dataContainer"
      (scroll)="onScrollInside($event)"
      #dataContainer
    >
      @if (!this.productId) {
      <app-financial-table [columns]="tableInside" [data]="insideData">
      </app-financial-table>
      }@else {
      <table class="table align-middle mb-0 bg-white">
        <thead>
          <tr class="text-center text-uppercase table-header">
            <th>Symbol</th>
            <th>TRANSACTOR NAME</th>
            <th>TRADED SHARES</th>
            <th>TRADE PRICE</th>
            <th>TRADE DATE</th>
            <th>CURRENT HOLDINGS</th>
          </tr>
        </thead>
        <tbody>
          @for (item of insideData; track $index) {
          <tr class="text-center">
            <td>
              <div 
                class="d-flex cursorpointer justify-content-center align-items-center tablefontsize"
              >
                <div (click)="companyDetails(item.symbol)" style="width: 40px">
                  @if (item.logoid !== '') {
                  <img
                    width="20px"
                    [src]="basePath + item.logoid + '.svg'"
                    class="me-2"
                  />
                  }@else {
                  <span class="avatarcssForTable">{{
                    item.name | initialLetter
                  }}</span>
                  }
                </div>
                <div style="width: 40px">
                  {{ item.symbol }}
                </div>
                <div style="width: 40px">
                  <i
                    placement="bottom"
                    container="body"
                    ngbTooltip="Remove from Portfolio"
                    (click)="removeSymboltoPotfoliyo(item.symbol, 'inside')"
                    style="
                      opacity: unset;
                      font-size: 20px;
                      color: var(--clr-primary);
                    "
                    class="fa-solid fa-circle-minus my-2 ms-2 cursorpointer"
                  ></i>
                </div>
              </div>
            </td>
            <td class="tablefontsize">{{ item.name }}</td>
            <td
              class="tablefontsize"
              [style.color]="item.change.toString() | riseAndFallColor"
            >
              {{ item.change }}
            </td>
            <td>
              @if(item.transactionPrice == 0){ Not Available }@else {
              {{ item.transactionPrice | number : "1.0-2" }}
              }
            </td>
            <td class="tablefontsize">{{ item.transactionDate }}</td>
            <td class="tablefontsize">{{ item.share | dollarsCoversion }}</td>
          </tr>
          }@empty { No Record Found }
        </tbody>
      </table>
      }
    </div>

    } @if (biotechData.length > 0) {
    <h2 #bioteach class="my-2">Biotech Earnings</h2>
    <p
      class="text-start tblcompanyLength my-1 text-nowrap d-flex align-items-center"
    >
      <i class="fa-solid fa-circle-info mx-2"></i>
      Showing {{ biotechData.length }} catalyst Out of
      {{ totalDatalengthofbiotech }}
    </p>
    <div
      [ngClass]="customclassforbioteach"
      class="table-responsive scrollable-table my-3"
      id="dataContainer"
      (scroll)="onScrollBiotech($event)"
    >
      @if (!this.productId) {
      <app-financial-table [columns]="tableBiotech" [data]="biotechData">
      </app-financial-table>
      } @else {
      <table class="table align-middle mb-0 bg-white">
        <thead>
          <tr class="text-center text-uppercase table-header">
            <th>Symbol</th>
            <th>Name</th>
            <th>Date</th>
            <th>Title</th>
            <th class="text-nowrap">Earnings per Share (Basic TTM)</th>
            <th>Industry</th>
            <th class="text-nowrap">Market Cap (Basic)</th>
          </tr>
        </thead>
        <tbody class="text-center">
          @for (item of biotechData; track $index) {
          <tr class="text-center">
            <td>
              <div 
                class="d-flex cursorpointer justify-content-center align-items-center tablefontsize"
              >
                <div (click)="companyDetails(item.symbol)" style="width: 40px">
                  @if (item.logoid !== '') {
                  <img
                    width="20px"
                    [src]="basePath + item.logoid + '.svg'"
                    class="me-2"
                  />
                  }@else {
                  <span class="avatarcssForTable">{{
                    item.name | initialLetter
                  }}</span>
                  }
                </div>
                <div style="width: 40px">
                  {{ item.symbol }}
                </div>
                <div style="width: 40px">
                  <i
                    placement="bottom"
                    container="body"
                    ngbTooltip="Remove from Portfolio"
                    (click)="removeSymboltoPotfoliyo(item.symbol, 'biotech')"
                    style="
                      opacity: unset;
                      font-size: 20px;
                      color: var(--clr-primary);
                    "
                    class="fa-solid fa-circle-minus my-2 ms-2 cursorpointer"
                  ></i>
                </div>
              </div>
            </td>
            <td class="tablefontsize">{{ item.name }}</td>
            <td class="tablefontsize">{{ item.date }}</td>
            <td class="tablefontsize">{{ item.title }}</td>
            @if (item.earnings_per_share_basic_ttm) {
            <td
              class="tablefontsize"
              [style.color]="
                item.earnings_per_share_basic_ttm.toString() | riseAndFallColor
              "
            >
              {{ item.earnings_per_share_basic_ttm | number : "1.0-2" }}
            </td>
            }@else {
            <td>-</td>
            }
            <td class="tablefontsize">{{ item.Industry }}</td>
            <td>{{ item.market_cap_basic | dollarsCoversion }}</td>
          </tr>
          }@empty { No Record Found }
        </tbody>
      </table>
      }
    </div>
    } @if (drugpipeData.length > 0) {
    <h2 #drugpipe class="my-2">Drug Pipelines</h2>
    <p
      class="text-start tblcompanyLength my-1 text-nowrap d-flex align-items-center"
    >
      <i class="fa-solid fa-circle-info mx-2"></i>
      Showing {{ drugpipeData.length }} Drug Out of
      {{ totalDatalengthofdrugpipe }}
    </p>
    <div
      [ngClass]="customclassfordrugpipe"
      class="table-responsive scrollable-table my-3"
      id="dataContainer"
      (scroll)="onDrugpipeScroll($event)"
    >
      @if (!this.productId) {
      <app-financial-table [columns]="tableDrugpipe" [data]="drugpipeData">
      </app-financial-table>
      }@else {
      <table class="table align-middle mb-0 bg-white">
        <thead>
          <tr class="text-center text-uppercase table-header">
            <th>Symbol</th>
            <th>pipeline</th>
            <th>CONDITION</th>
            <th>PHASE</th>
            <th>CATALYST DATE</th>
            <th>Study Title</th>
            <th>NCT Id</th>
          </tr>
        </thead>
        <tbody>
          @for (item of drugpipeData; track $index) {
          <tr class="text-center">
            <td>
              <div 
                class="d-flex cursorpointer justify-content-center align-items-center tablefontsize"
              >
                <div (click)="companyDetails(item.ticker)" style="width: 40px">
                  @if (item.logoid !== '') {
                  <img
                    width="20px"
                    [src]="basePath + item.logoid + '.svg'"
                    class="me-2"
                  />
                  }@else {
                  <span class="avatarcssForTable">{{
                    item.name | initialLetter
                  }}</span>
                  }
                </div>
                <div style="width: 40px">
                  {{ item.ticker }}
                </div>
                <div style="width: 40px">
                  <i
                    placement="bottom"
                    container="body"
                    ngbTooltip="Remove from Portfolio"
                    (click)="removeSymboltoPotfoliyo(item.ticker, 'drugpipe')"
                    style="
                      opacity: unset;
                      font-size: 20px;
                      color: var(--clr-primary);
                    "
                    class="fa-solid fa-circle-minus my-2 ms-2 cursorpointer"
                  ></i>
                </div>
              </div>
            </td>
            <td class="table-wide-cell">
              @if (item.InterventionName) {
              <p class="pipelinefont m-0 text-break tablefontsize">
                {{ item.InterventionName.slice(0, 40) }}
              </p>
              } @if (item.InterventionName?.length > 40) {
              <p
                triggers="click:blur"
                style="cursor: pointer"
                class="text-danger tablefontsize m-0"
                container="body"
                ngbTooltip="{{ item.InterventionName }}"
              >
                <span class="tablefontsize">Read More+</span>
              </p>
              }
            </td>
            <td class="table-wide-cell">
              <div class="table-font-size tablefontsize">
                {{ item.Condition.slice(0, 40) }}
              </div>

              @if(item.Condition.length > 40){
              <p
                triggers="click:blur"
                style="cursor: pointer"
                class="text-danger tablefontsize m-0"
                container="body"
                ngbTooltip="{{ item.Condition }}"
              >
                <span class="tablefontsize">Read More+</span>
              </p>
              }
            </td>
            <td>{{ item.Phase }}</td>
            <td class="tablefontsize">{{ item.CompletionDate }}</td>
            <td class="table-wide-cell">
              <div class="table-font-size tablefontsize m-0">
                {{ item.BriefTitle.slice(0, 30) }}
              </div>

              @if (item.BriefTitle.length > 30) {
              <p
                triggers="click:blur"
                style="cursor: pointer"
                class="text-danger m-0 tablefontsize"
                container="body"
                ngbTooltip="{{ item.BriefTitle }}"
              >
                <span>Read More+</span>
              </p>
              }
            </td>
            <td class="table-wide-cell">
              <div class="table-font-size tablefontsize">
                {{ item.nctId }}
              </div>
            </td>
          </tr>
          }@empty { No Record Found }
        </tbody>
      </table>
      }
    </div>
    } @if (hedgeFundData.length > 0) {
    <h2 #hedge class="my-2">Hedge Funds</h2>
    <p
      class="text-start tblcompanyLength my-1 text-nowrap d-flex align-items-center"
    >
      <i class="fa-solid fa-circle-info mx-2"></i>
      Showing {{ hedgeFundData.length }} Trades Out of
      {{ totalDatalengthofhedgeFund }}
    </p>
    <div
      [ngClass]="customclassforhedgefund"
      class="table-responsive scrollable-table my-3"
      id="dataContainer"
      (scroll)="onscrollhedgefund($event)"
    >
      @if(!this.productId){
      <app-financial-table [columns]="tableHedgeFund" [data]="hedgeFundData">
      </app-financial-table>
      }@else {
      <table class="table align-middle mb-0 bg-white">
        <thead>
          <tr class="text-center text-uppercase table-header">
            <th>STOCK</th>
            <th>Rank</th>
            <th>Share Held</th>
            <th>Market Value</th>
            <th>Hedge Fund Name</th>
          </tr>
        </thead>
        <tbody>
          @for (item of hedgeFundData; track $index) {
          <tr class="text-center">
            <td>
              <div 
                class="d-flex cursorpointer justify-content-center align-items-center tablefontsize"
              >
                @if (item.combinedData?.logoid) {
                <img (click)="companyDetails(item.symbol)"
                  width="20px"
                  [src]="basePath + item.combinedData?.logoid + '.svg'"
                  class="me-2"
                />
                }@else {
                <span class="avatarcssForTable">{{
                  item.name | initialLetter
                }}</span>
                }

                {{ item.symbol }}
                <i
                  placement="bottom"
                  container="body"
                  ngbTooltip="Remove from Portfolio"
                  (click)="removeSymboltoPotfoliyo(item.symbol, 'hedgefund')"
                  style="
                    opacity: unset;
                    font-size: 20px;
                    color: var(--clr-primary);
                  "
                  class="fa-solid fa-circle-minus my-2 ms-2 cursorpointer"
                ></i>
              </div>
            </td>
            <td class="tablefontsize">{{ item.rank }}</td>
            <td class="tablefontsize">{{ item.current_shares }}</td>
            <td class="tablefontsize">
              {{ item.current_mv | dollarsCoversion }}
            </td>
            <td class="">
              <p class="pipelinefont text-break tablefontsize m-0">
                {{ item.hedgeFundName[0] }} , {{ item?.hedgeFundName[1] }}
              </p>

              @if (item.hedgeFundName.length > 2) {
              <p
                triggers="click:blur"
                style="cursor: pointer"
                class="text-danger m-0 tablefontsize"
                container="body"
                ngbTooltip="{{ item.hedgeFundName | spaceaftercoma }}"
              >
                <span>Read More+</span>
              </p>
              }
            </td>
          </tr>
          }@empty { No Record Found }
        </tbody>
      </table>
      }
    </div>
    } @if(optionData.length > 0 || optionDataPuts.length > 0){
    <div class="row justify-content-evenly mb-3">
      <h2 #optiondata class="my-2">Option Data</h2>

      <div
        [ngClass]="customclassforoption"
        class="table-responsive col-lg-6 col-md-10 col-12 scrollable-table"
      >
        @if(!this.productId){
        <app-financial-table [columns]="tableOptionData" [data]="optionData">
        </app-financial-table>
        }@else {
        <table class="table align-middle mb-0 bg-white">
          <thead>
            <tr>
              <th
                class="text-uppercase"
                style="border-bottom: 1px solid whitesmoke; text-align: center"
                colspan="4"
              >
                Call
              </th>
            </tr>
            <tr class="text-center text-uppercase table-header">
              <th>EXPIRATION</th>
              <th>Symbol</th>
              <th>Total Volume</th>
              <th>Total Interest</th>
            </tr>
          </thead>
          <tbody>
            @for(item of optionData; track $index){
            <tr class="text-center">
              <td class="tablefontsize">{{ item.date }}</td>
              <td class="tablefontsize">{{ item.symbol }}</td>
              <td class="tablefontsize">{{ item.totalVolume }}</td>
              <td class="tablefontsize">{{ item.totalopenInterest }}</td>
            </tr>
            }@empty { No Record Found }
          </tbody>
        </table>
        }
      </div>
      <div
        [ngClass]="customclassforoption"
        class="table-responsive col-lg-6 col-md-10 col-12 scrollable-table optionput-table"
      >
        @if (!this.productId) {
        <app-financial-table
          [columns]="tableOptionData"
          [data]="optionDataPuts"
        >
        </app-financial-table>
        }@else {
        <table class="table align-middle mb-0 bg-white">
          <thead>
            <tr>
              <th
                class="text-uppercase"
                style="border-bottom: 1px solid whitesmoke; text-align: center"
                colspan="4"
              >
                Put
              </th>
            </tr>
            <tr class="text-center text-uppercase table-header">
              <th>EXPIRATION</th>
              <th>Symbol</th>
              <th>Total Volume</th>
              <th>Total Interest</th>
            </tr>
          </thead>
          <tbody>
            @for(item of optionDataPuts; track $index){
            <tr class="text-center">
              <td class="tablefontsize">{{ item.date }}</td>
              <td class="tablefontsize">{{ item.symbol }}</td>
              <td class="tablefontsize">{{ item.totalVolume }}</td>
              <td class="tablefontsize">{{ item.totalopenInterest }}</td>
            </tr>
            }@empty { No Record Found }
          </tbody>
        </table>
        }
      </div>
    </div>
    } @if (newsItems.length > 0) {

    <h2 #newsSection class="my-2">News</h2>

    <div [ngClass]="customclassfornews" class="">
      @for (item of newsItems; track $index) {
      <div class="">
        <div class="col-md-12">
          <div class="outer-main small-single-sec layout-right">
            <h6 class="head-text">{{ item.title }}</h6>
            <div class="d-flex align-items-center stock-details">
              <div class="dateText mb-0 me-2">
                <span class="me-2">
                  <b> {{ item.published }}</b></span
                >
                <span>[ {{ item.type | titlecase }} ]</span>
              </div>
              <div class="tag-btn mrgn-lft-12 me-2">
                @if (item.ticker) {
                <a
                  class="link-ticker most-value-btn"
                  routerLink="/company/{{ item.ticker }}"
                >
                  {{ item.ticker }} </a
                >}
              </div>
              <div class="d-flex">
                <p
                  class="company-plus-minus me-2 mb-0 d-flex align-items-center"
                  [style.color]="item.close | riseAndFallColor"
                  style="font-size: 13px"
                >
                  {{ item.close | number : "1.0-2" }}
                </p>
                <p
                  class="company-plus-minus mb-0 d-flex align-items-center"
                  [style.color]="item.change | riseAndFallColor"
                  style="font-size: 13px"
                >
                  ({{ item.change | number : "1.0-2" }}%)
                </p>
              </div>
            </div>
            @if (item.content) {
            <p class="mb-0" style="font-size: 13px">
              {{ item.content[1] | slice : 0 : 500 }}...
              <span (click)="openpopup(item)" class="text-danger cursorpointer">
                <b>Read more+</b>
              </span>
            </p>

            }@else {
            <p>-</p>
            }
          </div>
        </div>
        <hr class="" />
      </div>
      }
    </div>
    }
  </div>
  } @if (emptyView) {
  <div class="d-flex justify-content-center align-items-center">
    <h5 class="text-center">Portfolio is empty.</h5>
  </div>
  }

  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div style="background-color: #3b2cc6 !important" class="modal-header">
          <h1 class="modal-title text-light fs-5" id="exampleModalLabel">
            Add to Portfolio
          </h1>
          <i
            class="fa-solid fa-xmark cursorpointer"
            data-bs-dismiss="modal"
            style="opacity: inherit; color: #fff7d7"
          ></i>
        </div>
        <!-- <div class="modal-body">
          <div style="display: grid;" class="position-relative mb-3">
            <div class="align-items-center justify-content-center">
              <form class="example">
                <label for=""><b>Symbol</b></label>
                <input autocomplete="off" (keyup)="searchFilters($event)" (input)="onSearchInput($event)"
                  [(ngModel)]="searchValuequery" #searchVal type="search" class="form-control my-2"
                  placeholder="Search by Comapny name or Ticker" name="search2">
                @if(suggection?.length > 0){
                <div class="">
                  <ul class="list-unstyled m-0">
                    @for (item of suggection; track $index) {
                    <li class="suggestion-item  p-0 cursorpointer">
                      <div (click)="addsuggection(item.symbol)"
                        class="d-flex filter-bottom justify-content-between px-2">
                        <p class="text-start text-dark filter-font-text mb-1 border-1 me-4">{{ item.name }}</p>
                        <p class="text-end text-dark filter-font-text mb-1">{{ item.symbol }}</p>
                      </div>
                    </li>
                    }@empty {
                    No Record Found
                    }
                  </ul>
                </div>
                }
                <div class="form-group mb-2">
                  <label for="cost ">Cost</label>
                  <input [(ngModel)]="portfoliocost" type="number" class="form-control" id="cost">
                </div>
                <div class="form-group mb-2">
                  <label for="quantity ">Quantity</label>
                  <input [(ngModel)]="portfolioquntity" type="number" class="form-control" id="quantity">
                </div>
                <div class="form-group ">
                  <label for="total">Total amount</label>
                  @if (portfoliocost && portfolioquntity) {
                  <p class="m-0" id="total"><strong>{{ portfoliocost * portfolioquntity }} $</strong></p>
                  }
                </div>
              </form>
            </div>

          </div>
        </div> -->
        <div class="modal-body">
          <label for=""><b>Symbol</b></label>
          <input
            autocomplete="off"
            (keyup)="searchFilters($event)"
            (input)="onSearchInput($event)"
            [(ngModel)]="searchValuequery"
            #searchVal
            type="search"
            class="form-control my-2"
            placeholder="Search by Comapny name or Ticker"
            name="search2"
          />
          @if(suggection?.length > 0){
          <div class="">
            <ul class="list-unstyled m-0">
              @for (item of suggection; track $index) {
              <li class="suggestion-item p-0 cursorpointer">
                <div
                  (click)="addsuggection(item.symbol)"
                  class="d-flex filter-bottom justify-content-between px-2"
                >
                  <span>
                    @if (item.logoid !== '') {
                    <img width="25px" [src]="basePath + item.logoid + '.svg'" />
                    }@else {
                    <span
                      class="avatarcss"
                      style="
                        width: 25px !important;
                        height: 25px !important;
                        margin: 0 !important;
                      "
                      >{{ item.name | initialLetter }}</span
                    >
                    }
                  </span>
                  <p
                    class="text-start text-dark filter-font-text mb-1 border-1 me-4"
                  >
                    {{ item.name }}
                  </p>
                  <p class="text-end text-dark filter-font-text mb-1">
                    {{ item.symbol }}
                  </p>
                </div>
              </li>
              }@empty { No Record Found }
            </ul>
          </div>
          }
          <div class="form-group mb-2">
            <label for="cost ">Cost</label>
            <input
              [(ngModel)]="portfoliocost"
              type="number"
              class="form-control"
              id="cost"
              placeholder="Enter cost (optional)"
            />
          </div>
          <div class="form-group mb-2">
            <label for="quantity ">Quantity</label>
            <input
              [(ngModel)]="portfolioquntity"
              type="number"
              class="form-control"
              id="quantity"
              placeholder="Enter quantity (optional)"
            />
          </div>
          <div class="form-group">
            <label for="total">Total amount</label>
            @if (portfoliocost && portfolioquntity) {
            <p class="m-0" id="total">
              <strong>{{ portfoliocost * portfolioquntity }} $</strong>
            </p>
            }
          </div>
        </div>
        <div class="modal-footer">
          <button
            (click)="addSymboltoPotfoliyo()"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            class="addportfoiliobtn"
          >
            ADD
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- news popup   -->

  <div
    class="modal"
    tabindex="-1"
    role="dialog"
    [ngStyle]="{ display: displayStyle }"
  >
    <div
      class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title d-flex align-items-center text-light">
            Description
          </h4>
          <div class="d-flex align-items-center">
            <button type="button" class="btn" (click)="closePopup()">
              <i
                class="fa-solid fa-xmark text-white"
                style="opacity: unset"
              ></i>
            </button>
          </div>
        </div>
        <div class="modal-body">
          @if (newsItem!=null) {
          <h4 class="">{{ newsItem.title }}</h4>
          <div class="dateText mb-1">
            <div class="d-flex align-items-center">
              <span class="me-1"
                ><b style="font-size: 13px">{{
                  transformDate(newsItem.published)
                }}</b></span
              >
              @if (newsItem.ticker) {
              <a
                class="link-ticker most-value-btn me-1"
                style="font-size: 13px"
                routerLink="/company/{{ newsItem.ticker }}"
              >
                {{ newsItem.ticker }} </a
              >}

              <p
                class="company-plus-minus me-2 mb-0 d-flex align-items-center me-1"
                [style.color]="newsItem.close | riseAndFallColor"
                style="font-size: 13px"
              >
                {{ newsItem.close | number : "1.0-2" }}
              </p>
              <p
                class="company-plus-minus mb-0 d-flex align-items-center"
                [style.color]="newsItem.change | riseAndFallColor"
                style="font-size: 13px"
              >
                ({{ newsItem.change | number : "1.0-2" }}%)
              </p>
            </div>
          </div>
          @for (item of newsItem.content; track $index) {
          <p>{{ item }}</p>
          }}
        </div>
      </div>
    </div>
  </div>
</div>
