import { Component, ViewEncapsulation } from '@angular/core';
import { HitmapService } from '../../../shared/service/hitmap.service';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import HighchartsTreemap from 'highcharts/modules/treemap';
// import { CompanyDetailsService } from '../../shared/service/company-details.service';
import { Router } from '@angular/router';
import { DecryptionService } from 'src/app/modules/shared/service/decryption.service';
import { DollarsCoversionPipe } from 'src/app/modules/shared/pipes/dollars-coversion.pipe';
const dollarsCoversion = new DollarsCoversionPipe();

@Component({
  selector: 'app-hitmap',
  templateUrl: './hitmap.component.html',
  styleUrls: ['./hitmap.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HitmapComponent {
  constructor(
    private HitmapService: HitmapService,
    private router: Router,
    private decryptionService: DecryptionService
  ) {}
  marketCapVal = 'small-cap';
  bySecVal = '';
  modalDisplayStyle!: boolean;
  loading = false;
  today = Date.now();
  filterArray: any = [];
  chartOptions: Highcharts.Options | any = {
    title: {
      text: '',
    },

    colorAxis: {
      minColor: '#75DA8B',
      maxColor: '#089981',
    },
    tooltip: {
      formatter: function (this: any) {
        return `<b>${this.point?.comapnyName}<br/><span style="color: green; margin-top: 5px;">${this.point?.market_cap_basic}</span></b>`;
      },
    },
    series: [
      {
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        clip: false,
        data: [],
        dataLabels: {
          enabled: true,
          style: {
            color: 'white',
            textShadow: 'none',
            textOutline: 'none',
          },
        },
      },
    ],
  };

  chartOptionsn: Highcharts.Options | any = {
    title: {
      text: '',
    },
    colorAxis: {
      minColor: '#f33948',
      maxColor: '#E44236',
    },
    tooltip: {
      formatter: function (this: any) {
        return `<b>${this.point?.comapnyName}<br/><span style="color: red; margin-top: 5px;">MV ${this.point?.market_cap_basic}</span></b>`;
      },
    },
    series: [
      {
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        clip: false,
        data: [],
        dataLabels: {
          enabled: true,
          style: {
            color: 'white',
            textShadow: 'none',
            textOutline: 'none',
          },
        },
      },
    ],
  };

  ngOnInit(): void {
    
    HighchartsMore(Highcharts);
    HighchartsHeatmap(Highcharts);
    HighchartsTreemap(Highcharts);
    this.hitmap();
  }

  hitmap() {
    
    
    const data = {
      market_value: this.marketCapVal,
      Industry: this.filterArray,
    };
    
    if (Array.isArray(data.Industry) && data.Industry.length === 0) {
      
      delete data.Industry;
      this.HitmapService.HitmapWIndustry(data).subscribe({
        next: (res: any) => {
  
          const decryptData = this.decryptionService.decrypt(res.encrypted);
          const ParseDecryptedData = JSON.parse(decryptData);

          this.chartOptionsn.series[0].data = ParseDecryptedData.filter(
            (item: any) => item.change < 0
          ).map((item: any) => ({
            name: `${
              item.symbol
            }<br> <span style="font-size: small;">${item.change.toFixed(
              2
            )}%</span>`,
            comapnyName: item.name,
            market_cap_basic: dollarsCoversion.transform(item.market_cap_basic),
            value: Number(Math.abs(item.change).toFixed(2)),
            colorValue: Number(Math.abs(item.change).toFixed(2)),
          }));

          this.chartOptions.series[0].data = ParseDecryptedData.map(
            (item: any, index: number) => ({
              name: `${
                item.symbol
              }<br> <span style="font-size: small;">${item.change.toFixed(
                2
              )}%</span>`,
              comapnyName: item.name,
              market_cap_basic: dollarsCoversion.transform(
                item.market_cap_basic
              ),

              value: Number(item.change.toFixed(2)),
              colorValue: Number(item.change.toFixed(2)),
            })
          );



          Highcharts.chart('containerN', this.chartOptionsn);
          Highcharts.chart('container', this.chartOptions);
        },
      });
      return;
    }

    this.HitmapService.Hitmap(data).subscribe({
      
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.encrypted);
        const ParseDecryptedData = JSON.parse(decryptData);

        this.chartOptionsn.series[0].data = ParseDecryptedData.filter(
          (item: any) => item.change < 0
        ).map((item: any) => ({
          name: `${
            item.symbol
          }<br> <span style="font-size: small;">${item.change.toFixed(
            2
          )}%</span>`,
          comapnyName: item.name,
          market_cap_basic: dollarsCoversion.transform(item.market_cap_basic),

          value: Number(Math.abs(item.change).toFixed(2)),
          colorValue: Number(Math.abs(item.change).toFixed(2)),
        }));

        this.chartOptions.series[0].data = ParseDecryptedData.map(
          (item: any, index: number) => ({
            name: `${
              item.symbol
            }<br> <span style="font-size: small;">${item.change.toFixed(
              2
            )}%</span>`,
            comapnyName: item.name,
            market_cap_basic: dollarsCoversion.transform(item.market_cap_basic),

            value: Number(item.change.toFixed(2)),
            colorValue: Number(item.change.toFixed(2)),
          })
        );

        this.loading = false;


        Highcharts.chart('containerN', this.chartOptionsn);
        Highcharts.chart('container', this.chartOptions);
      },
    });

  }

  GetChildData(data: any) {
    this.modalDisplayStyle = data;
  }

  selectedCap(data: string) {
    let loginuser = localStorage.getItem('loggedInUser');

    if (!loginuser) {
      this.modalDisplayStyle = true;
      return;
    }
    if (this.bySecVal === data) {
      this.bySecVal = '';
      this.filterArray = [];
    } else {
      switch (data) {
        case 'Biotechnology': {
          const Biotechnology = [
            'Biotechnology',
            'Tobacco',
            'Real Estate Services',
            'Chemicals',
            ' N/A',
            'Farm Products',
            'Food Distribution',
            'Packaged Foods',
          ];
          this.bySecVal = 'Biotechnology';
          this.filterArray = Biotechnology;
          break;
        }
        case 'Medical Device': {
          const Medical_Device = [
            'Medical Devices',
            'Medical Instruments & Supplies',
            'Electronic Components',
            'Scientific & Technical Instruments',
            'Health Information Services',
            'Medical Care Facilities',
            'Furnishings',
            'Fixtures & Appliances',
            'Medical Distribution',
          ];
          this.bySecVal = 'Medical Device';
          this.filterArray = Medical_Device;
          break;
        }
        case 'Diagnostics & Research': {
          const Diagnostics = ['Diagnostics & Research'];
          this.bySecVal = 'Diagnostics & Research';
          this.filterArray = Diagnostics;
          break;
        }
        case 'Drug Manufacturers': {
          const Drug_Manufacturers = [
            'Drug Manufacturers—General',
            'Drug Manufacturers—Specialty & Generic',
            'Household & Personal Products,Pharmaceutical Retailers',
          ];
          this.bySecVal = 'Drug Manufacturers';
          this.filterArray = Drug_Manufacturers;
          break;
        }
      }
    }
    switch (data) {
      case 'small-cap': {
        this.marketCapVal = 'small-cap';
        break;
      }
      case 'mid-cap': {
        this.marketCapVal = 'mid-cap';
        break;
      }
      case 'micro-cap': {
        this.marketCapVal = 'micro-cap';
        break;
      }
      case 'large-cap': {
        this.marketCapVal = 'large-cap';
        break;
      }
      case 'mega-cap': {
        this.marketCapVal = 'mega-cap';
        break;
      }
    }
    this.hitmap();
  }
}
