<!-- <div class="background p-3">
  <div class="container">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-7 col-md-7 col-sm-12">
        <div class="footer-container">
          <div class="footer-image">
            <img class="img-height" src="assets/images/email.svg" alt="" />
          </div>
          <div class="footer-text">
            <h5 class="heading">Get the latest news and offers</h5>
            <h4 class="subhead mb-0">Subscribe to our newsletter</h4>
          </div>
        </div>
      </div>

     
    </div>
  </div>
</div> -->

<!---- second footer ----->

<!-- <section class="footers-2">
  <div class="container">
    <div class="row">
      <div class="mt-4">
        <div class="col d-flex justify-content-center">
          <div class="footer-tab">
            <img src="assets/images/footer-client.webp" alt="" class="footer-img-left" />
          </div>
          <img class="phone-z" src="assets/images/phone.svg" alt="" />
          <div class="footer-img-right footer-tab d-flex align-items-center justify-content-center">
            <div class="text-center">
              <h5 class="">Consult with our expert</h5>
              <h4 class="mb-0"><b>90-500-28-999</b></h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<!--logo footer-->
<!-- <footer class="footer-div">
  <div class="d-flex align-items-center justify-content-between p-2  main-footer-div">

    <img class="footer-logo-size ps-2" src="assets/images/footerlogo.svg" alt="" />


    
    <section class="about">
      <div class="container d-flex justify-content-evenly footer-set-space">
        <ul class="list-inline m-0 ">
          <li class="list-inline-item border-r m-0"><a routerLink="/about">About</a></li>
          <li class="list-inline-item border-r m-0"><a routerLink="/contact-us">Contact Us</a></li>
          <li class="list-inline-item"><a routerLink="/legal-about">Legal About</a></li>
        </ul>
      </div>
    </section>




    <div class="col-lg-4 col-md-12 col-sm-12">

      <div class="btn-input">
        <input class="footer-form-input" placeholder="Your email..." type="email" #email [formControl]="emailControl"
          required />
        <button (click)="getLinkId(emailControl.value)" class="footer-form-button">Subscribe</button>
      </div>


    </div>

    <section class="app my-2 pe-2">
      <div class="container d-flex justify-content-evenly">
        <ul class="list-inline m-0">
          <li class="list-inline-item">
            <a> <img src="assets/images/facebook.svg" alt="" /></a>
          </li>
          <li class="list-inline-item">
            <a> <img src="assets/images/instagram.svg" alt="" /></a>
          </li>
          <li class="list-inline-item">
            <a href="https://twitter.com/Biopharma_Watch" t rget="_blank" class="cursorpointer"> <img
                src="assets/images/twitterx.svg" alt="" /></a>
          </li>
          <li class="list-inline-item">
            <a class="cursorpointer" href="https://www.linkedin.com/company/biopharmawatch-com/" target="_blank"> <img
                src="assets/images/linkdin.svg" alt="" /></a>
          </li>
          <li class="list-inline-item">
            <a class="cursorpointer" href="https://discord.gg/CCvg8kCV" target="_blank"> <img
                src="assets/images/discord.png" alt="" /></a>
          </li>
        </ul>
      </div>
    </section>



  </div>

  
  <div class="line"></div>



  <section class="copyright">
    <div class="container">
      <div class="d-flex justify-content-center">
        <p class="copy mt-3 mb-3">Copyright@BIOPHARMAWATCH-2020</p>
      </div>
    </div>
  </section>
</footer> -->

<footer class="footers-2">
  <div class="text-start">
    <div (click)="hideshowfooter()" class="cursorpointer d-flex">
      @if(!hidefooter){
      <img
        ngbTooltip="Show footer"
        placement="right"
        width="17px"
        class="ms-4"
        src="assets/images/Uparrowfooter.svg"
        alt=""
      />
      <span
        style="font-size: 12px"
        class="text-light hide-show-footer d-none mx-2"
        >Show</span
      >
      }@else {
      <img
        ngbTooltip="Hide footer"
        placement="right"
        width="17px"
        class="ms-4"
        src="assets/images/downarrowfooter.svg"
        alt=""
      />
      <span
        style="font-size: 12px"
        class="text-light hide-show-footer d-none mx-2"
        >Hide</span
      >
      }
    </div>
  </div>
  @if(hidefooter){
  <div class="container-fluid">
    <div class="row justify-content-evenly footer-box">
      <div class="col-lg-4 col-md-12">
        <div>
          <div
            class="d-flex logo_social_icon_div justify-content-center align-items-center"
          >
            <div class="d-flex justify-content-center footer-logo_div ms-3">
              <a routerLink="home"
                ><img
                  routerLink="home"
                  class="footer-logo"
                  src="assets/images/footerlogo.svg"
                  alt=""
              /></a>
            </div>
            <div class="d-flex justify-content-start footer-social ms-5">
              <a
                href="https://www.instagram.com/biopharmawatch/"
                target="_blank"
                class="footer-icon-space"
              >
                <img
                  src="assets/images/instagramlogo.svg"
                  alt=""
                  class="footer-social-icon"
                />
              </a>
              <a
                href="https://stocktwits.com/BiopharmaWatch_com"
                target="_blank"
                class="footer-icon-space"
              >
                <img
                  src="assets/images/Stockwithlogo.svg"
                  alt=""
                  class="footer-social-icon"
                />
              </a>
              <a class="footer-icon-space">
                <img
                  src="assets/images/Facebooklogo.svg"
                  alt=""
                  class="footer-social-icon"
                />
              </a>
              <a
                class="footer-icon-space"
                href="https://www.linkedin.com/company/biopharmawatch-com/"
                target="_blank"
              >
                <img
                  src="assets/images/Iinkinlogo.svg"
                  alt=""
                  class="footer-social-icon"
                />
              </a>
              <a
                class="footer-icon-space"
                href="https://twitter.com/Biopharma_Watch"
                target="_blank"
              >
                <img
                  src="assets/images/twitterlogo.svg"
                  alt=""
                  class="footer-social-icon"
                />
              </a>
              <a
                class="footer-icon-space"
                href="https://discord.gg/vbr3w4nwx5"
                target="_blank"
              >
                <img
                  src="assets/images/discordlogo.svg"
                  alt=""
                  class="footer-social-icon"
                />
              </a>
            </div>
          </div>
          <div class="footer-grid-part mt-2">
            <div class="footer-btn-input mt-1">
              <input
                class="footer-form-input"
                placeholder="Your email..."
                type="email"
                #email
                [formControl]="emailControl"
                required
              />
              <button
                (click)="getLinkId(emailControl.value)"
                class="footer-form-button"
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 footer-route">
        <div class="row footer-set-space">
          <div class="col-3">
            <ul class="list-unstyled">
              <li class="mb-2">
                <a routerLink="/about" class="text-white footer-sub-link"
                  >About</a
                >
              </li>
              <li class="mb-2">
                <a routerLink="/contact-us" class="text-white footer-sub-link"
                  >Contact Us</a
                >
              </li>
              <li class="mb-2">
                <a routerLink="/legal-about" class="text-white footer-sub-link"
                  >Legal About</a
                >
              </li>
            </ul>
          </div>
          <div class="col-3">
            <ul class="list-unstyled">
              <li class="mb-2">
                <a
                  routerLink="/catalyst-sync"
                  class="text-white footer-sub-link"
                  >Catalyst Calendar</a
                >
              </li>
              <li class="mb-2">
                <a
                  routerLink="/company-screener"
                  class="text-white footer-sub-link"
                  >Company Screener</a
                >
              </li>
              <li class="mb-2">
                <a routerLink="/fda-calendar" class="text-white footer-sub-link"
                  >FDA Calendar</a
                >
              </li>
            </ul>
          </div>
          <div class="col-3">
            <ul class="list-unstyled">
              <li class="mb-2">
                <a routerLink="/blog" class="text-white footer-sub-link"
                  >Blogs</a
                >
              </li>
              <li class="mb-2 blog-set">
                <a
                  routerLink="/biotechearnings"
                  class="text-white footer-sub-link"
                  >Biotech Earning</a
                >
              </li>
              <li class="mb-2">
                <a
                  routerLink="/privacy-policy"
                  class="text-white footer-sub-link"
                  >Privacy Policy</a
                >
              </li>
            </ul>
          </div>
          <div class="col-3">
            <ul class="list-unstyled">
              <li class="mb-2">
                <a
                  routerLink="/Terms-service"
                  class="text-white footer-sub-link"
                  >Terms of Service</a
                >
              </li>
              <li class="mb-2">
                <a routerLink="/website-user" class="text-white footer-sub-link"
                  >User Guide</a
                >
              </li>
              <li class="mb-2">
                <a routerLink="/advertise" class="text-white footer-sub-link"
                  >Advertise</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="line"></div>
  }
  <!-- <div class="container">
    <div class="d-flex justify-content-between align-items-center py-1">
      <p class="text-white m-0">#</p>
      <p class="text-white m-0">A</p>
      <p class="text-white m-0">B</p>
      <p class="text-white m-0">C</p>
      <p class="text-white m-0">D</p>
      <p class="text-white m-0">E</p>
      <p class="text-white m-0">F</p>
      <p class="text-white m-0">G</p>
      <p class="text-white m-0">H</p>
      <p class="text-white m-0">I</p>
      <p class="text-white m-0">J</p>
      <p class="text-white m-0">K</p>
      <p class="text-white m-0">L</p>
      <p class="text-white m-0">M</p>
      <p class="text-white m-0">N</p>
      <p class="text-white m-0">O</p>
      <p class="text-white m-0">P</p>
      <p class="text-white m-0">Q</p>
      <p class="text-white m-0">R</p>
      <p class="text-white m-0">S</p>
      <p class="text-white m-0">T</p>
      <p class="text-white m-0">U</p>
      <p class="text-white m-0">V</p>
      <p class="text-white m-0">W</p>
      <p class="text-white m-0">X</p>
      <p class="text-white m-0">Y</p>
      <p class="text-white m-0">Z</p>
    </div>
  </div> -->
</footer>
