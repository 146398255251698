<div class="volatile">
  <div class="container-fluid p-2 fda-header-box">
    <div class="text-center fda-text">
      <h3 class="main-heading text-white m-0 me-3">Upcoming IPOs</h3>

      <p class="sub-heading text-white m-0">
        Upcoming IPO dates, offering a detailed IPO timeline and insights.
      </p>
    </div>
  </div>
</div>

<section class="volatile">
  <div
    class="table-responsive scrollable-table1"
    id="dataContainer"
    #dataContainer
  >
    <table class="table align-middle mb-0 bg-white">
      <thead class="text-center">
        <tr class="text-uppercase table-header">
          <th class="table-name-size">company</th>
          <th class="table-name-size">industry</th>
          <th class="table-name-size">shares</th>
          <th class="table-name-size">price low</th>
          <th class="table-name-size">price high</th>
          <th class="table-name-size">est_$_vol</th>
          <th class="table-name-size">expected to trade</th>
          <th class="table-name-size">status</th>
        </tr>
      </thead>
      <tbody id="tbody" class="text-center">
        @for(i of upcomingIPOdata;track $index){
        <tr class="table-tr-font">
          <td class="table-font-size" scope="row" style="z-index: 1">
            {{ i.symbol }} <br />
            {{ i.company }}
          </td>
          <td class="table-font-size" scope="row" style="z-index: 1">
            {{ i.industry }}
          </td>
          <td class="table-font-size" scope="row" style="z-index: 1">
            {{ i["shares_(millions)"] }} M
          </td>
          <td class="table-font-size" scope="row" style="z-index: 1">
            {{ i.price_low }}
          </td>
          <td class="table-font-size" scope="row" style="z-index: 1">
            {{ i.price_high }}
          </td>
          <td class="table-font-size" scope="row" style="z-index: 1">
            {{ i["est_$_vol_(millions)"] }} M
          </td>
          <td class="table-font-size" scope="row" style="z-index: 1">
            {{ i["expected_to_trade"] | date : "dd/MM/yyyy" }}
          </td>
          <td class="table-font-size" scope="row" style="z-index: 1">
            {{ i.status }}
          </td>
        </tr>
        }
      </tbody>
      <tfoot></tfoot>
    </table>
  </div>
</section>
