import { Injectable } from '@angular/core';
import { companyDetail } from '../models/company-details';
import { environment } from 'src/environments/environment';
import {
  BehaviorSubject,
  Observable,
  catchError,
  map,
  tap,
  throwError,
} from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  getoptionIvdata,
  getCompanyInsidetradesData,
  getCompanynewsData,
  getCompanyTabledata,
  getCompanyTablePipelinedata,
  getcompanyupcomingdata,
  gethedgedata,
  getoptiondata,
  searchsuggection,
  getcatalystresult,
} from '../const';

@Injectable({
  providedIn: 'root',
})
export class CompanyDetailsService {
  myObject: any;
  myData: any;
  ticker!: any;

  constructor(private http: HttpClient) {}

  companydata: BehaviorSubject<companyDetail> = new BehaviorSubject<any>(null);
  currentMessage = this.companydata.asObservable();

  getCompanyTable(ticker: any) {
    return this.http
      .get<companyDetail[]>(`${getCompanyTabledata}${ticker.toUpperCase()}`)
      .pipe(
        tap((response: any) => {}),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error.error.message);
        })
      );
  }

  getCompanyPipelineData(ticker: any) {
    return this.http
      .get<companyDetail[]>(
        `${getCompanyTablePipelinedata}${ticker.toUpperCase()}`
      )
      .pipe(
        tap((response: any) => {}),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error.error.message);
        })
      );
  }
  getCompanyInsidetradesData(ticker: any) {
    return this.http
      .get<companyDetail[]>(
        `${getCompanyInsidetradesData}${ticker.toUpperCase()}`
      )
      .pipe(
        tap((response: any) => {}),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error.error.message);
        })
      );
  }
  getcatalsytresult(ticker: any) {
    return this.http
      .get<companyDetail[]>(
        `${getcatalystresult}${ticker.toUpperCase()}`
      )
      .pipe(
        tap((response: any) => {}),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error.error.message);
        })
      );
  }

  getcompanyupcomingdata(ticker: any) {
    return this.http
      .get<companyDetail[]>(`${getcompanyupcomingdata}${ticker.toUpperCase()}`)
      .pipe(
        tap((response: any) => {}),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error.error.message);
        })
      );
  }
  gethedgedata(ticker: any) {
    return this.http
      .get<companyDetail[]>(`${gethedgedata}${ticker.toUpperCase()}`)
      .pipe(
        tap((response: any) => {}),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error.error.message);
        })
      );
  }

  getoptiondata(ticker: any) {
    return this.http
      .get<companyDetail[]>(`${getoptiondata}${ticker.toUpperCase()}`)
      .pipe(
        tap((response: any) => {}),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error.error.message);
        })
      );
  }

  getoptionsummaryIvdata(ticker: any) {
    return this.http
      .get<companyDetail[]>(`${getoptionIvdata}${ticker.toUpperCase()}`)
      .pipe(
        tap((response: any) => {}),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error.error.message);
        })
      );
  }
  searchsuggection(data: any) {
    return this.http.get<companyDetail[]>(`${searchsuggection}${data}`);
  }
  getCompanyNewsData(ticker: any) {
    return this.http
      .get<companyDetail[]>(`${getCompanynewsData}${ticker.toUpperCase()}`)
      .pipe(
        tap((response: any) => {}),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error.error.message);
        })
      );
  }

  getCompanyTicker(ticker: any) {
    localStorage.setItem('myTicker', ticker);
  }
}
