import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, throwError } from 'rxjs';
import { votting_price, votting_priceTarget } from '../const';
@Injectable({
  providedIn: 'root'
})
export class VottingPriceService {

  constructor(private http: HttpClient) { }

  votting_price(userid: any, ticker: any, vote: any) {
    const body = { userid, ticker, vote };
    return this.http.post(`${votting_price}`, body)
  }
  votting_priceTarget(userid: any, ticker: any, price: any, vote: any) {
    const body = { userid, ticker, price, vote };
    return this.http.post(`${votting_priceTarget}`, body)
  }

}
