import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'infinityNumber'
})
export class InfinityNumberPipe implements PipeTransform {
  transform(value: string | number): string | number {
    if (Number(value) == Infinity || null) {
      return '-';
    }
    let Num = Number(value).toFixed(2);
    return Num;
  }

}
