import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstthirtyword',
})
export class FirstthirtywordPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '-';
    }

    // const newword = value[0];
    const words = value.substring(0, 80);
    return words;
  }
}
