<div class="container-fluid blog-head-bg">
  <div
    class="row portfolio-head p-3 d-flex justify-content-center align-items-center"
  >
    <div class="col-12 d-flex justify-content-center">
      <div class="title-shape mb-0">
        <h1 class="blog-header text-white m-0">Blogs</h1>
      </div>
    </div>
  </div>
</div>

<div class="container mt-3"></div>

<div class="container-fluid mt-4">
  <div class="d-flex justify-content-center blog-main-box">
    @for (blog of getPaginatedBlogs(); track $index) {

    <ng-container>
      <div
        class="blog-box m-4"
        (click)="onuserClick(blog.blogurl)"
        style="cursor: pointer"
      >
        <div class="blog-back-img">
          <img [src]="blog.thumb" class="blog-all-size" alt="" />
        </div>
        <div class="container-fluid">
          <div
            class="d-flex justify-content-start align-items-center m-2 blog-name"
          >
            <p class="m-0 blog-admin-date">
              {{ blog.date | date : "longDate" }}
            </p>
          </div>
          <div class="mt-2">
            <!-- <p class="m-0 box-blue-text">{{blog.expert}}</p> -->
            <p class="m-0 mt-2 box-head">{{ blog.title }}</p>
            <p class="m-0 mt-3 box-sub">
              {{ blog.intro | slice : 0 : 200 }}...
              <span class="readmorebtn"><u>Read More</u></span>
            </p>
          </div>
        </div>
      </div>
    </ng-container>

    }@empty {
    <div class="container">
      <ngx-skeleton-loader
        [theme]="{
          height: '600px',
          'border-radius': '4px',
          'margin-top': '20px'
        }"
        count="1"
      >
      </ngx-skeleton-loader>
    </div>
    }
  </div>
</div>

<div class="d-flex justify-content-center mb-3">
  <button class="arrow-button" (click)="previousPage()">
    <img src="/assets/images/blog-left.svg" alt="Previous" class="arrow-size" />
  </button>

  @for (page of getPageNumbers(); track $index) {
  <ng-container>
    <button
      class="page-button"
      [class.active]="page === currentPage"
      (click)="goToPage(page)"
    >
      {{ page }}
    </button>
  </ng-container>
  }

  <button class="arrow-button" (click)="nextPage()">
    <img src="/assets/images/blog-right.svg" alt="Next" class="arrow-size" />
  </button>
</div>
