import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LoginService } from '../../../core/services/login.service';
import { environment } from 'src/environments/environment';
import { CompanyDetailsService } from 'src/app/modules/shared/service/company-details.service';
import { filter } from 'rxjs/operators';
import { DecryptionService } from '../../service/decryption.service';
import { WebsocketsService } from '../../service/websockets.service';
import { FdaCalenarService } from '../../service/fda-calendar.service';
import { jwtDecode } from 'jwt-decode';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public isCollapsed = true;
  isScrolled = false;
  ScreenerActive: boolean = false;
  userActive: boolean = false;
  blogsActive: boolean = false;
  newsActive: boolean = false;
  heatActive: boolean = false;
  CatalystActive: boolean = false;
  hedgeActive: boolean = false;
  adminActive: boolean = false;
  activeRoutehedge: string = 'assets/images/hedge-active-tab.svg';
  activeRouteCalendar: string = 'assets/images/sync-tab-active.svg';
  activeRouteScreener: string = 'assets/images/screener-active-tab.svg';
  activeRouteblog: string = 'assets/images/blog-active-tab.svg';
  activeRoutenews: string = 'assets/images/news-active-tab.svg';
  activeRouteuser: string = 'assets/images/user-active-tab.svg';
  activeRouteheat: string = 'assets/images/heat-map_non-hover.png';
  profileSue: String = 'assets/images/profile-archer.svg';
  searchValuequery: any;
  isFilterBtnSearch = false;
  suggection: any;
  symbol!: string;
  hidequary: any;
  isSearchVisible: boolean = true;
  showinputfield: boolean = false;
  basePath: string = 'https://s3-symbol-logo.tradingview.com/';

  notificationRecords: any = [];
  notificationLen: any;
  isProductId: boolean = false;
  clientUrl = environment.clientUrl;
  role: any;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private compamyDetails: CompanyDetailsService,
    private decryptionService: DecryptionService,
    private websocketService: WebsocketsService,
    private fdaService: FdaCalenarService
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.isSearchVisible = this.shouldShowSearch(event.url);
        this.getUpdates();
      });
  }

  shouldShowSearch(url: string): boolean {
    return url.includes('company/');
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateActiveRouteImage(event.urlAfterRedirects);
      }
    });

    this.loginService.roleSub.subscribe((res) => {
      this.role = res;
    });

    // let token = localStorage.getItem('accessToken');
    // if (token) {
    //   const decodedToken: any = jwtDecode(token);
    //   this.role = decodedToken.role;
    // }

    // this.getNotificationlength();

    this.websocketService.listenForNewData().subscribe((data: any) => {
      // this.notificationLen = data;
      data == 1 ? this.getUpdates() : null;
    });
  }

  getUpdates() {
    this.fdaService.getFdaUpdate().subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);
        if (!JSON.parse(decryptData).productId) {
          this.notificationRecords = JSON.parse(decryptData).data[0];
        } else {
          this.isProductId = true;
          this.notificationRecords = JSON.parse(decryptData).data;
          // this.notificationLen = this.notificationRecords.length;
        }
        this.getNotificationlength();
      },
    });
  }

  getNotificationlength() {
    this.fdaService.getNotificationlen().subscribe({
      next: (res: any) => {
        this.notificationLen = res.notificationLen;
      },
    });
  }

  getNotificationlenUpdate() {
    this.fdaService
      .getNotificationlenUpdate({
        notifications: 0,
      })
      .subscribe({
        next: (res: any) => {
          this.notificationLen = res.notifications;
        },
      });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.isScrolled = window.scrollY > 0;
  }

  toggleMenu() {
    this.isCollapsed = !this.isCollapsed;
  }

  getData($event: any) {
    this.hidequary = $event;
  }

  closeMenu() {
    let href = this.router.url;
    localStorage.setItem('route', href);
    this.isCollapsed = true;
  }

  loggedin() {
    return localStorage.getItem('loggedInUser');
  }

  onLogout() {
    window.location.href = this.router.url;
    this.loginService.logout();
    this.closeMenu();
  }

  updateActiveRouteImage(url: string): void {
    if (url.includes('fda-calendar')) {
      this.activeRouteCalendar = 'assets/images/sync-tab-active.svg';
      this.CatalystActive = true;
    } else if (url.includes('biotechearnings')) {
      this.activeRouteCalendar = 'assets/images/sync-tab-active.svg';
      this.CatalystActive = true;
    } else if (url.includes('PDUFA-calendar')) {
      this.activeRouteCalendar = 'assets/images/sync-tab-active.svg';
      this.CatalystActive = true;
    } else if (url.includes('historical-pdufa-calender')) {
      this.activeRouteCalendar = 'assets/images/sync-tab-active.svg';
      this.CatalystActive = true;
    } else if (url.includes('conference-calendar')) {
      this.activeRouteCalendar = 'assets/images/sync-tab-active.svg';
      this.CatalystActive = true;
    } else {
      this.activeRouteCalendar = 'assets/images/sync-tab.svg';
      this.CatalystActive = false;
    }

    if (url.includes('catalyst-sync')) {
      this.activeRouteScreener = 'assets/images/screener-active-tab.svg';
      this.ScreenerActive = true;
    } else if (url.includes('company-screener')) {
      this.activeRouteScreener = 'assets/images/screener-active-tab.svg';
      this.ScreenerActive = true;
    } else if (url.includes('inside-trades')) {
      this.activeRouteScreener = 'assets/images/screener-active-tab.svg';
      this.ScreenerActive = true;
    } else if (url.includes('hedgefunds')) {
      this.activeRouteScreener = 'assets/images/screener-active-tab.svg';
      this.ScreenerActive = true;
    } else if (url.includes('allstockshold')) {
      this.activeRouteScreener = 'assets/images/screener-active-tab.svg';
      this.ScreenerActive = true;
    } else if (url.includes('heatmap')) {
      this.activeRouteScreener = 'assets/images/screener-active-tab.svg';
      this.ScreenerActive = true;
    } else {
      this.activeRouteScreener = 'assets/images/screener-tab.svg';
      this.ScreenerActive = false;
    }

    if (url.includes('news')) {
      this.activeRoutehedge = 'assets/images/hedge-active-tab.svg';
      this.hedgeActive = true;
    } else if (url.includes('blog')) {
      this.activeRoutehedge = 'assets/images/hedge-active-tab.svg';
      this.hedgeActive = true;
    } else if (url.includes('website-user')) {
      this.activeRoutehedge = 'assets/images/hedge-active-tab.svg';
      this.hedgeActive = true;
    } else {
      this.activeRoutehedge = 'assets/images/hedge-tab.svg';
      this.hedgeActive = false;
    }

    if (url.includes('admin') || url.includes('blog-admin')) {
      this.adminActive = true;
    }
    if (url.includes('subscription')) {
      this.activeRoutenews = 'assets/images/pricing_active.svg';
    } else {
      this.activeRoutenews = 'assets/images/pricing.svg';
    }
  }
  onSearchInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;

    if (inputValue === '') {
      this.suggection = [];
    }
  }
  searchFilters(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;

    if (event.key === 'Escape' && !inputElement.value) {
      this.suggection = [];
      return;
    }

    if (!inputElement.value || !this.searchValuequery) {
      this.suggection = [];
      return;
    }

    this.compamyDetails.searchsuggection(inputElement.value).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res);

        const ParseDecryptedData = JSON.parse(decryptData);
        this.suggection = ParseDecryptedData;

        this.symbol = ParseDecryptedData[0].symbol;
      },
    });
  }

  companyDetails(data: string) {
    this.symbol = data;
    var loginuser = localStorage.getItem('loggedInUser');

    if (!loginuser) {
      this.router.navigate(['company', this.symbol]);
      return;
    }
    this.suggection = [];
    this.searchValuequery = '';

    this.compamyDetails.getCompanyTable(this.symbol).subscribe({
      next: (res: any) => {
        this.router.navigate(['company', this.symbol]);
        this.closeMenu();
      },
    });
    this.compamyDetails.getCompanyTicker(this.symbol);
  }

  showinput() {
    this.showinputfield = !this.showinputfield;
  }
}
