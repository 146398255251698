import { Component, ViewChild, ElementRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  MinusData,
  PlusData,
  PreMarketData,
} from '../../../shared/models/lossing-gainers';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoosingGainerService } from '../../../shared/service/loosing-gainer.service';
import { ToastrService } from 'ngx-toastr';
import { io } from 'socket.io-client';
import { CompanyDetailsService } from '../../../shared/service/company-details.service';
import { LoginService } from '../../../core/services/login.service';
import { Catalyst } from '../../../shared/models/upcomingcatalyst';
import { data } from 'jquery';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { NgbCarousel, NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
import { Meta, Title } from '@angular/platform-browser';
import { DecryptionService } from 'src/app/modules/shared/service/decryption.service';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

// import { NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
@Injectable()
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: 0 })),
      transition('void <=> *', animate(300)),
    ]),
    trigger('slideInOut', [
      state('void', style({ transform: 'translateY(-100%)' })),
      transition('void <=> *', animate(500)),
    ]),
  ],
})
export class HomeComponent {
  @ViewChild('gainerTab') gainerTab: any;
  @ViewChild('loserTab') loserTab: any;
  @ViewChild('preMarketGainerTab') preMarketGainerTab: any;
  @ViewChild('preMarketLoserTab') preMarketLoserTab: any;
  @ViewChild('NgbCarousel') carouselvolatile!: NgbCarousel;
  @ViewChild('PreMarketNgbCarousel') PreMarketNgbCarousel!: NgbCarousel;
  @ViewChild('carousel') carousel!: NgbCarousel;
  @ViewChild('Gailosecarousel') Gailosecarousel!: NgbCarousel;
  @ViewChild('PreMarketCarousel') PreMarketCarousel!: NgbCarousel;
  @ViewChild('Upcomingcarousel') Upcomingcarousel!: NgbCarousel;
  @ViewChild('Upcomingmobilecarousel') Upcomingmobilecarousel!: NgbCarousel;
  @ViewChild('MostVolatiles', { static: true }) MostVolatiles!: ElementRef;
  @ViewChild('PreMarket', { static: true }) PreMarket!: ElementRef;
  @ViewChild('ShortInterest') ShortInterest!: ElementRef;
  @ViewChild('UpcomingEarningss') UpcomingEarningss!: ElementRef;
  @ViewChild('highestVolumes') highestVolumes!: ElementRef;
  @ViewChild('activeStockOptionss') activeStockOptionss!: ElementRef;
  @ViewChild('UnusualActivitys') UnusualActivitys!: ElementRef;

  isVisible: boolean = false;

  EpsDash: boolean = true;
  isCompanyDetailsTriggered: boolean = false;
  imageUrl: string = 'assets/images/arrow.svg';
  plusData: PlusData[] = [];
  minusData: MinusData[] = [];
  prePlusData: PreMarketData[] = [];
  preMinusData: PreMarketData[] = [];
  upcomingEarnings: any[] = [];
  heighestVolArry: any[] = [];
  activeOptionsArry: any[] = [];
  UnusualOptionActivity: any[] = [];
  shortinterest: any[] = [];
  currentDate: Date = new Date('2024-11-06');
  currentPage = 1;
  Catalyst: Catalyst[] = [];
  itemsPerPage = 10;
  isHovered = false;
  loginform!: FormGroup;
  bioPharmaSignup!: boolean;
  hoverImageUrl = 'assets/images/whitearrow.svg';
  defaultImageUrl = 'assets/images/arrow.svg';
  basePath: string = 'https://s3-symbol-logo.tradingview.com/';
  displayStyle1 = false;
  socket = io(environment.socketUrl);
  lastClickTime = 0;
  selectedCompany: any;
  modalDisplayStyle!: boolean;
  gainers = true;
  loosings = false;
  stageShow: string = '';
  slides: any[] = [];
  screenWidth: any;
  posIni: any;
  posIni2: any;
  posIniPM: any;
  posIni3: any;
  posIni4: any;
  activeSlide: any = 0;
  activePreMarketSlide: any = 0;
  activeTab: number = 0;
  suggection: any;
  symbol!: string;
  activeGainerTab = 'active-tab';
  activebtntab = '';
  activepreMarketGainerTab = 'active-tab';
  activepreMarketLoserTab = '';
  searchValuequery: any;
  clientUrl = environment.clientUrl;

  constructor(
    private router: Router,
    private LoosingGainerService: LoosingGainerService,
    private compamyDetails: CompanyDetailsService,
    private titleService: Title,
    private decryptionService: DecryptionService
  ) {
    this.titleService.setTitle('BioPharmaWatch');
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url.includes('/success')) {
          this.isVisible = true;
        }
      });
  }

  ngOnInit(): void {
    this.gainerData();
    this.lossingData();
    this.preGainerData();
    this.preLosersData();
    this.bioPharmaSignupData();
    this.upcomingearnigs();
    this.upcomingcatalyst();
    this.heighestVol();
    this.ScoketAllData();
    this.activeOptions();
    this.UnusualOptionActivitys();
    this.shortInterestData();
  }

  scrollToSection(set: HTMLElement) {
    set.scrollIntoView();
  }
  closeModal() {
    this.isVisible = false;
    return this.router.navigate(['profile']);
  }
  loggedin() {
    return localStorage.getItem('loggedInUser');
  }

  move(pos: number) {
    const offset = this.posIni - pos;
    const animateSwipe = () => {
      if (offset < -10) {
        this.carousel.prev();
      } else if (offset > 10) {
        this.carousel.next();
      }
    };
    requestAnimationFrame(animateSwipe);
  }

  move2(pos: number) {
    const offset = this.posIni2 - pos;
    const animateSwipe = () => {
      if (offset < -10) {
        this.Gailosecarousel.prev();
      } else if (offset > 10) {
        this.Gailosecarousel.next();
      }
    };
    requestAnimationFrame(animateSwipe);
  }

  movePreMarket(pos: number) {
    const offset = this.posIniPM - pos;
    const animateSwipe = () => {
      if (offset < -10) {
        this.PreMarketCarousel.prev();
      } else if (offset > 10) {
        this.PreMarketCarousel.next();
      }
    };
    requestAnimationFrame(animateSwipe);
  }

  move4(pos: number) {
    const offset = this.posIni4 - pos;
    const animateSwipe = () => {
      if (offset < -10) {
        this.Upcomingmobilecarousel.prev();
      } else if (offset > 10) {
        this.Upcomingmobilecarousel.next();
      }
    };
    requestAnimationFrame(animateSwipe);
  }

  move3(pos: number) {
    const offset = this.posIni3 - pos;
    const animateSwipe = () => {
      if (offset < -10) {
        this.Upcomingcarousel.prev();
      } else if (offset > 10) {
        this.Upcomingcarousel.next();
      }
    };
    requestAnimationFrame(animateSwipe);
  }

  GetChildData(data: any) {
    this.modalDisplayStyle = data;
  }

  preGainerData() {
    this.LoosingGainerService.getPrePlusData().subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.encrypted);
        const ParseDecryptedData = JSON.parse(decryptData);

        this.prePlusData = ParseDecryptedData;
      },
    });
  }

  preLosersData() {
    this.LoosingGainerService.getPreMinusData().subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.encrypted);
        const ParseDecryptedData = JSON.parse(decryptData);

        this.preMinusData = ParseDecryptedData;
      },
    });
  }

  gainerData() {
    this.LoosingGainerService.getPlusData().subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.encrypted);
        const ParseDecryptedData = JSON.parse(decryptData);

        this.plusData = ParseDecryptedData;
      },
    });
  }

  upcomingearnigs() {
    this.LoosingGainerService.upcomingEarnigs().subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res);
        const ParseDecryptedData = JSON.parse(decryptData);
        this.upcomingEarnings = ParseDecryptedData;
        // res.forEach((data: any) => {
        // });

        this.screenWidth = window.innerWidth;
        var itemsPerSlide = 1;

        if (this.screenWidth > 1024) {
          itemsPerSlide = 5;
        }
        if (this.screenWidth >= 768 && this.screenWidth <= 1024) {
          itemsPerSlide = 3;
        }
        if (this.screenWidth >= 520 && this.screenWidth <= 768) {
          itemsPerSlide = 3;
        }
        if (this.screenWidth >= 370 && this.screenWidth <= 520) {
          itemsPerSlide = 2;
        }
        if (this.screenWidth < 370) {
          itemsPerSlide = 1;
        }

        for (let i = 0; i < this.upcomingEarnings.length; i += itemsPerSlide) {
          this.slides.push(this.upcomingEarnings.slice(i, i + itemsPerSlide));
        }
      },
    });
  }

  lossingData() {
    this.LoosingGainerService.getminusData().subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.encrypted);
        const ParseDecryptedData = JSON.parse(decryptData);
        this.minusData = ParseDecryptedData;
      },
    });
  }

  changeImage() {
    const UserId = localStorage.getItem('loggedInUser');
    if (UserId !== null) {
      this.modalDisplayStyle = false;
      this.router.navigate(['volatile-Data']);
    } else {
      this.modalDisplayStyle = true;
    }
  }

  closePopup() {
    this.displayStyle1 = false;
  }

  loginpage() {
    this.router.navigate(['login']);
  }

  upcomingcatalyst() {
    this.LoosingGainerService.upcomingcatalyst().subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res);
        const ParseDecryptedData = JSON.parse(decryptData);
        this.Catalyst = ParseDecryptedData;
      },
    });
  }

  catalystDetails(ticker: any) {
    var loginuser = localStorage.getItem('loggedInUser');
    if (loginuser === null) {
      this.modalDisplayStyle = true;
    } else {
      this.compamyDetails.getCompanyTable(ticker).subscribe({
        next: (res: any) => {
          this.router.navigate(['company', ticker]);
        },
      });
      this.compamyDetails.getCompanyTicker(ticker);
    }
  }

  heighestVol() {
    this.LoosingGainerService.heighestVol().subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res);
        const ParseDecryptedData = JSON.parse(decryptData);
        this.heighestVolArry = ParseDecryptedData;
      },
    });
  }

  activeOptions() {
    this.LoosingGainerService.activeOption().subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res);
        const ParseDecryptedData = JSON.parse(decryptData);
        this.activeOptionsArry = ParseDecryptedData;
      },
    });
  }

  UnusualOptionActivitys() {
    this.LoosingGainerService.UnusualOptionActivity().subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res);
        const ParseDecryptedData = JSON.parse(decryptData);
        this.UnusualOptionActivity = ParseDecryptedData;
      },
    });
  }

  plusDetails(ticker: any) {
    var loginuser = localStorage.getItem('loggedInUser');
    if (loginuser === null) {
      this.modalDisplayStyle = true;
    } else {
      this.compamyDetails.getCompanyTable(ticker).subscribe({
        next: (res: any) => {
          this.router.navigate(['company', ticker]);
        },
      });
      this.compamyDetails.getCompanyTicker(ticker);
    }
  }

  heighrstDetails(ticker: any) {
    var loginuser = localStorage.getItem('loggedInUser');
    if (loginuser === null) {
      this.modalDisplayStyle = true;
    } else {
      this.compamyDetails.getCompanyTable(ticker).subscribe({
        next: (res: any) => {
          this.router.navigate(['company', ticker]);
        },
      });
      this.compamyDetails.getCompanyTicker(ticker);
    }
  }

  EarningsDetails(ticker: string) {
    var loginuser = localStorage.getItem('loggedInUser');
    if (loginuser === null) {
      this.modalDisplayStyle = true;
    } else {
      this.compamyDetails.getCompanyTable(ticker).subscribe({
        next: (res: any) => {
          this.router.navigate(['company', ticker]);
        },
      });
      this.compamyDetails.getCompanyTicker(ticker);
    }
  }

  minusDetails(ticker: any) {
    var loginuser = localStorage.getItem('loggedInUser');
    if (loginuser === null) {
      this.modalDisplayStyle = true;
    } else {
      this.compamyDetails.getCompanyTable(ticker).subscribe({
        next: (res: any) => {
          this.router.navigate(['company', ticker]);
        },
      });
      this.compamyDetails.getCompanyTicker(ticker);
    }
  }

  bioPharmaSignupData() {
    const UserId = localStorage.getItem('loggedInUser');
    if (UserId) {
      this.bioPharmaSignup = false;
    } else {
      this.bioPharmaSignup = true;
    }
  }

  ScoketAllData() {
    this.socket.on('tblcompaniesScoket', (res: any) => {
      if (res.operationType == 'update') {
        var Data = {
          ...res.documentKey,
          ...res.updateDescription.updatedFields,
        };
        this.plusData.filter((PlusObj: any) => {
          if (PlusObj._id === Data._id) {
            PlusObj.close = Data.close ? Data.close : PlusObj.close;
          }
          if (PlusObj._id === Data._id) {
            PlusObj.change_abs = Data.change_abs
              ? Data.change_abs
              : PlusObj.change_abs;
          }
        });
        this.minusData.filter((MinusObj: any) => {
          if (MinusObj._id === Data._id) {
            MinusObj.close = Data.close ? Data.close : MinusObj.close;
          }
          if (MinusObj._id === Data._id) {
            MinusObj.change_abs = Data.change_abs
              ? Data.change_abs
              : MinusObj.change_abs;
          }
        });
        this.Catalyst.filter((CatalystObj: any) => {
          if (CatalystObj._id === Data._id) {
            CatalystObj.close = Data.close ? Data.close : CatalystObj.close;
          }
          if (CatalystObj._id === Data._id) {
            CatalystObj.change = Data.change ? Data.change : CatalystObj.change;
          }
          if (CatalystObj._id === Data._id) {
            CatalystObj.change_abs = Data.change_abs
              ? Data.change_abs
              : CatalystObj.change_abs;
          }
        });
      } else {
      }
    });
    this.socket.connect();
  }

  gainersdata() {
    this.gainers = true;
    this.loosings = false;
  }

  lossingdata() {
    this.loosings = true;
    this.gainers = false;
  }

  onSlideChange(slideEvent: NgbSlideEvent) {
    this.activeSlide = slideEvent.current;
    if (this.activeSlide == 0) {
      this.activebtntab = '';
      this.activeGainerTab = 'active-tab';
    } else if (this.activeSlide == 1) {
      this.activebtntab = 'active-tab';
      this.activeGainerTab = '';
    }
  }

  onPreMarketSlideChange(slideEvent: NgbSlideEvent) {
    this.activePreMarketSlide = slideEvent.current;
    if (this.activePreMarketSlide == 0) {
      this.activepreMarketLoserTab = '';
      this.activepreMarketGainerTab = 'active-tab';
    } else if (this.activePreMarketSlide == 1) {
      this.activepreMarketLoserTab = 'active-tab';
      this.activepreMarketGainerTab = '';
    }
  }

  selectTab(tabId: number) {
    if (tabId === 0) {
      this.gainerTab.nativeElement.click();
      this.carouselvolatile.select('0');
    } else if (tabId === 1) {
      this.loserTab.nativeElement.click();
      this.carouselvolatile.select('1');
    }
  }

  selectPreMarketTab(tabId: number) {
    if (tabId === 0) {
      this.preMarketGainerTab.nativeElement.click();
      this.PreMarketNgbCarousel.select('0');
    } else if (tabId === 1) {
      this.preMarketLoserTab.nativeElement.click();
      this.PreMarketNgbCarousel.select('1');
    }
  }

  shortInterestData() {
    this.LoosingGainerService.shortInterest().subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res);
        const ParseDecryptedData = JSON.parse(decryptData);
        this.shortinterest = ParseDecryptedData;
      },
    });
  }

  searchFilters(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;

    if (event.key === 'Escape' && !inputElement.value) {
      this.suggection = [];
      return;
    }

    if (!inputElement.value || !this.searchValuequery) {
      this.suggection = [];
      return;
    }

    this.compamyDetails.searchsuggection(inputElement.value).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res);
        const ParseDecryptedData = JSON.parse(decryptData);
        this.suggection = ParseDecryptedData;

        this.symbol = res[0].symbol;
      },
    });
  }

  onSearchInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;

    if (inputValue === '') {
      this.suggection = [];
    }
  }

  companyDetails(data: string) {
    this.symbol = data;

    let loginuser = localStorage.getItem('loggedInUser');

    if (!loginuser) {
      this.router.navigate(['company', this.symbol]);
      return;
    }
    this.suggection = [];
    this.searchValuequery = '';

    this.compamyDetails.getCompanyTable(this.symbol).subscribe({
      next: (res: any) => {
        this.router.navigate(['company', this.symbol]);
      },
    });
    this.compamyDetails.getCompanyTicker(this.symbol);
  }
}
