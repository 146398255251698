import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spaceaftercoma'
})
export class SpaceaftercomaPipe implements PipeTransform {

  transform(value: any): any {

  const arrayWithSpaces = value.map((item:any) => item + " , ").join("");
    return arrayWithSpaces

  }

}
