import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/modules/core/services/login.service';
import { environment, googleConfig } from 'src/environments/environment';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

@Component({
  selector: 'app-loginpop-up',
  templateUrl: './loginpop-up.component.html',
  styleUrls: ['./loginpop-up.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translateY(15px)', // At its original position
          opacity: 1,
          filter: 'blur(0px)', // No blur when fully visible
        })
      ),
      state(
        'out',
        style({
          transform: 'translateY(0px)', // 30px lower (off-screen)
          opacity: 0, // Transparent
          filter: 'blur(8px)', // Apply blur when sliding out
        })
      ),
      transition('out => in', [
        animate('1.8s cubic-bezier(0.34, 1.56, 0.64, 1)'),
      ]),
      transition('in => out', [
        animate('1.5s ease-out'), // Slow down ease-out slightly
      ]),
    ]),
  ],
})
export class LoginpopUpComponent {
  loginform!: FormGroup;
  resetEmail!: FormGroup;
  showPassword: boolean = false;
  submitted: boolean = false;
  emailControl = new FormControl('', Validators.required);
  displayStyleforget = 'none';
  siteKey: string = environment.sitekeyurl;
  @Input() displayStyle!: boolean;
  @Output() myout: EventEmitter<boolean> = new EventEmitter();
  outputmessage: boolean = false;
  isLoading = false;
  googleLoading = false;
  assectoken = localStorage.getItem('loggedInUser');
  testimonials: {
    content: string;
    name: string;
    position: string;
    image: string;
  }[] = [
    {
      content:
        'Thanks to the searchable FDA calendar and PDUFA catalysts, I’ve been able to plan ahead and consistently capitalize on market-moving events. This service has played a crucial role in helping me generate steady profits!',
      name: 'John D',
      position: 'Individual Investor',
      image:
        '/assets/images/young-bearded-man-with-striped-shirt.jpg',
    },
    {
      content:
        'The portfolio tracking tools have made managing my investments so much smoother. I can track catalysts and drug pipelines, and it’s helped me make better decisions that have really paid off. Definitely a great resource for anyone in biotech.',
      name: 'Samantha R',
      position: 'Portfolio Manager',
      image:
        '/assets/images/women.jpg',
    },
    {
      content:
        'Tracking biotech earnings and catalyst events is now easier than ever. I can quickly assess how different companies are performing and adjust my portfolio accordingly.',
      name: 'Emily K',
      position: 'Individual Investor',
      image:
        '/assets/images/girl-with-gray-sweater-blue-jeans-is-posing-photo.jpg',
    },
    {
      content:
        'As someone new to biotech investing, the tools have been a lifesaver. I’ve been able to make some solid gains just by following the market updates and tracking my portfolio closely. Highly recommend it!',
      name: 'Linda P',
      position: 'New Biotech Investor',
      image:
        'assets/images/portrait-charming-businesswoman-sitting-her-workplace-writing.jpg',
    },
    {
      content:
        'Being able to monitor insider trading and hedge fund holdings has provided invaluable insights. This feature alone has helped me spot opportunities and avoid potential pitfalls.',
      name: 'Carlos M',
      position: 'API User',
      image:
        'assets/images/bohemian-man-with-his-arms-crossed.jpg',
    },
    {
      content:
        'The extensive research on over 1,100 companies and clinical trials is simply impressive. It’s helped me shape more informed, strategic plans for both investments and R&D purposes.',
      name: 'Michael L',
      position: 'Pharma Researcher',
      image:
        'https://media.istockphoto.com/id/1170953707/photo/smiling-black-man.jpg?s=612x612&w=0&k=20&c=lKqsRoMExYAnVtIw9fadM84rOPBhI_LVLCuRaBvstvo=',
    },
  ];
  currentIndex = 0;
  currentState = 'in'; // This will track the current animation state
  displayedTestimonial = this.testimonials[0];

  constructor(
    private fb: FormBuilder,
    private loginservice: LoginService,
    private router: Router,
    private toaster: ToastrService
  ) {}

  ngOnInit() {
    setInterval(() => {
      this.changeTestimonial();
    },8000);

    this.loginform = this.fb.group({
      email: this.emailControl,
      password: ['', Validators.required],
      recaptcha: [''],
    });
    this.resetEmail = this.fb.group({
      email: this.emailControl,
    });
    this.checkAssectoken();
  }

  checkAssectoken() {
    var token = JSON.parse(this.assectoken || '{}');
    this.assectoken = token.accessToken;
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
  changeTestimonial() {
    this.currentState = 'out';

    setTimeout(() => {
      this.currentIndex = (this.currentIndex + 1) % this.testimonials.length;
      this.displayedTestimonial = this.testimonials[this.currentIndex];
      this.currentState = 'in';
    }, 400); // Match this delay with the 'in => out' transition duration
  }

  signInWithGoogle(): void {
    googleConfig.googleClick();
  }

  closePopup() {
    this.displayStyle = false;
    this.myout.emit(this.displayStyle);
    this.loginform.reset();
  }

  forgetpass() {
    this.displayStyleforget = 'block';
    this.resetEmail.reset();
  }

  closePopupforget() {
    this.displayStyleforget = 'none';
  }

  savePopup() {
    let email = this.resetEmail.value;
    this.submitted = true;
    if (email.email === null) {
      return;
    }
    this.loginservice.forgetpassword(email).subscribe({
      next: (response) => {
        this.toaster.success(response.message);
      },
      error: (error) => {
        this.toaster.error(error);
      },
    });
    this.closePopupforget();
  }

  login(data: any) {
    this.submitted = true;
    if (this.loginform.valid) {
      this.isLoading = true;
      this.loginservice.postlogin(data).subscribe({
        next: (res) => {
          if (res) {
            this.displayStyle = false;
            this.isLoading = false;
            let href = this.router.url;
            localStorage.setItem('route', href);
            window.location.href = this.router.url;
            this.router.navigate([href]);
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.toaster.error(error);
        },
      });
    }
  }
}
