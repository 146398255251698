import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  compnayfilter,
  hedgefundStockAllfilterColoum,
  compnayfilterColoum,
  fdafilter,
  fdafilterColoum,
  itfilter,
  itfilterColoum,
  syncfilterColoum,
  syncfilter,
  allstocksfilterColoum,
  allstocksfilterSearch,
  allstockssortdata,
  fdasortdata,
  syncDatasort,
  screenerdatasort,
  insidetradesort,
  hedgefundsortdata,
  screenerdatasortwithfilter,
  fdadatasortwithfilter,
  syncdatasortwithfilter,
  itdatasortwithfilter,
  hedgedatasortwithfilter,
  allstocksdatasortwithfilter,
  hedgefundfilterSearch,
  pdufadatawithfilter,
  pdufafilter,
  bioteachfilterdata,
} from '../const';

@Injectable({
  providedIn: 'root',
})
export class FilterdataService {
  constructor(private http: HttpClient) {}

  fdafilterData(data: any) {
    return this.http.post(`${fdafilter}`, data);
  }

  bioteachfilterData(data: any) {
    return this.http.post(`${bioteachfilterdata}`, data);
  }

  fdafilterColumn(page: number, filters: any) {
    const data = { filters };
    return this.http.post(`${fdafilterColoum}${page}`, data);
  }

  itfilterColum(filters: any, page: number) {
    const data = { filters };
    return this.http.post(`${itfilterColoum}${page}`, data);
  }
  compnayfilterData(data: any) {
    return this.http.post(`${compnayfilter}`, data);
  }
  compnayfilterColoum(filters: any, page: number) {
    const data = { filters };

    return this.http.post(`${compnayfilterColoum}${page}`, data);
  }

  itfilterData(data: any) {
    return this.http.post(`${itfilter}`, data);
  }

  syncfilterData(data: any) {
    return this.http.post(`${syncfilter}`, data);
  }

  syncfilterColum(page: number, filters: any) {
    const data = { filters };

    return this.http.post(`${syncfilterColoum}${page}`, data);
  }

  allStocksSerch(data: any) {
    return this.http.post(`${allstocksfilterSearch}`, data);
  }
  hedgefundsearch(data: any) {
    return this.http.post(`${hedgefundfilterSearch}`, data);
  }
  hedgefundstockColum(data: any) {
    return this.http.post(`${allstocksfilterColoum}`, data);
  }

  hedgefundscreenerColum(filters: any, page: number) {
    const data = { filters };

    return this.http.post(`${hedgefundStockAllfilterColoum}${page}`, data);
  }
  allStockscolumn(filters: any, page: number) {
    const data = { filters };

    return this.http.post(`${allstocksfilterColoum}${page}`, data);
  }
  allStocksSort(sortKey: string, order: number, page: number) {
    return this.http.post<any>(
      `${allstockssortdata}sortKey=${sortKey}&sortOrder=${order}&page=${page}`,
      ''
    );
  }
  hedgeStocksSort(sortKey: string, order: number, page: number) {
    return this.http.post<any>(
      `${hedgefundsortdata}sortKey=${sortKey}&sortOrder=${order}&page=${page}`,
      ''
    );
  }
  fdaSort(sortKey: string, order: number, page: number) {
    return this.http.post<any>(
      `${fdasortdata}sortKey=${sortKey}&sortOrder=${order}&page=${page}`,
      ''
    );
  }
  syncsort(sortKey: string, order: number, page: number) {
    return this.http.post<any>(
      `${syncDatasort}sortKey=${sortKey}&sortOrder=${order}&page=${page}`,
      ''
    );
  }
  screenersort(sortKey: string, order: number, page: number) {
    return this.http.post<any>(
      `${screenerdatasort}sortKey=${sortKey}&sortOrder=${order}&page=${page}`,
      ''
    );
  }
  screenersortwithFilter(
    sortKey: string,
    order: number,
    page: number,
    filters: any
  ) {
    const data = { filters };

    return this.http.post<any>(
      `${screenerdatasortwithfilter}sortKey=${sortKey}&sortOrder=${order}&page=${page}`,
      data
    );
  }
  fdasortwithFilter(
    sortKey: string,
    order: number,
    page: number,
    filters: any
  ) {
    const data = { filters };
    return this.http.post<any>(
      `${fdadatasortwithfilter}sortKey=${sortKey}&sortOrder=${order}&page=${page}`,
      data
    );
  }
  itsortwithFilter(sortKey: string, order: number, page: number, filters: any) {
    const data = { filters };

    return this.http.post<any>(
      `${itdatasortwithfilter}sortKey=${sortKey}&sortOrder=${order}&page=${page}`,
      data
    );
  }
  hedgesortwithFilter(
    sortKey: string,
    order: number,
    page: number,
    filters: any
  ) {
    const data = { filters };

    return this.http.post<any>(
      `${hedgedatasortwithfilter}sortKey=${sortKey}&sortOrder=${order}&page=${page}`,
      data
    );
  }
  allstockssortwithFilter(
    sortKey: string,
    order: number,
    page: number,
    filters: any
  ) {
    const data = { filters };

    return this.http.post<any>(
      `${allstocksdatasortwithfilter}sortKey=${sortKey}&sortOrder=${order}&page=${page}`,
      data
    );
  }
  syncsortwithFilter(
    sortKey: string,
    order: number,
    page: number,
    filters: any
  ) {
    debugger;
    const data = { filters };

    return this.http.post<any>(
      `${syncdatasortwithfilter}sortKey=${sortKey}&sortOrder=${order}&page=${page}`,
      data
    );
  }
  insidesort(sortKey: string, order: number, page: number) {
    return this.http.post<any>(
      `${insidetradesort}sortKey=${sortKey}&sortOrder=${order}&page=${page}`,
      ''
    );
  }

  pdufafilterData(data: any) {
    return this.http.post(`${pdufafilter}`, data);
  }

  pdufawithFilter(page: number, data: any) {
    return this.http.post(`${pdufadatawithfilter}${page}`, data);
  }
}
