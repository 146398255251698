<section class="Inside">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="text-center mt-3 mb-3">
          <h1 class="main-heading text-white m-0 me-3">Inside Trades</h1>

          <p class="sub-heading text-white m-0">
            Explore our "Insider Trades" tool to stay informed about the latest
            transactions carried out by top executives and insiders in the
            biotech and pharmaceutical sectors. Easily tailor your search using
            the user-friendly filters at the top of the page to pinpoint
            precisely what you're seeking.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid form-box py-2">
    <div class="row justify-content-center align-items-center">
      <div class="col-lg-3 col-md-10 col-12">
        <form style="height: 30px" class="example d-flex add_m_infilter">
          <input
            [(ngModel)]="searchValuequery"
            autocomplete="off"
            (keyup)="searchFilters($event)"
            (input)="onSearchInput($event)"
            #searchVal
            type="search"
            class="input-box"
            placeholder="Search by Comapny name or Ticker"
            name="search2"
          />
          <button
            (click)="searchFiltersonclick(searchValuequery)"
            class="searchquerybtn"
            type="button"
          >
            @if (isFilterBtnSearch) {
            <i style="color: white" class="fa fa-spin fa-spinner"></i>

            }@else {
            <i class="fa-solid fa-magnifying-glass"></i>
            }
          </button>
        </form>
        @if (suggection?.length > 0) {
        <ul class="list-unstyled m-0">
          @for (suggestion of suggection; track $index) {
          <li class="suggestion-item p-0 cursorpointer">
            <div
              (click)="searchFiltersonclick(suggestion.symbol)"
              class="d-flex filter-bottom justify-content-between px-2"
            >
              <span>
                @if (suggestion.logoid !== '') {
                <img
                  width="25px"
                  [src]="basePath + suggestion.logoid + '.svg'"
                />
                }@else {
                <span
                  class="avatarcss"
                  style="
                    width: 25px !important;
                    height: 25px !important;
                    margin: 0 !important;
                  "
                  >{{ suggestion.name | initialLetter }}</span
                >
                }
              </span>
              <p
                class="text-start text-dark filter-font-text mb-1 border-1 me-4"
              >
                {{ suggestion.name }}
              </p>
              <p class="text-end text-dark filter-font-text mb-1">
                {{ suggestion.symbol }}
              </p>
            </div>
          </li>
          }
        </ul>
        }
      </div>

      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedDataColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            #priceinput
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            Price
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if (selectedDataColor) {
          <span class="" (click)="clearSelectedPrice()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }

          <ul class="dropdown-menu games-menu">
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('less_than_1 $')"
                [class.selected]="'less_than_1 $' === selectedValue"
              >
                Less than 1 $
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('1$ to 2$')"
                [class.selected]="'1$ to 2$' === selectedValue"
              >
                1 $ to 2 $
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('2$ to 5$')"
                [class.selected]="'2$ to 5$' === selectedValue"
              >
                2 $ to 5 $
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('5$ to 50$')"
                [class.selected]="'5$ to 50$' === selectedValue"
              >
                5 $ to 50 $
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('50$ to 100$')"
                [class.selected]="'50$ to 100$' === selectedValue"
              >
                50 $ to 100 $
              </p>
            </li>
            <li>
              <button
                class="dropdown-item dropdown-toggle sub-menu dropdown-item filter-font-text text-center m-0"
                data-bs-toggle="dropdown"
              >
                Customize
              </button>
              <ul class="dropdown-menu games-menu">
                <div class="p-1" aria-labelledby="shareHoldDropdown">
                  <input
                    type="number"
                    class="custom-input-set mb-2"
                    placeholder="Min Value"
                    [(ngModel)]="minCustomValue"
                    (input)="updateCustomRange()"
                    (change)="pushFilterminPrice($event)"
                  />
                  <input
                    type="number"
                    class="custom-input-set"
                    placeholder="Max Value"
                    [(ngModel)]="maxCustomValue"
                    (input)="updateCustomRange()"
                    (change)="pushFiltermaxPrice($event)"
                  />
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedchangeColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <span>Change in Price</span>
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if (selectedchangeColor) {
          <span class="" (click)="clearSelectedChangePrice()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }

          <ul class="dropdown-menu">
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('less_than_1 %')"
                [class.selected]="'less_than_1 %' === selectedValueinchange"
              >
                Less than 1 %
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('1% to 2%')"
                [class.selected]="'1% to 2%' === selectedValueinchange"
              >
                1 % to 2 %
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('2% to 5%')"
                [class.selected]="'2% to 5%' === selectedValueinchange"
              >
                2 % to 5 %
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('5% to 10%')"
                [class.selected]="'5% to 10%' === selectedValueinchange"
              >
                5 % to 10 %
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('10% to 50%')"
                [class.selected]="'10% to 50%' === selectedValueinchange"
              >
                10 % to 50 %
              </p>
            </li>
            <li>
              <button
                class="dropdown-item dropdown-toggle dropdown-item filter-font-text text-center m-0"
                data-bs-toggle="dropdown"
              >
                Customize
              </button>
              <ul class="dropdown-menu">
                <div class="p-1" aria-labelledby="shareHoldDropdown">
                  <input
                    type="number"
                    class="custom-input-set mb-2"
                    placeholder="Min Value"
                    [(ngModel)]="minCustomChange"
                    (input)="updateChangePriceCustomRange()"
                    (change)="pushFilterChange($event)"
                  />
                  <input
                    type="number"
                    class="custom-input-set"
                    placeholder="Max Value"
                    [(ngModel)]="maxCustomChange"
                    (input)="updateChangePriceCustomRange()"
                    (change)="EndpushFilterChange($event)"
                  />
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedtradepriceColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            #priceinput
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            Trade Price
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if (selectedtradepriceColor) {
          <span class="" (click)="clearSelectedTransactionPrice()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }

          <ul class="dropdown-menu games-menu">
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('less_than_1 $ v')"
                [class.selected]="'less_than_1 $ v' === selectedTradeprice"
              >
                Less than 1 $
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('1$ to 2$ v')"
                [class.selected]="'1$ to 2$ v' === selectedTradeprice"
              >
                1 $ to 2 $
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('2$ to 5$ v')"
                [class.selected]="'2$ to 5$ v' === selectedTradeprice"
              >
                2 $ to 5 $
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('5$ to 50$ v')"
                [class.selected]="'5$ to 50$ v' === selectedTradeprice"
              >
                5 $ to 50 $
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('50$ to 100$ v')"
                [class.selected]="'50$ to 100$ v' === selectedTradeprice"
              >
                50 $ to 100 $
              </p>
            </li>
            <li>
              <button
                class="dropdown-item dropdown-toggle sub-menu dropdown-item filter-font-text text-center m-0"
                data-bs-toggle="dropdown"
              >
                Customize
              </button>
              <ul class="dropdown-menu games-menu">
                <div class="p-1" aria-labelledby="shareHoldDropdown">
                  <input
                    type="number"
                    class="custom-input-set mb-2"
                    placeholder="Min Value"
                    [(ngModel)]="minCustomTprice"
                    (input)="updateCustomRange()"
                    (change)="pushFilterminPrice($event)"
                  />
                  <input
                    type="number"
                    class="custom-input-set"
                    placeholder="Max Value"
                    [(ngModel)]="maxCustomTprice"
                    (input)="updateCustomRange()"
                    (change)="pushFiltermaxPrice($event)"
                  />
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedDateColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span>Trade Date</span>
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if (selectedDateColor) {
          <span class="" (click)="clearSelectedDate()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }

          <ul class="dropdown-menu">
            <li>
              <p class="text-center">
                <input
                  type="date"
                  class="custom-input-set mb-2"
                  placeholder="Min Value"
                  [(ngModel)]="minCustomDate"
                  (input)="updateDateCustomRange()"
                  (change)="pushFilterDateStart($event)"
                />
                <input
                  type="date"
                  class="custom-input-set"
                  placeholder="Max Value"
                  [(ngModel)]="maxCustomeDate"
                  (input)="updateDateCustomRange()"
                  (change)="pushFilterDateEnd($event)"
                />
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedTradeValueColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <span>Trade Value</span>
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if (selectedTradeValueColor) {
          <span class="" (click)="clearSelectedTradeValue()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }

          <ul class="dropdown-menu">
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('10m_or_more')"
                [class.selected]="'10m_or_more' === selectedValueinTradeValue"
              >
                $ 10 million or more
              </p>
            </li>

            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('1m_to_10m')"
                [class.selected]="'1m_to_10m' === selectedValueinTradeValue"
              >
                $ 1 million to $ 10 million
              </p>
            </li>

            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('100k_to_1m')"
                [class.selected]="'100k_to_1m' === selectedValueinTradeValue"
              >
                $ 100 thousand to $ 1 million
              </p>
            </li>

            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('less_than_100k')"
                [class.selected]="
                  'less_than_100k' === selectedValueinTradeValue
                "
              >
                Less than $ 100 thousand
              </p>
            </li>

            <li>
              <button
                class="dropdown-item dropdown-toggle dropdown-item filter-font-text text-center m-0"
                data-bs-toggle="dropdown"
              >
                Customize
              </button>
              <ul class="dropdown-menu">
                <div class="p-1" aria-labelledby="shareHoldDropdown">
                  <input
                    type="number"
                    class="custom-input-set mb-2"
                    placeholder="Min Value (Millions)"
                    [(ngModel)]="minCustomTradeValue"
                    (input)="updateTradeValueCustomRange()"
                    (change)="pushFilterTradeValue($event)"
                  />
                  <input
                    type="number"
                    class="custom-input-set"
                    placeholder="Max Value (Millions)"
                    [(ngModel)]="maxCustomTradeValue"
                    (input)="updateTradeValueCustomRange()"
                    (change)="EndpushFilterTradeValue($event)"
                  />
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedbuy_sellColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            #priceinput
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            Buy/Sale
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if (selectedbuy_sellColor) {
          <span class="" (click)="clearSelectedbuysell()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }

          <ul class="dropdown-menu games-menu">
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('buy')"
                [class.selected]="'buy' === selectedValueinbuy_sell"
              >
                Buy
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('sell')"
                [class.selected]="'sell' === selectedValueinbuy_sell"
              >
                Sell
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center m-0"
                (click)="selectedData('option')"
                [class.selected]="'option' === selectedValueinbuy_sell"
              >
                Option
              </p>
            </li>
          </ul>
        </div>
      </div>

      <!-- <div class="col-lg-auto add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button [ngClass]="selectedindustryColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle" role="button"
            data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
            <span>Industry</span>
            <i class="fa fa-caret-down ms-4"></i>
          </button>
          <span class="" (click)="clearSelectedIndustry()">
            <img *ngIf="selectedindustryColor" class="m-1 mb-0 cursorpointer" src="assets/images/new-minus.svg"
              alt="" />
          </span>
          <ul class="dropdown-menu">
            <li>
              <p class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'Biotechnology' === selectedValueinindustry" (click)="selectedData('Biotechnology')">
                Biotechnology
              </p>
            </li>
            <li>
              <p class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'Medical Device' === selectedValueinindustry"
                (click)="selectedData('Medical Device')">
                Medical Device
              </p>
            </li>
            <li>
              <p class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'Diagnostics & Research' === selectedValueinindustry"
                (click)="selectedData('Diagnostics & Research')">
                Diagnostics & Research
              </p>
            </li>
            <li>
              <p class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'Drug Manufacturers' === selectedValueinindustry"
                (click)="selectedData('Drug Manufacturers')">
                Drug Manufacturers
              </p>
            </li>
          </ul>
        </div>
      </div> -->
    </div>
    <div id="add" class="text-center mt-1 d-flex justify-content-center">
      <button
        type=""
        class="form-save-button d-flex justify-content-center align-items-center text-nowrap me-3 mt-2 mb-1"
        (click)="saveFilters()"
      >
        @if (!isFilterBtn) {
        <p class="mb-0">APPLY FILTERS</p>
        }@else {
        <i class="fa fa-spin fa-spinner"></i>
        }
      </button>
      <button
        type=""
        class="form-reset-button d-flex justify-content-center align-items-center text-nowrap me-3 mt-2 mb-1"
        (click)="resetFilters()"
      >
        @if (!isFilterBtnResert) {
        <p class="mb-0">RESET FILTERS</p>
        }@else {
        <i class="fa fa-spin fa-spinner"></i>
        }
      </button>
    </div>
  </div>
</section>

<section>
  @if (showButtons) {

  <div class="scrollbtns d-flex justify-content-between">
    <img
      id="left-button"
      (click)="scrollLeft()"
      class="button"
      src="assets/images/left-blue-arrow.svg"
      alt=""
    />
    <img
      id="right-button"
      (click)="scrollRight()"
      class="button"
      src="assets/images/right-blue-arrow.svg"
      alt=""
    />
  </div>

  }

  <div class="d-flex justify-content-between align-items-center mx-1">
    <p
      class="text-start tblcompanyLength my-1 text-nowrap d-flex align-items-center"
    >
      <i class="fa-solid fa-circle-info mx-2"></i>
      Showing {{ ISTable.length }} Trades Out of {{ totalDatalength }}
    </p>
    <app-multiselect
      [list]="list"
      (select_all)="Select_All_checkbox()"
      (shareCheckedList)="shareCheckedList($event)"
      (shareIndividualCheckedList)="shareIndividualCheckedList($event)"
    >
    </app-multiselect>
  </div>

  <div style="position: relative">
    <div
      class="table-responsive scrollable-table"
      id="dataContainer"
      #dataContainer
      (scroll)="onScroll($event)"
      [ngClass]="{ tablelocked: !productId }"
    >
      <table #table class="table align-middle mb-0 bg-white">
        <thead class="text-center">
          <tr class="text-uppercase table-header">
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                Name<i
                  (click)="assending('companyname')"
                  [ngClass]="arrowname"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>

            @if (ticker) {
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                Ticker<i
                  (click)="assending('symbol')"
                  [ngClass]="arrowticker"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            } @if (price) {
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                price<i
                  (click)="assending('closeprice')"
                  [ngClass]="arrowprice"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            } @if (thirtydaypricechange) {
            <th
              placement="bottom"
              container="body"
              ngbTooltip="30 days of price change"
            >
              30 DAYS PRICE CHANGE
            </th>
            } @if (industry) {
            <th>Industry</th>
            } @if (transactor_name) {
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                Transactor name
                <!-- <i (click)="assending('name')"
                [ngClass]="arrowtraname" class="fa-solid cursorpointer mx-1"></i> -->
              </div>
            </th>
            }

            <!-- @if (relationship) {
          <th>
            <div class="d-flex justify-content-center cursor-pointer">RELATIONSHIP
            </div>
          </th>
          } -->

            <!-- @if (buy_sell) {
          <th>
            buy/Sale
          </th>
          } -->

            @if(traded_share){
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                Traded shares<i
                  (click)="assending('change')"
                  [ngClass]="arrowtshares"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            } @if (traded_price) {
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                trade price<i
                  (click)="assending('transactionPrice')"
                  [ngClass]="arrowtprice"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            } @if (traded_value) {
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                trade value<i
                  (click)="assending('traded_value')"
                  [ngClass]="arrowtvalue"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            } @if (traded_date) {
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                Trade Date<i
                  (click)="assending('transactionDate')"
                  [ngClass]="arrowtdate"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            } @if (current_holding) {
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                Current Holdings<i
                  (click)="assending('share')"
                  [ngClass]="arrowcholdings"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            } @if (form_4) {
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                Sec Submission
                <!-- <i (click)="assending('form_4')"
                [ngClass]="arrowcholdings" class="fa-solid cursorpointer mx-3"></i> -->
              </div>
            </th>
            }
          </tr>
        </thead>
        <tbody class="text-center">
          <!-- <tr *ngIf="isLoading" class="loading-border">
          <td colspan="12">
            <div class="table-loading-container">
              <span class="loader">Fetching more data for you</span>
            </div>
          </td>
        </tr> -->
          @if (ISTable.length == 0) {
          <tr style="box-shadow: none">
            <td colspan="17">
              <ngx-skeleton-loader
                [theme]="{
                  height: '50px',
                  'border-radius': '4px',
                  'margin-top': '20px'
                }"
                count="11"
              >
              </ngx-skeleton-loader>
            </td>
          </tr>
          }@else { @for (it of ISTable; track $index) {
          <tr class="table-tr-font">
            <td scope="row">
              <div
                class="text-center company-hover"
                (click)="companyDetails(it.symbol)"
              >
                @if (it.logoid !== '') {
                <img
                  width="25px"
                  [src]="basePath + it.logoid + '.svg'"
                  class="mb-1"
                />
                }@else {
                <span class="avatarcssForTable">{{
                  it.companyname | initialLetter
                }}</span>
                }
                <p class="text-center text-data table-font-size">
                  <a
                    href="{{ clientUrl }}/company/{{ it.symbol | uppercase }}"
                    class="link-ticker"
                  >
                    {{ it.companyname }}
                  </a>
                </p>
              </div>
            </td>

            @if (ticker) {
            <td class="table-wide-cell">
              <div class="d-grid justify-content-center">
                <p class="table-astec-font table-font-size text-center">
                  <a
                    href="{{ clientUrl }}/company/{{ it.symbol | uppercase }}"
                    class="link-ticker"
                  >
                    {{ it.symbol }}
                  </a>
                </p>

                @if (it.symbol) { @if (portfoliomatchTicker) { @if
                (portfoliomatchTicker.includes(it.symbol)) {
                <i
                  placement="bottom"
                  container="body"
                  ngbTooltip="Remove from Portfolio"
                  (click)="removeSymboltoPotfoliyo(it.symbol)"
                  style="
                    opacity: unset;
                    font-size: 20px;
                    color: var(--clr-primary);
                  "
                  class="fa-solid fa-circle-minus my-2 cursorpointer"
                ></i>
                }@else {
                <i
                  placement="bottom"
                  container="body"
                  ngbTooltip="Add to Portfolio"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  (click)="
                    this.portfolioTicker = it.symbol;
                    portfoliocost = undefined;
                    this.portfolioPrice = it.closeprice;
                    portfolioquntity = undefined
                  "
                  style="
                    opacity: unset;
                    font-size: 20px;
                    color: var(--clr-primary);
                  "
                  class="fa-solid fa-circle-plus my-2 cursorpointer"
                ></i>
                } } @if (!portfoliomatchTicker) {
                <i
                  placement="bottom"
                  container="body"
                  ngbTooltip="Add to Portfolio"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  (click)="
                    this.portfolioTicker = it.symbol;
                    this.portfolioPrice = it.closeprice;
                    portfoliocost = undefined;
                    portfolioquntity = undefined
                  "
                  style="
                    opacity: unset;
                    font-size: 20px;
                    color: var(--clr-primary);
                  "
                  class="fa-solid fa-circle-plus my-2 cursorpointer text-center"
                ></i>
                } }
              </div>
            </td>
            } @if (price) {
            <td>
              <p class="table-font-size">
                {{ it.closeprice | number : "1.0-2" }}
              </p>
              @if (it.varprice) {

              <p
                class="table-price table-font-size"
                id="table-price-color"
                [style.color]="it.varprice.toString() | riseAndFallColor"
              >
                {{ it.varprice | number : "1.0-2" }}%
              </p>
              }
            </td>
            }

            <td scope="row" class="d-none">{{ $index + 1 }}</td>

            @if (thirtydaypricechange) { @if(it.thirtyDayChart){
            <td>
              <app-thirty-day-trend
                class="d-flex justify-content-center"
                [chartData]="it.thirtyDayChart"
                [x]="$index"
              ></app-thirty-day-trend>
            </td>
            }@else { - } } @if (industry) {
            <td class="table-font-size">
              @if (it.Industry) {
              <p>{{ it.Industry }}</p>
              }@else {
              <p>-</p>
              }
            </td>
            } @if (transactor_name) {
            <td class="">
              <p class="table-font-size">
                {{ it.name }}
              </p>
              @if (relationship) {
              <i class="table-font-italic">{{ it.relationship }}</i>
              }
            </td>
            } @if (traded_share) {
            <td>
              @if (buy_sell) {
              <!-- <td> -->
              <p class="table-font-size">
                @if(it.buy_sell=='Buy') {
                <span style="color: var(--clr-green)">{{ it.buy_sell }}</span>
                }@else if(it.buy_sell=='Sale') {
                <span style="color: var(--clr-red)">{{ it.buy_sell }}</span>
                }@else if(it.buy_sell=='Option Exercise'){
                <span style="color: var(--clr-primary)">Option</span>

                }
              </p>
              <!-- </td> -->
              } @if(it.buy_sell=='Buy') {
              <p class="table-font-size" style="color: var(--clr-green)">
                {{ it.change | dollarsCoversion }}
              </p>
              }@else if(it.buy_sell=='Sale') {
              <p class="table-font-size" style="color: var(--clr-red)">
                - {{ it.change | dollarsCoversion }}
              </p>
              }@else if(it.buy_sell=='Option Exercise'){
              <p class="table-font-size" style="color: var(--clr-primary)">
                {{ it.change | dollarsCoversion }}
              </p>

              }
              <!-- <p class="table-font-size" [style.color]="it.change">
              {{ it.change | dollarsCoversion }}
            </p> -->
            </td>
            } @if(traded_price){
            <td class="table-font-size">
              {{ it.transactionPrice }}
            </td>
            } @if(traded_value){
            <td class="table-font-size">
              {{ it.traded_value | dollarsCoversion }}
            </td>
            } @if (traded_date) {
            <td class="table-font-size text-nowrap">
              {{ it.transactionDate }}
            </td>
            } @if (current_holding) {
            <td class="table-font-size">
              {{ it.share | dollarsCoversion }}
            </td>
            } @if (form_4) {
            <td class="table-font-size">
              {{ it.form_4 }}
            </td>
            }
          </tr>
          }@empty { No Record Found } }
        </tbody>
        <tfoot>
          @if (loadData) {
          <tr class="loading-border">
            <td colspan="12">
              <div class="data-loading-container">
                <p class="Fetching me-3 mb-0 mt-0">
                  Fetching more data for you
                </p>
                <span class="loader mb-2" style="font-size: 3px"> </span>
              </div>
            </td>
          </tr>
          }
        </tfoot>
      </table>
    </div>
    <div
      style="
        position: absolute;
        bottom: 0;
        background-color: #f1f7fde6;
        z-index: 1;
      "
    >
      @if (!productId) { @if (ISTable.length > 0) {
      <app-locksec></app-locksec>
      } }
    </div>
  </div>

  <app-loginpop-up
    [displayStyle]="modalDisplayStyle"
    (myout)="GetChildData($event)"
  ></app-loginpop-up>
  <app-portfoliomodal
    [portfolioTicker]="portfolioTicker"
    [portfolioPrice]="portfolioPrice"
    [portfoliocost]="portfoliocost"
    [portfolioquntity]="portfolioquntity"
    (addSymbol)="onAddSymbol($event)"
  ></app-portfoliomodal>
</section>
