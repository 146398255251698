<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngStyle]="{ display: displayStyle ? 'block' : 'none' }"
>
  @if (!this.assectoken) {
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content">
      <div class="container p-0">
        <div class="row">
          <div
            class="col-md-6 d-flex align-items-center px-5 justify-content-center login-popup-backgroung-img rounded-left"
          >
            <div class="">
              <!-- <img src="assets/images/popup-img.svg"  alt="" class="img-fluid"> -->

              <form
                [formGroup]="loginform"
                (ngSubmit)="login(loginform.value)"
                class="from-center"
              >
                <div class="text-center">
                  <img
                    src="assets/images/popup-logo-img.svg"
                    alt=""
                    class="logo-img"
                  />
                </div>

                <div class="my-3">
                  <div class="input-group mb-3">
                    <span class="input-group-text"
                      ><i class="fa-solid fa-envelope"></i
                    ></span>
                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control"
                        formControlName="email"
                        id="floatingInputGroup1"
                        placeholder="Password"
                      />
                      <label for="floatingInputGroup1">Email</label>
                    </div>
                  </div>
                  <!-- <i class="fa-solid fa-envelope float-r ps-2 mt-1"></i> -->
                  @if ( loginform.controls['email'].invalid
                  &&(loginform.controls['email'].dirty ||
                  loginform.controls['email'].touched ||submitted)) {
                  <div class="mb-1">
                    @if (loginform.controls['email'].errors?.['required']) {
                    <span class="form-error mb-1">Email is required.</span>
                    }@else {
                    <span class="form-error">Enter correct email address.</span>
                    }
                  </div>
                  }
                  <!-- <label class="label popup-label" for="floatingInput">Email </label> -->
                </div>

                <div class="form-floating mb-3">
                  <div class="input-group mb-3">
                    <span class="input-group-text">
                      <i
                        class="fa-solid fa-lock"
                        (click)="showPassword = !showPassword"
                        [ngClass]="{
                          'fa-eye': !showPassword,
                          'fa-eye-slash': showPassword
                        }"
                      ></i
                    ></span>
                    <div class="form-floating">
                      <input
                        [type]="showPassword ? 'text' : 'password'"
                        class="form-control"
                        id="floatingInputGroup2"
                        formControlName="password"
                        placeholder="Password"
                      />
                      <label for="floatingInputGroup2">Password</label>
                    </div>
                  </div>
                  @if ( loginform.controls['password'].invalid &&
                  (loginform.controls['password'].dirty ||
                  loginform.controls['password'].touched || submitted)) {
                  <div class="mb-1">
                    @if (loginform.controls['password'].errors?.['required']) {
                    <span class="form-error">Password is required.</span>
                    }@else {
                    <span class="form-error">Enter correct Password.</span>
                    }
                  </div>
                  }
                </div>

                <!-- <div class="mb-3">
                  <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha">
                  </ngx-recaptcha2>
                  <div *ngIf="
                  loginform.controls['recaptcha']?.invalid &&
                  (loginform.controls['recaptcha'].dirty ||
                  loginform.controls['recaptcha'].touched||submitted)
                " class="mb-1">
                    <span class="form-error mb-1" *ngIf="loginform.controls['recaptcha'].errors?.['required']">recaptcha
                      is
                      required.</span>
                  </div>
                </div> -->

                <div
                  class="d-flex justify-content-end login-forget-text mb-3 mt-3"
                >
                  <a
                    (click)="forgetpass()"
                    routerLink="/login"
                    class="text-decoration-none text-nowrap forget-password linkcolor Forgot-password"
                    >Forgot password?</a
                  >
                </div>

                <div class="Login-Now">
                  <button class="Login-Now-btn" type="submit">
                    @if (isLoading) {
                    <i class="fa fa-spin fa-spinner"></i>
                    }@else {
                    <span class="linkcolor">Login Now</span>
                    }
                  </button>
                </div>

                <!-- <hr class="hr-text m-0 mb-3 mt-3" data-content="OR" />  -->
                <div class="col-md-12 mt-3">
                  <div class="login-or">
                    <hr class="hr-or" />
                    <span class="span-or"> OR </span>
                  </div>
                </div>

                <div class="d-grid mt-3">
                  <button
                    class="btn btn-google btn-login g-color mb-3 p-2"
                    (click)="signInWithGoogle()"
                    type="button"
                  >
                    @if (googleLoading) {
                    <i class="fa fa-spin fa-spinner"></i>
                    }@else {
                    <div class="google-text-size">
                      <img
                        class="me-0"
                        src="assets/images/google-icon.svg"
                        alt=""
                      />
                      <samp class="Login-with"> Login with </samp> Google
                    </div>
                    }
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div class="col-md-6 my-auto">
            <div class="text-end cursor-pointer" (click)="closePopup()">
              <i class="fa-regular fa-circle-xmark"></i>
            </div>
            <div class="text-center mx-2 mt-3">
              <h2 class="text-dark m-0 mb-3">
                Start making profits in the Biotech and Pharma market today!
              </h2>
              <div class="mb-2">
                <div
                  class="d-flex mb-2 justify-content-start align-items-center"
                >
                  <i class="fa fa-check-circle text-success me-2"></i>
                  <p class="m-0 text-start text-dark">
                    Searchable FDA calendar with data readouts and PDUFA
                    catalysts.
                  </p>
                </div>
                <div
                  class="d-flex mb-2 justify-content-start align-items-center"
                >
                  <i class="fa fa-check-circle text-success me-2"></i>
                  <p class="m-0 text-start text-dark">
                    Full access to drug pipelines, company health, and clinical
                    trial research on over 1,100 companies.
                  </p>
                </div>
                <div
                  class="d-flex mb-2 justify-content-start align-items-center"
                >
                  <i class="fa fa-check-circle text-success me-2"></i>
                  <p class="m-0 text-start text-dark">
                    Track biotech earnings, catalyst events, insider trading,
                    and hedge fund holdings.
                  </p>
                </div>
                <div
                  class="d-flex mb-2 justify-content-start align-items-center"
                >
                  <i class="fa fa-check-circle text-success me-2"></i>
                  <p class="m-0 text-start text-dark">
                    Advanced portfolio management tools with real-time market
                    updates, trends, and analysis.
                  </p>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-center">
              <div class="testimonial-card mb-4" [@slideInOut]="currentState">
                <div class="stars text-center ms-2 mb-2">
                  &#9733;&#9733;&#9733;&#9733;&#9733;
                </div>
                <div class="d-flex justify-content-center align-items-center">
                 
                  <div>
                    <h4 class="m-0 text-center">{{ displayedTestimonial.name }}</h4>
                    <div class="d-flex justify-content-center align-items-center">
                      <span>{{ displayedTestimonial.position }}</span>
                      <span
                        ><img
                          class="img-fluid mx-2"
                          width="30px"
                          src="{{ displayedTestimonial.country }}"
                          alt=""
                      /></span>
                    </div>
                    <div class="d-flex justify-content-center mt-1">
                      <span style="font-size: 13px"
                        >{{displayedTestimonial.address}}</span
                      >
                    </div>
                  </div>
                </div>
                <p class="my-2 text-center">{{ displayedTestimonial.content }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  }@else {
  <div
    class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
    role="document"
  >
    <div class="modal-content">
      <div
        style="
          justify-content: space-between;
          align-items: center;
          border: none;
          padding: 10px;
        "
        class="modal-header login-popup-backgroung-img-for-sub"
      >
        <div>
          <img
            src="assets/images/popup-logo-img.svg"
            alt=""
            class="logo-img-sub ms-2"
          />
        </div>
        <button (click)="closePopup()" type="button" class="btn closebtn">
          <i
            class="fa-solid fa-xmark"
            style="opacity: unset; cursor: pointer; color: white"
          ></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-12">
          <div class="row align-items-center py-2 addlock-style">
            <div class="text-center">
              <button routerLink="/subscription" class="form-save-button mb-3">
                Get Started
              </button>
            </div>
            <div class="col-12 col-md-6 order-first-mobile">
              <div class="row text-start">
                <div class="col-12 mb-2">
                  <div class="fw-bold">
                    <i
                      style="color: rgb(7, 175, 7); opacity: unset"
                      class="fa-solid fa-circle-check mx-2 mt-1"
                    ></i>
                    <p class="m-0">Full access to a searchable FDA calendar</p>
                  </div>
                </div>
                <div class="col-12 mb-2">
                  <div class="fw-bold">
                    <i
                      style="color: rgb(7, 175, 7); opacity: unset"
                      class="fa-solid fa-circle-check mx-2 mt-1"
                    ></i>
                    <p class="m-0">
                      Detailed monitoring of upcoming PDUFA dates and historical
                      data
                    </p>
                  </div>
                </div>
                <div class="col-12 mb-2">
                  <div class="fw-bold">
                    <i
                      style="color: rgb(7, 175, 7); opacity: unset"
                      class="fa-solid fa-circle-check mx-2 mt-1"
                    ></i>
                    <p class="m-0">
                      Bio hedge fund screener and holdings analysis
                    </p>
                  </div>
                </div>
                <div class="col-12 mb-2">
                  <div class="fw-bold">
                    <i
                      style="color: rgb(7, 175, 7); opacity: unset"
                      class="fa-solid fa-circle-check mx-2 mt-1"
                    ></i>
                    <p class="m-0">
                      Personalized portfolio management with advanced tracking
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- Column 2 -->
            <div class="col-12 col-md-6">
              <div class="row text-start">
                <div class="col-12 mb-2">
                  <div class="fw-bold">
                    <i
                      style="color: rgb(7, 175, 7); opacity: unset"
                      class="fa-solid fa-circle-check mx-2 mt-1"
                    ></i>
                    <p class="m-0">
                      Clinical trials and drug pipeline analysis
                    </p>
                  </div>
                </div>
                <div class="col-12 mb-2">
                  <div class="fw-bold">
                    <i
                      style="color: rgb(7, 175, 7); opacity: unset"
                      class="fa-solid fa-circle-check mx-2 mt-1"
                    ></i>
                    <p class="m-0">
                      Comprehensive research on over 1,100 companies
                    </p>
                  </div>
                </div>
                <div class="col-12 mb-2">
                  <div class="fw-bold">
                    <i
                      style="color: rgb(7, 175, 7); opacity: unset"
                      class="fa-solid fa-circle-check mx-2 mt-1"
                    ></i>
                    <p class="m-0">
                      Stay updated on all biotech earnings announcements
                    </p>
                  </div>
                </div>
                <div class="col-12 mb-2">
                  <div class="fw-bold">
                    <i
                      style="color: rgb(7, 175, 7); opacity: unset"
                      class="fa-solid fa-circle-check mx-2 mt-1"
                    ></i>
                    <p class="m-0">Insider trading monitoring</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  }
</div>
