<section>
  <div class="mt-3 mb-3">
    <h1 class="main-heading text-center">Get in Touch</h1>
  </div>
</section>

<section class="mb-3 section-height">
  <div class="container">
    <p class="sub-heading text-center">
      Connect with BiopharmaWatch for tailored support in your biotech and
      pharma investment journey. Our team is ready to address your queries and
      enhance your platform experience. Also, explore our Terms of Service for a
      clear understanding of guidelines and expectations on our innovative
      platform, ensuring a transparent and triumphant investment experience.
    </p>
  </div>

  <div class="container main-form-div w-50">
    <h1 class="main-heading text-center my-3">Contact Us</h1>

    <form
      [formGroup]="conatact_us"
      (ngSubmit)="submit(conatact_us.value)"
      class="row g-3"
    >
      <div class="col-md-6">
        <input
          type="text"
          formControlName="name"
          class="form-control forms-input shadow-sm rounded"
          id="Name"
          placeholder="Name"
        />

        @if ( conatact_us.controls['name'].invalid &&
        (conatact_us.controls['name'].dirty ||
        conatact_us.controls['name'].touched || submitted)) {
        <div class="mb-1">
          @if (conatact_us.controls['name'].errors?.['required']) {
          <span class="form-error mb-1">Name is required.</span>
          }@else {
          <span class="form-error">Enter correct name address.</span>
          }
        </div>
        }
      </div>
      <div class="col-md-6">
        <input
          type="email"
          formControlName="email"
          class="form-control forms-input shadow-sm rounded"
          id="email"
          placeholder="Email"
        />

        @if ( conatact_us.controls['email'].invalid &&
        (conatact_us.controls['email'].dirty ||
        conatact_us.controls['email'].touched || submitted)) {
        <div class="mb-1">
          @if (conatact_us.controls['email'].errors?.['required']) {
          <span class="form-error mb-1">Email is required.</span>
          }@else {
          <span class="form-error">Enter correct email address.</span>
          }
        </div>
        }
      </div>
      <div class="col-6">
        <input
          type="text"
          formControlName="subject"
          class="form-control forms-input shadow-sm rounded"
          id="inputAddress"
          placeholder="Subject"
        />
      </div>
      <div class="col-6">
        <input
          type="number"
          formControlName="phone"
          class="form-control forms-input shadow-sm rounded"
          id="inputAddress"
          placeholder="Phone-Number"
        />
      </div>
      <div class="col-12">
        <textarea
          class="form-control forms-input shadow-sm rounded"
          id="inputAddress"
          formControlName="feedback"
          placeholder="Feedback"
        ></textarea>
      </div>
      <div class="text-center">
        <button type="submit" class="btn submit-btn shadow-lg">Submit</button>
      </div>
    </form>
  </div>
</section>
