<section class="volatile">
  <div class="container-fluid p-2 fda-header-box">
    <div class="text-center fda-text">
      <h1 class="main-heading text-white m-0 me-3">FDA Calendar</h1>

      <p class="sub-heading text-white m-0">
        Our Free FDA Calendar is a daily updated tool that tracks future
        catalysts and key dates across biotech and pharma companies, including
        clinical trials, regulatory events, and PDUFA dates, designed to inform
        on stock-moving events
      </p>
    </div>
  </div>

  <div class="container-fluid form-box py-2">
    <div class="row justify-content-center align-items-start mt-1">
      <div class="col-lg-3 col-md-10 col-12">
        <form style="height: 30px" class="example d-flex add_m_infilter">
          <input
            [(ngModel)]="searchValuequery"
            autocomplete="off"
            (keyup)="searchFilters($event)"
            (input)="onSearchInput($event)"
            #searchVal
            type="search"
            class="input-box"
            placeholder="Search by Comapny name or Ticker"
            name="search2"
          />
          <button
            (click)="searchFiltersonclick(searchValuequery)"
            class="searchquerybtn"
            type="button"
          >
            @if (isFilterBtnSearch) {
            <i style="color: white" class="fa fa-spin fa-spinner"></i>

            }@else {
            <i class="fa-solid fa-magnifying-glass"></i>
            }
          </button>
        </form>
        @if (suggection?.length > 0) {
        <ul class="list-unstyled m-0">
          @for (suggestion of suggection; track $index) {
          <li class="suggestion-item p-0 cursorpointer">
            <div
              (click)="searchFiltersonclick(suggestion.symbol)"
              class="d-flex filter-bottom justify-content-between px-2"
            >
              <span>
                @if (suggestion.logoid !== '') {
                <img
                  width="25px"
                  [src]="basePath + suggestion.logoid + '.svg'"
                />
                }@else {
                <span
                  class="avatarcss"
                  style="
                    width: 25px !important;
                    height: 25px !important;
                    margin: 0 !important;
                  "
                  >{{ suggestion.name | initialLetter }}</span
                >
                }
              </span>
              <p
                class="text-start text-dark filter-font-text mb-1 border-1 me-4"
              >
                {{ suggestion.name }}
              </p>
              <p class="text-end text-dark filter-font-text mb-1">
                {{ suggestion.symbol }}
              </p>
            </div>
          </li>
          }
        </ul>
        }
      </div>
      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedDataColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            #priceinput
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            Price
            <i class="fa fa-caret-down ms-4"></i>
          </button>
          @if (selectedDataColor) {
          <span (click)="clearSelectedPrice()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }
          <ul class="dropdown-menu games-menu">
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('less_than_1 $')"
                [class.selected]="'less_than_1 $' === selectedValue"
              >
                Less than 1 $
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('1$ to 2$')"
                [class.selected]="'1$ to 2$' === selectedValue"
              >
                1 $ to 2 $
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('2$ to 5$')"
                [class.selected]="'2$ to 5$' === selectedValue"
              >
                2 $ to 5 $
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('5$ to 50$')"
                [class.selected]="'5$ to 50$' === selectedValue"
              >
                5 $ to 50 $
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('50$ to 100$')"
                [class.selected]="'50$ to 100$' === selectedValue"
              >
                50 $ to 100 $
              </p>
            </li>
            <li>
              <button
                class="dropdown-item dropdown-toggle sub-menu dropdown-item filter-font-text text-center m-0"
                data-bs-toggle="dropdown"
              >
                Customize
              </button>
              <ul class="dropdown-menu games-menu">
                <div class="p-1" aria-labelledby="shareHoldDropdown">
                  <input
                    type="number"
                    class="custom-input-set mb-2"
                    placeholder="Min Value"
                    [(ngModel)]="minCustomValue"
                    (input)="updateCustomRange()"
                    (change)="pushFilterminPrice($event)"
                  />
                  <input
                    type="number"
                    class="custom-input-set"
                    placeholder="Max Value"
                    [(ngModel)]="maxCustomValue"
                    (input)="updateCustomRange()"
                    (change)="pushFiltermaxPrice($event)"
                  />
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedchangeColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <span>Change in Price</span>
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if (selectedchangeColor) {
          <span class="" (click)="clearSelectedChangePrice()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }

          <ul class="dropdown-menu">
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('less_than_1 %')"
                [class.selected]="'less_than_1 %' === selectedValueinchange"
              >
                Less than 1 %
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('1% to 2%')"
                [class.selected]="'1% to 2%' === selectedValueinchange"
              >
                1 % to 2 %
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('2% to 5%')"
                [class.selected]="'2% to 5%' === selectedValueinchange"
              >
                2 % to 5 %
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('5% to 10%')"
                [class.selected]="'5% to 10%' === selectedValueinchange"
              >
                5 % to 10 %
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('10% to 50%')"
                [class.selected]="'10% to 50%' === selectedValueinchange"
              >
                10 % to 50 %
              </p>
            </li>
            <li>
              <button
                class="dropdown-item dropdown-toggle dropdown-item filter-font-text text-center m-0"
                data-bs-toggle="dropdown"
              >
                Customize
              </button>
              <ul class="dropdown-menu">
                <div class="p-1" aria-labelledby="shareHoldDropdown">
                  <input
                    type="number"
                    class="custom-input-set mb-2"
                    placeholder="Min Value"
                    [(ngModel)]="minCustomchange"
                    (input)="updateChangePriceCustomRange()"
                    (change)="pushFilterChange($event)"
                  />
                  <input
                    type="number"
                    class="custom-input-set"
                    placeholder="Max Value"
                    [(ngModel)]="maxCustomchange"
                    (input)="updateChangePriceCustomRange()"
                    (change)="EndpushFilterChange($event)"
                  />
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedDateColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <span>Date</span>
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if (selectedDateColor) {
          <span class="" (click)="clearSelectedDate()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }

          <ul class="dropdown-menu">
            <li>
              <p
                [class.selected]="'This Week' === selectedValueinDate"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('This Week')"
              >
                This Week
              </p>
            </li>
            <li>
              <p
                [class.selected]="'This Month' === selectedValueinDate"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('This Month')"
              >
                This Month
              </p>
            </li>
            <li>
              <p
                [class.selected]="'Next Week' === selectedValueinDate"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('Next Week')"
              >
                Next Week
              </p>
            </li>
            <li>
              <p
                [class.selected]="'Next Month' === selectedValueinDate"
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                (click)="selectedData('Next Month')"
              >
                Next Month
              </p>
            </li>
            <li>
              <button
                class="dropdown-item dropdown-toggle dropdown-item filter-font-text text-center m-0"
                data-bs-toggle="dropdown"
              >
                Customize
              </button>
              <ul class="dropdown-menu">
                <div class="p-1" aria-labelledby="shareHoldDropdown">
                  <input
                    type="date"
                    class="custom-input-set mb-2"
                    placeholder="Min Value"
                    [(ngModel)]="minCustomDate"
                    (input)="updateDateCustomRange()"
                    (change)="pushFilterDateStart($event)"
                  />
                  <input
                    type="date"
                    class="custom-input-set"
                    placeholder="Max Value"
                    [(ngModel)]="maxCustomDate"
                    (input)="updateDateCustomRange()"
                    (change)="pushFilterDateEnd($event)"
                  />
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedStageColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <span>Stage</span>
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if(selectedStageColor){
          <span class="" (click)="clearSelectedCheckbox()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }

          <ul class="dropdown-menu">
            <div class="form-check form-switch p-2 m-0">
              <label class="form-check-label p-0 m-0" for="selectAllCheckbox"
                >Select</label
              >
              <input
                class="form-check-input"
                type="checkbox"
                id="selectAllCheckbox"
                (change)="toggleSelectAll($event)"
              />
            </div>
            <div class="p-3 pt-1">
              @for (item of selectlist; track $index) {
              <div>
                <label class="filter-font-text">
                  <input
                    type="checkbox"
                    (change)="pushFilterstage()"
                    [(ngModel)]="item.checked"
                  />
                  {{ item.name }}
                </label>
              </div>
              }@empty { No Record Found }
            </div>
          </ul>
        </div>
      </div>

      <!-- Specific_Diseases -->
      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedDiseasesColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <span>Specific Diseases</span>
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if(selectedDiseasesColor){
          <span class="" (click)="clearDiseasesCheckbox()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }

          <ul class="dropdown-menu">
            <div class="form-check form-switch p-2 m-0">
              <label
                class="form-check-label p-0 m-0"
                for="selectDiseasesCheckbox"
                >Select</label
              >
              <input
                class="form-check-input"
                type="checkbox"
                id="selectDiseasesCheckbox"
                (change)="toggleDiseasesSelect($event)"
              />
            </div>
            <div class="p-3 pt-1">
              @for (item of diseasesList; track $index) {
              <div>
                <label class="filter-font-text">
                  <input
                    type="checkbox"
                    (change)="pushFilterDiseases()"
                    [(ngModel)]="item.checked"
                  />
                  {{ item.name }}
                </label>
              </div>
              }@empty { No Record Found }
            </div>
          </ul>
        </div>
      </div>

      <!-- Therapeutic_area -->
      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedTherapeuticColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <span>Therapeutic Area</span>
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if(selectedTherapeuticColor){
          <span class="" (click)="clearTherapeuticCheckbox()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }

          <ul class="dropdown-menu">
            <div class="form-check form-switch p-2 m-0">
              <label
                class="form-check-label p-0 m-0"
                for="selectTheraputicsCheckbox"
                >Select</label
              >
              <input
                class="form-check-input"
                type="checkbox"
                id="selectTheraputicsCheckbox"
                (change)="toggleTheraputics($event)"
              />
            </div>
            <div class="p-3 pt-1">
              @for (item of therapeuticList; track $index) {
              <div>
                <label class="filter-font-text">
                  <input
                    type="checkbox"
                    (change)="pushFilterTherapeutic()"
                    [(ngModel)]="item.checked"
                  />
                  {{ item.name }}
                </label>
              </div>
              }@empty { No Record Found }
            </div>
          </ul>
        </div>
      </div>

      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedindustryColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <span>Industry</span>
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if (selectedindustryColor) {

          <span class="" (click)="clearSelectedIndustry()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>

          }
          <ul class="dropdown-menu">
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'Biotechnology' === selectedValueinindustry"
                (click)="selectedData('Biotechnology')"
              >
                Biotechnology
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'Medical Device' === selectedValueinindustry"
                (click)="selectedData('Medical Device')"
              >
                Medical Device
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="
                  'Diagnostics & Research' === selectedValueinindustry
                "
                (click)="selectedData('Diagnostics & Research')"
              >
                Diagnostics & Research
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center m-0"
                [class.selected]="
                  'Drug Manufacturers' === selectedValueinindustry
                "
                (click)="selectedData('Drug Manufacturers')"
              >
                Drug Manufacturers
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-auto p-0 add_m_infilter col-md-3 col-6">
        <div class="dropdown mx-2 d-flex justify-content-center">
          <button
            [ngClass]="selectedEventColor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <span>Event type</span>
            <i class="fa fa-caret-down ms-4"></i>
          </button>
          @if (selectedEventColor) {

          <span class="" (click)="clearSelectedEvent()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>

          }
          <ul class="dropdown-menu">
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'BLA Filing' === selectedValueinEvent"
                (click)="selectedData('BLA Filing')"
              >
                BLA Filing
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'Clinical Update' === selectedValueinEvent"
                (click)="selectedData('Clinical Update')"
              >
                Clinical Update
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'IND Filing' === selectedValueinEvent"
                (click)="selectedData('IND Filing')"
              >
                IND Filing
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'NDA Filing' === selectedValueinEvent"
                (click)="selectedData('NDA Filing')"
              >
                NDA Filing
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'PDUFA Date' === selectedValueinEvent"
                (click)="selectedData('PDUFA Date')"
              >
                PDUFA Date
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'Phase 1 Readout' === selectedValueinEvent"
                (click)="selectedData('Phase 1 Readout')"
              >
                Phase 1 Readout
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'Phase 2 Readout' === selectedValueinEvent"
                (click)="selectedData('Phase 2 Readout')"
              >
                Phase 2 Readout
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center filter-bottom m-0"
                [class.selected]="'Phase 3 Readout' === selectedValueinEvent"
                (click)="selectedData('Phase 3 Readout')"
              >
                Phase 3 Readout
              </p>
            </li>
            <li>
              <p
                class="dropdown-item filter-font-text text-center m-0"
                [class.selected]="'Top-line Data' === selectedValueinEvent"
                (click)="selectedData('Top-line Data')"
              >
                Top-line Data
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div id="add" class="text-center mt-1 d-flex justify-content-center">
      <button
        type=""
        class="form-save-button d-flex justify-content-center align-items-center text-nowrap me-3 mt-2 mb-1"
        (click)="saveFilters()"
      >
        @if (!isFilterBtn) {
        <p class="mb-0">APPLY FILTERS</p>
        }@else {
        <i class="fa fa-spin fa-spinner"></i>
        }
      </button>
      <button
        type=""
        class="form-reset-button d-flex justify-content-center align-items-center text-nowrap me-3 mt-2 mb-1"
        (click)="resetFilters()"
      >
        @if (!isFilterBtnResert) {
        <p class="mb-0">RESET FILTERS</p>
        }@else {
        <i class="fa fa-spin fa-spinner"></i>
        }
      </button>
    </div>
  </div>
</section>

<section class="volatile">
  @if (showButtons) {

  <div class="scrollbtns d-flex justify-content-between">
    <img
      id="left-button"
      (click)="scrollLeft()"
      class="button"
      src="assets/images/left-blue-arrow.svg"
      alt=""
    />
    <img
      id="right-button"
      (click)="scrollRight()"
      class="button"
      src="assets/images/right-blue-arrow.svg"
      alt=""
    />
  </div>

  }

  <div class="d-flex justify-content-between align-items-center mx-1">
    <p
      class="text-start tblcompanyLength my-1 text-nowrap d-flex align-items-center"
    >
      <i class="fa-solid fa-circle-info mx-2"></i>
      Showing {{ fdaTable.length }} Drugs Out of {{ totalDatalength }}
    </p>
    <app-multiselect
      [list]="list"
      (select_all)="Select_All_checkbox()"
      (shareCheckedList)="shareCheckedList($event)"
      (shareIndividualCheckedList)="shareIndividualCheckedList($event)"
    >
    </app-multiselect>
  </div>

  <div style="position: relative">
    <div
      class="table-responsive scrollable-table"
      id="dataContainer"
      #dataContainer
      (scroll)="onScroll($event)"
      [ngClass]="{ tablelocked: !productId }"
    >
      <table class="table align-middle mb-0 bg-white">
        <thead class="text-center">
          <tr class="text-uppercase table-header">
            <th class="table-name-size">
              <div class="d-flex justify-content-center cursor-pointer">
                NAME<i
                  (click)="assending('name')"
                  [ngClass]="arrowname"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>

            @if (ticker) {
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                TICKER<i
                  (click)="assending('ticker')"
                  [ngClass]="arrowticker"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            } @if (price) {
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                PRICE<i
                  (click)="assending('close')"
                  [ngClass]="arrowprice"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            } @if (thirtydaypricechange) {
            <th>30 DAYS PRICE CHANGE</th>
            }

            <!-- @if (industry) {
          <th>Industry</th>
          } -->
            @if (market_cap_basic) {

            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                market capital<i
                  (click)="assending('market_cap_basic')"
                  [ngClass]="arrowdrug"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            } @if (volume) {

            <th>Volume</th>
            } @if (hedgeFunds) {

            <th>Hedge funds</th>
            }
            <th>Insiders</th>
            <th>Options</th>

            @if (catalyst) {
            <th>Event Type</th>
            } @if (drug) {
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                DRUG<i
                  (click)="assending('drugname')"
                  [ngClass]="arrowdrug"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            } @if (catalystdate) {
            <th class="text-nowrap">
              <div class="d-flex justify-content-center cursor-pointer">
                CATALYST DATE<i
                  (click)="assending('catdate')"
                  [ngClass]="arrowcatadate"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            }
            <!-- <th class="text-nowrap">
            Options
          </th> -->

            @if (trend_voting) {

            <th
              placement="bottom"
              container="body"
              ngbTooltip="Price Voting"
              class="text-nowrap"
            >
              <img
                class="table-header-img"
                src="assets/images/fda-trend.svg"
                alt=""
              />
            </th>
            }

            <!-- <th class="text-nowrap">
              Price Target
            </th> -->

            @if (stage) {
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                STAGE<i
                  (click)="assending('stage')"
                  [ngClass]="arrowstage"
                  class="fa-solid cursorpointer mx-1"
                ></i>
              </div>
            </th>
            } @if (treatment) {
            <th>TREATMENT</th>
            }

            <!-- <th *ngIf="price_target" class="text-nowrap">PRICE TARGET</th> -->
            <!-- <th>LAST UPDATED</th> -->

            @if (description) {
            <th>DESCRIPTION</th>
            }
          </tr>
        </thead>
        <tbody id="tbody" class="text-center">
          <!-- <tr  ="isLoading" class="loading-border">
          <td colspan="12">
            <div class="table-loading-container">
              <span class="loader">Fetching more data for you</span>
            </div>
          </td>
        </tr> -->
          @if (fdaTable.length === 0) {
          <tr style="box-shadow: none;">
            <td colspan="17">
              <ngx-skeleton-loader
                [theme]="{ height: '50px',  'border-radius': '4px', 'margin-top': '20px'}"
                count="11"
              >
              </ngx-skeleton-loader>
            </td>
          </tr>
          }@else { @for (fda of fdaTable; track $index) {
          <tr class="table-tr-font">
            <td scope="row" class="col-stick">
              <div
                class="text-center company-hover"
                (click)="companyDetails(fda.ticker, false)"
              >
                @if (fda.logoid !== '') {
                <img
                  width="25px"
                  [src]="basePath + fda.logoid + '.svg'"
                  class="mb-1"
                />
                }@else {
                <span class="avatarcssForTable">{{
                  fda.name | initialLetter
                }}</span>
                }

                <p class="text-center text-data table-font-size">
                  <a
                    href="{{ clientUrl }}/company/{{ fda.ticker | uppercase }}"
                    class="link-ticker"
                    >{{ fda.name }}</a
                  >
                </p>
              </div>
            </td>

            @if (ticker) {
            <td class="table-wide-cell">
              <div class="d-grid justify-content-center">
                <p class="table-astec-font table-font-size">
                  <a
                    href="{{ clientUrl }}/company/{{ fda.ticker | uppercase }}"
                    class="link-ticker"
                  >
                    {{ fda.ticker }}
                  </a>
                </p>

                @if (fda.ticker) { @if (portfoliomatchTicker) { @if
                (portfoliomatchTicker.includes(fda.ticker)) {
                <i
                  placement="bottom"
                  container="body"
                  ngbTooltip="Remove from Portfolio"
                  (click)="removeSymboltoPotfoliyo(fda.ticker)"
                  style="
                    opacity: unset;
                    font-size: 20px;
                    color: var(--clr-primary);
                  "
                  class="fa-solid fa-circle-minus my-2 cursorpointer"
                ></i>
                }@else {
                <i
                  placement="bottom"
                  container="body"
                  ngbTooltip="Add to Portfolio"
                  (click)="
                    this.portfolioTicker = fda.ticker;
                    this.portfolioPrice = fda.close;
                    portfoliocost = undefined;
                    portfolioquntity = undefined
                  "
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style="
                    opacity: unset;
                    font-size: 20px;
                    color: var(--clr-primary);
                  "
                  class="fa-solid fa-circle-plus my-2 cursorpointer"
                ></i>
                } } @if (!portfoliomatchTicker) {
                <i
                  placement="bottom"
                  container="body"
                  ngbTooltip="Add to Portfolio"
                  (click)="
                    portfolioTicker = fda.ticker;
                    this.portfolioPrice = fda.close;
                    portfoliocost = undefined;
                    portfolioquntity = undefined
                  "
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style="
                    opacity: unset;
                    font-size: 20px;
                    color: var(--clr-primary);
                  "
                  class="fa-solid fa-circle-plus my-2 cursorpointer"
                ></i>
                } }
              </div>
            </td>
            }

            <td scope="row" class="d-none">{{ $index + 1 }}</td>

            @if (price) {
            <td>
              <p [ngClass]="fda.closeClass" class="text-nowrap table-font-size">
                {{ fda.close | number : "1.0-2" }}
              </p>
              <p
                class="table-price text-nowrap table-font-size"
                [style.color]="fda.change.toString() | riseAndFallColor"
              >
                {{ fda.change | number : "1.0-2" }} %
              </p>
            </td>
            } @if (thirtydaypricechange) {
            <td>
              @if (fda.thirtyDayChart) {
              <app-thirty-day-trend
                class="d-flex justify-content-center"
                [chartData]="fda.thirtyDayChart"
                [x]="$index"
              ></app-thirty-day-trend>
              }@else { - }
            </td>
            }

            <!-- @if (industry) {
          <td>
            @if (fda.Industry) {
            <p>{{fda.Industry}}</p>
            }@else {
            <p>-</p>
            }
          </td>
          } -->

            @if (market_cap_basic) {

            <td class="table-wide-cell">
              <p class="table-font-size">
                {{ fda.market_cap_basic | dollarsCoversion }}
              </p>
            </td>
            } @if (volume) {

            <td class="table-wide-cell">
              <p class="table-font-size">{{ fda.volume | dollarsCoversion }}</p>
            </td>
            } @if (hedgeFunds) {

            <td class="table-wide-cell">
              <p class="table-font-size">
                @if (fda.hedgeFunds > 0) {
                {{ fda.hedgeFunds }}
                }@else { - }
              </p>
            </td>
            }

            <td class="table-wide-cell">
              @if (fda.avgInsiderTransactions) {
              <p class="table-font-size">
                @if(fda.avgInsiderTransactions){
                <a
                  [style.color]="
                    fda.avgInsiderTransactions.toString() | riseAndFallColor
                  "
                  href="{{ clientUrl }}/company/{{
                    fda.ticker
                  }}?section=Insiders"
                  >{{ fda.avgInsiderTransactions | dollarsCoversion }}</a
                >
                }
              </p>
              }@else{ - }
            </td>

            <td class="table-wide-cell">
              @if (fda.option == 1) {
              <a
                class="table-font-size text-decoration"
                href="{{ clientUrl }}/company/{{ fda.ticker }}?section=option"
                >Yes</a
              >
              } @else {
              <p class="table-font-size">No</p>
              }
            </td>

            @if (catalyst) {
            <td class="table-wide-cell">
              <a
                href="{{ clientUrl }}/company/{{ fda.ticker }}?section=catalyst"
                class="table-font-size table-text-blue"
                >{{ fda.catalyst }}</a
              >
            </td>
            } @if (drug) {
            <td class="table-wide-cell">
              <a
                href="{{ clientUrl }}/company/{{ fda.ticker }}?section=drugpipe"
                class="table-font-size table-text-blue table-description-text"
              >
                {{ fda.drugname.substring(0, 40) }}
              </a>

              @if (fda.drugname.length > 40) {
              <div>
                <p
                  style="cursor: pointer"
                  class="text-danger"
                  container="body"
                  ngbTooltip="{{ fda.drugname }}"
                  triggers="click:blur"
                >
                  Read More+
                </p>
              </div>
              }
            </td>

            } @if (catalystdate) {

            <td class="table-wide-cell table-text-blue">
              <a
                href="{{ clientUrl }}/company/{{ fda.ticker }}?section=catalyst"
                class="table-font-size table-text-blue text-nowrap"
                >{{ fda.catdate }}</a
              >
            </td>

            }
            <!-- <td class="table-wide-cell table-text-blue">
            <div class="table-font-size text-nowrap">
              @if (fda.isTickerEqualToFirstContractSymbol === true) {
              <a style="text-decoration: none;" class="cursorpointer table-text-blue"
                (click)="companyDetails(fda.ticker,true)">Yes</a>
              }@else {
              <p class="m-0 cursorpointer">No</p>
              }
            </div>
          </td> -->
            @if (trend_voting) {

            <td>
              <div class="d-flex">
                <div
                  container="body"
                  ngbTooltip="Price Up"
                  class="cursorpointer"
                  (click)="
                    up_down_vote(
                      fda.ticker,
                      'up',
                      fda.isIdIncludedInUp,
                      fda.isIdIncludedInDown
                    )
                  "
                >
                  <i
                    [ngClass]="{ removeopacity: fda.isIdIncludedInUp === true }"
                    style="font-size: 20px"
                    class="fas fa-arrow-up mx-2 text-success"
                  ></i>
                  <p class="m-0">{{ fda.upVotesLength }}</p>
                </div>
                <div
                  container="body"
                  ngbTooltip="Price Down"
                  class="cursorpointer"
                  (click)="
                    up_down_vote(
                      fda.ticker,
                      'down',
                      fda.isIdIncludedInUp,
                      fda.isIdIncludedInDown
                    )
                  "
                >
                  <i
                    [ngClass]="{
                      removeopacity: fda.isIdIncludedInDown === true
                    }"
                    style="font-size: 20px"
                    class="fas fa-arrow-down mx-2 text-danger"
                  ></i>
                  <p class="m-0">{{ fda.downVotesLength }}</p>
                </div>
                <!-- @if (fda.isIdIncludedInUp || fda.isIdIncludedInDown) {

              <button container="body" ngbTooltip="Remove vote from {{ fda.ticker }}"
                class="btn rounded-circle btn-sm btn-danger text-nowrap remove-btn py-0"
                (click)="removeVote(fda.ticker)">
                <i [ngClass]="{'removeopacity': fda.isIdIncludedInDown === true || fda.isIdIncludedInUp === true}"
                  class="fas fa-trash-alt"></i>
              </button>
              } -->
              </div>
            </td>
            }
            <!-- <td class="table-wide-cell ">
            <div class="justify-content-center d-flex align-items-start">
              <div class="text-center ">
                <input ngbTooltip="Predict the price for a Ticker" placement="bottom" #inputElement
                  class="Price_Target_Input" (click)="votingPrice(fda.ticker,inputElement.id)"
                  [attr.id]="'Price_Target_Input' + $index" type="number">
                @if (fda.priceVotesAvg) {
                <p ngbTooltip="Average price of all users" placement="bottom" style="margin-top: 5px !important;">
                  {{fda.priceVotesAvg}}</p>
                }
              </div>
              <div>
                <i (click)="votingPrice(fda.ticker,inputElement.id)" style="opacity: inherit; font-size: 20px"
                  class="fa-solid fa-check  cursorpointer"></i>
              </div>
            </div>

          </td> -->

            @if (stage) {
            <td class="table-wide-cell">
              <div>
                <p class="phase-1 table-font-size">{{ fda.stage }}</p>

                @if (fda.stage) {
                <app-stage-progress
                  stage="{{ fda.stage }}"
                ></app-stage-progress>
                }@else {
                <p class="m-0">-</p>
                }
              </div>
            </td>
            } @if (treatment) {
            <td class="table-wide-cell">
              <div class="text-center">
                <p class="table-description-text treatmentfont table-font-size">
                  <a
                    class="text-dark"
                    href="{{ clientUrl }}/company/{{
                      fda.ticker
                    }}?section=catalyst"
                    >{{ fda.treatment.substring(0, 100) }}</a
                  >
                </p>

                @if (fda.treatment.length > 100) {
                <div>
                  <p
                    style="cursor: pointer"
                    class="text-danger"
                    container="body"
                    ngbTooltip="{{ fda.treatment }}"
                    triggers="click:blur"
                  >
                    Read More+
                  </p>
                </div>
                }
              </div>
            </td>
            } @if (description) {
            <td>
              <div>
                @if (fda.description) {
                <p class="table-description-text table-font-size">
                  <a
                    class="text-dark"
                    href="{{ clientUrl }}/company/{{
                      fda.ticker
                    }}?section=catalyst"
                    >{{ fda.description.substring(0, 50) }}...</a
                  >
                </p>
                }@else {
                <p>-</p>
                }

                <div>
                  @if (fda.description.length > 50) {
                  <p
                    style="cursor: pointer; font-size: 12px"
                    class="text-danger"
                    placement="left"
                    container="body"
                    container="body"
                    ngbTooltip="{{ fda.description }}"
                    triggers="click:blur"
                  >
                    Read More+
                  </p>
                  }
                </div>
              </div>
            </td>
            }
          </tr>
          }@empty {
       
          } }
        </tbody>
        <tfoot>
          @if (loadData) {
          <tr class="loading-border">
            <td colspan="14">
              <div class="data-loading-container">
                <p class="Fetching me-3 mb-0 mt-0">
                  Fetching more data for you
                </p>
                <span class="loader mb-2" style="font-size: 3px"> </span>
              </div>
            </td>
          </tr>
          }
        </tfoot>
      </table>
    </div>
    <div
      style="
        position: absolute;
        bottom: 0;
        background-color: #f1f7fde6;
        z-index: 1;
      "
    >
      @if (!productId) { @if (fdaTable.length > 0) {
      <app-locksec></app-locksec>
      } }
    </div>
  </div>
  <app-loginpop-up
    [displayStyle]="modalDisplayStyle"
    (myout)="GetChildData($event)"
  ></app-loginpop-up>
</section>

<!-- Modal -->
<app-portfoliomodal
  [portfolioTicker]="portfolioTicker"
  [portfolioPrice]="portfolioPrice"
  [portfoliocost]="portfoliocost"
  [portfolioquntity]="portfolioquntity"
  (addSymbol)="onAddSymbol($event)"
></app-portfoliomodal>
