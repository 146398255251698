import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  bioCelender,
  bioCelenderCurrent,
  bioCelenderSearch,
  pudaCalenderSearch,
} from '../const';

@Injectable({
  providedIn: 'root',
})
export class BioCelenderService {
  constructor(private http: HttpClient) {}

  bioclender(requestBody: any) {
    return this.http.post(`${bioCelender}`, requestBody);
  }

  bioclenderCurrent(requestBody: any) {
    return this.http.post(`${bioCelenderCurrent}`, requestBody);
  }

  bioclenderSearch(requestBody: any) {
    return this.http.post(`${bioCelenderSearch}`, requestBody);
  }
  pudaCalenderSearch(requestBody: any) {
    return this.http.post(`${pudaCalenderSearch}`, requestBody);
  }
}
