import { Component, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import HighchartsTreemap from 'highcharts/modules/treemap';
import { DollarsCoversionPipe } from '../../pipes/dollars-coversion.pipe';
const dollarsCoversion = new DollarsCoversionPipe();

@Component({
  selector: 'app-hedge-fund-ind-heatmap',
  // standalone: true,
  // imports: [],
  templateUrl: './hedge-fund-ind-heatmap.component.html',
  styleUrl: './hedge-fund-ind-heatmap.component.scss',
})
export class HedgeFundIndHeatmapComponent {
  @Input() heatmapData: any;

  chartOptions: Highcharts.Options | any = {
    title: {
      text: '',
    },

    colorAxis: {
      minColor: '#75DA8B',
      maxColor: '#089981',
    },
    tooltip: {
      formatter: function (this: any) {
        return `<b>${this.point?.Issuer_Name} (${this.point?.current_mv})`;
      },
    },
    series: [
      {
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        clip: false,
        data: [],
        dataLabels: {
          enabled: true,
          style: {
            color: 'white',
            textShadow: 'none',
            textOutline: 'none',
          },
        },
      },
    ],
  };

  ngOnInit() {
    HighchartsMore(Highcharts);
    HighchartsHeatmap(Highcharts);
    HighchartsTreemap(Highcharts);
    this.heatmap();
  }

  heatmap() {
    this.chartOptions.series[0].data = this.heatmapData.map(
      (item: any, index: number) => ({
        name: `${item.symbol}<br/>${item.current_percent_of_portfolio.toFixed(
          2
        )}%`,
        symbol: item.symbol,
        Issuer_Name: item.Issuer_Name,
        current_mv: dollarsCoversion.transform(item.current_mv),
        value: Number(item.current_percent_of_portfolio.toFixed(2)),
        colorValue: Number(item.current_percent_of_portfolio.toFixed(2)),
      })
    );
    Highcharts.chart('container', this.chartOptions);
  }
}
