<section class="website">
  <div class="container p-3">
    <div class="text-center">
      <div class="accordion accordion-flush" id="accordionFlushExample">
        <h1 class="main-heading text-white m-0 me-3">User Guide</h1>
      </div>
    </div>
  </div>
</section>
<button
  class="btn d-none hideinlg"
  type="button"
  data-bs-toggle="offcanvas"
  data-bs-target="#newOffcanvas"
  aria-controls="newOffcanvas"
>
  ☰ Menu
</button>
<div class="container-fluid p-0">
  <!-- <div class="menu-toggle text-white cursorpointer"></div> -->

  <div
    class="offcanvas offcanvas-start"
    data-bs-scroll="true"
    tabindex="-1"
    id="newOffcanvas"
    aria-labelledby="newOffcanvasLabel"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="newOffcanvasLabel">Menu</h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <ul  data-bs-dismiss="offcanvas">
        <li>
          <a (click)="scrollToSection('what_is_an_fda_calendar', $event)"
            >What is an FDA Calendar?</a
          >
          <ul class="submenu">
            <li>
              <a (click)="scrollToSection('what_is_a_catalyst', $event)"
                >What is a Catalyst?</a
              >
            </li>
            <li>
              <a (click)="scrollToSection('biotech_stock_movers', $event)"
                >Biotech Stock Movers</a
              >
            </li>
            <li>
              <a (click)="scrollToSection('what_is_an_fda_pdufa_date', $event)"
                >What is an FDA PDUFA date?</a
              >
            </li>
            <li>
              <a
                (click)="
                  scrollToSection('how_long_does_an_fda_approval_take', $event)
                "
                >How long does an FDA approval take?</a
              >
            </li>
            <li>
              <a
                (click)="scrollToSection('how_do_you_find_fda_approvals', $event)"
                >How do you find FDA approvals?</a
              >
            </li>
          </ul>
        </li>
        <li>
          <a
            (click)="
              scrollToSection(
                'biopharmawatch_biotech_earnings_calendar_user_guide',
                $event
              )
            "
            >BioPharmaWatch Biotech Earnings Calendar User Guide</a
          >
          <ul class="submenu">
            <li>
              <a
                (click)="
                  scrollToSection(
                    'key_elements_of_the_biotech_earnings_calendar',
                    $event
                  )
                "
                >Key Elements of the Biotech Earnings Calendar</a
              >
            </li>
            <li>
              <a
                (click)="
                  scrollToSection('maximizing_your_investment_strategy', $event)
                "
                >Maximizing Your Investment Strategy</a
              >
            </li>
          </ul>
        </li>
        <li>
          <a (click)="scrollToSection('pdufa_calendar_user_guide', $event)"
            >PDUFA Calendar User Guide</a
          >
          <ul class="submenu">
            <li>
              <a
                (click)="
                  scrollToSection('how_to_navigate_the_pdufa_calendar', $event)
                "
                >How to Navigate the PDUFA Calendar</a
              >
            </li>
            <li>
              <a
                (click)="
                  scrollToSection(
                    'maximizing_your_investment_strategy_with_pdufa_dates',
                    $event
                  )
                "
                >Maximizing Your Investment Strategy with PDUFA Dates</a
              >
            </li>
          </ul>
        </li>
        <li>
          <a (click)="scrollToSection('historical_fda_pdufa_dates_guide', $event)"
            >Historical FDA PDUFA Dates Guide</a
          >
          <ul class="submenu">
            <li>
              <a
                (click)="
                  scrollToSection(
                    'key_elements_of_the_historical_pdufa_dates_table',
                    $event
                  )
                "
                >Key Elements of the Historical PDUFA Dates Table</a
              >
            </li>
            <li>
              <a
                (click)="
                  scrollToSection(
                    'using_the_historical_pdufa_dates_to_inform_your_strategy',
                    $event
                  )
                "
                >Using the Historical PDUFA Dates to Inform Your Strategy</a
              >
            </li>
            <li>
              <a
                (click)="
                  scrollToSection('maximizing_the_use_of_historical_data', $event)
                "
                >Maximizing the Use of Historical Data</a
              >
            </li>
          </ul>
        </li>
        <li>
          <a (click)="scrollToSection('conference_calendar_user_guide', $event)"
            >Conference Calendar User Guide</a
          >
          <ul class="submenu">
            <li>
              <a
                (click)="
                  scrollToSection('navigating_the_conference_calendar', $event)
                "
                >Navigating the Conference Calendar</a
              >
            </li>
            <li>
              <a
                (click)="
                  scrollToSection(
                    'maximizing_your_investment_strategy_with_the_conference_calendar',
                    $event
                  )
                "
                >Maximizing Your Investment Strategy with the Conference
                Calendar</a
              >
            </li>
          </ul>
        </li>
        <li>
          <a (click)="scrollToSection('ipo_calendar_user_guide', $event)"
            >IPO Calendar User Guide</a
          >
          <ul class="submenu">
            <li>
              <a (click)="scrollToSection('navigating_the_ipo_calendar', $event)"
                >Navigating the IPO Calendar</a
              >
            </li>
            <li>
              <a
                (click)="
                  scrollToSection(
                    'maximizing_your_investment_strategy_with_the_ipo_calendar',
                    $event
                  )
                "
                >Maximizing Your Investment Strategy with the IPO Calendar</a
              >
            </li>
          </ul>
        </li>
        <li>
          <a (click)="scrollToSection('catalystsync_user_guide', $event)"
            >CatalystSync™ User Guide</a
          >
          <ul class="submenu">
            <li>
              <a (click)="scrollToSection('key_features_of_catalystsync', $event)"
                >Key Features of CatalystSync™</a
              >
            </li>
            <li>
              <a
                (click)="
                  scrollToSection(
                    'maximizing_your_investment_strategy_with_catalystsync',
                    $event
                  )
                "
                >Maximizing Your Investment Strategy with CatalystSync™</a
              >
            </li>
          </ul>
        </li>
        <li>
          <a (click)="scrollToSection('company_screener_user_guide', $event)"
            >Company Screener User Guide</a
          >
          <ul class="submenu">
            <li>
              <a
                (click)="
                  scrollToSection('navigating_the_company_screener', $event)
                "
                >Navigating the Company Screener</a
              >
            </li>
            <li>
              <a
                (click)="
                  scrollToSection(
                    'maximizing_your_investment_strategy_with_the_company_screener',
                    $event
                  )
                "
                >Maximizing Your Investment Strategy with the Company Screener</a
              >
            </li>
          </ul>
        </li>
        <li>
          <a (click)="scrollToSection('inside_trades_user_guide', $event)"
            >Inside Trades User Guide</a
          >
          <ul class="submenu">
            <li>
              <a
                (click)="scrollToSection('key_features_of_inside_trades', $event)"
                >Key Features of Inside Trades</a
              >
            </li>
            <li>
              <a
                (click)="
                  scrollToSection(
                    'maximizing_your_investment_strategy_with_inside_trades',
                    $event
                  )
                "
                >Maximizing Your Investment Strategy with Inside Trades</a
              >
            </li>
            <li>
              <a
                (click)="
                  scrollToSection('quick_tips_for_using_inside_trades', $event)
                "
                >Quick Tips for Using Inside Trades</a
              >
            </li>
          </ul>
        </li>
        <li>
          <a (click)="scrollToSection('hedge_fund_screener_user_guide', $event)"
            >Hedge Fund Screener User Guide</a
          >
          <ul class="submenu">
            <li>
              <a
                (click)="
                  scrollToSection('navigating_the_hedge_fund_screener', $event)
                "
                >Navigating the Hedge Fund Screener</a
              >
            </li>
            <li>
              <a
                (click)="
                  scrollToSection(
                    'maximizing_your_investment_strategy_with_the_hedge_fund_screener',
                    $event
                  )
                "
                >Maximizing Your Investment Strategy with the Hedge Fund
                Screener</a
              >
            </li>
          </ul>
        </li>
        <li>
          <a (click)="scrollToSection('all_stock_holdings_user_guide', $event)"
            >All Stock Holdings User Guide</a
          >
          <ul class="submenu">
            <li>
              <a
                (click)="
                  scrollToSection('key_features_of_all_stock_holdings', $event)
                "
                >Key Features of All Stock Holdings</a
              >
            </li>
            <li>
              <a
                (click)="
                  scrollToSection(
                    'maximizing_your_investment_strategy_with_all_stock_holdings',
                    $event
                  )
                "
                >Maximizing Your Investment Strategy with All Stock Holdings</a
              >
            </li>
            <li>
              <a
                (click)="
                  scrollToSection(
                    'quick_tips_for_using_all_stock_holdings',
                    $event
                  )
                "
                >Quick Tips for Using All Stock Holdings</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  
  <div class="side-panel hideinmd">
    <ul>
      <li>
        <a (click)="scrollToSection('what_is_an_fda_calendar', $event)"
          >What is an FDA Calendar?</a
        >
        <ul class="submenu">
          <li>
            <a (click)="scrollToSection('what_is_a_catalyst', $event)"
              >What is a Catalyst?</a
            >
          </li>
          <li>
            <a (click)="scrollToSection('biotech_stock_movers', $event)"
              >Biotech Stock Movers</a
            >
          </li>
          <li>
            <a (click)="scrollToSection('what_is_an_fda_pdufa_date', $event)"
              >What is an FDA PDUFA date?</a
            >
          </li>
          <li>
            <a
              (click)="
                scrollToSection('how_long_does_an_fda_approval_take', $event)
              "
              >How long does an FDA approval take?</a
            >
          </li>
          <li>
            <a
              (click)="scrollToSection('how_do_you_find_fda_approvals', $event)"
              >How do you find FDA approvals?</a
            >
          </li>
        </ul>
      </li>
      <li>
        <a
          (click)="
            scrollToSection(
              'biopharmawatch_biotech_earnings_calendar_user_guide',
              $event
            )
          "
          >BioPharmaWatch Biotech Earnings Calendar User Guide</a
        >
        <ul class="submenu">
          <li>
            <a
              (click)="
                scrollToSection(
                  'key_elements_of_the_biotech_earnings_calendar',
                  $event
                )
              "
              >Key Elements of the Biotech Earnings Calendar</a
            >
          </li>
          <li>
            <a
              (click)="
                scrollToSection('maximizing_your_investment_strategy', $event)
              "
              >Maximizing Your Investment Strategy</a
            >
          </li>
        </ul>
      </li>
      <li>
        <a (click)="scrollToSection('pdufa_calendar_user_guide', $event)"
          >PDUFA Calendar User Guide</a
        >
        <ul class="submenu">
          <li>
            <a
              (click)="
                scrollToSection('how_to_navigate_the_pdufa_calendar', $event)
              "
              >How to Navigate the PDUFA Calendar</a
            >
          </li>
          <li>
            <a
              (click)="
                scrollToSection(
                  'maximizing_your_investment_strategy_with_pdufa_dates',
                  $event
                )
              "
              >Maximizing Your Investment Strategy with PDUFA Dates</a
            >
          </li>
        </ul>
      </li>
      <li>
        <a (click)="scrollToSection('historical_fda_pdufa_dates_guide', $event)"
          >Historical FDA PDUFA Dates Guide</a
        >
        <ul class="submenu">
          <li>
            <a
              (click)="
                scrollToSection(
                  'key_elements_of_the_historical_pdufa_dates_table',
                  $event
                )
              "
              >Key Elements of the Historical PDUFA Dates Table</a
            >
          </li>
          <li>
            <a
              (click)="
                scrollToSection(
                  'using_the_historical_pdufa_dates_to_inform_your_strategy',
                  $event
                )
              "
              >Using the Historical PDUFA Dates to Inform Your Strategy</a
            >
          </li>
          <li>
            <a
              (click)="
                scrollToSection('maximizing_the_use_of_historical_data', $event)
              "
              >Maximizing the Use of Historical Data</a
            >
          </li>
        </ul>
      </li>
      <li>
        <a (click)="scrollToSection('conference_calendar_user_guide', $event)"
          >Conference Calendar User Guide</a
        >
        <ul class="submenu">
          <li>
            <a
              (click)="
                scrollToSection('navigating_the_conference_calendar', $event)
              "
              >Navigating the Conference Calendar</a
            >
          </li>
          <li>
            <a
              (click)="
                scrollToSection(
                  'maximizing_your_investment_strategy_with_the_conference_calendar',
                  $event
                )
              "
              >Maximizing Your Investment Strategy with the Conference
              Calendar</a
            >
          </li>
        </ul>
      </li>
      <li>
        <a (click)="scrollToSection('ipo_calendar_user_guide', $event)"
          >IPO Calendar User Guide</a
        >
        <ul class="submenu">
          <li>
            <a (click)="scrollToSection('navigating_the_ipo_calendar', $event)"
              >Navigating the IPO Calendar</a
            >
          </li>
          <li>
            <a
              (click)="
                scrollToSection(
                  'maximizing_your_investment_strategy_with_the_ipo_calendar',
                  $event
                )
              "
              >Maximizing Your Investment Strategy with the IPO Calendar</a
            >
          </li>
        </ul>
      </li>
      <li>
        <a (click)="scrollToSection('catalystsync_user_guide', $event)"
          >CatalystSync™ User Guide</a
        >
        <ul class="submenu">
          <li>
            <a (click)="scrollToSection('key_features_of_catalystsync', $event)"
              >Key Features of CatalystSync™</a
            >
          </li>
          <li>
            <a
              (click)="
                scrollToSection(
                  'maximizing_your_investment_strategy_with_catalystsync',
                  $event
                )
              "
              >Maximizing Your Investment Strategy with CatalystSync™</a
            >
          </li>
        </ul>
      </li>
      <li>
        <a (click)="scrollToSection('company_screener_user_guide', $event)"
          >Company Screener User Guide</a
        >
        <ul class="submenu">
          <li>
            <a
              (click)="
                scrollToSection('navigating_the_company_screener', $event)
              "
              >Navigating the Company Screener</a
            >
          </li>
          <li>
            <a
              (click)="
                scrollToSection(
                  'maximizing_your_investment_strategy_with_the_company_screener',
                  $event
                )
              "
              >Maximizing Your Investment Strategy with the Company Screener</a
            >
          </li>
        </ul>
      </li>
      <li>
        <a (click)="scrollToSection('inside_trades_user_guide', $event)"
          >Inside Trades User Guide</a
        >
        <ul class="submenu">
          <li>
            <a
              (click)="scrollToSection('key_features_of_inside_trades', $event)"
              >Key Features of Inside Trades</a
            >
          </li>
          <li>
            <a
              (click)="
                scrollToSection(
                  'maximizing_your_investment_strategy_with_inside_trades',
                  $event
                )
              "
              >Maximizing Your Investment Strategy with Inside Trades</a
            >
          </li>
          <li>
            <a
              (click)="
                scrollToSection('quick_tips_for_using_inside_trades', $event)
              "
              >Quick Tips for Using Inside Trades</a
            >
          </li>
        </ul>
      </li>
      <li>
        <a (click)="scrollToSection('hedge_fund_screener_user_guide', $event)"
          >Hedge Fund Screener User Guide</a
        >
        <ul class="submenu">
          <li>
            <a
              (click)="
                scrollToSection('navigating_the_hedge_fund_screener', $event)
              "
              >Navigating the Hedge Fund Screener</a
            >
          </li>
          <li>
            <a
              (click)="
                scrollToSection(
                  'maximizing_your_investment_strategy_with_the_hedge_fund_screener',
                  $event
                )
              "
              >Maximizing Your Investment Strategy with the Hedge Fund
              Screener</a
            >
          </li>
        </ul>
      </li>
      <li>
        <a (click)="scrollToSection('all_stock_holdings_user_guide', $event)"
          >All Stock Holdings User Guide</a
        >
        <ul class="submenu">
          <li>
            <a
              (click)="
                scrollToSection('key_features_of_all_stock_holdings', $event)
              "
              >Key Features of All Stock Holdings</a
            >
          </li>
          <li>
            <a
              (click)="
                scrollToSection(
                  'maximizing_your_investment_strategy_with_all_stock_holdings',
                  $event
                )
              "
              >Maximizing Your Investment Strategy with All Stock Holdings</a
            >
          </li>
          <li>
            <a
              (click)="
                scrollToSection(
                  'quick_tips_for_using_all_stock_holdings',
                  $event
                )
              "
              >Quick Tips for Using All Stock Holdings</a
            >
          </li>
        </ul>
      </li>
    </ul>
  </div>

  <div #mainPanel class="main-content">
    <h2 id="what_is_an_fda_calendar" class="class">What is an FDA Calendar?</h2>
    <p>
      Biotech stocks are volatile, driven by key events that can significantly
      impact their prices. An FDA calendar is a valuable tool for investors to
      track these events, make informed decisions, and potentially profit from
      the resulting price fluctuations.
    </p>

    <p>
      <b class="cursorpointer" routerLink="/fda-calendar"><u>Biopharmawatch's FDA Calendar</u></b> is a daily updated tool that tracks
      future catalysts and key dates across biotech and pharma companies,
      including clinical data readouts, regulatory events, and PDUFA dates,
      designed to inform on stock-moving events. With daily updates, you'll
      always have access to the most current information about future catalysts
      that can impact your investments.
    </p>

    <p>Key features include:</p>
    <ul>
      <li class="mb-2">
        <b>Event Types and Stages:</b>
        <span>
          The calendar provides insights into key biotech events such as PDUFA
          dates, phase trial data readouts, and regulatory approvals. Each event
          is linked to a specific stage of development (e.g., Phase 1, Phase 2,
          PDUFA).</span
        >
      </li>
      <li class="mb-2">
        <b>Stock Market Data:</b>
        <span>
          It offers real-time stock metrics like current price, 30-day price
          change, market capitalization, and trading volume, helping users
          assess the financial health of the companies.</span
        >
      </li>
      <li class="mb-2">
        <b>Hedge Fund and Insider Activity:</b>
        <span>
          The tool tracks hedge fund involvement and insider trading activity,
          offering clues on institutional confidence in a stock or upcoming
          event.</span
        >
      </li>
      <li class="mb-2">
        <b>Detailed Drug Information:</b>
        <span>
          Each entry includes the drug name, treatment type, and the condition
          it aims to treat, allowing for in-depth research on the therapeutic
          areas.</span
        >
      </li>
      <li class="mb-2">
        <b>Filtering Options:</b>
        <span>
          Users can filter events by therapeutic area, event type, stage of
          development, and specific diseases, enabling targeted research based
          on specific investment strategies.</span
        >
      </li>
    </ul>

    <p>
      The calendar allows data screening, based on company names or tickers,
      events, date-wise or based on a date range. They are designed to serve as
      a ‘one-stop shop’ for data needs of investors, both existing and
      potential, to capitalize on the opportunities these catalysts throw up or
      cut the losses from an adverse development.
    </p>

    <h2 id="what_is_a_catalyst">What is a Catalyst?</h2>
    <p>
      A catalyst is an event that can significantly influence a stock price,
      either positively or negatively.
    </p>

    <h2 id="biotech_stock_movers">Biotech Stock Movers</h2>
    <p>
      Regulatory actions and clinical data are major catalysts for biotech
      stocks. Positive results can lead to substantial price increases, while
      negative or mixed results can cause significant declines. This is because
      biotech companies often have high potential but also high risk, as their
      success depends on the development of successful drugs.
    </p>

    <h2 id="what_is_an_fda_pdufa_date">What is an FDA PDUFA date?</h2>
    <p>
      A Prescription Drug User Fee Act (PDUFA) date is the deadline by which the
      FDA is required to make a decision on a regulatory application submitted
      by a pharmaceutical company. This decision could be an approval, a
      complete response letter (CRL), or a delay due to specific issues. Passed
      in 1992, the PDUFA allows the FDA to charge fees to companies to expedite
      the review process.
    </p>
    <p>
      If the FDA issues a CRL, it means the application is incomplete and more
      information, like additional clinical studies or manufacturing details, is
      needed. Delays in PDUFA decisions can also occur if the FDA requires more
      time to review newly submitted data or if they encounter issues like
      delays in facility inspections.
    </p>
    <p>
      The FDA may specify a date, a quarter, or even just a general timeline
      (e.g., Q1, Q2) for when they will make their decision. If no clear
      timeline is provided, companies and investors often estimate the decision
      date based on the application filing date.
    </p>

    <h2 id="how_long_does_an_fda_approval_take">
      How long does an FDA approval take?
    </h2>
    <p>
      Once a regulatory application (NDA, BLA, etc.) is submitted, the FDA takes
      up to 60 days to decide if it’s accepted for review. If accepted, the
      standard review period is 10 months, while a priority review shortens this
      to 6 months. The faster timeline depends on the type of review—priority or
      standard.
    </p>

    <h2 id="how_do_you_find_fda_approvals">How do you find FDA approvals?</h2>
    <p>
      Tracking FDA approvals is easier with tools like a
      <b class="cursorpointer" routerLink="/PDUFA-calendar"><u>Biopharmawatch PDUFA Calendar</u>,</b> which lists important decisions in
      one place. The FDA also makes public announcements or informs the company,
      which typically releases a press statement afterward.
    </p>
    <p>
      This rewritten content enhances readability while ensuring keywords like
      PDUFA date, FDA approval, CRL, NDA, BLA, and Catalyst Calendar are
      highlighted for clarity and searchability.
    </p>
    <h2 id="biopharmawatch_biotech_earnings_calendar_user_guide">
      BioPharmaWatch Biotech Earnings Calendar User Guide
    </h2>
    <p>
      Welcome to the <b class="cursorpointer" routerLink="/biotechearnings"><u>Biotech Earnings Calendar</u></b> on
      <b>BioPharmaWatch!</b> This feature helps you track earnings reports from
      biotech companies, providing essential data such as expected and previous
      earnings per share (EPS). This guide will walk you through how to use the
      Earnings Calendar effectively.
    </p>
    <h2 id="key_elements_of_the_biotech_earnings_calendar">
      Key Elements of the Biotech Earnings Calendar
    </h2>
    <ol>
      <li class="mb-2">
        <b>Monthly Overview </b>
        <p class="m-0">
          The calendar view shows all biotech companies reporting earnings in a
          given month. You can navigate by month using the arrows next to the
          date.
        </p>
        <p>
          Each date block contains company names, with additional companies
          shown when you click <b>“+ More”</b> on busy dates.
        </p>
      </li>
      <li class="mb-2">
        <b>Company Details </b>
        <p>
          On the right-hand panel, you can see the selected company's earnings
          details:
        </p>
        <ul class="sub-li">
          <li>
            <b>Company Name & Ticker:</b
            ><span>
              The company’s stock ticker is shown for easy reference.</span
            >
          </li>
          <li>
            <b>Earnings Date:</b
            ><span> The scheduled date of the earnings report.</span>
          </li>
          <li>
            <b>Previous EPS:</b
            ><span>
              The company’s earnings per share from the previous quarter. This
              helps you assess how the stock has performed historically.</span
            >
          </li>
          <li>
            <b>Description:</b
            ><span>
              Additional information about upcoming earnings or events related
              to the company.</span
            >
          </li>
        </ul>
      </li>
      <li class="mb-2">
        <b>Filters</b>
        <p class="m-0">
          <b>By Sector: </b>
          <span>Filter companies by sector to focus on areas of interest.</span>
        </p>
        <b>Market Size: </b>
        <span
          >Sort companies by their market capitalization, allowing you to target
          larger or smaller companies depending on your strategy.</span
        >
      </li>
      <li class="mb-2">
        <b>Search Bar</b>
        <p>
          Use the <b>Search Bar</b> at the top right to quickly find specific
          companies or stock tickers. This is a great way to focus on individual
          earnings reports without scrolling through the entire calendar.
        </p>
      </li>
    </ol>
    <h2 id="maximizing_your_investment_strategy">
      Maximizing Your Investment Strategy
    </h2>
    <ol>
      <li><b>Plan Around Earnings Dates</b></li>
      <p class="mb-1">
        The earnings reports for biotech companies can significantly impact
        stock prices. Use the calendar to plan your trades around these key
        dates.
      </p>
      <p>
        If you anticipate a strong earnings report based on past performance or
        company news, consider entering a position before the report.
        Conversely, if expectations are low, you might avoid the stock or sell
        before the release to avoid losses.
      </p>
      <li>
        <b>Compare Previous EPS</b>
        <p>
          The <b>Previous EPS</b> data is critical for understanding a company’s
          financial performance. Compare the previous earnings to analyst
          expectations and use this to gauge the likelihood of a stock price
          increase or decrease after the earnings report.
        </p>
      </li>
      <li>
        <b>Focus on High-Impact Dates</b>
        <p>
          Pay attention to companies with multiple upcoming earnings dates in
          the same week or those that frequently appear in the calendar. These
          are often high-profile reports that can drive significant market
          movement.
        </p>
      </li>
      <li>
        <b>Sector & Market Size Filtering</b>
        <p>
          Tailor your strategy by focusing on sectors that interest you or
          companies of a specific size. For instance, larger market cap
          companies may be more stable, while smaller companies could offer
          higher growth potential but come with more risk.
        </p>
      </li>
    </ol>
    <h2 id="pdufa_calendar_user_guide">PDUFA Calendar User Guide</h2>
    <p>
      The <b routerLink="/PDUFA-calendar" class="cursorpointer"><u>PDUFA Calendar</u></b> on BioPharmaWatch helps you track upcoming
      Prescription Drug User Fee Act (PDUFA) dates, which are crucial FDA
      deadlines for drug approval decisions. Understanding these dates can give
      you a strategic edge in biotech and pharma investing, as they often signal
      market-moving events. This guide will help you navigate the calendar and
      leverage it to optimize your investment decisions.
    </p>
    <h2 id="how_to_navigate_the_pdufa_calendar">
      How to Navigate the PDUFA Calendar
    </h2>
    <ol>
      <li>
        <b>Monthly Calendar View</b>
        <p class="m-0">
          The calendar shows a visual overview of all important PDUFA dates for
          the selected month. For each date, you can see companies listed with
          their upcoming PDUFA event. Dates with more than one company will show
          a “+ More” link.
        </p>
        <p>
          Easily move between months using the arrow buttons, allowing you to
          plan ahead and mark significant approval dates in your investment
          timeline.
        </p>
      </li>
      <li>
        <b>Company and Drug Details</b>
        <p>
          On the right panel, when you click a date, you’ll see detailed
          information about the company, the drug in question, and the type of
          event (in this case, PDUFA dates).
        </p>
        <b>Key Details Include:</b>
        <ul class="sub-li mb-3">
          <li>
            <b>Company Name & Ticker: </b>
            <span>The name of the company and its stock ticker symbol.</span>
          </li>
          <li>
            <b>Drug Name: </b>
            <span>The name of the drug awaiting FDA approval.</span>
          </li>
          <li>
            <b>Stage: </b>
            <span>Indicates the approval stage (PDUFA).</span>
          </li>
          <li>
            <b>Treatment: </b>
            <span
              >Briefly describes the condition or disease the drug is
              targeting.</span
            >
          </li>
          <li>
            <b>Description: </b>
            <span
              >Offers a concise overview of the FDA process, such as whether the
              drug has Priority Review, or other specific information about the
              approval pathway.</span
            >
          </li>
        </ul>
      </li>
      <li>
        <b>Search Functionality</b>
        <p>
          Use the Search Bar to find specific companies or drugs by entering
          their names or tickers. This feature saves time and allows you to zero
          in on your stocks of interest without scrolling through the calendar.
        </p>
      </li>
    </ol>
    <h2 id="maximizing_your_investment_strategy_with_pdufa_dates">
      Maximizing Your Investment Strategy with PDUFA Dates
    </h2>
    <ol>
      <li>
        <b>Watch for Significant Market Movements</b>
        <p>
          PDUFA dates often lead to substantial price movements. If a drug is
          approved, stocks may surge, while rejections or delays could result in
          sharp declines. You can time your entry and exit points around these
          dates to take advantage of potential swings.
        </p>
        <p>
          <b>Tip:</b> Consider entering positions before the PDUFA date if you
          believe the approval odds are strong, and be ready to exit shortly
          after the announcement to capture profits.
        </p>
      </li>
      <li>
        <b>Focus on High-Impact Drugs</b>
        <p>
          Drugs with <b>Priority Review</b> or treatments for large markets
          (e.g., cancer, autoimmune diseases) often have a bigger impact on
          stock prices. Pay close attention to these listings on the calendar
          for higher potential gains.
        </p>
      </li>
      <li>
        <b>Leverage the Description for Deeper Insights</b>
        <p>
          The Description section provides more than just basic information. It
          often highlights crucial FDA actions such as Priority Reviews or sNDAs
          (supplemental New Drug Applications), which can shorten the approval
          timeline or increase the likelihood of success.
        </p>
      </li>
      <li>
        <b>Avoid Last-Minute Surprises</b>
        <p>
          By regularly checking the PDUFA calendar, you can stay informed about
          upcoming deadlines and avoid being caught off guard by sudden price
          fluctuations. This helps you react to the market with confidence.
        </p>
      </li>
      <li>
        <b>Track Multiple Companies on the Same Date</b>
        <p>
          On days with more than one PDUFA event, like September 5th in the
          example, be mindful that multiple approvals or rejections could create
          sector-wide momentum. These dates can affect not just the individual
          companies but sometimes the entire biotech sector.
        </p>
      </li>
    </ol>
    <h2 id="historical_fda_pdufa_dates_guide">
      Historical FDA PDUFA Dates Guide
    </h2>
    <p>
      The Historical FDA PDUFA Dates feature on BioPharmaWatch provides a
      detailed timeline of past FDA approval decisions, offering valuable
      insights into how biotech and pharma companies have performed in securing
      regulatory approvals. This guide will help you leverage this tool to make
      informed investment decisions based on historical data.
    </p>
    <h2 id="key_elements_of_the_historical_pdufa_dates_table">
      Key Elements of the Historical PDUFA Dates Table
    </h2>
    <ol>
      <li>
        <b>Name: </b>
        <span
          >Lists the company name, which allows you to see which biotech and
          pharma companies have had FDA reviews.</span
        >
      </li>
      <li>
        <b>Ticker: </b>
        <span
          >The stock ticker for easy reference. You can click the "+" symbol to
          add a company to your watchlist.</span
        >
      </li>
      <li>
        <b>PDUFA Date: </b>
        <span
          >The date when the FDA reviewed the drug or therapy. Use this to see
          how long ago decisions were made, which can be useful when researching
          approval timelines for similar drugs.</span
        >
      </li>
      <li>
        <b>Drug/Therapy: </b>
        <span
          >The name of the drug or therapy that was under review, which gives
          you specific details on the treatment being approved or
          rejected.</span
        >
      </li>
      <li>
        <b>Indication: </b>
        <span
          >The disease or condition the drug is intended to treat. This helps
          you focus on areas relevant to your investment interests, such as
          oncology, immunology, or rare diseases.</span
        >
      </li>
      <li>
        <b>Status: </b>
        <span
          >Displays the outcome of the FDA review, such as
          <b>Approval, Complete Response Letter (CRL),</b> or other decisions.
          This allows you to track approval rates and how specific companies
          have performed over time.</span
        >
      </li>
    </ol>
    <h2 id="using_the_historical_pdufa_dates_to_inform_your_strategy">
      Using the Historical PDUFA Dates to Inform Your Strategy
    </h2>
    <ol>
      <li>
        <b>Track Company Performance:</b>
        <p>
          By reviewing past PDUFA dates, you can see which companies have a
          strong track record of approvals versus rejections. This can guide
          your investment strategy if you prefer to invest in companies with a
          history of successful FDA interactions.
        </p>
      </li>
      <li>
        <b>Evaluate Drug Development Trends:</b>
        <p>
          Use the <b>Drug/Therapy</b> and <b>Indication</b> columns to observe
          which therapeutic areas are receiving more approvals. For example, if
          you notice multiple approvals in oncology, it could signal a strong
          FDA focus on cancer treatments, offering a potential investment
          opportunity.
        </p>
      </li>
      <li>
        <b>Understand FDA Decision Timelines:</b>
        <p>
          The PDUFA dates in this table allow you to assess how long it
          typically takes for similar drugs to move through the approval
          process. This is especially useful when evaluating newer drugs in a
          company’s pipeline—historical data can offer benchmarks for when
          future decisions may occur.
        </p>
      </li>
      <li>
        <b>Analyze the Impact of CRLs:</b>
        <p>
          A <b>Complete Response Letter (CRL)</b> is issued when the FDA
          declines to approve a drug but provides feedback on what must be
          addressed. By tracking how frequently companies receive CRLs and how
          they respond, you can gauge the risk associated with ongoing drug
          development.
        </p>
      </li>
    </ol>
    <h2 id="maximizing_the_use_of_historical_data">
      Maximizing the Use of Historical Data
    </h2>
    <ul>
      <li>
        <p>
          <b>Filter By Status or Date:</b> Use the filtering options at the top
          of the table to refine your search by <b>Approval</b> or
          <b>CRL</b> status, as well as by specific date ranges. This helps you
          quickly identify key trends or patterns within the data.
        </p>
      </li>
      <li>
        <p>
          <b>Cross-Reference with Future PDUFA Dates:</b> Combine the insights
          from historical PDUFA data with the upcoming events in the
          <b class="cursorpointer" routerLink="/PDUFA-calendar"><u>PDUFA Calendar</u></b> to anticipate how stocks might react to future
          FDA decisions based on past outcomes.
        </p>
      </li>
    </ul>
    <h2 id="conference_calendar_user_guide">Conference Calendar User Guide</h2>
    <p>
      The <b class="cursorpointer" routerLink="/conference-calendar"><u>Conference Calendar</u></b> on BioPharmaWatch helps you stay informed
      about upcoming biotech and pharma conferences, where companies often
      present key data, trial updates, or announce new developments that can
      impact stock prices. This guide will help you navigate the calendar and
      leverage it to stay ahead in the market.
    </p>
    <h2 id="navigating_the_conference_calendar">
      Navigating the Conference Calendar
    </h2>
    <ol>
      <li>
        <b>Monthly Calendar View</b>
        <p class="m-0">
          The calendar provides a visual representation of all scheduled
          conferences for a selected month. You can easily navigate between
          months using the arrow buttons next to the date.
        </p>
        <p>
          Each date block shows the conferences happening on that day. You can
          click on each entry to learn more about the events scheduled for that
          day.
        </p>
      </li>
      <li>
        <b>Conference Details Panel</b>
        <p>
          The right-hand panel provides a detailed list of all conferences
          happening during the selected month, along with their full names and
          dates. Here you’ll find information such as:
        </p>
        <ul class="sub-li mb-3">
          <li>
            <b>Conference Name: </b>
            <span>The official title of the conference.</span>
          </li>
          <li>
            <b>Dates: </b>
            <span
              >The duration of the conference, indicating when it starts and
              ends. This section allows you to quickly scan all upcoming events
              without navigating the full calendar.</span
            >
          </li>
        </ul>
      </li>
      <li>
        <b>Filter and Search</b>
        <p class="m-0">
          Use the <b>Search Bar</b> at the top right to find specific
          conferences by entering keywords, such as conference names or relevant
          topics.
        </p>
        <p>
          The <b>refresh icon</b> next to the "Conferences" heading allows you
          to update the list, ensuring you have the most current information.
        </p>
      </li>
    </ol>
    <h2 id="maximizing_your_investment_strategy_with_the_conference_calendar">
      Maximizing Your Investment Strategy with the Conference Calendar
    </h2>
    <ol>
      <li>
        <b>Identify Key Conference Dates</b>
        <p class="m-0">
          Conferences often feature presentations from biotech and pharma
          companies, where they share important data or trial results.
          Monitoring these dates can help you anticipate stock movements, as
          positive or negative news can have a big impact.
        </p>
        <p>
          Use the calendar to mark down dates for conferences related to your
          investment interests, such as those focused on oncology, cardiology,
          or rare diseases.
        </p>
      </li>
      <li>
        <b>Track Multiple Presentations</b>
        <p>
          Some companies present at multiple conferences within a short period.
          Keeping track of these events helps you gauge the momentum or
          direction a company might take based on its announcements.
        </p>
      </li>
      <li>
        <b>Spot Industry Trends</b>
        <p>
          Major conferences, like the American Heart Association (AHA), Society
          for Immunotherapy of Cancer (SITC), AACR, ASCO, etc often highlight
          emerging trends in the biotech and pharma sectors. By attending or
          following updates from these conferences, you can gain insights into
          the latest advancements, research, and investment opportunities.
        </p>
      </li>
      <li>
        <b>React to Real-Time Announcements</b>
        <p>
          The calendar lets you stay updated with conferences happening right
          now or in the coming days. If you notice that a company you’re
          invested in is presenting at an ongoing conference, you can monitor
          stock movements closely and act swiftly.
        </p>
      </li>
    </ol>
    <h2 id="ipo_calendar_user_guide">IPO Calendar User Guide</h2>
    <p>
      The <b class="cursorpointer" routerLink="/ipo-sec"><u>IPO Calendar</u></b> on BioPharmaWatch is designed to track biotech
      companies that are going public, providing essential details like expected
      trading dates, financial data, and market trends. This tool allows
      investors to stay updated on new investment opportunities in the biotech
      and pharmaceutical sectors.
    </p>
    <h2 id="navigating_the_ipo_calendar">Navigating the IPO Calendar</h2>
    <ol>
      <li>
        <b>Upcoming IPOs</b>
        <p>
          The top section of the calendar shows <b class="cursorpointer" routerLink="/ipo-sec"><u>Upcoming IPOs</u></b> for biotech
          and pharmaceutical companies. For each upcoming IPO, the following
          details are provided:
        </p>
        <ul class="sub-li">
          <li>
            <b>Company Name: </b>
            <span>The name and stock ticker of the company going public.</span>
          </li>
          <li>
            <b>Industry: </b>
            <span
              >The sector the company belongs to, such as
              <b>Pharmaceuticals</b> or <b>Retail-Drug Stores.</b></span
            >
          </li>
          <li>
            <b>Shares: </b>
            <span>The number of shares being offered during the IPO.</span>
          </li>
          <li>
            <b>Price Low / Price High: </b>
            <span>The expected price range per share.</span>
          </li>
          <li>
            <b>Estimated Sale Volume: </b>
            <span>The estimated total volume of the sale.</span>
          </li>
          <li>
            <b>Expected to Trade: </b>
            <span>The date when the stock is expected to start trading.</span>
          </li>
          <li>
            <b>Status: </b>
            <span
              >The current status of the IPO (whether it's on schedule, pending,
              etc.).</span
            >
          </li>
        </ul>
      </li>
    </ol>
    <ol>
      <li>
        <b>Past IPOs</b>
        <p>
          Below the upcoming IPOs, the <b class="cursorpointer" routerLink="/ipo-sec"><u>Past IPOs</u></b> section lists previously
          held IPOs in the biotech and pharma sectors. This section includes:
        </p>
        <ul class="sub-li">
          <li>
            <b>Company Name & Ticker: </b>
            <span
              >The name and ticker symbol of the company that went public.</span
            >
          </li>
          <li>
            <b>Current Price: </b>
            <span>The stock’s price at the time of review.</span>
          </li>
          <li>
            <b>Therapeutic Focus: </b>
            <span
              >The main areas of research or treatment that the company focuses
              on (e.g., Oncology, Immunology).</span
            >
          </li>
          <li>
            <b>Underwriters: </b>
            <span
              >The financial institutions responsible for underwriting the
              IPO.</span
            >
          </li>
          <li>
            <b>IPO Date: </b> <span>The date the company went public.</span>
          </li>
          <li>
            <b>Offer Price: </b>
            <span>The original offer price for the shares.</span>
          </li>
          <li>
            <b>Raised Amount: </b>
            <span>The total capital raised during the IPO.</span>
          </li>
          <li>
            <b>Original Price Range: </b>
            <span>The initial price range set during the offering.</span>
          </li>
        </ul>
      </li>
    </ol>
    <h2 id="maximizing_your_investment_strategy_with_the_ipo_calendar">
      Maximizing Your Investment Strategy with the IPO Calendar
    </h2>
    <ol>
      <li>
        <b>Identify High-Potential IPOs</b>
        <p>
          Pay close attention to the <b>Estimated Sale Volume</b> and the
          <b>Price Range</b>. Companies with high sale volumes and narrow price
          ranges are often perceived as more stable or in high demand, which
          could present good investment opportunities.
        </p>
      </li>
      <li>
        <b>Track Market Sentiment on Upcoming IPOs</b>
        <p class="m-0">
          The <b>Expected to Trade</b> date gives you an idea of when to expect
          volatility around these stocks. You can prepare by researching the
          company’s pipeline, news, and analysts' opinions leading up to the
          date.
        </p>
        <p>
          Look at the <b>Industry</b> and <b>Therapeutic Focus</b> to understand
          which sectors are drawing the most investor interest. Sectors like
          immunology, oncology, and precision medicine often attract more
          attention due to their high growth potential.
        </p>
      </li>
      <li>
        <b>Learn from Past IPOs</b>
        <p class="m-0">
          Use the <b>Past IPOs</b> section to track the performance of companies
          after going public. Compare the <b>Offer Price</b> to the
          <b>Current Price</b> to see how well the stock has performed since the
          IPO. This can help you spot patterns in how certain types of biotech
          companies perform in the market.
        </p>
        <p>
          Reviewing the <b>Underwriters</b> can also be useful. Well-established
          underwriters like J.P. Morgan or Morgan Stanley typically back
          companies with strong market potential, which may indicate a safer
          investment.
        </p>
      </li>
      <li>
        <b>Focus on Therapeutic Trends</b>

        <p>
          Understanding the <b>Therapeutic Focus</b> of past and upcoming IPOs
          allows you to spot trends in which biotech subsectors are receiving
          the most attention. If multiple companies in gene editing or oncology
          are going public, it might signal growing interest and potential
          breakthroughs in that area.
        </p>
      </li>
    </ol>
    <h2 id="catalystsync_user_guide">CatalystSync™ User Guide</h2>
    <p>
      <b class="cursorpointer" routerLink="/catalyst-sync"><u>CatalystSync™</u></b> is a powerful integrated platform that offers a
      panoramic view of ongoing clinical trials, their phases, completion dates,
      market capital, live cash, runway, and more. Designed for biotech and
      pharma investors, CatalystSync™ helps streamline discovery and investment
      opportunities. This guide will help you navigate the key features of
      CatalystSync™ and show you how to use the available data to make informed
      decisions.
    </p>
    <h2 id="key_features_of_catalystsync">Key Features of CatalystSync™</h2>
    <ol>
      <li>
        <b>Company Overview</b>
        <p class="m-0">
          The left side of the table lists <b>Company Name, Ticker</b>, and
          <b>Price</b>, helping you quickly identify the biotech or pharma
          companies under review.
        </p>
        <p>
          The <b>30 Days Price Change</b> column provides a visual
          representation of the stock price fluctuation over the past 30 days,
          helping you spot trends or volatility.
        </p>
      </li>
      <li>
        <b>Industry and Study Title</b>
        <p class="m-0">
          The <b>Industry</b> column specifies whether the company operates in
          <b>Drug Manufacturing, Biotechnology,</b> or another related sector.
        </p>
        <p>
          The <b>Title</b> column provides the name of the ongoing clinical
          study, giving you direct insight into the specific trial a company is
          working on. Clicking the <b>“Read More+” </b>link under each title
          allows you to dive deeper into the details of the study.
        </p>
      </li>
      <li>
        <b>Completion Date & Condition</b>
        <p class="m-0">
          The <b>Completion Date</b> column shows the expected completion date
          of the clinical trial, helping you time your investments around
          significant milestones.
        </p>
        <p>
          The <b>Condition</b> column indicates the specific medical condition
          or disease the trial is targeting, such as cancer, growth hormone
          disorders, or dermatological conditions.
        </p>
      </li>
      <li>
        <b>Pipeline</b>
        <p>
          The <b>Pipeline</b> column outlines the treatments or drugs in
          development, providing valuable insights into the company's R&D focus.
          Clicking on "Read More+" in the pipeline gives you further details on
          each drug in development.
        </p>
      </li>
      <li>
        <b>Phase and Market Cap</b>
        <p class="m-0">
          The <b>Phase</b> column shows the current clinical trial phase (e.g.,
          Phase 1, Phase 2, Phase 3, Phase 4). This helps you evaluate the stage
          of development—trials in Phase 3 or 4 tend to have more significant
          market impact.
        </p>
        <p>
          The <b>Market Cap</b> column displays the company’s market
          capitalization, which provides a snapshot of its overall size and
          financial stability.
        </p>
      </li>
      <li>
        <b>Live Cash, Volume, Burn Rate, and Runway</b>
        <p class="m-0">
          <b>Live Cash:</b> The cash reserves the company currently holds,
          useful for assessing a company’s financial health.
        </p>
        <p class="m-0">
          <b>Volume:</b> The trading volume of the company’s stock, indicating
          how actively the stock is being traded.
        </p>
        <p class="m-0">
          <b>Burn Rate:</b> The speed at which the company is using its cash
          reserves. A high burn rate may indicate financial strain, while a low
          burn rate suggests more extended financial flexibility.
        </p>
        <p>
          <b>Runway:</b> The number of months the company can continue operating
          before needing additional funds, calculated based on its current burn
          rate and live cash.
        </p>
      </li>
    </ol>
    <h2 id="maximizing_your_investment_strategy_with_catalystsync">
      Maximizing Your Investment Strategy with CatalystSync™
    </h2>
    <ol>
      <li>
        <b>Track Clinical Trial Progress</b>
        <p>
          Keep an eye on the <b>Completion Date</b> for critical clinical
          trials. Trials nearing completion can have a significant impact on
          stock prices, particularly if the results are positive. Plan your
          trades accordingly—enter before significant data releases and consider
          exiting positions if expectations are already priced into the stock.
        </p>
      </li>
      <li>
        <b>Focus on Late-Stage Trials</b>
        <p>
          Trials in <b>Phase 3</b> or <b>Phase 4</b> are typically closer to
          regulatory approval and market launch, making them more attractive
          from an investment perspective. These late-stage trials are often less
          risky and have more potential for success compared to earlier-stage
          studies.
        </p>
      </li>
      <li>
        <b>Use Burn Rate and Runway to Assess Financial Stability</b>
        <p>
          A company’s <b>Burn Rate</b> and <b>Runway</b> are crucial indicators
          of financial health. Companies with a high burn rate and a short
          runway may need to raise capital soon, potentially diluting current
          shares or slowing progress. Prioritize companies with a healthy
          <b>Runway</b> (the higher, the better) and manageable
          <b>Burn Rate</b>.
        </p>
      </li>
      <li>
        <b>Evaluate Market Potential Through Pipeline and Market Cap</b>
        <p>
          The <b>Pipeline</b> and <b>Market Cap</b> help you assess whether the
          company’s size is in line with its drug pipeline. A small company with
          a promising late-stage drug pipeline might present a strong growth
          opportunity, while a larger company with several trials in early
          stages could be more stable but slower to provide returns.
        </p>
      </li>
    </ol>
    <h2 id="company_screener_user_guide">Company Screener User Guide</h2>
    <p>
      The <b class="cursorpointer" routerLink="/company-screener"><u>Company Screener</u></b> on BioPharmaWatch offers a comprehensive view
      of the financial health, drug pipeline, and operational metrics of biotech
      and pharma companies. With data points such as P/E ratios, market cap,
      burn rate, and runway, this screener helps you evaluate companies for
      investment opportunities. This guide will walk you through the key
      features of the Company Screener and how to use them effectively.
    </p>
    <h2 id="navigating_the_company_screener">
      Navigating the Company Screener
    </h2>
    <ol>
      <li>
        <b>Company Name, Ticker, and Price</b>
        <p class="m-0">
          The first few columns provide the
          <b>Company Name, Stock Ticker,</b> and the <b>Current Stock Price</b>.
          You can easily identify the company and add it to your watchlist using
          the "+" symbol next to the ticker.
        </p>
        <p>
          The 30 Days Price Change column offers a visual representation of
          stock price fluctuations over the last 30 days, helping you quickly
          spot trends and patterns in stock performance.
        </p>
      </li>
      <li>
        <b>Industry and Specific Diseases</b>
        <p class="m-0">
          The Industry column tells you which sector the company operates in,
          such as <b>Biotechnology</b> or <b>Drug Manufacturers</b>.
        </p>
        <p>
          The Specific Diseases column highlights the diseases or conditions
          that the company's drugs or therapies target, giving you insight into
          the therapeutic focus of each company (e.g., cancer, autoimmune
          diseases).
        </p>
      </li>
      <li>
        <b>Therapeutic Area and Market Cap</b>
        <p class="m-0">
          The Therapeutic Area column helps you understand the broader area of
          medicine that the company’s products address, like
          <b>Cardiovascular, Oncology, </b>or <b>Endocrinology</b>.
        </p>
        <p>
          The Market Cap column displays the total market capitalization of the
          company, giving you an idea of its size and financial strength.
        </p>
      </li>
      <li>
        <b>Key Financial Metrics</b>
        <p class="m-0">
          <b>Volume: </b>
          <span
            >Shows the average number of shares traded daily, which helps assess
            the liquidity of the stock.</span
          >
        </p>
        <p class="m-0">
          <b>P/E Ratio: </b>
          <span
            >Price-to-Earnings Ratio is a valuation metric showing how much
            investors are willing to pay for a dollar of earnings. A higher P/E
            ratio suggests higher growth expectations.</span
          >
        </p>
        <p>
          <b>Insiders: </b>
          <span
            >Displays insider activity in terms of shares bought or sold. A
            positive number reflects insider buying (a good signal), while
            negative values indicate insider selling.</span
          >
        </p>
      </li>
      <li>
        <b>Cash Data and Runway</b>
        <p class="m-0">
          <b>Live Cash: </b>
          <span
            >The cash or cash equivalents available to the company. Companies
            with higher cash reserves are often better positioned to weather
            challenges and fund ongoing projects.</span
          >
        </p>
        <p class="m-0">
          <b>Burn Rate: </b>
          <span
            >Indicates how quickly the company is spending its cash reserves. A
            high burn rate might indicate financial strain, whereas a low burn
            rate suggests better cash management.</span
          >
        </p>
        <p>
          <b>Runway: </b>
          <span
            >Reflects how many months the company can continue operations based
            on its current burn rate and cash reserves. A longer runway
            indicates more financial stability.</span
          >
        </p>
      </li>
      <li>
        <b>EPS and EBITDA</b>
        <p class="m-0">
          <b>EPS (Earnings Per Share): </b>
          <span>
            Shows the company's profitability. Positive EPS is a sign of
            profitability, while negative EPS might indicate ongoing
            losses.</span
          >
        </p>
        <p>
          <b
            >EBITDA (Earnings Before Interest, Taxes, Depreciation, and
            Amortization):
          </b>
          <span
            >This metric offers insight into a company's profitability from its
            core operations, excluding external factors like taxes or financing
            costs.</span
          >
        </p>
      </li>
    </ol>
    <h2 id="maximizing_your_investment_strategy_with_the_company_screener">
      Maximizing Your Investment Strategy with the Company Screener
    </h2>
    <ol>
      <li>
        <b>Spot Financially Stable Companies</b>
        <p>
          Focus on companies with a healthy Live Cash balance and a Burn Rate
          that allows for a longer Runway. Companies with strong cash reserves
          and a long runway are less likely to require immediate capital raises,
          reducing dilution risk for investors.
        </p>
      </li>
      <li>
        <b>Analyze Insider Activity</b>
        <p>
          Use the Insiders column to track insider buying and selling. Large
          amounts of insider buying can indicate confidence in the company’s
          future, while insider selling could be a red flag.
        </p>
      </li>
      <li>
        <b>Evaluate Growth Potential with P/E Ratios</b>
        <p>
          Companies with a high P/E Ratio are often priced for growth, meaning
          the market expects them to deliver strong results. However, a lower
          P/E could signal that the stock is undervalued, offering a potential
          buying opportunity.
        </p>
      </li>
    </ol>
    <h2 id="inside_trades_user_guide">Inside Trades User Guide</h2>
    <p>
      The <b class="cursorpointer" routerLink="/inside-trades"><u>Inside Trades</u></b> tool on BioPharmaWatch allows investors to track
      insider transactions within the biotech and pharmaceutical sectors.
      Insider trades, such as buying or selling shares by executives or key
      stakeholders, can signal confidence or concerns within a company. This
      guide will help you navigate the <b class="cursorpointer" routerLink="/inside-trades"><u>Inside Trades</u></b> section and use it
      to make well-informed investment decisions.
    </p>
    <h2 id="key_features_of_inside_trades">Key Features of Inside Trades</h2>
    <ol>
      <li>
        <b>Company Name, Ticker, and Price</b>
        <p class="m-0">
          The first columns provide the <b>Company Name, Ticker</b>, and
          <b>Current Stock Price.</b> You can quickly identify the company,
          track the stock's recent performance, and add it to your watchlist
          using the <b>"+"</b> icon next to the ticker.
        </p>
        <p>
          <b>30 Days Price Change: </b
          ><span
            >This column shows a graphical representation of the stock's price
            change over the last 30 days, helping you spot price trends and
            volatility.</span
          >
        </p>
      </li>
      <li>
        <b>Transaction Details</b>
        <p class="m-0">
          <b>Transactor Name: </b>The name and title of the insider conducting
          the trade (e.g., Director, CEO, Chief Financial Officer). This
          provides insight into who within the company is making trades and may
          indicate how much confidence the insider has in the company’s future
          performance.
        </p>
        <p class="m-0">
          <b>Traded Shares: </b>Shows the number of shares bought or sold by the
          insider. Green numbers indicate buys, while red indicates sells,
          providing an easy way to gauge insider sentiment.
        </p>
        <p>
          <b>Trade Price: </b> The price at which the insider bought or sold
          their shares. Comparing this to the current stock price can offer
          insights into whether the insider got in at a favorable price.
        </p>
      </li>
      <li>
        <b>Trade Date and Current Holdings</b>
        <p class="m-0">
          <b>Trade Date: </b>The date the insider transaction took place, which
          is essential for identifying the timing of insider trades relative to
          stock performance and key company events.
        </p>
        <p>
          <b>Current Holdings: </b>The total number of shares that the insider
          currently holds after the trade. This helps assess the level of
          ownership commitment the insider has in the company.
        </p>
      </li>
      <li>
        <b>SEC Submission</b>
        <p class="m-0">
          <b>The SEC Submission</b> column shows the date and time the trade was
          officially reported to the U.S. Securities and Exchange Commission
          (SEC). This gives you transparency and confirmation of the trade.
        </p>
      </li>
    </ol>
    <h2 id="maximizing_your_investment_strategy_with_inside_trades">
      Maximizing Your Investment Strategy with Inside Trades
    </h2>
    <ol>
      <li>
        <b>Track Insider Confidence</b>
        <p class="m-0">
          <b>Buying Activity: </b> Insider buying is generally seen as a
          positive signal, as it indicates that insiders believe the stock is
          undervalued or that the company’s prospects are promising. Keep an eye
          on significant buys by top executives, particularly if they have
          historically timed their trades well.
        </p>
        <p>
          <b>Selling Activity: </b> Insider selling isn’t always a bad
          sign—insiders might sell for various reasons (e.g., personal financial
          needs, portfolio diversification). However, consistent or large-volume
          selling could signal potential problems within the company.
        </p>
      </li>
      <li>
        <b>Focus on High-Impact Insiders</b>
        <p>
          Pay close attention to trades made by key executives, such as
          <b>CEOs, CFOs, and Directors,</b> as they have the most insight into
          the company’s current and future outlook. Trades by 10% owners or
          large stakeholders can also have a significant market impact.
        </p>
      </li>
      <li>
        <b>Compare Trade Prices to Current Prices</b>
        <p>
          Use the <b>Trade Price</b> column to compare the insider’s buy or sell
          price with the <b>Current Stock Price.</b> If insiders are buying
          below the current price, it may indicate that they expect the stock to
          rise. If they're selling above the current price, it could be a signal
          to watch for further stock declines.
        </p>
      </li>
      <li>
        <b>Monitor Timing Relative to Company Announcements</b>
        <p>
          Pay attention to the <b>Trade Date</b> and how it aligns with major
          announcements, earnings reports, or FDA decisions. Insider trades
          shortly before key news can indicate that insiders have confidence in
          or are hedging against upcoming developments.
        </p>
      </li>
      <li>
        <b>Follow Patterns of Consistent Buying or Selling</b>
        <p>
          If you notice <b>consistent buying</b> from multiple insiders over
          time, it may be a sign of confidence in the company’s future growth.
          Conversely, if multiple insiders are <b>consistently selling</b>, it
          may warrant caution or further investigation.
        </p>
      </li>
    </ol>
    <h2 id="quick_tips_for_using_inside_trades">
      Quick Tips for Using Inside Trades
    </h2>
    <ul>
      <li>
        <b>Filter by Trade Type: </b>
        <span
          >Use the filter options at the top to display only <b>Buy</b> or
          <b>Sell</b> transactions, allowing you to focus on the trades that
          align with your strategy.</span
        >
      </li>
      <li>
        <b>Spot Large Buys or Sells: </b>
        <span
          >Keep an eye out for large trades in terms of <b>Traded Shares</b> or
          <b>Trade Value.</b> Significant purchases or sales can indicate major
          shifts in sentiment within the company.</span
        >
      </li>
      <li>
        <b>Cross-Reference with Price Movements: </b>
        <span
          >If insiders are buying during periods of price decline or selling
          during a rally, it may provide clues about their long-term outlook for
          the stock.</span
        >
      </li>
    </ul>
    <h2 id="hedge_fund_screener_user_guide">Hedge Fund Screener User Guide</h2>
    <p>
      The <b class="cursorpointer" routerLink="/hedgefunds"><u>Hedge Fund Screener</u></b> on BioPharmaWatch allows investors to track
      the investment holdings, performance, and strategies of major hedge funds
      within the biotech and pharmaceutical sectors. By analyzing the data on
      hedge fund activities, you can gain valuable insights into market trends
      and investment patterns, helping you make more informed decisions.
    </p>
    <h2 id="navigating_the_hedge_fund_screener">
      Navigating the Hedge Fund Screener
    </h2>
    <ol>
      <li>
        <b>Fund Name and Fund Size</b>
        <p class="m-0">
          The <b>Fund Name</b> column lists the names of the hedge funds
          investing in biotech and pharma stocks. This feature gives you
          visibility into the major players in the industry.
        </p>
        <p>
          <b>Fund Size</b> refers to the total assets under management by the
          hedge fund, measured in billions. This data helps you assess the scale
          and impact of each fund's investments.
        </p>
      </li>
      <li>
        <b>Stocks Held and Largest Holding</b>
        <p class="m-0">
          The <b>Stocks Held</b> column shows how many different stocks each
          fund is invested in. A higher number of stocks typically indicates a
          diversified portfolio, while a smaller number may indicate
          concentrated positions in a few companies.
        </p>
        <p>
          The <b>Largest Holding</b> column lists the top stock by position size
          in each fund’s portfolio. This gives insight into where hedge funds
          have the highest conviction, as their largest holding usually
          represents the most significant part of their strategy.
        </p>
      </li>
      <li>
        <b>Quarter-Over-Quarter (QoQ) Change</b>
        <p class="m-0">
          <b>QoQ Change</b> shows the change in the value of a hedge fund’s
          position in its largest holding from one quarter to the next. A
          positive number indicates an increase in position size, while a
          negative number shows a reduction. This can help you spot trends and
          shifts in fund strategy:
        </p>
        <ul class="sub-li">
          <li><b>Green Up Arrow: </b> <span>Increased position.</span></li>
          <li><b>Red Down Arrow: </b> <span>Decreased position.</span></li>
        </ul>
      </li>
    </ol>
    <h2 id="maximizing_your_investment_strategy_with_the_hedge_fund_screener">
      Maximizing Your Investment Strategy with the Hedge Fund Screener
    </h2>
    <ol>
      <li>
        <b>Follow Smart Money</b>
        <p>
          Hedge funds are considered "smart money" in the market, meaning they
          often have more resources and information to make investment
          decisions. By tracking their largest holdings and recent changes in
          positions, you can identify trends that might be worth following.
        </p>
      </li>
      <li>
        <b>Spot Opportunities in Fund Favorites</b>
        <p>
          Funds with large positions in certain stocks (as seen in the
          <b>Largest Holding</b> column) often have deep convictions in those
          companies. If you see multiple hedge funds holding or increasing
          positions in the same stock, it could be an indicator of strong
          potential growth.
        </p>
      </li>
      <li>
        <b>Monitor Hedge Fund Sentiment</b>
        <p>
          Use the <b>QoQ Change</b> column to observe changes in hedge fund
          sentiment toward specific stocks. A significant increase in position
          size by multiple funds could signal growing optimism about a company,
          while large reductions may be a warning sign of potential downside
          risk.
        </p>
      </li>
      <li>
        <b>Analyze Diversification Strategies</b>
        <p>
          The <b>Stocks Held</b> column provides insight into each hedge fund’s
          diversification strategy. Funds holding many stocks might be spreading
          their risk across the sector, while funds holding fewer stocks may
          have more concentrated bets on certain companies. Understanding this
          can help you assess the fund’s risk profile and how it aligns with
          your investment approach.
        </p>
      </li>
      <li>
        <b>Fund Size and Influence</b>
        <p>
          <b>Fund Size</b> helps you gauge the influence a hedge fund may have
          on the market. Larger funds may have more influence on stock price
          movements when they adjust their positions. Tracking the activities of
          these larger funds can give you early signals on potential market
          shifts.
        </p>
      </li>
    </ol>
    <h2 id="all_stock_holdings_user_guide">All Stock Holdings User Guide</h2>
    <p>
      The <b class="cursorpointer" routerLink="/allstockshold"><u>All Stock Holdings</u></b> feature on BioPharmaWatch offers a powerful
      snapshot of biopharma investments, providing insights into how elite hedge
      funds allocate their investments. This tool allows you to view company
      support, stock performance, and investor consensus for strategic market
      insights. Below is a guide to navigating the key features of this section.
    </p>
    <h2 id="key_features_of_all_stock_holdings">
      Key Features of All Stock Holdings
    </h2>
    <ol>
      <li>
        <b>Rank and Stock</b>
        <p class="m-0">
          The <b>Rank</b> column shows the relative ranking of stocks held by
          hedge funds, indicating which stocks are most popular or hold the
          highest market value.
        </p>
        <p>
          The <b>Stock</b> column lists the stock ticker for each biopharma
          company, providing an easy reference for further investigation or
          tracking. You can click on the ticker to view more details about the
          stock.
        </p>
      </li>
      <li>
        <b>Company Name and Shares Held</b>
        <p class="m-0">
          <b>Company Name</b> provides the full name of the biopharma company,
          offering clarity on the specific stock being referenced by its ticker.
        </p>
        <p>
          <b>Shares Held</b> displays the total number of shares held by various
          hedge funds, offering insights into how much interest these funds have
          in each stock. A larger number of shares suggests stronger hedge fund
          support or confidence in the company’s growth potential.
        </p>
      </li>
      <li>
        <b>Market Value</b>
        <p>
          The <b>Market Value</b> column represents the total market value of
          the stock held by hedge funds, measured in billions of dollars. This
          gives a sense of the overall worth of the stock in hedge fund
          portfolios. Larger market values generally indicate higher levels of
          confidence and support from elite investors.
        </p>
      </li>
    </ol>
    <h2 id="maximizing_your_investment_strategy_with_all_stock_holdings">
      Maximizing Your Investment Strategy with All Stock Holdings
    </h2>
    <ol>
      <li class="">
        <b>Focus on Top-Ranked Stocks</b>
        <p class="mb-2">
          The ranking system helps you quickly identify which stocks are the
          most heavily supported by hedge funds. High-ranking stocks like
          <b>Ascendis Pharma (ASND)</b> or
          <b>Madrigal Pharmaceuticals (MDGL)</b> indicate strong conviction from
          the hedge fund community. These stocks may present less risk due to
          widespread backing.
        </p>
      </li>
      <li>
        <b>Track Hedge Fund Interest by Shares Held</b>
        <p>
          Stocks with a large number of <b>Shares Held</b> show significant
          hedge fund interest. If a particular stock, like
          <b>Incyte Corporation (INCY),</b> has a high number of shares held by
          hedge funds, it suggests strong institutional confidence. This can be
          a good indicator for potential stock stability or future growth.
        </p>
      </li>
      <li>
        <b>Analyze Market Value for Investment Size</b>
        <p>
          The <b>Market Value</b> provides a clear picture of how much hedge
          funds are investing in each stock. For example,
          <b>Ascendis Pharma (ASND)</b> has a market value of over $2.18 billion
          in hedge fund holdings, indicating that it's a key focus for many
          large investors. Tracking this value helps you align your investments
          with those of seasoned professionals.
        </p>
      </li>
      <li>
        <b>Spot Opportunities in Mid-Ranked Stocks</b>
        <p>
          Stocks that appear in the middle of the rankings may still hold great
          potential, especially if they have a growing number of shares held or
          a rising market value. These could be companies that are on the verge
          of gaining more significant support from hedge funds, offering you a
          potential early-entry advantage.
        </p>
      </li>
      <li>
        <b>Evaluate Growth Potential with Diversified Holdings</b>
        <p>
          Looking at the variety of companies held across different therapeutic
          areas or disease focuses helps you understand broader industry trends.
          For example, hedge funds might favor companies involved in
          <b>Oncology, Autoimmune Disorders,</b> or <b>Rare Diseases</b>, which
          could indicate sectors primed for growth.
        </p>
      </li>
    </ol>
    <h2 id="maximizing_your_investment_strategy_with_all_stock_holdings">
      Quick Tips for Using All Stock Holdings
    </h2>
    <ul>
      <li class="mb-2">
        <b>Use the Search Bar: </b>
        <span
          >If you’re looking for a specific stock, you can use the search bar to
          quickly find it within the holdings list. This feature allows you to
          streamline your analysis and focus on companies of interest.</span
        >
      </li>
      <li class="mb-2">
        <b>Filter by Price or Market Value: </b>
        <span
          >Use the filter options at the top to organize stocks based on
          <b>Average Price</b> or <b>Market Value</b>, depending on your
          investment strategy. This can help you spot the most valuable or
          potentially undervalued stocks.</span
        >
      </li>
      <li class="mb-2">
        <b>Investigate Changes in Shareholding: </b>
        <span
          >If you notice a significant number of shares being held by hedge
          funds increasing over time, it may be a sign of growing confidence in
          the stock, which could offer a good buying opportunity.</span
        >
      </li>
    </ul>
  </div>
</div>
