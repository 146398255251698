import { Component, OnInit } from '@angular/core';
import { BlogService } from '../../../shared/service/blog.service';
import { BlogUserService } from '../../../shared/service/blog-user.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
})
export class BlogComponent implements OnInit {
  blogData: any[] = [];
  p: number = 1;
  currentPage: number = 1;
  pageSize: number = 9;
  totalPages: number = 0;
  total: number = 0;
  isuserDetailsTriggered: boolean = false;
  selectedUser: any;

  constructor(
    private blogService: BlogService,
    private blogmoreDetail: BlogUserService,
    private router: Router,
    private toaster: ToastrService,
    private meta: Meta
  ) {}

  ngOnInit(): void {
    this.blogsData();
  }

  blogsData() {
    this.blogService.getblogdata().subscribe((data: any) => {
      this.blogData = data;
      this.totalPages = Math.ceil(this.blogData.length / this.pageSize);
      this.currentPage = 1;
    });
  }

  pageChangeEvent(event: number) {
    this.p = event;
    this.blogsData();
  }

  onuserClick(blogUrl: any) {
    if (!this.isuserDetailsTriggered) {
      this.isuserDetailsTriggered = true;
      this.router.navigate(['blog', blogUrl]);
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  getPaginatedBlogs() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return this.blogData.slice(startIndex, endIndex);
  }

  getPageNumbers(): number[] {
    const pageNumbers = [];
    for (let i = 1; i <= this.totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }

  goToPage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }
}
