import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FdaCalendarComponent } from './modules/feature/products/fda-calendar/fda-calendar.component';
import { HomeComponent } from './modules/feature/products/home/home.component';
import { CompanyScreenerComponent } from './modules/feature/products/company-screener/company-screener.component';
import { MyportfolioComponent } from './modules/feature/products/myportfolio/myportfolio.component';
import { InsideTradesComponent } from './modules/feature/products/inside-trades/inside-trades.component';
import { WebsiteUserComponent } from './modules/feature/pages/website-user/website-user.component';
import { SubscriptionComponent } from './modules/feature/products/subscription/subscription.component';
import { LoginComponent } from './modules/core/auth/login/login.component';
import { SignupComponent } from './modules/core/auth/signup/signup.component';
import { CatalystSyncComponent } from './modules/feature/products/catalyst-sync/catalyst-sync.component';
import { ProfileComponent } from './modules/core/auth/profile/profile.component';
import { AuthGuard } from './modules/core/guards/auth.guard';
import { VolatileDataComponent } from './modules/feature/products/volatile-data/volatile-data.component';
import { ForgotPasswordComponent } from './modules/core/auth/forgot-password/forgot-password.component';
import { CompanyDetailsComponent } from './modules/feature/products/company-details/company-details.component';
import { BiotechEarningsComponent } from './modules/feature/products/biotech-earnings/biotech-earnings.component';
import { BlogComponent } from './modules/feature/pages/blog/blog.component';
import { BlogViewMoreComponent } from './modules/feature/products/blog-view-more/blog-view-more.component';
import { AboutComponent } from './modules/feature/pages/about/about.component';
import { ContactUsComponent } from './modules/feature/pages/contact-us/contact-us.component';
import { LegalAboutComponent } from './modules/feature/pages/legal-about/legal-about.component';
import { HedgefundsComponent } from './modules/feature/products/hedgefunds/hedgefunds.component';
import { IndividualfundComponent } from './modules/feature/products/individualfund/individualfund.component';
import { AllstocksholdComponent } from './modules/feature/products/allstockshold/allstockshold.component';
import { AdvertiseComponent } from './modules/feature/pages/advertise/advertise.component';
import { PrivacyPolicyComponent } from './modules/feature/pages/privacy-policy/privacy-policy.component';
import { TermsServiceComponent } from './modules/feature/pages/terms-service/terms-service.component';
import { PdufaCalendarComponent } from './modules/feature/products/pdufa-calendar/pdufa-calendar.component';
import { HitmapComponent } from './modules/feature/products/hitmap/hitmap.component';
import { ScrolltopComponent } from './modules/shared/components/scrolltop/scrolltop.component';
import { FdaCalenderAdminComponent } from './modules/core/admin/fda-calender-admin/fda-calender-admin.component';
import { BlogadminComponent } from './modules/core/admin/blogadmin/blogadmin.component';
import { adminAuthGuard } from './modules/core/guards/admin-auth.guard';
import { NewsComponent } from './modules/feature/products/news/news.component';
import { HistoricalPDUFAComponent } from './modules/feature/products/historical-pdufa/historical-pdufa.component';
import { ConferenceCalendarComponent } from './modules/feature/products/conference-calendar/conference-calendar.component';
import { IpoComponentComponent } from './modules/feature/products/ipo-component/ipo-component.component';
import { BioHedgeFundShotsComponent } from './modules/feature/products/bio-hedge-fund-shots/bio-hedge-fund-shots.component';

import { UpcomingipoComponent } from './modules/feature/products/upcomingipo/upcomingipo.component';
import { PastIpoComponent } from './modules/feature/products/past-ipo/past-ipo.component';
import { DailyUpdatesComponent } from './modules/feature/products/daily-updates/daily-updates.component';
import { InvestingMasterclassComponent } from './modules/feature/pages/investing-masterclass/investing-masterclass.component';
import { AdminComponent } from './modules/core/admin/admin/admin.component';
import { MarketMoversComponent } from './modules/feature/products/market-movers/market-movers.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: 'home',
    redirectTo: '',
    component: HomeComponent,
    title: 'BioPharmaWatch',
  },
  {
    path: 'login',
    component: LoginComponent,
    title: 'Login',
  },
  {
    path: 'signup',
    component: SignupComponent,
    title: 'Signup',
  },
  {
    path: 'fda-calendar',
    component: FdaCalendarComponent,
    title: 'FDA Calendar',
  },
  {
    path: 'company-screener',
    component: CompanyScreenerComponent,
    title: 'Company Screener',
  },
  {
    path: 'biotechearnings',
    component: BiotechEarningsComponent,
    title: 'BiotechEarnings',
  },
  {
    path: 'myportfolio',
    component: MyportfolioComponent,
    title: 'My Portfolio',
  },
  {
    path: 'inside-trades',
    component: InsideTradesComponent,
    title: 'Inside Trades',
  },
  {
    path: 'website-user',
    component: WebsiteUserComponent,
    title: 'Website User',
  },
  {
    path: 'about',
    component: AboutComponent,
    title: 'About',
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    title: 'Contact Us',
  },
  {
    path: 'legal-about',
    component: LegalAboutComponent,
    title: 'Legal About',
  },
  {
    path: 'subscription',
    component: SubscriptionComponent,
    title: 'Subscription',
  },
  {
    path: 'catalyst-sync',
    component: CatalystSyncComponent,
    title: 'Catalyst Sync',
  },
  {
    path: 'Terms-service',
    component: TermsServiceComponent,
    title: 'Terms service',
  },
  {
    path: 'new-password/:token',
    component: ForgotPasswordComponent,
    title: 'BioPharmaWatch',
  },
  {
    path: 'blog/:id',
    component: BlogViewMoreComponent,
  },
  {
    path: 'blog',
    component: BlogComponent,
    title: 'Blog',
  },
  {
    path: 'daily-updates',
    component: DailyUpdatesComponent,
    title: 'daily-updates',
  },
  {
    path: 'company/:ticker',
    component: CompanyDetailsComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    title: 'Profile',
  },
  {
    path: 'volatile-Data',
    component: VolatileDataComponent,
    canActivate: [AuthGuard],
    title: 'Volatile Data',
  },
  {
    path: 'hedgefunds',
    component: HedgefundsComponent,
    title: 'Hedge Funds',
  },
  {
    path: 'individualfund/:fundname',
    component: IndividualfundComponent,
    title: 'Individual Fund',
  },
  {
    path: 'allstockshold',
    component: AllstocksholdComponent,
    title: 'AllStocks Hold',
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    title: 'Privacy Policy',
  },
  {
    path: 'PDUFA-calendar',
    component: PdufaCalendarComponent,
    title: 'PDUFA calendar',
  },
  {
    path: 'advertise',
    component: AdvertiseComponent,
    title: 'Advertise',
  },
  {
    path: 'heatmap',
    component: HitmapComponent,
    title: 'Heatmap',
  },
  {
    path: 'scroll',
    component: ScrolltopComponent,
    title: 'scroll',
  },
  {
    path: 'fda-calender-admin',
    component: FdaCalenderAdminComponent,
    canActivate: [adminAuthGuard],
    title: 'fda-admin',
  },
  {
    path: 'blog-admin',
    component: BlogadminComponent,
    canActivate: [adminAuthGuard],
    title: 'blog-admin',
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [adminAuthGuard],
    title: 'Admin Dashboard',
  },
  {
    path: 'news',
    component: NewsComponent,
    title: 'News',
  },
  {
    path: 'signup/verify/:otp',
    component: SignupComponent,
    title: 'Signup',
  },
  {
    path: 'historical-pdufa-calender',
    component: HistoricalPDUFAComponent,
    title: 'Historial Pdufa Calender',
  },
  {
    path: 'conference-calendar',
    component: ConferenceCalendarComponent,
    title: 'Conference Calendar',
  },
  {
    path: 'ipo-sec',
    component: IpoComponentComponent,
    title: 'IPO',
  },
  {
    path: 'bio-hedge-fund-shots',
    component: BioHedgeFundShotsComponent,
    title: 'Bio-Hedge-Fund-Shots',
  },
  {
    path: 'upcoming-ipo',
    component: UpcomingipoComponent,
    title: 'Upcoming Ipo',
  },
  {
    path: 'past-ipo',
    component: PastIpoComponent,
    title: 'Upcoming Ipo',
  },
  {
    path: 'investing-masterclass',
    component: InvestingMasterclassComponent,
    title: 'Investing Masterclass',
  },
  {
    path: 'market-movers',
    component: MarketMoversComponent,
    title: 'Market Movers'
  },
  { path: '**', component: HomeComponent, title: 'BioPharmaWatch' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
