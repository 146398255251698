import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FdaCalendarComponent } from './products/fda-calendar/fda-calendar.component';
import { CompanyScreenerComponent } from './products/company-screener/company-screener.component';
import { MyportfolioComponent } from './products/myportfolio/myportfolio.component';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionComponent } from './products/subscription/subscription.component';
import { InsideTradesComponent } from './products/inside-trades/inside-trades.component';
import { WebsiteUserComponent } from './pages/website-user/website-user.component';
import { HomeComponent } from './products/home/home.component';
import { CatalystSyncComponent } from './products/catalyst-sync/catalyst-sync.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { VolatileDataComponent } from './products/volatile-data/volatile-data.component';
import { CompanyDetailsComponent } from './products/company-details/company-details.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from '../core/interceptors/token.interceptor';
import { BiotechEarningsComponent } from './products/biotech-earnings/biotech-earnings.component';
import { SharedModule } from '../shared/shared.module';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogViewMoreComponent } from './products/blog-view-more/blog-view-more.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { LegalAboutComponent } from './pages/legal-about/legal-about.component';
import { ThirtyDayTrendComponent } from '../shared/components/thirty-day-trend/thirty-day-trend.component';
import { HedgefundsComponent } from './products/hedgefunds/hedgefunds.component';
import { IndividualfundComponent } from './products/individualfund/individualfund.component';
import { AllstocksholdComponent } from './products/allstockshold/allstockshold.component';
import { AdvertiseComponent } from './pages/advertise/advertise.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsServiceComponent } from './pages/terms-service/terms-service.component';
import { PdufaCalendarComponent } from './products/pdufa-calendar/pdufa-calendar.component';
import { HitmapComponent } from './products/hitmap/hitmap.component'; // Correct import

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PortfoliomodalComponent } from '../shared/components/portfoliomodal/portfoliomodal.component';
import { NewsComponent } from './products/news/news.component';
import { HistoricalPDUFAComponent } from './products/historical-pdufa/historical-pdufa.component';
import { ConferenceCalendarComponent } from './products/conference-calendar/conference-calendar.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { IpoComponentComponent } from './products/ipo-component/ipo-component.component';

@NgModule({
  declarations: [
    FdaCalendarComponent,
    CompanyScreenerComponent,
    MyportfolioComponent,
    SubscriptionComponent,
    InsideTradesComponent,
    WebsiteUserComponent,
    HomeComponent,
    CatalystSyncComponent,
    VolatileDataComponent,
    CompanyDetailsComponent,
    BiotechEarningsComponent,
    BlogComponent,
    BlogViewMoreComponent,
    AboutComponent,
    ContactUsComponent,
    LegalAboutComponent,
    ThirtyDayTrendComponent,
    HedgefundsComponent,
    IndividualfundComponent,
    AllstocksholdComponent,
    AdvertiseComponent,
    PrivacyPolicyComponent,
    TermsServiceComponent,
    PdufaCalendarComponent,
    HitmapComponent,
    NewsComponent,
    HistoricalPDUFAComponent,
    ConferenceCalendarComponent,
    IpoComponentComponent,
    
  ],
  exports: [
    CatalystSyncComponent,
    FdaCalendarComponent,
    CompanyScreenerComponent,
    MyportfolioComponent,
    SubscriptionComponent,
    InsideTradesComponent,
    WebsiteUserComponent,
    HomeComponent,
    ThirtyDayTrendComponent,
    NewsComponent,
    HistoricalPDUFAComponent,
  ],
  providers: [
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  imports: [
    SharedModule,
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
    NgbDatepickerModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    NgxSkeletonLoaderModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true,
      closeButton: true,
    }),
    BsDatepickerModule.forRoot(),
    PortfoliomodalComponent,
  ],
})
export class FeatureModule {}
