import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { addadminfda, editadminfda, getadminfda, removeadminfda, searchadmin, searchsuggectionadmin } from '../../shared/const';

@Injectable({
  providedIn: 'root'
})
export class AdminFdaServiceService {

  constructor(private http: HttpClient) { }

  getFdaadminData() {
    return this.http.get<any[]>(`${getadminfda}`);
  }
  editFdaadminData(data: any) {
    return this.http.post<any[]>(`${editadminfda}`, data);
  }
  deleteFdaadminData(id: object) {
    return this.http.post<any[]>(`${removeadminfda}`, id);
  }
  addFdaadminData(id: object) {
    return this.http.post<any[]>(`${addadminfda}`, id);
  }
  searchsuggection(data: any) {
    return this.http.get<any[]>(`${searchsuggectionadmin}${data}`);
  }
  fdasearchadmin(data: any) {
    return this.http.post<any[]>(`${searchadmin}`, data);
  }
}

