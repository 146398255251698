import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ContactUsService } from 'src/app/modules/shared/service/contact-us.service';
@Component({
  selector: 'app-advertise',
  templateUrl: './advertise.component.html',
  styleUrls: ['./advertise.component.scss']
})
export class AdvertiseComponent {
  protected AdvertiseForm!: FormGroup;
  submitted: boolean = false;

  constructor(private fb: FormBuilder, private toaster: ToastrService,private conatctusapi: ContactUsService) { }

  ngOnInit() {
    this.AdvertiseForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      subject: ['', Validators.required],
      role: ['', Validators.required],
      feedback: ['', Validators.required],
    })
  }


  submit(data: any) {
    this.submitted = true
    if (this.AdvertiseForm.valid) {
      this.conatctusapi.advertiesData(data).subscribe({
        next: ((res: any) => {
          this.submitted = false
          this.toaster.success(res)
          this.AdvertiseForm.reset()
        }), error: (error) => {
          this.AdvertiseForm.reset()

          this.toaster.error(error)

        }
      })
    }
  }
}
