import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-stage-progress',
  templateUrl: './stage-progress.component.html',
  styleUrls: ['./stage-progress.component.scss'],
})
export class StageProgressComponent {
  @Input() stage: any;
  progressline: any;
  progress: string = '';
  NotApplicable = false;
  bar40 = false;
  bar20 = false;
  bar10 = false;
  bar60 = false;
  bar70 = false;
  bar80 = false;
  bar100 = false;

  constructor() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.getStage(this.stage);
    });
  }

  getStage(stage: any) {
    switch (stage) {
      case '':
        this.NotApplicable = true;
        break;
      case 'NA':
        this.NotApplicable = true;
        break;
      case 'null':
        this.NotApplicable = true;
        break;
      case 'Not Applicable':
        this.NotApplicable = true;
        break;
      case 'Pre-clinical':
        this.bar10 = true;
        break;
      case 'Phase 1':
        this.bar20 = true;
        break;
      case 'EARLY_PHASE1':
        this.bar20 = true;
        break;
      case 'PHASE1':
        this.bar20 = true;
        break;
      case 'Phase 1/2':
        this.bar20 = true;
        break;
      case 'Phase 1,Phase 2':
        this.bar20 = true;
        break;
      case 'Phase 1/2a':
        this.bar20 = true;
        break;
      case 'Phase 1a':
        this.bar20 = true;
        break;
      case 'Phase 1a/1b':
        this.bar20 = true;
        break;
      case 'Phase 1b':
        this.bar20 = true;
        break;
      case 'Phase 1b/2':
        this.bar20 = true;
        break;
      case 'Phase 1b/2a':
        this.bar20 = true;
        break;
      case 'Investigational New Drug (IND)':
        this.bar20 = true;
        break;
      case 'Phase 2':
        this.bar40 = true;
        break;
      case 'PHASE2':
        this.bar40 = true;
        break;
      case 'Phase 2/3':
        this.bar40 = true;
        break;
      case 'Phase 2a':
        this.bar40 = true;
        break;
      case 'Phase 2b':
        this.bar40 = true;
        break;
      case 'Phase 2b/3':
        this.bar40 = true;
        break;
      case 'Phase 3':
        this.bar60 = true;
        break;
      case 'PHASE3':
        this.bar60 = true;
        break;
      case 'Phase 3b':
        this.bar60 = true;
        break;
      case 'NDA Submission':
        this.bar70 = true;
        break;
      case 'Phase 4':
        this.bar80 = true;
        break;
      case 'PHASE4':
        this.bar80 = true;
        break;
      case 'PDUFA Adcomm':
        this.bar80 = true;
        break;
      case 'Abbreviated New Drug Application (ANDA)':
        this.bar100 = true;
        break;
      case 'Biologics License Applications (BLA)':
        this.bar100 = true;
        break;
      case 'Biologics License Applications (BLA) Priority Review':
        this.bar100 = true;
        break;
      case 'BLA':
        this.bar100 = true;
        break;
      case 'PDUFA':
        this.bar100 = true;
        break;
      case 'NDA':
        this.bar100 = true;
        break;
      case 'Breakthrough Device Designation':
        this.bar100 = true;
        break;
      case 'European Medicines Agency (EMA)':
        this.bar100 = true;
        break;
      case 'New Drug Application (NDA)':
        this.bar100 = true;
        break;
      case 'New Drug Application (NDA) Priority Review':
        this.bar100 = true;
        break;
      case 'supplemental  New Drug Application (sNDA)':
        this.bar100 = true;
        break;
      case 'supplemental Biologics License Applications (sBLA) Priority Review':
        this.bar100 = true;
        break;
      default:
    }
  }

  stageBlank() {
    return this.NotApplicable
      ? 'w-100 d-flex justify-content-center bg-transparent'
      : 'bg-light';
  }
}
