<!-- <div (mouseleave)="showDropDown = false">
    <button type="button"
        class="border-btn form-input text-nowrap align-items-center m-0 btn d-flex justify-content-between"
        (click)="showDropDown=!showDropDown">
        <i class="fa-solid fa-ellipsis-vertical"></i>
    </button>
    <div class="drop-show p-2" *ngIf="showDropDown">
        <div class="form-check form-switch p-2 m-0">
            <label class="form-check-label p-0 m-0" for="selectAllCheckbox">Select</label>
            <input class="form-check-input" type="checkbox" checked id="selectAllCheckbox"
                (click)="select(selectedAll)">
        </div>
        <label class="d-flex align-items-center label-drop" *ngFor="let a of list">
            <input type="checkbox" class="form-check-input" [(ngModel)]="a.checked"
                (change)="getSelectedValue(a.checked,a.name)" />
            <span class="form-input-label">{{a.name}}</span>
        </label>
    </div>
</div> -->
<div class="dropdown">
    <button class="border-0 form-input text-nowrap align-items-center m-0 btn d-flex justify-content-between"
        data-bs-auto-close="outside" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <p class="tblcompanyLength m-0 mx-2">Customize</p>
        <i style="font-size: 15px;" class="fa fa-caret-down"></i>
    </button>
    <ul class="dropdown-menu">
        <div class="drop-show p-2">
            <div class="form-check form-switch p-2 m-0">
                <label class="form-check-label p-0 m-0" for="selectAllCheckbox">Select</label>
                <input class="form-check-input" type="checkbox" checked id="selectAllCheckbox"
                    (click)="select(selectedAll)">
            </div>

            @for (a of list; track $index) {
            <label class="d-flex align-items-center label-drop">
                <input type="checkbox" class="form-check-input" [(ngModel)]="a.checked"
                    (change)="getSelectedValue(a.checked,a.name)" />
                <span class="form-input-label text-nowrap">{{a.name}}</span>
            </label>
            }

        </div>
    </ul>
</div>