<div class="table-button">
  <div class="d-flex section-route">
    <div class="section-scroll section-route hide-marque">
      <div class="text-center mx-1">
        <p (click)="scrollToSection(MostVolatiles)" class="table-option mb-0">
          Today's Most Volatile
        </p>
      </div>
      <span class="d-flex align-items-center">|</span>
      <div class="text-center mx-1">
        <p (click)="scrollToSection(PreMarket)" class="table-option mb-0">
          Pre Market
        </p>
      </div>
      <span class="d-flex align-items-center">|</span>
      <div class="text-center mx-1">
        <p
          class="table-option mb-0"
          (click)="scrollToSection(UpcomingEarningss)"
        >
          Upcoming Earning
        </p>
      </div>
      <span class="d-flex align-items-center">|</span>
      <div class="text-center mx-1">
        <p class="table-option mb-0" (click)="scrollToSection(highestVolumes)">
          Highest Volume
        </p>
      </div>
      <span class="d-flex align-items-center">|</span>
      <div class="text-center mx-1">
        <p (click)="scrollToSection(ShortInterest)" class="table-option mb-0">
          Short Interest
        </p>
      </div>
      <span class="d-flex align-items-center">|</span>
      <div class="text-center mx-1">
        <p
          (click)="scrollToSection(activeStockOptionss)"
          class="table-option mb-0"
        >
          Most Active Stock Options
        </p>
      </div>
      <span class="d-flex align-items-center">|</span>
      <div class="text-center mx-1">
        <p
          (click)="scrollToSection(UnusualActivitys)"
          class="table-option mb-0"
        >
          Unusual Option Activity
        </p>
      </div>
    </div>

    <marquee
      class="show-marque d-none"
      behavior="alternate"
      scrollamount="3"
      direction="right"
    >
      <div class="d-flex">
        <div class="text-center mx-1">
          <p (click)="scrollToSection(MostVolatiles)" class="table-option mb-0">
            Today's Most Volatile
          </p>
        </div>
        <span class="d-flex align-items-center">|</span>
        <div class="text-center mx-1">
          <p (click)="scrollToSection(PreMarket)" class="table-option mb-0">
            Pre Market
          </p>
        </div>
        <span class="d-flex align-items-center">|</span>
        <div class="text-center mx-1">
          <p
            class="table-option mb-0"
            (click)="scrollToSection(UpcomingEarningss)"
          >
            Upcoming Earning
          </p>
        </div>
        <span class="d-flex align-items-center">|</span>
        <div class="text-center mx-1">
          <p
            class="table-option mb-0"
            (click)="scrollToSection(highestVolumes)"
          >
            Highest Volume
          </p>
        </div>
        <span class="d-flex align-items-center">|</span>
        <div class="text-center mx-1">
          <p (click)="scrollToSection(ShortInterest)" class="table-option mb-0">
            Short Interest
          </p>
        </div>
        <span class="d-flex align-items-center">|</span>
        <div class="text-center mx-1">
          <p
            (click)="scrollToSection(activeStockOptionss)"
            class="table-option mb-0"
          >
            Most Active Stock Options
          </p>
        </div>
        <span class="d-flex align-items-center">|</span>
        <div class="text-center mx-1">
          <p
            (click)="scrollToSection(UnusualActivitys)"
            class="table-option mb-0"
          >
            Unusual Option Activity
          </p>
        </div>
      </div>
    </marquee>
  </div>
</div>

<section class="banner">
  <div class="container banner-space mt-5 mb-3">
    <div class="row">
      <div class="col-xl-6 col-sm-12">
        <div class="head-title">
          <h2 class="header-text">
            Most actionable catalysts events for
            <span class="home-head-color">Biotech</span> and
            <span class="home-head-color">Pharma investors</span>
          </h2>
          <p class="pargh-text">
            Simplifying biotech investing with real-time updates and analysis
            from scientists, investment experts, and AI. Constantly tracking
            more than 1,000 companies and their 85+ key metrics such as clinical
            trial progress, FDA approvals, financial performance, drug pipeline
            and more catalysts. Join today and start maximizing your returns in
            this exciting market.
          </p>
          <div style="display: grid" class="position-relative mb-3">
            <div class="align-items-center">
              <form class="example d-flex add_m_infilter">
                <input
                  autocomplete="off"
                  (keyup)="searchFilters($event)"
                  (input)="onSearchInput($event)"
                  [(ngModel)]="searchValuequery"
                  #searchVal
                  type="search"
                  class="input-box"
                  placeholder="Search by Comapny name or Ticker"
                  name="search2"
                />
                <button
                  (click)="companyDetails(searchValuequery)"
                  class="searchquerybtn"
                  type="button"
                >
                  <i class="fa-solid fa-magnifying-glass"></i>
                </button>
              </form>
            </div>
            @if(suggection?.length > 0){
            <div class="">
              <ul class="list-unstyled m-0">
                @for (item of suggection; track $index) {
                <li class="suggestion-item p-0 cursorpointer">
                  <div
                    (click)="companyDetails(item.symbol)"
                    class="d-flex filter-bottom justify-content-between px-2"
                  >
                    <span>
                      @if (item.logoid !== '') {
                      <img
                        width="25px"
                        [src]="basePath + item.logoid + '.svg'"
                      />
                      }@else {
                      <span
                        class="avatarcss"
                        style="
                          width: 25px !important;
                          height: 25px !important;
                          margin: 0 !important;
                        "
                        >{{ item.name | initialLetter }}</span
                      >
                      }
                    </span>
                    <p
                      class="text-start text-dark filter-font-text mb-1 border-1 me-4"
                    >
                      {{ item.name }}
                    </p>
                    <p class="text-end text-dark filter-font-text mb-1">
                      {{ item.symbol }}
                    </p>
                  </div>
                </li>
                }@empty { No Record Found }
              </ul>
            </div>
            }
          </div>
          @if (!loggedin()) {
          <button type="button" routerLink="/signup" class="btn-primary-green">
            Start Free Trial
          </button>
          }
        </div>
      </div>
      <div class="col-xl-6 col-sm-12">
        <div class="head-button">
          <h6>Our Investor's Favorite Tools</h6>
          <div class="head-button-set">
            <div class="item item2">
              <button
                type="button"
                routerLink="/fda-calendar"
                class="btn btn-warning button-set2-banner-right"
              >
                <h1>FDA Calendar</h1>
              </button>
            </div>
            <div class="item item3">
              <button
                routerLink="/catalyst-sync"
                type="button"
                class="btn btn-warning button-set2-banner-right"
              >
                <h1>CatalystSync™</h1>
              </button>
            </div>

            <div class="item item5">
              <button
                type="button"
                routerLink="/biotechearnings"
                class="btn btn-warning button-set2-banner-right"
              >
                <h1>Biotech Earnings</h1>
              </button>
            </div>
            <div class="item item4">
              <button
                routerLink="/company-screener"
                type="button"
                class="btn btn-warning button-set2-banner-right"
              >
                <h1>Company Screener</h1>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="upcoming-catalyst">
  <div class="container upcoming-catalyst-padding">
    <div class="upcoming-catalyst-title">
      <div class="title-shape">
        <h3 class="main-blue-heading m-0">Upcoming catalyst</h3>
      </div>
      <div
        (touchstart)="posIni3 = $event.changedTouches[0].pageX"
        (touchend)="move3($event.changedTouches[0].pageX)"
        class="upcoming-catalyst-box upcoming-catalyst-main-view slider-Catalyst p-0"
      >
        <ngb-carousel #Upcomingcarousel [interval]="100000">
          @for (item of Catalyst; track $index) {
          <ng-template ngbSlide>
            <div class="upcoming-catalyst-box pt-3">
              <div class="stage-size">
                <h6 class="text-nowrap">Company</h6>
                <span class="ticker-hover">
                  <a
                    href="{{ clientUrl }}/company/{{ item.ticker | uppercase }}"
                    class="link-ticker"
                  >
                    {{ item.ticker }}
                  </a>
                </span>
                <p class="mb-0">{{ item.name }}</p>
              </div>
              <div class="stage-size">
                <h6 class="text-nowrap">DRUG</h6>
                <p class="d-none">{{ $index + 1 }}</p>
                <span
                  class="color-bleck text-break"
                  style="cursor: pointer"
                  [ngbTooltip]="item.drugname.length > 20 ? item.drugname : ''"
                  container="body"
                >
                  {{ item.drugname.slice(0, 20) }}
                  @if(item.drugname.length > 20){
                  <span>...</span>
                  }
                </span>
              </div>
              <div class="stage-size">
                <h6 class="text-nowrap">PRICE</h6>
                <p class="color-bleck mb-0">{{ item.close | zerotoDash }}</p>
                <p
                  class="mb-0"
                  [style.color]="item.change_abs.toString() | riseAndFallColor"
                >
                  {{ item.change_abs | number : "1.0-2" }} ({{
                    item.change | number : "1.0-2" | qoqChg
                  }})
                </p>
              </div>
              <div class="stage-size">
                <h6 class="text-nowrap">STAGE</h6>
                <span class="color-light text-wrap">{{ item.stage }}</span>
                @if (item.stage) {
                <app-stage-progress
                  stage="{{ item.stage }}"
                ></app-stage-progress>
                }@else { - }
              </div>

              <div class="">
                <h6 class="text-nowrap">CATALYST</h6>
                <span class="text-nowrap">{{ item.catdate }}</span>
              </div>
            </div>
          </ng-template>
          }@empty { No Record Found }
        </ngb-carousel>
      </div>
      <div
        (touchstart)="posIni4 = $event.changedTouches[0].pageX"
        (touchend)="move4($event.changedTouches[0].pageX)"
        class="d-none upcoming-catalyst-mobile-view slider-Catalyst p-0"
      >
        <ngb-carousel #Upcomingmobilecarousel [interval]="100000">
          @for (item of Catalyst; track $index) {
          <ng-template ngbSlide>
            <div class="">
              <div class="row">
                <div class="col-12 upcoming-catalyst-mobile-box py-2 px-0">
                  <div class="text-center">
                    <div class="d-flex justify-content-center">
                      <p
                        (click)="catalystDetails(item.ticker)"
                        class="ticker-hover text-nowrap Heighest-symbol m-0 upcoming-line-size"
                      >
                        {{ item.ticker }}
                      </p>
                      <span
                        class="text-nowrap upcoming-line-size ms-2"
                        style="font-weight: bold; color: var(--clr-primary)"
                        >{{ item.catdate }}</span
                      >
                    </div>
                    <p
                      class="mb-0 text-nowrap upcoming-line-size"
                      [ngbTooltip]="item.name.length > 40 ? item.name : ''"
                      container="body"
                    >
                      {{ item.name.slice(0, 40) }}
                      @if (item.name.length > 40) {
                      <span class="upcoming-line-size">...</span>
                      }
                    </p>
                  </div>
                </div>

                <div class="col-12 upcoming-catalyst-mobile-box py-2 px-0">
                  <p class="d-none">{{ $index + 1 }}</p>
                  <div class="text-center">
                    <!-- <h6 class="text-nowrap text-center drug-head upcoming-line-size m-0">DRUG</h6> -->
                    <p
                      class="color-bleck text-break upcoming-line-size m-0"
                      style="cursor: pointer; font-weight: bold"
                      [ngbTooltip]="
                        item.drugname.length > 40 ? item.drugname : ''
                      "
                      container="body"
                    >
                      {{ item.drugname.slice(0, 40) }}
                      <!-- <span *ngIf="item.drugname.length > 40">...</span> -->
                      @if(item.drugname.length > 40){
                      <span>...</span>
                      }
                    </p>
                    <span
                      class="upcoming-line-size text-center mx-2 color-light text-wrap"
                      >{{ item.stage }}</span
                    >
                    <div class="w-50 mx-auto">
                      @if (item.stage) {
                      <app-stage-progress
                        stage="{{ item.stage }}"
                      ></app-stage-progress>
                      }@else { - }
                    </div>
                  </div>
                </div>

                <div class="col-12 py-2 px-0 pb-0">
                  <div class="d-flex justify-content-center">
                    <p
                      class="color-bleck mb-0 upcoming-line-size"
                      style="font-weight: bold"
                    >
                      {{ item.close | zerotoDash }}
                    </p>
                    <p
                      class="mb-0 ms-2 upcoming-line-size"
                      [style.color]="
                        item.change_abs.toString() | riseAndFallColor
                      "
                    >
                      {{ item.change_abs | number : "1.0-2" }} ({{
                        item.change | number : "1.0-2" | qoqChg
                      }})
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          }@empty { No Record Found }
        </ngb-carousel>
      </div>
    </div>
  </div>
</section>

<!-- Testimonials -->
<div class="container">
  <div class="title-shape MostVolatiles-space mt-5">
    <h3 class="main-blue-heading m-0">
      Trusted by Industry Leaders: Providing top-tier bio/pharma equity
      research.
    </h3>
  </div>
  <div class="d-flex hide-marque-testi justify-content-center mt-3">
    <img
      src="assets/images/gilead_Testimonials .png"
      class="mx-2"
      style="aspect-ratio: 3/2; object-fit: contain"
      width="150px"
      alt="Testimonials"
    />
    <img
      src="assets/images/iqvia_Testimonials.png"
      class="mx-2"
      style="aspect-ratio: 3/2; object-fit: contain"
      width="150px"
      alt="Testimonials"
    />
    <img
      src="assets/images/ladenburg_Testimonials.png"
      class="mx-2"
      style="aspect-ratio: 3/2; object-fit: contain"
      width="150px"
      alt="Testimonials"
    />
    <img
      src="assets/images/sanofi_Testimonials.png"
      class="mx-2"
      style="aspect-ratio: 3/2; object-fit: contain"
      width="150px"
      alt="Testimonials"
    />
    <img
      src="assets/images/mirae_Testimonials.png"
      class="mx-2"
      style="aspect-ratio: 3/2; object-fit: contain"
      width="150px"
      alt="Testimonials"
    />
    <img
      src="assets/images/bristol_Testimonials.png"
      class="mx-2"
      style="aspect-ratio: 3/2; object-fit: contain"
      width="150px"
      alt="Testimonials"
    />
    <img
      src="assets/images/schroders_Testimonials.png"
      class="mx-2"
      style="aspect-ratio: 3/2; object-fit: contain"
      width="150px"
      alt="Testimonials"
    />
  </div>
  <marquee
    class="show-marque-testi d-none"
    behavior="alternate"
    scrollamount="3"
    direction="left"
  >
    <div class="d-flex justify-unset-mb justify-content-center">
      <img
        src="assets/images/gilead_Testimonials .png"
        class="mx-2"
        style="aspect-ratio: 3/2; object-fit: contain"
        width="150px"
        alt="Testimonials"
      />
      <img
        src="assets/images/iqvia_Testimonials.png"
        class="mx-2"
        style="aspect-ratio: 3/2; object-fit: contain"
        width="150px"
        alt="Testimonials"
      />
      <img
        src="assets/images/ladenburg_Testimonials.png"
        class="mx-2"
        style="aspect-ratio: 3/2; object-fit: contain"
        width="150px"
        alt="Testimonials"
      />
      <img
        src="assets/images/sanofi_Testimonials.png"
        class="mx-2"
        style="aspect-ratio: 3/2; object-fit: contain"
        width="150px"
        alt="Testimonials"
      />
      <img
        src="assets/images/mirae_Testimonials.png"
        class="mx-2"
        style="aspect-ratio: 3/2; object-fit: contain"
        width="150px"
        alt="Testimonials"
      />
      <img
        src="assets/images/bristol_Testimonials.png"
        class="mx-2"
        style="aspect-ratio: 3/2; object-fit: contain"
        width="150px"
        alt="Testimonials"
      />
      <img
        src="assets/images/schroders_Testimonials.png"
        class="mx-2"
        style="aspect-ratio: 3/2; object-fit: contain"
        width="150px"
        alt="Testimonials"
      />
    </div>
  </marquee>
</div>

<!-- Today’s most Volatile LEPTOP SECTION -->
<div>
  <div class="title-shape MostVolatiles-space mt-5">
    <h3 #MostVolatiles class="main-blue-heading m-0">Today's Most Volatile</h3>
  </div>
  <section class="volatile mt-3 leptop-section">
    <div class="container volatile-container">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="d-responsive">
              <div class="col">
                <div class="">
                  <h4 class="m-0 stock-gainer ms-3">
                    Stock gainers
                    <i
                      class="fa-solid fa-angle-right"
                      style="color: #089981"
                    ></i>
                  </h4>
                </div>
                @for (item of plusData; track $index) {
                <div class="volatile-content">
                  <ul class="m-0">
                    <li
                      class="volatile-data-hover"
                      (click)="plusDetails(item.symbol)"
                    >
                      @if (item.logoid !== '') {
                      <img
                        width="40px"
                        [src]="basePath + item.logoid + '.svg'"
                        class="mb-0 company-hover"
                      />
                      }@else {
                      <span class="avatarcss mb-0">{{
                        item.name | initialLetter
                      }}</span>
                      }
                      <h6 class="mt-not-0 name-text-lep">
                        {{ item.name }} <br />
                        <!-- <span class="most-value-btn"> -->
                        <a
                          href="{{ clientUrl }}/company/{{ item.symbol }}"
                          class="link-ticker most-value-btn"
                        >
                          {{ item.symbol }}
                        </a>
                        <!-- </span> -->
                      </h6>

                      <p class="d-none">{{ $index + 1 }}</p>
                      <a
                        class="btn-green close_price_width-lep text-center text-nowrap"
                        title=""
                        >{{ item.close.toString() | number : "1.0-2" }} $
                      </a>
                      <P class="change-plus-box"
                        >{{ item.change | number : "1.0-2" }} %
                      </P>
                    </li>
                  </ul>
                </div>
                }@empty {
                <ngx-skeleton-loader
                  [theme]="{ height: '50px', width: '400px', 'border-radius': '4px', display: 'flex',  }"
                  count="7"
                >
                </ngx-skeleton-loader>
                }
              </div>
              <div class="col minusdata">
                <div class="">
                  <h4 class="m-0 stock-losers ms-3">
                    Stock losers
                    <i
                      class="fa-solid fa-angle-right"
                      style="color: #f23645"
                    ></i>
                  </h4>
                </div>
                @for (item of minusData; track $index) {
                <div class="volatile-content">
                  <ul class="ps-0 m-0">
                    <li
                      class="volatile-data-hover"
                      (click)="minusDetails(item.symbol)"
                    >
                      @if (item.logoid !== '') {
                      <img
                        width="40px"
                        [src]="basePath + item.logoid + '.svg'"
                        class="mb-0 company-hover"
                      />
                      }@else {
                      <span class="avatarcss mb-0">{{
                        item.name | initialLetter
                      }}</span>
                      }
                      <h6 class="mt-not-0 name-text-lep">
                        {{ item.name }} <br />
                        <a
                          href="{{ clientUrl }}/company/{{ item.symbol }}"
                          class="link-ticker most-value-btn"
                        >
                          {{ item.symbol }}
                        </a>
                      </h6>
                      <p class="d-none">{{ $index + 1 }}</p>
                      <a
                        title=""
                        class="button-red close_price_width-lep text-center text-nowrap"
                        >{{ item.close | number : "1.0-2" }} $
                      </a>
                      <P class="change-minus-box"
                        >{{ item.change | number : "1.0-2" }} %
                      </P>
                    </li>
                  </ul>
                </div>
                }@empty {
                <ngx-skeleton-loader
                  [theme]="{ height: '50px', width: '400px', 'border-radius': '4px', display: 'flex',  }"
                  count="7"
                >
                </ngx-skeleton-loader>
                }
              </div>
            </div>
            <div class="text-center viewmore">
              <button
                (click)="changeImage()"
                type="button"
                class="btn btn-light hoverbtn w-auto"
                (mouseover)="isHovered = true"
                (mouseleave)="isHovered = false"
              >
                <span>View More</span>
                <img
                  src="assets/images/whitearrow.svg"
                  alt=""
                  class="ms-2 arrowsize"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="mt-3 mobile-section d-none">
    <div class="d-flex justify-content-evenly mb-4">
      <h3
        #gainerTab
        class="p-2 text-center gainer-loser-btn gainer-text mb-0 mt-2"
        (click)="selectTab(0)"
        [ngClass]="activeGainerTab"
      >
        Gainer
      </h3>
      <h3
        #loserTab
        class="p-2 text-center gainer-loser-btn loosing-text mb-0 mt-2"
        (click)="selectTab(1)"
        [ngClass]="activebtntab"
      >
        Losers
      </h3>
    </div>

    <div
      (touchstart)="posIni2 = $event.changedTouches[0].pageX"
      (touchend)="move2($event.changedTouches[0].pageX)"
    >
      <ngb-carousel
        #Gailosecarousel
        [activeId]="activeSlide"
        [interval]="20000"
        [pauseOnHover]="true"
        #NgbCarousel
        (slide)="onSlideChange($event)"
      >
        <ng-template ngbSlide id="0">
          <div class="col">
            @for (plus of plusData; track $index) {
            <div class="volatile-content">
              <ul class="mb-2 mx-3">
                <li class="volatile-data-hover d-flex align-items-center">
                  <div style="width: 15%" class="d-grid d-grid-mobile">
                    @if (plus.logoid !== '') {
                    <img
                      width="40px"
                      [src]="basePath + plus.logoid + '.svg'"
                      class="mb-0 company-hover"
                      (click)="plusDetails(plus.symbol)"
                    />
                    }@else {
                    <span class="avatarcss mb-0">{{
                      plus.name | initialLetter
                    }}</span>
                    }
                  </div>
                  <div style="width: 60%">
                    <p
                      style="width: fit-content; line-height: 18px"
                      class="most-value-btn m-0"
                    >
                      <a
                        class="text-dark"
                        style="text-decoration: none"
                        href="{{ clientUrl }}/company/{{ plus.symbol }}"
                        >{{ plus.symbol }}</a
                      >
                    </p>
                    <h6
                      class="mt-not-0 m-0 name-text text-truncate d-flex justify-content-start align-items-center"
                    >
                      {{ plus.name }}
                    </h6>
                  </div>
                  <div style="width: 25%">
                    <div class="text-center">
                      <a
                        style="text-decoration: none; font-size: 13px"
                        class="btn-green text-nowrap"
                        title=""
                        >{{ plus.close | number : "1.0-2" | zerotoDash }}
                      </a>
                    </div>
                    <div class="d-flex justify-content-center">
                      <P
                        style="width: 80%; font-size: 13px"
                        class="mt-1 change-mobile-plus-box"
                        >{{ plus.change | number : "1.0-2" | qoqChg }}</P
                      >
                    </div>
                  </div>
                  <p class="d-none">{{ $index + 1 }}</p>
                </li>
              </ul>
            </div>
            }@empty {
            <ngx-skeleton-loader
              [theme]="{ height: '50px', width: '400px', 'border-radius': '4px', display: 'flex',  }"
              count="7"
            >
            </ngx-skeleton-loader>
            }
          </div>
        </ng-template>
        <ng-template ngbSlide id="1">
          <div class="col minusdata">
            @for (minus of minusData; track $index) {
            <div class="volatile-content">
              <ul class="mb-2 mx-3">
                <li class="volatile-data-hover d-flex align-items-center">
                  <div style="width: 15%" class="d-grid d-grid-mobile">
                    @if (minus.logoid !== '') {
                    <img
                      width="40px"
                      [src]="basePath + minus.logoid + '.svg'"
                      class="mb-0 company-hover"
                      (click)="plusDetails(minus.symbol)"
                    />
                    }@else {
                    <span class="avatarcss mb-0">{{
                      minus.name | initialLetter
                    }}</span>
                    }
                  </div>
                  <div style="width: 60%">
                    <p
                      style="width: fit-content; line-height: 18px"
                      class="most-value-btn mb-0"
                    >
                      <a
                        class="text-dark"
                        style="text-decoration: none"
                        href="{{ clientUrl }}/company/{{ minus.symbol }}"
                        >{{ minus.symbol }}</a
                      >
                    </p>
                    <h6
                      class="mt-not-0 mb-0 name-text text-truncate d-flex justify-content-start align-items-center"
                    >
                      {{ minus.name }}
                    </h6>
                  </div>
                  <div style="width: 25%">
                    <div class="text-center">
                      <a
                        style="text-decoration: none; font-size: 13px"
                        class="button-red text-nowrap"
                        title=""
                        >{{ minus.close | number : "1.0-2" | zerotoDash }}
                      </a>
                    </div>
                    <div class="d-flex justify-content-center">
                      <P
                        style="width: 80%; font-size: 13px"
                        class="mt-1 change-minus-box"
                        >{{ minus.change | number : "1.0-2" | qoqChg }}
                      </P>
                    </div>
                  </div>

                  <p class="d-none">{{ $index + 1 }}</p>
                </li>
              </ul>
            </div>
            }@empty {
            <ngx-skeleton-loader
              [theme]="{ height: '50px', width: '400px', 'border-radius': '4px', display: 'flex',  }"
              count="7"
            >
            </ngx-skeleton-loader>
            }
          </div>
        </ng-template>
      </ngb-carousel>
    </div>

    <div class="text-center viewmore mt-3">
      <button
        (click)="changeImage()"
        type="button"
        class="btn btn-light hoverbtn w-auto"
      >
        <span>View More</span>
        <img src="assets/images/whitearrow.svg" alt="" class="ms-2 arrowsize" />
      </button>
    </div>
  </section>
</div>

<app-loginpop-up
  [displayStyle]="modalDisplayStyle"
  (myout)="GetChildData($event)"
></app-loginpop-up>

<!-- Pre market volatile LEPTOP SECTION -->
<div>
  <div class="title-shape MostVolatiles-space mt-5">
    <h3 #PreMarket class="main-blue-heading m-0">Today's Pre Market</h3>
  </div>
  <section class="volatile mt-3 leptop-section">
    <div class="container volatile-container">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="d-responsive">
              <div class="col">
                <div class="">
                  <h4 class="m-0 stock-gainer ms-3">
                    Pre Market Gainers
                    <i
                      class="fa-solid fa-angle-right"
                      style="color: #089981"
                    ></i>
                  </h4>
                </div>
                @for (item of prePlusData; track $index) {
                <div class="volatile-content">
                  <ul class="m-0">
                    <li
                      class="volatile-data-hover"
                      (click)="plusDetails(item.symbol)"
                    >
                      @if (item.logoid !== '') {
                      <img
                        width="40px"
                        [src]="basePath + item.logoid + '.svg'"
                        class="mb-0 company-hover"
                      />
                      }@else {
                      <span class="avatarcss mb-0">{{
                        item.name | initialLetter
                      }}</span>
                      }
                      <h6 class="mt-not-0 name-text-lep">
                        {{ item.name }} <br />
                        <!-- <span class="most-value-btn"> -->
                        <a
                          href="{{ clientUrl }}/company/{{ item.symbol }}"
                          class="link-ticker most-value-btn"
                        >
                          {{ item.symbol }}
                        </a>
                        <!-- </span> -->
                      </h6>

                      <p class="d-none">{{ $index + 1 }}</p>
                      <a
                        class="btn-green close_price_width-lep text-center text-nowrap"
                        title=""
                        >{{ item.previous_close_price | number : "1.0-2" }} $
                      </a>
                      <P class="change-plus-box"
                        >{{ item.previous_change | number : "1.0-2" }} %
                      </P>
                    </li>
                  </ul>
                </div>
                }@empty {
                <ngx-skeleton-loader
                  [theme]="{ height: '50px', width: '400px', 'border-radius': '4px', display: 'flex',  }"
                  count="7"
                >
                </ngx-skeleton-loader>
                }
              </div>
              <div class="col minusdata">
                <div class="">
                  <h4 class="m-0 stock-losers ms-3">
                    Pre Market losers
                    <i
                      class="fa-solid fa-angle-right"
                      style="color: #f23645"
                    ></i>
                  </h4>
                </div>
                @for (item of preMinusData; track $index) {
                <div class="volatile-content">
                  <ul class="ps-0 m-0">
                    <li
                      class="volatile-data-hover"
                      (click)="minusDetails(item.symbol)"
                    >
                      @if (item.logoid !== '') {
                      <img
                        width="40px"
                        [src]="basePath + item.logoid + '.svg'"
                        class="mb-0 company-hover"
                      />
                      }@else {
                      <span class="avatarcss mb-0">{{
                        item.name | initialLetter
                      }}</span>
                      }
                      <h6 class="mt-not-0 name-text-lep">
                        {{ item.name }} <br />
                        <a
                          href="{{ clientUrl }}/company/{{ item.symbol }}"
                          class="link-ticker most-value-btn"
                        >
                          {{ item.symbol }}
                        </a>
                      </h6>
                      <p class="d-none">{{ $index + 1 }}</p>
                      <a
                        title=""
                        class="button-red close_price_width-lep text-center text-nowrap"
                        >{{ item.previous_close_price | number : "1.0-2" }} $
                      </a>
                      <P class="change-minus-box"
                        >{{ item.previous_change | number : "1.0-2" }} %
                      </P>
                    </li>
                  </ul>
                </div>
                }@empty {
                <ngx-skeleton-loader
                  [theme]="{ height: '50px', width: '400px', 'border-radius': '4px', display: 'flex',  }"
                  count="7"
                >
                </ngx-skeleton-loader>
                }
              </div>
            </div>
            <!-- <div class="text-center viewmore">
              <button (click)="changeImage()" type="button" class="btn btn-light hoverbtn w-auto"
                (mouseover)="isHovered = true" (mouseleave)="isHovered = false">
                <span>View More</span>
                <img src="assets/images/whitearrow.svg" alt="" class="ms-2 arrowsize" />
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="mt-3 mobile-section d-none">
    <div class="d-flex justify-content-evenly mb-4">
      <h3
        #preMarketGainerTab
        class="p-2 text-center gainer-loser-btn gainer-text mb-0 mt-2"
        (click)="selectPreMarketTab(0)"
        [ngClass]="activepreMarketGainerTab"
      >
        Gainer
      </h3>
      <h3
        #preMarketLoserTab
        class="p-2 text-center gainer-loser-btn loosing-text mb-0 mt-2"
        (click)="selectPreMarketTab(1)"
        [ngClass]="activepreMarketLoserTab"
      >
        Losers
      </h3>
    </div>

    <div
      (touchstart)="posIniPM = $event.changedTouches[0].pageX"
      (touchend)="movePreMarket($event.changedTouches[0].pageX)"
    >
      <ngb-carousel
        #PreMarketCarousel
        [activeId]="activePreMarketSlide"
        [interval]="20000"
        [pauseOnHover]="true"
        #PreMarketNgbCarousel
        (slide)="onPreMarketSlideChange($event)"
      >
        <ng-template ngbSlide id="0">
          <div class="col">
            @for (plus of prePlusData; track $index) {
            <div class="volatile-content">
              <ul class="mb-2 mx-3">
                <li class="volatile-data-hover d-flex align-items-center">
                  <div style="width: 15%" class="d-grid d-grid-mobile">
                    @if (plus.logoid !== '') {
                    <img
                      width="40px"
                      [src]="basePath + plus.logoid + '.svg'"
                      class="mb-0 company-hover"
                      (click)="plusDetails(plus.symbol)"
                    />
                    }@else {
                    <span class="avatarcss mb-0">{{
                      plus.name | initialLetter
                    }}</span>
                    }
                  </div>
                  <div style="width: 60%">
                    <p
                      style="width: fit-content; line-height: 18px"
                      class="most-value-btn m-0"
                    >
                      <a
                        class="text-dark"
                        style="text-decoration: none"
                        href="{{ clientUrl }}/company/{{ plus.symbol }}"
                        >{{ plus.symbol }}</a
                      >
                    </p>
                    <h6
                      class="mt-not-0 m-0 name-text text-truncate d-flex justify-content-start align-items-center"
                    >
                      {{ plus.name }}
                    </h6>
                  </div>
                  <div style="width: 25%">
                    <div class="text-center">
                      <a
                        style="text-decoration: none; font-size: 13px"
                        class="btn-green text-nowrap"
                        title=""
                        >{{
                          plus.previous_close_price
                            | number : "1.0-2"
                            | zerotoDash
                        }}
                      </a>
                    </div>
                    <div class="d-flex justify-content-center">
                      <P
                        style="width: 80%; font-size: 13px"
                        class="mt-1 change-mobile-plus-box"
                        >{{
                          plus.previous_change | number : "1.0-2" | qoqChg
                        }}</P
                      >
                    </div>
                  </div>
                  <p class="d-none">{{ $index + 1 }}</p>
                </li>
              </ul>
            </div>
            }@empty { No Record Found }
          </div>
        </ng-template>
        <ng-template ngbSlide id="1">
          <div class="col minusdata">
            @for (minus of preMinusData; track $index) {
            <div class="volatile-content">
              <ul class="mb-2 mx-3">
                <li class="volatile-data-hover d-flex align-items-center">
                  <div style="width: 15%" class="d-grid d-grid-mobile">
                    @if (minus.logoid !== '') {
                    <img
                      width="40px"
                      [src]="basePath + minus.logoid + '.svg'"
                      class="mb-0 company-hover"
                      (click)="plusDetails(minus.symbol)"
                    />
                    }@else {
                    <span class="avatarcss mb-0">{{
                      minus.name | initialLetter
                    }}</span>
                    }
                  </div>
                  <div style="width: 60%">
                    <p
                      style="width: fit-content; line-height: 18px"
                      class="most-value-btn mb-0"
                    >
                      <a
                        class="text-dark"
                        style="text-decoration: none"
                        href="{{ clientUrl }}/company/{{ minus.symbol }}"
                        >{{ minus.symbol }}</a
                      >
                    </p>
                    <h6
                      class="mt-not-0 mb-0 name-text text-truncate d-flex justify-content-start align-items-center"
                    >
                      {{ minus.name }}
                    </h6>
                  </div>
                  <div style="width: 25%">
                    <div class="text-center">
                      <a
                        style="text-decoration: none; font-size: 13px"
                        class="button-red text-nowrap"
                        title=""
                        >{{
                          minus.previous_close_price
                            | number : "1.0-2"
                            | zerotoDash
                        }}
                      </a>
                    </div>
                    <div class="d-flex justify-content-center">
                      <P
                        style="width: 80%; font-size: 13px"
                        class="mt-1 change-minus-box"
                        >{{ minus.previous_change | number : "1.0-2" | qoqChg }}
                      </P>
                    </div>
                  </div>

                  <p class="d-none">{{ $index + 1 }}</p>
                </li>
              </ul>
            </div>
            }@empty { No Record Found }
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
  </section>
</div>

<section class="volatile mt-5">
  <div class="container volatile-container">
    <div class="row">
      <div class="col-12">
        <div class="title-shape">
          <h3 #UpcomingEarningss class="main-blue-heading">
            Upcoming Earnings
          </h3>
        </div>
        <div
          (touchstart)="posIni = $event.changedTouches[0].pageX"
          (touchend)="move($event.changedTouches[0].pageX)"
          class="container-fluid slider-earning"
        >
          <ngb-carousel
            #carousel
            [showNavigationArrows]="true"
            [showNavigationIndicators]="true"
            [interval]="0"
          >
            @for (slide of slides; track $index) {
            <ng-template ngbSlide>
              <div class="d-flex col-12 justify-content-center mt-2">
                @for (plus of slide; track $index) {
                <div class="d-flex justify-content-center m-1">
                  <div
                    class="shadow box-hover p-3 pt-4 pb-4 m-3"
                    style="border-radius: 10px"
                  >
                    <ul class="p-0 m-0">
                      <li
                        style="display: unset"
                        class="volatile-data-hover"
                        (click)="EarningsDetails(plus.symbol)"
                      >
                        <div class="calendar mx-auto">
                          <div class="calendar-year">
                            <h6 class="text-light text-center">
                              {{ plus.date | dateformate | slice : 11 : 15 }}
                            </h6>
                          </div>
                          <div>
                            <h6 class="fw-bold upcoming-date text-center mt-2">
                              {{ plus.date | dateformate | slice : 8 : 10 }}
                            </h6>
                            <h6 class="text-center my-1">
                              {{ plus.date | dateformate | slice : 4 : 7 }}
                            </h6>
                          </div>
                        </div>
                        <h6
                          style="text-align: center !important"
                          class="mt-4 name-text-lep-upcoming-earnings"
                        >
                          {{ plus.title }} <br />
                        </h6>
                        <div class="d-flex justify-content-center">
                          <p class="text-center Heighest-symbol m-0">
                            <a
                              href="{{ clientUrl }}/company/{{
                                plus.symbol | uppercase
                              }}"
                              class="link-ticker"
                              style="color: #fff; font-size: 13px"
                            >
                              {{ plus.symbol }}
                            </a>
                          </p>
                        </div>
                        <!-- <p class="d-none">{{ ip + 1 }}</p> -->
                        <div class="m-0 mt-4 eps-data text-center">
                          @if(plus.earnings_per_share_basic_ttm){
                          <a
                            [style.color]="
                              plus.earnings_per_share_basic_ttm.toString()
                                | riseAndFallColor
                            "
                            class="close_price_width-lep-for-upcoming-ernings bg-white p-0 mt-2 text-nowrap"
                            >{{
                              plus.earnings_per_share_basic_ttm
                                | number : "1.0-2"
                                | zerotoDash
                            }}
                            @if (plus.earnings_per_share_basic_ttm !== 0) {
                            <span class="EPS">EPS</span>
                            }
                          </a>
                          }@else { - }
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                }@empty { No Record Found }
              </div>
            </ng-template>
            }@empty { No Record Found }
          </ngb-carousel>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="container volatile mt-5">
  <div class="row">
    <div class="col-md-5 px-2">
      <div class="title-shape most-Unusual-table">
        <h3 #highestVolumes class="main-blue-heading">Highest Volume</h3>
      </div>
      <div class="Short-table all-table-scroll">
        <table class="table table-fixed table-lock-height mb-0">
          <thead class="sticky-header">
            <tr class="text-uppercase table-header">
              <th class="px-2 text-center py-2">Company</th>
              <th class="px-2 text-center py-2">Volume</th>
              <th class="px-2 text-center py-2">Price</th>
            </tr>
          </thead>
          <tbody>
            @for (data of heighestVolArry; track $index) {
            <tr>
              <td class="table-img-td">
                <div
                  class="text-center company-hover"
                  (click)="heighrstDetails(data.symbol)"
                >
                  <div class="d-grid">
                    <div class="d-flex align-items-center company-ticker-wrap">
                      @if (data.logoid !== '') {
                      <img
                        class="p-1 company-img-space me-1"
                        style="width: 30px"
                        [src]="basePath + data.logoid + '.svg'"
                      />
                      }@else {
                      <div class="not-login-symbol p-1 me-1">
                        <p class="company-img-space mb-0">
                          {{ data.name | initialLetter }}
                        </p>
                      </div>
                      }
                      <div class="">
                        <p class="m-0 text-start Heighest-symbol text-data">
                          <a
                            href="{{ clientUrl }}/company/{{
                              data.symbol | uppercase
                            }}"
                            class="link-ticker"
                            style="color: #fff; font-size: 13px"
                          >
                            {{ data.symbol }}
                          </a>
                        </p>
                        <!-- <p class="text-start text-data table-font-size m-0">{{ data.name }}</p> -->
                      </div>
                    </div>
                    <div class="company-name-hide">
                      <p class="text-start text-data table-font-size m-0">
                        {{ data.name }}
                      </p>
                    </div>

                    <!-- <div class="">
                        <p class="m-0 text-start Heighest-symbol text-data">{{ data.symbol }}</p>
                        <p class="text-start text-data table-font-size m-0">{{ data.name }}</p>
                      </div> -->
                  </div>
                </div>
              </td>
              <td class="">
                <p class="m-0 text-nowrap text-center text-data">
                  {{ data.volume | dollarsCoversion }}
                </p>
              </td>
              <td class="">
                <p
                  [style.color]="data.close.toString() | riseAndFallColor"
                  class="m-0 text-nowrap text-center text-data"
                >
                  {{ data.close | number : "1.0-2" }} $
                </p>
              </td>
            </tr>
            } @empty {
            <tr style="box-shadow: none">
              <td colspan="17">
                <ngx-skeleton-loader
                  [theme]="{ height: '50px',  'border-radius': '4px',  }"
                  count="7"
                >
                </ngx-skeleton-loader>
              </td>
            </tr>
            }
          </tbody>
        </table>
      </div>
    </div>

    <div class="col-md-7 px-2">
      <div class="title-shape most-Unusual-table">
        <h3 #ShortInterest class="main-blue-heading Option-Stock-table">
          Short Interest
        </h3>
      </div>
      <div class="Short-table all-table-scroll">
        <table class="table table-fixed table-lock-height mb-0">
          <thead class="sticky-header">
            <tr class="text-uppercase table-header">
              <th class="px-2 text-center py-2">Company</th>
              <th
                class="px-2 py-2 text-nowrap text-center"
                placement="bottom"
                container="body"
                ngbTooltip="Short Percent Of Float"
              >
                Short % Float
              </th>
              <th
                class="px-2 py-2 text-nowrap"
                placement="bottom"
                container="body"
                ngbTooltip="Float Shares"
              >
                Float Shares
              </th>
              <th
                class="px-2 py-2 text-nowrap"
                placement="bottom"
                container="body"
                ngbTooltip="Total Shares"
              >
                Total Shares
              </th>
              <th class="px-2 py-2 text-center">Volume</th>
            </tr>
          </thead>
          <tbody>
            @for (data of shortinterest; track $index) {
            <tr>
              <td style="cursor: pointer">
                <div
                  class="text-center company-hover"
                  (click)="heighrstDetails(data.symbol)"
                >
                  <div class="d-grid">
                    <div class="d-flex align-items-center company-ticker-wrap">
                      @if (data.logoid !== '') {
                      <img
                        class="p-1 company-img-space me-1"
                        style="width: 30px"
                        [src]="basePath + data.logoid + '.svg'"
                      />
                      }@else {
                      <div class="not-login-symbol p-1 me-3">
                        <p class="company-img-space mb-0">
                          {{ data.name | initialLetter }}
                        </p>
                      </div>
                      }
                      <div class="">
                        <p class="m-0 text-start Heighest-symbol text-data">
                          <a
                            href="{{ clientUrl }}/company/{{
                              data.symbol | uppercase
                            }}"
                            class="link-ticker"
                            style="color: #fff; font-size: 13px"
                          >
                            {{ data.symbol }}
                          </a>
                        </p>
                        <!-- <p class="text-start text-data table-font-size m-0">{{ data.name }}</p> -->
                      </div>
                    </div>
                    <div class="company-name-hide">
                      <p class="text-start text-data table-font-size m-0">
                        {{ data.name }}
                      </p>
                    </div>
                  </div>
                </div>
              </td>
              <td class="">
                <p class="m-0 text-data text-nowrap text-center">
                  {{
                    data.Short_percent_of_Float * 100
                      | number : "1.0-2"
                      | qoqChg
                  }}
                </p>
              </td>
              <td class="">
                <p class="m-0 text-data text-nowrap text-center">
                  {{ data.Float_Shares | dollarsCoversion }}
                </p>
              </td>
              <td class="">
                <p class="m-0 text-data text-nowrap text-center">
                  {{ data.Total_Shares | dollarsCoversion }}
                </p>
              </td>
              <td class="">
                <p class="m-0 text-data text-nowrap text-center">
                  {{ data.volume | dollarsCoversion }}
                </p>
              </td>
            </tr>
            }@empty {
            <tr style="box-shadow: none">
              <td colspan="17">
                <ngx-skeleton-loader
                  [theme]="{ height: '50px',  'border-radius': '4px',  }"
                  count="7"
                >
                </ngx-skeleton-loader>
              </td>
            </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>

<section class="container table-space volatile mt-5">
  <div class="row">
    <div class="col-md-5 px-2">
      <div class="title-shape most-Unusual-table">
        <h3 #activeStockOptionss class="main-blue-heading Option-Stock-table">
          Most Active Stock Options
        </h3>
      </div>
      <div class="Short-table all-table-scroll">
        <table class="table table-fixed mb-0">
          <thead class="sticky-header">
            <tr class="text-uppercase table-header">
              <th class="text-center">Company</th>
              <th class="text-center">Volume</th>
            </tr>
          </thead>
          <tbody>
            @for (data of activeOptionsArry; track $index) {
            <tr>
              <td class="table-img-td" style="cursor: pointer">
                <div
                  class="text-center company-hover"
                  (click)="heighrstDetails(data._id)"
                >
                  <div class="d-grid">
                    <div class="d-flex align-items-center company-ticker-wrap">
                      @if (data.logoId !== '') {
                      <img
                        class="p-1 company-img-space me-1"
                        style="width: 30px"
                        [src]="basePath + data.logoId + '.svg'"
                      />
                      }@else {
                      <div class="not-login-symbol p-1 me-3">
                        <p class="company-img-space mb-0">
                          {{ data.name | initialLetter }}
                        </p>
                      </div>
                      }
                      <div class="">
                        <p class="m-0 text-start Heighest-symbol text-data">
                          <a
                            href="{{ clientUrl }}/company/{{
                              data._id | uppercase
                            }}"
                            class="link-ticker"
                            style="color: #fff; font-size: 13px"
                          >
                            {{ data._id }}
                          </a>
                        </p>
                        <!-- <p class="text-start text-data table-font-size m-0">{{ data.name }}</p> -->
                      </div>
                    </div>
                    <div class="company-name-hide">
                      <p class="text-start text-data table-font-size m-0">
                        {{ data.name }}
                      </p>
                    </div>

                    <!-- <div class="">
                        <p class="m-0 text-start Heighest-symbol text-data">{{ data.symbol }}</p>
                        <p class="text-start text-data table-font-size m-0">{{ data.name }}</p>
                      </div> -->
                  </div>
                </div>
              </td>
              <td>
                <p class="text-data text-center m-0">
                  {{ data.volume | dollarsCoversion }}
                </p>
              </td>
            </tr>
            }@empty {
            <tr style="box-shadow: none">
              <td colspan="17">
                <ngx-skeleton-loader
                  [theme]="{ height: '50px',  'border-radius': '4px',  }"
                  count="7"
                >
                </ngx-skeleton-loader>
              </td>
            </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-7 px-2">
      <div class="title-shape most-Unusual-table">
        <h3 #UnusualActivitys class="main-blue-heading Option-Stock-table">
          Unusual Option Activity
        </h3>
      </div>
      <div class="Short-table all-table-scroll">
        <table class="table table-fixed table-lock-height mb-0">
          <thead class="sticky-header">
            <tr class="text-uppercase table-header">
              <th class="px-2 text-center py-2">Company</th>
              <th class="px-2 text-center py-2">Type</th>
              <th class="px-2 text-center py-2">Expiration</th>
              <th
                class="px-2 text-center py-2"
                placement="bottom"
                container="body"
                ngbTooltip="Volume/Open Interest"
              >
                V/OI
              </th>
              <th class="px-2 text-center py-2">Volume</th>
              <th
                class="px-2 py-2"
                placement="bottom"
                container="body"
                ngbTooltip="Open Interest"
              >
                OI
              </th>
            </tr>
          </thead>
          <tbody>
            @for (data of UnusualOptionActivity; track $index) {
            <tr>
              <td style="cursor: pointer">
                <div
                  class="text-center company-hover"
                  (click)="heighrstDetails(data.contractSymbol)"
                >
                  <div class="d-grid">
                    <div class="d-flex align-items-center company-ticker-wrap">
                      @if (data.logoid !== '') {
                      <img
                        class="p-1 company-img-space me-1"
                        style="width: 30px"
                        [src]="basePath + data.logoid + '.svg'"
                      />
                      }@else {
                      <div class="not-login-symbol p-1 me-3">
                        <p class="company-img-space mb-0">
                          {{ data.name | initialLetter }}
                        </p>
                      </div>
                      }
                      <div class="">
                        <p class="m-0 text-start Heighest-symbol text-data">
                          <a
                            href="{{ clientUrl }}/company/{{
                              data.contractSymbol | uppercase
                            }}"
                            class="link-ticker"
                            style="color: #fff; font-size: 13px"
                          >
                            {{ data.contractSymbol }}
                          </a>
                        </p>
                        <!-- <p class="text-start text-data table-font-size m-0">{{ data.name }}</p> -->
                      </div>
                    </div>
                    <div class="company-name-hide">
                      <p class="text-start text-data table-font-size m-0">
                        {{ data.name }}
                      </p>
                    </div>

                    <!-- <div class="">
                        <p class="m-0 text-start Heighest-symbol text-data">{{ data.symbol }}</p>
                        <p class="text-start text-data table-font-size m-0">{{ data.name }}</p>
                      </div> -->
                  </div>
                </div>
              </td>
              <td class="">
                <p class="m-0 text-center text-data">{{ data.type }}</p>
              </td>
              <td class="">
                <p class="m-0 text-center text-data">
                  {{ data.expiration_date }}
                </p>
              </td>
              <td class="">
                <p class="m-0 text-center text-data">
                  {{ data.volumeOpenInterestRatio | number : "1.0-2" }}
                </p>
              </td>
              <td class="">
                <p class="m-0 text-center text-data">{{ data.totalVolume }}</p>
              </td>
              <td class="">
                <p class="m-0 text-center text-data">
                  {{ data.totalOpenInterest }}
                </p>
              </td>
            </tr>
            }@empty {
            <tr style="box-shadow: none">
              <td colspan="17">
                <ngx-skeleton-loader
                  [theme]="{ height: '50px',  'border-radius': '4px',  }"
                  count="7"
                >
                </ngx-skeleton-loader>
              </td>
            </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>

<!-- //your key to sucess invt -->
<section class="tools volatile mt-5">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="title-shape">
          <h3 class="main-blue-heading">Your key to successful investing</h3>
          <p class="sub-blue-heading">
            Our comprehensive screening and analysis tools
          </p>
        </div>
        <div class="row justify-content-center">
          <div
            class="col-6 col-md-4 col-lg-4 col-sm-2 cardlist"
            routerLink="/catalyst-sync"
          >
            <span class="d-flex justify-content-center">
              <img
                src="assets/images/icon-watchlist-1.svg"
                alt=""
                class="me-3 cardicon"
              />
              <h1 class="text">CatalystSync™</h1>
            </span>
            <p>
              CatalystSync™ is a integarted platform that provides a complete
              overview of a clinical trails in CatalystSync.
            </p>
          </div>
          <div
            class="col-6 col-md-4 col-lg-4 col-sm-2 cardlist"
            routerLink="/fda-calendar"
          >
            <span class="icon d-flex justify-content-center">
              <img
                src="assets/images/icon-fda-2.svg"
                alt=""
                class="me-3 cardicon"
              />
              <h1 class="text">FDA Calendar</h1>
            </span>
            <p class="desc">
              Don't miss a beat: Click here for real-time updates on upcoming
              catalysts with our FDA calendar.
            </p>
          </div>
          <div
            class="col-6 col-md-4 col-lg-4 col-sm-2 cardlist"
            routerLink="/biotechearnings"
          >
            <span class="icon d-flex justify-content-center">
              <img
                src="assets/images/icon-biotech-3.svg"
                alt=""
                class="me-3 cardicon"
              />
              <h1 class="text">Biotech Earning calendar</h1>
            </span>
            <p class="desc">
              Stay ahead of the earnings game with our Biotech Earnings
              Calendar.
            </p>
          </div>

          <div
            class="col-6 col-md-4 col-lg-4 col-sm-2 cardlist"
            routerLink="/inside-trades"
          >
            <span class="d-flex justify-content-center">
              <img
                src="assets/images/icon-insidetrading-4.svg"
                alt=""
                class="me-3 cardicon"
              />
              <h1 class="text">Inside Trading</h1>
            </span>
            <p>
              Access our Insider Trades tool for the latest trades by top
              executives and insiders.
            </p>
          </div>
          <div
            class="col-6 col-md-4 col-lg-4 col-sm-2 cardlist"
            routerLink="/company-screener"
          >
            <span class="d-flex justify-content-center">
              <img
                src="assets/images/icon-1000companies-5.svg"
                alt=""
                class="me-3 cardicon"
              />
              <h1 class="text">1000+ Companies Screener</h1>
            </span>
            <p>
              Discover popular biotech investing themes and create your own
              Screener.
            </p>
          </div>
          <div
            class="col-6 col-md-4 col-lg-4 col-sm-2 cardlist"
            routerLink="/hedgefunds"
          >
            <span class="d-flex justify-content-center">
              <img
                src="assets/images/icon-1000companies-5.svg"
                alt=""
                class="me-3 cardicon"
              />
              <h1 class="text">Biotech and Pharma Hedge Funds</h1>
            </span>
            <p>
              Follow the smart money with our hedge fund tracking, understanding
              the moves and strategies of key players in the industry
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="container-fluid goal-question-img mt-4">
  <div class="row">
    <div
      class="col-12 d-flex justify-content-center goal-space align-items-center p-5"
    >
      <div class="text-center">
        <div class="">
          <h3 class="text-white">
            How does our research process give you an edge and benefit your
            investment goals?
          </h3>

          <p class="text-white mt-4">
            At BiopharmaWatch, we employ a rigorous research process that
            combines the expertise of our scientists, financial experts, and AI
            technology to provide you with real-time updates and analysis on
            biotech and pharma companies you care about.
          </p>
          <p class="text-white mt-4">
            Our team is constantly tracking more than 1,000 companies and their
            85+ key metrics such as clinical trial progress, FDA approvals, and
            financial performance.
          </p>
          <p class="text-white mt-4">
            What sets us apart is our personalized style. We offer advice and
            alerts tailored to your investment goals and risk tolerance. That
            way, you have a competitive edge in the marketplace and can be
            confident of getting the most out of your investments in the
            exciting world of biotech and pharma. With BiopharmaWatch, you will
            have access to the latest news and insights that can help you make
            investment decisions.
          </p>
        </div>

        <div class="mt-4">
          @if (!loggedin()) {
          <button
            type="button"
            routerLink="/signup"
            class="btn btn-warning button-set2-banner mb-0"
          >
            Free Access
          </button>
          }
        </div>
      </div>
    </div>
  </div>
</div>

<section class="tools volatile gstarted mt-5 mb-5">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="title-shape">
          <h3 class="main-blue-heading">Getting Started</h3>
          <p class="sub-blue-heading">
            Our comprehensive screening and analysis tools
          </p>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-6 col-sm-12 text-center main-box">
            <div class="box d-flex justify-content-center">
              <div class="">
                <div class="triangle-element"></div>
              </div>
              <div class="home-start-box p-4 pt-5">
                <h2 class="text-white text-start box-number m-0">01</h2>
                <h5 class="text text-white text-start box-head m-0">
                  NEW INVESTORS
                </h5>
                <p class="text-white text-start box-text m-0">
                  You'll find a comprehensive guide to industry terms, an
                  overview of clinical trials and FDA approvals, and more. Our
                  goal is to help you feel confident and informed as you begin
                  your biotech investing journey.
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-12 text-center main-box">
            <div class="box d-flex justify-content-center">
              <div class="">
                <div class="triangle-element"></div>
              </div>
              <div class="home-start-box p-4 pt-5">
                <div class="">
                  <h2 class="text-white text-start box-number m-0">02</h2>
                  <h5 class="text text-white text-start box-head m-0">
                    EXPERT INVESTORS
                  </h5>
                </div>
                <p class="text-white text-start box-text m-0">
                  You'll get access to our expert insights, advanced tools, and
                  comprehensive data to help you make informed investment
                  decisions.
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-12 text-center main-box">
            <div class="box d-flex justify-content-center">
              <div class="">
                <div class="triangle-element"></div>
              </div>
              <div class="home-start-box p-4 pt-5">
                <h2 class="text-white text-start box-number m-0">03</h2>
                <h5 class="text text-white text-start box-head m-0">
                  ANALYSTS AND ADVISORS
                </h5>
                <p class="text-white text-start box-text m-0">
                  We help you track the latest industry developments and
                  identify key investment opportunities. Deliver sound
                  recommendations to your clients using our tools and resources.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="modal-overlay" @fadeInOut *ngIf="isVisible">
  <div class="modal-content" @slideInOut>
    <span routerLink="/home" class="close-icon d-flex justify-content-end mx-3">
      <i class="fas fa-times"></i>
    </span>
    <div class="">
      <h2>Payment Successful !!</h2>
    </div>
    <div class="modal-body">
      <img
        src="assets/images/payment_success.gif"
        alt="Success Icon"
        class="success-icon"
      />
      <p>Your payment has been processed successfully.</p>
    </div>
    <div class="modal-footer text-center">
      <button (click)="closeModal()">Check your plan</button>
    </div>
  </div>
</div>
