<section class="h-100">
  <div class="container h-100 mt-5 mb-5">
    <div class="card reset-password-form-card">
      <div class="card-body p-5">
        <h4 class="card-title fw-bold mb-4">Reset password</h4>
        <form class="form" [formGroup]="forgotpassword" (ngSubmit)="onSubmit(forgotpassword.value)">
          <div class=" form-floating mb-3">
            <i class="fa-solid fa-eye float-r" (click)="showPassword = !showPassword"
              [ngClass]="{'fa-eye': !showPassword, 'fa-eye-slash': showPassword}"></i>
            <input id="newPassword" [type]="showPassword ? 'text' : 'password'" class="form-control input-background"
              formControlName="newPassword" #pw />


            <label class="label" for="newPassword">New password </label>
          </div>
          <div class="form-floating mb-3">
            <i class="fa-solid fa-eye float-r" (click)="shownewpassword = !shownewpassword"
              [ngClass]="{'fa-eye': !shownewpassword, 'fa-eye-slash': shownewpassword}"></i>
            <input id="confirmPassword" [type]="shownewpassword ? 'text' : 'password'"
              class="form-control input-background" [ngClass]="confirmPasswordClass" formControlName="confirmPassword"
              #cpw />

            <label class="label" for="confirmPassword">confirm New password</label>
          </div>

          @if (forgotpassword.hasError('confirmedValidator', ['confirmPassword'])) {
          <div>
            Passwords dose not match
          </div>
          }

          <div class="d-flex align-items-center">
            <button type="button"
              class="btn mx-auto col-5 mx-auto btn profile-edit-button fw-bold text-uppercase btn-light shadow rounded bg-white"
              routerLink="/login">
              cancel
            </button>
            <button type="submit" [disabled]="forgotpassword.invalid"
              class=" col-5 mx-auto btn profile-edit-button fw-bold text-uppercase btn-light shadow rounded bg-white">
              Reset password
            </button>

          </div>
        </form>
      </div>
    </div>
  </div>
</section>