<section class="BiotechEarnings text-center">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="mt-3 mb-3">
          <h1 class="main-heading text-white m-0 me-3">
            Biotech Earnings Calendar
          </h1>
          <p class="sub-heading text-center text-white m-0">
            Our Biotech Earnings Calendar provides detailed earnings and
            forecasted estimates for covered biotech companies, featuring
            current EPS for informed investment decisions.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

@if (!isLoading) {
<div class="container p-1 mb-3">
  <div class="row">
    <div class="col-lg-7">
      <div class="calendar-container">
        <div class="container-fluid p-0">
          <div class="d-flex justify-content-center">
            <div
              class="dropdown formobileL d-none me-2 d-flex justify-content-center"
            >
              <button
                [ngClass]="{ selectedbgforsec: bySecVal !== '' }"
                class="btn d-flex align-items-center justify-content-between accordion-down"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                @if (!bySecVal) {
                <span>By Sector</span>
                }@else {
                <span>{{ bySecVal }}</span>
                }

                <i class="fa fa-caret-down ms-4"></i>
              </button>
              <ul class="dropdown-menu games-menu">
                <li>
                  <a
                    class="dropdown-item filter-bottom filter-font-text"
                    [ngClass]="{
                      selecteddropdwon: bySecVal === 'Biotechnology'
                    }"
                    (click)="selectedCap('Biotechnology')"
                    >Biotechnology</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item filter-bottom filter-font-text"
                    [ngClass]="{
                      selecteddropdwon: bySecVal === 'Medical Device'
                    }"
                    (click)="selectedCap('Medical Device')"
                    >Medical Device</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item filter-bottom filter-font-text"
                    [ngClass]="{
                      selecteddropdwon: bySecVal === 'Diagnostics & Research'
                    }"
                    (click)="selectedCap('Diagnostics & Research')"
                    >Diagnostics & Research</a
                  >
                </li>

                <li>
                  <a
                    class="dropdown-item text-center filter-font-text"
                    [ngClass]="{
                      selecteddropdwon: bySecVal === 'Drug Manufacturers'
                    }"
                    (click)="selectedCap('Drug Manufacturers')"
                    >Drug Manufacturers</a
                  >
                </li>
              </ul>
            </div>
            <div
              class="dropdown formobileL d-none mx-2 d-flex justify-content-center"
            >
              <button
                [ngClass]="{ selectedbgforsec: marketCapVal !== '' }"
                class="btn d-flex align-items-center justify-content-between accordion-down"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                @if(!marketCapVal){
                <span>Market Size</span>
                }@else {
                <span>{{ marketCapVal }}</span>
                }
                <i class="fa fa-caret-down ms-4"></i>
              </button>
              <ul class="dropdown-menu games-menu">
                <li>
                  <a
                    class="dropdown-item filter-bottom filter-font-text"
                    [ngClass]="{
                      selecteddropdwon: marketCapVal === 'micro-cap'
                    }"
                    (click)="selectedCap('micro-cap')"
                    >Micro Caps</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item filter-bottom filter-font-text"
                    [ngClass]="{
                      selecteddropdwon: marketCapVal === 'small-cap'
                    }"
                    (click)="selectedCap('small-cap')"
                    >Small Caps</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item filter-bottom filter-font-text"
                    [ngClass]="{ selecteddropdwon: marketCapVal === 'mid-cap' }"
                    (click)="selectedCap('mid-cap')"
                    >Mid Caps</a
                  >
                </li>

                <li>
                  <a
                    class="dropdown-item filter-bottom filter-font-text"
                    [ngClass]="{
                      selecteddropdwon: marketCapVal === 'large-cap'
                    }"
                    (click)="selectedCap('large-cap')"
                    >Large Caps</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item text-center filter-font-text"
                    [ngClass]="{
                      selecteddropdwon: marketCapVal === 'mega-cap'
                    }"
                    (click)="selectedCap('mega-cap')"
                    >Mega Caps</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="header">
          <div class="d-flex align-items-center">
            <span class="yearfont">{{ currentMonth | date : " yyyy" }}</span>
            <span class="monthfont">{{ currentMonth | date : "MMMM" }}</span>

            <img
              (click)="toggle()"
              width="30px"
              class="cursorpointer"
              src="/assets/images/calender-img.webp"
              alt=""
            />

            @if (!modalDisplayStyle) {
            <input
              class="hide_date_input"
              (click)="selectdateforcalendar()"
              [(ngModel)]="dateModel"
              type="text"
              bsDatepicker
              [bsConfig]="bsConfig"
              (ngModelChange)="onMonthYearChange($event)"
              id="startMonth"
            />
            }
          </div>
          <div>
            <button class="prev-btn ms-2 me-3" (click)="prevMonth()">
              <img
                width="20px"
                src="/assets/images/left-arrow-icon.png"
                alt=""
              />
            </button>

            <button class="next-btn" (click)="nextMonth()">
              <img
                width="20px"
                src="/assets/images/right-arrow-icon.png"
                alt=""
              />
            </button>
          </div>

          <div class="d-flex filter">
            <div class="dropdown forLeptopm me-2 d-flex justify-content-center">
              <button
                [ngClass]="{ selectedbgforsec: bySecVal !== '' }"
                class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                @if (!bySecVal) {
                <span>By Sector</span>
                }@else {
                <span>{{ bySecVal }}</span>
                }

                <!-- <i class="fa fa-caret-down ms-4 text-dark"></i> -->
              </button>
              <ul class="dropdown-menu games-menu">
                <li>
                  <a
                    class="dropdown-item filter-bottom filter-font-text"
                    [ngClass]="{
                      selecteddropdwon: bySecVal === 'Biotechnology'
                    }"
                    (click)="selectedCap('Biotechnology')"
                    >Biotechnology</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item filter-bottom filter-font-text"
                    [ngClass]="{
                      selecteddropdwon: bySecVal === 'Medical Device'
                    }"
                    (click)="selectedCap('Medical Device')"
                    >Medical Device</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item filter-bottom filter-font-text"
                    [ngClass]="{
                      selecteddropdwon: bySecVal === 'Diagnostics & Research'
                    }"
                    (click)="selectedCap('Diagnostics & Research')"
                    >Diagnostics & Research</a
                  >
                </li>

                <li>
                  <a
                    class="dropdown-item text-center filter-font-text"
                    [ngClass]="{
                      selecteddropdwon: bySecVal === 'Drug Manufacturers'
                    }"
                    (click)="selectedCap('Drug Manufacturers')"
                    >Drug Manufacturers</a
                  >
                </li>
              </ul>
            </div>
            <div class="dropdown forLeptopm ms-2 d-flex justify-content-center">
              <button
                [ngClass]="{ selectedbgforsec: marketCapVal !== '' }"
                class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                @if(!marketCapVal){
                <span>Market Size</span>
                }@else {
                <span>{{ marketCapVal }}</span>
                }
                <!-- <i class="fa fa-caret-down ms-4 text-dark"></i> -->
              </button>
              <ul class="dropdown-menu games-menu">
                <li>
                  <a
                    class="dropdown-item filter-bottom filter-font-text"
                    [ngClass]="{
                      selecteddropdwon: marketCapVal === 'micro-cap'
                    }"
                    (click)="selectedCap('micro-cap')"
                    >Micro Caps</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item filter-bottom filter-font-text"
                    [ngClass]="{
                      selecteddropdwon: marketCapVal === 'small-cap'
                    }"
                    (click)="selectedCap('small-cap')"
                    >Small Caps</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item filter-bottom filter-font-text"
                    [ngClass]="{ selecteddropdwon: marketCapVal === 'mid-cap' }"
                    (click)="selectedCap('mid-cap')"
                    >Mid Caps</a
                  >
                </li>

                <li>
                  <a
                    class="dropdown-item filter-bottom filter-font-text"
                    [ngClass]="{
                      selecteddropdwon: marketCapVal === 'large-cap'
                    }"
                    (click)="selectedCap('large-cap')"
                    >Large Caps</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item text-center filter-font-text"
                    [ngClass]="{
                      selecteddropdwon: marketCapVal === 'mega-cap'
                    }"
                    (click)="selectedCap('mega-cap')"
                    >Mega Caps</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="weekdays">
          @for (day of weekdays; track $index) {
          <div>{{ day }}</div>
          }
        </div>
        <div
          class="days cursorpointer"
          (click)="selectedDate ? getSelectedDayData(selectedDate) : null"
        >
          @for (blank of getEmptyDaysBeforeFirstDay(); track $index) {
          <div></div>
          } @for (day of calendarDays; track $index) {
          <div class="calendar-box" (click)="selectDate(day)">
            <p
              [ngClass]="{ today: isToday(day), d: isSelected(day) }"
              class="d-flex getDate px-2 m-1"
              style="margin-bottom: 0"
            >
              {{ day.getDate() }}
            </p>
            <span class="text-center">
              <div class="d-flex justify-content-center">
                @for (stock of getMatchingStocks(day) | slice:0:1 ; track
                $index) {
                <p
                  [ngbTooltip]="stock.title.length > 10 ? stock.title : ''"
                  container="body"
                  class="forlep"
                >
                  {{ stock.title }}
                </p>
                }
              </div>

              @if (getMatchingStocks(day).length > 1) {
              <p
                class="formobile mx-auto px-1 m-0 mt-1"
                (click)="openpopupmo(day)"
              >
                <strong>+{{ getMatchingStocks(day).length - 1 }} More</strong>
              </p>
              } @if (getMatchingStocks(day).length >= 1) {
              <p
                class="formobile-number d-none mx-auto px-1 m-0 mt-1"
                (click)="openpopupmo(day)"
              >
                <strong
                  >{{ getMatchingStocks(day).length }}<sup>+</sup>
                </strong>
              </p>
              }
            </span>
          </div>
          }
        </div>
      </div>
    </div>
    <div class="col-lg-5">
      <div>
        <div class="">
          <div class="justify-content-start my-3">
            <form style="height: 30px" class="d-flex example add_m_infilter">
              <input
                [(ngModel)]="searchValuequery"
                autocomplete="off"
                (keyup)="searchFilters($event)"
                (input)="onSearchInput($event)"
                #searchVal
                type="search"
                class="input-box"
                placeholder="Search by Comapny name or Ticker"
                name="search2"
              />
              <button (click)="showDetailData()" class="btn btn-sm p-0 mx-3">
                <i
                  style="opacity: unset; font-size: 20px"
                  class="fa-solid fa-rotate-right"
                ></i>
              </button>
            </form>
            @if (suggection?.length > 0) {
            <ul class="list-unstyled m-0">
              @for (suggestion of suggection; track $index) {
              <li class="suggestion-item p-0 cursorpointer">
                <div
                  (click)="getQueryData(suggestion.symbol)"
                  class="d-flex filter-bottom justify-content-between px-2"
                >
                  <span>
                    @if (suggestion.logoid !== '') {
                    <img
                      width="25px"
                      [src]="basePath + suggestion.logoid + '.svg'"
                    />
                    }@else {
                    <span
                      style="
                        width: 25px !important;
                        height: 25px !important;
                        margin: 0 !important;
                      "
                      class="avatarcss"
                      >{{ suggestion.name | initialLetter }}</span
                    >
                    }
                  </span>
                  <p
                    class="text-start text-dark filter-font-text mb-1 border-1 me-4"
                  >
                    {{ suggestion.name }}
                  </p>
                  <p class="text-end text-dark filter-font-text mb-1">
                    {{ suggestion.symbol }}
                  </p>
                </div>
              </li>
              }
            </ul>
            }
          </div>
          <div class="data-get-scroll">
            <div>
              @for (calenderData of detailData; track $index) {
              <div class="box-border mb-2">
                <div class="d-grid calendar-month-data">
                  <div class="left-border">
                    <div class="d-flex align-items-center">
                      @if (calenderData.logoid !== '') {
                      <img
                        width="40px"
                        [src]="basePath + calenderData.logoid + '.svg'"
                        class="company-hover"
                      />
                      }@else {
                      <span class="avatarcss mb-0">{{
                        calenderData.name | initialLetter
                      }}</span>
                      }
                      <div class="ms-2">
                        <h5 style="font-size: 18px" class="m-0">
                          {{ calenderData.title }}
                        </h5>
                        <p class="pdufa-symbol m-0">
                          <a
                            href="{{ clientUrl }}/company/{{
                              calenderData.symbol | uppercase
                            }}"
                            class="link-ticker"
                          >
                            {{ calenderData.symbol }}</a
                          >
                        </p>
                      </div>
                    </div>
                    <div class="mt-2 d-flex align-items-center">
                      <i class="fas fa-calendar-alt"></i>
                      <p class="m-0 ms-2 catdate-text">
                        {{ calenderData.date }}
                      </p>
                    </div>
                    <div class="mt-2">
                      @if (calenderData.earnings_per_share_basic_ttm) {
                      <p
                        class="m-0 calnder-text"
                        [style.color]="
                          calenderData.earnings_per_share_basic_ttm.toString()
                            | riseAndFallColor
                        "
                      >
                        <span
                          class=""
                          style="color: var(--clr-primary); font-weight: 600"
                          >Previous EPS: </span
                        >{{
                          calenderData.earnings_per_share_basic_ttm
                            | number : "1.0-2"
                        }}
                      </p>
                      }@else { }
                    </div>

                    @if (calenderData.description) {
                    <div class="mt-2">
                      <p class="m-0 calnder-text">
                        <span
                          style="color: var(--clr-primary); font-weight: 600"
                          >Description : </span
                        >{{ calenderData.description }}
                      </p>
                    </div>
                    }@else { }
                  </div>
                </div>
              </div>
              }@empty {
              <h5
                _ngcontent-ng-c489926447=""
                class="no_record text-center"
                style="color: var(--clr-red); margin-top: 250px"
              >
                No Record Found on {{ selectedDate | dateformate }} !
              </h5>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

}@else {
  <div class="container">

    <ngx-skeleton-loader
      [theme]="{ height: '500px', 'border-radius': '4px', 'margin-top': '20px' }"
      count="1"
    >
    </ngx-skeleton-loader>
  </div>
}

<!-- =======Pop_up======== -->
<!-- 
  <div class=" modal" tabindex="-1" role="dialog" [ngStyle]="{ display: displayStyle }">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title d-flex align-items-center">
          <h5 class="m-0 me-3 text-white">{{ selectedStock?.title }}</h5>
          <strong class="biotech-symbol">{{ selectedStock?.symbol }}</strong>
        </h4>
        <div class="d-flex align-items-center">
          <i (click)="closePopup()" *ngIf="backbtn" class="fa-sharp text-white btn fa-solid fa-arrow-left me-2"
            style="opacity: unset;"></i>
          <button type="button" class="btn" (click)="closePopup()"><i class="fa-solid fa-xmark text-white"
              style="opacity: unset;"></i></button>
        </div>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="selectedDate">
          <p>
            <strong class="me-2 fw-bold">Date : </strong>
            {{ weekdays[selectedDate.getDay()] }},
            {{ selectedDate | date : "dd MMM yyyy" }}
          </p>
          <p>
            <strong class="me-2">Previous
              <span placement="bottom" container="body" ngbTooltip="EARNINGS PER SHARE">
                EPS</span>
              :
            </strong>
            {{ selectedStock?.prev_earnings_per_share?.toFixed(2) }}
          </p>
          <p>
            <strong>Description : </strong> {{ selectedStock?.description }}
          </p>
          <div class="modal-footer d-flex justify-content-between">
          </div>
          <a href="#" style="font-size: 10px;">more-detail</a>
        </ng-container>
      </div>
    </div>
  </div>
</div> -->

<!-- =======Pop_up_Mobile======== -->

<app-loginpop-up
  [displayStyle]="modalDisplayStyle"
  (myout)="GetChildData($event)"
></app-loginpop-up>
