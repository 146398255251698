
<section class="individualfund">
  <div class="container p-3">
    <div class="text-center fda-text">
      <h1 class="main-heading text-light">{{ FundName }}</h1>
    </div>
  </div>
  <!-- <div class="form-box py-2 ">
    <div class="container-fluid">
      <div class="container-xxl">
        <div>
          <div class="d-flex screener-input align-items-end justify-content-center my-2">
            <div>
              <h5 for=" exampleInput1" class="form-input-label">SEARCH</h5>
              <div class="d-flex" role="search">
                <input #searchVal class="form-input" type="search" placeholder="Search" aria-label="Search" />
                <button (click)="searchFilters(searchVal.value)" type="button"
                  placeholder="Search by Ticker, Drug or Indication" class="form-save-button filter-search">
                  <i class="fa" [ngClass]="{
                    'fa-spin fa-spinner': isFilterBtnSearch,
                  }"></i>
                  <img *ngIf="!isFilterBtnSearch" class="filter-search-img" src="assets/images/icons8-search.svg" />
                </button>
              </div>
            </div>

            <div class="fda-select ms-3 d-grid justify-content-center">
              <h5 for="exampleInput2" class="form-input-label">CUSTOMIZE</h5>
              <app-multiselect (select_all)="Select_All_checkbox()" [list]="list"
                (shareCheckedList)="shareCheckedList($event)" (shareIndividualCheckedList)="
                  shareIndividualCheckedList($event)
                ">">
              </app-multiselect>
            </div>
            <div class="fiter-btn-set">
              <button type="button" class="form-filter form-save-button ms-3 accordion" data-bs-toggle="collapse"
                (click)="iconchange()" data-bs-target="#demo">
                Filters
                <i class="fa-solid icon-size" [ngClass]="{
                    'fa-angle-up': !isCollapsed,
                    'fa-angle-down': isCollapsed
                  }"></i>
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="filterOpened || showDate || showPrice || showStage">
          <hr class="mt-0" />
        </div>
        <div id="demo" class="collapse">
          <div class="d-flex justify-content-center align-items-center mb-3 form-input_div">
            <div *ngIf="showDate">
              <div>
                <label class="form-input-label ms-3" for="">Date</label>
              </div>
              <div class="d-flex ms-2 align-items-start">
                <input class="form-input filter-input ms-2" (change)="pushFilterDateStart($event)" type="date"
                  placeholder=" Starting Date" [ngModel]="dateStart" />
                <input class="form-input filter-input ms-2" (change)="pushFilterDateEnd($event)" type="date"
                  placeholder=" Ending Date" [ngModel]="dateEnd" />
                <div class="btn btn-sm border-0 d-flex p-0 ms-2 company-remove-btn" (click)="popFilterDate()">
                  <img class="m-1" src="assets/images/new-minus.svg" alt="" />
                </div>
              </div>
            </div>
            <div *ngIf="showPrice">
              <div>
                <label class="form-input-label ms-3" for="">Price</label>
              </div>
              <div class="d-flex ms-2 align-items-start">
                <input class="form-input filter-input ms-2" (change)="pushFilterminPrice($event)" type="number"
                  placeholder=" Min Price" />
                <input class="form-input filter-input ms-2" (change)="pushFiltermaxPrice($event)" type="number"
                  placeholder=" Max Price" />
                <div class="btn btn-sm border-0 d-flex p-0 ms-2 company-remove-btn" (click)="popFilterPrice()">
                  <img class="m-1" src="assets/images/new-minus.svg" alt="" />
                </div>
              </div>
            </div>
            <div *ngIf="showStage">
              <div>
                <label class="form-input-label fda-stage ms-2" for="">Stage</label>
              </div>
              <div class="d-flex ms-2 align-items-start">
                <app-multiselect (select_all)="Select_All_checkbox()" [list]="selectlist"
                  (shareCheckedList)="shareCheckedList($event)"
                  (shareIndividualCheckedList)="shareIndividualChecked($event)">">
                </app-multiselect>
                <div class="btn btn-sm border-0 d-flex p-0 ms-2 company-remove-btn" (click)="popFilterStage()">
                  <img class="m-1" src="assets/images/new-minus.svg" alt="" />
                </div>
              </div>
            </div>
            <div *ngIf="ChangeinPrice">
              <div>
                <label class="form-input-label ms-3" for="">Change in Price</label>
              </div>
              <div class="d-flex ms-2 align-items-start">
                <input class="form-input filter-input ms-2" (change)="pushFilterChange($event)" type="number"
                  placeholder=" Start Change" />
                <input class="form-input filter-input ms-2" (change)="EndpushFilterChange($event)" type="number"
                  placeholder=" End Change" />
                <div class="btn btn-sm border-0 d-flex p-0 ms-2 company-remove-btn" (click)="popFilterChange()">
                  <img class="m-1" src="assets/images/new-minus.svg" alt="" />
                </div>
              </div>
            </div>
            <div *ngIf="filterOpened" class="ms-2">
              <div>
                <label for=" exampleInput3" class="form-input-label ms-2">Add Filter</label>
              </div>
              <select (change)="selectedFilter($event)" class="form-input filter-input ms-2" id="exampleInput3"
                name="showAll">
                <option [value]="null" selected disabled>
                  Select Column...
                </option>
                <option value="Price">Price</option>
                <option value="ChangeinPrice">Change in Price</option>
                <option value="Date">Date</option>
                <option value="Stage">Stage</option>
              </select>
            </div>
          </div>
          <hr class="my-3" />
          <div id="add" class="text-center d-flex justify-content-center">
            <div class="btn applyfilter_div border-0 d-flex align-items-center mb-2" (click)="openFilter()">
              <img class="m-1" src="assets/images/new-pluss.svg" alt="" />
              <p class="form-add-filter m-1 text-nowrap">Add Filter</p>
            </div>
            <button type=""
              class="form-save-button d-flex justify-content-center align-items-center text-nowrap me-3 mt-2 mb-3"
              (click)="saveFilters()">
              <p class="mb-0" *ngIf="!isFilterBtn">APPLY FILTERS</p>
              <i class="fa" [ngClass]="{
                'fa-spin fa-spinner': isFilterBtn,
              }"></i>
            </button>
            <button type=""
              class="form-reset-button d-flex justify-content-center align-items-center text-nowrap me-3 mt-2 mb-3"
              (click)="resetFilters()">
              <p class="mb-0" *ngIf="!isFilterBtnResert">RESET FILTERS</p>
              <i class="fa" [ngClass]="{
              'fa-spin fa-spinner': isFilterBtnResert,
            }"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</section>

<section class="volatile">
  <div class="row heat-map-div justify-content-center mx-2">
    <div class="col-lg-6" style="position: relative">
      <p
        class="text-start my-1 tblcompanyLength text-nowrap d-flex align-items-center"
      >
        <i class="fa-solid fa-circle-info mx-2"></i>
        Showing {{ indivisualTbl.length }} Stocks Out of {{ totalDatalength }}
      </p>
      <div
        class="table-responsive scrollable-table"
        id="dataContainer"
        #dataContainer
        (scroll)="onScroll($event)"
        [ngClass]="{ tablelocked: !productId }"
      >
        <table class="table align-middle mb-0 bg-white">
          <thead class="text-center">
            <tr class="text-uppercase table-header">
              <th class="table-name-size">Stock</th>
              <th>Compnay Name</th>
              <th>Shares Held</th>
              <th>Market Value</th>
              <th>% of Portfolio</th>
              <th>QoQ Change</th>
            </tr>
          </thead>
          <tbody id="tbody" class="text-center">
            @if (indivisualTbl.length == 0) {
              <tr style="box-shadow: none;">
                <td colspan="17">
                  <ngx-skeleton-loader
                    [theme]="{ height: '50px',  'border-radius': '4px', 'margin-top': '20px'}"
                    count="11"
                  >
                  </ngx-skeleton-loader>
                </td>
              </tr>
            }@else { @for (data of indivisualTbl; track $index) {
            <tr class="table-tr-font">
              <td scope="row">
                <div
                  (click)="companyDetails(data.symbol)"
                  class="d-flex cursorpointer align-items-center justify-content-center"
                >
                  <div style="width: 40px">
                    @if (data.logoid[0]) {
                    <img
                      width="25px"
                      [src]="basePath + data.logoid[0] + '.svg'"
                      class="mx-2"
                    />
                    }@else {
                    <span class="avatarcssForTable mx-2 avatarcss">{{
                      data.symbol | initialLetter
                    }}</span>
                    }
                  </div>
                  <div style="width: 40px">
                    <p class="m-0">
                      <a
                        href="{{ clientUrl }}/company/{{
                          data.symbol | uppercase
                        }}"
                        class="link-ticker"
                      >
                        {{ data.symbol }}
                      </a>
                    </p>
                  </div>
                </div>

                @if (data.percent_shares_change === 100) {
                <p class="bg-success text-light rounded shadow">New</p>
                }
              </td>
              <td>
                <p class="text-nowrap">{{ data.Issuer_Name }}</p>
              </td>
              <td>
                <p>{{ data.current_shares | dollarsCoversion }}</p>
              </td>
              <td>
                <p>{{ data.current_mv | dollarsCoversion }}</p>
              </td>

              <td>
                <p>{{ data.current_percent_of_portfolio }} %</p>
              </td>
              <td>
                <div class="d-flex justify-content-center align-items-center">
                  <div style="width: 40px">
                    @if (data.QoQ_chg !== 0) { @if (data.QoQ_chg > 0) {
                    <i
                      style="color: green; font-size: 17px; opacity: unset"
                      class="fas fa-circle-up mx-2"
                    ></i>
                    }@else {
                    <i
                      style="color: red; font-size: 17px; opacity: unset"
                      class="fas fa-circle-down mx-2"
                    ></i>
                    } }
                  </div>
                  <div style="width: 40px">
                    <p class="m-0">{{ data.QoQ_chg | number : "1.0-2" }}</p>
                  </div>
                </div>
              </td>
            </tr>
            }@empty { No Data to Show.. } } @if (loadData) {
            <tr class="loading-border">
              <td colspan="12">
                <div class="data-loading-container">
                  <p class="Fetching me-3 mb-0 mt-0">
                    Fetching more data for you
                  </p>
                  <span class="loader mb-2" style="font-size: 3px"> </span>
                </div>
              </td>
            </tr>
            }
          </tbody>
        </table>
      </div>
      <div
        style="
          position: absolute;
          bottom: 0;
          background-color: #f1f7fde6;
          z-index: 1;
        "
      >
        @if (!productId) { @if (indivisualTbl.length > 0) {
        <app-locksec></app-locksec>
        } }
      </div>
    </div>
    <div class="col-lg-6">
      @if (top20hedgefunds) {
      <div class="container">
        <div
          style="overflow: auto"
          class="d-flex overviewdivmain justify-content-around align-items-center"
        >
          <div class="overviewdiv my-3 px-2">
            <div class="px-4 py-2 shadow rounded">
              <div class="d-grid text-center">
                <b style="font-size: 15px; white-space: nowrap">Market Value</b>
                <p style="font-size: 13px; white-space: nowrap" class="m-0">
                  $ {{ totalmv | dollarsCoversion }}
                </p>
              </div>
            </div>
          </div>
          <div class="overviewdiv my-3 px-2">
            <div class="px-4 py-2 shadow rounded">
              <div class="d-grid text-center">
                <b style="font-size: 15px; white-space: nowrap">Holdings</b>
                <p style="font-size: 13px; white-space: nowrap" class="m-0">
                  {{ totalDatalength }}
                </p>
              </div>
            </div>
          </div>
          <div class="overviewdiv my-3 px-2">
            <div class="px-4 py-2 shadow rounded">
              <div class="d-grid text-center">
                <b style="font-size: 15px; white-space: nowrap">Period</b>
                <p style="font-size: 13px; white-space: nowrap" class="m-0">
                  Q1 2024
                </p>
              </div>
            </div>
          </div>
          <div class="overviewdiv my-3 px-2">
            <div class="px-4 py-2 shadow rounded">
              <div class="d-grid text-center">
                <b style="font-size: 15px; white-space: nowrap"
                  >Holdings in Top 20</b
                >
                <p style="font-size: 13px; white-space: nowrap" class="m-0">
                  {{ total20holding | number : "1.0-2" }} %
                </p>
              </div>
            </div>
          </div>
          <div class="overviewdiv my-3 px-2">
            <div class="px-4 py-2 shadow rounded">
              <div class="d-grid text-center">
                <b style="font-size: 15px; white-space: nowrap">QoQ Changes</b>
                <p style="font-size: 13px; white-space: nowrap" class="m-0">
                  {{ qoqchange | number : "1.0-2" }} %
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h3 class="my-1"><b>Top 20 Holdings</b></h3>
      <app-hedge-fund-ind-heatmap
        [heatmapData]="top20hedgefunds"
      ></app-hedge-fund-ind-heatmap>
      }
    </div>
  </div>
</section>

<app-loginpop-up
  [displayStyle]="modalDisplayStyle"
  (myout)="GetChildData($event)"
></app-loginpop-up>
