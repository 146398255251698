import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CSTable } from '../../../shared/models/company-screener';
import { CompanyScreenerService } from '../../../shared/service/company-screener.service';
import { CompanyDetailsService } from '../../../shared/service/company-details.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { io } from 'socket.io-client';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FilterdataService } from '../../../shared/service/filterdata.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { FdaCalenarService } from 'src/app/modules/shared/service/fda-calendar.service';
import { DecryptionService } from 'src/app/modules/shared/service/decryption.service';
declare const TradingView: any;

@Component({
  selector: 'app-company-screener',
  templateUrl: './company-screener.component.html',
  styleUrls: ['./company-screener.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CompanyScreenerComponent {
  @ViewChild('dataContainer') dataContainer!: ElementRef;
  isCollapsed: boolean = true;
  selectedCompany: any;
  // @Output() selectedCom: EventEmitter<any> = new EventEmitter();
  showButtons = false;
  suggestions: string[] = [];
  displayStyle = 'none';
  displayStyleLo = 'none';
  isLoading!: boolean;
  loadData!: boolean;
  scrollend = true;
  csTable: CSTable[] = [];
  public chart: any;
  isJqueryWorking!: string;
  cssClass = '';
  epsCss = '';
  screenerForm!: FormGroup;
  basePath: string = 'https://s3-symbol-logo.tradingview.com/';
  socket = io(environment.socketUrl);
  currentPage = 1;
  searchingOrFiltering: boolean = true;
  isCompanyDetailsTriggered: boolean = false;
  list!: any[];
  ticker = true;
  price = true;
  thirtydaypricechange = true;
  marketcap = true;
  pe_ratio = true;
  eps = true;
  dter = true;
  working_cap = true;
  industry = true;
  Specific_Diseases = true;
  Therapeutic_area = true;
  searchValuequery: string = '';
  volume = true;
  burn_rate = true;
  runway = true;
  modalDisplayStyle!: boolean;
  unit: any;
  socketData: any[] = [];
  resdata: any;
  companyTicker: BehaviorSubject<string> = new BehaviorSubject<any>(null);
  TickerBS = this.companyTicker.asObservable();
  select_all = true;
  filterActive = false;
  showPrice = false;
  EPS = false;
  forResetfilters = false;
  showpe = false;
  ChangeinPrice = false;
  filterOpened = false;
  filterArray: any[] = [];
  dateEnd!: Date;
  dateStart!: Date;
  selectlist!: any[];
  stageArr: any[] = [];
  Market_cap = false;
  Netcash = false;
  isFilterBtn = false;
  isFilterBtnResert = false;
  isFilterBtnSearch = false;
  selectedOption!: any;
  startPrice!: number;
  endPrice!: number;
  showloginWarning = false;
  toggle: boolean = true;
  selectedPriceRange: string | null = null;
  selectedEPSRange: string | null = null;
  selectedMarketCapRange: string | null = null;
  selectedIndustryRange: string | null = null;
  selectedBurnRateRange: string | null = null;
  selectedRunwayRange: string | null = null;
  selectedVolumeRange: string | null = null;
  selectedLiveCashRange: string | null = null;
  selectedPERange: string | null = null;
  isDropdownOpen: { [key: string]: boolean } = {};
  selectedChangePriceRange: string | null = null;
  disableDeselect: boolean = false;
  selectedTherapeutic: any = [];
  selectedDiseases: any = [];

  isCustomChangePriceRangeSelected: boolean = false;
  isCustommarketcapRangeSelected: boolean = false;
  isCustomRangeSelected: boolean = false;
  isCustomEPSRangeSelected: boolean = false;

  minCustomValue: number | undefined;
  maxCustomValue: number | undefined;
  minCustomchange!: number;
  maxCustomchange!: number;
  minCustomMarket!: number;
  maxCustomMarket!: number;
  minCustomEps!: number;
  maxCustomEps!: number;
  minCustomburn_rate: any;
  maxCustomburn_rate: any;
  minCustomrunway: any;
  maxCustomrunway: any;
  minCustomlive_cash: any;
  maxCustomlive_cash: any;
  minCustomrvolume: any;
  maxCustomrvolume: any;
  minCustomrpe: any;
  maxCustomrpe: any;

  showpriceDropDown!: boolean;
  showchangepriceDropDown!: boolean;
  isEPSDropdownOpen!: boolean;
  isMarketcapDropdownOpen!: boolean;
  isIndustrydownOpen!: boolean;
  BiotechnologyData!: any[];
  MedicalDeviceData!: any[];
  DiagnosticsData!: any[];
  DrugData!: any[];
  arrowclass = 'fa-arrow-up';
  FilterObj: any;
  sortNumber = 1;
  sortKeyVal = '';
  isSorton = false;
  apitype = 'defualt';
  totalDatalength = 0;
  selectedDataColor!: string;
  selectedepsColor!: string;
  selectedchangeColor!: string;
  selectedmarketColor!: string;
  selectedindustryColor!: string;
  selectedValue: string | null = null;
  selectedValueinchange: string | null = null;
  selectedValueinmarket_cap: string | null = null;
  selectedValueineps: string | null = null;
  selectedValueinindustry: string | null = null;
  isFilterWithSort = false;
  isFilter = false;
  isSort = false;
  selectedValueinburn_rate: string | null = null;
  selectedValueinlive_cash: string | null = null;
  selectedValueinrunway: string | null = null;
  selectedValueinvolume: string | null = null;
  selectedValueinpe: string | null = null;
  selectedbutn_rateColor!: string;
  selectedlive_cashColor!: string;
  selectedrunwayColor!: string;
  selectedvolumeColor!: string;
  selectedpeColor!: string;

  arrowname = 'fa-arrow-up';
  arrowticker = 'fa-arrow-up';
  arrowprice = 'fa-arrow-up';
  arrowPe_rat = 'fa-arrow-up';
  arrowEps = 'fa-arrow-up';
  arrowDE_rat = 'fa-arrow-up';
  arrowmarket_cap = 'fa-arrow-up';
  arrowlive_cash = 'fa-arrow-up';
  arrowvolume = 'fa-arrow-up';
  arrowburn_rate = 'fa-arrow-up';
  arrowrunway = 'fa-arrow-up';
  suggection: any;
  portfoliomatchTicker: any[] = [];
  portfolioTicker: any;
  portfolioPrice: any;
  portfoliocost: number | undefined;
  portfolioquntity: number | undefined;
  portfoliototal: any;

  selectedDiseasesColor!: string;
  diseasesList!: any[];
  disablediseasesList: boolean = false;

  selectedTherapeuticColor!: string;
  therapeuticList!: any[];
  disableTheraputics: boolean = false;
  lockedArray: any[] = [];
  productId: number | undefined;
  clientUrl = environment.clientUrl;

  constructor(
    private screenerservice: CompanyScreenerService,
    private formBuilder: FormBuilder,
    private compamyDetails: CompanyDetailsService,
    private router: Router,
    private toaster: ToastrService,
    private filter: FilterdataService,
    private fdacalendarservice: FdaCalenarService,
    private decryptionService: DecryptionService
  ) {
    this.list = [
      { name: 'TICKER', checked: true },
      { name: 'PRICE', checked: true },
      { name: '30 DAYS PRICE CHANGE', checked: true },
      { name: 'MARKET CAP', checked: true },
      { name: 'P/E RATIO', checked: true },
      { name: 'EARNINGS PER SHARE (EPS)', checked: true },
      { name: 'DEBT TO EQUITY RATIO', checked: true },
      { name: 'LIVE CASH', checked: true },
      { name: 'INDUSTRY', checked: true },
      { name: 'Specific Diseases', checked: true },
      { name: 'Therapeutic Area', checked: true },
      { name: 'VOLUME', checked: true },
      { name: 'BURN RATE', checked: true },
      { name: 'RUNWAY', checked: true },
    ];

    this.selectlist = [
      { name: 'Phase 1', checked: false },
      { name: 'Phase 1/a', checked: false },
      { name: 'Phase 1/b', checked: false },
      { name: 'Phase 1/2', checked: false },
      { name: 'Phase 2a', checked: false },
      { name: 'Phase 2', checked: false },
      { name: 'Phase 2b', checked: false },
      { name: 'Phase 2/3', checked: false },
      { name: 'Phase 3', checked: false },
      { name: 'NDA Filing', checked: false },
      { name: 'sNDA Filing', checked: false },
      { name: 'BLA Filing', checked: false },
      { name: 'PDUFA', checked: false },
      { name: 'Approved', checked: false },
      { name: 'CRL', checked: false },
    ];

    this.BiotechnologyData = [
      'Biotechnology',
      'Tobacco',
      'Real Estate Services',
      'Chemicals',
      ' N/A',
      'Farm Products',
      'Food Distribution',
      'Packaged Foods',
    ];

    this.MedicalDeviceData = [
      'Medical Devices',
      'Medical Instruments & Supplies',
      'Electronic Components',
      'Scientific & Technical Instruments',
      'Health Information Services',
      'Medical Care Facilities',
      'Furnishings',
      'Fixtures & Appliances',
      'Medical Distribution',
    ];

    this.DiagnosticsData = ['Diagnostics & Research'];

    this.DrugData = [
      'Drug Manufacturers—General',
      'Drug Manufacturers—Specialty & Generic',
      'Household & Personal Products,Pharmaceutical Retailers',
    ];

    this.diseasesList = [
      { name: 'Multiple Sclerosis', checked: false },
      { name: 'Autoimmune Diseases', checked: false },
      { name: 'Genetic Disorders', checked: false },
      { name: 'Lymphoma', checked: false },
      { name: "Parkinson's Disease", checked: false },
      { name: 'Hepatitis', checked: false },
      { name: 'Heart Disease', checked: false },
      { name: "Alzheimer's Disease", checked: false },
      { name: 'Diabetes', checked: false },
      { name: 'HIV/AIDS', checked: false },
      { name: 'Breast Cancer', checked: false },
      { name: 'Lung Cancer', checked: false },
      { name: 'Leukemia', checked: false },
    ];

    this.therapeuticList = [
      { name: 'Cardiovascular Disease', checked: false },
      { name: 'Dermatology', checked: false },
      { name: 'Endocrinology/Metabolism/Bone', checked: false },
      { name: 'Gastroenterology', checked: false },
      { name: 'Infectious Disease (viral)', checked: false },
      { name: 'Neurology', checked: false },
      { name: 'Oncology', checked: false },
      { name: 'Ophthalmology', checked: false },
      { name: 'Renal Disease', checked: false },
      { name: 'Analgesia/Anesthesiology/Anti-inflammatory', checked: false },
      { name: 'Psychiatry', checked: false },
      { name: 'Addiction', checked: false },
      { name: 'Infectious Disease (non viral)', checked: false },
      { name: 'Hematology/Coagulation', checked: false },
      { name: 'Pulmonary', checked: false },
      { name: 'Medical Imaging', checked: false },
      { name: 'Rheumatology', checked: false },
      { name: 'Allergy', checked: false },
      { name: 'Immunomodulators', checked: false },
    ];
  }

  ngOnInit() {
    this.screenerForm = this.formBuilder.group({
      addform: this.formBuilder.array([]),
    });
    const element = document.getElementById('dataContainer');
    if (element) {
      element.addEventListener('scroll', this.onScroll.bind(this));
    }

    this.csTableData();
    this.tblcompaniesScoket();
    this.returnLogin();
  }

  onOptionChange(item: any) {
    if (item) {
      this.startPrice = item.startPrice;
      this.endPrice = item.endPrice;

      this.filterArray.push({ start_market_cap: Number(this.startPrice) });
      this.filterArray.push({ end_market_cap: Number(this.endPrice) });
    }
  }

  onTickerchange(data: string) {
    this.loadTradingViewWidget(data);
  }

  scrollLeft() {
    this.dataContainer.nativeElement.scrollLeft -= 300;
  }

  scrollRight() {
    this.dataContainer.nativeElement.scrollLeft += 300;
  }

  checkScrollable() {
    const container = this.dataContainer.nativeElement;
    if (container.scrollWidth > container.clientWidth) {
      this.showButtons = true;
    } else {
      this.showButtons = false;
    }
  }

  GetChildData(data: any) {
    this.modalDisplayStyle = data;
  }

  shareCheckedList(item: any[]) {}

  shareIndividualCheckedList(item: {}) {
    let value = Object.values(item)[1];

    switch (value) {
      case 'TICKER':
        this.ticker = !this.ticker;
        break;
      case 'PRICE':
        this.price = !this.price;
        break;
      case '30 DAYS PRICE CHANGE':
        this.thirtydaypricechange = !this.thirtydaypricechange;
        break;
      case 'MARKET CAP':
        this.marketcap = !this.marketcap;
        break;
      case 'P/E RATIO':
        this.pe_ratio = !this.pe_ratio;
        break;
      case 'EARNINGS PER SHARE (EPS)':
        this.eps = !this.eps;
        break;
      case 'DEBT TO EQUITY RATIO':
        this.dter = !this.dter;
        break;
      case 'LIVE CASH':
        this.working_cap = !this.working_cap;
        break;
      case 'INDUSTRY':
        this.industry = !this.industry;
        break;

      case 'Specific Diseases':
        this.Specific_Diseases = !this.Specific_Diseases;
        break;
      case 'Therapeutic Area':
        this.Therapeutic_area = !this.Therapeutic_area;
        break;

      case 'VOLUME':
        this.volume = !this.volume;
        break;
      case 'BURN RATE':
        this.burn_rate = !this.burn_rate;
        break;
      case 'RUNWAY':
        this.runway = !this.runway;
        break;
      default:
        break;
    }
  }

  formControl() {
    return this.screenerForm.get('addform') as FormArray;
  }

  removeEmployee(empIndex: number) {
    this.formControl().removeAt(empIndex);
  }

  newForm(): FormGroup {
    return this.formBuilder.group({
      query: ['', Validators.required],
      // customize: [null, Validators.required],
      // showAll: [null, Validators.required],
    });
  }

  tblcompaniesScoket() {
    this.socket.on('tblcompaniesScoket', (res: any) => {
      if (res.operationType == 'update') {
        var Data = {
          ...res.documentKey,
          ...res.updateDescription.updatedFields,
        };
        this.csTable.filter((CStableObj: any) => {
          if (CStableObj._id === Data._id) {
            const previousClose = CStableObj.close;

            CStableObj.close = Data.close ? Data.close : CStableObj.close;

            if (Data.close) {
              if (Data.close > previousClose) {
                this.animateField(CStableObj, 'close', 'green-highlight');
              } else if (Data.close < previousClose) {
                this.animateField(CStableObj, 'close', 'red-highlight');
              }
            }
          }
          if (CStableObj._id === Data._id) {
            CStableObj.change = Data.change ? Data.change : CStableObj.change;
            // this.animateField(CStableObj, 'change');
          }
          if (CStableObj._id === Data._id) {
            CStableObj.change_abs = Data.change_abs
              ? Data.change_abs
              : CStableObj.change_abs;
            // this.animateField(CStableObj, 'change_abs');
          }
          if (CStableObj._id === Data._id) {
            CStableObj.earnings_per_share_basic_ttm =
              Data.earnings_per_share_basic_ttm
                ? Data.earnings_per_share_basic_ttm
                : CStableObj.earnings_per_share_basic_ttm;
            // this.animateField(CStableObj, 'earnings_per_share_basic_ttm');
          }
          if (CStableObj._id === Data._id) {
            CStableObj.market_cap_basic = Data.market_cap_basic
              ? Data.market_cap_basic
              : CStableObj.market_cap_basic;
            // this.animateField(CStableObj, 'market_cap_basic');
          }
          if (CStableObj._id === Data._id) {
            CStableObj.price_earnings_ttm = Data.price_earnings_ttm
              ? Data.price_earnings_ttm
              : CStableObj.price_earnings_ttm;
            // this.animateField(CStableObj, 'price_earnings_ttm');
          }
        });
      } else {
      }
    });
    this.socket.connect();
  }

  animateField(CStableObj: any, field: string, highlightClass: string) {
    // Add the 'highlight' class
    CStableObj[field + 'Class'] = highlightClass;

    // Remove the class after the animation duration (e.g., 1 second)
    setTimeout(() => {
      CStableObj[field + 'Class'] = '';
    }, 1000); // Adjust this time based on the animation duration
  }

  updateTableCloseValue(updatedClosePrice: any) {
    for (let i = 0; i < this.csTable.length; i++) {
      this.csTable[i].close = updatedClosePrice[i];
    }
  }

  csTableData() {
    this.isLoading = true;

    this.screenerservice.getCSTable(this.currentPage).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);

        const ParseDecryptedData = JSON.parse(decryptData);

        this.totalDatalength = ParseDecryptedData[0].total[0].count;

        ParseDecryptedData.find((x: any) => x.portfolio)?.portfolio.forEach(
          (element: any) => {
            this.portfoliomatchTicker.push(element.ticker);
          }
        );

        this.productId = ParseDecryptedData.find(
          (x: any) => x.productId
        )?.productId;

        this.csTable = ParseDecryptedData[0].data;
        this.currentPage = 2;
        this.isLoading = false;
        this.isFilterBtn = false;

        this.isFilterBtnResert = false;
        this.csTable = ParseDecryptedData[0].data;

        // for (let i = 0; i < 2; i++) {
        //   this.lockedArray.push(this.csTable);
        //   this.lockedArray = this.lockedArray.flat();
        // }

        // if (!this.productId) {
        //   this.csTable = this.lockedArray;
        // }

        setTimeout(() => {
          this.checkScrollable();
        });
      },
    });
  }

  // datatypes() {
  //   const validItems = this.resdata.filter((item: any) => item );
  //   validItems.forEach((data: any) => {
  //     data.dtoe = data.liabilities / data.equity;
  //     data.price_earnings_ttm = data.close / data.earnings_per_share_basic_ttm;

  //     this.csTable.push(data);
  //   });
  // }

  loadMoreData() {
    this.isLoading = true;
    this.loadData = true;

    if (this.apitype == 'filter') {
      this.filter
        .compnayfilterColoum(this.FilterObj, this.currentPage)
        .subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res.result);

            const ParseDecryptedData = JSON.parse(decryptData);

            ParseDecryptedData[0].data.forEach((element: any) => {
              element.dtoe = element.liabilities / element.equity;
              this.csTable.push(element);
            });
            this.isLoading = false;
            this.loadData = false;
            this.currentPage++;
          },
          error: (err) => {
            this.loadData = false;
            this.isLoading = true;
            this.toaster.warning('All Record displayed');
            this.apitype = 'defualt';
          },
        });
    }

    if (this.apitype == 'defualt') {
      this.screenerservice.getCSTable(this.currentPage).subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res.result);

          const ParseDecryptedData = JSON.parse(decryptData);
          this.totalDatalength = ParseDecryptedData[0].total[0].count;

          res.portfolioSymbols &&
            res.portfolioSymbols.forEach((element: any) => {
              this.portfoliomatchTicker.push(element.ticker);
            });

          ParseDecryptedData[0].data.forEach((element: any) => {
            element.dtoe = element.liabilities / element.equity;
            this.csTable.push(element);
          });
          this.loadData = false;
          this.isLoading = false;
          this.currentPage++;
        },
        error: (err) => {
          this.loadData = false;
          this.isLoading = true;
          this.toaster.warning('All Record displayed');
          this.apitype = 'defualt';
        },
      });
    }

    if (this.apitype == 'sort') {
      this.filter
        .screenersort(this.sortKeyVal, this.sortNumber, this.currentPage)
        .subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res.result);

            const ParseDecryptedData = JSON.parse(decryptData);
            ParseDecryptedData[0].data.forEach((element: any) => {
              element.dtoe = element.liabilities / element.equity;
              this.csTable.push(element);
            });
            this.isLoading = false;
            this.loadData = false;

            this.currentPage++;
          },
          error: (err) => {
            this.isLoading = true;
            this.loadData = false;
            this.toaster.warning('All Record displayed');
            this.apitype = 'defualt';
          },
        });
    }

    if (this.isSort) {
      this.filter
        .screenersortwithFilter(
          this.sortKeyVal,
          this.sortNumber,
          this.currentPage,
          this.FilterObj
        )
        .subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res.result);

            const ParseDecryptedData = JSON.parse(decryptData);
            this.totalDatalength = ParseDecryptedData[0].total[0].count;
            this.loadData = false;
            this.isLoading = false;
            this.currentPage++;
            ParseDecryptedData[0].data.forEach((element: any) => {
              this.csTable.push(element);
            });
          },
          error: (err) => {
            this.loadData = false;
            this.isLoading = true;
            this.toaster.warning('All Record displayed');
            this.apitype = 'defualt';
          },
        });
    }
  }

  onScroll(event: any) {
    const element = event.target as HTMLElement;
    const scrollPosition = element.scrollTop + element.clientHeight;
    const totalHeight = element.scrollHeight;
    if (this.productId) {
      if (scrollPosition >= totalHeight - 1 && !this.isLoading) {
        if (this.currentPage != 1) {
          this.loadMoreData();
        }
      }
    } else {
      let href = this.router.url;
      localStorage.setItem('route', href);
      this.modalDisplayStyle = true;
    }
  }

  loginpage() {
    this.router.navigate(['login']);
  }

  resetFilters() {
    this.isFilterBtnResert = true;
    this.isFilter = false;
    this.isSort = false;
    this.apitype = 'defualt';
    this.refresh();
    this.csTableData();

    this.clearSelectedPrice();
    this.clearSelectedChangePrice();
    this.clearSelectedEPS();
    this.clearSelectedMarketCap();
    this.clearSelectedBurn_rate();
    this.clearSelectedIndustry();

    this.clearDiseasesCheckbox();
    this.clearTherapeuticCheckbox();

    this.clearSelectedrunway();
    this.clearSelectedvolume();
    this.clearSelectedpe();
    this.clearSelectedLive_cash();
    // if (this.forResetfilters) {
    //   this.clearSelectedPrice();
    //   this.clearSelectedChangePrice();
    //   this.clearSelectedEPS();
    //   this.clearSelectedMarketCap();
    //   this.clearSelectedBurn_rate();
    //   this.clearSelectedIndustry();

    //   this.clearDiseasesCheckbox();
    //   this.clearTherapeuticCheckbox();

    //   this.clearSelectedrunway();
    //   this.clearSelectedvolume();
    //   this.clearSelectedpe();
    //   this.clearSelectedLive_cash();
    // }
  }

  companyDetails(ticker: any) {
    this.compamyDetails.getCompanyTable(ticker).subscribe({
      next: (res: any) => {
        this.router.navigate(['company', ticker]);
      },
    });
    this.compamyDetails.getCompanyTicker(ticker);
  }

  closePopup() {
    this.displayStyle = 'none';
    this.displayStyleLo = 'none';
  }

  companyTrackBy(index: any, item: any) {
    return item.symbol;
  }

  Select_All_checkbox() {
    this.toggle = !this.toggle;
    if (this.toggle) {
      this.ticker = true;
      this.list.forEach((item) => (item.checked = true));
      this.price = true;
      this.thirtydaypricechange = true;
      this.industry = true;
      this.Specific_Diseases = true;
      this.Therapeutic_area = true;
      this.volume = true;
      this.eps = true;
      this.working_cap = true;
      this.marketcap = true;
      this.pe_ratio = true;
      this.dter = true;
      this.runway = true;
      this.burn_rate = true;
    } else {
      this.ticker = false;
      this.list.forEach((item) => (item.checked = false));
      this.price = false;
      this.thirtydaypricechange = false;
      this.industry = false;
      this.Specific_Diseases = false;
      this.Therapeutic_area = false;
      this.volume = false;
      this.eps = false;
      this.working_cap = false;
      this.runway = false;
      this.burn_rate = false;
      this.marketcap = false;
      this.pe_ratio = false;
      this.dter = false;
    }
  }

  popFilterEPS() {
    this.EPS = false;
  }
  popFilterNetcash() {
    this.Netcash = false;
  }

  pushFilterstart_pe(data: any) {
    this.filterArray.push({ start_pe: data.target.value });
  }

  pushFilterend_pe(data: any) {
    this.filterArray.push({ end_pe: data.target.value });
  }

  // pushFilterChange(data: any) {
  //   this.filterArray.push({ startChange: Number(data.target.value) })
  // }

  // EndpushFilterChange(data: any) {
  //   this.filterArray.push({ endChange: Number(data.target.value) })
  // }

  // pushFilterMarket_cap(data: any) {
  //   this.filterArray.push({ start_market_cap: Number(data.target.value) })
  // }

  // EndpushFilterMarket_cap(data: any) {
  //   this.filterArray.push({ end_market_cap: Number(data.target.value) })
  // }

  pushFilterNetcash(data: any) {
    this.filterArray.push({ start_netcash: Number(data.target.value) });
  }

  EndpushFilterNetcash(data: any) {
    this.filterArray.push({ end_netcash: Number(data.target.value) });
  }

  popFilterPrice() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].startPrice;
      delete this.filterArray[key].endPrice;
    }
    this.showPrice = false;
  }
  popFilterpe() {
    this.showpe = false;
  }
  popFilterChange() {
    this.ChangeinPrice = false;
  }
  popFilterMarket_cap() {
    this.Market_cap = false;
  }

  openFilter() {
    this.filterOpened = true;
  }

  refresh() {
    this.csTable = [];
    this.lockedArray = [];

    this.isLoading = false;
    this.loadData = false;

    this.currentPage = 1;
    this.arrowname = 'fa-arrow-up';
    this.arrowticker = 'fa-arrow-up';
    this.arrowprice = 'fa-arrow-up';
    this.arrowEps = 'fa-arrow-up';
    this.arrowPe_rat = 'fa-arrow-up';
    this.arrowDE_rat = 'fa-arrow-up';
    this.arrowmarket_cap = 'fa-arrow-up';
    this.arrowlive_cash = 'fa-arrow-up';
    this.arrowvolume = 'fa-arrow-up';
    this.arrowburn_rate = 'fa-arrow-up';
    this.arrowrunway = 'fa-arrow-up';
  }

  saveFilters() {
    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }
    let filtered = this.filterArray.filter((el) => Object.keys(el).length);
    if (filtered.length <= 0) {
      this.toaster.warning('Enter Valid Inputs.');
      return;
    }
    this.refresh();
    this.isFilter = true;
    this.forResetfilters = true;
    this.apitype = 'filter';
    var newObject = filtered.reduce((result, currentObject) => {
      for (var key in currentObject) {
        if (currentObject.hasOwnProperty(key)) {
          result[key] = currentObject[key];
        }
      }
      return result;
    }, {});
    this.isLoading = true;
    this.isFilterBtn = true;
    this.FilterObj = newObject;
    this.filter
      .compnayfilterColoum(this.FilterObj, this.currentPage)
      .subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res.result);

          const ParseDecryptedData = JSON.parse(decryptData);
          this.currentPage = 2;
          this.isLoading = false;
          this.isFilterBtn = false;
          this.totalDatalength = ParseDecryptedData[0].total[0].count;

          ParseDecryptedData[0].data.forEach((data: any) => {
            data.dtoe = data.liabilities / data.equity;
            this.csTable = ParseDecryptedData[0].data as CSTable[];
          });
          this.searchingOrFiltering = false;
        },
        error: (err) => {
          this.isLoading = false;
          this.isFilterBtn = false;
          this.resetFilters();
          this.toaster.error(err.error.message);
        },
      });
  }

  assending(sortKey: string) {
    if (!this.productId) {
      this.modalDisplayStyle = true;
    }else{
    this.apitype = 'sort';

    this.sortNumber = this.sortKeyVal === sortKey ? this.sortNumber * -1 : 1;
    this.sortKeyVal = sortKey;

    this.refresh();

    switch (sortKey) {
      case 'name':
        this.arrowname =
          this.sortNumber == 1
            ? 'fa-arrow-up-wide-short'
            : 'fa-arrow-down-wide-short';
        break;
      case 'symbol':
        this.arrowticker =
          this.sortNumber == 1
            ? 'fa-arrow-up-wide-short'
            : 'fa-arrow-down-wide-short';
        break;
      case 'close':
        this.arrowprice =
          this.sortNumber == 1
            ? 'fa-arrow-up-wide-short'
            : 'fa-arrow-down-wide-short';
        break;
      case 'market_cap_basic':
        this.arrowmarket_cap =
          this.sortNumber == 1
            ? 'fa-arrow-up-wide-short'
            : 'fa-arrow-down-wide-short';
        break;
      case 'volume':
        this.arrowvolume =
          this.sortNumber == 1
            ? 'fa-arrow-up-wide-short'
            : 'fa-arrow-down-wide-short';
        break;
      case 'price_earnings_ttm':
        this.arrowPe_rat =
          this.sortNumber == 1
            ? 'fa-arrow-up-wide-short'
            : 'fa-arrow-down-wide-short';
        break;
      case 'earnings_per_share_basic_ttm':
        this.arrowEps =
          this.sortNumber == 1
            ? 'fa-arrow-up-wide-short'
            : 'fa-arrow-down-wide-short';
        break;
      case 'dtoe':
        this.arrowDE_rat =
          this.sortNumber == 1
            ? 'fa-arrow-up-wide-short'
            : 'fa-arrow-down-wide-short';
        break;
      case 'Cash_and_Cash_Equivalents':
        this.arrowlive_cash =
          this.sortNumber == 1
            ? 'fa-arrow-up-wide-short'
            : 'fa-arrow-down-wide-short';
        break;
      case 'Monthly_Burn_Rate':
        this.arrowburn_rate =
          this.sortNumber == 1
            ? 'fa-arrow-up-wide-short'
            : 'fa-arrow-down-wide-short';
        break;
      case 'Runway_(Months)':
        this.arrowrunway =
          this.sortNumber == 1
            ? 'fa-arrow-up-wide-short'
            : 'fa-arrow-down-wide-short';
        break;
      default:
        break;
    }

    if (this.isFilter === true) {
      this.apitype = 'filtersort';
      this.isSort = true;
      this.filter
        .screenersortwithFilter(
          this.sortKeyVal,
          this.sortNumber,
          this.currentPage,
          this.FilterObj
        )
        .subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res.result);

            const ParseDecryptedData = JSON.parse(decryptData);
            this.totalDatalength = ParseDecryptedData[0].total[0].count;

            ParseDecryptedData[0].data.forEach((element: any) => {
              this.csTable.push(element);
              this.currentPage = 2;
            });
          },
          error: (err) => {
            this.isLoading = false;
            this.isFilterBtn = false;
            this.refresh();
            this.csTableData();
            this.toaster.error(err.error.message);
          },
        });
    } else {
      this.filter
        .screenersort(this.sortKeyVal, this.sortNumber, this.currentPage)
        .subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res.result);

            const ParseDecryptedData = JSON.parse(decryptData);
            this.totalDatalength = ParseDecryptedData[0].total[0].count;

            ParseDecryptedData[0].data.forEach((element: any) => {
              this.csTable.push(element);
              this.currentPage = 2;
            });
          },
          error: (err) => {
            this.isLoading = false;
            this.isFilterBtn = false;
            this.refresh();
            this.csTableData();
            this.toaster.error(err.error.message);
          },
        });
    }
  }
  }

  selectedFilter(val: any) {
    if (val.target.value == 'EPS') {
      this.EPS = true;
    }
    if (val.target.value == 'Price') {
      this.showPrice = true;
    }
    if (val.target.value == 'P/E RATIO') {
      this.showpe = true;
    }
    if (val.target.value == 'ChangeinPrice') {
      this.ChangeinPrice = true;
    }
    if (val.target.value == 'Market_cap') {
      this.Market_cap = true;
    }
    if (val.target.value == 'Netcash') {
      this.Netcash = true;
    }
    this.filterOpened = false;
  }
  shareIndividualChecked(item: {}) {
    let value = Object.values(item)[1];
    this.stageArr.push(value);
    const stage = this.stageArr;
    const obj = {
      stage,
    };
    this.filterArray.push(obj);
  }

  loadTradingViewWidget(ticker: string) {
    new TradingView.widget({
      autosize: true,
      symbol: ` ${ticker}`,
      interval: 'D',
      timezone: 'Etc/UTC',
      theme: 'light',
      style: '1',
      locale: 'in',
      enable_publishing: false,
      allow_symbol_change: true,
      container_id: 'tradingview_e173a',
    });
  }

  iconchange(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  returnLogin() {
    let loign = localStorage.getItem('loggedInUser');
    if (!loign) {
      this.showloginWarning = true;
    }
  }

  selectPriceRange(selectedRange: any) {
    if (selectedRange === 'customRange') {
      this.selectedPriceRange = selectedRange;
      this.isCustomRangeSelected = true;
      this.showpriceDropDown = false;

      this.isDropdownOpen['customRange'] = true;
    } else {
      if (this.selectedPriceRange === selectedRange) {
        this.selectedPriceRange = null;
      } else {
        this.selectedPriceRange = selectedRange;
      }
    }
  }

  isSelected(selectedRange: any) {
    return this.selectedPriceRange === selectedRange;
  }

  isSelectedAnyCheckbox(): boolean {
    return this.selectlist.some((item) => item.checked);
  }

  toggleDropdown(key: string): void {
    this.isDropdownOpen[key] = !this.isDropdownOpen[key];
  }

  selectAll() {
    if (!this.disableDeselect) {
      this.selectlist.forEach((item) => (item.checked = true));
    }
  }

  deselectAll() {
    if (!this.disableDeselect) {
      this.selectlist.forEach((item) => (item.checked = false));
    }
  }
  toggleSelectAll(event: any) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.selectAll();
    } else {
      this.deselectAll();
    }
    this.toggleDropdown('Phase');
  }

  updateCustomRange() {
    if (
      this.minCustomValue &&
      this.maxCustomValue
    ) {
      this.selectedPriceRange = `${this.minCustomValue}$ to ${this.maxCustomValue}$`;
      this.selectedDataColor = 'selected-button'
    } else {
      this.selectedPriceRange = null;
      this.selectedDataColor = '';
    }
  }
  pushFilterminPriceless(data: any) {
    this.filterArray.push({ close: 'less_than_1' });
    this.selectPriceRange(data);
  }

  pushFilterminPrice1t02(data: any) {
    this.filterArray.push({ startPrice: 1 }, { endPrice: 2 });
    this.selectPriceRange(data);
  }

  pushFilterminPrice2t05(data: any) {
    this.filterArray.push({ startPrice: 2 }, { endPrice: 5 });
    this.selectPriceRange(data);
  }
  pushFilterminPrice5t50(data: any) {
    this.filterArray.push({ startPrice: 5 }, { endPrice: 50 });
    this.selectPriceRange(data);
  }
  pushFilterminPrice50t100(data: any) {
    this.filterArray.push({ startPrice: 50 }, { endPrice: 100 });
    this.selectPriceRange(data);
  }

  //pricechange filter

  pushFilterminChangePriceless(data: any) {
    this.filterArray.push({ change: 'less_than_1' });
    this.selectChangePriceRange(data);
  }

  pushFilterminChangePrice1t02(data: any) {
    this.filterArray.push({ startChange: 1 }, { endChange: 2 });
    this.selectChangePriceRange(data);
  }

  pushFilterminChangePrice2t05(data: any) {
    this.filterArray.push({ startChange: 2 }, { endChange: 5 });
    this.selectChangePriceRange(data);
  }
  pushFilterminChangePrice5t50(data: any) {
    this.filterArray.push({ startChange: 5 }, { endChange: 50 });
    this.selectChangePriceRange(data);
  }
  pushFilterminChangePrice50t100(data: any) {
    this.filterArray.push({ startChange: 50 }, { endChange: 100 });
    this.selectChangePriceRange(data);
  }

  updateChangePriceCustomRange() {
    if (
      this.minCustomchange &&
      this.maxCustomchange
    ) {
      this.selectedChangePriceRange = `${this.minCustomchange}% to ${this.maxCustomchange}%`;
      this.selectedchangeColor = 'selected-button';
    } else {
      this.selectedChangePriceRange = null;
      this.selectedchangeColor = '';
    }
  }

  ChangeSelected(selectedRange: any) {
    return this.selectedChangePriceRange === selectedRange;
  }

  selectChangePriceRange(selectedRange: string) {
    if (selectedRange === 'customRangechange') {
      this.selectedChangePriceRange = selectedRange;
      this.isCustomChangePriceRangeSelected = true;
      this.showchangepriceDropDown = false;
      this.isDropdownOpen['customRangechange'] = true;
    } else {
      if (this.selectedChangePriceRange === selectedRange) {
        this.selectedChangePriceRange = null;
      } else {
        this.selectedChangePriceRange = selectedRange;
      }
      this.toggleDropdown('changeInPrice');
    }
  }

  //EPS

  EPSfilterless(data: any) {
    this.filterArray.push({ eps: 'less_than_1' });
    this.selectEPSRange(data);
  }

  EPSfilter1t02(data: any) {
    this.filterArray.push({ start_eps: 1 }, { end_eps: 2 });
    this.selectEPSRange(data);
  }

  EPSfilter2t05(data: any) {
    this.filterArray.push({ start_eps: 2 }, { end_eps: 5 });
    this.selectEPSRange(data);
  }

  EPSfilter5t50(data: any) {
    this.filterArray.push({ start_eps: 5 }, { end_eps: 50 });
    this.selectEPSRange(data);
  }

  EPSfilter50t100(data: any) {
    this.filterArray.push({ start_eps: 50 }, { end_eps: 100 });
    this.selectEPSRange(data);
  }

  selectEPSRange(selectedRange: string) {
    if (selectedRange === 'customRangeEPS') {
      this.selectedEPSRange = selectedRange;
      this.isCustomEPSRangeSelected = true;

      this.isEPSDropdownOpen = false;
      this.isDropdownOpen['customRangeEPS'] = true;
    } else {
      if (this.selectedEPSRange === selectedRange) {
        this.selectedEPSRange = null;
      } else {
        this.selectedEPSRange = selectedRange;
      }
      this.toggleDropdown('EPS');
    }
  }

  EPSSelected(selectedRange: any) {
    return this.selectedEPSRange === selectedRange;
  }

  updateCustomRangeEPS() {
    if (
      this.minCustomValue  &&
      this.maxCustomValue 
    ) {
      this.selectedEPSRange = `${this.minCustomValue}$ to ${this.maxCustomValue}$`;
    }
  }

  //marketcap

  marcketcap200more(data: any) {
    this.filterArray.push({ market_cap: '200b_or_more' });
    this.selectMarketCapRange(data);
  }

  marcketcap10t0200(data: any) {
    this.filterArray.push({ market_cap: '10b_to_200b' });
    this.selectMarketCapRange(data);
  }

  marcketcap2t010(data: any) {
    this.filterArray.push({ market_cap: '2b_to_10b' });
    this.selectMarketCapRange(data);
  }

  marcketcap250to2(data: any) {
    this.filterArray.push({ market_cap: '250m_to_20b' });
    this.selectMarketCapRange(data);
  }

  marcketcap250less(data: any) {
    this.filterArray.push({ market_cap: '250m_or_less' });
    this.selectMarketCapRange(data);
  }

  selectMarketCapRange(selectedRange: string) {
    if (selectedRange === 'customRangemarketcap') {
      this.selectedMarketCapRange = selectedRange;
      this.isCustommarketcapRangeSelected = true;

      this.isMarketcapDropdownOpen = false;
      this.isDropdownOpen['customRangemarketcap'] = true;
    } else {
      if (this.selectedMarketCapRange === selectedRange) {
        this.selectedMarketCapRange = null;
      } else {
        this.selectedMarketCapRange = selectedRange;
      }
    }
  }

  updateMarketCapCustomRange() {
    if (
      this.minCustomMarket &&
      this.maxCustomMarket
    ) {
      this.selectedMarketCapRange = `$${this.minCustomMarket}m to $${this.maxCustomMarket}m`;
      this.selectedmarketColor = 'selected-button';
    } else {
      this.selectedMarketCapRange = null;
      this.selectedmarketColor = '';
    }
  }

  updateEpsCustomRange(){
    if (
      this.minCustomEps &&
      this.maxCustomEps
    ) {
      this.selectedEPSRange = `${this.minCustomEps}$ to ${this.maxCustomEps}$`;
      this.selectedepsColor = 'selected-button';
    } else {
      this.selectedEPSRange = null;
      this.selectedepsColor = '';
    }
  }

  updateBurnRateCustomRange(){
    if (
      this.minCustomburn_rate &&
      this.maxCustomburn_rate
    ) {
      this.selectedBurnRateRange = `$${this.minCustomburn_rate}m to $${this.maxCustomburn_rate}m`;
      this.selectedbutn_rateColor = 'selected-button';
    } else {
      this.selectedBurnRateRange = null;
      this.selectedbutn_rateColor = '';
    }
  }

  updateVolumeCustomRange(){
    if (
      this.minCustomrvolume &&
      this.maxCustomrvolume
    ) {
      this.selectedVolumeRange = `$${this.minCustomrvolume}m to $${this.maxCustomrvolume}m`;
      this.selectedvolumeColor = 'selected-button';
    } else {
      this.selectedVolumeRange = null;
      this.selectedvolumeColor = '';
    }
  }

  updatePeCustomRange(){
    if (
      this.minCustomrpe &&
      this.maxCustomrpe
    ) {
      this.selectedPERange = `${this.minCustomrpe} to ${this.maxCustomrpe}`;
      this.selectedpeColor = 'selected-button';
    } else {
      this.selectedPERange = null;
      this.selectedpeColor = '';
    }
  }

  updateLiveCashCustomRange(){
    if (
      this.minCustomlive_cash &&
      this.maxCustomlive_cash
    ) {
      this.selectedLiveCashRange = `$${this.minCustomlive_cash}m to $${this.maxCustomlive_cash}m`;
      this.selectedlive_cashColor = 'selected-button';
    } else {
      this.selectedLiveCashRange = null;
      this.selectedlive_cashColor = '';
    }
  }

  updateRunwayCustomRange(){
    if (
      this.minCustomrunway &&
      this.maxCustomrunway
    ) {
      this.selectedRunwayRange = `${this.minCustomrunway} Years to ${this.maxCustomrunway} Years`;
      this.selectedrunwayColor = 'selected-button';
    } else {
      this.selectedRunwayRange = null;
      this.selectedrunwayColor = '';
    }
  }

  MarketCapSelected(selectedRange: any) {
    return this.selectedMarketCapRange === selectedRange;
  }

  pricefilterclick() {
    if (this.isDropdownOpen['customRange'] === true) {
      this.showpriceDropDown = true;
      this.isCustomRangeSelected = false;
    } else {
      this.showpriceDropDown = true;
    }
    this.isCustomEPSRangeSelected = false;
    this.isCustomChangePriceRangeSelected = false;
    this.isMarketcapDropdownOpen = false;
  }

  changefilterclick() {
    if (this.isDropdownOpen['customRangechange'] === true) {
      this.showchangepriceDropDown = true;
      this.isCustomChangePriceRangeSelected = false;
    } else {
      this.showchangepriceDropDown = true;
    }
    this.isCustomRangeSelected = false;
    this.isCustomEPSRangeSelected = false;
    this.isCustommarketcapRangeSelected = false;
  }

  EPSfilterclick() {
    if (this.isDropdownOpen['customRangeEPS'] === true) {
      this.isEPSDropdownOpen = true;
      this.isCustomEPSRangeSelected = false;
    } else {
      this.isEPSDropdownOpen = true;
    }
    this.isCustomRangeSelected = false;
    this.isCustomChangePriceRangeSelected = false;
    this.isCustommarketcapRangeSelected = false;
  }

  marketcapfilterclick() {
    if (this.isDropdownOpen['customRangemarketcap'] === true) {
      this.isMarketcapDropdownOpen = true;
      this.isCustommarketcapRangeSelected = false;
    } else {
      this.isMarketcapDropdownOpen = true;
    }
    this.isCustomRangeSelected = false;
    this.isCustomChangePriceRangeSelected = false;
    this.isCustomEPSRangeSelected = false;
  }

  Biotechnology(data: any) {
    this.filterArray.push({ Industry: this.BiotechnologyData });
    this.selectIndustryRange(data);
  }

  MedicalDevice(data: any) {
    this.filterArray.push({ Industry: this.MedicalDeviceData });
    this.selectIndustryRange(data);
  }

  Diagnostics(data: any) {
    this.filterArray.push({ Industry: this.DiagnosticsData });
    this.selectIndustryRange(data);
  }

  DrugManufacturers(data: any) {
    this.filterArray.push({ Industry: this.DrugData });
    this.selectIndustryRange(data);
  }

  selectIndustryRange(selectedRange: string) {
    if (this.selectedIndustryRange === selectedRange) {
      this.selectedIndustryRange = null;
    } else {
      this.selectedIndustryRange = selectedRange;
    }
  }

  Industryfilterclick() {
    this.isIndustrydownOpen = true;
    this.isMarketcapDropdownOpen = false;
    this.isCustomRangeSelected = false;
    this.isCustomEPSRangeSelected = false;
    this.isCustomChangePriceRangeSelected = false;
  }

  IndustrySelected(selectedRange: any) {
    return this.selectedIndustryRange === selectedRange;
  }

  selectedData(data: string) {
    switch (data) {
      case 'less_than_1 $': {
        this.selectedPriceRange = null;
        this.selectedValue = 'less_than_1 $';
        this.selectedDataColor = 'selected-button';
        this.filterArray.push({ close: 'less_than_1' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].startPrice;
          delete this.filterArray[key].endPrice;
        }
        this.saveFilters();
        break;
      }
      case '1$ to 2$': {
        this.selectedPriceRange = null;
        this.selectedValue = '1$ to 2$';
        this.selectedDataColor = 'selected-button';
        this.filterArray.push({ startPrice: 1 }, { endPrice: 2 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].close;
        }
        this.saveFilters();
        break;
      }
      case '2$ to 5$': {
        this.selectedPriceRange = null;
        this.selectedValue = '2$ to 5$';
        this.selectedDataColor = 'selected-button';
        this.filterArray.push({ startPrice: 2 }, { endPrice: 5 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].close;
        }
        this.saveFilters();
        break;
      }
      case '5$ to 50$': {
        this.selectedPriceRange = null;
        this.selectedValue = '5$ to 50$';
        this.selectedDataColor = 'selected-button';
        this.filterArray.push({ startPrice: 5 }, { endPrice: 50 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].close;
        }
        this.saveFilters();
        break;
      }
      case '50$ to 100$': {
        this.selectedPriceRange = null;
        this.selectedValue = '50$ to 100$';
        this.selectedDataColor = 'selected-button';
        this.filterArray.push({ startPrice: 50 }, { endPrice: 100 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].close;
        }
        this.saveFilters();
        break;
      }
      case 'less_than_1 $ E': {
        this.selectedEPSRange = null;
        this.selectedValueineps = 'less_than_1 $ E';
        this.selectedepsColor = 'selected-button';
        this.filterArray.push({ eps: 'less_than_1' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].start_eps;
          delete this.filterArray[key].end_eps;
        }
        this.saveFilters();
        break;
      }
      case '1$ to 2$ E': {
        this.selectedEPSRange = null;
        this.selectedValueineps = '1$ to 2$ E';
        this.selectedepsColor = 'selected-button';
        this.filterArray.push({ start_eps: 1 }, { end_eps: 2 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].eps;
        }
        this.saveFilters();
        break;
      }
      case '2$ to 5$ E': {
        this.selectedEPSRange = null;
        this.selectedValueineps = '2$ to 5$ E';
        this.selectedepsColor = 'selected-button';
        this.filterArray.push({ start_eps: 2 }, { end_eps: 5 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].eps;
        }
        this.saveFilters();
        break;
      }
      case '5$ to 50$ E': {
        this.selectedEPSRange = null;
        this.selectedValueineps = '5$ to 50$ E';
        this.selectedepsColor = 'selected-button';
        this.filterArray.push({ start_eps: 5 }, { end_eps: 50 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].eps;
        }
        this.saveFilters();
        break;
      }
      case '50$ to 100$ E': {
        this.selectedEPSRange = null;
        this.selectedValueineps = '50$ to 100$ E';
        this.selectedepsColor = 'selected-button';
        this.filterArray.push({ start_eps: 50 }, { end_eps: 100 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].eps;
        }
        this.saveFilters();
        break;
      }
      case 'less_than_1 %': {
        this.selectedChangePriceRange = null;
        this.selectedValueinchange = 'less_than_1 %';
        this.selectedchangeColor = 'selected-button';
        this.filterArray.push({ change: 'less_than_1' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].startChange;
          delete this.filterArray[key].endChange;
        }
        this.saveFilters();
        break;
      }
      case '1% to 2%': {
        this.selectedChangePriceRange = null;
        this.selectedValueinchange = '1% to 2%';
        this.selectedchangeColor = 'selected-button';
        this.filterArray.push({ startChange: 1 }, { endChange: 2 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].change;
        }
        this.saveFilters();
        break;
      }
      case '2% to 5%': {
        this.selectedChangePriceRange = null;
        this.selectedValueinchange = '2% to 5%';
        this.selectedchangeColor = 'selected-button';
        this.filterArray.push({ startChange: 2 }, { endChange: 5 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].change;
        }
        this.saveFilters();
        break;
      }
      case '5% to 10%': {
        this.selectedChangePriceRange = null;
        this.selectedValueinchange = '5% to 10%';
        this.selectedchangeColor = 'selected-button';
        this.filterArray.push({ startChange: 5 }, { endChange: 10 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].change;
        }
        this.saveFilters();
        break;
      }
      case '10% to 50%': {
        this.selectedChangePriceRange = null;
        this.selectedValueinchange = '10% to 50%';
        this.selectedchangeColor = 'selected-button';
        this.filterArray.push({ startChange: 10 }, { endChange: 50 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].change;
        }
        this.saveFilters();
        break;
      }
      case '200b_or_more': {
        this.selectedMarketCapRange = null;
        this.selectedValueinmarket_cap = '200b_or_more';
        this.selectedmarketColor = 'selected-button';
        this.filterArray.push({ market_cap: '200b_or_more' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].start_market_cap;
          delete this.filterArray[key].end_market_cap;
        }
        this.saveFilters();
        break;
      }
      case '10b_to_200b': {
        this.selectedMarketCapRange = null;
        this.selectedValueinmarket_cap = '10b_to_200b';
        this.selectedmarketColor = 'selected-button';
        this.filterArray.push({ market_cap: '10b_to_200b' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].start_market_cap;
          delete this.filterArray[key].end_market_cap;
        }
        this.saveFilters();
        break;
      }
      case '2b_to_10b': {
        this.selectedMarketCapRange = null;
        this.selectedValueinmarket_cap = '2b_to_10b';
        this.selectedmarketColor = 'selected-button';
        this.filterArray.push({ market_cap: '2b_to_10b' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].start_market_cap;
          delete this.filterArray[key].end_market_cap;
        }
        this.saveFilters();
        break;
      }
      case '250m_to_2b': {
        this.selectedMarketCapRange = null;
        this.selectedValueinmarket_cap = '250m_to_2b';
        this.selectedmarketColor = 'selected-button';
        this.filterArray.push({ market_cap: '250m_to_2b' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].start_market_cap;
          delete this.filterArray[key].end_market_cap;
        }
        this.saveFilters();
        break;
      }
      case '250m_or_less': {
        this.selectedMarketCapRange = null;
        this.selectedValueinmarket_cap = '250m_or_less';
        this.selectedmarketColor = 'selected-button';
        this.filterArray.push({ market_cap: '250m_or_less' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].start_market_cap;
          delete this.filterArray[key].end_market_cap;
        }
        this.saveFilters();
        break;
      }
      case '-5b_negative': {
        this.selectedBurnRateRange = null;
        this.selectedValueinburn_rate = '-5b_negative';
        this.selectedbutn_rateColor = 'selected-button';
        this.filterArray.push({ end_br: -5000 * 1000000 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].start_br;
        }
        this.saveFilters();
        break;
      }
      case '-500m_-5b': {
        this.selectedBurnRateRange = null;
        this.selectedValueinburn_rate = '-500m_-5b';
        this.selectedbutn_rateColor = 'selected-button';
        this.filterArray.push(
          { start_br: -5000 * 1000000 },
          { end_br: -500 * 1000000 }
        );
        this.saveFilters();
        break;
      }
      case '-10m_-500m': {
        this.selectedBurnRateRange = null;
        this.selectedValueinburn_rate = '-10m_-500m';
        this.selectedbutn_rateColor = 'selected-button';
        this.filterArray.push(
          { start_br: -500 * 1000000 },
          { end_br: -10 * 1000000 }
        );
        this.saveFilters();
        break;
      }
      case '-10m_500m': {
        this.selectedBurnRateRange = null;
        this.selectedValueinburn_rate = '-10m_500m';
        this.selectedbutn_rateColor = 'selected-button';
        this.filterArray.push(
          { start_br: -10 * 1000000 },
          { end_br: 500 * 1000000 }
        );
        this.saveFilters();
        break;
      }
      case '500m_5b': {
        this.selectedBurnRateRange = null;
        this.selectedValueinburn_rate = '500m_5b';
        this.selectedbutn_rateColor = 'selected-button';
        this.filterArray.push(
          { start_br: 500 * 1000000 },
          { end_br: 5000 * 1000000 }
        );
        this.saveFilters();
        break;
      }
      case '5b_more': {
        this.selectedBurnRateRange = null;
        this.selectedValueinburn_rate = '5b_more';
        this.selectedbutn_rateColor = 'selected-button';
        this.filterArray.push({ start_br: 5000 * 1000000 });
        this.saveFilters();
        break;
      }

      //--------------------------------------------------      runway         -----------------------------------------------------------------
      case 'less_than_6m': {
        this.selectedRunwayRange = null;
        this.selectedValueinrunway = 'less_than_6m';
        this.selectedrunwayColor = 'selected-button';
        this.filterArray.push({ end_runway_month: 6 });

        for (let key in this.filterArray) {
          delete this.filterArray[key].start_runway_month;
        }
        this.saveFilters();
        break;
      }
      case '1y_to_2y': {
        this.selectedRunwayRange = null
        this.selectedValueinrunway = '1y_to_2y';
        this.selectedrunwayColor = 'selected-button';
        this.filterArray.push(
          { start_runway_month: 12 },
          { end_runway_month: 24 }
        );
        this.saveFilters();
        break;
      }
      case '2_to_5y': {
        this.selectedRunwayRange = null;
        this.selectedValueinrunway = '2_to_5y';
        this.selectedrunwayColor = 'selected-button';
        this.filterArray.push(
          { start_runway_month: 24 },
          { end_runway_month: 60 }
        );
        this.saveFilters();
        break;
      }
      case '5_to_10y': {
        this.selectedRunwayRange = null;
        this.selectedValueinrunway = '5_to_10y';
        this.selectedrunwayColor = 'selected-button';
        this.filterArray.push(
          { start_runway_month: 60 },
          { end_runway_month: 120 }
        );
        this.saveFilters();
        break;
      }
      case 'morethan_10y': {
        this.selectedRunwayRange = null;
        this.selectedValueinrunway = 'morethan_10y';
        this.selectedrunwayColor = 'selected-button';
        this.filterArray.push({ start_runway_month: 120 });
        this.saveFilters();
        break;
      }
      //--------------------------------------------------      Live-Cash         -----------------------------------------------------------------

      case '200b_more L': {
        this.selectedLiveCashRange = null;
        this.selectedValueinlive_cash = '200b_more L';
        this.selectedlive_cashColor = 'selected-button';
        this.filterArray.push({ start_live_cash: 200000 * 1000000 });
        for (let key in this.filterArray) {
          delete this.filterArray[key].end_live_cash;
        }
        this.saveFilters();
        break;
      }
      case '10b_200b L': {
        this.selectedLiveCashRange = null;
        this.selectedValueinlive_cash = '10b_200b L';
        this.selectedlive_cashColor = 'selected-button';
        this.filterArray.push(
          { start_live_cash: 10000 * 1000000 },
          { end_live_cash: 200000 * 1000000 }
        );
        this.saveFilters();
        break;
      }
      case '2b_10b L': {
        this.selectedLiveCashRange = null;
        this.selectedValueinlive_cash = '2b_10b L';
        this.selectedlive_cashColor = 'selected-button';
        this.filterArray.push(
          { start_live_cash: 10000 * 200000 },
          { end_live_cash: 10000 * 1000000 }
        );
        this.saveFilters();
        break;
      }
      case '250m_2b L': {
        this.selectedLiveCashRange = null;
        this.selectedValueinlive_cash = '250m_2b L';
        this.selectedlive_cashColor = 'selected-button';
        this.filterArray.push(
          { start_live_cash: 250 * 1000000 },
          { end_live_cash: 10000 * 200000 }
        );
        this.saveFilters();
        break;
      }
      case 'less_than_250m L': {
        this.selectedLiveCashRange = null;
        this.selectedValueinlive_cash = 'less_than_250m L';
        this.selectedlive_cashColor = 'selected-button';
        this.filterArray.push({ end_live_cash: 250 * 1000000 });
        this.saveFilters();
        break;
      }

      //--------------------------------------------------      pe         -----------------------------------------------------------------

      // case 'Negative': {
      //   this.selectedValueinpe = "Negative"
      //   this.selectedpeColor = "selected-button"
      //   this.filterArray.push({ start_pe: -10 });

      //   for (let key in this.filterArray) {
      //     delete this.filterArray[key].end_pe;
      //   }

      //   break
      // }

      case '1_20': {
        this.selectedPERange = null;
        this.selectedValueinpe = '1_20';
        this.selectedpeColor = 'selected-button';
        this.filterArray.push({ start_pe: 1 }, { end_pe: 2 });
        this.saveFilters();
        break;
      }
      case '20_100': {
        this.selectedPERange = null;
        this.selectedValueinpe = '20_100';
        this.selectedpeColor = 'selected-button';
        this.filterArray.push({ start_pe: 20 }, { end_pe: 100 });
        this.saveFilters();
        break;
      }
      case '100_500': {
        this.selectedPERange = null;
        this.selectedValueinpe = '100_500';
        this.selectedpeColor = 'selected-button';
        this.filterArray.push({ start_pe: 100 }, { end_pe: 500 });
        this.saveFilters();
        break;
      }

      //--------------------------------------------------      volume         -----------------------------------------------------------------

      case 'less_than_0.1m': {
        this.selectedVolumeRange = null;
        this.selectedValueinvolume = 'less_than_0.1m';
        this.selectedvolumeColor = 'selected-button';
        this.filterArray.push({ end_volume: 10 * 10000 });

        for (let key in this.filterArray) {
          delete this.filterArray[key].start_volume;
        }
        this.saveFilters();
        break;
      }
      case '0.1m_10m': {
        this.selectedVolumeRange = null;
        this.selectedValueinvolume = '0.1m_10m';
        this.selectedvolumeColor = 'selected-button';
        this.filterArray.push(
          { start_volume: 10 * 10000 },
          { end_volume: 100 * 100000 }
        );
        this.saveFilters();
        break;
      }
      case '10m_100m': {
        this.selectedVolumeRange = null;
        this.selectedValueinvolume = '10m_100m';
        this.selectedvolumeColor = 'selected-button';
        this.filterArray.push(
          { start_volume: 100 * 100000 },
          { end_volume: 100 * 1000000 }
        );
        this.saveFilters();
        break;
      }
      case '100m_200m': {
        this.selectedVolumeRange = null;
        this.selectedValueinvolume = '100m_200m';
        this.selectedvolumeColor = 'selected-button';
        this.filterArray.push(
          { start_volume: 100 * 1000000 },
          { end_volume: 100 * 2000000 }
        );
        this.saveFilters();
        break;
      }
      case 'more_than_200m': {
        this.selectedVolumeRange = null;
        this.selectedValueinvolume = 'more_than_200m';
        this.selectedvolumeColor = 'selected-button';
        this.filterArray.push({ start_volume: 100 * 2000000 });

        for (let key in this.filterArray) {
          delete this.filterArray[key].end_volume;
        }
        this.saveFilters();
        break;
      }

      case 'Biotechnology': {
        this.selectedindustryColor = 'selected-button';
        this.selectedValueinindustry = 'Biotechnology';
        const Biotechnology = [
          'Biotechnology',
          'Tobacco',
          'Real Estate Services',
          'Chemicals',
          ' N/A',
          'Farm Products',
          'Food Distribution',
          'Packaged Foods',
        ];
        this.filterArray.push({ Industry: Biotechnology });
        this.saveFilters();
        break;
      }
      case 'Medical Device': {
        this.selectedindustryColor = 'selected-button';
        this.selectedValueinindustry = 'Medical Device';
        const Medical_Device = [
          'Medical Devices',
          'Medical Instruments & Supplies',
          'Electronic Components',
          'Scientific & Technical Instruments',
          'Health Information Services',
          'Medical Care Facilities',
          'Furnishings',
          'Fixtures & Appliances',
          'Medical Distribution',
        ];
        this.filterArray.push({ Industry: Medical_Device });
        this.saveFilters();
        break;
      }
      case 'Diagnostics & Research': {
        this.selectedindustryColor = 'selected-button';
        this.selectedValueinindustry = 'Diagnostics & Research';
        const Diagnostics = ['Diagnostics & Research'];
        this.filterArray.push({ Industry: Diagnostics });
        this.saveFilters();
        break;
      }
      case 'Drug Manufacturers': {
        this.selectedindustryColor = 'selected-button';
        this.selectedValueinindustry = 'Drug Manufacturers';

        const Drug_Manufacturers = [
          'Drug Manufacturers—General',
          'Drug Manufacturers—Specialty & Generic',
          'Household & Personal Products,Pharmaceutical Retailers',
        ];
        this.filterArray.push({ Industry: Drug_Manufacturers });
        this.saveFilters();
        break;
      }
    }
  }

  clearSelectedPrice() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].close;
      delete this.filterArray[key].startPrice;
      delete this.filterArray[key].endPrice;
    }

    this.selectedPriceRange = null;
    this.minCustomValue = 0;
    this.maxCustomValue = 0;
    this.selectedDataColor = '';
    this.selectedValue = '';

    
    if(this.isFilter){
      if(this.filterArray.every((item) => Object.keys(item).length === 0)){
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedChangePrice() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].change;
      delete this.filterArray[key].startChange;
      delete this.filterArray[key].endChange;
    }

    this.selectedChangePriceRange = null;
    this.minCustomchange = 0;
    this.maxCustomchange = 0;
    this.selectedchangeColor = '';
    this.selectedValueinchange = '';

    
    if(this.isFilter){
      if(this.filterArray.every((item) => Object.keys(item).length === 0)){
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedEPS() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].eps;
      delete this.filterArray[key].start_eps;
      delete this.filterArray[key].end_eps;
    }

    this.selectedEPSRange = null;
    this.maxCustomEps = 0;
    this.minCustomEps = 0;
    this.selectedValueineps = '';
    this.selectedepsColor = '';

    
    if(this.isFilter){
      if(this.filterArray.every((item) => Object.keys(item).length === 0)){
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedvolume() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].start_volume;
      delete this.filterArray[key].end_volume;
    }

    this.selectedVolumeRange = null;
    this.minCustomrvolume = 0;
    this.maxCustomrvolume = 0;
    this.selectedValueinvolume = '';
    this.selectedvolumeColor = '';

    
    if(this.isFilter){
      if(this.filterArray.every((item) => Object.keys(item).length === 0)){
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }
  clearSelectedpe() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].start_pe;
      delete this.filterArray[key].end_pe;
    }

    this.selectedPERange = null;
    this.minCustomrpe = 0;
    this.maxCustomrpe = 0;
    this.selectedValueinpe = '';
    this.selectedpeColor = '';

    
    if(this.isFilter){
      if(this.filterArray.every((item) => Object.keys(item).length === 0)){
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }
  clearSelectedBurn_rate() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].start_br;
      delete this.filterArray[key].end_br;
    }

    this.selectedBurnRateRange = null; 
    this.maxCustomburn_rate = 0;
    this.minCustomburn_rate = 0;
    this.selectedValueinburn_rate = '';
    this.selectedbutn_rateColor = '';

    
    if(this.isFilter){
      if(this.filterArray.every((item) => Object.keys(item).length === 0)){
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }
  clearSelectedLive_cash() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].start_live_cash;
      delete this.filterArray[key].end_live_cash;
    }

    this.selectedLiveCashRange = null;
    this.maxCustomlive_cash = 0;
    this.minCustomlive_cash = 0;
    this.selectedValueinlive_cash = '';
    this.selectedlive_cashColor = '';

    
    if(this.isFilter){
      if(this.filterArray.every((item) => Object.keys(item).length === 0)){
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedrunway() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].start_runway_month;
      delete this.filterArray[key].end_runway_month;
    }

    this.selectedRunwayRange = null;
    this.minCustomrunway = 0;
    this.maxCustomrunway = 0;
    this.selectedValueinrunway = '';
    this.selectedrunwayColor = '';

    
    if(this.isFilter){
      if(this.filterArray.every((item) => Object.keys(item).length === 0)){
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedIndustry() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].Industry;
    }
    this.selectedindustryColor = '';
    this.selectedValueinindustry = '';
    
    if(this.isFilter){
      if(this.filterArray.every((item) => Object.keys(item).length === 0)){
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  clearSelectedMarketCap() {
    for (let key in this.filterArray) {
      delete this.filterArray[key]?.market_cap;
      delete this.filterArray[key]?.end_market_cap;
      delete this.filterArray[key]?.start_market_cap;
    }

    this.selectedMarketCapRange = null;
    this.maxCustomMarket = 0;
    this.minCustomMarket = 0;
    this.selectedmarketColor = '';
    this.selectedValueinmarket_cap = '';

    
    if(this.isFilter){
      if(this.filterArray.every((item) => Object.keys(item).length === 0)){
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  pushFilterminPrice(data: any) {
    // this.selectedDataColor = 'selected-button';
    this.filterArray.push({ startPrice: Number(data.target.value) });
  }

  pushFiltermaxPrice(data: any) {
    // this.selectedDataColor = 'selected-button';
    this.filterArray.push({ endPrice: Number(data.target.value) });
  }

  pushFilterChange(data: any) {
    // this.selectedchangeColor = 'selected-button';
    this.filterArray.push({ startChange: Number(data.target.value) });
  }

  EndpushFilterChange(data: any) {
    // this.selectedchangeColor = 'selected-button';
    this.filterArray.push({ endChange: Number(data.target.value) });
  }

  pushFilterstartEPS(data: any) {
    // this.selectedepsColor = 'selected-button';
    this.filterArray.push({ start_eps: Number(data.target.value) });
  }

  pushFilterendEPS(data: any) {
    // this.selectedepsColor = 'selected-button';
    this.filterArray.push({ end_eps: Number(data.target.value) });
  }

  pushFilterMarket_cap(data: any) {
    for (let key in this.filterArray) {
      delete this.filterArray[key].market_cap;
    }
    // this.selectedmarketColor = 'selected-button';
    this.filterArray.push({
      start_market_cap: Number(data.target.value) * 1000000,
    });
  }

  EndpushFilterMarket_cap(data: any) {
    // this.selectedmarketColor = 'selected-button';
    this.filterArray.push({
      end_market_cap: Number(data.target.value) * 1000000,
    });
  }

  pushFilterBurn_rate(data: any) {
    // this.selectedbutn_rateColor = 'selected-button';
    this.filterArray.push({ start_br: this.minCustomburn_rate * 1000000 });
  }

  EndpushFilterBurn_rate(data: any) {
    // this.selectedbutn_rateColor = 'selected-button';
    this.filterArray.push({ end_br: this.maxCustomburn_rate * 1000000 });
  }

  pushFilterrunway(data: any) {
    // this.selectedrunwayColor = 'selected-button';
    this.filterArray.push({ start_runway_month: this.minCustomrunway });
  }

  EndpushFilterrunway(data: any) {
    // this.selectedrunwayColor = 'selected-button';
    this.filterArray.push({ end_runway_month: this.maxCustomrunway });
  }

  pushFilterlive_cash(data: any) {
    // this.selectedlive_cashColor = 'selected-button';
    this.filterArray.push({
      start_live_cash: this.minCustomlive_cash * 1000000,
    });
  }

  EndpushFilterlive_cash(data: any) {
    // this.selectedlive_cashColor = 'selected-button';
    this.filterArray.push({ end_live_cash: this.maxCustomlive_cash * 1000000 });
  }

  pushFiltervolume(data: any) {
    // this.selectedvolumeColor = 'selected-button';
    this.filterArray.push({ start_volume: this.minCustomrvolume * 1000000 });
  }

  EndpushFiltervolume(data: any) {
    // this.selectedvolumeColor = 'selected-button';
    this.filterArray.push({ end_volume: this.maxCustomrvolume * 1000000 });
  }
  pushFilterpe(data: any) {
    // this.selectedpeColor = 'selected-button';
    this.filterArray.push({ start_pe: this.minCustomrpe });
  }

  EndpushFilterpe(data: any) {
    // this.selectedpeColor = 'selected-button';
    this.filterArray.push({ end_pe: this.maxCustomrpe });
  }

  searchFilters(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;

    if (event.key === 'Escape' && !inputElement.value) {
      this.suggection = [];
      return;
    }

    if (!inputElement.value || !this.searchValuequery) {
      this.suggection = [];
      return;
    }

    this.compamyDetails.searchsuggection(inputElement.value).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res);

        const ParseDecryptedData = JSON.parse(decryptData);
        this.suggection = ParseDecryptedData;

        // this.symbol = res[0].symbol
      },
    });
  }

  onSearchInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;

    if (inputValue === '') {
      this.suggection = [];
    }
  }

  searchFiltersonclick(query: any) {
    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }
    if (query == '') {
      this.toaster.warning('Enter Valid Inputs.');
      return;
    }

    let search = {
      query,
    };
    this.suggection = [];
    this.searchValuequery = '';

    // this.isLoading = true;
    this.isFilterBtnSearch = true;
    this.forResetfilters = true;
    // this.isFilter = true

    this.csTable = [];

    this.filter.compnayfilterData(search).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);

        const ParseDecryptedData = JSON.parse(decryptData);
        this.isFilterBtnSearch = false;
        this.forResetfilters = false;
        this.totalDatalength = ParseDecryptedData[0].data.length;
        this.csTable = ParseDecryptedData[0].data as CSTable[];
        this.suggection = [];
        this.isLoading = true;
      },
      error: (err) => {
        this.isFilterBtnSearch = false;
        this.forResetfilters = false;
        this.searchValuequery = '';
        this.csTable = [];
        this.csTableData();
        this.toaster.error(err.error.message);
      },
    });
  }

  addSymboltoPotfoliyo(symbol: any) {
    let loginuser = JSON.parse(localStorage.getItem('loggedInUser') || '{}');

    let obj = {
      email: loginuser.email,
      ticker: symbol,
    };

    if (loginuser.email) {
      this.portfoliomatchTicker = symbol;

      if (obj) {
        this.fdacalendarservice.addSymboltoPotfoliyo(obj).subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res);

            const ParseDecryptedData = JSON.parse(decryptData);
            ParseDecryptedData.portfolio.forEach((element: any) => {
              this.portfoliomatchTicker.push(element.ticker);
            });
            this.toaster.success('Symbol added to your portfolio');
          },
        });
      }
    } else {
      this.modalDisplayStyle = true;
    }
  }

  removeSymboltoPotfoliyo(symbol: any) {
    let loginuser = JSON.parse(localStorage.getItem('loggedInUser') || '{}');

    let obj = {
      email: loginuser.email,
      ticker: symbol,
    };

    if (obj) {
      this.fdacalendarservice.removeSymboltoPotfoliyo(obj).subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res);
          this.portfoliomatchTicker = [];
          const ParseDecryptedData = JSON.parse(decryptData);
          ParseDecryptedData.portfolio.forEach((element: any) => {
            this.portfoliomatchTicker.push(element.ticker);
          });
          this.toaster.success('Symbol removed from your portfolio');
        },
      });
    }
  }

  onAddSymbol(data: any) {
    this.portfoliomatchTicker.push(data.ticker);
    return;
  }

  clearDiseasesCheckbox() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].Specific_Diseases;
    }

    this.selectedDiseases = [];
    this.selectedDiseasesColor = '';
    this.diseasesList.forEach((item) => (item.checked = false));
    this.isDropdownOpen['Specific_Diseases'] = false;

    
    if(this.isFilter){
      if(this.filterArray.every((item) => Object.keys(item).length === 0)){
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  toggleDiseasesSelect(event: any) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.selectDiseases();
    } else {
      this.deselectDiseases();
    }
    this.toggleDropdown('Specific_Diseases');
    this.pushFilterDiseases();
  }

  selectDiseases() {
    if (!this.disableDeselect) {
      this.diseasesList.forEach((item) => (item.checked = true));
    }
  }

  deselectDiseases() {
    if (!this.disablediseasesList) {
      this.diseasesList.forEach((item) => (item.checked = false));
    }
  }

  pushFilterDiseases() {
    this.selectedDiseases = this.diseasesList
      .filter((item) => item.checked)
      .map((item) => item.name);
    if (this.selectedDiseases.length > 0) {
      this.selectedDiseasesColor = 'selected-button';
    } else {
      this.selectedDiseasesColor = '';
    }
    this.filterArray.push({ Specific_Diseases: this.selectedDiseases });
    this.saveFilters();

  }

  clearTherapeuticCheckbox() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].Therapeutic_area;
    }

    this.selectedTherapeutic = [];
    this.selectedTherapeuticColor = '';
    this.therapeuticList.forEach((item) => (item.checked = false));
    this.isDropdownOpen['Therapeutic_area'] = false;

    
    if(this.isFilter){
      if(this.filterArray.every((item) => Object.keys(item).length === 0)){
        this.resetFilters();
      } else {
        this.saveFilters();
      }
    }
    // if (
    //   this.filterArray.every((item) => Object.keys(item).length === 0) &&
    //   this.isFilter
    // ) {
    //   this.resetFilters();
    // }
  }

  toggleTheraputics(event: any) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.selectTheraputics();
    } else {
      this.deselectTheraputics();
    }
    this.toggleDropdown('Therapeutic_area');
    this.pushFilterTherapeutic();
  }

  selectTheraputics() {
    if (!this.disableTheraputics) {
      this.therapeuticList.forEach((item) => (item.checked = true));
    }
  }

  deselectTheraputics() {
    if (!this.disableTheraputics) {
      this.therapeuticList.forEach((item) => (item.checked = false));
    }
  }

  pushFilterTherapeutic() {
    this.selectedTherapeutic = this.therapeuticList
      .filter((item) => item.checked)
      .map((item) => item.name);
    if (this.selectedTherapeutic.length > 0) {
      this.selectedTherapeuticColor = 'selected-button';
    } else {
      this.selectedTherapeuticColor = '';
    }
    this.filterArray.push({ Therapeutic_area: this.selectedTherapeutic });
    this.saveFilters();

  }
}
