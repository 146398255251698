import { Component, Input } from '@angular/core';
import { CompanyDetailsService } from '../../service/company-details.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-financial-table',
  templateUrl: './financial-table.component.html',
  styleUrl: './financial-table.component.scss',
})
export class FinancialTableComponent {

  constructor(private compamyDetails: CompanyDetailsService, private router: Router) {}
  @Input() columns: {
    name: string;
    isMultiValue?: boolean;
    isFunction?: boolean;
    isFunctionCompany?: boolean;
    valuename: string;
  }[] = [];
  @Input() data: any;
  clientUrl = environment.clientUrl;


  indiDetails(fundname: any) {
    // this.hedgeService.indivisualData(fundname, 1);
    let name = fundname.replace(/ /g, '_');

    
    this.router.navigate(['individualfund', name]);

}
companyDetails(ticker: any) {
  this.compamyDetails.getCompanyTable(ticker).subscribe({
    next: (res: any) => {
      this.router.navigate(['company', ticker]);
    },
  });
  this.compamyDetails.getCompanyTicker(ticker);
}
}
