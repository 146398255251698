import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateformate'
})
export class DateformatePipe implements PipeTransform {
  transform(value: any): any {
    const date = new Date(value).toDateString();
    return date
  }
}
