import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getNews, getPortfolioNews } from '../const';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  constructor(private http: HttpClient) {}

  getPortfolioNewsData(page: number) {
    return this.http.get(`${getPortfolioNews}${page}`);
  }

  getNewsData(newsType: string, page: number, match: string) {
    return this.http.get(
      `${getNews}?type=${newsType}&page=${page}&match=${match}`
    );
  }
}
