<section class="mb-3 section-height">
  <div class="container">
    <div class="mt-3 mb-3">
      <h1 class="main-heading text-center head-text">Elevate Your Presence</h1>
    </div>
    <p class="sub-heading text-center">
      Partner with BiopharmaWatch, the leading platform connecting investors
      with the latest in the Health Technology sector's stocks. We're your
      strategic ally for boosting visibility and sharing critical news in the
      health sector, ensuring maximum impact and reach.
    </p>
  </div>
  <div class="container">
    <div class="mt-3 mb-3">
      <h3 class="main-heading text-center head-text">Targeted Reach</h3>
    </div>
    <p class="sub-heading text-center">
      BiopharmaWatch is singularly focused on bio/pharmaceuticals, medical
      technologies, and health sciences, providing unmatched entry to a highly
      engaged investor community eager for fresh opportunities in life sciences.
      Utilize our dynamic, tailored strategies to spark significant market
      interest and precisely conduct targeted investor outreach.
    </p>
  </div>
  <div class="container">
    <div class="mt-3 mb-3">
      <h3 class="main-heading text-center head-text">Our Premier Services</h3>
    </div>
    <ul>
      <li>
        <p>
          <strong class="main-heading our-text"
            >Presence on our Platforms :
          </strong>
          Prominently showcase your brand on our specialized platform, crafted
          to attract and retain an audience deeply invested in the biotech and
          pharma sector. Benefit from our tailored features designed for maximum
          engagement and brand positioning e.g CatalystSync, Company Screener,
          News Section, and many more.
        </p>
      </li>
      <li>
        <p>
          <strong class="main-heading our-text">Daily/Weekly Digest : </strong
          >Start the day with our esteemed newsletter, a direct line to
          institutional investors, equity firms, analysts, and retailers
          ensuring your news and science top the agenda.
        </p>
      </li>
    </ul>
  </div>
  <div class="container">
    <div class="mt-3 mb-3">
      <h3 class="main-heading text-center head-text">Why Choose Us?</h3>
    </div>
    <p class="sub-heading text-center">
      BiopharmaWatch stands apart with its commitment to quality and precision.
      Our daily, double opt-in newsletter ensures a focused, investment-ready
      audience, delivering your message directly and effectively. Partner with
      us for a strategic advantage in the fast-paced health investment sector.
    </p>
  </div>
  <div class="container">
    <div class="mt-3 mb-3">
      <h3 class="main-heading text-center head-text">Want to know more?</h3>
    </div>
    <form
      [formGroup]="AdvertiseForm"
      (ngSubmit)="submit(AdvertiseForm.value)"
      class="row g-3 justify-content-center"
    >
      <div class="col-8">
        <div class="row">
          <div class="col-md-6">
            <input
              type="text"
              formControlName="name"
              class="form-control forms-input shadow-sm rounded form-space"
              id="Name"
              placeholder="Name"
            />
            @if ( AdvertiseForm.controls['name'].invalid &&
            (AdvertiseForm.controls['name'].dirty ||
            AdvertiseForm.controls['name'].touched || submitted)) {
            <div class="mb-1">
              @if (AdvertiseForm.controls['name'].errors?.['required']) {
              <span class="form-error mb-1">Name is required.</span>
              }@else {
              <span class="form-error">Enter correct name address.</span>
              }
            </div>
            }
          </div>
          <div class="col-md-6">
            <input
              type="email"
              formControlName="email"
              class="form-control forms-input shadow-sm form-space rounded"
              id="email"
              placeholder="Email"
            />

            @if ( AdvertiseForm.controls['email'].invalid &&
            (AdvertiseForm.controls['email'].dirty ||
            AdvertiseForm.controls['email'].touched || submitted)) {
            <div class="mb-1">
              @if (AdvertiseForm.controls['email'].errors?.['required']) {
              <span class="form-error mb-1">Email is required.</span>
              }@else {
              <span class="form-error">Enter correct email address.</span>
              }
            </div>
            }
          </div>
        </div>
        <div class="row form-section mt-3">
          <div class="col-md-6">
            <input
              type="text"
              formControlName="subject"
              class="form-control forms-input form-space shadow-sm rounded"
              id="inputAddress"
              placeholder="Company Name"
            />

            @if ( AdvertiseForm.controls['subject'].invalid &&
            (AdvertiseForm.controls['subject'].dirty ||
            AdvertiseForm.controls['subject'].touched || submitted)) {
            <div class="mb-1">
              @if (AdvertiseForm.controls['subject'].errors?.['required']) {
              <span class="form-error mb-1">Company Name is required.</span>
              }@else {
              <span class="form-error">Enter correct Company Name.</span>
              }
            </div>
            }
          </div>
          <div class="col-md-6">
            <input
              type="number"
              formControlName="phone"
              class="form-control forms-input form-space shadow-sm rounded"
              id="inputAddress"
              placeholder="Phone-Number"
            />
            @if (AdvertiseForm.controls['phone'].invalid &&
            (AdvertiseForm.controls['phone'].dirty ||
            AdvertiseForm.controls['phone'].touched || submitted)) {
            <div class="mb-1">
              @if (AdvertiseForm.controls['phone'].errors?.['required']) {
              <span class="form-error mb-1">Phone is required.</span>
              }@else {
              <span class="form-error">Enter correct phone address.</span>
              }
            </div>
            }
          </div>
        </div>
        <div class="row form-section mt-3">
          <div class="col-md-6">
            <input
              type="text"
              formControlName="role"
              class="form-control forms-input form-space shadow-sm rounded"
              id="inputAddress"
              placeholder="Role/Title"
            />

            @if ( AdvertiseForm.controls['role'].invalid &&
            (AdvertiseForm.controls['role'].dirty ||
            AdvertiseForm.controls['role'].touched || submitted)) {
            <div class="mb-1">
              @if (AdvertiseForm.controls['role'].errors?.['required']) {
              <span class="form-error mb-1">Role is required.</span>
              }@else {
              <span class="form-error">Enter correct role address.</span>
              }
            </div>
            }
          </div>
        </div>
        <div class="col-12 form-section mt-3 mb-3">
          <textarea
            class="form-control forms-input shadow-sm form-space rounded"
            id="inputAddress"
            formControlName="feedback"
            placeholder="Inquiry Details/Comments"
          ></textarea>
          @if ( AdvertiseForm.controls['feedback'].invalid &&
          (AdvertiseForm.controls['feedback'].dirty ||
          AdvertiseForm.controls['feedback'].touched || submitted)) {
          <div class="mb-1">
            @if (AdvertiseForm.controls['feedback'].errors?.['required']) {
            <span class="form-error mb-1">Feedback is required.</span>
            }@else {
            <span class="form-error">Enter correct feedback address.</span>
            }
          </div>
          }
        </div>
        <div class="text-center">
          <button type="submit" class="btn submit-btn shadow-lg">Submit</button>
        </div>
      </div>
    </form>
  </div>
</section>
