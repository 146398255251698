<div>
  <div class="progress" [class]="stageBlank()">

    @if (bar40) {
    <div class="progress-bar bg-warning progress-bar-striped" role="progressbar" style="width: 40px" aria-valuenow="40"
      aria-valuemin="0" aria-valuemax="100">
      40%
    </div>

    }

    @if (bar10) {
    <div class="progress-bar bg-danger progress-bar-striped" role="progressbar" style="width: 20px; " aria-valuenow="10"
      aria-valuemin="0" aria-valuemax="100">
      10%
    </div>
    }

    @if (bar20) {
    <div class="progress-bar bg-danger progress-bar-striped" role="progressbar" style="width: 30px; " aria-valuenow="20"
      aria-valuemin="0" aria-valuemax="100">
      20%
    </div>
    }

    @if (NotApplicable) {
    <div> - </div>
    }

    @if (bar60) {
    <div class="progress-bar bg-info progress-bar-striped" role="progressbar" style="width: 60px" aria-valuenow="60"
      aria-valuemin="0" aria-valuemax="100">
      60%
    </div>
    }

    @if (bar80) {
    <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 80px" aria-valuenow="80"
      aria-valuemin="0" aria-valuemax="100">
      80%
    </div>
    }

    @if (bar100) {
    <div class="progress-bar bg-success progress-bar-striped" role="progressbar" style="width: 100%" aria-valuenow="100"
      aria-valuemin="0" aria-valuemax="100">
      100%
    </div>
    }

  </div>
</div>