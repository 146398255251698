<div class="row">
  <div class="col-lg-6">
    <h2 class="company-detail-list text-start">Volume Totals by Expiry</h2>
    <hr class="mb-3" />
    <div class="d-flex btn-space">
      <button
        class="optionbtn me-2"
        [ngClass]="{
          optionbtnactive: selectedOptionType === 'call',
          optionnotactive: selectedOptionType == 'put,callput'
        }"
        (click)="callupdateOption('call')"
      >
        <p class="m-0">Call</p>
      </button>
      <button
        class="optionbtn me-2"
        [ngClass]="{
          optionbtnactive: selectedOptionType === 'put',
          optionnotactive: selectedOptionType == 'call,callput'
        }"
        (click)="putupdateOption('put')"
      >
        <p class="m-0">Put</p>
      </button>
      <button
        class="optionbtn"
        [ngClass]="{
          optionbtnactive: selectedOptionType === 'callput',
          optionnotactive: selectedOptionType == 'put,call'
        }"
        (click)="callputupdateOption('callput')"
      >
        <p class="m-0">Call & Put</p>
      </button>
    </div>

    <div class="chart-container">
      <canvas [ngClass]="{ blur: !this.productId }" id="MyChart">{{
        chart
      }}</canvas>
    </div>
  </div>
  <div class="col-lg-6">
    <h2 class="company-detail-list interest-chart text-start">
      Open Interest Totals by Expiry
    </h2>
    <hr class="mb-3" />
    <div class="d-flex btn-space">
      <button
        class="optionbtn me-2"
        [ngClass]="{
          optionbtnactive: selectedInterestType === 'call',
          optionnotactive: selectedInterestType == 'put,callput'
        }"
        (click)="callupdateOpenInterest('call')"
      >
        <p class="m-0">Call</p>
      </button>
      <button
        class="optionbtn me-2"
        [ngClass]="{
          optionbtnactive: selectedInterestType === 'put',
          optionnotactive: selectedInterestType == 'call,callput'
        }"
        (click)="putupdateOpenInterest('put')"
      >
        <p class="m-0">Put</p>
      </button>
      <button
        class="optionbtn"
        [ngClass]="{
          optionbtnactive: selectedInterestType === 'callput',
          optionnotactive: selectedInterestType == 'put,call'
        }"
        (click)="callputupdateOpenInterest('callput')"
      >
        <p class="m-0">Call & Put</p>
      </button>
    </div>

    <div class="chart-container">
      <canvas [ngClass]="{ blur: !this.productId }" id="MyChart2">{{
        Interestchart
      }}</canvas>
    </div>
  </div>
</div>
