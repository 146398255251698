import { Component } from '@angular/core';
import { HedgefundsService } from '../../../shared/service/hedgefunds.service';
import { individualTable } from '../../../shared/models/individualfund';
import { CompanyDetailsService } from '../../../shared/service/company-details.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DecryptionService } from 'src/app/modules/shared/service/decryption.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-individualfund',
  templateUrl: './individualfund.component.html',
  styleUrls: ['./individualfund.component.scss'],
})
export class IndividualfundComponent {
  indivisualTbl: individualTable[] = [];
  FundName = '';
  totalDatalength = 0;
  isLoading = false;
  loadData = false;
  apitype = 'defualt';
  currentPage = 1;
  basePath: string = 'https://s3-symbol-logo.tradingview.com/';
  top20hedgefunds: any;
  qoqchange: any;
  totalmv: number = 0;
  total20holding: number = 0;
  clientUrl = environment.clientUrl;
  lockedArray: any[] = [];
  productId: number | undefined;
  modalDisplayStyle!: boolean;

  constructor(
    private hedgeService: HedgefundsService,
    private compamyDetails: CompanyDetailsService,
    private router: Router,
    private toaster: ToastrService,
    private decryptionService: DecryptionService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      let name  = params['fundname'];
      this.FundName = name.replace(/[_%]/g, ' ');
    });
    this.indivisualDataTable();
    this.individualOverview();


    
  }

  indivisualDataTable() {

    this.hedgeService
      .indivisualData(this.FundName, this.currentPage)
      .subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res.result);
          const ParseDecryptedData = JSON.parse(decryptData);
          this.top20hedgefunds = ParseDecryptedData[0].data;
          let sum = 0;

          this.productId = ParseDecryptedData.find(
            (x: any) => x.productId
          )?.productId;

          ParseDecryptedData[0].data.forEach((element: any) => {
            this.indivisualTbl.push(element);

            sum += element.current_percent_of_portfolio;
          });

          this.total20holding = sum;

          for (let i = 0; i < 2; i++) {
            this.lockedArray.push(this.indivisualTbl);
            this.lockedArray = this.lockedArray.flat();
          }

          if (!this.productId) {
            this.indivisualTbl = this.lockedArray;
          }
          this.isLoading = false;
          this.currentPage++;
        },

        error: (err) => {
          this.isLoading = true;
          this.toaster.warning('All Record displayed');
          this.apitype = 'defualt';
        },
      });
  }

  companyDetails(ticker: any) {
    var loginuser = localStorage.getItem('loggedInUser');
    if (loginuser !== null) {
      this.compamyDetails.getCompanyTable(ticker).subscribe({
        next: (res: any) => {
          this.router.navigate(['company', ticker]);
        },
      });
      this.compamyDetails.getCompanyTicker(ticker);
    }
  }

  individualOverview() {
    this.hedgeService.Indevidualoverview(this.FundName).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);
        const ParseDecryptedData = JSON.parse(decryptData);
        this.totalmv = ParseDecryptedData.current_mv;
        this.totalDatalength = ParseDecryptedData.symbolLength;
        this.qoqchange = ParseDecryptedData.QoQ_change;
      },
    });
  }
  GetChildData(data: any) {
    this.modalDisplayStyle = data;
  }
  loadMoreData() {
    this.isLoading = true;
    this.loadData = true;

    if (this.apitype == 'defualt') {
      this.hedgeService
        .indivisualData(this.FundName, this.currentPage)
        .subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res.result);
            const ParseDecryptedData = JSON.parse(decryptData);
            this.loadData = false;
            ParseDecryptedData[0].data.forEach((element: any) => {
              this.indivisualTbl.push(element);
            });
            this.isLoading = false;
            this.currentPage++;
          },
          error: (err) => {
            this.loadData = false;
            this.isLoading = true;
            this.toaster.warning('All Record displayed');
            this.apitype = 'defualt';
          },
        });
    }
  }

  onScroll(event: any) {
    const element = event.target as HTMLElement;
    const scrollPosition = element.scrollTop + element.clientHeight;
    const totalHeight = element.scrollHeight;
    if (this.productId) {
      if (scrollPosition >= totalHeight - 1 && !this.isLoading) {
        if (this.currentPage != 1) {
          this.loadMoreData();
        }
      }
    } else {
      this.modalDisplayStyle = true;
    }
  }
}
