import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getPastIpoData, getUpcomingIpoData } from '../const';

@Injectable({
  providedIn: 'root',
})
export class IpoService {
  constructor(private http: HttpClient) {}

  getUpcomingIpoTable(page: number) {
    return this.http.get<any[]>(`${getUpcomingIpoData}${page}`);
  }

  getPastIpoTable(page: number) {
    return this.http.get<any[]>(`${getPastIpoData}${page}`);
  }
}
