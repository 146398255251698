import { Component, ElementRef, Input } from '@angular/core';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-thirty-day-trend',
  templateUrl: './thirty-day-trend.component.html',
  styleUrls: ['./thirty-day-trend.component.scss'],
})
export class ThirtyDayTrendComponent {
  @Input() chartData: any[] = [];
  @Input() x!: number;

  priceChange: any;
  dateChange: any;

  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
    this.priceChange = this.chartData.map((item, index) => {
      return item.thirty;
    });

    this.dateChange = this.chartData.map((item, index) => {
      return item.today;
    });

    const labels = Array.from({ length: 25 }, (_, i) => i + 1);

    const canvas: HTMLCanvasElement = this.el.nativeElement.querySelector(
      `#chartCanvas${this.x}`
    );

    const ctx = canvas?.getContext('2d');
    if (ctx) {
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.dateChange,
          datasets: [
            {
              label: '', // Set the label to an empty string
              data: this?.priceChange,
              borderColor: this.getLineColor(this.priceChange), // Dynamically set line color
              borderWidth: 2, // Set the line weight
              pointRadius: 0, // Set the initial radius of the points on the line
              hoverBackgroundColor: 'rgba(75, 192, 192, 1)', // Set the fill color when hovering
              pointHoverBackgroundColor: 'black', // Set the intersection point color
              pointHoverBorderColor: 'black', // Set the intersection point border color
            },
          ],
        },
        options: {
          maintainAspectRatio: false, // Allow the chart to dynamically adjust its aspect ratio
          scales: {
            x: {
              display: false, // Hide x-axis
            },
            y: {
              display: false, // Hide y-axis
            },
          },
          plugins: {
            legend: {
              display: false, // Hide legend
            },
            tooltip: {
              mode: 'nearest', // Show the nearest tooltip
              intersect: false, // Allow the tooltip to appear as you slide left to right
              callbacks: {
                label: function (context) {
                  if (context.dataset) {
                    // Cast the value to number and round to 2 decimal places
                    let label = (
                      Number(context.dataset.data[context.dataIndex]) as number
                    )
                      .toFixed(2)
                      .toString();
                    return label;
                  }
                  return '';
                },
              },
              borderWidth: 0, // Set the border width to 0 to remove the border
              displayColors: false, // Do not display color boxes
            },
          },

          layout: {
            padding: {
              left: 10,
              right: 10,
              top: 10,
              bottom: 10,
            },
          },
        },
      });
    }
  }

  getLineColor(data: any) {
    const firstValue = data[0];
    const lastValue = data[data.length - 1];

    // return lastValue > firstValue ? '#f23645' : '#089981';
    return lastValue > firstValue ? '#089981' : '#f23645';
  }
}
