<div class="container">
  <div class="mt-3 mb-3">
    <h3 class="main-heading text-center head-text">Our Mission</h3>
  </div>
  <p class="sub-heading text-center">
    At BiopharmaWatch, we are driven by a single, essential mission: to empower
    investors by delivering unparalleled insights into the biotech and
    pharmaceutical industries. Our commitment is to provide timely, accurate,
    and valuable information to help you make informed investment decisions in
    these dynamic sectors.
  </p>

  <div class="mt-3 mb-3">
    <h1 class="main-heading text-center head-text">Who We Are</h1>
  </div>
  <p class="sub-heading text-center">
    BiopharmaWatch is a leading provider of investment analysis and research in
    the biotech and pharma sectors. We are a team of dedicated professionals,
    combining the expertise of seasoned scientists, experienced investment
    analysts, and cutting-edge artificial intelligence technology. Together, we
    work tirelessly to bring you the most relevant and impactful market
    information.
  </p>
  <div class="mt-3 mb-3">
    <h3 class="main-heading text-center head-text">What We Do</h3>
  </div>
  <p class="sub-heading text-center">
    Our services are meticulously crafted to offer comprehensive coverage of
    over 1,000 companies in the biotech and pharma industries. We track and
    analyze more than 85 critical metrics, ensuring you have access to the
    latest data on clinical trial progress, FDA approvals, financial
    performance, and much more. Our tools and insights are designed to give you
    an edge in navigating the complex landscape of biotech and pharma investing.
  </p>
  <div class="mt-3 mb-3">
    <h3 class="main-heading text-center head-text">Our Approach</h3>
  </div>
  <p class="sub-heading text-center">
    At BiopharmaWatch, our approach is rooted in a commitment to excellence,
    integrity, and innovation. We believe in providing information that is not
    only extensive but also accurate and actionable. Our rigorous research
    process and state-of-the-art technology ensure that our clients receive the
    most current and relevant insights.
  </p>
  <div class="mt-3 mb-3">
    <h3 class="main-heading text-center head-text">Our Promise to You</h3>
  </div>
  <p class="sub-heading text-center">
    Your success is our top priority. We pledge to provide you with
    high-quality, reliable information to guide your investment decisions.
    Whether you're a seasoned investor or new to the biotech and pharma sectors,
    we are here to support you every step of the way.
  </p>
  <div class="mt-3 mb-3">
    <h3 class="main-heading text-center head-text">Get in Touch</h3>
  </div>
  <p class="sub-heading text-center">
    Join us at BiopharmaWatch, and experience a new level of insight and
    expertise in biotech and pharma investing. For more information, or if you
    have any questions, please feel free to reach out to our support team. We're
    here to help you navigate the market with confidence.
  </p>
  <p class="sub-heading text-center">
    Welcome to BiopharmaWatch - Your guide to informed biotech and pharma
    investing.
  </p>
</div>
