<div class="volatile">
  <div class="container-fluid p-2 fda-header-box">
    <div class="text-center fda-text">
      <h3 class="main-heading text-white m-0 me-3">Past IPOs</h3>

      <p class="sub-heading text-white m-0">
        Past IPO dates, offering a detailed IPO timeline and insights.
      </p>
    </div>
  </div>
</div>

<section class="volatile">
  <div
    class="table-responsive scrollable-table1"
    id="dataContainer"
    #dataContainer
  >
    <table class="table align-middle mb-0 bg-white">
      <thead class="text-center">
        <tr class="text-uppercase table-header">
          <th class="table-name-size">company</th>
          <th class="table-name-size">therapeutic focus</th>
          <th class="table-name-size">underwriters</th>
          <th class="table-name-size">ipo date</th>
          <th class="table-name-size">offer price</th>
          <th class="table-name-size">raised amount</th>
          <th class="table-name-size">original price range</th>
        </tr>
      </thead>
      <tbody id="tbody" class="text-center">
        @for(i of pastIPOdata;track $index){
        <tr class="table-tr-font">
          <td class="table-font-size" scope="row" style="z-index: 1">
            {{ i.ticker }} <br />
            {{ i.company }}
          </td>
          <td class="table-font-size" scope="row" style="z-index: 1">
            {{ i.therapeutic_focus }}
          </td>
          <td class="table-font-size" scope="row" style="z-index: 1">
            {{ i.underwriters }}
          </td>
          <td class="table-font-size" scope="row" style="z-index: 1">
            {{ i.ipo_date }}
          </td>
          <td class="table-font-size" scope="row" style="z-index: 1">
            {{ i.offer_price }}
          </td>
          <td class="table-font-size" scope="row" style="z-index: 1">
            {{ i.raised_amount }}
          </td>
          <td class="table-font-size" scope="row" style="z-index: 1">
            {{ i.original_price_range }}
          </td>
        </tr>
        }
      </tbody>
      <tfoot></tfoot>
    </table>
  </div>
</section>
