import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-scrolltop',
  templateUrl: './scrolltop.component.html',
  styleUrl: './scrolltop.component.scss'
})
export class ScrolltopComponent {

  windowScrolled!: boolean;

  @HostListener("window:scroll", [])
  onWindowScroll() {
    // Detect if user has scrolled down 400px from the top
    this.windowScrolled = window.pageYOffset > 400;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}




