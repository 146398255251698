import { Injectable } from '@angular/core';
import { profile } from '../../core/model/profile';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { catchError, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  cancelplan,
  cancelplanotp,
  getProfile,
  postProfile,
  resetpassword,
} from '../../shared/const';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private http: HttpClient) {}

  getProfile(id: string) {
    return this.http.get<profile[]>(`${getProfile}${id}`).pipe(
      tap((res) => {}),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error.error.message);
      })
    );
  }

  cancelotpsent() {
    return this.http.get<any>(`${cancelplanotp}`).pipe(
      tap((res) => {}),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error.error.message);
      })
    );
  }

  cancelplan(data: any) {
    return this.http.post<any>(`${cancelplan}`, data).pipe(
      tap((res) => {}),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error.error.message);
      })
    );
  }

  postProfile(data: any, id: number) {
    return this.http.post<profile[]>(`${postProfile}${id}`, data).pipe(
      tap((res) => {
        localStorage.setItem('profileData', JSON.stringify(res));
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error.error.message);
      })
    );
  }

  resetpassword(data: any, id: any) {
    return this.http.post<profile[]>(`${resetpassword}${id}`, data).pipe(
      tap((res) => {}),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error.error.message);
      })
    );
  }
}
