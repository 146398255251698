import { Component } from '@angular/core';

@Component({
  selector: 'app-website-user',
  templateUrl: './website-user.component.html',
  styleUrls: ['./website-user.component.scss']
})
export class WebsiteUserComponent {

}
