import { Injectable } from '@angular/core';
import { login } from '../../core/model/login';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Route, Router } from '@angular/router';
import { BehaviorSubject, Observable, catchError, tap, throwError } from 'rxjs';
import { ProfileService } from './profile.service';
import { environment } from 'src/environments/environment';
import { forgetpassword, googlePostLogin, postlogin } from '../../shared/const';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  isloginUser = new BehaviorSubject<boolean>(false);
  private isLoggedInKey = 'isLoggedIn';
  expirationDate: any;
  PortfoliomatchTicker: any;
  private formDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  private loggedIn = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private router: Router,
    private profileService: ProfileService,
    private toaster: ToastrService
  ) {
    const storedStatus = localStorage.getItem(this.isLoggedInKey);
    if (storedStatus !== null) {
    }
  }
  public id: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  postlogin(data: any): Observable<any> {
    return this.http.post<login[]>(`${postlogin}`, data).pipe(
      tap((response: any) => {
        this.isloginUser.next(true);
        this.id.next(response.id);

        this.router.navigate(['home']);
        // localStorage.setItem('password', JSON.stringify(response.password));
        localStorage.setItem(
          'accessToken',
          JSON.stringify(response.accessToken)
        );
        localStorage.setItem('role', response.role);
        localStorage.setItem('loggedInUser', JSON.stringify(response));
        localStorage.setItem('UserId', JSON.stringify(response.id));
        localStorage.setItem('expireDate', JSON.stringify(response.expireDate));
        this.expirationDate = localStorage.getItem('expireDate');
        // this.autoLogout(this.expirationDate);
        // this.profileService.getProfile(response.id).subscribe({
        //   next: (res: any) => {
        //     this.PortfoliomatchTicker = res.data.portfolio;
        //   },
        // });
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error.error.message);
      })
    );
  }

  // GetPortfolio() {
  //   return this.PortfoliomatchTicker;
  // }

  googlePostLogin(data: any): Observable<any> {
    let idToken = { idToken: data };

    return this.http.post(`${googlePostLogin}`, idToken).pipe(
      tap((response: any) => {
        this.isloginUser.next(true);

        this.id.next(response.id);
        this.router.navigate(['home']);
        // localStorage.setItem('googleToken', JSON.stringify(response.user.accessToken));
        localStorage.setItem('loggedInUser', JSON.stringify(response.user));
        localStorage.setItem('UserId', JSON.stringify(response.user.id));
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error.error.message);
      })
    );
  }

  forgetpassword(data: any): Observable<any> {
    return this.http.post(`${forgetpassword}`, data).pipe(
      tap((response: any) => {
        localStorage.setItem('email', JSON.stringify(response.email));
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error.error.message);
      })
    );
  }

  setFormData(formData: any) {
    this.formDataSubject.next(formData);
  }

  getFormData(): Observable<any> {
    return this.formDataSubject.asObservable();
  }
  getId(): Observable<any> {
    return this.id.asObservable();
  }

  login() {
    this.loggedIn.next(true);
  }

  logout() {
    localStorage.removeItem('loggedInUser');
    localStorage.removeItem('UserId');
    localStorage.removeItem('profileData');
    localStorage.removeItem('profileId');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('lastName');
    localStorage.removeItem('firstName');
    localStorage.removeItem('password');
    localStorage.removeItem('companyTableData');
    localStorage.removeItem('bloguserDetails');
    localStorage.removeItem('expireDate');
    this.loggedIn.next(false);
    this.router.navigate(['login']);
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }
}
