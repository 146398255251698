import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { roLocale } from 'ngx-bootstrap/chronos';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class adminAuthGuard {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    var role = localStorage.getItem('role');

    if (role == 'admin' && role != null && role != undefined) {
      return true;
    } else {
      this.router.navigate(['home']);
      return false;
    }
  }
}
