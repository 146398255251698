import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CompanyDetailsService } from 'src/app/modules/shared/service/company-details.service';
import { DecryptionService } from 'src/app/modules/shared/service/decryption.service';
import { FdaCalenarService } from 'src/app/modules/shared/service/fda-calendar.service';
import { FilterdataService } from 'src/app/modules/shared/service/filterdata.service';
import { PdufaCalendarService } from 'src/app/modules/shared/service/pdufa-calendar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-historical-pdufa',
  templateUrl: './historical-pdufa.component.html',
  styleUrl: './historical-pdufa.component.scss',
})
export class HistoricalPDUFAComponent {
  @ViewChild('dataContainer') dataContainer!: ElementRef;
  showButtons = true;
  portfolioTicker: any;
  portfoliomatchTicker: any[] = [];
  private currentPage = 1;
  isLoading!: boolean;
  loadData!: boolean;
  modalDisplayStyle!: boolean;
  apitype = 'defualt';
  sortKeyVal = '';
  sortNumber = 1;
  arrowclass = 'fa-arrow-up';
  totalDatalength = 0;
  pdufaTable: any[] = [];
  lockedArray: any[] = [];
  FilterObj: any;
  suggection: any;
  searchValuequery: string = '';
  isFilterBtnResert = false;
  isFilterBtnSearch = false;
  forResetfilters = false;
  isFilter = false;
  selectedValueinDate: string | null = null;
  selectedValueinStatus: string | null = null;
  selectedValueinEvent: string | null = null;
  selectedDateColor!: string;
  filterArray: any[] = [];
  minCustomDate!: number;
  maxCustomDate!: number;
  searchingOrFiltering: boolean = true;
  selectedstatusColor!: string;
  maxCustomValue: number | undefined;
  minCustomValue: number | undefined;
  selectedDateRange: any = null;
  productId: any;
  isFilterBtn = false;
  showloginWarning = false;
  basePath: string = 'https://s3-symbol-logo.tradingview.com/';
  clientUrl = environment.clientUrl;
  portfoliocost: number | undefined;
  portfolioPrice: any;
  portfolioquntity: number | undefined;
  portfoliototal: any;

  constructor(
    private router: Router,
    private pdufaCalendarService: PdufaCalendarService,
    private decryptionService: DecryptionService,
    private filter: FilterdataService,
    private toaster: ToastrService,
    private compamyDetails: CompanyDetailsService,
    private formBuilder: FormBuilder,
    private fdacalendarservice: FdaCalenarService
  ) {}

  ngOnInit(): void {
    this.pdufaTableData();
    this.returnLogin();
  }

  checkScrollable() {
    const container = this.dataContainer.nativeElement;
    if (container.scrollWidth > container.clientWidth) {
      this.showButtons = true;
    } else {
      this.showButtons = false;
    }
  }

  scrollLeft() {
    this.dataContainer.nativeElement.scrollLeft -= 300;
  }

  scrollRight() {
    this.dataContainer.nativeElement.scrollLeft += 300;
  }

  onAddSymbol(data: any) {
    this.portfoliomatchTicker.push(data.ticker);
    return;
  }

  onScroll(event: any) {
    const element = event.target as HTMLElement;
    const scrollPosition = element.scrollTop + element.clientHeight;
    const totalHeight = element.scrollHeight;
    if (this.productId) {
      if (scrollPosition >= totalHeight - 1 && !this.isLoading) {
        if (this.currentPage != 1) {
          this.loadMoreData();
        }
      }
    } else {
      let href = this.router.url;
      localStorage.setItem('route', href);
      this.modalDisplayStyle = true;
    }
  }

  pdufaTableData() {
    this.pdufaCalendarService.historicalPdufa(this.currentPage).subscribe({
      next: (res: any) => {
        this.currentPage = 2;
        this.isFilterBtnResert = false;
        this.isFilterBtn = false;

        setTimeout(() => {
          this.checkScrollable();
        });
        const decryptData = this.decryptionService.decrypt(res.result);

        const ParseDecryptedData = JSON.parse(decryptData);

        ParseDecryptedData.find((x: any) => x.portfolio)?.portfolio.forEach(
          (element: any) => {
            this.portfoliomatchTicker.push(element.ticker);
          }
        );

        this.productId = ParseDecryptedData.find(
          (x: any) => x.productId
        )?.productId;

        this.pdufaTable = ParseDecryptedData[0].data;

        this.totalDatalength = ParseDecryptedData[0].total[0].count;

        for (let i = 0; i < 2; i++) {
          this.lockedArray.push(this.pdufaTable);
          this.lockedArray = this.lockedArray.flat();
        }

        if (!this.productId) {
          this.pdufaTable = this.lockedArray;
        }
      },
      error: (error) => {
        this.isLoading = false;
        console.log('error :', error);
      },
    });
  }

  loadMoreData() {
    this.isLoading = true;
    this.loadData = true;
    if (this.apitype == 'filter') {
      this.filter.pdufawithFilter(this.currentPage, this.FilterObj).subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res.encrypted);

          const ParseDecryptedData = JSON.parse(decryptData);

          ParseDecryptedData[0].data.forEach((element: any) => {
            this.pdufaTable.push(element);
          });
          this.isLoading = false;
          this.loadData = false;

          this.currentPage++;
        },
        error: (err) => {
          this.isLoading = true;
          this.loadData = false;

          this.toaster.warning('All Record displayed');
          this.apitype = 'defualt';
        },
      });
    }

    if (this.apitype == 'defualt') {
      this.pdufaCalendarService.historicalPdufa(this.currentPage).subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res.result);

          const ParseDecryptedData = JSON.parse(decryptData);
          ParseDecryptedData[0].data.forEach((element: any) => {
            this.pdufaTable.push(element);
          });
          this.isLoading = false;
          this.loadData = false;
          this.currentPage++;
        },
        error: (err) => {
          this.loadData = false;
          this.isLoading = true;
          this.toaster.warning('All Record displayed');
          this.apitype = 'defualt';
        },
      });
    }
  }

  searchFilters(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;

    if (event.key === 'Escape' && !inputElement.value) {
      this.suggection = [];
      return;
    }

    if (!inputElement.value || !this.searchValuequery) {
      this.suggection = [];
      return;
    }

    this.compamyDetails.searchsuggection(inputElement.value).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res);

        const ParseDecryptedData = JSON.parse(decryptData);

        this.suggection = ParseDecryptedData;
      },
    });
  }

  onSearchInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;

    if (inputValue === '') {
      this.suggection = [];
    }
  }

  searchFiltersonclick(query: any) {
    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }

    if (query == '') {
      this.toaster.warning('Enter Valid Inputs.');
      return;
    }

    let search = {
      query,
    };
    this.suggection = [];
    this.searchValuequery = '';

    this.isLoading = true;
    this.isFilterBtnSearch = true;
    this.forResetfilters = true;
    this.isFilter = true;

    this.pdufaTable = [];

    this.filter.pdufafilterData(search).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.encrypted);

        const ParseDecryptedData = JSON.parse(decryptData);

        this.isFilterBtnSearch = false;
        this.forResetfilters = false;
        this.loadData = false;
        this.totalDatalength = ParseDecryptedData.length;
        this.pdufaTable = ParseDecryptedData as any[];
        this.suggection = [];
        this.isLoading = true;
      },
      error: (err) => {
        this.isFilterBtnSearch = false;
        this.forResetfilters = false;
        this.searchValuequery = '';
        this.isLoading = false;
        this.pdufaTable = [];
        this.pdufaTableData();
        this.toaster.error(err.error.message);
      },
    });
  }

  selectedData(data: string) {
    switch (data) {
      case 'Last month': {
        this.selectedValueinDate = 'Last month';
        this.selectedDateColor = 'selected-button';
        this.filterArray.push({ month: 'lastMonth' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].year;
          delete this.filterArray[key].quarter;
          delete this.filterArray[key].startDate;
          delete this.filterArray[key].endDate;
        }
        this.saveFilters();
        break;
      }
      case 'Last quarter': {
        this.selectedValueinDate = 'Last quarter';
        this.selectedDateColor = 'selected-button';
        this.filterArray.push({ quarter: 'lastQuarter' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].year;
          delete this.filterArray[key].month;
          delete this.filterArray[key].startDate;
          delete this.filterArray[key].endDate;
        }
        this.saveFilters();
        break;
      }
      case 'Last year': {
        this.selectedValueinDate = 'Last year';
        this.selectedDateColor = 'selected-button';
        this.filterArray.push({ year: 'lastYear' });
        for (let key in this.filterArray) {
          delete this.filterArray[key].quarter;
          delete this.filterArray[key].month;
          delete this.filterArray[key].startDate;
          delete this.filterArray[key].endDate;
        }
        this.saveFilters();
        break;
      }
      // case 'Next Week': {
      //   this.selectedValueinDate = 'Next Week';
      //   this.selectedDateColor = 'selected-button';
      //   this.filterArray.push({ week: 'nextWeek' });
      //   for (let key in this.filterArray) {
      //     delete this.filterArray[key].month;
      //     delete this.filterArray[key].startDate;
      //     delete this.filterArray[key].endDate;
      //   }

      //   break;
      // }
      case 'Approval': {
        this.selectedstatusColor = 'selected-button';
        this.selectedValueinStatus = 'Approval';
        this.filterArray.push({ Status: 'Approval' });
        this.saveFilters();
        break;
      }
      case 'CRL': {
        this.selectedstatusColor = 'selected-button';
        this.selectedValueinStatus = 'CRL';
        this.filterArray.push({ Status: 'CRL' });
        this.saveFilters();
        break;
      }
    }
  }

  clearSelectedDate() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].startDate;
      delete this.filterArray[key].endDate;
      delete this.filterArray[key].year;
      delete this.filterArray[key].month;
      delete this.filterArray[key].quarter;
    }
    this.minCustomDate = 0;
    this.maxCustomDate = 0;
    this.selectedDateColor = '';
    this.selectedValueinDate = '';
    if (
      this.filterArray.every((item) => Object.keys(item).length === 0) &&
      this.isFilter
    ) {
      this.resetFilters();
    }
  }

  resetFilters() {
    this.searchingOrFiltering = true;
    this.isFilterBtnResert = true;
    this.isFilter = false;
    this.refresh();
    this.totalDatalength = 0;
    this.apitype = 'defualt';
    this.pdufaTableData();

    if (this.forResetfilters) {
      this.clearSelectedDate();
      this.clearSelectedStatus();
    }
  }

  refresh() {
    this.pdufaTable = [];
    this.lockedArray = [];
    this.isLoading = false;
    this.loadData = false;

    this.currentPage = 1;
  }

  clearSelectedStatus() {
    for (let key in this.filterArray) {
      delete this.filterArray[key].Status;
    }
    this.selectedstatusColor = '';
    this.selectedValueinStatus = '';

    if (
      this.filterArray.every((item) => Object.keys(item).length === 0) &&
      this.isFilter
    ) {
      this.resetFilters();
    }
  }

  updateDateCustomRange() {
    if (
      this.minCustomValue !== undefined &&
      this.maxCustomValue !== undefined
    ) {
      this.selectedDateRange = `${this.minCustomValue}$ to ${this.maxCustomValue}$`;
    }
  }

  pushFilterDateStart(data: any) {
    for (let key in this.filterArray) {
      delete this.filterArray[key].week;
      delete this.filterArray[key].month;
    }
    this.selectedDateColor = 'selected-button';
    this.filterArray.push({ start_Date: data.target.value });
  }

  pushFilterDateEnd(data: any) {
    for (let key in this.filterArray) {
      delete this.filterArray[key].week;
      delete this.filterArray[key].month;
    }
    this.selectedDateColor = 'selected-button';
    this.filterArray.push({ end_Date: data.target.value });
  }

  saveFilters() {
    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }

    const filtered = this.filterArray.filter(
      (el) => Object.keys(el).length > 0
    );
    if (filtered.length === 0) {
      this.toaster.warning('Enter Valid Inputs.');
      return;
    }
    this.refresh();
    this.isFilter = true;
    this.isFilterBtn = true;
    this.forResetfilters = true;
    this.apitype = 'filter';
    this.FilterObj = filtered.reduce((result, currentObject) => {
      for (var key in currentObject) {
        if (currentObject.hasOwnProperty(key)) {
          result[key] = currentObject[key];
        }
      }
      return result;
    }, {});

    this.filter.pdufawithFilter(this.currentPage, this.FilterObj).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.encrypted);

        const ParseDecryptedData = JSON.parse(decryptData);

        this.totalDatalength = ParseDecryptedData[0].total[0].count;

        this.pdufaTable = ParseDecryptedData[0].data;
        this.isFilterBtn = false;
        this.isLoading = false;
        this.currentPage = 2;
      },
      error: (err) => {
        this.isLoading = false;
        this.isFilterBtn = false;
        this.resetFilters();
        this.toaster.error(err.error.message);
      },
    });
  }

  GetChildData(data: any) {
    this.modalDisplayStyle = data;
  }

  returnLogin() {
    let loign = localStorage.getItem('loggedInUser');
    if (!loign) {
      this.showloginWarning = true;
    }
  }

  companyDetails(ticker: any) {
      this.compamyDetails.getCompanyTable(ticker).subscribe({
        next: (res: any) => {
         
            this.router.navigate(['company', ticker]);
        },
      });
      this.compamyDetails.getCompanyTicker(ticker);
  
  }

  removeSymboltoPotfoliyo(symbol: any) {
    let loginuser = JSON.parse(localStorage.getItem('loggedInUser') || '{}');

    let obj = {
      email: loginuser.email,
      ticker: symbol,
    };

    if (obj) {
      this.fdacalendarservice.removeSymboltoPotfoliyo(obj).subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res);
          this.portfoliomatchTicker = [];
          const ParseDecryptedData = JSON.parse(decryptData);
          ParseDecryptedData.portfolio.forEach((element: any) => {
            this.portfoliomatchTicker.push(element.ticker);
          });

          this.toaster.success('Symbol removed from your portfolio');
        },
      });
    }
  }
}
