import { Component } from '@angular/core';
import { BioCelenderService } from '../../../shared/service/bio-celender.service';
import { CompanyDetailsService } from '../../../shared/service/company-details.service';
import { Router } from '@angular/router';
import { DecryptionService } from 'src/app/modules/shared/service/decryption.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-biotech-earnings',
  templateUrl: './biotech-earnings.component.html',
  styleUrls: ['./biotech-earnings.component.scss'],
})
export class BiotechEarningsComponent {
  [x: string]: any;
  detailData: any;
  suggection: any;
  sidepannelArray: any;
  monthName: any;
  requestBody: any;
  currentMonth: Date = new Date();
  selectedDate: Date | null = null;
  selectedDay!: number;
  weekdays: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  calendarDays: Date[] = [];
  displayStyle = 'none';
  displayStylemobile = 'none';
  date!: number;
  isLoading = true;
  year!: number;
  month!: number;
  resobj: any;
  monthData: any;
  productId: any;
  backbtn = false;
  mobpopupzindex!: number;
  modalDisplayStyle: boolean = false;
  loginuser = localStorage.getItem('loggedInUser');
  selectedStock: {
    title: string;
    description: string;
    prev_earnings_per_share: number;
    symbol: string;
  } | null = null;
  attributes = {
    disabled: false,
  };
  showloginWarning = false;
  showDetailDataFlag = false;
  basePath: string = 'https://s3-symbol-logo.tradingview.com/';

  bsConfig: object = {
    dateInputFormat: 'YYYY-MM',
    minMode: 'month',
    containerClass: 'theme-dark-blue',
    isAnimated: true,
  };
  marketCapVal: any = '';
  bySecVal = '';
  dateModel: Date = new Date();
  filterArray: any = [];

  clientUrl = environment.clientUrl;
  searchValuequery: any;

  constructor(
    private bioservice: BioCelenderService,
    private compamyDetails: CompanyDetailsService,
    private router: Router,
    private decryptionService: DecryptionService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.generateCalendarDays();
    this.getCalendarData();
    this.returnLogin();
  }

  returnLogin() {
    let loign = localStorage.getItem('loggedInUser');
    if (!loign) {
      this.showloginWarning = true;
    }
  }

  selectdateforcalendar() {
    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }
  }

  onMonthYearChange(event: Event): void {
    this.currentMonth = new Date(this.dateModel);
    this.generateCalendarDays();
    this.getCalendarData();
    this.selectdateforcalendar();
  }

  generateCalendarDays(): void {
    this.calendarDays = [];
    const year = this.currentMonth.getFullYear();
    const month = this.currentMonth.getMonth();
    const lastDayOfMonth = new Date(year, month + 1, 0);

    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      const currentDate = new Date(year, month, i);
      this.calendarDays.push(currentDate);
    }
  }

  getQueryData(searchQuery: any) {
    let searchValue = {
      query: searchQuery,
      month:
        this.month + 1 < 10 ? '0' + (this.month + 1) : String(this.month + 1),
      // Industry: this.filterArray,
      Industry: this.filterArray,
      market_value: this.marketCapVal,
      year: String(this.year),
    };

    if (searchValue.market_value === '') {
      delete searchValue.market_value;
    }

    if (
      Array.isArray(searchValue.Industry) &&
      searchValue.Industry.length === 0
    ) {
      delete searchValue.Industry;
    }

    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }

    this.bioservice.bioclenderSearch(searchValue).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);
        const ParseDecryptedData = JSON.parse(decryptData);

        // this.resobj = ParseDecryptedData.data;
        this.productId = ParseDecryptedData.productId;
        this.isLoading = false;
        this.showDetailDataFlag = false;
        this.detailData = ParseDecryptedData.data;
        this.suggection = [];
        this.searchValuequery = '';
        // this.detailData.sort(
        //   (a: any, b: any) =>
        //     new Date(a.date).getTime() - new Date(b.date).getTime()
        // );
      },
      error: (err) => {
        this.isLoading = false;
        this.suggection = [];
        this.toaster.error(err.error.message);
      },
    });
  }

  showDetailData() {
    this.detailData = this.sidepannelArray;

    this.searchValuequery = '';
  }

  getMatchingStocks(day: Date): any[] {
    return this.resobj.filter((stock: any) => {
      const stockDate = new Date(stock.date);
      return (
        stockDate.getDate() === day.getDate() &&
        stockDate.getMonth() === day.getMonth() &&
        stockDate.getFullYear() === day.getFullYear()
      );
    });
  }

  getCalendarData() {
    this.showDetailDataFlag = false;

    this.requestBody = {
      year: this.currentMonth.getFullYear().toString(),
      month: ('0' + (this.currentMonth.getMonth() + 1)).slice(-2),
      Industry: this.filterArray,
      market_value: this.marketCapVal,
    };
    if (
      Array.isArray(this.requestBody.Industry) &&
      this.requestBody.Industry.length === 0
    ) {
      delete this.requestBody.Industry;
    }
    var monthstring = this.requestBody.month;
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    this.monthName = monthNames[monthstring - 1];

    this.isLoading = true;

    this.bioservice.bioclender(this.requestBody).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);

        const ParseDecryptedData = JSON.parse(decryptData);
        this.monthData = ParseDecryptedData.data;
        this.resobj = this.monthData;
        this.productId = ParseDecryptedData.productId;
        this.isLoading = false;
        this.detailData = ParseDecryptedData.data;
        this.sidepannelArray = ParseDecryptedData.data;
      },
      error: (err) => {
        this.isLoading = false;
        this.resobj = [];
        this.detailData = [];
      },
    });
  }

  getEmptyDaysBeforeFirstDay(): number[] {
    const firstDayOfMonth = this.calendarDays[0];
    const dayOfWeek = firstDayOfMonth.getDay();
    const emptyDays = [];
    for (let i = 0; i < dayOfWeek; i++) {
      emptyDays.push(i);
    }
    return emptyDays;
  }

  prevMonth(): void {
    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }
    this.showDetailDataFlag = false;

    const prevMonthDate = new Date(this.currentMonth);
    prevMonthDate.setMonth(prevMonthDate.getMonth() - 1);
    this.currentMonth = prevMonthDate;
    this.generateCalendarDays();
    this.getCalendarData();
  }

  nextMonth(): void {
    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }
    this.showDetailDataFlag = false;
    const nextMonthDate = new Date(this.currentMonth);
    nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
    this.currentMonth = nextMonthDate;
    this.generateCalendarDays();
    this.getCalendarData();
  }

  isToday(date: Date): boolean {
    const today = new Date();
    return date.toDateString() === today.toDateString();
  }

  isSelected(date: Date): boolean {
    this.date = date.getDate();
    this.month = date.getMonth();
    this.year = date.getFullYear();

    if (!this.selectedDate) return false;

    const selectedYear = this.selectedDate.getFullYear();
    const selectedMonth = this.selectedDate.getMonth();
    const selectedDay = this.selectedDate.getDate();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    const currentDay = date.getDate();

    return (
      selectedYear === currentYear &&
      selectedMonth === currentMonth &&
      selectedDay === currentDay
    );
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  selectDate(date: Date): void {
    this.selectedDate = date;
    this.selectedDay = date.getDate();
  }

  // closePopup() {
  //   this.displayStyle = "none";
  // }

  getSelectedDayData(day: Date): any {
    let data = this.resobj.filter((stock: any) => {
      const stockDate = new Date(stock.date);
      return (
        stockDate.getDate() === day.getDate() &&
        stockDate.getMonth() === day.getMonth() &&
        stockDate.getFullYear() === day.getFullYear()
      );
    });

    this.detailData = data;
  }
  // openpopup(stocks: any) {
  //   this.detailData = []

  //   this.selectedStock = stocks;
  //   this.detailData.push(this.selectedStock)
  //   this.showDetailDataFlag = true
  //   // this.closePopupmo();
  // }

  // closePopupmo() {
  //   this.displayStylemobile = "none";
  //   this.backbtn = false
  // }

  openpopupmo(dayRef: any) {
    this.showDetailDataFlag = true;
    this.backbtn = true;
    this.mobpopupzindex = 999;
    // this.closePopup()
  }

  GetChildData(data: any) {
    this.modalDisplayStyle = data;
  }

  companysDetails(ticker: any) {
    if (this.productId) {
      this.modalDisplayStyle = true;
    } else {
      this.compamyDetails.getCompanyTable(ticker).subscribe({
        next: (res: any) => {
          this.router.navigate(['company', ticker]);
        },
      });
      this.compamyDetails.getCompanyTicker(ticker);
    }
  }

  toggle() {
    document.getElementById('startMonth')?.click();
  }

  selectedCap(data: string) {
    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }

    if (this.marketCapVal === data) {
      this.marketCapVal = '';
    } else {
      switch (data) {
        case 'small-cap': {
          this.marketCapVal = 'small-cap';
          break;
        }
        case 'mid-cap': {
          this.marketCapVal = 'mid-cap';
          break;
        }
        case 'micro-cap': {
          this.marketCapVal = 'micro-cap';
          break;
        }
        case 'large-cap': {
          this.marketCapVal = 'large-cap';
          break;
        }
        case 'mega-cap': {
          this.marketCapVal = 'mega-cap';
          break;
        }
      }
    }
    if (this.bySecVal === data) {
      this.bySecVal = '';
      this.filterArray = [];
    } else {
      switch (data) {
        case 'Biotechnology': {
          const Biotechnology = [
            'Biotechnology',
            'Tobacco',
            'Real Estate Services',
            'Chemicals',
            ' N/A',
            'Farm Products',
            'Food Distribution',
            'Packaged Foods',
          ];
          this.bySecVal = 'Biotechnology';
          this.filterArray = Biotechnology;
          break;
        }
        case 'Medical Device': {
          const Medical_Device = [
            'Medical Devices',
            'Medical Instruments & Supplies',
            'Electronic Components',
            'Scientific & Technical Instruments',
            'Health Information Services',
            'Medical Care Facilities',
            'Furnishings',
            'Fixtures & Appliances',
            'Medical Distribution',
          ];
          this.bySecVal = 'Medical Device';
          this.filterArray = Medical_Device;
          break;
        }
        case 'Diagnostics & Research': {
          const Diagnostics = ['Diagnostics & Research'];
          this.bySecVal = 'Diagnostics & Research';
          this.filterArray = Diagnostics;
          break;
        }
        case 'Drug Manufacturers': {
          const Drug_Manufacturers = [
            'Drug Manufacturers—General',
            'Drug Manufacturers—Specialty & Generic',
            'Household & Personal Products,Pharmaceutical Retailers',
          ];
          this.bySecVal = 'Drug Manufacturers';
          this.filterArray = Drug_Manufacturers;
          break;
        }
      }
    }

    this.getCalendarData();
  }

  closepopup() {
    this.modalDisplayStyle = false;
  }

  searchFilters(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;

    if (event.key === 'Escape' && !inputElement.value) {
      this.suggection = [];
      return;
    }

    if (!inputElement.value || !this.searchValuequery) {
      this.suggection = [];
      return;
    }

    this.compamyDetails.searchsuggection(inputElement.value).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res);

        const ParseDecryptedData = JSON.parse(decryptData);

        this.suggection = ParseDecryptedData;

        // this.symbol = res[0].symbol
      },
    });
  }

  onSearchInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;

    if (inputValue === '') {
      this.suggection = [];
    }
  }
}
