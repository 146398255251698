import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SignupService } from '../../services/signup.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { googleConfig } from 'src/environments/environment';
import { LoginService } from '../../services/login.service';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translateY(15px)', // At its original position
          opacity: 1,
          filter: 'blur(0px)', // No blur when fully visible
        })
      ),
      state(
        'out',
        style({
          transform: 'translateY(0px)', // 30px lower (off-screen)
          opacity: 0, // Transparent
          filter: 'blur(8px)', // Apply blur when sliding out
        })
      ),
      transition('out => in', [
        animate('1.8s cubic-bezier(0.34, 1.56, 0.64, 1)'),
      ]),
      transition('in => out', [
        animate('1.5s ease-out'), // Slow down ease-out slightly
      ]),
    ]),
  ],
})
export class SignupComponent {
  showPassword: boolean = false;
  protected signupform!: FormGroup;
  formsuccess = false;
  formfail = false;
  successMsg = '';
  errorMsg = '';
  siteKey: string = environment.sitekeyurl;
  submitted: boolean = false;
  displayStyle = 'none';
  varifyEmail!: FormGroup;
  verifyEmail!: string;
  verifypassword!: string;
  isLoading = false;
  otp: any;
  testimonials: {
    content: string;
    name: string;
    position: string;
    image: string;
    country?: string;
    address?: string;
  }[] = [
    {
      content:
        'Thanks to the searchable FDA calendar and PDUFA catalysts, I’ve been able to plan ahead and consistently capitalize on market-moving events. This service has played a crucial role in helping me generate steady profits!',
      name: 'John D',
      position: 'Individual Investor',
      image: '/assets/images/young-bearded-man-with-striped-shirt.jpg',
      country:
        'https://png.pngtree.com/png-vector/20230905/ourmid/pngtree-us-flag-sign-png-image_9947494.png',
      address: 'San Francisco, CA, USA',
    },
    {
      content:
        'The portfolio tracking tools have made managing my investments so much smoother. I can track catalysts and drug pipelines, and it’s helped me make better decisions that have really paid off. Definitely a great resource for anyone in biotech.',
      name: 'Samantha R',
      position: 'Portfolio Manager',
      image: '/assets/images/women.jpg',
      country:
        'https://upload.wikimedia.org/wikipedia/commons/3/3d/Flag_of_germany_800_480.png',
      address: 'Berlin, Germany',
    },
    {
      content:
        'Tracking biotech earnings and catalyst events is now easier than ever. I can quickly assess how different companies are performing and adjust my portfolio accordingly.',
      name: 'Emily K',
      position: 'Individual Investor',
      image:
        '/assets/images/girl-with-gray-sweater-blue-jeans-is-posing-photo.jpg',
      country:
        'https://png.pngtree.com/png-vector/20230905/ourmid/pngtree-us-flag-sign-png-image_9947494.png',
      address: 'Chicago, IL, USA',
    },
    {
      content:
        'As someone new to biotech investing, the tools have been a lifesaver. I’ve been able to make some solid gains just by following the market updates and tracking my portfolio closely. Highly recommend it!',
      name: 'Linda P',
      position: 'New Biotech Investor',
      image:
        'assets/images/portrait-charming-businesswoman-sitting-her-workplace-writing.jpg',
      country:
        'https://img.freepik.com/free-vector/illustration-uk-flag_53876-18166.jpg',
      address: 'London, UK',
    },
    {
      content:
        'Being able to monitor insider trading and hedge fund holdings has provided invaluable insights. This feature alone has helped me spot opportunities and avoid potential pitfalls.',
      name: 'Carlos M',
      position: 'API User',
      image: 'assets/images/bohemian-man-with-his-arms-crossed.jpg',
      country:
        'https://png.pngtree.com/png-vector/20230905/ourmid/pngtree-us-flag-sign-png-image_9947494.png',
      address: 'Boston, MA, USA',
    },
    {
      content:
        'The extensive research on over 1,100 companies and clinical trials is simply impressive. It’s helped me shape more informed, strategic plans for both investments and R&D purposes.',
      name: 'Michael L',
      position: 'Pharma Researcher',
      image:
        'https://media.istockphoto.com/id/1170953707/photo/smiling-black-man.jpg?s=612x612&w=0&k=20&c=lKqsRoMExYAnVtIw9fadM84rOPBhI_LVLCuRaBvstvo=',
      country:
        'https://img.freepik.com/free-vector/illustration-canada-flag_53876-27114.jpg',
      address: 'Toronto, Canada',
    },
  ];
  currentIndex = 0;
  currentState = 'in'; // This will track the current animation state
  displayedTestimonial = this.testimonials[0];

  constructor(
    private fb: FormBuilder,
    private signupData: SignupService,
    private router: Router,
    private toast: ToastrService,
    private activatedRoute: ActivatedRoute,
    private loginData: LoginService
  ) {}

  ngOnInit() {
    this.signupformdata();
    this.otp = this.activatedRoute.snapshot.paramMap.get('otp');
    this.otp && this.confirmEmail(this.otp);
    setInterval(() => {
      this.changeTestimonial();
    }, 8000); // Change every 3 seconds
  }

  signupformdata() {
    this.signupform = this.fb.group({
      fname: ['', Validators.required],
      // lname: ['', Validators.required],
      // mobile: [''],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^[\w.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z_\-\.]+\.[a-zA-Z]{2,}$/
          ),
        ],
      ],
      password: ['', [Validators.required, Validators.pattern(/^.{8,}$/)]],
      // recaptcha: ['', Validators.required],
      checkbox: [false, Validators.requiredTrue],
    });
    this.varifyEmail = this.fb.group({
      sinupToken: ['', Validators.required],
    });
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  signInWithGoogle(): void {
    googleConfig.googleClick();
  }

  changeTestimonial() {
    this.currentState = 'out';

    setTimeout(() => {
      this.currentIndex = (this.currentIndex + 1) % this.testimonials.length;
      this.displayedTestimonial = this.testimonials[this.currentIndex];
      this.currentState = 'in';
    }, 400); // Match this delay with the 'in => out' transition duration
  }

  signup(data: any) {
    this.submitted = true;
    this.verifyEmail = this.signupform.controls['email'].value;
    this.verifypassword = this.signupform.controls['password'].value;
    if (this.signupform.valid) {
      this.isLoading = true;
      this.signupData.postSignup(data).subscribe({
        next: (response) => {
          this.signupform.reset();

          this.displayStyle = 'block';
          // this.toast.warning('Verification link has been sent to your Email.');
          this.isLoading = false;
          this.submitted = false;
        },
        error: (error) => {
          if (error == 'Please verify email.') {
            this.displayStyle = 'block';
            // this.toast.warning(
            //   'Verification link has been sent to your Email.'
            // );
          } else {
            this.displayStyle = 'none';
          }
          this.isLoading = false;
          this.toast.error(error);
        },
      });
    }
  }

  savePopup() {
    var sinupToken = this.varifyEmail.controls['sinupToken'].value;
    var password = this.verifypassword;
    var email = this.verifyEmail;
    var loginData = { email, password };
    if (sinupToken == '') {
      this.toast.warning('Enter Valid Value !!');
      return;
    }
    this.isLoading = true;
    const data = { email, sinupToken };

    this.signupData.VerifyEmail(data).subscribe({
      next: (res) => {
        this.isLoading = false;
        JSON.stringify(res);
        this.loginData.postlogin(loginData).subscribe({
          next: (response) => {
            this.router.navigate(['subscription']);
          },
        });
        this.toast.success(`${res}`);
      },
      error: (err) => {
        this.isLoading = false;
        this.toast.error(err.error.msg);
      },
    });
  }

  confirmEmail(otp: any) {
    this.signupData.VerifyOtp(otp).subscribe({
      next: (res) => {
        this.toast.success(JSON.stringify(res));
        this.router.navigate(['login']);
      },
      error: (err) => {},
    });
  }

  closePopup() {
    this.displayStyle = 'none';
    this.varifyEmail.reset();
  }
}
