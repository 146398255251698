import { Injectable } from '@angular/core';
import { FDATable } from '../models/fda-calendar';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  addSymbol,
  getFdaData,
  getTenFdaData,
  removeSymbol,
  remove_price,
  votting_price,
  votting_priceTarget,
} from '../const';

@Injectable({
  providedIn: 'root',
})
export class FdaCalenarService {
  constructor(private http: HttpClient) {}

  getFdaData(page: number) {
    return this.http.post<FDATable[]>(`${getFdaData}${page}`, '');
  }

  // getTenFdaData() {
  //   return this.http.get<FDATable[]>(`${getTenFdaData}`);
  // }

  addSymboltoPotfoliyo(data: any) {
    return this.http.post(`${addSymbol}`, data);
  }
  removeSymboltoPotfoliyo(data: any) {
    return this.http.post(`${removeSymbol}`, data);
  }
  upvote(data: object) {
    return this.http.post(`${votting_price}`, data);
  }
  removeVote(data: object) {
    return this.http.post(`${remove_price}`, data);
  }
  vottingprice(data: object) {
    return this.http.post(`${votting_priceTarget}`, data);
  }
}
