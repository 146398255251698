<div class="container-fluid p-2 fda-header-box">
  <div class="text-center fda-text">
    <span class="sub-plan-text text-white m-0">Subscription Plan</span>
    <!-- <h4 class="text-white m-0 my-2 me-3">Plan & Pricing</h4> -->

    <h2 class=" text-white my-2 m-0"> Simple Pricing, Powerful Insights </h2>
    <p class=" text-white m-0">Full access to Biotech/Pharma trading opportunities.</p>
    <p class="sub-heading text-white m-0">
      No hidden fees—powerful insights, tools, and exclusive real-time data to maximize your bioteach profits.
    </p>
  </div>
</div>
<!-- <h2 class="text-center">Join BiopharmaWatch Today</h2> -->
<div class="my-3">
  <div class="d-flex plan_switch_div justify-content-center align-items-center">
    <!-- <h4 class="text-center m-0 mx-2">
      @if (this.isswitchplan) { Yearly }@else { Monthly }
    </h4> -->
    
    <div class="d-flex  align-items-center justify-content-center mx-2">
      <div class="billing-toggle">
        <button style="position: relative;" type="button" (click)="changePlan('annual')" [ngClass]="{'active': this.isswitchplan === 'annual' }">
          
          Annual billing
        
            <p class="text-center text-white two-coffees m-0">Save 20%</p>
         
        </button>
        <button type="button" (click)="changePlan('monthly')" [ngClass]="{'active': this.isswitchplan === 'monthly'}">Monthly billing</button>
    </div>
      <!-- <label class="switch">
        <input  type="checkbox" id="checkboxval" />
        <span class="slider round"></span>
      </label> -->
    

    </div>
    <!-- @if(this.isswitchplan === 'annual'){ -->
      <strong class="Coffeesslowgan text-center">< 2 Coffees a Month ☕☕</strong>
    <!-- } -->
  </div>
</div>
<div class="container">
  <div class="row justify-content-evenly">
    <!-- Basic Plan -->

    <div class="col-md-4 mb-4 highlighted">
      <div class="card plan-card text-center h-100">
        <div class="card-header">For Visitor</div>
        <div class="card-body">
          <div class="mb-2">
            <button
              (click)="makeorder('Basic', '482022')"
              class="btn btn-basic"
            >
              @if (isloader !== 'Basic') { Get Started } @else {
              <i class="fa fa-spin fa-spinner"></i>
              }
            </button>
          </div>
          <h5 class="card-title">$0.00</h5>
          <p class="card-text">
            BiopharmaWatch Basic
          </p>
          <p class="card-text">
            Experience the basics and discover why serious investors choose our
            Premium Plan tor convenensive data.
          </p>
          <ul class="list-unstyled">
            <li style="border-bottom: none">
              * Plan from Elite access for 7-day
            </li>
            <!-- <li>Data for over 650 biotech companies</li>
              <li>Limited Catalyst Calendar</li>
              <li>Limited PDUFA Calendar</li>
              <li>Limited Historical M&A Activity</li> -->
          </ul>
        </div>
        <!-- <div class="card-footer"></div> -->
      </div>
    </div>

    <!-- Elite Plus (Highlighted) -->
    <div class="col-md-4 mb-4">
      <div class="card plan-card text-center h-100">
        <div class="card-header">
          For Serious Investor and Traders
          <span class="badge badge-danger">(Most Popular)</span>
        </div>
        <div class="card-body">
          <div class="mb-2">
            <button
              (click)="makeorder('Elite plus', '515484')"
              class="btn btn-basic"
            >
              @if (isloader !== 'Elite plus') { Get Started } @else {
              <i class="fa fa-spin fa-spinner"></i>
              }
            </button>
          </div>
          <h5 class="card-title">
            @if (isswitchplan === 'annual') {
            <span>
              <span style="font-size: 18px"><strong>Only</strong> </span>
              $12.00
            </span>
            } @else {
            <span
              ><span style="font-size: 18px"><strong>Only</strong> </span>
              $15.00</span
            >
            }
          </h5>
          <p class="card-text">
            BiopharmaWatch Elite Plus
          </p>
          <p class="card-text">
            @if (this.isswitchplan) { Your Key to Consistent Profits: Save Big
            with Our Annual Plan at Just $12.00 per Month }@else { Unlock
            BiopharmaWatch Elite Plus for unlimited access to our powerful
            tools. }
          </p>
          <ul class="list-unstyled">
            <div>
              <li>
                <div class="d-flex align-items-start">
                  <i
                    style="color: rgb(7, 175, 7)"
                    class="fa-solid fa-circle-check mx-2 mt-1"
                  ></i>

                  <span class="text-center"
                    >Searchable FDA calendar providing data readouts and PDUFA catalysts.
                    </span
                  >
                </div>
              </li>
              <li>
                <div class="d-flex align-items-start">
                  <i
                    style="color: rgb(7, 175, 7)"
                    class="fa-solid fa-circle-check mx-2 mt-1"
                  ></i>
                  <span class="text-center"
                    >Full access to drug pipelines and company health for informed decisions.
                    </span
                  >
                </div>
              </li>
              <li>
                <div class="d-flex align-items-start">
                  <i
                    style="color: rgb(7, 175, 7)"
                    class="fa-solid fa-circle-check mx-2 mt-1"
                  ></i>
                  <span class="text-center"
                    >Track biotech earnings and critical catalyst events with ease.
                    </span
                  >
                </div>
              </li>
              <li>
                <div class="d-flex align-items-start">
                  <i
                    style="color: rgb(7, 175, 7)"
                    class="fa-solid fa-circle-check mx-2 mt-1"
                  ></i>
                  <span class="text-center"
                    >Monitor insider trading and hedge fund holdings for market insights.
                    </span
                  >
                </div>
              </li>
              <li>
                <div class="d-flex align-items-start">
                  <i
                    style="color: rgb(7, 175, 7)"
                    class="fa-solid fa-circle-check mx-2 mt-1"
                  ></i>
                  <span class="text-center"
                    >Advanced portfolio management tools for smart biotech investments.</span
                  >
                </div>
              </li>
            </div>
            <div>
              <li>
                <div class="d-flex align-items-start">
                  <i
                    style="color: rgb(7, 175, 7)"
                    class="fa-solid fa-circle-check mx-2 mt-1"
                  ></i>
                  <span class="text-center"
                    >Research on over 1,100 companies and clinical trials.
                  </span>
                </div>
              </li>
              <li>
                <div class="d-flex align-items-start">
                  <i
                    style="color: rgb(7, 175, 7)"
                    class="fa-solid fa-circle-check mx-2 mt-1"
                  ></i>
                  <span class="text-center"
                    >Access historical FDA decisions to shape strategic plans.
                    </span
                  >
                </div>
              </li>
              <li>
                <div class="d-flex align-items-start">
                  <i
                    style="color: rgb(7, 175, 7)"
                    class="fa-solid fa-circle-check mx-2 mt-1"
                  ></i>
                  <span class="text-center"
                    >Get real-time market updates, trends, and analysis to stay competitive.
                    </span
                  >
                </div>
              </li>
              <li>
                <div class="d-flex align-items-start">
                  <i
                    style="color: rgb(7, 175, 7)"
                    class="fa-solid fa-circle-check mx-2 mt-1"
                  ></i>
                  <span class="text-center"
                    >Learn with exclusive courses on biotech investment basics.


                    </span
                  >
                </div>
              </li>

            </div>
            <!-- <li>M&A Stock Ideas</li>
        <li>7-day free trial</li> -->
          </ul>
        </div>
        <!-- <div class="card-footer"></div> -->
      </div>
    </div>
    
  </div>
</div>

<section class="d-flex justify-content-center mb-5">
  <div class="testimonial-card" [@slideInOut]="currentState">
    <div class="stars text-center ms-2 mb-2">
      &#9733;&#9733;&#9733;&#9733;&#9733;
    </div>
    <div class="d-flex justify-content-center align-items-center">
      <div>
        <img
          class="user-info-img"
          [src]="displayedTestimonial.image"
          alt="User Photo"
        />
      </div>
      <div>
        <h4 class="m-0">{{ displayedTestimonial.name }}</h4>
        <span>{{ displayedTestimonial.position }}</span>
      </div>
    </div>
    <p class="my-2">{{ displayedTestimonial.content }}</p>
  </div>
</section>

    <div class="text-center">
      <h3 class="main-blue-heading">FAQs</h3>
    </div>

    <section class="container-fluid Information p-0 mt-3 mb-3">
      <div class="accordion-key">
        <div class="mt-3">
          <div class="accordion" id="accordionExample">
            <!-- Accordion Item 1 -->
            <div class="accordion-item key-tab">
              <div class="container">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed header-text"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <span class="number-key">01</span> What sources are used for drug and catalyst information?

                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    We gather data from trusted sources such as SEC filings, company press releases, corporate slide decks, scientific publications, and posters presented at industry conferences.

                  </div>
                </div>
              </div>
            </div>
            <!-- Accordion Item 2 -->
            <div class="accordion-item key-tab">
              <div class="container">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed header-text"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <span class="number-key">02</span> Which companies are listed on BiopharmaWatch.com?

                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    We cover all biotech companies listed on NASDAQ and NYSE. We're constantly expanding our database, and if you're an Elite member, you can request additional companies or assets within our coverage range.

                  </div>
                </div>
              </div>
            </div>
    
            <!-- Accordion Item 3 -->
            <div class="accordion-item key-tab">
              <div class="container">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed header-text"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <span class="number-key">03</span> How often is the data updated?

                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Catalyst data is manually updated twice daily, while the rest of the data, including real-time financials and market trends, is updated continuously throughout the day.

                  </div>
                </div>
              </div>
            </div>
            <!-- Accordion Item 4 -->
            <div class="accordion-item key-tab">
              <div class="container">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed header-text"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    <span class="number-key">04</span> What catalyst events are listed on BiopharmaWatch.com?

                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    We list catalyst events for assets ranging from preclinical stages to clinical phases, including PDUFA dates for companies in our database. As a Pro member, you can request the addition of any biotech company or drug to our database.
                  </div>
                </div>
              </div>
            </div>
            <!-- Accordion Item 5 -->
            <div class="accordion-item key-tab">
              <div class="container">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button collapsed header-text"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    <span class="number-key">05</span> Are there any discounts for annual subscription plans?

                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Yes! We’re offering a 20% discount on our annual subscription plan for a limited time. Don’t miss out—grab it while it lasts!

                  </div>
                </div>
              </div>
            </div>
    
            <div class="accordion-item key-tab">
              <div class="container">
                <h2 class="accordion-header" id="headingSix">
                  <button
                    class="accordion-button collapsed header-text"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    <span class="number-key">06</span> Can I cancel my BiopharmaWatch Pro membership?

                  </button>
                </h2>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Yes, you can cancel anytime through your ‘My Subscription’ page. Simply click your profile picture in the top right menu. You can also reach out to us at <a href="mailto:contact@biopharmawatch.com">contact&#64;biopharmawatch.com</a> for assistance.

                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item key-tab">
              <div class="container">
                <h2 class="accordion-header" id="headingSeven">
                  <button
                    class="accordion-button collapsed header-text"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    <span class="number-key">07</span> What does a free account include?

                  </button>
                </h2>
                <div
                  id="collapseSeven"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSeven"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    A free account provides access to all core features available in BiopharmaWatch Elite Plus, including basic tools and insights.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

<app-loginpop-up
  [displayStyle]="modalDisplayStyle"
  (myout)="GetChildData($event)"
></app-loginpop-up>
