import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { getblogTable } from '../const';

@Injectable({
  providedIn: 'root'
})
export class BlogUserService {

  constructor(private http: HttpClient) { }

  getblogTable(blogId: string | null) {
    return this.http.get<any[]>(`${getblogTable}${blogId}`).pipe((data: any) => {
      return data;
    })
  }
}
