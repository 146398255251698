import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPO, pastIPO } from '../const';

@Injectable({
  providedIn: 'root'
})
export class IpoServiceService {

  constructor(private http: HttpClient) { }

  getIpoData() {
    return this.http.get(`${IPO}`);
  }
  getPastipoData() {
    return this.http.get(`${pastIPO}`);
  }
}
