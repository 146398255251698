import { Component } from '@angular/core';
import { PdufaCalendarService } from '../../../shared/service/pdufa-calendar.service';
import { CompanyDetailsService } from '../../../shared/service/company-details.service';
import { Router } from '@angular/router';
import { DecryptionService } from 'src/app/modules/shared/service/decryption.service';
import { environment } from 'src/environments/environment';
import { BioCelenderService } from 'src/app/modules/shared/service/bio-celender.service';

@Component({
  selector: 'app-pdufa-calendar',
  templateUrl: './pdufa-calendar.component.html',
  styleUrls: ['./pdufa-calendar.component.scss'],
})
export class PdufaCalendarComponent {
  [x: string]: any;
  detailData: any;
  sidepannelArray: any;
  searchValuequery: any;
  suggection: any;

  monthName: any;
  requestBody: any;
  currentMonth: Date = new Date();
  selectedDate: Date | null = null;
  selectedDay!: number;
  weekdays: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  calendarDays: Date[] = [];
  displayStyle = 'none';
  displayStylemobile = 'none';
  date!: number;
  isLoading = true;
  year!: number;
  month!: number;
  resobj: any;
  productId: any;
  backbtn = false;
  mobpopupzindex!: number;
  modalDisplayStyle: boolean = false;
  loginuser = localStorage.getItem('loggedInUser');
  selectedStock: {
    title: string;
    description: string;
    prev_earnings_per_share: number;
    symbol: string;
  } | null = null;
  attributes = {
    disabled: false,
  };
  showloginWarning = false;
  showDetailDataFlag = false;
  pdufascrollData = 'data-get-scroll';
  basePath: string = 'https://s3-symbol-logo.tradingview.com/';
  bsConfig: object = {
    dateInputFormat: 'YYYY-MM',
    minMode: 'month',
    containerClass: 'theme-dark-blue',
    isAnimated: true,
  };
  dateModel = new Date();
  clientUrl = environment.clientUrl;

  constructor(
    private Pdufaservice: PdufaCalendarService,
    private compamyDetails: CompanyDetailsService,
    private router: Router,
    private decryptionService: DecryptionService,
    private bioservice: BioCelenderService
  ) {}

  ngOnInit(): void {
    this.generateCalendarDays();
    this.getCalendarData();
    this.returnLogin();
  }

  returnLogin() {
    let loign = localStorage.getItem('loggedInUser');
    if (!loign) {
      this.showloginWarning = true;
    }
  }

  selectdateforcalendar() {
    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }
  }

  onMonthYearChange(event: Event): void {
    this.currentMonth = new Date(this.dateModel);
    this.generateCalendarDays();
    this.getCalendarData();
    this.selectdateforcalendar();
  }

  generateCalendarDays(): void {
    this.calendarDays = [];
    const year = this.currentMonth.getFullYear();
    const month = this.currentMonth.getMonth();
    const lastDayOfMonth = new Date(year, month + 1, 0);

    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      const currentDate = new Date(year, month, i);
      this.calendarDays.push(currentDate);
    }
  }

  getCalendarData() {
    this.showDetailDataFlag = false;
    this.requestBody = {
      year: this.currentMonth.getFullYear().toString(),
      month: ('0' + (this.currentMonth.getMonth() + 1)).slice(-2),
    };
    var monthstring = this.requestBody.month;
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    this.monthName = monthNames[monthstring - 1];

    this.isLoading = true;

    this.Pdufaservice.bioclender(this.requestBody).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);
        const ParseDecryptedData = JSON.parse(decryptData);

        this.resobj = ParseDecryptedData.data;
        this.productId = ParseDecryptedData.productId;
        this.isLoading = false;
        this.detailData = ParseDecryptedData.data;
        this.sidepannelArray = ParseDecryptedData.data;
      },
      error: (err) => {
        this.isLoading = false;
        this.resobj = [];
        this.detailData = [];

      },
    });
  }

  getMatchingStocks(day: Date): any[] {
    return this.resobj.filter((stock: any) => {
      const stockDate = new Date(stock.catdate);
      return (
        stockDate.getDate() === day.getDate() &&
        stockDate.getMonth() === day.getMonth() &&
        stockDate.getFullYear() === day.getFullYear()
      );
    });
  }

  getEmptyDaysBeforeFirstDay(): number[] {
    const firstDayOfMonth = this.calendarDays[0];
    const dayOfWeek = firstDayOfMonth.getDay();
    const emptyDays = [];
    for (let i = 0; i < dayOfWeek; i++) {
      emptyDays.push(i);
    }
    return emptyDays;
  }

  prevMonth(): void {
    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }
    this.showDetailDataFlag = false;

    const prevMonthDate = new Date(this.currentMonth);
    prevMonthDate.setMonth(prevMonthDate.getMonth() - 1);
    this.currentMonth = prevMonthDate;
    this.generateCalendarDays();
    this.getCalendarData();
  }

  nextMonth(): void {
    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }
    this.showDetailDataFlag = false;
    const nextMonthDate = new Date(this.currentMonth);
    nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
    this.currentMonth = nextMonthDate;
    this.generateCalendarDays();
    this.getCalendarData();
  }

  isToday(date: Date): boolean {
    const today = new Date();
    return date.toDateString() === today.toDateString();
  }

  isSelected(date: Date): boolean {
    this.date = date.getDate();
    this.month = date.getMonth();
    this.year = date.getFullYear();

    if (!this.selectedDate) return false;

    const selectedYear = this.selectedDate.getFullYear();
    const selectedMonth = this.selectedDate.getMonth();
    const selectedDay = this.selectedDate.getDate();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    const currentDay = date.getDate();

    return (
      selectedYear === currentYear &&
      selectedMonth === currentMonth &&
      selectedDay === currentDay
    );
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  selectDate(date: Date): void {
    this.selectedDate = date;
    this.selectedDay = date.getDate();
  }

  getSelectedDayData(day: Date): any {
    let data = this.resobj.filter((stock: any) => {
      const stockDate = new Date(stock.catdate);
      return (
        stockDate.getDate() === day.getDate() &&
        stockDate.getMonth() === day.getMonth() &&
        stockDate.getFullYear() === day.getFullYear()
      );
    });

    this.detailData = data;

    // this.closePopupmo();
  }

  showDetailData() {
    this.detailData = this.sidepannelArray;
    this.searchValuequery = '';
  }

  openpopupmo(dayRef: any) {
    this.showDetailDataFlag = true;
    this.backbtn = true;
    this.mobpopupzindex = 999;
  }

  GetChildData(data: any) {
    this.modalDisplayStyle = data;
  }

  companysDetails(ticker: any) {
    if (!this.productId) {
      this.modalDisplayStyle = true;
    } else {
      this.compamyDetails.getCompanyTable(ticker).subscribe({
        next: (res: any) => {
          this.router.navigate(['company', ticker]);
        },
      });
      this.compamyDetails.getCompanyTicker(ticker);
    }
  }

  toggle() {
    document.getElementById('startMonth')?.click();
  }

  closepopup() {
    this.modalDisplayStyle = false;
  }

  getQueryData(searchQuery: any) {
    let searchValue = {
      query: searchQuery,
      month:
        this.month + 1 < 10 ? '0' + (this.month + 1) : String(this.month + 1),
      year: String(this.year),
    };

    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }

    this.bioservice.pudaCalenderSearch(searchValue).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);
        const ParseDecryptedData = JSON.parse(decryptData);

        // this.resobj = ParseDecryptedData.data;
        this.productId = ParseDecryptedData.productId;
        this.isLoading = false;
        this.showDetailDataFlag = false;
        this.detailData = ParseDecryptedData.data;
        this.suggection = [];
        this.searchValuequery = '';
        // this.detailData.sort(
        //   (a: any, b: any) =>
        //     new Date(a.date).getTime() - new Date(b.date).getTime()
        // );
      },
      error: (err) => {
        this.isLoading = false;
        this.suggection = [];
        // this.toaster.error(err.error.message);
      },
    });
  }

  searchFilters(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;

    if (event.key === 'Escape' && !inputElement.value) {
      this.suggection = [];
      return;
    }

    if (!inputElement.value || !this.searchValuequery) {
      this.suggection = [];
      return;
    }

    this.compamyDetails.searchsuggection(inputElement.value).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res);

        const ParseDecryptedData = JSON.parse(decryptData);

        this.suggection = ParseDecryptedData;

        // this.symbol = res[0].symbol
      },
    });
  }

  onSearchInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;

    if (inputValue === '') {
      this.suggection = [];
    }
  }
}
