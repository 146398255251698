import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FilterdataService } from 'src/app/modules/shared/service/filterdata.service';
import { AdminFdaServiceService } from '../../services/admin-fda.service.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@Component({
  selector: 'app-fda-calender-admin',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
  templateUrl: './fda-calender-admin.component.html',
  styleUrl: './fda-calender-admin.component.scss'
})
export class FdaCalenderAdminComponent {
  admin: any;
  treatment: any;
  description: any;
  catalyst: any;
  stage: any;
  catdate: any;
  name: any;
  ticker: any;
  drugname: any;
  catalystId: any;
  searchValuequery: any;
  form!: FormGroup;
  suggection: any
  isFilterBtnSearch = false;

  // arr!: FormArray;
  showtable: boolean = false;

  constructor(private adminservies: AdminFdaServiceService, private fb: FormBuilder, private toaster: ToastrService, private filter: FilterdataService) {
    this.form = this.fb.group({
      items: this.fb.array([])
    });

    // Initialize the form with one empty item
    this.addItem();
  }

  ngOnInit(): void {
    this.fdaData()
    // this.myForm = this.fb.group({
    //   arr: this.fb.array([this.createItem()])
    // })


  }

  fdaData() {
    this.admin = []
    this.adminservies.getFdaadminData().subscribe({
      next: (data) => {
        this.admin = data;
      },
      error: (error) => {
      }
    })
  }

  editData(data: any) {
    this.catalystId = data._id;
    this.ticker = data.ticker; // disable
    this.catalyst = data.catalyst;
    this.catdate = data.catdate;
    this.description = data.description;
    this.drugname = data.drugname;
    this.name = data.name; // disable
    this.stage = data.stage;
    this.treatment = data.treatment;
  }

  updateChanges() {
    const updatedData = {
      id: this.catalystId,
      ticker: this.ticker,
      name: this.name,
      catalyst: this.catalyst,
      catdate: this.catdate,
      description: this.description,
      drugname: this.drugname,
      stage: this.stage,
      treatment: this.treatment
    }
    this.adminservies.editFdaadminData(updatedData).subscribe({
      next: ((res: any) => {
        this.toaster.success(res);
        this.fdaData()
      })
    })
  }

  deleteRecord(id: any) {
    let dataid = {
      id: id

    }
    // return
    let conform = confirm('Are you sure you want to delete this record?');
    if (!conform) {
      return;
    }
    this.adminservies.deleteFdaadminData(dataid).subscribe({
      next: (res: any) => {
        this.toaster.success(res);

        this.fdaData()
      }
    })

  }

  createItem() {
    return this.fb.group({
      lessons: this.fb.array([])
    })
  }

  addItem() {
    const itemFormGroup = this.fb.group({
      ticker: [''],
      name: [''],
      catalyst: [''],
      catdate: [''],
      description: [''],
      drugname: [''],
      stage: [''],
      treatment: ['']
    });
    this.items.push(itemFormGroup);
  }
  removeItem(index: number) {
    this.items.removeAt(index);
  }
  onSubmit() {


    this.adminservies.addFdaadminData(this.form.value.items).subscribe({
      next: (res: any) => {
        this.toaster.success(res);

        this.fdaData();
      },
      error: (err: any) => {
        (this.toaster as any).error("Error to Add Data");
      },
    })
  }

  get items() {
    return this.form.get('items') as FormArray;
  }
  showtablerow() {
    this.showtable = !this.showtable;
  }


  searchFilters(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;

    if (event.key === 'Escape' && !inputElement.value) {
      this.suggection = [];
      return;
    }

    if (!inputElement.value || !this.searchValuequery) {
      this.suggection = [];
      return;
    }

    this.adminservies.searchsuggection(inputElement.value).subscribe({
      next: ((res: any) => {
        this.suggection = res
        // this.symbol = res[0].symbol
      })
    })
  }

  onSearchInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;

    if (inputValue === '') {
      this.suggection = [];
    }
  }

  searchFiltersonclick(query: any) {
    if (query == '') {
      this.toaster.warning('Enter Valid Inputs.');
      return;
    }

    let search =
    {
      query,
    }
    this.suggection = [];
    this.searchValuequery = ''

    // this.isLoading = true;
    this.isFilterBtnSearch = true;
    // this.isFilter = true

    this.admin = [];

    this.adminservies.fdasearchadmin(search).subscribe({
      next: (res: any) => {
        this.isFilterBtnSearch = false;
        this.admin = res;
        this.suggection = [];
      },
      error: (err: any) => {
        this.isFilterBtnSearch = false;
        this.searchValuequery = ""
        this.admin = [];
        this.fdaData();
        this.toaster.error(err.error.message);
      },
    });
  }

}