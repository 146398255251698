import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getblogdata, saveblog } from '../const';
@Injectable({
  providedIn: 'root',
})
export class BlogService {
  constructor(private http: HttpClient) {}

  getblogdata() {
    return this.http.get<any[]>(`${getblogdata}`);
  }

  saveblogdata(blogdata: any) {
    return this.http.post<any[]>(`${saveblog}`, blogdata);
  }
}
