import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ProfileService } from 'src/app/modules/core/services/profile.service';
import { WishlistServiesService } from 'src/app/modules/shared/service/wishlist-servies.service';
import { ToastrService } from 'ngx-toastr';
import { FdaCalenarService } from 'src/app/modules/shared/service/fda-calendar.service';
import { DecryptionService } from 'src/app/modules/shared/service/decryption.service';
import { CompanyDetailsService } from 'src/app/modules/shared/service/company-details.service';
import { News } from 'src/app/modules/shared/models/news.model';
import { NewsService } from 'src/app/modules/shared/service/news.service';
import { Router } from '@angular/router';
// import { isFunctionCompany } from 'ngx-bootstrap/chronos/utils/type-checks';
@Component({
  selector: 'app-myportfolio',
  templateUrl: './myportfolio.component.html',
  styleUrls: ['./myportfolio.component.scss'],
})
export class MyportfolioComponent implements OnInit {
  @ViewChild('overview') overview!: ElementRef;
  @ViewChild('insidetrades') insidetrades!: ElementRef;
  @ViewChild('bioteach') bioteach!: ElementRef;
  @ViewChild('drugpipe') drugpipe!: ElementRef;
  @ViewChild('hedge') hedge!: ElementRef;
  @ViewChild('optiondata') optiondata!: ElementRef;
  @ViewChild('catalyst') catalyst!: ElementRef;
  @ViewChild('newsSection') newsSection!: ElementRef;

  chartdataarray: any;
  totalDatalengthofCatalyst: number = 0;
  totalDatalengthofdrugpipe: number = 0;
  totalDatalengthofinside: number = 0;
  totalDatalengthofbiotech: number = 0;
  totalDatalengthofhedgeFund: number = 0;
  catalystData: any = [];
  drugpipeData: any = [];
  insideData: any = [];
  biotechData: any = [];
  hedgeFundData: any = [];
  optionData: any = [];
  optionDataPuts: any = [];
  portfolioData: any = [];
  chart: any;
  startMonth!: string;
  endMonth!: string;
  fullName: string = '';
  isLoading: boolean = true;
  isLoadingforHedge: boolean = true;
  isLoadingforOptions: boolean = true;
  isLoadingforCatalyst: boolean = true;
  isLoadingforBioTeach: boolean = true;
  isLoadingforinside: boolean = true;
  currentPage = 1;
  currentPageininside = 1;
  currentPagebiotech = 1;
  currentPagedrugpipeline = 1;
  currentPagehedgefund = 1;
  currentPageOptions = 1;
  loadData!: boolean;
  isCatalystDataScroll: boolean = false;
  isinsideDataScroll: boolean = false;
  isdrugpipescroll: boolean = false;
  ishedgescroll: boolean = false;
  isbiotechDataScroll: boolean = false;
  customclassforcatalyst = 'table-responsive fitcontenttable';
  customclassforinsides = 'table-responsive fitcontenttable';
  customclassforbioteach = 'table-responsive fitcontenttable';
  customclassfordrugpipe = 'table-responsive fitcontenttable';
  customclassforhedgefund = 'table-responsive fitcontenttable';
  customclassforoption = 'table-responsive fitcontenttable';
  customclassfornews = 'table-responsive fitcontenttable';
  customclassforoverview = 'table-responsive fitcontenttable';

  basePath: string = 'https://s3-symbol-logo.tradingview.com/';
  totalInvestedval = 0;
  totalcostval = 0;
  totalprofit = 0;
  portfoliomatchTicker: any[] = [];

  emptyView!: boolean;
  searchValuequery: any;
  suggection: any;
  portfoliocost: any;
  portfolioquntity: any;
  newsItems: News[] = [];
  newsItem: any;
  displayStyle = 'none';
  productId: any;

  tableCatalyst = [
    { name: 'Symbol', valuename: 'ticker', isFunctionCompany: true },
    { name: 'Name', valuename: 'name' },
    { name: 'Close', valuename: 'close' },
    { name: 'Change', valuename: 'change' },
    { name: 'Catalyst Date', valuename: 'catdate' },
    { name: 'Phase', valuename: 'stage' },
    { name: 'Drug Name', valuename: 'drugname' },
    { name: 'treatment', valuename: 'treatment' },
  ];

  tableInside = [
    { name: 'Symbol', valuename: 'symbol', isFunctionCompany: true },
    { name: 'TRANSACTOR NAME', valuename: 'name' },
    { name: 'TRADED SHARES', valuename: 'change' },
    { name: 'TRADE PRICE	', valuename: 'transactionPrice' },
    { name: 'TRADE DATE', valuename: 'transactionDate' },
    { name: 'CURRENT HOLDINGS', valuename: 'share' },
  ];

  tableBiotech = [
    { name: 'Symbol', valuename: 'symbol', isFunctionCompany: true },
    { name: 'Name', valuename: 'name' },
    { name: 'Date', valuename: 'date' },
    { name: 'Title	', valuename: 'title' },
    {
      name: 'Earnings per Share (Basic TTM)',
      valuename: 'earnings_per_share_basic_ttm',
    },
    { name: 'Industry', valuename: 'Industry' },
    { name: 'Market Cap (Basic)', valuename: 'market_cap_basic' },
  ];

  tableDrugpipe = [
    { name: 'Symbol', valuename: 'ticker', isFunctionCompany: true },
    { name: 'pipeline', valuename: 'InterventionName' },
    { name: 'CONDITION', valuename: 'Condition' },
    { name: 'PHASE	', valuename: 'Phase' },
    {
      name: 'CATALYST DATE',
      valuename: 'CompletionDate',
    },
    { name: 'Study Title', valuename: 'BriefTitle' },
    { name: 'NCT Id', valuename: 'nctId' },
  ];

  tableHedgeFund = [
    { name: 'STOCK', valuename: 'symbol', isFunctionCompany: true },
    { name: 'Rank', valuename: 'rank' },
    { name: 'Share Held', valuename: 'current_shares' },
    { name: 'Market Value	', valuename: 'current_mv' },
    {
      name: 'Hedge Fund Name',
      valuename: 'hedgeFundName',
    },
  ];

  tableOptionData = [
    { name: 'EXPIRATION', valuename: 'date', isFunctionCompany: true },
    { name: 'Symbol', valuename: 'symbol' },
    { name: 'Total Volume', valuename: 'totalVolume' },
    { name: 'Total Interest	', valuename: 'totalopenInterest' },
  ];

  tablePortfolio = [
    { name: 'Ticker', valuename: 'ticker', isFunctionCompany: true },
    { name: 'Purchase Date', valuename: 'purchaseDate' },
    { name: 'Cost per share', valuename: 'cps' },
    { name: 'Price per share	', valuename: 'pps' },
    { name: 'Earning per share	', valuename: 'eps' },
    { name: 'Holdings', valuename: 'holdings' },
    { name: 'Total Cost', valuename: 'totalCost' },
    { name: 'Total Price', valuename: 'totalPrice' },
    { name: 'Total Profit', valuename: 'totalProfit' },
  ];

  constructor(
    private newsService: NewsService,
    private profileService: ProfileService,
    private wishlistServiesService: WishlistServiesService,
    private toaster: ToastrService,
    private fdacalendarservice: FdaCalenarService,
    private decryptionService: DecryptionService,
    private compamyDetails: CompanyDetailsService,
    private datePipe: DatePipe,
    private router: Router
  ) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    this.startMonth = `${currentYear}-01`;
    this.endMonth = `${currentYear}-12`;
  }

  ngOnInit() {
    this.getloginData();
    this.drugpipeWislist();
    this.getCatalystwishlist();
    this.getinsidewishlist();
    this.BioTechwishlist();
    this.hedgefundWishlist();
    this.getOptionData();
    this.loadNewsData();
    this.portfolioOverview();
    this.emptyMsg();
  }

  transformDate(dateString: any) {
    const datePart =
      dateString.split(' ')[0] +
      ' ' +
      dateString.split(' ')[1] +
      ' ' +
      dateString.split(' ')[2];
    const date = new Date(datePart);
    return this.datePipe.transform(date, 'MMMM d, y');
  }

  getloginData(): void {
    var profileid = JSON.parse(localStorage.getItem('loggedInUser') || '{}').id;

    this.profileService.getProfile(profileid).subscribe({
      next: (res: any) => {
        res.data.lname
          ? (this.fullName = res.data.fname + ' ' + res.data.lname)
          : (this.fullName = res.data.fname);
      },
    });
  }

  scrollToSection(section: any): void {
    if (section && section.nativeElement) {
      section.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  getCatalystwishlist() {
    this.isLoadingforCatalyst = true;

    this.wishlistServiesService
      .getCatalystwishlist(this.currentPage)
      .subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res.result);

          const ParseDecryptedData = JSON.parse(decryptData);

          this.productId = ParseDecryptedData.find(
            (x: any) => x.productId
          )?.productId;

          this.isLoadingforCatalyst = false;
          this.currentPage = 2;
          this.totalDatalengthofCatalyst = ParseDecryptedData[0].total[0].count;
          this.catalystData = ParseDecryptedData[0].data;
          let lockedArray: any[] = [];

          for (let i = 0; i < 4; i++) {
            lockedArray.push(ParseDecryptedData[0].data);
            lockedArray = lockedArray.flat();
          }

          if (!this.productId) {
            this.catalystData = [
              {
                ticker: 'locked',
                name: 'locked',
                close: 'locked',
                change: 'locked',
                catdate: 'locked',
                stage: 'locked',
                drugname: 'locked',
                treatment: 'locked',
              },
              {
                ticker: 'locked',
                name: 'locked',
                close: 'locked',
                change: 'locked',
                catdate: 'locked',
                stage: 'locked',
                drugname: 'locked',
                treatment: 'locked',
              },
              {
                ticker: 'locked',
                name: 'locked',
                close: 'locked',
                change: 'locked',
                catdate: 'locked',
                stage: 'locked',
                drugname: 'locked',
                treatment: 'locked',
              },
            ];
            this.catalystData.forEach((element: any) => {
              element.change = element.change.toFixed(2);
              element.close = element.close.toFixed(2);
            });
          }

          if (ParseDecryptedData[0].data.length > 13) {
            return (this.customclassforcatalyst =
              'table-responsive scrollable-table');
          } else {
            return (this.customclassforcatalyst =
              'table-responsive fitcontenttable');
          }
        },
      });
  }

  getinsidewishlist() {
    this.isLoadingforinside = true;

    this.wishlistServiesService
      .getinsidewishlist(this.currentPageininside)
      .subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res.result);

          const ParseDecryptedData = JSON.parse(decryptData);
          this.productId = ParseDecryptedData.find(
            (x: any) => x.productId
          )?.productId;

          this.isLoadingforinside = false;
          this.currentPageininside = 2;
          this.totalDatalengthofinside = ParseDecryptedData[0].total.count;

          this.insideData = ParseDecryptedData[0].data;
          let lockedArray: any[] = [];

          for (let i = 0; i < 4; i++) {
            lockedArray.push(ParseDecryptedData[0].data);
            lockedArray = lockedArray.flat();
          }

          if (!this.productId) {
            this.insideData = lockedArray;
          }

          if (ParseDecryptedData[0].data.length > 15) {
            return (this.customclassforinsides =
              'table-responsive scrollable-table');
          } else {
            return (this.customclassforinsides =
              'table-responsive fitcontenttable');
          }
        },
      });
  }

  BioTechwishlist() {
    this.isLoadingforBioTeach = true;

    this.wishlistServiesService
      .getBiotechwishlist(this.currentPagebiotech)
      .subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res.encrypted);

          const ParseDecryptedData = JSON.parse(decryptData);

          this.productId = ParseDecryptedData.find(
            (x: any) => x.productId
          )?.productId;

          this.isLoadingforBioTeach = false;
          this.currentPagebiotech = 2;
          this.totalDatalengthofbiotech = ParseDecryptedData[0].total[0].count;

          this.biotechData = ParseDecryptedData[0].data;

          let lockedArray: any[] = [];

          for (let i = 0; i < 4; i++) {
            lockedArray.push(ParseDecryptedData[0].data);
            lockedArray = lockedArray.flat();
          }

          if (!this.productId) {
            this.biotechData = lockedArray;
          }

          if (ParseDecryptedData[0].data.length > 13) {
            return (this.customclassforbioteach =
              'table-responsive scrollable-table');
          } else {
            return (this.customclassforbioteach =
              'table-responsive fitcontenttable');
          }
        },
      });
  }

  drugpipeWislist() {
    this.isLoading = true;

    this.wishlistServiesService
      .getDrugwishlist(this.currentPagedrugpipeline)
      .subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res.result);
          const ParseDecryptedData = JSON.parse(decryptData);
          this.isLoading = false;
          this.currentPagedrugpipeline = 2;
          this.totalDatalengthofdrugpipe = ParseDecryptedData[0].total[0].count;
          this.drugpipeData = ParseDecryptedData[0].data;

          this.productId = ParseDecryptedData.find(
            (x: any) => x.productId
          )?.productId;

          let lockedArray: any[] = [];

          for (let i = 0; i < 4; i++) {
            lockedArray.push(ParseDecryptedData[0].data);
            lockedArray = lockedArray.flat();
          }

          if (!this.productId) {
            this.drugpipeData = lockedArray;
          }

          if (ParseDecryptedData[0].data.length > 9) {
            return (this.customclassfordrugpipe =
              'table-responsive scrollable-table');
          } else {
            return (this.customclassfordrugpipe =
              'table-responsive fitcontenttable');
          }
        },
      });
  }

  hedgefundWishlist() {
    this.isLoadingforHedge = true;

    this.wishlistServiesService
      .getHedgeFundwishlist(this.currentPagehedgefund)
      .subscribe({
        next: (res: any) => {
          const decryptData = this.decryptionService.decrypt(res.result);

          const ParseDecryptedData = JSON.parse(decryptData);

          this.isLoadingforHedge = false;
          this.currentPagehedgefund = 2;
          this.hedgeFundData = ParseDecryptedData[0].data;

          this.productId = ParseDecryptedData.find(
            (x: any) => x.productId
          )?.productId;

          let lockedArray: any[] = [];

          for (let i = 0; i < 4; i++) {
            lockedArray.push(ParseDecryptedData[0].data);
            lockedArray = lockedArray.flat();
          }

          if (!this.productId) {
            this.hedgeFundData = lockedArray;
          }

          this.totalDatalengthofhedgeFund =
            ParseDecryptedData[0].total[0].count;

          if (ParseDecryptedData[0].data.length > 9) {
            return (this.customclassforhedgefund =
              'table-responsive scrollable-table');
          } else {
            return (this.customclassforhedgefund =
              'table-responsive fitcontenttable');
          }
        },
      });
  }

  getOptionData() {
    this.isLoadingforOptions = true;

    this.wishlistServiesService.getOptionsummary().subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res);

        const ParseDecryptedData = JSON.parse(decryptData);

        ParseDecryptedData.forEach((element: any) => {
          const callsTotal = element.totalCalls;
          const putTotal = element.totalPuts;
          for (const a of callsTotal) {
            const id = a._id.expiration_date;
            const fiter = putTotal.find(
              (x: any) => x._id.expiration_date === id
            );
            this.optionData.push({
              ...a,
              putsInterest: fiter?.totalopenInterest,
              putsvolume: fiter?.totalVolume,
              date: a._id.expiration_date,
              symbol: a._id.contractSymbol,
            });
          }
          let lockedArray1: any[] = [];

          for (let i = 0; i < 7; i++) {
            lockedArray1.push(this.optionData);
            lockedArray1 = lockedArray1.flat();
          }

          if (!this.productId) {
            this.optionData = lockedArray1;
          }
          for (const a of putTotal) {
            const id = a._id.expiration_date;
            const fiter = putTotal.find(
              (x: any) => x._id.expiration_date === id
            );
            this.optionDataPuts.push({
              ...a,
              putsInterest: fiter?.totalopenInterest,
              putsvolume: fiter?.totalVolume,
              date: a._id.expiration_date,
              symbol: a._id.contractSymbol,
            });
          }
          let lockedArray: any[] = [];

          for (let i = 0; i < 7; i++) {
            lockedArray.push(this.optionDataPuts);
            lockedArray = lockedArray.flat();
          }

          if (!this.productId) {
            this.optionDataPuts = lockedArray;
          }
          if (this.optionDataPuts.length > 9) {
            return (this.customclassforoption =
              'table-responsive scrollable-table');
          } else {
            return (this.customclassforoption =
              'table-responsive fitcontenttable');
          }
        });
      },
      error: (error: any) => {
        console.error('Error fetching option data:', error);
      },
    });
  }

  loadMoreData() {
    // this.isLoading = true;
    // // this.isLoadingforHedge = true;
    // // this.isLoadingforCatalyst = true;
    // this.isLoadingforBioTeach = true;
    // this.isLoadingforinside = true;
    // this.loadData = true;
    if (this.productId) {
      if (this.isCatalystDataScroll) {
        this.wishlistServiesService
          .getCatalystwishlist(this.currentPage)
          .subscribe({
            next: (res: any) => {
              const decryptData = this.decryptionService.decrypt(res.result);

              const ParseDecryptedData = JSON.parse(decryptData);

              ParseDecryptedData[0].data.forEach((element: any) => {
                this.catalystData.push(element);
              });

              this.currentPage++;
              this.isLoadingforCatalyst = false;
            },
            error: (err) => {
              this.isLoadingforCatalyst = true;
              this.toaster.warning('All Record displayed');
            },
          });
      }

      if (this.isinsideDataScroll) {
        this.wishlistServiesService
          .getinsidewishlist(this.currentPageininside)
          .subscribe({
            next: (res: any) => {
              const decryptData = this.decryptionService.decrypt(res.result);

              const ParseDecryptedData = JSON.parse(decryptData);

              ParseDecryptedData[0].data.forEach((element: any) => {
                this.insideData.push(element);
              });

              this.currentPageininside++;
              this.isLoadingforinside = false;
            },
            error: (err) => {
              this.isLoadingforinside = true;
              this.toaster.warning('All Record displayed');
            },
          });
      }

      if (this.isbiotechDataScroll) {
        this.wishlistServiesService
          .getBiotechwishlist(this.currentPagebiotech)
          .subscribe({
            next: (res: any) => {
              const decryptData = this.decryptionService.decrypt(res.encrypted);

              const ParseDecryptedData = JSON.parse(decryptData);
              // this.biotechData = this.biotechData.concat(res);
              ParseDecryptedData[0].data.forEach((element: any) => {
                this.biotechData.push(element);
              });
              this.currentPagebiotech++;
              this.isLoadingforBioTeach = false;
            },
            error: (err) => {
              this.isLoadingforBioTeach = true;
              this.toaster.warning('All Record displayed');
            },
          });
      }

      if (this.isdrugpipescroll) {
        this.wishlistServiesService
          .getDrugwishlist(this.currentPagedrugpipeline)
          .subscribe({
            next: (res: any) => {
              const decryptData = this.decryptionService.decrypt(res.result);

              const ParseDecryptedData = JSON.parse(decryptData);

              ParseDecryptedData[0].data.forEach((element: any) => {
                this.drugpipeData.push(element);
              });

              this.currentPagedrugpipeline++;
              this.isdrugpipescroll = false;
            },
            error: (err) => {
              this.isdrugpipescroll = true;
              this.toaster.warning('All Record displayed');
            },
          });
      }

      if (this.ishedgescroll) {
        this.wishlistServiesService
          .getHedgeFundwishlist(this.currentPagehedgefund)
          .subscribe({
            next: (res: any) => {
              const decryptData = this.decryptionService.decrypt(res.result);

              const ParseDecryptedData = JSON.parse(decryptData);

              ParseDecryptedData[0].data.forEach((element: any) => {
                this.hedgeFundData.push(element);
              });

              this.currentPagehedgefund++;
              this.isLoadingforHedge = false;
            },
            error: (err) => {
              this.isLoadingforHedge = true;
              this.toaster.warning('All Record displayed');
            },
          });
      }
    }
  }

  onScrollCatalyst(event: any) {
    const element = event.target as HTMLElement;
    const scrollPosition = element.scrollTop + element.clientHeight;
    const totalHeight = element.scrollHeight;
    if (scrollPosition >= totalHeight - 1 && !this.isLoadingforCatalyst) {
      if (this.currentPage != 1) {
        this.isCatalystDataScroll = true;
        this.ishedgescroll = false;
        this.isbiotechDataScroll = false;
        this.isinsideDataScroll = false;
        this.isdrugpipescroll = false;

        this.loadMoreData();
      }
    }
  }

  onScrollBiotech(event: any) {
    const element = event.target as HTMLElement;
    const scrollPosition = element.scrollTop + element.clientHeight;
    const totalHeight = element.scrollHeight;
    if (scrollPosition >= totalHeight - 1 && !this.isLoadingforBioTeach) {
      if (this.currentPagebiotech != 1) {
        this.isCatalystDataScroll = false;
        this.isinsideDataScroll = false;
        this.ishedgescroll = false;
        this.isbiotechDataScroll = true;
        this.isdrugpipescroll = false;

        this.loadMoreData();
      }
    }
  }

  onScrollInside(event: any) {
    const element = event.target as HTMLElement;
    const scrollPosition = element.scrollTop + element.clientHeight;
    const totalHeight = element.scrollHeight;

    if (scrollPosition >= totalHeight - 1 && !this.isLoadingforinside) {
      if (this.currentPageininside != 1) {
        this.isinsideDataScroll = true;
        this.isCatalystDataScroll = false;
        this.isbiotechDataScroll = false;
        this.ishedgescroll = false;
        this.isdrugpipescroll = false;

        this.loadMoreData();
      }
    }
  }

  onDrugpipeScroll(event: any) {
    const element = event.target as HTMLElement;
    const scrollPosition = element.scrollTop + element.clientHeight;
    const totalHeight = element.scrollHeight;

    if (scrollPosition >= totalHeight - 1 && !this.isdrugpipescroll) {
      if (this.currentPagedrugpipeline != 1) {
        this.isCatalystDataScroll = false;
        this.isinsideDataScroll = false;
        this.isbiotechDataScroll = false;
        this.ishedgescroll = false;
        this.isdrugpipescroll = true;

        this.loadMoreData();
      }
    }
  }

  onscrollhedgefund(event: any) {
    const element = event.target as HTMLElement;
    const scrollPosition = element.scrollTop + element.clientHeight;
    const totalHeight = element.scrollHeight;
    if (scrollPosition >= totalHeight - 1 && !this.isLoadingforHedge) {
      if (this.currentPagehedgefund != 1) {
        this.isCatalystDataScroll = false;
        this.isinsideDataScroll = false;
        this.isbiotechDataScroll = false;
        this.isdrugpipescroll = false;
        this.ishedgescroll = true;
        this.loadMoreData();
      }
    }
  }

  removeSymboltoPotfoliyo(symbol: string, type: string) {
    let loginuser = JSON.parse(localStorage.getItem('loggedInUser') || '{}');

    let obj = {
      email: loginuser.email,
      ticker: symbol,
    };

    switch (type) {
      case 'overview':
        this.fdacalendarservice.removeSymboltoPotfoliyo(obj).subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res);
            this.portfoliomatchTicker = [];
            const ParseDecryptedData = JSON.parse(decryptData);
            ParseDecryptedData.portfolio.forEach((element: any) => {
              this.portfoliomatchTicker.push(element.ticker);
            });

            this.toaster.success('Symbol removed from your portfolio');
            // this.currentPage = 1;
            this.call_all_function();
          },
        });
        break;
      case 'catalyst':
        this.fdacalendarservice.removeSymboltoPotfoliyo(obj).subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res);
            this.portfoliomatchTicker = [];
            const ParseDecryptedData = JSON.parse(decryptData);
            ParseDecryptedData.portfolio.forEach((element: any) => {
              this.portfoliomatchTicker.push(element.ticker);
            });

            this.toaster.success('Symbol removed from your portfolio');
            this.currentPage = 1;
            this.call_all_function();
          },
        });
        break;
      case 'inside':
        this.fdacalendarservice.removeSymboltoPotfoliyo(obj).subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res);
            this.portfoliomatchTicker = [];
            const ParseDecryptedData = JSON.parse(decryptData);
            ParseDecryptedData.portfolio.forEach((element: any) => {
              this.portfoliomatchTicker.push(element.ticker);
            });
            this.toaster.success('Symbol removed from your portfolio');
            this.currentPageininside = 1;
            this.call_all_function();
          },
        });
        break;
      case 'biotech':
        this.fdacalendarservice.removeSymboltoPotfoliyo(obj).subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res);
            this.portfoliomatchTicker = [];
            const ParseDecryptedData = JSON.parse(decryptData);
            ParseDecryptedData.portfolio.forEach((element: any) => {
              this.portfoliomatchTicker.push(element.ticker);
            });
            this.toaster.success('Symbol removed from your portfolio');
            this.currentPagebiotech = 1;
            this.call_all_function();
          },
        });
        break;
      case 'drugpipe':
        this.fdacalendarservice.removeSymboltoPotfoliyo(obj).subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res);
            this.portfoliomatchTicker = [];
            const ParseDecryptedData = JSON.parse(decryptData);
            ParseDecryptedData.portfolio.forEach((element: any) => {
              this.portfoliomatchTicker.push(element.ticker);
            });
            this.toaster.success('Symbol removed from your portfolio');
            this.currentPagedrugpipeline = 1;
            this.call_all_function();
          },
        });
        break;
      case 'hedgefund':
        this.fdacalendarservice.removeSymboltoPotfoliyo(obj).subscribe({
          next: (res: any) => {
            const decryptData = this.decryptionService.decrypt(res);
            this.portfoliomatchTicker = [];
            const ParseDecryptedData = JSON.parse(decryptData);
            ParseDecryptedData.portfolio.forEach((element: any) => {
              this.portfoliomatchTicker.push(element.ticker);
            });
            this.toaster.success('Symbol removed from your portfolio');
            this.currentPagehedgefund = 1;
            this.call_all_function();
          },
        });
        break;
    }
  }

  call_all_function() {
    this.currentPage = 1;
    this.currentPageininside = 1;
    this.currentPagebiotech = 1;
    this.currentPagedrugpipeline = 1;
    this.currentPagehedgefund = 1;

    this.drugpipeWislist();
    this.getCatalystwishlist();
    this.getinsidewishlist();
    this.BioTechwishlist();
    this.hedgefundWishlist();
    this.getOptionData();
    this.loadNewsData();
    this.portfolioOverview();

    this.catalystData = [];
    this.drugpipeData = [];
    this.insideData = [];
    this.biotechData = [];
    this.hedgeFundData = [];
    this.portfolioData = [];
    this.optionData = [];
    this.optionDataPuts = [];
    this.newsItems = [];
    this.totalprofit = 0;

    this.totalcostval = 0;
    this.totalInvestedval = 0;
    this.emptyMsg();
  }

  portfolioOverview() {
    this.wishlistServiesService.getPortfolioOverview().subscribe({
      next: (res: any) => {
        let tolcost: any[] = [];
        let tolprice: any[] = [];
        let tolprofit: any[] = [];

        res.forEach((element: any) => {
          tolcost.push(element.totalCost);
          tolprice.push(element.totalPrice);
          tolprofit.push(element.totalProfit);
        });

        this.portfolioData = res;

        this.totalInvestedval = 0;

        tolcost.forEach((ele) => {
          this.totalInvestedval += ele;
        });

        tolprice.forEach((ele) => {
          this.totalcostval += ele;
        });

        tolprofit.forEach((ele) => {
          this.totalprofit += ele;
        });

        let lockedArray: any[] = [];

        for (let i = 0; i < 4; i++) {
          lockedArray.push(this.portfolioData);
          lockedArray = lockedArray.flat();
        }

        if (!this.productId) {
          this.portfolioData = lockedArray;
        }

        if (res.length > 13) {
          return (this.customclassforoverview =
            'table-responsive scrollable-table');
        } else {
          return (this.customclassforoverview =
            'table-responsive fitcontenttable');
        }
      },
    });
  }

  emptyMsg() {
    setTimeout(() => {
      if (
        !(
          this.portfolioData.length ||
          this.catalystData.length ||
          this.insideData.length ||
          this.biotechData.length ||
          this.drugpipeData.length ||
          this.hedgeFundData.length ||
          this.optionData.length ||
          this.optionDataPuts.length
        )
      ) {
        this.emptyView = true;
      } else {
        this.emptyView = false;
      }
    }, 3000);
  }

  searchFilters(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;

    if (event.key === 'Escape' && !inputElement.value) {
      this.suggection = [];
      return;
    }

    if (!inputElement.value || !this.searchValuequery) {
      this.suggection = [];
      return;
    }

    this.compamyDetails.searchsuggection(inputElement.value).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res);
        const ParseDecryptedData = JSON.parse(decryptData);
        this.suggection = ParseDecryptedData;
      },
    });
  }
  onSearchInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;

    if (inputValue === '') {
      this.suggection = [];
    }
  }

  addsuggection(symbol: string) {
    this.searchValuequery = symbol;
    this.suggection = [];
  }

  addSymboltoPotfoliyo() {
    let loginuser = JSON.parse(localStorage.getItem('loggedInUser') || '{}');

    // Emit event with data
    // if (!this.searchValuequery) {
    //   this.toaster.warning('Enter valid input');
    //   return;
    // }

    let obj = {
      email: loginuser.email,
      ticker: this.searchValuequery,
      cost: this.portfoliocost,
      holdings: this.portfolioquntity,
    };

    this.fdacalendarservice.addSymboltoPotfoliyo(obj).subscribe({
      next: (res: any) => {
        this.call_all_function();
        this.toaster.success('Symbol added to your portfolio');
        this.searchValuequery = '';
        this.portfoliocost = '';
        this.portfolioquntity = '';
      },
    });
  }

  closePopup() {
    this.displayStyle = 'none';
    document.body.style.overflow = 'auto';
  }

  openpopup(data: any) {
    this.newsItem = data;
    this.displayStyle = 'block';
    document.body.style.overflow = 'hidden';
  }

  removeDuplicatesByTitle(arr: any) {
    const uniqueTitles = new Set();
    return arr.filter((obj: any) => {
      const title = obj.title;
      return uniqueTitles.has(title) ? false : uniqueTitles.add(title);
    });
  }

  loadNewsData() {
    this.newsService.getPortfolioNewsData(1).subscribe({
      next: (res: any) => {
        let decryptData = this.decryptionService.decrypt(res);
        this.newsItems = this.newsItems.concat(
          this.removeDuplicatesByTitle(JSON.parse(decryptData))
        );

        if (this.newsItems.length > 5) {
          return (this.customclassfornews =
            'table-responsive scrollable-table');
        } else {
          return (this.customclassfornews = 'table-responsive fitcontenttable');
        }

        // this.newsCount = res.count;
        // this.isLoading = false;
      },
      error: (err: any) => {
        // this.isLoading = true;
      },
    });
  }
  companyDetails(ticker: any) {
    this.compamyDetails.getCompanyTable(ticker).subscribe({
      next: (res: any) => {
        this.router.navigate(['company', ticker]);
      },
    });
    this.compamyDetails.getCompanyTicker(ticker);
  }
}
