import { Component } from '@angular/core';
import { PdufaCalendarService } from '../../../shared/service/pdufa-calendar.service';
import { CompanyDetailsService } from '../../../shared/service/company-details.service';
import { Router } from '@angular/router';
import { DecryptionService } from 'src/app/modules/shared/service/decryption.service';
import { environment } from 'src/environments/environment';
import { ConferenceCalendarServiceService } from 'src/app/modules/shared/service/conference-calendar.service.service';

@Component({
  selector: 'app-conference-calendar',
  templateUrl: './conference-calendar.component.html',
  styleUrl: './conference-calendar.component.scss',
})
export class ConferenceCalendarComponent {
  [x: string]: any;
  detailData: any;
  productId: any;
  monthName: any;
  requestBody: any;
  currentMonth: Date = new Date();
  selectedDate!: Date;
  selectedDay!: number;
  weekdays: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  calendarDays: Date[] = [];
  displayStyle = 'none';
  displayStylemobile = 'none';
  date!: number;
  isLoading = true;
  year!: number;
  month!: number;
  resobj: any;
  backbtn = false;
  mobpopupzindex!: number;
  modalDisplayStyle!: boolean;
  loginuser = localStorage.getItem('loggedInUser');
  selectedStock: {
    title: string;
    description: string;
    prev_earnings_per_share: number;
    symbol: string;
  } | null = null;
  attributes = {
    disabled: false,
  };
  showloginWarning = false;
  showDetailDataFlag = false;
  pdufascrollData = 'data-get-scroll';
  basePath: string = 'https://s3-symbol-logo.tradingview.com/';
  bsConfig: object = {
    dateInputFormat: 'YYYY-MM',
    minMode: 'month',
    containerClass: 'theme-dark-blue',
    isAnimated: true,
  };
  dateModel = new Date();
  clientUrl = environment.clientUrl;
  dateRanges: any[] = [];
  colors: string[] = ['red', 'blue'];

  constructor(
    private conferenceservice: ConferenceCalendarServiceService,
    private compamyDetails: CompanyDetailsService,
    private router: Router,
    private decryptionService: DecryptionService
  ) {}

  ngOnInit(): void {
    this.generateCalendarDays();
    // this.getColorForConference();
    this.getCalendarData();
    this.returnLogin();
  }

  isDateInRange(day: Date): boolean {
    let convertdate = this.formatDate(day);
    return this.dateRanges.some(
      (range) => convertdate >= range.startDate && convertdate <= range.endDate
    );
  }

  getColorForConference() {
    this.resobj.forEach((element: any) => {
      element.color = 'red';
    });
  }

  returnLogin() {
    let loign = localStorage.getItem('loggedInUser');
    if (!loign) {
      this.showloginWarning = true;
    }
  }

  selectdateforcalendar() {
    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }
  }

  onMonthYearChange(event: Event): void {
    this.currentMonth = new Date(this.dateModel);
    this.generateCalendarDays();
    this.getCalendarData();
    this.selectdateforcalendar();
  }

  generateCalendarDays(): void {
    this.calendarDays = [];
    const year = this.currentMonth.getFullYear();
    const month = this.currentMonth.getMonth();
    const lastDayOfMonth = new Date(year, month + 1, 0);

    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      const currentDate = new Date(year, month, i);
      this.calendarDays.push(currentDate);
    }
  }

  getCalendarData() {
    this.showDetailDataFlag = false;
    this.requestBody = {
      year: this.currentMonth.getFullYear().toString(),
      month: ('0' + (this.currentMonth.getMonth() + 1)).slice(-2),
    };
    var monthstring = this.requestBody.month;
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    this.monthName = monthNames[monthstring - 1];

    this.isLoading = true;

    this.conferenceservice.conferencecalendar(this.requestBody).subscribe({
      next: (res: any) => {
        const decryptData = this.decryptionService.decrypt(res.result);
        const ParseDecryptedData = JSON.parse(decryptData);

        this.productId = ParseDecryptedData.productId;

        this.isLoading = false;
        let index = 1;
        let tmpdata = new Array();
        ParseDecryptedData.data.forEach((x: any, i: number) => {
          x.color = this.getRandomColor(); //i % 2 === 0 ? 'cls-blue' : 'cls-red';

          const r = parseInt(x.color.slice(1, 3), 16);
          const g = parseInt(x.color.slice(3, 5), 16);
          const b = parseInt(x.color.slice(5, 7), 16);

          // Use luminance to decide if text color should be black or white
          const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
          const textColor = luminance > 128 ? 'black' : 'white';
          x.textcolor = textColor;

          var findbetweendate = tmpdata.findIndex(
            (f: any) =>
              new Date(x['Start Date']).getTime() >=
                new Date(f['Start Date']).getTime() &&
              new Date(x['Start Date']).getTime() <=
                new Date(f['End Date']).getTime()
          );
          if (findbetweendate === -1) {
            x.idindex = index;
            tmpdata.push(x);
            index++;
          } else {
            var finddatabetweendate = tmpdata.find(
              (f: any) =>
                new Date(x['Start Date']).getTime() >=
                  new Date(f['Start Date']).getTime() &&
                new Date(x['Start Date']).getTime() <=
                  new Date(f['End Date']).getTime()
            );

            x.idindex = finddatabetweendate.idindex;
          }
        });
        this.resobj = ParseDecryptedData.data;
       
      },
      error: (err) => {
        this.isLoading = false;
        this.resobj = [];
      },
    });
  }

  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getConferencesForDate(day: Date): any[] {
    let conferences: any[] = new Array();
    let convertdate = this.formatDate(day);

    this.resobj.forEach((range: any) => {
      if (
        convertdate >= range['Start Date'] &&
        convertdate <= range['End Date']
      ) {
        let samedate = conferences.findIndex(
          (f) => f.startdate === range['Start Date']
        );

        conferences.push({
          name: range['Conference Name'],
          color: range['color'],
          textcolor: range['textcolor'],
          startdate: range['Start Date'],
          enddate: range['End Date'],
          samedate: samedate,
        });
      }
    });
    return conferences;
  }

  formatDate(date: any) {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  getMatchingStocks(day: any) {
    return this.resobj.filter((stock: any) => {
      const stockDate = new Date(stock['Start Date']);
      return (
        stockDate.getDate() === day.getDate() &&
        stockDate.getMonth() === day.getMonth() &&
        stockDate.getFullYear() === day.getFullYear()
      );
    });
  }

  getEmptyDaysBeforeFirstDay(): number[] {
    const firstDayOfMonth = this.calendarDays[0];
    const dayOfWeek = firstDayOfMonth.getDay();
    const emptyDays = [];
    for (let i = 0; i < dayOfWeek; i++) {
      emptyDays.push(i);
    }
    return emptyDays;
  }

  prevMonth(): void {
    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }
    this.showDetailDataFlag = false;

    const prevMonthDate = new Date(this.currentMonth);
    prevMonthDate.setMonth(prevMonthDate.getMonth() - 1);
    this.currentMonth = prevMonthDate;
    this.generateCalendarDays();
    this.getCalendarData();
  }

  nextMonth(): void {
    if (!this.productId) {
      this.modalDisplayStyle = true;
      return;
    }
    this.showDetailDataFlag = false;
    const nextMonthDate = new Date(this.currentMonth);
    nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
    this.currentMonth = nextMonthDate;
    this.generateCalendarDays();
    this.getCalendarData();
  }

  isToday(date: Date): boolean {
    const today = new Date();
    return date.toDateString() === today.toDateString();
  }

  isSelected(date: Date): boolean {
    this.date = date.getDate();
    this.month = date.getMonth();
    this.year = date.getFullYear();

    if (!this.selectedDate) return false;

    const selectedYear = this.selectedDate.getFullYear();
    const selectedMonth = this.selectedDate.getMonth();
    const selectedDay = this.selectedDate.getDate();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    const currentDay = date.getDate();

    return (
      selectedYear === currentYear &&
      selectedMonth === currentMonth &&
      selectedDay === currentDay
    );
  }

  // formatDate(date: Date): string {
  //   const year = date.getFullYear();
  //   const month = (date.getMonth() + 1).toString().padStart(2, '0');
  //   const day = date.getDate().toString().padStart(2, '0');
  //   return `${year}-${month}-${day}`;
  // }

  selectDate(date: Date): void {
    this.selectedDate = date;
    this.selectedDay = date.getDate();
  }

  getSelectedDayData() {
    this.showDetailDataFlag = true;
  }

  showDetailData() {
    this.getCalendarData();
  }

  // openpopupmo(dayRef: any) {
  //   this.showDetailDataFlag = true;
  //   this.backbtn = true;
  //   this.mobpopupzindex = 999;
  // }

  GetChildData(data: any) {
    this.modalDisplayStyle = data;
  }

  // companysDetails(ticker: any) {
  //   var loginuser = localStorage.getItem('loggedInUser');
  //   if (loginuser === null) {
  //     this.modalDisplayStyle = true;
  //   } else {
  //     this.compamyDetails.getCompanyTable(ticker).subscribe({
  //       next: (res: any) => {
  //         this.router.navigate(['company', ticker]);
  //       },
  //     });
  //     this.compamyDetails.getCompanyTicker(ticker);
  //   }
  // }

  toggle() {
    document.getElementById('startMonth')?.click();
  }
  closepopup() {
    this.modalDisplayStyle = false;
  }
}
