import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HitmapData, HitmapDatawIndustry } from '../const';

@Injectable({
  providedIn: 'root'
})
export class HitmapService {

  constructor(private http: HttpClient) { }

  Hitmap(data: object) {
    return this.http.post(`${HitmapData}`, data);
  }
  HitmapWIndustry(data: object) {
    return this.http.post(`${HitmapDatawIndustry}`, data);
  }
}
