<section class="allstocksold">
  <div class="container p-3">
    <div class="text-center fda-text">
      <h1 class="main-heading text-white m-0">All Stocks Holdings</h1>
      <p class="sub-heading text-center text-white m-0">
        "All Stock Holdings" provides a powerful snapshot of biopharma
        investments from elite hedge funds, highlighting company support,
        performance, and investor consensus for strategic market insights.
      </p>
    </div>
  </div>
</section>
<section class="CatalystSync-table">
  <div class="container-fluid form-box py-2">
    <div class="row justify-content-center align-items-center">
      <div class="col-lg-3 col-md-10 col-12">
        <form style="height: 30px" class="example d-flex add_m_infilter">
          <input
            [(ngModel)]="searchValuequery"
            autocomplete="off"
            (keyup)="searchFilters($event)"
            (input)="onSearchInput($event)"
            #searchVal
            type="search"
            class="input-box"
            placeholder="Search by Stocks"
            name="search2"
          />
          <button
            (click)="searchFiltersonclick(searchValuequery)"
            class="searchquerybtn"
            type="button"
          >
            @if (isFilterBtnSearch) {
            <i style="color: white" class="fa fa-spin fa-spinner"></i>

            }@else {
            <i class="fa-solid fa-magnifying-glass"></i>
            }
          </button>
        </form>
        @if (suggection?.length > 0) {
        <ul class="list-unstyled m-0">
          @for (suggestion of suggection; track $index) {
          <li class="suggestion-item p-0 cursorpointer">
            <div
              (click)="searchFiltersonclick(suggestion.symbol)"
              class="d-flex filter-bottom justify-content-between px-2"
            >
              <span>
                @if (suggestion.logoid !== '') {
                <img
                  width="25px"
                  [src]="basePath + suggestion.logoid + '.svg'"
                />
                }@else {
                <span
                  class="avatarcss"
                  style="
                    width: 25px !important;
                    height: 25px !important;
                    margin: 0 !important;
                  "
                  >{{ suggestion.name | initialLetter }}</span
                >
                }
              </span>
              <p
                class="text-start text-dark filter-font-text mb-1 border-1 me-4"
              >
                {{ suggestion.name }}
              </p>
              <p class="text-end text-dark filter-font-text mb-1">
                {{ suggestion.symbol }}
              </p>
            </div>
          </li>
          }
        </ul>
        }
      </div>
      <div class="col-lg-auto add_m_infilter col-md-3 col-6">
        <div class="dropdown d-flex">
          <button
            [ngClass]="selectedvaluecolor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            #priceinput
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            {{
              selectedAvgPriceRange
              ? selectedAvgPriceRange
              : 'Avg. Price'
            }}
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if (selectedvaluecolor) {
          <span class="" (click)="clearSelectedPrice()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }

          <ul class="dropdown-menu games-menu">
            <div class="p-1" aria-labelledby="dropdownMenuButton">
              <input
                type="number"
                class="custom-input-set mb-2"
                placeholder="Min Value"
                [(ngModel)]="minCustomAvg"
                (input) = "updateAvgPriceCustomRange()"
                (change)="pushFilterminPrice($event)"
              />
              <input
                type="number"
                class="custom-input-set"
                placeholder="Max Value"
                [(ngModel)]="maxCustomAvg"
                (input) = "updateAvgPriceCustomRange()"
                (change)="pushFiltermaxPrice($event)"
              />
            </div>
          </ul>
        </div>
      </div>
      <div class="col-lg-auto add_m_infilter col-md-3 col-6">
        <div class="dropdown d-flex">
          <button
            [ngClass]="selectedmarketcolor"
            class="btn d-flex align-items-center justify-content-between accordion-down dropdown-toggle"
            #priceinput
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            {{
              selectedMarketRange
              ? selectedMarketRange
              : 'Market Size'
            }}
            <i class="fa fa-caret-down ms-4"></i>
          </button>

          @if (selectedmarketcolor) {
          <span class="" (click)="clearSelectedMarket()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }

          <ul class="dropdown-menu games-menu">
            <div class="p-1" aria-labelledby="shareHoldDropdown">
              <input
                type="number"
                class="custom-input-set mb-2"
                placeholder="Min Value (Millions)"
                [(ngModel)]="minCustomMarket"
                (input) = "updateMarketSizeCustomRange()"
                (change)="pushFilterMarketStart($event)"
              />
              <input
                type="number"
                class="custom-input-set"
                placeholder="Max Value (Millions)"
                [(ngModel)]="maxCustomMarket"
                (input) = "updateMarketSizeCustomRange()"
                (change)="pushFilterMarketEnd($event)"
              />
            </div>
          </ul>
        </div>
      </div>
      <div class="col-lg-auto add_m_infilter col-md-3 col-6">
        <div class="dropdown d-flex justify-content-center">
          <button
            [ngClass]="{ selectedbgforsec: marketCapVal !== '' }"
            class="btn d-flex align-items-center justify-content-between accordion-down"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
          <span>
            {{
              marketCapVal
              ? marketCapVal
              : 'Market Cap'
            }}
          </span>
            <i class="fa fa-caret-down ms-4"></i>
          </button>
          @if (marketCapVal) {
          <span class="" (click)="clearmarketcap()">
            <img
              class="m-1 mb-0 cursorpointer"
              src="assets/images/new-minus.svg"
              alt=""
            />
          </span>
          }
          <ul class="dropdown-menu games-menu">
            <li>
              <a
                class="dropdown-item filter-bottom filter-font-text"
                [ngClass]="{
                  selecteddropdwon: marketCapVal === 'micro-cap'
                }"
                (click)="selectedCap('micro-cap')"
                >Micro Caps</a
              >
            </li>
            <li>
              <a
                class="dropdown-item filter-bottom filter-font-text"
                [ngClass]="{
                  selecteddropdwon: marketCapVal === 'small-cap'
                }"
                (click)="selectedCap('small-cap')"
                >Small Caps</a
              >
            </li>
            <li>
              <a
                class="dropdown-item filter-bottom filter-font-text"
                [ngClass]="{ selecteddropdwon: marketCapVal === 'mid-cap' }"
                (click)="selectedCap('mid-cap')"
                >Mid Caps</a
              >
            </li>

            <li>
              <a
                class="dropdown-item filter-bottom filter-font-text"
                [ngClass]="{
                  selecteddropdwon: marketCapVal === 'large-cap'
                }"
                (click)="selectedCap('large-cap')"
                >Large Caps</a
              >
            </li>
            <li>
              <a
                class="dropdown-item text-center filter-font-text"
                [ngClass]="{
                  selecteddropdwon: marketCapVal === 'mega-cap'
                }"
                (click)="selectedCap('mega-cap')"
                >Mega Caps</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div id="add" class="text-center mt-1 d-flex justify-content-center">
      <button
        type=""
        class="form-save-button d-flex justify-content-center align-items-center text-nowrap me-3 mt-2 mb-1"
        (click)="saveFilters()"
      >
        @if (!isFilterBtn) {
        <p class="mb-0">APPLY FILTERS</p>
        }@else {
        <i class="fa fa-spin fa-spinner"></i>
        }
      </button>
      <button
        type=""
        class="form-reset-button d-flex justify-content-center align-items-center text-nowrap mt-2 mb-1"
        (click)="resetFilters()"
      >
        @if (!isFilterBtnResert) {
        <p class="mb-0">RESET FILTERS</p>
        }@else {
        <i class="fa fa-spin fa-spinner"></i>
        }
      </button>
    </div>
  </div>
</section>

<section class="volatile">
  <p class="text-start tblcompanyLength my-1 d-flex align-items-center">
    <i class="fa-solid fa-circle-info mx-2"></i>
    Showing {{ allstocksTbl?.length }} Stocks Out of {{ totalDatalength }}.
    Click on the tickers for more details!
  </p>

  <div style="position: relative">
    <div
      class="table-responsive scrollable-table"
      id="dataContainer"
      #dataContainer
      (scroll)="onScroll($event)"
      [ngClass]="{ tablelocked: !productId }"
    >
      <table class="table align-middle mb-0 bg-white">
        <thead class="text-center">
          <tr class="text-uppercase table-header">
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                Rank<i
                  (click)="assending('rank')"
                  [ngClass]="arrowrank"
                  class="fa-solid cursorpointer mx-3"
                ></i>
              </div>
            </th>
            <th class="table-name-size">Stock</th>
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                company Name
                <!-- <i (click)="assending('Issuer_Name')" class="fa-solid cursorpointer mx-3"></i> -->
              </div>
            </th>

            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                Hedge Funds
                <i
                  (click)="assending('hedgeFunds')"
                  [ngClass]="arrowavg_price"
                  class="fa-solid cursorpointer mx-3"
                ></i>
              </div>
            </th>
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                Shares Held<i
                  (click)="assending('current_shares')"
                  [ngClass]="arrowshare_held"
                  class="fa-solid cursorpointer mx-3"
                ></i>
              </div>
            </th>
            <th>
              <div class="d-flex justify-content-center cursor-pointer">
                Market value<i
                  (click)="assending('current_mv')"
                  [ngClass]="arrowmarket_cap"
                  class="fa-solid cursorpointer mx-3"
                ></i>
              </div>
            </th>
          </tr>
        </thead>
        <tbody id="tbody" class="text-center">
          @if(!allstocksTbl || allstocksTbl?.length == 0){

          <tr style="box-shadow: none">
            <td colspan="17">
              <ngx-skeleton-loader
                [theme]="{
                  height: '50px',
                  'border-radius': '4px',
                  'margin-top': '20px'
                }"
                count="11"
              >
              </ngx-skeleton-loader>
            </td>
          </tr>

          }@else { @for (data of allstocksTbl; track $index) {

          <tr class="table-tr-font">
            <td class="">
              <p class="">{{ data.rank }}</p>
            </td>

            <td scope="row ">
              <div class="d-flex justify-content-center align-items-center">
                <div (click)="companyDetails(data.symbol)" style="width: 40px">
                  @if (data.combinedData?.logoid) {
                  <img
                    width="25px"
                    [src]="basePath + data.combinedData?.logoid + '.svg'"
                    class="me-2"
                  />
                  }@else {
                  <span class="avatarcssForTable me-2 avatarcss">{{
                    data.symbol | initialLetter
                  }}</span>
                  }
                </div>
                <div style="width: 40px">
                  <span class="cursorpointer">
                    <a
                      href="{{ clientUrl }}/company/{{
                        data.symbol | uppercase
                      }}"
                      class="link-ticker"
                    >
                      {{ data.symbol }}
                    </a>
                  </span>
                </div>
              </div>

              <!-- <p class="cursorpointer">{{data.symbol}}</p> -->
            </td>

            <td class="">
              <p class="">{{ data.combinedData?.name }}</p>
            </td>
            <td>
              @if (data.hedgeFundName) {
              <p class="m-0">{{ data.hedgeFunds }}</p>
              }@else { - }
            </td>
            <td>
              <p>{{ data.current_shares | dollarsCoversion }}</p>
            </td>
            <td>
              <p>$ {{ data.current_mv | dollarsCoversion }}</p>
            </td>
          </tr>

          } @empty { No Record Found } } @if (loadData) {
            <td colspan="20">
              <ngx-skeleton-loader
                [theme]="{
                    height: '50px',
                    'border-radius': '4px',
                    'margin-top': '20px',
                  }"
                count="1"
              >
              </ngx-skeleton-loader>
            </td>
          }
        </tbody>
      </table>
    </div>
    <div
      style="
        position: absolute;
        bottom: 0;
        background-color: #f1f7fde6;
        z-index: 1;
      "
    >
      @if (!productId) { @if (allstocksTbl?.length > 0) {
      <app-locksec></app-locksec>
      } }
    </div>
  </div>

  <!-- <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{ display: displayStyle }">
              <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h4 class="modal-title text-center">Price Votting</h4>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closePopup()"
                              aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                          <form [formGroup]="plusminuspricetarget" (ngSubmit)="savePopup()" action="">
                              <div class="d-flex text-center justify-content-center">
                                  <div style="width: 100%">
                                      <input formControlName="up_button"
                                          class="votting-price-up btn profile-edit-button button-up fw-bold text-uppercase btn-light shadow rounded password-input-save"
                                          type="submit" value="Up" />
                                  </div>
                                  <div style="width: 100%">
                                      <input formControlName="down_button"
                                          class="votting-price-up button-down btn profile-edit-button fw-bold text-uppercase btn-light shadow rounded password-input-save"
                                          type="submit" value="Down" />
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      
          <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{ display: displayStyle2 }">
              <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h4 class="modal-title text-center">Price Target</h4>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closePopup()"
                              aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                          <form [formGroup]="priceTarget" (ngSubmit)="priceSavePopup()" action="">
                              <div class="d-flex text-center justify-content-center price_target_input_maindiv">
                                  <div class="me-3 popup-input">
                                      <input formControlName="price_up_button" inputmode="numeric" class="price_target_input"
                                          type="number" value="price_up_button" (input)="validateInput($event)" />
                                  </div>
                                  <div class="popup-button">
                                      <button
                                          class="votting-price-up btn profile-edit-button button-up fw-bold text-uppercase btn-light shadow rounded password-input-save"
                                          type="submit" [disabled]="!priceTarget.valid">
                                          Submit
                                      </button>
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      
          <app-loginpop-up [displayStyle]="modalDisplayStyle" (myout)="GetChildData($event)"></app-loginpop-up> -->
  <app-loginpop-up
    [displayStyle]="modalDisplayStyle"
    (myout)="GetChildData($event)"
  ></app-loginpop-up>
</section>
