import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dollarsCoversion',
})
export class DollarsCoversionPipe implements PipeTransform {
  num!: any;

  transform(value: string | number): string | number {
    if (!value) {
      return '-';
    }

    if (typeof value == 'number') {
      this.num = value.toString().split('.')[0].length;
    }

    if (isNaN(Number(value))) {
      return '0';
    } else {
      const num = Number(value);
      const isNegative = num < 0;
      const absNum = Math.abs(num);

      if (absNum >= 1000000000) {
        return (isNegative ? '-' : '') + (absNum / 1000000000).toFixed(2) + ' B';

      } else if (absNum >= 1000000) {
        return (isNegative ? '-' : '') + (absNum / 1000000).toFixed(2) + ' M';

      } else if (absNum >= 1000) {
        return (isNegative ? '-' : '') + (absNum / 1000).toFixed(2) + ' K';

      } else {
        return absNum.toString();
      }
    }
  }
}
